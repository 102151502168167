import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { stylesGlobal } from "./utils/muiStyles";
import { FallbackProps } from "react-error-boundary";

const ErrorFallback = ({ resetErrorBoundary }: FallbackProps) => {
  return (
    <Stack sx={stylesGlobal.stackColumnCenter}>
      <Stack sx={stylesGlobal.stackRowCenter}>
        <Typography variant="h4">Something went wrong,</Typography>
        <Typography variant="h6">Please try again later</Typography>
        <Button onClick={resetErrorBoundary}>Dismiss</Button>
      </Stack>
    </Stack>
  );
};

export default ErrorFallback;
