import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE, RESET } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";
import { toast } from "react-toastify";

const { SUCCESS, REQUEST, UNSUBSCRIBE_PLAN } = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const unsubscribePlanData: any = (state = intialState, action: actions) => {
    switch (action.type) {
      case UNSUBSCRIBE_PLAN[REQUEST]:
        return intialState;
      case UNSUBSCRIBE_PLAN[SUCCESS]:
        toast.success("Plan Unsubscribed Successfully");
        return {
          ...state,
          data: action?.payload,
          isLoading: false,
          isError: false,
        };
      case UNSUBSCRIBE_PLAN[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case UNSUBSCRIBE_PLAN[RESET]:
        return intialState;
      default:
        return state;
    }
  };

  return combineReducers({
    unsubscribePlanData,
  });
};

export default entity;

export const unsubscribePlan = (state: RootState) =>
  state.entities.unsubscribePlan;
