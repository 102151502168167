import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, EDIT_VIDEO_AUTH } = actionTypes;

//write sagas function

function* editVideoAuthSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.editVideoAuth,
      data.data
    );
    yield sendPayload(apiResponse, EDIT_VIDEO_AUTH);
  } catch (e) {
    yield sendPayloadFailure(e, EDIT_VIDEO_AUTH);
  }
}

export const sagas = {
  watchEditVideoAuth: takeLatest(
    actionTypes.EDIT_VIDEO_AUTH[REQUEST],
    editVideoAuthSaga
  ),
};
