import { THEMEBACKGROUNDCOLOR } from "../../../colors";

export const globalCardStyle = {
  stepperBox: {
    background: THEMEBACKGROUNDCOLOR,
    mt: "16px",
    mb: "20px",
    width: "60%",
    overflow: "auto",
    padding: "16px",
    stepperTextWrapper: {
      padding: "0",
    },
  },
};

export const personalDetailsStyle = {
  wrapper: {
    border: "1px solid rgba(0,0,0,0.25)",
    padding: "16px 33px",
    field: { color: "#969696", marginBottom: "8px", fontFamily: "Jost" },
    heading: {
      mb: "20px",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    mb: "20px",
  },
};

export const addressDetailsStyle = {
  wrapper: {
    border: "1px solid rgba(0,0,0,0.25)",
    padding: "16px 33px",
    field: { color: "#969696", marginBottom: "8px", fontFamily: "Jost" },
    heading: {
      mb: "20px",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    columnGap: "16px",
    mb: "20px",
  },
};

export const acknowledgementStyle = {
  wrapper: {
    heading: {
      mb: "20px",
    },
    checkOuter: { mt: "10px", flexDirection: "row", alignItems: "center" },
    checkBoxStyle: { p: 0, pr: "10px" },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    columnGap: "16px",
    mb: "20px",
  },
  acknowledgementWrapper: {
    height: "360px",
    overflowY: "scroll",
    border: "1px solid rgba(0,0,0,0.25)",
    padding: "16px 33px",
  },
};

export const reviewSubmitStyle = {
  wrapper: {
    border: "1px solid rgba(0,0,0,0.25)",
    padding: "16px 33px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    columnGap: "16px",
    mb: "20px",
  },
};
