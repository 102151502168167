import { Box, Button, Stack, TextField } from "@mui/material";
import React from "react";
import { SubTitle2 } from "../../../../utils/styledComponents";
import { stylesGlobal } from "../../../../utils/muiStyles";
import { useTranslation } from "react-i18next";

const CustomParamModal = ({
  close,
  queryParam,
  setQueryParam,
  setAggregateList,
}: any) => {
  function onSave() {
    close();
  }
  const {t} = useTranslation()
  return (
    <Box>
      <SubTitle2 sx={{ color: "grey" }}> {t("Query Parameter")} </SubTitle2>
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        value={queryParam}
        placeholder={t("eg. ref")}
        inputProps={{ maxLength: 40 }}
        onChange={(e) => setQueryParam(e.target.value)}
        sx={{ ...stylesGlobal.textField, m: "10px 0" }}
        name="password"
      />
      <Stack justifyContent={"center"} alignItems={"center"}>
        <Button
          sx={stylesGlobal.containedBtn}
          variant="contained"
          onClick={onSave}
        >
          {t("Okay")}
        </Button>
      </Stack>
    </Box>
  );
};

export default CustomParamModal;
