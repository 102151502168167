import { Box, Modal } from "@mui/material";
import React from "react";
import { Styles } from "./style";
import { StyledText } from "../../utils/styledComponents";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

interface IModalProps {
  open: boolean;
  onClose: any;
  modalTitle: string;
  modalBody: any;
  isPadding?:boolean;
}

const FloatModal = ({ open, onClose, modalTitle, modalBody,isPadding=true }: IModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box
        sx={
          modalTitle === "Upload Thumbnail"
            ? { ...Styles.productModalWrapper, width: "max-content !important" }
            : modalTitle === "Products to buy again"
            ? { ...Styles.modalWrapper, width: "96%", maxWidth:"600px" }
            : Styles.modalWrapper
        }
        className="overflow-x-contain"
      >
        <Box sx={Styles.modalWrapper.modalHeader}>
          <StyledText fontvalue="22px" lineheight="33px" fontWeight="500">
            {t(modalTitle)}
          </StyledText>
          <Box
            sx={Styles.modalWrapper.modalHeader.crossWrapper}
            onClick={onClose}
          >
            <ClearIcon />
          </Box>
        </Box>
        <Box
          className="overflow-x-content overflow-y"
          sx={isPadding?Styles.modalWrapper.modalBody:Styles.modalWrapper.modalbody}
        >
          {modalBody}
        </Box>
      </Box>
    </Modal>
  );
};

export default FloatModal;
