import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE, RESET } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";

const { SUCCESS, REQUEST, UPLOAD_PROFILE_PHOTO } = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
  isSuccess: false,
};

const entity = () => {
  const uploadProfilePhotoData: any = (state = {}, action: actions) => {
    switch (action.type) {
      case UPLOAD_PROFILE_PHOTO[REQUEST]:
        return {
          ...intialState,
          isLoading: true,
          isSuccess: false,
        };
      case UPLOAD_PROFILE_PHOTO[SUCCESS]:
        return {
          ...state,
          data: action.payload?.data,
          isLoading: false,
          isError: false,
          isSuccess: true,
        };
      case UPLOAD_PROFILE_PHOTO[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case UPLOAD_PROFILE_PHOTO[RESET]:
        return {};
      default:
        return state;
    }
  };

  return combineReducers({
    uploadProfilePhotoData,
  });
};

export default entity;

export const uploadCustomProduct = (state: RootState) =>
  state.entities.uploadCustomProduct;
