import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE, RESET } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";
import { toast } from "react-toastify";
import { t } from "i18next";

const {
  SUCCESS,
  REQUEST,
  POST_AFFILIATE_ADDRESS,
  GET_AFFILIATE_PROFILE,
  GOAFFPRO_NOTIFICATION_PREFERENCE,
} = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const affiliateProfileData = (state = intialState, action: actions) => {
    switch (action.type) {
      case GET_AFFILIATE_PROFILE[SUCCESS]:
        return { ...state, data: action.payload?.data, isLoading: false };
      case GET_AFFILIATE_PROFILE[REQUEST]:
        return action?.payload?.data
          ? { ...state, data: action.payload?.data, isLoading: true }
          : intialState;
      case GET_AFFILIATE_PROFILE[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case GET_AFFILIATE_PROFILE[RESET]:
        return intialState;
      default:
        return state;
    }
  };

  const postAffiliateAddress = (
    state = { ...intialState, isLoading: false },
    action: actions
  ) => {
    switch (action.type) {
      case POST_AFFILIATE_ADDRESS[SUCCESS]:
        toast.success(t("Updated successfuly"));
        return { ...state, data: action.payload, isLoading: false };
      case POST_AFFILIATE_ADDRESS[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload, isLoading: true }
          : intialState;
      case POST_AFFILIATE_ADDRESS[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  const notificationPreference = (
    state = { ...intialState, isLoading: false },
    action: actions
  ) => {
    switch (action.type) {
      case GOAFFPRO_NOTIFICATION_PREFERENCE[SUCCESS]:
        toast.success(t("Updated successfuly"));
        return { ...state, data: action.payload, isLoading: false };
      case GOAFFPRO_NOTIFICATION_PREFERENCE[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload, isLoading: true }
          : intialState;
      case GOAFFPRO_NOTIFICATION_PREFERENCE[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case GOAFFPRO_NOTIFICATION_PREFERENCE[RESET]:
        return { ...intialState, isLoading: false };
      default:
        return state;
    }
  };

  return combineReducers({
    affiliateProfileData,
    postAffiliateAddress,
    notificationPreference,
  });
};

export default entity;

export const affiliateProfile = (state: RootState) =>
  state.entities.affiliateProfile;
