import * as Yup from "yup";

export interface ProfileFormValues {
  first_name: string;
  last_name: string;
  phone: string | null; 
  accepts_marketing: boolean;
  [key: string]: string | boolean | null;
}

export const initialValues: ProfileFormValues = {
  first_name: "",
  last_name: "",
  phone: "",
  accepts_marketing: false,
};
export const profileSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "First name must contain only alphabets")
    .required("*Please enter the first name"),
  last_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Last name must contain only alphabets")
    .required("*Please enter the last name"),
  phone: Yup.string()
    .matches(
      /^\+\d{1,3}\d{9}$/,
      "Phone number must be in the format '+<country code><10-digit number>'"
    )
    .nullable(),
  accepts_marketing: Yup.boolean(),
});
