import React from "react";

const MediaPiinterest = ({ fill = "#D5D5D5", width = "50", height = "50" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 2C5.582 2 2 5.582 2 10C2 13.3893 4.10867 16.284 7.08467 17.4493C7.01467 16.8167 6.95133 15.8453 7.11267 15.1547C7.258 14.5307 8.05067 11.178 8.05067 11.178C8.05067 11.178 7.81133 10.6987 7.81133 9.99067C7.81133 8.878 8.456 8.048 9.25867 8.048C9.94133 8.048 10.2707 8.56067 10.2707 9.17467C10.2707 9.86133 9.834 10.8873 9.608 11.838C9.41933 12.6347 10.0073 13.284 10.7927 13.284C12.2147 13.284 13.3073 11.7847 13.3073 9.62067C13.3073 7.70533 11.9313 6.366 9.966 6.366C7.69 6.366 6.354 8.07333 6.354 9.838C6.354 10.5253 6.61867 11.2627 6.94933 11.664C7.01467 11.7433 7.024 11.8127 7.00467 11.8933C6.944 12.146 6.80933 12.6893 6.78267 12.8007C6.748 12.9473 6.66667 12.978 6.51533 12.9073C5.516 12.442 4.89133 10.9813 4.89133 9.808C4.89133 7.28467 6.72467 4.96667 10.1773 4.96667C12.9527 4.96667 15.1093 6.944 15.1093 9.58733C15.1093 12.3447 13.3707 14.5633 10.958 14.5633C10.1473 14.5633 9.38533 14.142 9.12467 13.6447C9.12467 13.6447 8.72333 15.172 8.626 15.5467C8.44533 16.2413 7.958 17.1127 7.63133 17.644C8.38 17.8747 9.17533 18 10 18C14.418 18 18 14.418 18 10C18 5.582 14.418 2 10 2Z"
      fill="black"
    />
  </svg>
);

export default MediaPiinterest;
