import { DELETE_HISTORY } from './../actions/actiontypes';
import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
import { FAVORITE_UNFAVORITE, GET_PRODUCT_LINK, SAVE_PRODUCT_LINK } from "../actions/actiontypes";
import { toast } from 'react-toastify';
import { t } from 'i18next';
const { REQUEST, GET_MEDIA_ASSETS } = actionTypes;

//write sagas function

function* mediaAssetsSagas(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getMediaAssets,
      data.data
    );
    yield sendPayload(apiResponse, GET_MEDIA_ASSETS);
  } catch (e) {
    yield sendPayloadFailure(e, GET_MEDIA_ASSETS);
  }
}

function* getProductLinkSagas() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getProductLink
    );
    yield sendPayload(apiResponse, GET_PRODUCT_LINK);
  } catch (e) {
    yield sendPayloadFailure(e, GET_PRODUCT_LINK);
  }
}

function* saveProductLinkSagas(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.saveProductLink,
      data.data
    );
    yield sendPayload(apiResponse, SAVE_PRODUCT_LINK);
  } catch (e) {
    yield sendPayloadFailure(e, SAVE_PRODUCT_LINK);
  }
}

function* favoriteProductSagas(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.favoriteUnfavorite,
      data.data
    );
    if (data.data.data.status) {
      toast.success(t("Product successfully added to favorite"))
    } else {
      toast.success(t("Product successfully removed from favorite"))
    }
    yield sendPayload(apiResponse, FAVORITE_UNFAVORITE);
  } catch (e) {
    yield sendPayloadFailure(e, FAVORITE_UNFAVORITE);
  }
}

function* deleteProductSagas(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.deleteProductHistory,
      data.data
    );
    toast.success(t("Successfully deleted the product from history"))
    yield sendPayload(apiResponse, DELETE_HISTORY);
  } catch (e) {
    toast.error(t("Something went wrong while deleting the product from history"))
    yield sendPayloadFailure(e, DELETE_HISTORY);
  }
}

export const sagas = {
  watchMediaAssets: takeLatest(
    actionTypes.GET_MEDIA_ASSETS[REQUEST],
    mediaAssetsSagas
  ),
  watchGetProductLink: takeLatest(
    actionTypes.GET_PRODUCT_LINK[REQUEST],
    getProductLinkSagas
  ),
  watchSaveProductLink: takeLatest(
    actionTypes.SAVE_PRODUCT_LINK[REQUEST],
    saveProductLinkSagas
  ),
  watchFavoriteProduct: takeLatest(
    actionTypes.FAVORITE_UNFAVORITE[REQUEST],
    favoriteProductSagas
  ),
  watchDeleteProduct: takeLatest(
    actionTypes.DELETE_HISTORY[REQUEST],
    deleteProductSagas
  ),
};
