
import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, GOAFFPRO_ANALYTICS } = actionTypes;

function* goaffproAnalyticsSagas(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getGoaffproAnalytics,
      data.data
    );
    yield sendPayload(apiResponse, GOAFFPRO_ANALYTICS);
  } catch (e) {
    yield sendPayloadFailure(e, GOAFFPRO_ANALYTICS);
  }
}

export const sagas = {
  watchGoaffproAnalytics: takeLatest(
    actionTypes.GOAFFPRO_ANALYTICS[REQUEST],
    goaffproAnalyticsSagas
  ),
};
