import React, { useMemo, useState } from "react";
import MediaFacebookIcon from "../../utils/icons/MediaFacebookIcon";
import MediaTwitterIcon from "../../utils/icons/MediaTwitterIcon";
import MediaPinterestIcon from "../../utils/icons/MediaPinterestIcon";
import MediaWhatsappIcon from "../../utils/icons/MediaWhatsappIcon";
import MediaMailIcon from "../../utils/icons/MediaMailIcon";
import MediaLinkedInIcon from "../../utils/icons/MediaLinkedInIcon";
import { Box, Grid } from "@mui/material";
import { shareMediaIconModal } from "./style";

const FloatShareURL = ({ videoInfo, handleClose }: any) => {
  const media = useMemo(() => {
    const mediaList = [
      {
        icon: <MediaFacebookIcon />,
        name: "Facebook",
        url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          videoInfo?.webUrl
        )}`,
      },
      {
        icon: <MediaTwitterIcon />,
        name: "Twitter",
        url: `https://twitter.com/intent/tweet?text=${videoInfo?.title} - ${
          videoInfo?.description
        }&url=${encodeURIComponent(videoInfo?.webUrl)}`,
      },
      {
        icon: <MediaLinkedInIcon />,
        name: "Linked In",
        url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          videoInfo?.webUrl
        )}&title=${videoInfo?.title}&summary=${videoInfo?.description}&source=`,
      },
      {
        icon: <MediaPinterestIcon />,
        name: "Pinterest",
        url: `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
          videoInfo?.webUrl
        )}&media=&description=${videoInfo?.title}`,
      },
      {
        icon: <MediaWhatsappIcon />,
        name: "Whatsapp",
        url: `https://wa.me/?text=${encodeURIComponent(videoInfo?.webUrl)}`,
      },
      {
        icon: <MediaMailIcon />,
        name: "Mail",
        url:
          "mailto:?&subject=Check out this Pyvit video!&body=%20%0d%0a" +
          encodeURIComponent(videoInfo?.webUrl),
      },
    ];
    return mediaList;
  }, [videoInfo]);

  return (
    <Grid container>
      {media?.map((item: any) => {
        return (
          <Grid
            item
            xs={2}
            md={3}
            lg={4}
            xl={4}
            onClick={() => {
              window.open(item?.url);
            }}
            sx={shareMediaIconModal.mediaIcon}
          >
            {item.icon}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FloatShareURL;
