import * as React from "react";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import { styles } from "./style";
import {
  StyledAccordion,
  StyledTableHeadCell,
  StyledTableHeadRow,
  StyledText,
} from "../../../utils/styledComponents";
import {
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import FloatModal from "../../../shared/components/FloatModal";
import TransactionOrdersModal from "./TransactionOrdersModal";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionData } from "../../../actions";
import { RootState } from "../../../configureStore";
import {
  convertDateFromServer,
  convertTimeFromServer,
} from "../../../utils/hooks";
import CustomPagination from "../../../shared/components/CustomPagination";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";
import { TransactionConstants } from "../../../utils/constants";
import TransactionDetailsModal from "./TransactionDetailsModal";
const LIMIT = 10;

interface Metadata {
  reward_data: string;
  reward_created: string;
  reward_type: string;
  new_status: string;
  new_amount: number;
  old_status: string;
  old_amount: number;
  order_number: string;
  order_value: string;
  commission_on: string;
  downline: Downline;
  old_affiliate_commission: number;
  new_affiliate_commission: number;
}

interface Downline {
  name: string;
  affiliate_id: number;
}

interface ITransaction {
  amount: string;
  currency: string;
  event_type: string;
  entity_type: string;
  entity_id: number;
  metadata: Metadata;
  is_paid: number;
  created_at: string;
  endingBalance: string;
  startingBalance: string;
}

export const StyledAccordionSummary = styled(AccordionSummary)({
  width: "100%",
  overflow: "auto",
  padding: "0px 16px 0px 0px",

  "& .MuiAccordionSummary-expandIconWrapper": {
    border: "1px solid",
    borderRadius: "4px",
    transition: "none",
    position: "absolute",
    top: "0",
    right: "0",
    margin: "5px",
  },
  "& .MuiSvgIcon-root": {
    webkitTransition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    transition: " fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "& .Mui-expanded": {
    overflow: "hidden",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "12px 0px 0px 0px"
  }
});

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

interface Customer {
  first_name: string;
  last_name: string;
  email: string;
}

interface Address {
  first_name: string;
  last_name: string;
  address1: string;
  address2?: string | null;
  company?: string | null;
  phone?: string | null;
  city: string;
  zip: string;
  province: string;
  country: string;
  latitude: number;
  longitude: number;
  name: string;
  country_code: string;
  province_code: string;
}

interface Shipping {
  address: Address;
  amount: string;
  currency: string;
}

export interface IOrder {
  order_number: string;
  order_total: string;
  commission: string;
  customer: Customer;
  shipping: Shipping;
  coupon_codes: string;
}

interface Info {
  order_id: string;
  date: string;
  amount: number;
  final_balance: number;
  entity_type: string;
  meta?: {
    order_number?: number;
    commission_on?: number;
    old_commission: number;
    new_commission: number;
    new_status: string;
    old_status: string;
    goaffpro_order_id: number;
    shopify_order_id: string;
    network_affiliate_name?: string;
  };
}

const headCells: readonly HeadCell[] = [
  {
    id: "Date",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "Transaction",
    numeric: false,
    disablePadding: false,
    label: "Transaction",
  },
  {
    id: "Amount",
    numeric: false,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "Final Balance",
    numeric: true,
    disablePadding: false,
    label: "Final Balance",
  },
];

function TransactionTableHead() {
  return (
    <TableHead>
      <StyledTableHeadRow>
        {headCells.map((headCell: HeadCell, index: number) => {
          {
            return (
              <StyledTableHeadCell
                key={index}
                align="left"
                id={headCell.id}
                scope="row"
              >
                {headCell.label}
              </StyledTableHeadCell>
            );
          }
        })}
      </StyledTableHeadRow>
    </TableHead>
  );
}

function CreatorTransactions() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const transactionData = useSelector(
    (state: RootState) => state?.entities?.transactionData.transactionData
  ) as any;

  const [openTransactionModal, setOpenTransactionModal] =
    useState<boolean>(false);
  const [openTransactionDetailsModal, setOpenTransactionDetailsModal] =
    useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<string>();
  const [entityId, setEntityid] = useState<number>();
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [transactionsData, setTransactionsData] = useState<ITransaction[]>()

  useEffect(() => {
    dispatch(getTransactionData.request({ limit: LIMIT, offset: offset }));
  }, []);

  useEffect(() => {
    setTransactionsData(transactionData?.data?.transactions)
    setTotalPages(Math.ceil(transactionData?.data?.count / LIMIT))
  }, [transactionData && transactionData?.isLoading]);

  const handlePageChange = (event: any, pageNumber: number) => {
    setCurrentPage(pageNumber);
    setOffset((currentPage - 1) * LIMIT)
    dispatch(getTransactionData.request({ limit: LIMIT, offset: ((pageNumber - 1) * LIMIT) }));
  };

  const handleOpenTransactionModal = (data: ITransaction) => {
    if (
      data &&
      data.entity_id
    ) {
      setEntityid(data.entity_id)
      setOpenTransactionModal(true);
      setSelectedTransaction(data.metadata.order_number);
    }
  };

  const handleCloseTransactionModal = () => {
    setOpenTransactionModal(false);
  };

  const handleOpenTransactionDetailsModal = (data: ITransaction) => {
    if (
      data &&
      data.entity_id
    ) {
      setEntityid(data.entity_id)
      setOpenTransactionDetailsModal(true);
    }
  };

  const handleCloseTransactionDetailsModal = () => {
    setOpenTransactionDetailsModal(false);
  };

  const handleAccordionClick = (eventType: string, entityType: string) => {
    if ((eventType === TransactionConstants.INSERT && entityType === TransactionConstants.REWARDS) ||
      (eventType === TransactionConstants.UPDATE && entityType === TransactionConstants.ORDERS))
      return true
    else
      return false
  }

  const handleColor = (amount: string) => {
    const value = Number(amount)
    if (value <= 0)
      return "red"
    else
      return "green"
  }

  return (
    <Box sx={styles.boxStyle}>
      {transactionData && transactionData.isLoading ? (
        <Box sx={styles.loaderBox}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {
            transactionsData && transactionsData.length > 0 ? (
              transactionsData.map((row: ITransaction, index: number) => {
                return (
                  <StyledAccordion key={index} isExpandedStyle={true}>
                    <StyledAccordionSummary
                      expandIcon={
                        isLg && handleAccordionClick(row.event_type, row.entity_type) ?
                          <ArrowDropDownIcon
                            fontSize="medium"
                            sx={styles.smallscreenBreakpoint}
                          /> :
                          null
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Grid container>
                        <Grid item xs={10} md={3.9} lg={2.4}>
                          <StyledText
                            fontvalue="16px"
                            fontWeight="bold"
                            letterSpacing="0.15"
                            lineheight="24px"
                          >
                            {t("Date")}
                          </StyledText>
                          <StyledText fontvalue="16px" lineheight="21px">
                            {row.created_at
                              ? `${convertDateFromServer(
                                row.created_at
                              )} ${convertTimeFromServer(row.created_at)}`
                              : "-"}
                          </StyledText>
                        </Grid>
                        <Grid item xs={10} md={4.7} lg={3.2}>
                          <StyledText
                            fontvalue="16px"
                            fontWeight="bold"
                            letterSpacing="0.15"
                            lineheight="24px"
                          >
                            {t("Transaction")}
                          </StyledText>

                          <Box>
                            {
                              row.entity_type === TransactionConstants.PAYOUT &&
                              <StyledText fontvalue="16px" lineheight="20px">
                                {t("Payout")}
                              </StyledText>
                            }

                            {
                              (row.event_type === TransactionConstants.INSERT && row.entity_type === TransactionConstants.ORDERS) &&
                              <StyledText fontvalue="16px" lineheight="20px" color="#4158E3" onClick={() => handleOpenTransactionModal(row)}>
                                {row.metadata.order_number}
                              </StyledText>
                            }

                            {
                              (row.event_type === TransactionConstants.UPDATE && row.entity_type === TransactionConstants.REWARDS) &&
                              <Box
                                sx={{
                                  display: "flex",
                                  columnGap: "5px"
                                }}
                                onClick={() => handleOpenTransactionDetailsModal(row)}
                              >
                                <StyledText fontvalue="16px" lineheight="20px" color="#ff6000">
                                  {t("Network commission")}
                                </StyledText>
                                <StyledText fontvalue="16px" lineheight="20px" color="#ff6000">
                                  {row.metadata.order_number}
                                </StyledText>
                              </Box>
                            }

                            {
                              (row.event_type === TransactionConstants.INSERT && row.entity_type === TransactionConstants.REWARDS) && (
                                <StyledText fontvalue="16px" lineheight="20px">
                                  {t("Network commission")}
                                </StyledText>
                              )
                            }

                            {
                              (row.event_type === TransactionConstants.UPDATE && row.entity_type === TransactionConstants.ORDERS) &&
                              <StyledText fontvalue="16px" lineheight="20px" color="#ff6000" onClick={() => handleOpenTransactionDetailsModal(row)}>
                                {row.metadata.order_number}
                              </StyledText>
                            }

                            <Box
                              sx={styles.largescreenBreakpoint}
                            >
                              {
                                (row.event_type === TransactionConstants.INSERT && row.entity_type === TransactionConstants.REWARDS) &&
                                <>
                                  <StyledText>
                                    {row.metadata.downline.name}
                                  </StyledText>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      columnGap: "5px"
                                    }}
                                  >
                                    <StyledText>
                                      {row.metadata.order_number}
                                    </StyledText>
                                    <StyledText>
                                      (${row.metadata.commission_on ? row.metadata.commission_on : 0})
                                    </StyledText>
                                  </Box>
                                </>
                              }
                              {
                                (row.event_type === TransactionConstants.UPDATE && row.entity_type === TransactionConstants.ORDERS) &&
                                <StyledText
                                  fontvalue="16px"
                                  lineheight="18px"
                                  sx={styles.transactionText}
                                >
                                  {"Commission"}: {row.metadata?.old_affiliate_commission}
                                  {" - "}
                                  {row.metadata?.new_affiliate_commission}
                                </StyledText>
                              }
                            </Box>

                          </Box>

                        </Grid>
                        <Grid item xs={10} md={3.9} lg={2.4}>
                          <StyledText
                            fontvalue="16px"
                            fontWeight="bold"
                            letterSpacing="0.15"
                            lineheight="24px"
                          >
                            {t("Status")}
                          </StyledText>
                          {row.metadata?.new_status && row.metadata.old_status ? (
                            <>
                              {row.metadata?.new_status === "approved" &&
                                row.metadata?.old_status === "approved" ? (
                                <StyledText
                                  fontvalue="14px"
                                  lineheight="16px"
                                  sx={styles.transactionText}
                                >
                                  <StyledText
                                    fontvalue="14px"
                                    lineheight="16px"
                                    color="green"
                                  >
                                    {" "}
                                    {t("Approved")}{" "}
                                  </StyledText>
                                </StyledText>
                              ) : row.metadata?.new_status === "rejected" &&
                                row.metadata?.old_status === "rejected" ? (
                                <StyledText
                                  fontvalue="14px"
                                  lineheight="16px"
                                  sx={styles.transactionText}
                                >
                                  <StyledText
                                    fontvalue="14px"
                                    lineheight="16px"
                                    color="red"
                                  >
                                    {" "}
                                    {t("Rejected")}{" "}
                                  </StyledText>
                                </StyledText>
                              ) : row.metadata?.new_status === "rejected" ? (
                                <StyledText
                                  fontvalue="14px"
                                  lineheight="16px"
                                  sx={styles.transactionText}
                                >
                                  <StyledText
                                    fontvalue="14px"
                                    lineheight="16px"
                                    color="green"
                                  >
                                    {" "}
                                    {t("Approved")}{" "}
                                  </StyledText>
                                  -
                                  <StyledText
                                    fontvalue="14px"
                                    lineheight="16px"
                                    color="red"
                                  >
                                    {t("Rejected")}{" "}
                                  </StyledText>
                                </StyledText>
                              ) : (
                                <StyledText
                                  fontvalue="14px"
                                  lineheight="16px"
                                  sx={styles.transactionText}
                                >
                                  <StyledText
                                    fontvalue="14px"
                                    lineheight="16px"
                                    color="red"
                                  >
                                    {" "}
                                    {t("Rejected")}{" "}
                                  </StyledText>
                                  -
                                  <StyledText
                                    fontvalue="14px"
                                    lineheight="16px"
                                    color="green"
                                  >
                                    {t("Approved")}{" "}
                                  </StyledText>
                                </StyledText>
                              )}
                            </>
                          ) : (
                            "-"
                          )}
                        </Grid>
                        <Grid item xs={10} md={3.5} lg={2}>
                          <StyledText
                            fontvalue="16px"
                            fontWeight="bold"
                            letterSpacing="0.15"
                            lineheight="24px"
                          >
                            {t("Amount")}
                          </StyledText>
                          <StyledText fontvalue="16px" lineheight="21px" color={handleColor(row.amount)}>
                            $
                            {row.amount
                              ? Number(row.amount).toFixed(2)
                              : "-"}
                          </StyledText>
                        </Grid>
                        <Grid item xs={10} md={3.5} lg={2}>
                          <StyledText
                            fontvalue="16px"
                            fontWeight="bold"
                            letterSpacing="0.15"
                            lineheight="24px"
                          >
                            {t("Final balance")}
                          </StyledText>
                          <StyledText fontvalue="16px" lineheight="21px">
                            $
                            {row.endingBalance
                              ? Number(row.endingBalance).toFixed(2)
                              : "-"}
                          </StyledText>
                        </Grid>
                      </Grid>
                    </StyledAccordionSummary>
                    <AccordionDetails
                      sx={styles.accordionStyles}
                    >
                      <Grid container>
                        <Grid item xs={10} md={3.9} lg={2.4}></Grid>
                        <Grid item xs={10} md={3.9} lg={2.4}>
                          <Box
                            sx={styles.smallscreenBreakpoint}
                          >

                            {
                              (row.event_type === TransactionConstants.INSERT && row.entity_type === TransactionConstants.REWARDS) &&
                              <>
                                <StyledText>
                                  {row.metadata.downline.name}
                                </StyledText>
                                <Box
                                  sx={{
                                    display: "flex",
                                    columnGap: "5px"
                                  }}
                                >
                                  <StyledText>
                                    {row.metadata.order_number}
                                  </StyledText>
                                  <StyledText>
                                    (${row.metadata.commission_on ? row.metadata.commission_on : 0})
                                  </StyledText>
                                </Box>
                              </>
                            }

                            {
                              (row.event_type === TransactionConstants.UPDATE && row.entity_type === TransactionConstants.ORDERS) &&
                              <StyledText
                                fontvalue="16px"
                                lineheight="18px"
                                sx={styles.transactionText}
                              >
                                {"Commission"}: {row.metadata?.old_affiliate_commission}
                                {" - "}
                                {row.metadata?.new_affiliate_commission}
                              </StyledText>
                            }

                          </Box>
                        </Grid>
                        <Grid item xs={10} md={3.9} lg={2.4}></Grid>
                        <Grid item xs={10} md={3.9} lg={2.4}></Grid>
                        <Grid item xs={10} md={3.9} lg={2.4}></Grid>
                      </Grid>
                    </AccordionDetails>
                  </StyledAccordion>
                );
              })
            ) : (
              <Box
                sx={styles.boxStyle}
              >
                <StyledText fontvalue="16px" lineheight="21px">
                  {t("No records found.")}
                </StyledText>
              </Box>
            )}
        </Box>
      )}

      {entityId && selectedTransaction && (
        <FloatModal
          open={openTransactionModal}
          onClose={handleCloseTransactionModal}
          modalTitle={selectedTransaction.toString()}
          modalBody={
            <TransactionOrdersModal
              entityId={entityId}
            />
          }
        />
      )}

      {entityId && (
        <FloatModal
          open={openTransactionDetailsModal}
          onClose={handleCloseTransactionDetailsModal}
          modalTitle={t("Event log")}
          isPadding={false}
          modalBody={
            <TransactionDetailsModal
              entityId={entityId}
            />
          }
        />
      )}

      {currentPage > 0 && totalPages > 0 && (
        <CustomPagination
          page={currentPage}
          count={totalPages}
          handlePageChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default CreatorTransactions;
