import React from "react";

const MailIcon = ({
  fill = "#D5D5D5",
  width = "1.253rem",
  height = "0.933rem",
}) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.60156 17.994C3.60156 9.96222 10.0052 3.58383 18.0543 3.60004C26.0101 3.61625 32.3894 9.97842 32.4016 17.9048C32.4137 25.9852 26.0588 32.4 18.0461 32.4C9.98085 32.4 3.60156 26.0379 3.60156 17.994Z"
      fill="#010101"
    />
    <path
      d="M26.3995 16.4095C26.3995 18.4924 26.359 20.2227 26.4117 21.9531C26.4563 23.3755 25.8889 24.0279 24.4217 24.0157C20.158 23.9793 15.8944 23.9752 11.6348 24.0157C10.2041 24.0279 9.54347 23.4727 9.58805 22.0139C9.63668 20.2308 9.60021 18.4438 9.60021 16.4176C11.955 17.5806 14.1719 18.5896 16.2997 19.7608C17.471 20.4091 18.4396 20.4618 19.6271 19.8094C21.7589 18.6342 23.9759 17.6171 26.4036 16.4135L26.3995 16.4095Z"
      fill="white"
    />
    <path
      d="M18.0319 12.0005C20.3583 12.0005 22.6847 11.9802 25.007 12.0208C25.4609 12.0289 26.1378 11.6682 26.3566 12.3936C26.5431 13.0176 26.2958 13.6376 25.7203 13.9335C23.3129 15.1816 20.8974 16.4135 18.4453 17.5644C17.6996 17.9129 10.0437 14.0915 9.73563 13.2972C9.40735 12.4544 9.53704 11.9559 10.6556 11.9843C13.1117 12.0491 15.5718 12.0045 18.0319 12.0045V12.0005Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default React.memo(MailIcon);
