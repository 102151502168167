export const styles = {
  signupContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    height: "250px",
  },
  signinContainer: {
    alignItems: "center",
    height: "100%",
    direction: "row",
    justifyContent: "center",
  },
  signinBtnOuter: {
    display: "flex",
    padding: "0 10px 10px",
    borderRadius: "5px",
    border: "1px solid #333",
  },
};
