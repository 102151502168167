import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../shared/Loading";
import { RootState } from "../../../configureStore";
import {
  getAccountProfile,
  getUserPurchaseAlert,
  putAccountUserData,
} from "../../../actions";
import { FormLabel, StyledText } from "../../../utils/styledComponents";
import { ProfileFormValues } from "../Profile/profileSchema";
import { initialValues, profileSchema } from "./profileSchema";
import { styles } from "./style";

function Profile() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>("...");
  const [modified, setModified] = useState<boolean>(false);

  const { accountProfileData, putUserDataResponse } = useSelector(
    (state: RootState) => state?.entities?.accountProfile
  );
  const { puchaseAlertResponse } = useSelector(
    (state: RootState) => state?.entities?.purchaseAlert
  );

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setValues,
    dirty,
  } = useFormik<ProfileFormValues>({
    initialValues: initialValues,
    validationSchema: profileSchema,
    onSubmit: () => {
      dispatch(putAccountUserData.request({ data: { ...values } }));
    },
  });

  const profileFields = useMemo(
    () => [
      {
        label: "First name",
        id: "first_name",
        name: "first_name",
        value: values.first_name,
        error: touched?.first_name && !!errors?.first_name,
        helperText: touched?.first_name && errors?.first_name,
        type: "text",
      },
      {
        label: "Last name",
        id: "last_name",
        name: "last_name",
        value: values.last_name,
        error: touched?.last_name && !!errors?.last_name,
        helperText: touched?.last_name && errors?.last_name,
        type: "text",
      },
      {
        label: "Phone number",
        id: "phone",
        name: "phone",
        value: values.phone,
        error: touched?.phone && !!errors?.phone,
        helperText: touched?.phone && errors?.phone,
        type: "text",
      },
    ],
    [values, errors, touched]
  );

  useEffect(() => {
    if (accountProfileData?.data && !accountProfileData.isLoading) {
      const { email, first_name, last_name, phone, accepts_marketing } =
        accountProfileData?.data;
      setValues({ first_name, last_name, phone, accepts_marketing });
      setEmail(email);
    }
  }, [accountProfileData]);

  useEffect(() => {
    dispatch(getAccountProfile.request({}));
    dispatch(getUserPurchaseAlert.request({}));
  }, []);

  return (
    <Box>
      {puchaseAlertResponse?.data?.subscriptionProcessingAlert?.length > 0
        ? puchaseAlertResponse?.data?.subscriptionProcessingAlert?.map(
          (message: string) => {
            return (
              <Box sx={styles.alertBox} >
                <StyledText fontvalue="18px" lineheight="20px">
                  {message}
                </StyledText>
              </Box>
            );
          }
        )
        : null}
      {accountProfileData?.data && !accountProfileData?.isLoading ? (
        <Box>
          <Stack sx={styles.fieldContain}>
            <FormLabel>{t("Email")}</FormLabel>
            <StyledText fontvalue="16px" sx={styles.field}>
              {email}
            </StyledText>
          </Stack>
          {profileFields.map((item, index) => {
            return (
              <Stack key={index} sx={styles.fieldContain}>
                <FormLabel>
                  {t(item.label)}
                  {(index === 0 || index === 1) && (
                    <span style={{ color: "#FF0000" }}>*</span>
                  )}
                </FormLabel>
                <TextField
                  size="small"
                  sx={styles.field}
                  id={item.id}
                  name={item.name}
                  onChange={(e) => {
                    handleChange(e);
                    setModified(true);
                  }}
                  value={values[item.name]}
                  error={item.error}
                  helperText={
                    typeof item.helperText === "string"
                      ? t(item.helperText)
                      : undefined
                  }
                  type={item.type}
                ></TextField>
              </Stack>
            );
          })}
          <Box>
            <Stack sx={styles.checkOuter}>
              <Checkbox
                id="accepts_marketing"
                name="accepts_marketing"
                checked={values.accepts_marketing}
                onChange={(e) => {
                  handleChange(e);
                  setModified(true);
                }}
                sx={styles.checkboxStyle}
              />
              <StyledText sx={styles.checkText}>
                {t("Accepts marketing from PYVIT")}
              </StyledText>
            </Stack>
            {touched?.accepts_marketing && errors?.accepts_marketing && (
              <StyledText sx={styles.checkError}>
                {errors.accepts_marketing}
              </StyledText>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: isSmallScreen ? "center" : "left",
            }}
          >
            <Button
              type="submit"
              onClick={() => handleSubmit()}
              sx={styles.saveBtn}
              variant="contained"
              disabled={!dirty || !modified}
            >
              {putUserDataResponse?.isLoading && (
                <CircularProgress size="12px" sx={styles.loaderStyle} />
              )}
              {t("Save")}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box height={"400px"}>
          <Loading />
        </Box>
      )}
    </Box>
  );
};

export default Profile;
