import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, GRAB_VIDEO_ANALYTICS } = actionTypes;

//write sagas function

function* analyticsVideoSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getVideoAnalyticsDataById,
      data.data
    );
    yield sendPayload(apiResponse, GRAB_VIDEO_ANALYTICS);
  } catch (e) {
    yield sendPayloadFailure(e, GRAB_VIDEO_ANALYTICS);
  }
}

export const sagas = {
  watchAnalyticsVideoSaga: takeLatest(
    actionTypes.GRAB_VIDEO_ANALYTICS[REQUEST],
    analyticsVideoSaga
  ),
};
