import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Tooltip,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteProductCall, showUserProducts } from "../../../actions";
import { RootState } from "../../../configureStore";
import { muiStyle } from "./style";
import Loading from "../../../shared/Loading";
import AddPyvitProductModel from "../../../shared/popupmodals/AddPyvitProductModel";
import FloatModal from "../../../shared/components/FloatModal";
import FloatAddProductModal from "../../../shared/components/addProducts/FloatAddProduct";
import BackDropLoader from "../../../shared/BackdropLoader";
import { Info, StyledText } from "../../../utils/styledComponents";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import FloatAlert from "../../../shared/components/FloatAlert";

function Products() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { grabProducts }: any = useSelector(
    (state: RootState) => state?.entities?.grabProducts
  );

  const { deleteProductData }: any = useSelector(
    (state: RootState) => state?.entities?.deleteProduct
  );
  const [deleteProgress, setDeleteProgress] = useState(false);

  const [products, setProducts] = useState([]);
  const [addPyvitProduct, setPyvitProduct] = useState(false);
  const [openCUstomProduct, setCustomProduct] = useState(false);
  const [productModalData, setProductModalData]: any = useState();
  const [deleteProductItem, setDeleteProduct]: any = useState({});
  const [openAlert, setAlert] = useState(false);

  useEffect(() => {
    console.log("user prods");
    dispatch(showUserProducts.request({}));
  }, []);

  useEffect(() => {
    if (Array.isArray(grabProducts?.data)) {
      setProducts(grabProducts.data);
    } else {
      setProducts([]);
    }
  }, [grabProducts?.data]);

  useEffect(() => {
    setDeleteProgress(deleteProductData?.isLoading);
  }, [deleteProductData?.isLoading]);

  const handleAddPyvitClick = () => {
    setPyvitProduct(true);
  };

  const handleAddCustomClick = () => {
    setCustomProduct(true);
  };

  const handlePyvitProductClose = () => {
    setPyvitProduct(false);
  };

  const handleCustomProductClose = () => {
    setProductModalData({});
    setCustomProduct(false);
  };

  const onDeleteProduct = () => {
    dispatch(deleteProductCall.request({ data: deleteProductItem }));
  };

  const openModalFromMenuCallback = useCallback(
    (product: any) => {
      setProductModalData(product);
      handleAddCustomClick();
    },
    [setProductModalData, setPyvitProduct]
  );

  return (
    <>
      <Box>
        <Box sx={muiStyle.btnContainer}>
          <Button
            onClick={handleAddPyvitClick}
            startIcon={<span>+</span>}
            sx={muiStyle.pyvitBtn}
            color="secondary"
            variant="contained"
          >
            {t("Add PYVIT product")}
          </Button>

          <Button
            onClick={() => {
              handleAddCustomClick();
              setProductModalData("");
            }}
            startIcon={<span>+</span>}
            color="secondary"
            variant="outlined"
            sx={muiStyle.outlinedBtn}
          >
            {t("Add custom product")}
          </Button>
        </Box>
        {!grabProducts?.isLoading && !grabProducts?.isError ? (
          <Box sx={muiStyle.productContainer}>
            {products?.length > 0 ? (
              <Grid container spacing={2}>
                {products.map((item: any, index) => (
                  <Grid key={index} item xs={6} sm={6} md={4} lg={3}>
                    <Paper
                      elevation={4}
                      sx={
                        isSmallScreen ? muiStyle.paperMobile2 : muiStyle.paper2
                      }
                    >
                      <Box sx={muiStyle.boxStyle}>
                        <img
                          className="product-transition"
                          style={{
                            width: "100%",
                            height: isSmallScreen ? "10rem" : "15rem",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                          src={item?.thumbnail_url}
                          alt={`Product ${index + 1}`}
                          onClick={() => openModalFromMenuCallback(item)}
                        />
                      </Box>
                      <Tooltip
                        title={item.title?.length > 55 ? item.title : ""}
                        placement="top"
                      >
                        <Box
                          sx={muiStyle.productBox}
                        >
                          <StyledText
                            sx={muiStyle.typographyStyle}
                          >
                            {item.title?.length > 55
                              ? `${item.title?.slice(0, 55)}...`
                              : item.title}
                          </StyledText>
                        </Box>
                      </Tooltip>
                      <Box
                        sx={
                          isSmallScreen
                            ? muiStyle.contentMobile
                            : muiStyle.content
                        }
                      >
                        <IconButton
                          aria-label="add to cart"
                          sx={
                            isSmallScreen ? muiStyle.cartMobile : muiStyle.cart
                          }
                          onClick={() => openModalFromMenuCallback(item)}
                        >
                          <EditIcon
                            fontSize="small"
                            color="primary"
                            sx={muiStyle.icon}
                          />
                          <Info>{t("Edit")}</Info>
                        </IconButton>
                        <IconButton
                          sx={
                            isSmallScreen
                              ? muiStyle.buyNowMobile
                              : muiStyle.buyNow
                          }
                          onClick={() => {
                            setDeleteProduct({item_id:item?.item_id});
                            setAlert(true);
                          }}
                        >
                          <DeleteIcon fontSize="small" sx={muiStyle.icon} />
                          <Info>{t("Delete")}</Info>
                        </IconButton>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            ) : (
              Array.isArray(grabProducts?.data) &&
              grabProducts?.data?.length === 0 && (
                <StyledText textAlign={"center"}>
                  {t("No records found.")}
                </StyledText>
              )
            )}
          </Box>
        ) : (
          grabProducts?.isLoading && (
            <Box sx={muiStyle.loader}>
              {" "}
              <Loading />
            </Box>
          )
        )}
      </Box>
      {addPyvitProduct && (
        <FloatModal
          open={addPyvitProduct}
          onClose={handlePyvitProductClose}
          modalTitle={t("Add PYVIT product")}
          modalBody={
            <AddPyvitProductModel
              handleClose={() => handlePyvitProductClose()}
            />
          }
        />
      )}
      <FloatAddProductModal
        open={openCUstomProduct}
        product={productModalData}
        onClose={handleCustomProductClose}
        productType="product"
      />
      <BackDropLoader open={deleteProgress} />

      {openAlert && (
        <FloatAlert
          open={openAlert}
          onClose={() => {
            setAlert(false);
          }}
          alert={t("Are you sure you want to delete this product?")}
          actionBtn="Submit"
          action={() => {
            onDeleteProduct();
            setDeleteProduct({});
            setAlert(false);
          }}
        />
      )}
    </>
  );
};

export default Products;
