import { Collapse, List, ListItemButton, ListItemText, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useDispatch, useSelector } from 'react-redux';
import { styles } from '../style';
import { RootState } from '../../configureStore';
import { RouteList } from '../RouteList';
import EwalletSignUp from '../../Pages/eWallet/signUp/SignUp';
import EwalletSignIn from '../../Pages/eWallet/signUp/EwalletSignIn';
import { openSendMoney } from '../../actions';
import { SIDENAV_SELECTEDROUTE_BG, SIDENAV_SELECTED_TEXT } from '../../colors';
import MainSubRoute from './MainSubRoute';
import { isArrayIncludesKey } from '../../utils/hooks';
import { StyledDivider } from '../../utils/styledComponents';

const mainRouteModals = ["eWallet"];
const MainList = ({accessibleRoute,setModelProps,setSubRouteName}:any) => {
  const currentPath = window.location.pathname;
  const {t} = useTranslation();
  const dispatch = useDispatch();

    const [openStates, setOpenStates] = useState(RouteList.map(() => false));

    const { globalModalState }: any = useSelector(
        (state: RootState) => state?.entities?.globalMoadal
      );
    
      const { userData } = useSelector(
        (state: RootState) => state?.entities?.userProfile
      );

      const handleClick = (index: number) => {
        const newOpenStates = Array(openStates.length).fill(false); 
        newOpenStates[index] = !openStates[index];
        setOpenStates(newOpenStates);
      };

      const onEwalletClick = () => {
        const access: any = userData?.data?.access;
        let eWallet = access?.find((item: any) => item.name === "eWallet");
    
        let signUpAccess = eWallet?.subRoute?.find(
          (sub: any) => sub.name === "Sign Up"
        );
    
        if (signUpAccess?.access) {
          setModelProps({
            name: t("eWallet Sign Up"),
            element: <EwalletSignUp />,
          });
          dispatch(openSendMoney());
        } else {
          setModelProps({
            name: t("eWallet sign in"),
            element: <EwalletSignIn />,
          });
          dispatch(openSendMoney());
        }
      };

    return (
      <Stack sx={styles.listContainer} className="overflow-y-contain">
        <List
          sx={{ ...styles.list }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <StyledDivider />
          {accessibleRoute?.routeList?.map((item: any, index: number) => {
            if (isArrayIncludesKey(mainRouteModals, item.name)) {
              return (
                <React.Fragment>
                  <ListItemButton
                    sx={
                      globalModalState?.isOpen
                        ? styles.listBtnSelected
                        : styles.listBtn
                    }
                    onClick={() => {
                      handleClick(index);
                      if (item.name === "eWallet") {
                        onEwalletClick();
                      }
                    }}
                  >
                    <ListItemText primary={t(item.name)} />
                  </ListItemButton>
                  <StyledDivider />
                </React.Fragment>
              );
            } else {
              return (
                item?.name?.length && (
                  <React.Fragment key={index}>
                    <ListItemButton
                      sx={
                        openStates[index] ||
                        currentPath?.startsWith(`/${item?.route}`)
                          ? {
                              ...styles.listBtn,
                              color: SIDENAV_SELECTED_TEXT,
                              backgroundColor: SIDENAV_SELECTEDROUTE_BG,
                            }
                          : styles.listBtn
                      }
                      onClick={() => handleClick(index)}
                    >
                      <ListItemText primary={t(item.name)} />
                      {openStates[index]
                        ? item.name !== "eWallet" &&
                          !currentPath?.startsWith(`/${item?.route}`) && (
                            <ExpandLess />
                          )
                        : item.name !== "eWallet" &&
                          !currentPath?.startsWith(`/${item?.route}`) && (
                            <ExpandMore />
                          )}

                      {currentPath?.startsWith(`/${item?.route}`) && (
                        <ArrowRightIcon fontSize="large" />
                      )}
                    </ListItemButton>
                    <Collapse
                      in={
                        openStates[index] ||
                        currentPath?.startsWith(`/${item?.route}`)
                      }
                      timeout="auto"
                      unmountOnExit
                    >
                      <MainSubRoute
                        item={item}
                        setSubRouteName={setSubRouteName}
                        setModelProps={setModelProps}
                      />
                    </Collapse>
                    <StyledDivider />
                  </React.Fragment>
                )
              );
            }
          })}
        </List>
      </Stack>
    );
};

export default MainList;