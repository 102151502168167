import { THIN_BORDER_COLOR } from "../../../colors";

export const orderHistoryStyle = {
  loaderBox: {
    display: "flex",
    justifyContent: "center",
    height: "500px",
    alignItems: "center",
  },
  tableCell: {
    border: "none",
    padding: "16px 30px",
    paddingLeft: "15px",
  },
  detailTableCell: {
    border: "none",
    padding: "16px 30px",
    paddingTop: "0px",
    paddingLeft: "15px",
  },
  reactivateButton: {
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    padding: "8px 27px !important",
    buttonText: {
      whiteSpace: "nowrap",
    },
  },
  unsubscribeButton: {
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    padding: "8px 20px !important",
    buttonText: {
      whiteSpace: "nowrap",
    },
    "&:hover": {
      background: "none !important",
      color: "#000 !important",
    },
  },
  buttontableCell: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    border: "none",
    padding: "16px 30px",
  },
  summaryDatatableCell: {
    border: "none",
    padding: "16px 30px",
    width: "52%",
  },
  summaryDetailsData: {
    textAlign: "right",
  },
  summaryData: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  summaryDataWrapper: {
    border: `1px solid ${THIN_BORDER_COLOR}`,
    padding: "12px 20px",
  },
  seeAllButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "15px",
  },
  seeAllButton: {
    textDecoration: "underline",
    cursor: "pointer",
    margin: "auto",
  },
  buttonBorder: { border: "1px solid black" },
  boxBorder: { border: `1px solid ${THIN_BORDER_COLOR}` },
  accordionStyle: {
    borderRadius: "0px",
    border: `1px solid ${THIN_BORDER_COLOR} !important`,
    borderRight: `1px solid ${THIN_BORDER_COLOR} !important`,
  },
  gridStyle: { padding: "10px 0" },
  dataStyle: { rowGap: "20px", columnGap: "20px" },
  accordionDetailsStyle: { display: "flex", padding: "0px 8px" },
  textStyle:{ textTransform: "capitalize" }
};
