import { HOVERPRIMARYCOLOUR, THEMEBACKGROUNDCOLOR } from "../../../../colors";

export const trafficAnalysisStyle = {
  outer:{ margin: "15px 0" },
  filterContain:{
    flexDirection:"row",
        justifyContent:"space-between",
        alignItems:"center"
  },
  tblContainer:{
    margin: "10px 0",
    overflowY: "auto",
    border: `1px solid ${THEMEBACKGROUNDCOLOR}`,
  },
  thRow:{ borderBottom: `1px solid ${THEMEBACKGROUNDCOLOR}` },
  trafficText:{ fontSize: "22px !important", fontWeight: "bold" },
    tableHead: {
      background: HOVERPRIMARYCOLOUR,
      height: "50px",
    },
    thCell: {
      padding: '12px !important',
      color: "#333",
    },
    box: { p: { xs: "20px 0", md: "20px 0px" } },
    tbCell: { p: 1.6,borderBottom:`1px solid ${THEMEBACKGROUNDCOLOR}`, color: "black" },
  };
