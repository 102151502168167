import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, GOAFFPRO_PAYMENTS } = actionTypes;

//write sagas function

function* getGoaffproPamentSagas(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getGoaffproPayments,
      data.data
    );
    yield sendPayload(apiResponse, GOAFFPRO_PAYMENTS);
  } catch (e) {
    yield sendPayloadFailure(e, GOAFFPRO_PAYMENTS);
  }
}

export const sagas = {
  watchGoaffproPayments: takeLatest(
    actionTypes.GOAFFPRO_PAYMENTS[REQUEST],
    getGoaffproPamentSagas
  ),
};
