export const styles = {
  fieldContain: { width: { xs: "100%", md: "100%", lg: "600px" } },
  label: { fontWeight: "500" },
  field: { color: "#969696", marginBottom: "8px" },
  saveBtn: {
    mt: "10px",
    width: "fit-content",
    color: "#fff",
    backgroundColor: "#333",
    textTransform: "none",
    "&:hover": { backgroundColor: "#444", color: "#fff" },
  },
  inputStyle: {
    color: "#333",
  },
  loaderStyle: { color: "#fff", mr: "2px" },
};
