import { Box, Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import { Info, StyledText } from "../../../utils/styledComponents";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { productTblStyle } from "./style";
import { useTranslation } from "react-i18next";

const CreatorsProductVendorMobile = ({ row }: any) => {
  const { item_details } = row;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const { rowPadding, rowValue, header, linkStyle, linkText, infoStyle } = productTblStyle;

  const RowPadding = ({ name }: any) => {
    return (
      <Box sx={rowPadding}>
        <StyledText sx={rowValue}>{name}</StyledText>
      </Box>
    );
  };

  return (
    <Stack>
      {item_details?.length > 0 ? <Stack alignItems={"center"}>
        {
          <Grid
            container
            className={`grid-container ${open ? "open" : "closed"}`}
          >
            <Grid item xs={6} sx={header}>
              {t("Product name")}
            </Grid>
            <Grid item xs={6} sx={header}>
              {t("Vendor name")}
            </Grid>
            {item_details?.map((product: any, index: number) => (
              <Grid container key={index}>
                <Grid item xs={6}>
                  <RowPadding name={product.product_name ?? "-"} />
                </Grid>
                <Grid item xs={6}>
                  <RowPadding name={product.vendor_name ?? "-"} />
                </Grid>
              </Grid>
            ))}
          </Grid>
        }
        <Stack sx={linkStyle} onClick={() => setOpen(!open)}>
          <StyledText sx={linkText}> View Product and Vendor List </StyledText>
          &nbsp;{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Stack>
      </Stack> : <Info sx={infoStyle}>{t("No products linked for this video")}</Info>}
    </Stack>
  );
};

export default CreatorsProductVendorMobile;
