/* eslint-disable */
import { Box, Button, Container, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { StyledText } from "../utils/styledComponents";
import { stylesGlobal } from "../utils/muiStyles";
import LinkedProducts from "../Pages/videoDetails/LinkedProducts/LinkedProducts";
import { useTranslation } from "react-i18next";

const Unauthorized: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container
      sx={{ padding: { xs: "0", md: "20px" }, maxWidth: "1700px !important" }}
    >
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          padding: "0 20px 0 20px",
        }}
      >
        <Box sx={stylesGlobal.alertBox}>
          <StyledText fontvalue="18px" lineheight="20px">
            {t("Unable to login, No worries, let's try logging in again!")}
          </StyledText>
        </Box>
      </Stack>
    </Container>
  );
};

export default Unauthorized;
