import React from "react";

const DeleteSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.0013 15.8333C5.0013 16.75 5.7513 17.5 6.66797 17.5H13.3346C14.2513 17.5 15.0013 16.75 15.0013 15.8333V5.83333H5.0013V15.8333ZM15.8346 3.33333H12.918L12.0846 2.5H7.91797L7.08464 3.33333H4.16797V5H15.8346V3.33333Z"
        fill="black"
        fill-opacity="0.56"
      />
    </svg>
  );
};

export default DeleteSvg;
