export const shareVideoStyle = {
    wrapper:
    {
        display: "flex", justifyContent: "center",
        subTitleStyle: { lineHeight: 1, marginBottom: "8px" },
        iconStyle: { cursor: "pointer", ":hover": { color: "#333" } },
        textField: {
            width: "100%",
            background: "#F2F2F2",
            border: "none",
        },
        textStyle: { lineHeight: 1, marginBottom: "8px" },
        stackMargin:{ mt: "20px" },
        box:{ display: "flex" }
    }
}