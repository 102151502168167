/* eslint-disable */
import { data } from "./../actions/Types";
import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
import { GET_TRANSACTION_DETAILS } from "../actions/actiontypes";
const { REQUEST, GET_TRANSACTION_DATA, GET_TRANSACTION_ORDER } = actionTypes;

//write sagas function

function* getTransactionDataSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getTransactionDataApi,
      data.data
    );
    yield sendPayload(apiResponse, GET_TRANSACTION_DATA);
  } catch (e) {
    yield sendPayloadFailure(e, GET_TRANSACTION_DATA);
  }
}

function* getTransactionOrderSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getTransactionOrderApi,
      data.data
    );
    yield sendPayload(apiResponse, GET_TRANSACTION_ORDER);
  } catch (e) {
    yield sendPayloadFailure(e, GET_TRANSACTION_ORDER);
  }
}

function* getTransactionDetailsSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getTransactionDetailsApi,
      data.data
    );
    yield sendPayload(apiResponse, GET_TRANSACTION_DETAILS);
  } catch (e) {
    yield sendPayloadFailure(e, GET_TRANSACTION_DETAILS);
  }
}

export const sagas = {
  watchTransactionData: takeLatest(
    actionTypes.GET_TRANSACTION_DATA[REQUEST],
    getTransactionDataSaga
  ),
  watchTransactionOrder: takeLatest(
    actionTypes.GET_TRANSACTION_ORDER[REQUEST],
    getTransactionOrderSaga
  ),
  watchTransactionDetails: takeLatest(
    actionTypes.GET_TRANSACTION_DETAILS[REQUEST],
    getTransactionDetailsSaga
  ),
};
