import { TableHead } from '@mui/material';
import React from 'react';
import { StyledTableHeadCell, StyledTableHeadRow } from '../../../utils/styledComponents';
import { headMobileCells, isVisibleCol } from './helper,';
import { useTranslation } from 'react-i18next';
import { CreatorLibVideoProps, VideosHeadCellProps } from '../../../models/backoffice.models';

function VideosTableHeadMobile(props: CreatorLibVideoProps) {
    const {t} = useTranslation();
    const { multilistInfo } = props;
    return (
      <TableHead>
        <StyledTableHeadRow
          sx={{ display: "flex"}}
        >
          {headMobileCells.map((headCell: VideosHeadCellProps, index: number) => {
            if (
              headCell.label === "Video" ||
              isVisibleCol(multilistInfo,headCell.label,t)
            ) {
              return (
                <StyledTableHeadCell
                  key={index}
                  align={headCell.align}
                  id={headCell.id}
                  scope="row"
                  sx={{width:"4rem"}}
                >
                  {t(headCell.label)}
                </StyledTableHeadCell>
              );
            }
            return null;
          })}
        </StyledTableHeadRow>
      </TableHead>
    );
  }

  export default VideosTableHeadMobile