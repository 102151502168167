import React from "react";

const FaceBookIcon = ({ fill = "#D5D5D5", width = "50", height = "50" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0016 3.60001C10.0492 3.60001 3.60156 10.0476 3.60156 18C3.60156 25.9524 10.0492 32.4 18.0016 32.4C25.954 32.4 32.4016 25.9524 32.4016 18C32.4016 10.0476 25.954 3.60001 18.0016 3.60001ZM23.0188 13.2H20.944C19.6528 13.2 19.2016 13.8816 19.2016 15.2616V16.8H22.9864L22.4728 20.4H19.2016V29.94C18.8068 29.9784 18.4072 30 18.0016 30C17.1796 30 16.3768 29.916 15.6016 29.7588V20.4H12.0016V16.8H15.6016V14.8668C15.6016 11.2068 17.3848 9.60001 20.4268 9.60001C21.8836 9.60001 22.654 9.70801 23.0188 9.75721V13.2Z"
      fill="black"
    />
  </svg>
);

export default React.memo(FaceBookIcon);
