import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Info, StyledText } from "../../../utils/styledComponents";
import FloatSearch from "../../../shared/components/FloatSearch";
import { stylesGlobal } from "../../../utils/muiStyles";
import FloatModal from "../../../shared/components/FloatModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../configureStore";
import { filterInfluencerNetowrk } from "../../../actions";
import Orders from "./Orders";
import BackDropLoader from "../../../shared/BackdropLoader";
import { useTranslation } from "react-i18next";
import { networkExplorerStyle } from "./styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FloatDateSelector from "../../../shared/components/FloatDateSelector";

function NetworkExplorer(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDates, setSelectedDates]: any = useState(["", ""]);
  const [showDate, setShowDate] = useState("MM/DD/YYYY to MM/DD/YYYY");

  const { searchFilterStyle } = stylesGlobal;

  const [showNetworkDetails, setShowNetworkDetails] = useState(false);
  const [activeList, setActiveList] = useState(false);
  const [loadBackdrop, setBackdropLoader] = useState(false);
  const [sales, setSales] = useState("-");
  const [revenue, setRevenue] = useState("-");

  const [affiliateReferalsList, setAffiliateReferalsList] = useState<any[]>([]);
  const [filteredList, setFilteredReferalsList] = useState<any[]>([]);
  const [currentOrderDetails, setCurrentOrder]: any = useState();
  const [currentOrderuserName, setCurrentOrderuserName]: any = useState();
  const [searchVal,setSearchVal]= useState<string>("")

  const { influencerNetworkFilterData }: any = useSelector(
    (state: RootState) => state?.entities?.influencerNetwork
  );

  const balanceData = [
    {
      balance: sales,
      name: "Number of sales",
    },
    {
      balance: revenue,
      name: "Total sale volume",
    },
  ];

  const onSelectDate = () => {
    setOpenDatePicker(!openDatePicker);
  };

  const handleDateFilter = () => {
    setShowDate(
      `${selectedDates[0]?.format("MM/DD/YYYY")} to ${selectedDates[1]?.format(
        "MM/DD/YYYY"
      )}`
    );

    dispatch(
      filterInfluencerNetowrk.request({
        min: selectedDates[0]?.format("YYYY-MM-DD"),
        max: selectedDates[1]?.format("YYYY-MM-DD"),
      })
    );
    setOpenDatePicker(false);
  };

  useEffect(() => {
    const currentDate = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    
    dispatch(
      filterInfluencerNetowrk.request({
        min: formatPayloadDate(oneMonthAgo),
        max: formatPayloadDate(currentDate),
      })
    );

    setShowDate(
      `${formatInitialDate(oneMonthAgo)} to ${formatInitialDate(currentDate)}`
    );
  }, []);

  function formatPayloadDate(date:Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}
  
  function formatInitialDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${month}/${day}/${year}`;
  }

  useEffect(() => {
    setBackdropLoader(influencerNetworkFilterData?.isLoading);
    if (
      !influencerNetworkFilterData?.isLoading &&
      !influencerNetworkFilterData?.isError
    ) {
      setSales(influencerNetworkFilterData?.data?.sales ?? "-");
      setRevenue(
        influencerNetworkFilterData?.data?.revenue
          ? parseFloat(influencerNetworkFilterData?.data?.revenue)?.toFixed(
              2
            ) ?? "-"
          : "-"
      );
      let data = influencerNetworkFilterData?.data?.affiliates?.map(
        (item: any) => {
          return {
            image: item?.profile_photo?.url ?? "",
            isProfileImage: item?.profile_photo?.url?.length ? true : false,
            name: item.name,
            email: item.email,
            number: item.phone,
            orders: item.orders,
          };
        }
      );

      setAffiliateReferalsList(data);
      let active = data?.filter((item: any) => item.orders?.length > 0);
      activeList ? setFilteredReferalsList(active) : setFilteredReferalsList(data);
    }
  }, [
    influencerNetworkFilterData?.isLoading,
    influencerNetworkFilterData?.data?.sales,
  ]);

  const onActive = (e: any) => {
    setActiveList(e.target.checked);
    const active = affiliateReferalsList?.filter(
      (item: any) => item.orders?.length > 0
    );
    e.target.checked
      ? setFilteredReferalsList(active)
      : setFilteredReferalsList(affiliateReferalsList);
  };

  const handleSearch = (e: any) => {
      const active = affiliateReferalsList?.filter(
        (item: any) => item.orders?.length > 0
      );
      setSearchVal(e.target.value?.toLowerCase() ?? "");
      if (activeList) {
        const searchFilter = active?.filter((item: any) => {
          return item.name
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase());
        });
        setFilteredReferalsList(searchFilter);
      } else {
        const searchFilter = affiliateReferalsList?.filter((item: any) => {
          return item.name
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase());
        });
        setFilteredReferalsList(searchFilter);
      }
    };

  return (
    <>
      {loadBackdrop && <BackDropLoader open={loadBackdrop} />}
      {!influencerNetworkFilterData?.isError ? (
        <Box>
          <Box sx={networkExplorerStyle.wrapper}>
            <IconButton
              onClick={props.handleBack}
              sx={networkExplorerStyle.wrapper.IconButton}
            >
              <ArrowBackOutlinedIcon />
            </IconButton>
            <StyledText>{t("Network explorer")}</StyledText>
          </Box>
          <Grid sx={networkExplorerStyle.wrapper.gridStyle}>
            <Grid sx={networkExplorerStyle.wrapper.innerGrid}>
              {balanceData &&
                balanceData.map((item) => {
                  return (
                    <Grid sx={networkExplorerStyle.wrapper.datagrid}>
                      <StyledText
                        fontSize="30px"
                        lineheight="45px"
                        fontWeight="500"
                      >
                        {item.balance}
                      </StyledText>
                      <StyledText>{t(item.name)}</StyledText>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
          <StyledText>{t("Details")}</StyledText>
          <Box width={"100%"} display={"flex"} flexWrap={"wrap"}>
            <Box
              sx={searchFilterStyle.searchOuter}
              width={"250px"}
              paddingLeft={"0px !important"}
            >
              <FloatSearch
                handleSearch={handleSearch}
                placeHolder={t("Search by name")}
              />
            </Box>
            <Box display={"flex"} alignItems={"center"} width={"70%"}>
              <Checkbox checked={activeList} onChange={(e) => onActive(e)} />
              <StyledText>{t("Show active")}</StyledText>
            </Box>
          </Box>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              mb: "10px",
              mt: "10px",
            }}
          >
            <Button
              onClick={() => onSelectDate()}
              sx={{
                width: "250px",
                padding: "10px 6px",
                borderBottom: "2px solid #333",
                borderRadius: "0",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.10)",
              }}
            >
              <StyledText sx={{ textTransform: "none" }}>
                {showDate}{" "}
              </StyledText>
              &nbsp;
              <ArrowDropDownIcon />
            </Button>
          </Stack>
          <FloatDateSelector
            open={openDatePicker}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            handleDateFilter={handleDateFilter}
          />
          <Grid item xs={12} sx={networkExplorerStyle.wrapper.gridMargin}>
            {filteredList?.length ? (
              <Grid container spacing={2}>
                {filteredList.map((affiliate, index) => (
                  <Grid
                    onClick={() => {
                      setCurrentOrderuserName(affiliate?.name);
                      setCurrentOrder(affiliate?.orders);
                      affiliate?.orders?.length && setShowNetworkDetails(true);
                    }}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={index}
                    sx={affiliate?.orders?.length ? { cursor: "pointer" } : {}}
                  >
                    <Card sx={networkExplorerStyle.wrapper.cardStyle}>
                      <Card sx={networkExplorerStyle.wrapper.innerCard}>
                        {affiliate.isProfileImage ? (
                          <CardMedia
                            sx={networkExplorerStyle.wrapper.cardMedia}
                            component="img"
                            height="88px"
                            image={affiliate.image}
                          />
                        ) : (
                          <Box sx={networkExplorerStyle.wrapper.boxStyle}>
                            <Box sx={networkExplorerStyle.wrapper.boxFlex}>
                              <Avatar
                                sx={networkExplorerStyle.wrapper.avatarStyle}
                              >
                                {affiliate.name?.slice(0, 1)}
                              </Avatar>
                            </Box>
                          </Box>
                        )}
                        <CardContent
                          sx={networkExplorerStyle.wrapper.cardContent}
                        >
                          <StyledText>{affiliate.name}</StyledText>
                          <StyledText color={"#2196F3"}>
                            {affiliate.email}
                          </StyledText>
                          <StyledText>{affiliate.number}</StyledText>
                        </CardContent>
                      </Card>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box padding={"20px"}>
                <Info> {t("No referrals to show")} </Info>
              </Box>
            )}
          </Grid>
          <FloatModal
            modalTitle={currentOrderuserName ?? ""}
            onClose={() => setShowNetworkDetails(false)}
            modalBody={<Orders orders={currentOrderDetails} />}
            open={showNetworkDetails}
          />
        </Box>
      ) : influencerNetworkFilterData?.isError ? (
        <Info>{t("Something went wrong")}</Info>
      ) : null}
    </>
  );
}

export default NetworkExplorer;
