import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { centerButtonWrapper, detailsStyle } from "./style";
import { StyledText } from "../../../utils/styledComponents";
import { taxInitialValues, taxSchema } from "./settingsSchema";
import { addressDetailsStyle } from "../../eWallet/GlobalCard/style";
import { stylesGlobal } from "../../../utils/muiStyles";
import { addAffiliateAddress } from "../../../actions";
import { RootState } from "../../../configureStore";

function CreatorDetails() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { affiliateProfileData }: any = useSelector(
    (state: RootState) => state?.entities?.affiliateProfile
  );

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
  useFormik({
    initialValues: taxInitialValues,
    validationSchema: taxSchema,
    onSubmit: () => {
      dispatch(addAffiliateAddress.request(values));
    },
  });
  
  useEffect(() => {
    if (!affiliateProfileData?.isLoading && !affiliateProfileData?.isError) {
      setFieldValue(
        "tax_identification_number",
        affiliateProfileData?.data.tax_identification_number ?? ""
      );
    }
  }, [affiliateProfileData?.isLoading]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6} sx={detailsStyle.wrapper.heightStyle}>
          <Box sx={detailsStyle.wrapper}>
            <Box sx={{ ...detailsStyle.wrapper.item, minHeight: "190px" }}>
              <StyledText fontvalue="20px" lineheight="30px">
                {t("Referral Code")}
              </StyledText>
              <StyledText fontvalue="16px" lineheight="22px">
                {affiliateProfileData && affiliateProfileData?.data?.ref_code}
              </StyledText>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} sx={detailsStyle.wrapper.heightStyle}>
          <Box sx={detailsStyle.wrapper}>
            <Box sx={detailsStyle.wrapper.item}>
              <StyledText fontvalue="20px" lineheight="30px">
                {t("Tax identification number")}
                  {/* <T kind='Tax identification number' /> */}
              </StyledText>
              <StyledText>
                {t("EIN or SSN or PAN (whichever is applicable)")}
              </StyledText>
              <StyledText
                fontvalue="16px"
                lineheight="22px"
                sx={detailsStyle.wrapper.item.labelText}
              >
                {t("Tax identification number")}
              </StyledText>
              <TextField
                size="small"
                type={showPassword ? "text" : "password"}
                sx={addressDetailsStyle.wrapper.field}
                id="tax_identification_number"
                name="tax_identification_number"
                onChange={handleChange}
                value={values.tax_identification_number}
                error={
                  touched?.tax_identification_number &&
                  !!errors?.tax_identification_number
                }
                helperText={
                  touched?.tax_identification_number &&
                  errors?.tax_identification_number &&
                  t(errors?.tax_identification_number)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword((prev) => !prev);
                        }}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              <Box sx={centerButtonWrapper}>
                <Button
                  variant="contained"
                  disabled={values.tax_identification_number.length < 1}
                  sx={stylesGlobal.containedBtn}
                  onClick={() => handleSubmit()}
                >
                  {t("Submit")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CreatorDetails;
