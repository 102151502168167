import React from "react";

const BackIconSVG = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke="black" />
      <mask
        id="mask0_8152_66866"
        maskUnits="userSpaceOnUse"
        x="6"
        y="6"
        width="18"
        height="18"
      >
        <rect x="6" y="6" width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_8152_66866)">
        <path
          d="M11.5298 15.5625L15.8019 19.8346L15 20.625L9.375 15L15 9.375L15.8019 10.1654L11.5298 14.4375H20.625V15.5625H11.5298Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
};

export default BackIconSVG;
