import React from "react";
import {
  StyledButton,
  StyledModalTable,
  StyledModalTableCell,
  StyledText,
} from "../../../utils/styledComponents";
import { Box, TableBody, TableHead, TableRow } from "@mui/material";
import { buyProductAgainModalStyle, orderHistoryStyle } from "./style";
import { LocalMallIcon } from "../../../utils/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ILineItem, ILineItemsWithCurrency } from "../../../models/backoffice.models";


interface IProps {
  productData: ILineItemsWithCurrency;
}

function BuyAgainProduct({ productData }: IProps) {
  const { lineItems, currency } = productData;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box sx={buyProductAgainModalStyle.wrappper}>
      <StyledModalTable>
        <TableHead>
          <TableRow>
            <StyledModalTableCell sx={buyProductAgainModalStyle.productName}>
              {t("Product name")}
            </StyledModalTableCell>
            <StyledModalTableCell sx={buyProductAgainModalStyle.textBold}>
              {t("Price")}
            </StyledModalTableCell>
            <StyledModalTableCell
              sx={buyProductAgainModalStyle.textBold}
            ></StyledModalTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems?.map((product: ILineItem, index: number) => (
            <TableRow key={index}>
              <StyledModalTableCell>{product.name}</StyledModalTableCell>
              <StyledModalTableCell
                sx={buyProductAgainModalStyle.whiteSpace}
              >{`${currency} ${product.price}`}</StyledModalTableCell>
              <StyledModalTableCell>
                <StyledButton
                  paddingvalue="5px 20px"
                  colour="#FFF"
                  background="#262626"
                  sx={orderHistoryStyle.buyAgainButton}
                >
                  <img src={LocalMallIcon} />
                  <StyledText
                    color="#fff"
                    fontWeight="600"
                    fontvalue="13px"
                    lineheight="15px"
                    sx={orderHistoryStyle.buyAgainButton.buttonText}
                    onClick={() => window.open(product.landing_url, "_blank")}
                  >
                    {t("Buy now")}
                  </StyledText>
                </StyledButton>
              </StyledModalTableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledModalTable>
    </Box>
  );
};

export default BuyAgainProduct;
