import * as Yup from "yup";

export const initialValues = {
  first_name: "",
  last_name: "",
  address1: "",
  city: "",
  country: "",
  zip: "",
  phone: "",
  is_default: false,
};

export const addressShema = Yup.object().shape({
  first_name: Yup.string()
    .required("*Please enter the first name")
    .matches(/^[a-zA-Z]+$/, "First name must contain only alphabets")
    .max(20, "*First name is too long"),
  last_name: Yup.string()
    .required("*Please enter the last name")
    .matches(/^[a-zA-Z]+$/, "Second name must contain only alphabets")
    .max(20, "*Last name is too long"),
  address1: Yup.string()
    .required("*Please enter the address")
    .matches(
      /[^\d]/,
      "*Address must contain at least one non-numeric character"
    ),
  city: Yup.string()
    .required("*Please enter the city")
    .matches(/[^\d]/, "*City must contain at least one non-numeric character"),
  country: Yup.string().required("*Please enter the Country/Region"),
  zip: Yup.string().required("*Please enter the postal/zip code"),
  phone: Yup.number()
    .min(1000000000, "Phone number must be at least 10 digits")
    .max(9999999999,"Phone number must contain 10 digits")
    .nullable(),
  is_default: Yup.boolean(),
});
