import { Box, Pagination, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { Styles } from "./style";
import { StyledText } from "../../utils/styledComponents";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { THIN_BORDER_COLOR } from "../../colors";

const StyledPagination = styled(Pagination)({
  background: "#F0F0F0",
  "& .Mui-selected": {
    backgroundColor: "#FFF",
    border: `1px solid ${THIN_BORDER_COLOR}`,
    borderRadius: "4px",
  },
});

const CustomPagination = ({ page, count, handlePageChange }: any) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={Styles.paginationBox}>
      <Box sx={Styles.paginationBox.paginationContainer}>
        {<StyledText>{t("Previous")}</StyledText>}
        <StyledPagination
          page={page}
          count={count}
          shape="rounded"
          onChange={handlePageChange}
          size={isMobile ? "small" : "medium"}
          boundaryCount={1}
          siblingCount={0}
        />
        {<StyledText>{t("Next")}</StyledText>}
      </Box>
    </Box>
  );
};

export default CustomPagination;
