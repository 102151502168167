import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE, RESET } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";

const { SUCCESS, REQUEST, INFLUENCER_NETWORK, INFLUENCER_NETWORK_FILTER } =
  actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const influencerNetworkData = (state = intialState, action: actions) => {
    switch (action.type) {
      case INFLUENCER_NETWORK[SUCCESS]:
        return { ...state, data: action.payload.data, isLoading: false };
      case INFLUENCER_NETWORK[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload, isLoading: false }
          : state;
      case INFLUENCER_NETWORK[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  const influencerNetworkFilterData: any = (state = {}, action: actions) => {
    switch (action.type) {
      case INFLUENCER_NETWORK_FILTER[SUCCESS]:
        return { ...intialState, data: action.payload.data, isLoading: false };
      case INFLUENCER_NETWORK_FILTER[REQUEST]:
        return action?.payload
          ? { ...intialState, data: action.payload, isLoading: true }
          : { ...intialState, isLoading: true };
      case INFLUENCER_NETWORK_FILTER[FAILURE]:
        return {
          ...intialState,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case INFLUENCER_NETWORK_FILTER[RESET]:
        return intialState;
      default:
        return state;
    }
  };

  return combineReducers({
    influencerNetworkData,
    influencerNetworkFilterData,
  });
};

export default entity;

export const influencerNetwork = (state: RootState) =>
  state.entities.influencerNetwork;
