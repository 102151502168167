import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { grabVideoAnalytics } from "../../../../actions";
import { RootState } from "../../../../configureStore";
import { Box } from "@mui/material";
import FloatAreaLineGraph from "../../../../shared/components/FloatAreaLineChart";
import { ChartLineType } from "../../../../models/backoffice.models";
import { ResponsiveContainer } from "recharts";
import { useTranslation } from "react-i18next";
import { completionStyles } from "./style";

function VideoCompletionGraph({ setSelectFilters }: any) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isLoading, data, isError, errorMessage } = useSelector(
    (state: RootState) => state?.entities?.analyticsVideo?.analyticsVideoData
  );

  const [chartData, setChartData]: any = useState();
  const [maximum, setMaximum] = useState(0);

  const ChartViews: ChartLineType[] = [
    {
      type: "linear",
      name: t("Percent of viewers"),
      dataKey: "rate",
      stroke: "#2456CC",
      dotedGraph: false,
    },
  ];

  useEffect(() => {
    dispatch(grabVideoAnalytics.request({ data: { content_id: id } }));
  }, []);

  function formatSeconds(secondsString: string): string {
    const seconds = parseInt(secondsString);
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    let formattedTime = "";
    if (minutes > 0) {
      formattedTime += `${minutes}m `;
    }
    formattedTime += `${remainingSeconds}s`;

    return formattedTime;
  }

  useEffect(() => {
    // chart data logic
    if (!isLoading && !isError) {
      const { timeline }: any = data?.data;
      let chart = timeline?.map((timeRate: any, index: number) => ({
        time: formatSeconds(`${timeRate.time / 1000}`),
        rate: timeRate.completion_rate,
      }));
      setChartData(chart);
      setMaximum(timeline?.length);
    }
  }, [data]);

  return (
    <Box
      className="overflow-x-contain"
      sx={completionStyles.boxStyle}
      // width={chartData?.length > 15 ?`${chartData?.length * 30}px`:"100%"}
    >
      <FloatAreaLineGraph
        //  max={10}
        interval={chartData?.length ? Math.ceil(chartData?.length / 10) : 5}
        Xaxis={{ dataKey: "time" }}
        ChartData={chartData}
        ChartLines={ChartViews}
      />
    </Box>
  );
};

export default VideoCompletionGraph;
