import { THIN_BORDER_COLOR } from "../../../colors";

export const profileStyle = {
  wrapper: {
    border: `1px solid ${THIN_BORDER_COLOR}`,
    padding: "16px 33px",
    mb: "24px",
    profileText: {
      mb: "4px",
    },
    idText: {
      mb: "14px",
    },
    field: { color: "#969696", marginBottom: "8px", fontFamily: "Jost" },
    profileWrapper: {
      mb: "16px",
      display: "flex",
      profileItem: {
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "12px",
      },
      profileButton: {
        display: "flex",
        alignItems: "center",
        columnGap: "16px",
      },
    },
    quillStyle: {
      "ql-editor": {
        height: "200px",
      },
    },
    avatarStyle: {
      width: "100px",
      height: "100px",
      fontSize: 30,
      backgroundColor: "grey",
      color: "#fff",
    },
    settingMargin: { mb: "24px" },
    settingBox: { alignItems: "end" },
  },
  viewButtonStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
};

export const addressStyle = {
  wrapper: {
    border: `1px solid ${THIN_BORDER_COLOR}`,
    padding: "16px 33px",
    mb: "24px",
    profileText: {
      mb: "4px",
    },
    idText: {
      mb: "14px",
    },
    field: { color: "#969696", marginBottom: "8px", fontFamily: "Jost" },
  },
};

export const detailsStyle = {
  wrapper: {
    mb: "24px",
    display: "flex",
    flexWrap: "wrap",
    rowGap: "39px",
    columnGap: "39px",
    item: {
      border: `1px solid ${THIN_BORDER_COLOR}`,
      padding: "16px 33px",
      flex: "1",
      display: "flex",
      flexDirection: "column",
      rowGap: "4px",
      labelText: {
        mt: "12px",
      },
      inputStyle: {
        color: "#333",
      },
    },
    height: { minHeight: "220px" },
  },
};

export const centerButtonWrapper = {
  display: "flex",
  justifyContent: "center",
};

export const endButtonWrapper = {
  display: "flex",
  justifyContent: "flex-end",
  columnGap: "17px",
};

export const changePasswordStyle = {
  wrapper: {
    ml: "36px",
    mr: "36px",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    loaderStyle: { color: "#fff", mr: "2px" },
  },
};

export const previewStyle = {
  headerBox: {
    display: "flex",
    alignItems: "center",
    columnGap: "24px",
    mb: "20px",
  },
  previewBody: {
    border: `1px solid ${THIN_BORDER_COLOR}`,
    padding: "16px 33px",
    profilePhoto: {
      marginTop: "16px",
      marginBottom: "24px",
    },
    cursor: {
      cursor: "pointer",
    },
    avatarStyle: {
      width: "100px",
      height: "100px",
      fontSize: 30,
      backgroundColor: "grey",
      color: "#fff",
    },
    wordBreak: { wordBreak: "break-word" },
    margin: { mt: "20px", mb: "10px" },
  },
};

export const referralCodeStyle = {
  flexStyle: { display: "flex", flexDirection: "column" },
};

export const notificationPrefStyle = {
  wrapper: {
    padding: "8px",
    border: `1px solid ${THIN_BORDER_COLOR}`,
    loader: { mr: "10px" },
    stack: { alignItems: "center" },
  },
};
