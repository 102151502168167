import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";

const { SUCCESS, REQUEST, USER_PROFILE_DATA } = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const userData = (state = intialState, action: actions) => {
    switch (action.type) {
      case USER_PROFILE_DATA[SUCCESS]:
        return { ...state, data: action.payload?.data, isLoading: false };
      case USER_PROFILE_DATA[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload, isLoading: true }
          : state;
      case USER_PROFILE_DATA[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  return combineReducers({
    userData,
  });
};

export default entity;

export const userProfile: any = (state: RootState) =>
  state.entities.userProfile;
