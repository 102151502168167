import { useMediaQuery } from "@mui/material";
import { VideosHeadCellProps } from "../../../models/backoffice.models";
import { CONSTANTS } from "../../../utils/constants";
import styled from "styled-components";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const videoDetailsListInfl = [
    { name: "Title", status: true },
    { name: "Creator handle", status: true },
    { name: "Description", status: true },
    { name: "Collection", status: true },
    { name: "Content id", status: true },
  ];
  
  export const videoDetailsList = [
    { name: "Title", status: true },
    { name: "Description", status: true },
    { name: "Collection", status: true },
    { name: "Content id", status: true },
  ];
  
  export const searchByList = [
    { name: "Collection", key: "collections", status: false },
    { name: "Content id", key: "content_id", status: false },
    { name: "Description", key: "description", status: false },
    { name: "Title", key: "title", status: true },
    { name: "Product name", key: "product_name" },
    { name: "Vendor name", key: "vendor_name" },
  ];
  
  export const searchByListInfl = [
    { name: "Collection", key: "collections", status: false },
    { name: "Content id", key: "content_id", status: false },
    { name: "Creator handle", key: "creator_handle", status: false },
    { name: "Description", key: "description", status: false },
    { name: "Title", key: "title", status: true },
    { name: "Product name", key: "product_name" },
    { name: "Vendor name", key: "vendor_name" },
  ];

 export const headMobileCells: VideosHeadCellProps[] = [
    {
      id: "video",
      numeric: false,
      disablePadding: false,
      label: "Video",
      align: "left",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label: "Title",
      align: "right",
    },
  ];

  export const headCellHook = (userRole:string | undefined) => {
    const headCells: VideosHeadCellProps[] = [
    {
      id: "video",
      numeric: false,
      disablePadding: false,
      label: "Video",
      align: "left",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label: "Title",
      align: "left",
    },
    {
      id: "description",
      numeric: true,
      disablePadding: false,
      label: "Description",
      align: "left",
    },
    {
      id: "collection",
      numeric: true,
      disablePadding: false,
      label: "Collection",
      align: "left",
    },
    {
      id: "content",
      numeric: false,
      disablePadding: false,
      label: "Content id",
      align: "left",
    },
  ];

  if (userRole === CONSTANTS.roles.INFLUENCER) {
    headCells.splice(2, 0, {
      id: "creatorHandle",
      numeric: true,
      disablePadding: false,
      label: "Creator handle",
    });
  }
return headCells
}

export function isVisibleCol(multilistInfo:any, colName:string,t:any){
 return multilistInfo
  .map((item: any) => t(item))
  ?.includes(t(colName))
}

export   const convertToPlainText = (html: string) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

export const RotatableIcon = styled(ArrowDropDownIcon)(({ rotate }) => ({
  transform: rotate ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.3s',
}));

export function eventStopProgogation(e:any){
    e.preventDefault();
    e.stopPropagation();
}