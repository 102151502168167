import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, VIDEOS_ANALYTICS } = actionTypes;

//write sagas function

function* getVideosAnalyticSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getVideosAnalytics,
      { content_ids: data.content_ids }
    );
    yield sendPayload(apiResponse, VIDEOS_ANALYTICS);
  } catch (e) {
    yield sendPayloadFailure(e, VIDEOS_ANALYTICS);
  }
}

export const sagas = {
  watchVideosAnalytics: takeLatest(
    actionTypes.VIDEOS_ANALYTICS[REQUEST],
    getVideosAnalyticSaga
  ),
};
