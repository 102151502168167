import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, VIDEO_PRODUCT_CLICKS_STATS } = actionTypes;

//write sagas function
function* getVideoProductClickStatSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getProductClicksByVideo,
      data.data
    );
    yield sendPayload(apiResponse, VIDEO_PRODUCT_CLICKS_STATS);
  } catch (e) {
    yield sendPayloadFailure(e, VIDEO_PRODUCT_CLICKS_STATS);
  }
}

export const sagas = {
  watchVideoProductClickStats: takeLatest(
    actionTypes.VIDEO_PRODUCT_CLICKS_STATS[REQUEST],
    getVideoProductClickStatSaga
  ),
};
