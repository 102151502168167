import { Box,  Stack, useMediaQuery } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ChartLineType } from "../../../../models/backoffice.models";
import FloatLineChart from "../../../../shared/components/FloatLineChart";
import FLoatDropDown from "../../../../shared/components/FLoatDropDown";
import { videoProductClicksWeekly } from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../configureStore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { formatMontDate, past24Hours } from "../../../../utils/hooks";
import Loading from "../../../../shared/Loading";
import DateRangeGraphFilter from "../../../../shared/components/DateRangeGraphFilter";
import { useTranslation } from "react-i18next";
import FloatModal from "../../../../shared/components/FloatModal";
import FloatDateSelector from "../../../../shared/components/FloatDateSelector";

const ChartViews: ChartLineType[] = [
  {
    type: "monotone",
    name: "Click throughs",
    dataKey: "clickThroughs",
    stroke: "#0457D4",
  },
  {
    type: "monotone",
    name: "Product clicks",
    dataKey: "clicks",
    stroke: "#C864C7",
  },
];

function ProductWiseChart({
  product,
  setAll,
  products,
  setSelectFilters,
  openMobileDate,
  setOpenMobileDate,
}: any) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const ref = useRef<any>();

  const { videoProductClickStat } = useSelector(
    (state: RootState) => state?.entities?.videoProductClick
  );

  const [productList, setProductList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = React.useState({
    filter: "past7days",
    product: "all",
  });
  const [chartData, setChartData]: any = useState();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedDates, setSelectedDates]: any = useState(["", ""]);
  const [openDate, setOpenDate] = useState<boolean>(false);

  const moment = require("moment");
  const today = moment().format("YYYY-MM-DD")
  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
  const past7thDay = moment().subtract(6, "days").format("YYYY-MM-DD");
  const past30stDay = moment().subtract(29, "days").format("YYYY-MM-DD");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  

  const onProductChange = (value:string) => {
      const startEndDate:any = graphFilters?.find((item)=>item.key === filters.filter)?.value ?? ""
      const customDate = filters.filter === "dateRange" ? true : false;
      let payload = {}
      const range = {startDate: selectedDates[0] ? selectedDates[0]?.format("YYYY-MM-DD") :"",
      endDate: selectedDates[1] ?  selectedDates[1]?.format("YYYY-MM-DD"):""}

      if(value === "all"){
        if(filters.filter === "past24hours"){
          payload = customDate  ? {content_id: id, filter: "past24hours"} : { content_id: id ,filter: "past24hours"}
        }else{
          payload = customDate  ? {content_id: id,...range, filter: "pastNdays"} : { content_id: id ,...startEndDate,filter: "pastNdays"}
        }
      }else{
        if(filters.filter === "past24hours"){
          payload = customDate ? { item_id: value, content_id: id,filter:"past24hours"} : { item_id: value, content_id: id,filter:"past24hours"}
        }else{
          payload = customDate ? { item_id: value, content_id: id,...range ,filter:"pastNdays"} : { item_id: value, content_id: id,...startEndDate,filter:"pastNdays"}
        }
      }
      dispatch(
        videoProductClicksWeekly.request({
          data: payload,
        })
      );
      setFilters({ ...filters, product: value, });
  }
 
  const onFilterChange = (value:string) =>{
    setFilters({...filters,filter:value})
    const startEndDate:any = graphFilters?.find((item)=>item.key === value)?.value ?? {}
    const customDate = value === "dateRange" ? true : false;

    let payload = {}
    if(!customDate){
      if(value==="past24hours"){
        payload = filters.product === "all"?  {content_id: id , filter: "past24hours"} :{ item_id: filters.product, content_id: id ,filter:"past24hours"} 
      }else{
        payload = filters.product === "all"?  {content_id: id ,...startEndDate, filter: "pastNdays"} :{ item_id: filters.product, content_id: id ,...startEndDate,filter:"pastNdays"} 
      }

    dispatch(
      videoProductClicksWeekly.request({
        data: payload,
      })
    );  
      }
    else{

  if (isMobile) {
    setOpenDate(true);
  }
    }
}
  const commonFilterChange = (e: any) => {
    if (!(e.target.name === "filter" && e.target.value === "dateRange")) {
      dispatch(videoProductClicksWeekly.reset());
    }

    const name = e.target.name;
    const value = e.target.value;

    let data = {
      filter: name === "filter" ? value : filters.filter,
      product: name === "product" ? value : filters.product,
    };
    setFilters({ ...filters, ...data });

    switch(name){
      case "product":
        return onProductChange(value);
      case "filter":
        return onFilterChange(value);
      default:
        return onProductChange("all")
    }
  };

  const handleDateFilter = () => {
    dispatch(
      videoProductClicksWeekly.request({
        data: {
          item_id: "",
          content_id: id,
          startDate: selectedDates[0]?.format("YYYY-MM-DD"),
          endDate: selectedDates[1]?.format("YYYY-MM-DD"),
          filter: "pastNdays"
        },
      })
    );
    handleClose();
    setOpenDate(false);
    setOpenMobileDate(false);
  };

  const onMobileFilter = () => {
    dispatch(
      videoProductClicksWeekly.request({
        data: { ...filters, content_id: id },
      })
    );
  };

  const graphFilters = [
    {
      key: "past7days",
      name: "Past 7 days",
      value: {
        startDate: past7thDay,
        endDate: today,
      },
    },
    {
      key: "past30days",
      name: "Past 30 days",
      value: {
        startDate: past30stDay,
        endDate: today,
      },
    },
    {
      key: "past24hours",
      name: "Past 24 hours",
      value:"past24hours"
    },
    {
      key: "dateRange",
      name: "Select date",
      value:"dateRange"
    },
  ];

  const selectMemoFilters = useMemo(() => {
    const SelectFilters = [
      {
        list: graphFilters,
        label: "Filter",
        onChange: commonFilterChange,
        value: filters.filter,
        name: "filter",
      },
      {
        list: productList,
        label: "Product list",
        onChange: commonFilterChange,
        value: filters.product,
        name: "product",
      },
    ];
    return SelectFilters;
  }, [filters?.product, filters.filter, productList,selectedDates]);

  useEffect(() => {
    if (product?.item_id) {
      onProductChange(product?.item_id)
    }
  }, [product.item_id]);

  useEffect(() => {
    setOpenDate(openMobileDate);
  }, [openMobileDate]);

  useEffect(() => {
    const prodFilterList = [{ name: "All", key: "all" }];
    products?.map((item: any) => {
      prodFilterList.push({
        name: item.name?.slice(0, 6) + "...",
        key: item.item_id?.toString(),
      });
    });
    setProductList(prodFilterList);
    if (product.item_id) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        product: product.item_id,
      }));
    }
    return () => {
      setSelectFilters({});
      setOpenMobileDate(false);
      setOpenDate(false);
    };
  }, []);

  useEffect(() => {
    setIsLoading(videoProductClickStat.isLoading);
    if (!videoProductClickStat?.isLoading && !videoProductClickStat?.isError) {
      let chart: any = [];
      const hours = past24Hours();
      if(filters.filter === "past24hours"){
        videoProductClickStat?.data?.map((item: any,index:number) => {
          chart.push({
            time: hours[index + 1],
            // time: filters?.filter === "past7days" ? formatMontDate(item.date) : item.date.slice(-2),
            clicks: item?.product_clicks,
            clickThroughs: item?.click_throughs,
          });
          return;
        });
      }else{
        videoProductClickStat?.data?.map((item: any) => {
          chart.push({
            time: formatMontDate(item.date),
            // time: filters?.filter === "past7days" ? formatMontDate(item.date) : item.date.slice(-2),
            clicks: item?.product_clicks,
            clickThroughs: item?.click_throughs,
          });
          return;
        });
      }
      setChartData(chart);
    }
  }, [videoProductClickStat?.isLoading]);

  useEffect(() => {
    return () => {
      setSelectFilters({});
    };
  }, []);

  useEffect(() => {
    setSelectFilters({
      filters: filters,
      setFilters: (a: any) => setFilters(a),
      SelectFilters: selectMemoFilters,
      onSubmit: () => onMobileFilter(),
    });
  }, [selectMemoFilters]);

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "fit-content" }} onClick={() => setAll(true)}>
          <ArrowBackIcon />
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {!isMobile &&
            selectMemoFilters.map((item, index) => (
              <Box ref={ref} aria-describedby={id}>
                <FLoatDropDown
                  key={index}
                  list={item.list}
                  label={item.label}
                  onChange={item.onChange}
                  value={item.value}
                  name={item.name}
                  iconClick={(e) => handleClick(e)}
                />
              </Box>
            ))}
        </Box>
      </Box>
      <FloatDateSelector
        open={open}
        max30Days={true}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        handleDateFilter={handleDateFilter}
      />

      <Stack>
        {!isLoading ? (
          <Box
            className="overflow-x-contain"
            sx={{ width: "100%", maxWidth: "calc(100% - 10px)" }}
          >
            <Box
              sx={{
                height: "300px",
                marginBottom: "20px",
                width: filters?.filter === "past7days" ? "800px" : "1500px",
              }}
            >
              <FloatLineChart
                Xaxis={{ dataKey: "time" }}
                ChartData={chartData}
                ChartLines={ChartViews}
              />
            </Box>
          </Box>
        ) : (
          <Box sx={{ height: `300px` }}>
            {" "}
            <Loading />
          </Box>
        )}
        {isMobile && (
          <FloatModal
            open={openDate}
            onClose={() => {
              setOpenDate(false);
              setOpenMobileDate(false);
            }}
            modalTitle={t("Select date")}
            modalBody={
              <DateRangeGraphFilter
                max30Days={true}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                handleDateFilter={handleDateFilter}
              />
            }
          />
        )}
      </Stack>
    </Box>
  );
};

export default ProductWiseChart;
