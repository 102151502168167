export function formatDateForPicker(dateStr: string) {
    // Parse the input date string
    let dateObj = new Date(dateStr.replace(/-/g, "/"));
  
    // Get day, month, year, hours, minutes, and seconds
    let day = ("0" + dateObj.getDate()).slice(-2);
    let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    let year = dateObj.getFullYear();
    let hours = ("0" + dateObj.getHours()).slice(-2);
    let minutes = ("0" + dateObj.getMinutes()).slice(-2);
    let seconds = ("0" + dateObj.getSeconds()).slice(-2);
  
    // Get the day of the week
    let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let dayOfWeek = days[dateObj.getDay()];
  
    // Format the date according to the desired format
    let formattedDate =
      dayOfWeek +
      "%2B" +
      month +
      "%2B" +
      day +
      "%2B" +
      year +
      "%2B" +
      hours +
      "%3A" +
      minutes +
      "%3A" +
      seconds +
      "%2BGMT%2B0530%2B%28India%2BStandard%2BTime%29";
  
    return formattedDate;
  }

  export interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
    width?: string;
  }
  
  export const headCells: readonly HeadCell[] = [
    {
      id: "members",
      numeric: false,
      disablePadding: false,
      label: "Members",
      width: "75%",
    },
    {
      id: "data",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "25%",
    },
  ];
  
  export const headCellsTwo: readonly HeadCell[] = [
    {
      id: "level",
      numeric: false,
      disablePadding: false,
      label: "Level",
    },
    {
      id: "sales",
      numeric: true,
      disablePadding: false,
      label: "Sales",
    },
    {
      id: "revenue",
      numeric: true,
      disablePadding: false,
      label: "Revenue",
    },
  ];
  