import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Info, StyledText, SubTitle1 } from "../../utils/styledComponents";
import { addVideoCall, uploadCustomProductCall } from "../../actions";
import VideoMetadata from "./VideoMetaData";
import { RootState } from "../../configureStore";
import { VideoIcon } from "../../utils/icons";
import { addVideoStyle, videoUploadStyle } from "./style";
import UploadBtnIcon from "../../utils/icons/uploadBtnIcon";
import { stylesGlobal } from "../../utils/muiStyles";
import { iskeyInObject } from "../../utils/hooks";

function AddVideoModal(props: any) {
  const {
    baseStyle,
    focusedStyle,
    acceptStyle,
    rejectStyle,
    uploadBtnStyle,
    infoIconStyle,
    errorInfoStyle,
    errorTextOuter,
    changeFileBtn,
  } = videoUploadStyle;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile]: any = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [isLimitReached, setIsLimitReached] = useState(false);

  const { planData }: any = useSelector(
    (state: RootState) => state?.entities?.planData
  );

  const { uploadCustomProductData }: any = useSelector(
    (state: RootState) => state?.entities?.uploadCustomProduct
  );

  const { getVideosData } = useSelector(
    (state: RootState) => state?.entities?.videos
  );

  const { addVideoData }: any = useSelector(
    (state: RootState) => state?.entities?.addVideo
  );

  const getVideoDuration = useCallback(
    (duration: any) => {
      setVideoDuration(duration);
      setIsLimitReached(duration > planData?.totalPurchasedStorageMinutes * 60);
    },
    [setVideoDuration, setIsLimitReached, planData]
  );

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    open: openFileDialog,
  }: any = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: { "video/mp4": [] },
    maxFiles: 1,
    multiple: false,
  });

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const file: any = selectedFile.map((file: any) => (
    <VideoMetadata
      key={file.path}
      videoFile={file}
      getVideoDuration={getVideoDuration}
    />
  ));

  const handleUploadVideo = () => {
    setIsUploading(true);
    let formData = new FormData();
    formData.append("file", selectedFile[0]);
    dispatch(uploadCustomProductCall.request({ data: formData }));
  };

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      setSelectedFile(acceptedFiles);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (uploadCustomProductData?.data?.file_key?.length) {
      let fileKey = uploadCustomProductData?.data?.file_key;
      let fileName = uploadCustomProductData?.data?.original_filename;
      let data = {
        file_key: fileKey,
        title: fileName,
        duration: videoDuration,
      };
      dispatch(addVideoCall.request({ data: data }));
      dispatch(uploadCustomProductCall.reset());
    }
  }, [uploadCustomProductData?.data, getVideosData.isLoading]);

  useEffect(() => {
    if (!addVideoData?.isLoading || addVideoData?.isError) {
      if (
        iskeyInObject(addVideoData?.data,"success") &&
        addVideoData?.data?.success
      ) {
        dispatch(addVideoCall.reset());
        props.handleClose();
      }
      if (addVideoData?.isError) {
        dispatch(addVideoCall.reset());
        props.handleClose();
      }
    }
  }, [addVideoData?.isLoading]);

  return (
    <Box
      sx={{
        ...addVideoStyle.wrapper,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <div {...getRootProps({ style })}>
        <input className="input-zone" {...getInputProps()} />
        <Stack onClick={openFileDialog} sx={addVideoStyle.stackStyle}>
          {selectedFile?.length ? file : <img src={VideoIcon} />}
        </Stack>
        {selectedFile.length > 0 && (
          <Button
            variant="outlined"
            color="primary"
            component="label"
            sx={changeFileBtn}
            onClick={openFileDialog}
          >
            {t("Change file")}
          </Button>
        )}
      </div>


      <Stack sx={addVideoStyle.wrapperStack}>
          {( videoDuration > 180) && <StyledText variant="caption" sx={errorInfoStyle}>
              {t("Video is too long, video must be max 3 mins long")}
          </StyledText>}
        <SubTitle1>{t("Drag and drop a video file to upload")}</SubTitle1>
        <Info>
          {t("You will be able to review your selected file after upload.")}
        </Info>
        {selectedFile.length < 1 && (
          <Button
            variant="contained"
            color="primary"
            component="label"
            disabled={isLimitReached}
            sx={addVideoStyle.buttonStyle}
            onClick={openFileDialog}
          >
            {t("Select file")}
          </Button>
        )}
        <Box>
          <Button
            disabled={(videoDuration > 180) || isUploading}
            variant="contained"
            sx={{...stylesGlobal.containedBtn,display:selectedFile.length > 0 ? "flex" : "none" }}
            onClick={handleUploadVideo}
          >
         {isUploading ? <CircularProgress size="12px" /> : <UploadBtnIcon fill={(videoDuration > 180 )|| isUploading ?"grey":"#fff"} />}&nbsp;{t("Upload video")}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default AddVideoModal;
