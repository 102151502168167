import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
import { USER_PRODUCTS } from "../actions/actiontypes";
const { REQUEST, ADD_PYVIT_PRODUCT } = actionTypes;


function* addPyvitProductSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.postPyvitProduct,
      data.data
    );
    yield sendPayload(apiResponse, ADD_PYVIT_PRODUCT);

    if (apiResponse) {
      const productsListCall: Generator<string, number, string> = yield call(
        ApiService.getUserProducts,
        {}
      );
      yield sendPayload(productsListCall, USER_PRODUCTS);
    }
  } catch (e) {
    yield sendPayloadFailure(e, ADD_PYVIT_PRODUCT);
  }
}

export const sagas = {
  watchaddPyvitProduct: takeLatest(
    actionTypes.ADD_PYVIT_PRODUCT[REQUEST],
    addPyvitProductSaga
  ),
};
