import React from "react";

const UploadImageIcon = () => {
  return (
    <svg
      width="96"
      height="83"
      viewBox="0 0 96 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7485_73730)">
        <mask
          id="mask0_7485_73730"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="96"
          height="83"
        >
          <path d="M96 0H0V83H96V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_7485_73730)">
          <path
            d="M11.0679 2.02942e-07H67.0261C69.9595 0.00640624 72.7709 1.15642 74.8451 3.1984C76.9193 5.24038 78.0875 8.00807 78.094 10.8959V67.5763C78.0875 70.4641 76.9193 73.2318 74.8451 75.2738C72.7709 77.3158 69.9595 78.4658 67.0261 78.4722H11.0679C8.13451 78.4658 5.32312 77.3158 3.2489 75.2738C1.17468 73.2318 0.00650718 70.4641 0 67.5763V10.7732C0.0324333 7.90461 1.21286 5.16441 3.28503 3.14742C5.3572 1.13042 8.15386 -0.000553126 11.0679 2.02942e-07Z"
            fill="#EBEBEB"
          />
          <path
            d="M36.1873 51.2942L19.7724 35.1358L0 54.5994V67.4539C0.00650718 70.3417 1.17468 73.1094 3.2489 75.1514C5.32312 77.1934 8.13451 78.3434 11.0679 78.3498H67.0261C69.9595 78.3434 72.7709 77.1934 74.8451 75.1514C76.9193 73.1094 78.0875 70.3417 78.094 67.4539V46.5196L59.5649 28.1572L36.1873 51.2942Z"
            fill="#262626"
          />
          <path
            d="M78.0949 83.0014C87.9849 83.0014 96.0024 75.1086 96.0024 65.3723C96.0024 55.636 87.9849 47.7432 78.0949 47.7432C68.2049 47.7432 60.1875 55.636 60.1875 65.3723C60.1875 75.1086 68.2049 83.0014 78.0949 83.0014Z"
            fill="white"
          />
          <path
            d="M75.485 57.1671C75.4771 56.8277 75.5392 56.4903 75.6674 56.1753C75.7957 55.8602 75.9876 55.574 76.2314 55.334C76.4752 55.094 76.7659 54.9051 77.0859 54.7789C77.4059 54.6526 77.7487 54.5915 78.0934 54.5993C78.7836 54.6042 79.4442 54.8763 79.9322 55.3568C80.4203 55.8373 80.6967 56.4875 80.7016 57.1671V73.8161C80.7016 74.4972 80.4268 75.1503 79.9377 75.6318C79.4485 76.1134 78.7851 76.3839 78.0934 76.3839C77.4016 76.3839 76.7382 76.1134 76.249 75.6318C75.7598 75.1503 75.485 74.4972 75.485 73.8161V57.1671Z"
            fill="#262626"
          />
          <path
            d="M76.2328 58.8783C75.7573 58.4069 75.491 57.7692 75.4922 57.1048C75.4934 56.4405 75.762 55.8037 76.2392 55.334C76.7164 54.8642 77.3632 54.5998 78.038 54.5986C78.7128 54.5975 79.3606 54.8596 79.8394 55.3277L85.8081 61.2036C86.0463 61.4364 86.2355 61.7132 86.3648 62.0182C86.4941 62.3232 86.5609 62.6503 86.5615 62.9807C86.5621 63.3112 86.4965 63.6385 86.3683 63.944C86.2401 64.2494 86.0519 64.5269 85.8146 64.7606C85.5772 64.9942 85.2953 65.1795 84.9851 65.3056C84.6748 65.4318 84.3423 65.4965 84.0066 65.4959C83.671 65.4953 83.3387 65.4295 83.0289 65.3022C82.7191 65.1749 82.4379 64.9887 82.2014 64.7542L76.2328 58.8783Z"
            fill="#262626"
          />
          <path
            d="M76.231 55.3335C76.4675 55.099 76.7487 54.9128 77.0585 54.7855C77.3683 54.6582 77.7006 54.5924 78.0363 54.5918C78.3719 54.5912 78.7044 54.6559 79.0147 54.7821C79.3249 54.9083 79.6068 55.0935 79.8442 55.3272C80.0815 55.5608 80.2697 55.8384 80.3979 56.1438C80.526 56.4492 80.5917 56.7765 80.5911 57.107C80.5905 57.4374 80.5237 57.7646 80.3944 58.0695C80.2651 58.3745 80.0759 58.6513 79.8377 58.8842L73.9879 64.7601C73.509 65.2281 72.8612 65.4903 72.1864 65.4891C71.5116 65.4879 70.8648 65.2235 70.3876 64.7537C69.9104 64.284 69.6418 63.6472 69.6406 62.9829C69.6394 62.3185 69.9057 61.6808 70.3812 61.2094L76.231 55.3335Z"
            fill="#262626"
          />
          <path
            d="M35.6907 32.8085C39.5369 32.8085 42.6549 29.739 42.6549 25.9526C42.6549 22.1662 39.5369 19.0967 35.6907 19.0967C31.8445 19.0967 28.7266 22.1662 28.7266 25.9526C28.7266 29.739 31.8445 32.8085 35.6907 32.8085Z"
            fill="#262626"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_7485_73730">
          <rect width="96" height="83" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UploadImageIcon;
