import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, UPLOAD_CUSTOM_PRODUCT } = actionTypes;

//write sagas function

function* uploadCustomProductSagas(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.postCustomProduct,
      data.data
    );
    yield sendPayload(apiResponse, UPLOAD_CUSTOM_PRODUCT);
  } catch (e) {
    yield sendPayloadFailure(e, UPLOAD_CUSTOM_PRODUCT);
  }
}

export const sagas = {
  watchuploadCustomProducts: takeLatest(
    actionTypes.UPLOAD_CUSTOM_PRODUCT[REQUEST],
    uploadCustomProductSagas
  ),
};
