import { TABLEHEADBACKGROUNDSHADE2, THEMEBACKGROUNDCOLOR } from "../../../colors";

export const CreatorActionRowStyles = {
    wrapper: {
        backgroundColor: "#EEEEEE",
        padding: "12px 6px 6px 6px",
        container:
        {
            display: "flex",
            columnGap: "16px",
        },
        expandedContainer: {
            display: "flex",
            columnGap: "16px",
            rowGap: "6px",
            flexWrap: "wrap",
        },
        textDiv: {
            display: "flex",
            columnGap: "12px",
            alignItems: "center",
            paddingBottom: "6px"
        },
        dotBox: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "3px",
            marginTop: "6px"
        },
        analyticsText: {
            whiteSpace: "nowrap"
        }
    },
    row: {
        backgroundColor: "#F6F6F6",
        gridStyle: { display: "flex", justifyContent: "space-between", alignItems: "center" },
        textStyle: { fontSize: "0.75rem  !important" },
        boxWidth: {
            width: "calc(100vw - 50px) !important",
        }
    },
    analyticsRow: {
        rowStyle: { backgroundColor:   THEMEBACKGROUNDCOLOR },
        containerDesktop: { display: "flex", flexDirection: "row" },
        tableCell: {
            padding: "5px 12px !important",
            alignItems: "center",
            // borderLeft: "1px solid grey",
            // borderRight: "1px solid grey",
            // borderBottom: "1px solid grey",
        },
        mobileScrollOuter: {
            width: "calc(100vw - 50px) !important",
            overflowX: "auto",
        },
        wrapBox: { width: "fit-content", display: "flex", flexWrap: "wrap" },
        flexBox: { width: "fit-content", display: "flex" },
        infoBox: { mr: "8px", padding: "3px", justifyContent: "center" },
        infoMobile: { width: "max-content", fontSize: "0.75rem  !important" },
        scrollIndicatorBox: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "3px",
            marginTop: "6px",
        },
    },
}

export const creatorLibraryStyle = {
    headrow: { display: "flex" },
    boxStyle: {
        backgroundColor: "#000",
        alignItems: "center",
        justifyContent: "center",
    },
    tablecell: { whiteSpace: "nowrap" },
    accordionStyle: { padding: "0px !important" },
    accordionSummary: {
        padding: "0px 5px !important",
        ".MuiAccordionSummary-content": {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            margin: "0px !important"
        },
    },
    styledCell: { padding: "8px 2px !important" },
    cellBox: {
        display: "flex",
        width: "74px",
        alignItems: "center",
        justifyContent: "center",
    },
    cellStyle: {
        width: "inherit !important",
        padding: "8px 2px !important",
    },
    tableCell: { padding: "8px 2px !important" },
    gridStyle: {
        display: "flex",
        alignItems: "center",
    },
    cellTextWrap: { whiteSpace: "nowrap" },
};

export const productsRowStyles = {
  rowStyle: { backgroundColor: "#F7F7F7" },
  productRow: { maxWidth: "calc(100vw - 340px) !important" },
  productRowinner: { width: "max-content", flexDirection: "row" },
  rowTitles: { display: "flex", padding: "0 10px" },
  valuesRow: { display: "flex", padding: "0 10px", maxWidth: "170px" },
  productName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    panddingBottom: "4px",
  },
  venderName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  scrollIndicatorBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "3px",
    marginTop: "6px",
  },
};

export const productTblStyle = {
    rowPadding: { padding: "5px 10px" },
    rowValue: {
      width: "150px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    header: {
      padding: "10px",
      backgroundColor: TABLEHEADBACKGROUNDSHADE2,
      fontWeight: "bold",
      fontSize: "16px",
      fontFamily: "Jost",
    },
    linkStyle: {
      flexDirection: "row",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
    },
    linkText: { fontSize: "16px", fontWeight: "bold" },
    infoStyle:{pl:"10px"}
  }