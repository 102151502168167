import { Box, Button, Checkbox, CircularProgress, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SubTitle2 } from "../../../utils/styledComponents";
import { stylesGlobal } from "../../../utils/muiStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  getAffiliateProfile,
  setNotificationPreference,
} from "../../../actions";
import { RootState } from "../../../configureStore";
import { useTranslation } from "react-i18next";
import { THIN_BORDER_COLOR } from "../../../colors";
import { notificationPrefStyle } from "./style";

interface Payload {
  [key: string]: number;
}
const notifications = [
  { name: "New sale notification", key: "notif_order_new", status: false },
  {
    name: "Sale updated notification",
    key: "notif_order_update",
    status: false,
  },
  {
    name: "Payment processed notification",
    key: "notif_payout",
    status: false,
  },
  { name: "Newsletter email", key: "notif_marketing", status: false },
];

function NotificationPreference({ profileData, handleClose }: any){
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { notificationPreference }: any = useSelector(
    (state: RootState) => state?.entities?.affiliateProfile
  );

  const [preferences, setPreferences] = useState(notifications);

  useEffect(() => {
    if (!profileData?.isLoading && !profileData?.isError) {
      let list = [
        {
          name: "New sale notification",
          key: "notif_order_new",
          status: Boolean(profileData?.data?.notif_order_new),
        },
        {
          name: "Sale updated notification",
          key: "notif_order_update",
          status: Boolean(profileData?.data?.notif_order_update),
        },
        {
          name: "Payment processed notification",
          key: "notif_payout",
          status: Boolean(profileData?.data?.notif_payout),
        },
        {
          name: "Newsletter email",
          key: "notif_marketing",
          status: Boolean(profileData?.data?.notif_marketing),
        },
      ];
      setPreferences(list);
    }
  }, [profileData]);

  useEffect(() => {
    if (notificationPreference?.isError) {
      dispatch(setNotificationPreference.reset());
      handleClose();
    }
    if (
      Object?.keys(notificationPreference?.data).includes("success") &&
      notificationPreference?.data?.success
    ) {
      dispatch(getAffiliateProfile.request({}));
      dispatch(setNotificationPreference.reset());
      handleClose();
    }
  }, [notificationPreference]);

  const onStatusChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const updatedPreferences = preferences.map((notification) => {
      if (notification.key === key) {
        return { ...notification, status: e.target.checked };
      }
      return notification;
    });
    setPreferences(updatedPreferences);
  };

  const onSave = () => {
    const payload: Payload = {};
    preferences.forEach((item) => {
      payload[item.key] = item.status ? 1 : 0;
    });
    dispatch(setNotificationPreference.request(payload));
  };

  return (
    <Box>
      <Box sx={notificationPrefStyle.wrapper}>
        {preferences?.map((notification) => {
          return (
            <Stack direction={"row"} alignItems={"center"}>
              <Checkbox
                checked={notification.status}
                onChange={(e) => onStatusChange(e, notification.key)}
              />{" "}
              <SubTitle2>{t(notification.name)}</SubTitle2>
            </Stack>
          );
        })}
      </Box>
      <Stack sx={notificationPrefStyle.wrapper.stack}>
        <Button
          variant="contained"
          sx={stylesGlobal.containedBtn}
          onClick={onSave}
          disabled={notificationPreference?.isLoading}
        >
          {notificationPreference?.isLoading && (
            <CircularProgress
              size={"14px"}
              sx={notificationPrefStyle.wrapper.loader}
            />
          )}{" "}
          {t("Save")}
        </Button>
      </Stack>
    </Box>
  );
};

export default NotificationPreference;
