import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import ImageCropper from "../../../shared/cropImage/ImageCroper";
import { Box, Button, Stack } from "@mui/material";
import { stylesGlobal } from "../../../utils/muiStyles";
import UploadImageIcon from "../../../utils/icons/UploadImageIcon";
import { Info, SubTitle1 } from "../../../utils/styledComponents";
import { thumbnailBodyStyle } from "../style";
import { useTranslation } from "react-i18next";
import { SECONDARYCOLOUR, THIN_BORDER_COLOR } from "../../../colors";

const FloatThumbnailBody = (props: any) => {
  const { t } = useTranslation();

  const { baseStyle, focusedStyle, acceptStyle, rejectStyle } = {
    baseStyle: {
      flex: 1,
      minHeight: "150px",
      minWidth: "150px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "15px",
      height: "auto",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: THIN_BORDER_COLOR,
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    focusedStyle: {
      borderColor: SECONDARYCOLOUR,
    },
    acceptStyle: {
      borderColor: "#00e676",
    },
    rejectStyle: {
      borderColor: "#ff1744",
    },
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    open: openFileDialog,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: { "image/jpeg": [], "image/png": [] },
    maxFiles: 1,
    multiple: false,
  });

  const [croppedImage, setCroppedImage]: any = useState();

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const file = acceptedFiles.map((file) => (
    <ImageCropper
      key={file.name}
      image={file}
      type={props?.type ?? "product"}
      onImageCropped={(image: any) => setCroppedImage(image)}
    />
  ));

  const photo = acceptedFiles.map((file) => file.name);
  const onSave = () => {
    if (croppedImage && props.isNameRequired && photo && photo.length > 0) {
      props.changeThumbnailCallback(croppedImage, photo[0]);
    } else if (croppedImage) {
      props.changeThumbnailCallback(croppedImage);
      props.onClose();
    } else {
      openFileDialog();
    }
  };

  return (
    <Box
      sx={{
        ...thumbnailBodyStyle.boxStyle,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box sx={{ cursor: "pointer", mr: { xs: "0px", md: "15px" } }}>
        <div {...getRootProps({ style })}>
          <input className="input-zone" {...getInputProps()} />

          {croppedImage ? (
            <>
              <img
                src={croppedImage}
                style={{
                  height: "11rem",
                  objectFit: "cover",
                  display: "block",
                }}
              />
            </>
          ) : (
            <Stack onClick={openFileDialog}>
              <UploadImageIcon />
            </Stack>
          )}
          {file}
        </div>
      </Box>
      <Stack sx={thumbnailBodyStyle.stackStyle}>
        {croppedImage && (
          <Button
            variant="outlined"
            onClick={openFileDialog}
            sx={stylesGlobal.outlinedBtn}
          >
            {t("Change file")}
          </Button>
        )}
        <SubTitle1>{t("Drag and drop an image to upload")}</SubTitle1>
        <Info>
          {t(
            "You will be able to review your selected file and crop after upload."
          )}
        </Info>

        <Box>
          <Button
            variant="contained"
            onClick={() => onSave()}
            sx={stylesGlobal.containedBtn}
          >
            {croppedImage ? t("Save") : t("Select file")}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default FloatThumbnailBody;
