import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE, RESET } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";
import { toast } from "react-toastify";
import { t } from "i18next";

const {
  SUCCESS,
  REQUEST,
  CREATOR_LIBRARY_GRAB_VIDEOS,
  CREATOR_LIBRARY_ANALYTICS,
  COPY_CREATOR_VIDEO_LINK,
} = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const creatorLibraryVideoList = (state = intialState, action: actions) => {
    switch (action.type) {
      case CREATOR_LIBRARY_GRAB_VIDEOS[SUCCESS]:
        return { ...state, data: action.payload?.data, isLoading: false };
      case CREATOR_LIBRARY_GRAB_VIDEOS[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload?.data, isLoading: true }
          : { ...state, isLoading: true };
      case CREATOR_LIBRARY_GRAB_VIDEOS[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case CREATOR_LIBRARY_GRAB_VIDEOS[RESET]:
        return intialState;
      default:
        return state;
    }
  };

  const creatorLibraryAnalyticsList = (
    state = intialState,
    action: actions
  ) => {
    switch (action.type) {
      case CREATOR_LIBRARY_ANALYTICS[SUCCESS]:
        return { ...state, data: action.payload?.data, isLoading: false };
      case CREATOR_LIBRARY_ANALYTICS[REQUEST]:
        return action?.payload
          ? { ...state, data: {}, isLoading: true }
          : { ...state, isLoading: true };
      case CREATOR_LIBRARY_ANALYTICS[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case CREATOR_LIBRARY_ANALYTICS[RESET]:
        return intialState;
      default:
        return state;
    }
  };

  const copyVideoLinkData = (state = intialState, action: actions) => {
    switch (action.type) {
      case COPY_CREATOR_VIDEO_LINK[SUCCESS]:
        toast.success(t("Content copied successfully"));
        return { ...state, data: action.payload, isLoading: false };
      case COPY_CREATOR_VIDEO_LINK[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload, isLoading: true }
          : { ...state, isLoading: true };
      case COPY_CREATOR_VIDEO_LINK[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case CREATOR_LIBRARY_ANALYTICS[RESET]:
        return intialState;
      default:
        return state;
    }
  };

  return combineReducers({
    creatorLibraryVideoList,
    creatorLibraryAnalyticsList,
    copyVideoLinkData,
  });
};

export default entity;

export const creatorLibraryVideos = (state: RootState) =>
  state.entities.creatorLibrary;
