import { THIN_BORDER_COLOR } from "../../colors";

export const muiStyle = {
  productContainer: {
    marginTop: "20px",
  },
  paper: {
    boxShadow: "none",
    padding: 2,
    textAlign: "center",
    ":hover": {
      border: "none",
      boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.24)",
    },
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    alignItems: "center",
  },
  iconContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 1,
  },
  iconPaper: {
    borderRadius: "50%",
    marginTop: 1,
  },
  image: {
    width: "100%",
    height: "15rem",
  },
  itemRating: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  itemCount: {
    display: "flex",
    alignItems: "center",
  },
  counterIcon: {
    padding: "4px",
  },
  counterPaper: {
    display: "flex",
  },
  cart: {
    border: `1px solid ${THIN_BORDER_COLOR}`,
    borderRadius: "3px",
    padding: "6px",
    width: "45%",
    display: "flex",
    justifyContent: "space-evenly",
  },
  buyNow: {
    background: "#000",
    borderRadius: "3px",
    color: "#fff",
    padding: "7px",
    width: "45%",
    display: "flex",
    justifyContent: "space-evenly",
    "&:hover": {
      backgroundColor: "black",
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "black",
    },
  },
  boxStyle: { position: "relative" },
  iconButtonStyle: { padding: "0px" },
  startIconStyle: { width: "1.2rem" },
  textStyle: { textDecoration: "line-through" },
};
