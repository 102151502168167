/* eslint-disable */
import { Box, Typography } from "@mui/material";
import React, { useRef } from "react";
import { StyledText } from "../../utils/styledComponents";
import { videoMetaDataStyle } from "./style";

function VideoMetadata({ videoFile, getVideoDuration }: any) {
  const videoEl = useRef(null);
  const videoSrcUrl = URL.createObjectURL(videoFile);

  const handleLoadedMetadata = () => {
    const video: any = videoEl.current;
    if (!video) return;
    getVideoDuration(video.duration);
  };

  return (
    <Box
      sx={videoMetaDataStyle.wrapper}
    >
      <video
        width="160"
        height="120"
        src={videoSrcUrl}
        ref={videoEl}
        onLoadedMetadata={handleLoadedMetadata}
      />
      <StyledText ml={2} fontWeight="bold" sx={{width:"140px", overflow:"hidden",whiteSpace:"nowrap", textOverflow:"ellipsis"}}>
        {videoFile.name}
      </StyledText>
    </Box>
  );
};

export default VideoMetadata;
