import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FormLabel, StyledText } from "../../../utils/styledComponents";
import { RootState } from "../../../configureStore";
import { stylesGlobal } from "../../../utils/muiStyles";
import {
  changeVideoThumbnailCall,
  getVideoDetailsCall,
  saveVideoDetailsCall,
  uploadCustomProductCall,
} from "../../../actions";
import Loading from "../../../shared/Loading";
import FloatModal from "../../../shared/components/FloatModal";
import { changeThumbnail, tabStyle } from "./style";
import FloatThumbnailBody from "../../../shared/components/addProducts/FloatThumbnailBody";
import ClickEditTutorial from "./tutorialGifs/click-edit-edit-title.gif"
import AddDescriptionTutorial from "./tutorialGifs/add-description-save-details.gif"
import ChangeThumbnailTutorial from "./tutorialGifs/change-thumbnail.gif"
import { ExpandMore } from "@mui/icons-material";
import { THIN_BORDER_COLOR } from "../../../colors";

function DetailsVideo() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const reactQuillRef: any = React.useRef();
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { detailStyle } = tabStyle;

  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [openThumbnailModal, setOpenThumbnailModal] = useState<boolean>(false);

  const { videoDetails } = useSelector(
    (state: RootState) => state?.entities?.videoDetail
  );

  const { changeVideoThumbnailData }: any = useSelector(
    (state: RootState) => state?.entities?.changeVideoThumbnail
  );

  const { uploadCustomProductData }: any = useSelector(
    (state: RootState) => state?.entities?.uploadCustomProduct
  );

  const videoInfo = videoDetails?.data?.data?.response;

  useEffect(() => {
    if (uploadCustomProductData?.data?.file_key?.length) {
      let fileKey = uploadCustomProductData?.data?.file_key;
      let data = {
        content_id: params.id,
        thumbnail_file_key: fileKey,
      };
      dispatch(uploadCustomProductCall.reset());
      dispatch(getVideoDetailsCall.reset());
      dispatch(changeVideoThumbnailCall.request({ data: data }));

    }
  }, [uploadCustomProductData?.data?.file_key]);

  useEffect(() => {
    if (
      !changeVideoThumbnailData?.isLoading &&
      !changeVideoThumbnailData?.isError
    ) {
      dispatch(changeVideoThumbnailCall.reset());
      dispatch(
        getVideoDetailsCall.request({ data: { content_id: params.id } })
      );
    }
  }, [changeVideoThumbnailData?.isLoading]);

  useEffect(() => {
    if (!videoDetails?.isLoading && videoInfo) {
      setTitle(videoInfo?.title);
      setDescription(videoInfo?.description ?? "");
    }
  }, [videoInfo, videoDetails?.isLoading]);

  const handleTitleChange = (e: any) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e: any) => {
    e === "<p><br></p>" ? setDescription("") : setDescription(e);
  };

  const uploadDate = () => {
    let uploaded = new Date(videoInfo?.created_at);
    return (
      uploaded.toDateString() + " at " + uploaded.toLocaleTimeString("en-US")
    );
  };

  const editDate = () => {
    let edited = new Date(videoInfo?.updated_at);
    return videoInfo?.updated_at
      ? edited.toDateString() + " at " + edited.toLocaleTimeString("en-US")
      : "-";
  };

  const handleChangeThumbnail = async (newThumbnail: any) => {
    let blobFile = await fetch(newThumbnail).then((r) => r.blob());
    let file = new File([blobFile], "thumbnail.jpeg");
    let formData = new FormData();
    formData.append("file", file);
    dispatch(uploadCustomProductCall.request({ data: formData }));
  };

  const changeThumbnailCallback = (imageUrl: any) => {
    handleChangeThumbnail(imageUrl);
  };

  const onSaveDetails = () => {
    dispatch(
      saveVideoDetailsCall.request({
        data: {
          content_id: videoInfo?.content_id ?? "",
          title: title,
          description: description,
        },
      })
    );
  };

  const checkCharacterCount = (event: any) => {
    const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
    if (unprivilegedEditor.getLength() > 1000 && event.key !== "Backspace")
      event.preventDefault();
  };

  return !videoDetails?.isLoading && videoInfo ? (
    <Box>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <FormLabel>
            {t("Title")}
            <span style={{ color: "#FF0000" }}>*</span>
          </FormLabel>
          <TextField
            name="title"
            size="small"
            value={title}
            onChange={(e) => handleTitleChange(e)}
            sx={detailStyle.inputField}
            inputProps={{ maxLength: 100 }}
          ></TextField>
          <Box sx={changeThumbnail.textBox}>
            <FormLabel>
              {t("Description")}
              <span style={{ color: "#FF0000" }}>*</span>
            </FormLabel>
            {
              <ReactQuill
                id={"description"}
                theme="snow"
                value={description}
                onKeyDown={checkCharacterCount}
                ref={reactQuillRef}
                onChange={handleDescriptionChange}
                style={
                  {
                    ".ql-editor": {
                      height: "200px",
                    },
                  } as React.CSSProperties
                }
              />
            }
          </Box>

          {!isMobile && (
            <Box sx={detailStyle.saveBtnOuter}>
              <Button
                variant="contained"
                onClick={onSaveDetails}
                disabled={title.length < 1 || description.length < 1}
                sx={
                  title.length < 1 || description.length < 1
                    ? {
                      ...stylesGlobal.containedBtn,
                      backgroundColor: "#989898",
                    }
                    : stylesGlobal.containedBtn
                }
              >
                {t("Save details")}
              </Button>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box sx={detailStyle.thumbnailOuter}>
            <Box sx={detailStyle.thumbnailInfoRow}>
              <Box width="35%">
                <FormLabel>{t("Uploaded")}:</FormLabel>
              </Box>
              <Box width="65%">
                <StyledText>{uploadDate()}</StyledText>
              </Box>
            </Box>

            <Box sx={detailStyle.thumbnailInfoRow}>
              <Box width="35%">
                <FormLabel>{t("Last edited")}:</FormLabel>
              </Box>
              <Box width="65%">
                <StyledText>{editDate()}</StyledText>
              </Box>
            </Box>

            <Box
              sx={
                isMobile
                  ? detailStyle.thumbnailInfoRowMobile
                  : detailStyle.thumbnailInfoRow
              }
            >
              <Box width="35%">
                <FormLabel>{t("Thumbnail")}:</FormLabel>
              </Box>
              <Box width={isMobile ? "100%" : "65%"}>
                <Box
                  sx={
                    isMobile
                      ? detailStyle.thumbnailImgOuterMobile
                      : detailStyle.thumbnailImgOuter
                  }
                >
                  <Box sx={detailStyle.thumbnailImgContain}>
                    <img
                      src={videoInfo?.thumbnail_url ?? ""}
                      alt="video"
                      style={{
                        width: "auto",
                        height: "100%",
                        maxWidth: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>

                  <Box sx={detailStyle.changeThumbnailbtnOuter}>
                    <StyledText>
                      {videoInfo?.video?.duration?.toFixed(2)}s
                    </StyledText>
                    <Button
                      variant="outlined"
                      sx={changeThumbnail.buttonStyle}
                      onClick={() => setOpenThumbnailModal(true)}
                    >
                      {t("Change thumbnail")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            {isMobile && (
              <Box sx={detailStyle.saveBtnOuterMobile}>
                <Button
                  variant="contained"
                  onClick={onSaveDetails}
                  disabled={title.length < 1 || description.length < 1}
                  sx={
                    title.length < 1 || description.length < 1
                      ? {
                        ...stylesGlobal.containedBtn,
                        backgroundColor: "#989898",
                      }
                      : stylesGlobal.containedBtn
                  }
                >
                  {t("Save details")}
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
        <FloatModal
          modalTitle={t("Upload thumbnail")}
          onClose={() => setOpenThumbnailModal(false)}
          modalBody={
            <FloatThumbnailBody
              changeThumbnailCallback={(e: any) => changeThumbnailCallback(e)}
              onClose={() => setOpenThumbnailModal(false)}
              type="video"
            />
          }
          open={openThumbnailModal}
        />
      </Grid>
      <Box sx={{ mt: 2 }}>
        <Accordion sx={{ borderBottom: 1, borderColor: THIN_BORDER_COLOR, boxShadow: 0 }}>
          <AccordionSummary expandIcon={<ExpandMore />} id="accordion-edit-title">
            <Typography variant="h6">Tutorial (click to view/hide)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack>
              <Typography sx={{ fontWeight: "bold", mt: 4 }}>Edit title (optional)</Typography>
              <p>When the video is uploaded, the name of the file becomes the video title by default.</p>
              <p>Edit the title by deleting and replacing the text in the Title field.</p>
              <img src={ClickEditTutorial} style={{ maxWidth: 800, height: "auto" }} />

              <Typography sx={{ fontWeight: "bold", mt: 6 }}>Add description (required)</Typography>
              <p>The Description field is required to publish the video. It's also the text that will help customers become interested in clicking on your video to watch it. An enticing description can give your video more clicks and longer viewtimes.</p>
              <p>The description shows on the video post feeds and the video post page.</p>
              <p>Type a description or copy and paste from another document.</p>
              <img src={AddDescriptionTutorial} style={{ maxWidth: 800, height: "auto" }} />

              <Typography sx={{ fontWeight: "bold", mt: 6 }}>Change video thumbnail (optional)</Typography>
              <p>When The video is uploaded, a video thumbnail is randomly selected from near the start of the video. You can change the default thumbnail to one you have created.</p>
              <p>Change the default thumbnail to one you have created.</p>
              <p>Click the "change thumbnail" button and upload your thumbnail image.</p>
              <p>After you upload the file, you can change the size, positioning, and cropping of the thumbnail.</p>
              <img src={ChangeThumbnailTutorial} style={{ maxWidth: 800, height: "auto" }} />
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  ) : (
    <Loading />
  );
};

export default DetailsVideo;
