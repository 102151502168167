import React, { useEffect, useRef, useState } from 'react';
import { StyledTableCell, StyledTableRow, StyledText } from '../../../utils/styledComponents';
import { Box,  Stack } from '@mui/material';
import FloatDotIndecator from '../../../shared/components/FloatDotIndecator';
import { useTranslation } from 'react-i18next';
import { productsRowStyles } from './style';


const CreatorLibraryProductsRow = ({row}:any) => {
  const {item_details} = row
    const ref = useRef<HTMLDivElement | null>(null);
    const {t}= useTranslation();
    
    const [isNavRefLeft, setNavLeft] = useState(true);
    const [scrollableNav, setScrollable] = useState<boolean>(true);
  
    const handleScroll = () => {
      if (ref.current) {
        const scrollLeft = ref.current.scrollLeft;
        const isAtLeft = scrollLeft === 0;
        setNavLeft(isAtLeft);
      }
    };
  
    useEffect(() => {
      if (ref.current) {
        ref.current.addEventListener("scroll", handleScroll);
        const { scrollWidth, clientWidth } = ref.current;
        const isScrollable = scrollWidth > clientWidth;
        setScrollable(isScrollable);
      }
      return () => {
        if (ref.current) {
          ref.current.removeEventListener("scroll", handleScroll);
        }
      };
    });

    return (
        <StyledTableRow sx={productsRowStyles.rowStyle}>
             <StyledTableCell colSpan={6}>
                <Box>
            <Stack  
            ref={ref}
            className={"overflow-x-stats-mobilenavsroll"} sx={productsRowStyles.productRow}>
                <Stack sx={productsRowStyles.productRowinner}>
                    {item_details?.length > 0  ?   <Stack sx={productsRowStyles.rowTitles}>
                        <StyledText fontWeight="bold">
                        {t("Product name")} :
                        </StyledText>
                        <StyledText>
                        {t("Vendor name")} :
                        </StyledText>
                    </Stack>: t("No products linked for this video")}
                    {item_details?.map((product:any)=>(

                   <Stack sx={productsRowStyles.valuesRow}>
                        <StyledText fontWeight="bold" sx={productsRowStyles.productName}>
                        {product.product_name ?? "-"}
                        </StyledText>
                        <StyledText sx={productsRowStyles.venderName}>
                        {product.vendor_name ?? "-"}
                        </StyledText>
                    </Stack>
                     ))}
                    </Stack>
            </Stack>
            </Box>
            {scrollableNav && (
            <Box sx={productsRowStyles.scrollIndicatorBox}>
              <FloatDotIndecator checked={isNavRefLeft} />
              <FloatDotIndecator checked={!isNavRefLeft} />
            </Box>
          )}
            </StyledTableCell>
        </StyledTableRow>
    );
};

export default CreatorLibraryProductsRow;