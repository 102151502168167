import { THEMEBACKGROUNDCOLOR } from "../../../colors";

export const paymentStyle = {
  loaderBox: {
    height: "80vh",
    width: "80vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  screenHeading: {
    mb: "16px",
  },
  subHeading: {
    mb: "8px",
  },
  amountWrapper: {
    padding: "14px 31px",
    background: THEMEBACKGROUNDCOLOR,
    display: "flex",
    columnGap: "32px",
    flexWrap: "wrap",
    rowGap: "16px",
    mb: "24px",
    width: "fit-content",
    amountItem: {
      padding: "12px 46px",
      display: "flex",
      flexDirection: "column",
      rowGap: "4px",
      background: "#fff",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  noDataWrapper: {
    padding: "12px 20px",
    width: "fit-content",
    background: THEMEBACKGROUNDCOLOR,
    borderRadius: "5px",
  },
};

export const analyticsStyles = {
  gridBox: { padding: "15px", backgroundColor: THEMEBACKGROUNDCOLOR ,height:"calc(100% - 30px) !important" },
  stackStyle: { background: "#fff", padding: "10px" },
  stackBox: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  boxBG: {
    backgroundColor: THEMEBACKGROUNDCOLOR,
    padding: "15px",
    mt: "15px",
    mb: "10px",
    width: {
      xs: "90%",
      lg: "60%"
    }
  },
  whiteBG: { background: "#fff" },
};
