import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
import { USER_PRODUCTS } from "../actions/actiontypes";
const { REQUEST, EDIT_ITEM } = actionTypes;

function* editProductSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.postEditProduct,
      data.data
    );
    yield sendPayload(apiResponse, EDIT_ITEM);

    if (apiResponse) {
      const productsListCall: Generator<string, number, string> = yield call(
        ApiService.getUserProducts,
        {}
      );
      yield sendPayload(productsListCall, USER_PRODUCTS);
    }
  } catch (e) {
    yield sendPayloadFailure(e, EDIT_ITEM);
  }
}

export const sagas = {
  watchEditProduct: takeLatest(actionTypes.EDIT_ITEM[REQUEST], editProductSaga),
};
