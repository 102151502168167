import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
import { USER_PRODUCTS } from "../actions/actiontypes";
const { REQUEST, DELETE_PRODUCT } = actionTypes;

//write sagas function

function* deleteProductSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.deleteProduct,
      data.data
    );
    yield sendPayload(apiResponse, DELETE_PRODUCT);

    if (apiResponse) {
      const productsListCall: Generator<string, number, string> = yield call(
        ApiService.getUserProducts,
        {}
      );
      yield sendPayload(productsListCall, USER_PRODUCTS);
    }
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_PRODUCT);
  }
}

export const sagas = {
  watchdeleteProduct: takeLatest(
    actionTypes.DELETE_PRODUCT[REQUEST],
    deleteProductSaga
  ),
};
