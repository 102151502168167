import { THIN_BORDER_COLOR } from "../../../colors";
import { stylesGlobal } from "../../../utils/muiStyles";

export const styles = {
  addBtnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  addProductBtn: {
    ...stylesGlobal.containedBtn,
    padding: "5px 20px",
    fontWeight: "14px",
  },
  hookBtn: {
    ...stylesGlobal.containedBtn,
    width: "200px",
    fontSize: "16px",
    fontWeight: "500",
    padding: "12px",
    mb: "20px",
  },
  unHookBtn: {
    ...stylesGlobal.outlinedBtn,
    width: "200px",
    fontSize: "16px",
    fontWeight: "500",
    padding: "12px",
    mb: "20px",
    border: "1px solid #000",
  },
};
export const LinkProductStyles = {
  wrapperBox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    columnGap: "15px",
  },
  loaderBox: {
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  tableContainer: {
    marginTop: "20px",
    table: {
      border: `1px solid ${THIN_BORDER_COLOR}`,
    },
    titleBox: {
      textOverflow: "ellipsis",
      height: "65px",
      width: "100%",
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "0px",
      },
    },
    rowStyle: {
      cursor: "pointer",
    },
    rowStyleMobile: {
      cursor: "pointer",
      width: "100%",
    },
  },
  btnLoader: { mr: "5px", color: "#444" },
  boxStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px",
  },
  stackStyle:{
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    mb: "12px",
  },
  wrapper:{ border: `1px solid ${THIN_BORDER_COLOR}`, p: "20px 0 20px 20px" },
  boxHeight:{ height: "240px" },
  productStack:{ p: "4px 12px", width: "100%", alignItems: "start" },
  itemStack:{ width: "100%", justifyContent: "center", alignItems: "center" },
  styledStack:{
    flexDirection: "row",
    width: "100%",
    maxWidth: "300px",
    justifyContent: "space-between",
  }
};
