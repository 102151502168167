import { THIN_BORDER_COLOR } from "../../../../colors";

export const linkedProductStyles={
boxStyle:{
    width: { xs: "100%", md: "calc(100% - 170px)" },
    minHeight: "350px",
    border: `1px solid ${THIN_BORDER_COLOR}`,
    borderRight: `1px solid ${THIN_BORDER_COLOR}`,
    borderBottom: `1px solid ${THIN_BORDER_COLOR}`,
  },
  formControlStyle:{
    p: { xs: "0", sm: "0" },
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #333",
    },
  },
  textStyle:{
    fontSize: "14px !important",
    lineHeight: "1.45rem",
    fontFamily: "Jost !important",
  },
  headRow:{ display: "flex", justifyContent: "space-between" },
  buttonStyle:{ textTransform: "none" },
  accordionStyle:{ padding: "0px !important" },
  accordionSummary:{ padding: "0px !important" },
  tablecell:{ padding: "8px 4px !important" },
  StyledTableCell:{
    width: "inherit !important",
    padding: "8px 2px !important",
  },
  cellStyle:{ padding: "8px 4px !important" },
  styleButtonStyle:{ textTransform: "none", mt: "10px" },
  gridStyle:{ display: "flex", alignItems: "center" },
  detailKeyStyle:{fontWeight:"600 !important"}
  
}