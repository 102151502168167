import React, { useEffect, useRef, useState } from "react";
import { StyledText } from "../../../utils/styledComponents";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CopySvg from "../../../utils/icons/CopySvg";
import MediaFacebookIcon from "../../../utils/icons/MediaFacebookIcon";
import MediaTwitterIcon from "../../../utils/icons/MediaTwitterIcon";
import MediaWhatsappIcon from "../../../utils/icons/MediaWhatsappIcon";
import MediaPiinterest from "../../../utils/icons/MediaPiinterest";
import { MarketingToolStyle } from "./style";
import DownloadSvg from "../../../utils/icons/DownloadSvg";
import FLoatDropDown from "../../../shared/components/FLoatDropDown";
import { CONSTANTS } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, favoriteProduct, getAffiliateProfile, getMediaAssets, getProductLink, marketingProductLink, saveProductLink } from "../../../actions";
import { RootState } from "../../../configureStore";
import { useTranslation } from "react-i18next";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { muiStyle } from "../../RecentViewed/styles";
import DeleteIcon from "../../../utils/icons/DeleteIcon";
import DeleteSvg from "../../../utils/icons/DeleteSvg";
import FloatAlert from "../../../shared/components/FloatAlert";
import ExpandSVG from "../../../utils/icons/ExpandSVG";
import { useNavigate } from "react-router-dom";

interface IMediaItem {
  media_id: number;
  media_url: string;
  product_link: string | null;
  type: string;
  metadata: any | null;
  filename: string;
  size: number;
  width: number;
  height: number;
  created: string;
  caption: string | null;
  media_thumb: string;
  media_description: string | null;
  media_category: string;
}

interface IproductData {
  id: number,
  desc: string,
  image?: string,
  url: string,
  title: string,
  isFavorite: number,
}

function MarketingTools() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const [productLink, setProductLink] = useState<string>("");
  const [generatedLink, setGeneratedLink] = useState<string>("");
  const [tooltipOpenCustomer, setTooltipOpenCustomer] = useState<string>();
  const [tooltipOpen, setTooltipOpen] = useState<number>();
  const [productLinkData, setProductLinkData] = useState<IproductData[]>([])
  const [filterValue, setFilterValue] = useState<any>({
    category: "",
  });
  const [favoriteData, setFavotiteData] = useState<{ id: number, status: boolean }>({
    id: 0,
    status: false
  })
  const [openAlert, setAlert] = useState<boolean>(false);
  const [deleteProductId, setDeleteProductId] = useState<number>()

  const productLinkChange = (event: any) => {
    setProductLink(event.target.value);
  };

  const generatedLinkChange = (event: any) => {
    setGeneratedLink(event.target.value);
  };

  const { marketingProductLinkData }: any = useSelector(
    (state: RootState) => state?.entities?.marketingProductLink
  );

  const { mediaAssets }: any = useSelector(
    (state: RootState) => state?.entities?.mediaAssets
  );

  const { saveProductLinkData }: any = useSelector(
    (state: RootState) => state?.entities?.mediaAssets
  );

  const { getProductLinkData }: any = useSelector(
    (state: RootState) => state?.entities?.mediaAssets
  );

  const { favoriteProductData }: any = useSelector(
    (state: RootState) => state?.entities?.mediaAssets
  );

  const { deleteProductData }: any = useSelector(
    (state: RootState) => state?.entities?.mediaAssets
  );

  const { affiliateProfileData }: any = useSelector(
    (state: RootState) => state?.entities?.affiliateProfile
  );

  const refCode = affiliateProfileData && affiliateProfileData?.data?.ref_code

  const url = `https://business.pyvit.com/shop/${refCode}`

  const handleClick = () => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (!favoriteProductData.isLoading && !favoriteProductData.isError) {
      setProductLinkData((prevData) =>
        prevData.map((item) =>
          item.id === favoriteData.id
            ? { ...item, isFavorite: favoriteData.status ? 1 : 0 }
            : item
        )
      );
    }
  }, [favoriteProductData.isLoading, favoriteData]);

  useEffect(() => {
    if (!deleteProductData.isLoading && !deleteProductData.isError) {
      setProductLinkData((prevData) =>
        prevData.filter((item) => item.id !== deleteProductId)
      );
    }
  }, [deleteProductData.isLoading]);

  useEffect(() => {
    dispatch(getMediaAssets.request({ category: "" }));
    dispatch(getProductLink.request())
    dispatch(getAffiliateProfile.request({}));
    return () => {
      setProductLink("");
      setGeneratedLink("");
    };
  }, []);

  useEffect(() => {
    if (!saveProductLinkData?.isLoading && !saveProductLinkData?.isError) {
      dispatch(getProductLink.request())
      setProductLink("")
      setGeneratedLink("")
    }
  }, [saveProductLinkData.isLoading])

  useEffect(() => {
    if (
      marketingProductLinkData?.data?.data &&
      productLink &&
      !marketingProductLinkData?.isError
    ) {
      setGeneratedLink(marketingProductLinkData.data.data); // Set generated link from API response
    }
  }, [marketingProductLinkData]);

  useEffect(() => {
    if (!getProductLinkData?.isLoading && getProductLinkData?.data?.links) {
      setProductLinkData(getProductLinkData?.data?.links)
    }
  }, [getProductLinkData?.isLoading])


  const handleFilter = (e: any) => {
    let name = e.target.name;
    let data = {
      category: name === "category" ? e.target.value : filterValue.category,
    };
    setFilterValue({ ...filterValue, ...data });
    dispatch(getMediaAssets.request({ ...filterValue, ...data }));
  };

  const media = [
    {
      icon: <MediaFacebookIcon height="22" width="22" />,
      name: "Facebook",
      url: (url: string) =>
        `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    },
    {
      icon: <MediaTwitterIcon height="22" width="22" />,
      name: "Twitter",
      url: (url: string) => `https://twitter.com/intent/tweet?url=${url}`,
    },
    {
      icon: <MediaPiinterest height="22" width="22" />,
      name: "Pinterest",
      url: (url: string) =>
        `https://pinterest.com/pin/create/button/?url=${url}`,
    },
    {
      icon: <MediaWhatsappIcon height="22" width="22" />,
      name: "Whatsapp",
      url: (url: string) => `https://wa.me/?text=${url}`,
    },
  ];

  const handleCopyClick = (text: any) => {
    navigator.clipboard
      .writeText(text)
      .then(() => { })
      .catch((error) => {
        console.error("Failed to copy text to clipboard:", error);
      });
  };

  const handlePaste: any = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = event.clipboardData.getData("text");
    if (/(https?:\/\/[^\s]+)/.test(pastedText)) {
      dispatch(marketingProductLink.request({ product_link: pastedText }));
    } else {
    }
  };

  const handleCopyLink = async (link: string, id?: number) => {
    navigator.clipboard.writeText(link);
    try {
      await navigator.clipboard.writeText(link);
      setTooltipOpenCustomer(link);
      id ? setTooltipOpen(id) : setTooltipOpen(-1)
      setTimeout(() => {
        setTooltipOpenCustomer("");
        setTooltipOpen(-1)
      }, 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  const handleDownload = async (imageUrl: any, filename: any) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const reader: any = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64Data = reader.result as string;
        const link = document.createElement("a");
        link.href = base64Data;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const handleSaveLink = () => {
    dispatch(saveProductLink.request({
      "url": generatedLink,
      "type": "manual"
    }))
  }

  const handleFavorite = (status: boolean, id: number) => {
    setFavotiteData({
      id,
      status
    })
    if (favoriteProductData && !favoriteProductData.inProgress)
      dispatch(favoriteProduct.request({
        id,
        data: {
          "status": status
        }
      }))
  }

  const handleDeleteClick = (id: number) => {
    setDeleteProductId(id)
    setAlert(true)
  }

  return (
    <Box>
      <Box sx={MarketingToolStyle.headerBox}>
        <Box
          sx={
            isSmallScreen
              ? MarketingToolStyle.headerBox.headerItemMobile
              : MarketingToolStyle.headerBox.headerItem
          }
        >
          <Box>
            <StyledText fontvalue="18px" lineheight="24px">
              {t("Product links")}
            </StyledText>
            <StyledText fontvalue="14px" lineheight="18px">
              {t(
                "Browse the store for qualifying products. Paste the URL below to generate an affiliate link"
              )}
            </StyledText>
          </Box>
          <Box
            sx={MarketingToolStyle.productBox}
          >
            <Box
              sx={MarketingToolStyle.productBox.productItem}
            >
              <StyledText fontvalue="12px" lineheight="22px">
                {t("Paste product page link")}
              </StyledText>
              <TextField
                fullWidth
                size="small"
                onChange={productLinkChange}
                onPaste={handlePaste}
                value={productLink}
                type="text"
              />
            </Box>
            <Box
              sx={MarketingToolStyle.productBox.productItem}
            >
              <StyledText fontvalue="12px" lineheight="22px">
                {t("Your generated link")}
              </StyledText>
              <TextField
                fullWidth
                size="small"
                value={generatedLink}
                onChange={generatedLinkChange}
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={MarketingToolStyle.productBox.loadingBox}
          >
            {
              generatedLink &&
              <Button
                variant="contained"
                onClick={handleSaveLink}
                sx={{
                  textTransform: "none"
                }}
              >
                {t("Save")}
              </Button>
            }
          </Box>
          <StyledText fontvalue="12px" lineheight="16px" textAlign="center">
            {t(
              "Use this link to PYV products in your PYVIT videos, or share this link on your blog, Facebook, Instagram, etc. When people visit the store using your link, you get commissions on everything they buy for the next 24 hours"
            )}
          </StyledText>
        </Box>
      </Box>
      {
        productLinkData.length > 0 &&
        <Box sx={MarketingToolStyle.boxMargin}
        >
          {
            getProductLinkData?.isLoading ?
              <Box
                sx={MarketingToolStyle.productBox.loadingBox}
              >
                <CircularProgress />
              </Box>
              :
              <>
                <Box
                  sx={MarketingToolStyle.historyBox}
                >
                  <Box
                    sx={MarketingToolStyle.historyLabel}
                  >
                    <StyledText fontvalue="18px" lineheight="22px">
                      {t("History")}
                    </StyledText>
                    <Box
                      onClick={handleClick}
                      style={MarketingToolStyle.pointer}
                    >
                      <ExpandSVG />
                    </Box>
                  </Box>
                </Box>
                <Grid container spacing={2}>
                  {
                    productLinkData.map((item: IproductData) => (
                      <Grid key={item.id} item xs={12} sm={6} md={6} lg={4}>
                        <Box
                          sx={MarketingToolStyle.historyProduct}
                        >
                          {
                            item.image &&
                            <Box sx={muiStyle.boxStyle}>
                              <img
                                className="product-transition"
                                style={{
                                  width: "100%",
                                  height: isSmallScreen ? "10rem" : "15rem",
                                  objectFit: "cover",
                                  cursor: "pointer",
                                }}
                                src={item.image}
                                alt="image"
                              />
                            </Box>
                          }
                          <Box>
                            <StyledText fontvalue="14px" lineheight="16px"
                              sx={MarketingToolStyle.textBreak}
                            >
                              {item.title}
                            </StyledText>

                          </Box>
                          <Box>
                            <StyledText fontvalue="12px" lineheight="14px"
                              sx={MarketingToolStyle.textStyle}
                            >
                              {item.url}
                            </StyledText>
                            <span
                              onClick={() => handleCopyClick(item?.url)}
                              style={MarketingToolStyle.pointer}
                            >
                              <Tooltip
                                open={tooltipOpen === item.id}
                                onClose={() => setTooltipOpen(item.id)}
                                title={t("Link copied!")}
                              >
                                <IconButton
                                  onClick={() => handleCopyLink(item?.url, item.id)}
                                  style={MarketingToolStyle.pointer}
                                >
                                  <CopySvg />
                                </IconButton>
                              </Tooltip>
                            </span>
                          </Box>
                          <Box
                            sx={MarketingToolStyle.productBox.button}
                          >
                            <Box sx={MarketingToolStyle.cardBody.mediaIcons}>
                              <StyledText fontvalue="14px" lineheight="18px">{t("Share on")}</StyledText>
                              {media.map((media: any) => {
                                return (
                                  <IconButton
                                    onClick={() => {
                                      window.open(media.url(item?.url));
                                    }}
                                    sx={MarketingToolStyle.mediaIcons}
                                  >
                                    {media.icon}
                                  </IconButton>
                                );
                              })}
                            </Box>
                            <Box
                              sx={MarketingToolStyle.flexStyle}
                            >
                              {
                                item.isFavorite === 0 ?
                                  <FavoriteBorderIcon
                                    onClick={() => handleFavorite(true, item.id)}
                                    sx={MarketingToolStyle.pointer}
                                  />
                                  :
                                  <FavoriteIcon
                                    onClick={() => handleFavorite(false, item.id)}
                                    sx={MarketingToolStyle.pointer}
                                  />
                              }
                              <Box
                                onClick={() => handleDeleteClick(item.id)}
                                sx={MarketingToolStyle.pointer}
                              >
                                <DeleteSvg />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    ))
                  }
                </Grid>
              </>
          }
        </Box>
      }

      <Box sx={MarketingToolStyle.filterWrapper}>
        <StyledText fontvalue="16px" lineheight="24px">
          {t("Media assets")}
        </StyledText>
        <FLoatDropDown
          label="Filter data"
          value={filterValue?.category}
          onChange={handleFilter}
          list={CONSTANTS.FilterVideo}
          name={"category"}
        />
      </Box>
      <Box sx={MarketingToolStyle.card}>
        {mediaAssets?.isLoading ? (
          <Box sx={MarketingToolStyle.flexBoxStyle}>
            <CircularProgress />
          </Box>
        ) : (
          mediaAssets?.data?.creatives.map((item: any, index: any) => (
            <Box
              sx={
                isSmallScreen
                  ? MarketingToolStyle.card.cardItemMobile
                  : MarketingToolStyle.card.cardItem
              }
              key={index}
            >
              <img src={item.thumbnail} />
              <Box sx={MarketingToolStyle.cardBody}>
                <Box sx={MarketingToolStyle.cardBody.header}>
                  <Box sx={MarketingToolStyle.cardBody.header.item}>
                    <StyledText>{(item?.size / 1024).toFixed(2)}</StyledText>
                    <StyledText>.</StyledText>
                    <StyledText>
                      {item?.width}x{item?.height}
                    </StyledText>
                  </Box>
                  <Box sx={MarketingToolStyle.cardBody.header.IconItem}>
                    <input
                      type="text"
                      ref={inputRef}
                      value={item.id}
                      readOnly
                      style={{ position: "absolute", left: "-9999px" }}
                    />
                    <span
                      onClick={() => handleCopyClick(item?.id)}
                      style={MarketingToolStyle.pointer}
                    >
                      <Tooltip
                        open={tooltipOpenCustomer === item?.id}
                        onClose={() => setTooltipOpenCustomer(item?.id)}
                        title={t("Link copied!")}
                      >
                        <IconButton
                          onClick={() => handleCopyLink(item?.id)}
                          style={MarketingToolStyle.pointer}
                        >
                          <CopySvg />
                        </IconButton>
                      </Tooltip>
                    </span>
                    <IconButton
                      onClick={() =>
                        handleDownload(item?.thumbnail, item?.name)
                      }
                    >
                      <DownloadSvg />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={MarketingToolStyle.cardBody.mediaIcons}>
                  {media.map((media: any) => {
                    return (
                      <IconButton
                        onClick={() => {
                          window.open(media.url(item?.url));
                        }}
                        sx={MarketingToolStyle.pointer}
                      >
                        {media.icon}
                      </IconButton>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          ))
        )}
      </Box>

      <FloatAlert
        open={openAlert}
        onClose={() => {
          setAlert(false);
        }}
        alert={t("Are you sure you want to delete?")}
        action={() => {
          deleteProductId && dispatch(deleteProduct.request(deleteProductId))
          setAlert(false)
        }
        }
      />
    </Box>
  );
};

export default MarketingTools;