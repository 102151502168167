/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Checkbox,
  CircularProgress,
  Autocomplete,
  InputAdornment,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { createAddress, getAllAddresses } from "../../../actions";
import Loading from "../../../shared/Loading";
import ListAddresses from "./ListAddresses";
import { RootState } from "../../../configureStore";
import { AllAdresses } from "../../../models/backoffice.models";
import { CountryMobileData } from "../../../utils/constants";
import { ICountryData } from "../../../models/types.model";
import { styles } from "./style";
import { addressShema, initialValues } from "./addressSchema";
import {
  FormLabel,
  Info,
  StyledText,
  SubTitle1,
  Title,
} from "../../../utils/styledComponents";
import { iskeyInObject } from "../../../utils/hooks";

function AccountAddress() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loader, setLoader] = useState<boolean>(true);
  const [addresses, setAddresses] = useState<AllAdresses>();
  const [mobCode, setMobCode] = useState<string>("");
  const [saveLoader, setSaveLoader] = useState<boolean>(false);

  const { allAddresses } = useSelector(
    (state: RootState) => state?.entities?.accountAddress
  );
  const { addressCreateData } = useSelector(
    (state: RootState) => state?.entities?.createAccountAddress
  );

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    setFieldError
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addressShema,
    onSubmit: () => {
      const phone = values.phone.toString();
      const zip = values.zip.toString();
      const countryCode = mobCode.toString();

      if (phone !== "" && mobCode === t("Select country code")) {
        setFieldError('phone', t("Phone number must be in the format '+<country code><10-digit number>'"))
        return;
      }

      const mobile = phone !== "" ? countryCode + phone : ""

      dispatch(
        createAddress.request({
          data: { ...values, phone: mobile, zip },
        })
      );
      setSaveLoader(true);
    },
  });

  useEffect(() => {
    const filteredCountryData = CountryMobileData.filter(
      (item) => item.name === values.country
    );
    if (filteredCountryData.length > 0) {
      const mCode = filteredCountryData[0].phoneNumberPrefix;
      setMobCode(mCode.toString());
    }
  }, [values.country]);

  const addressFields = useMemo(
    () => [
      {
        label: "First name",
        id: "first_name",
        name: "first_name",
        value: values.first_name ?? "",
        error: touched?.first_name && !!errors?.first_name,
        helperText: touched?.first_name && errors?.first_name,
        type: "text",
      },
      {
        label: "Last name",
        id: "last_name",
        name: "last_name",
        value: values.last_name ?? "",
        error: touched?.last_name && !!errors?.last_name,
        helperText: touched?.last_name && errors?.last_name,
        type: "text",
      },
      {
        label: "Address",
        id: "address1",
        name: "address1",
        value: values.address1 ?? "",
        error: touched?.address1 && !!errors?.address1,
        helperText: touched?.address1 && errors?.address1,
        type: "text",
      },
      {
        label: "City",
        id: "city",
        name: "city",
        value: values.city ?? "",
        error: touched?.city && !!errors?.city,
        helperText: touched?.city && errors?.city,
        type: "text",
      },
      {
        label: "Country/region",
        id: "country",
        name: "country",
        value: values.country ?? "",
        error: touched?.country && !!errors?.country,
        helperText: touched?.country && errors?.country,
        type: "selectField",
      },
      {
        label: "Postal/zip code",
        id: "zip",
        name: "zip",
        value: values.zip ?? "",
        error: touched?.zip && !!errors?.zip,
        helperText: touched?.zip && errors?.zip,
        type: "number",
      },
      {
        label: "Phone number",
        id: "phone",
        name: "phone",
        value: values.phone ?? "",
        error: touched?.phone && !!errors?.phone,
        helperText: touched?.phone && errors?.phone,
        type: "text",
      },
    ],
    [values, errors, touched]
  );

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (allAddresses?.isLoading) {
      setLoader(true);
    }
    if (!allAddresses?.isLoading) {
      setAddresses(allAddresses?.data);
      setLoader(false);
    }
  }, [allAddresses?.isLoading, allAddresses?.data?.additional?.length]);

  useEffect(() => {
    if (!addressCreateData?.isLoading) {
      dispatch(getAllAddresses.request({}));
      setSaveLoader(false);
    }
    if (addressCreateData?.isError) {
      setSaveLoader(false);
    } else {
      values.country = "";
      setFieldValue("country", "");
      dispatch(createAddress.reset());
      setMobCode("");
      resetForm();
      setSaveLoader(false);
    }
  }, [addressCreateData.isLoading]);

  const getAll = () => {
    dispatch(getAllAddresses.request({}));
  };

  const renderOptions = (optionArray: ICountryData[]) => {
    const options = optionArray.map((item) => ({
      label: item.name,
      value: item.name,
    }));
    return options;
  };

  const renderMobCodeOptions = (optionArray: ICountryData[]) => {
    const options = optionArray.map((item) => ({
      countryCode: item.code,
      phoneCode: item.phoneNumberPrefix,
    }));
    return options;
  };

  const handleMobileCodeChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setMobCode(e.target.value);
  };

  return !loader ? (
    <Stack sx={styles.outer}>
      <Stack sx={styles.stackStyle}>
        <Title>{t("Addresses")} </Title>
        {iskeyInObject(allAddresses?.data,"default") ? (
          <>
            <SubTitle1>{t("Default address")}</SubTitle1>
            <ListAddresses
              address={addresses?.default}
              getAll={getAll}
              setAddresses={setAddresses}
            />
          </>
        ) : (
          <Info>{t("No addresses to show")}</Info>
        )}
        {addresses?.additional?.length ? (
          <SubTitle1 variant="h6" style={{ marginTop: "10px" }}>
            {t("Additional addresses")}
          </SubTitle1>
        ) : null}
        {addresses?.additional?.map((item, index) => (
          <ListAddresses
            key={index}
            address={item}
            getAll={getAll}
            setAddresses={setAddresses}
          />
        )
        )}
      </Stack>
      <Stack sx={styles.stackStyle}>
        <StyledText fontvalue="32px" lineheight="40px" variant="h4">
          {t("Add new address")}
        </StyledText>
        <Box sx={styles.boxStyle}>
          {addressFields.map((item, index) => {
            if (item.type === "selectField") {
              return (
                <Stack key={index} sx={styles.fieldContain}>
                  <FormLabel>
                    {t(item.label)}
                    <span style={{ color: "#FF0000" }}>*</span>
                  </FormLabel>
                  <Autocomplete
                    disablePortal
                    id={item.id}
                    defaultValue={{ label: "", value: "" }}
                    options={renderOptions(CountryMobileData)}
                    onChange={(
                      event,
                      newValue: { label: string; value: string } | null
                    ) => setFieldValue("country", newValue?.value)}
                    value={{ label: item.value, value: "country" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={styles.field}
                        id={item.id}
                        name={item.name}
                        onChange={handleChange}
                        value={item.value ? item.value : ""}
                        error={item.error}
                        helperText={item.helperText && t(item.helperText)}
                      ></TextField>
                    )}
                  />
                </Stack>
              );
            } else {
              return (
                <Stack key={index} sx={styles.fieldContain}>
                  <FormLabel>
                    {t(item.label)}
                    {index !== 6 && <span style={{ color: "#FF0000" }}>*</span>}
                  </FormLabel>
                  <TextField
                    size="small"
                    type={item.type}
                    sx={styles.field}
                    id={item.id}
                    name={item.name}
                    onChange={handleChange}
                    value={item.value}
                    error={item.error}
                    helperText={item.helperText && t(item.helperText)}
                    InputProps={
                      item.name === "phone"
                        ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              <TextField
                                size="small"
                                select
                                id={`${item.id}-country`}
                                name={`${item.name}-country`}
                                onChange={handleMobileCodeChange}
                                value={
                                  mobCode === undefined || mobCode === ""
                                    ? t("Select country code")
                                    : mobCode
                                }
                                InputProps={{
                                  sx: {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      border: "none",
                                    },
                                  },
                                }}
                              >
                                {renderMobCodeOptions(CountryMobileData).map(
                                  (code, index) =>
                                    code.countryCode === "" ? (
                                      <MenuItem
                                        key={index}
                                        value={t("Select country code")}
                                      >
                                        <StyledText
                                          fontSize="10px"
                                          lineheight="20px"
                                          opacityValue="0.7"
                                          textAlign="left"
                                        >
                                          {t("Select country code")}
                                        </StyledText>
                                      </MenuItem>
                                    ) : (
                                      <MenuItem
                                        key={index}
                                        value={code.phoneCode}
                                      >
                                        {code.phoneCode} ({code.countryCode})
                                      </MenuItem>
                                    )
                                )}
                              </TextField>
                            </InputAdornment>
                          ),
                        }
                        : {}
                    }
                  ></TextField>
                </Stack>
              );
            }
          })}
          <Stack sx={styles.checkOuter}>
            <Checkbox
              id="is_default"
              name="is_default"
              checked={values.is_default}
              onChange={handleChange}
              sx={styles.checkBoxStyle}
            />
            <StyledText sx={styles.checkText}>
              {t("Make default address")}
            </StyledText>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: isSmallScreen ? "center" : "left",
            }}
          >
            <Button
              type="submit"
              onClick={() => handleSubmit()}
              sx={styles.saveBtn}
              variant="contained"
            >
              {saveLoader ? (
                <CircularProgress size="14px" sx={styles.loderStyle} />
              ) : (
                ""
              )}{" "}
              {t("Save")}
            </Button>
          </Box>
        </Box>
      </Stack>
    </Stack>
  ) : (
    <Box height={"100vh"}>
      {" "}
      <Loading />
    </Box>
  );
};

export default AccountAddress;
