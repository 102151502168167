import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../../../../shared/Loading";
import { RootState } from "../../../../configureStore";
import { uniqueVideoTotalViewsCall } from "../../../../actions";
import { StyledTableHeadCell } from "../../../../utils/styledComponents";
import { HOVERPRIMARYCOLOUR } from "../../../../colors";
import { useTranslation } from "react-i18next";
import { viewStyle } from "./styles";

const viewTableStyle = {
  tableHead: {
    background: HOVERPRIMARYCOLOUR,
    height: "50px",
  },
  thCell: {
    padding: "0px",
    color: "#333",
  },
  box: { p: { xs: "20px 0", md: "20px 0px" } },
  tbCell: { p: 1.6, borderBottom: "none", color: "black" },
};

const tableHeader = [
  { key: "Views", value: "Stats type" },
  { key: "total_views", value: "Total" },
  { key: "total_unique_views", value: "Unique" },
  { key: "repeat", value: "Repeat" },
];

function ViewTable() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data, isLoading, isError, errorMessage } = useSelector(
    (state: RootState) =>
      state?.entities?.uniqueVideoViews?.uniqueVideoViewsData
  );

  useEffect(() => {
    dispatch(uniqueVideoTotalViewsCall.request({ data: { videoId: id } }));
  }, [id]);

  // useEffect()

  return (
    <Box sx={viewTableStyle.box}>
      {isLoading ? (
        <Loading />
      ) : (
        !isLoading && (
          <TableContainer
            component={Paper}
            style={{
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
              overflowY: "auto",
            }}
          >
            <Table aria-label="collapsible table" size="small">
              <TableHead sx={viewTableStyle.tableHead}>
                <TableRow>
                  {tableHeader?.map((header, index) => (
                    <StyledTableHeadCell
                      key={index}
                      align="center"
                      sx={viewTableStyle.thCell}
                    >
                      {t(header.value)}
                    </StyledTableHeadCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  className="table-row"
                  sx={viewStyle.tableRow}
                >
                  {tableHeader?.map((item: any) => {
                    if (item.key === "repeat") {
                      return (
                        <TableCell align="center" sx={viewTableStyle.tbCell}>
                          {parseInt(data?.data?.total_views) -
                            parseInt(data?.data?.total_unique_views)}
                        </TableCell>
                      );
                    } else if (item.key === "total_views") {
                      return (
                        <TableCell align="center" sx={viewTableStyle.tbCell}>
                          {data?.data?.total_views}
                        </TableCell>
                      );
                    } else if (item.key === "total_unique_views") {
                      return (
                        <TableCell align="center" sx={viewTableStyle.tbCell}>
                          {data?.data?.total_unique_views}
                        </TableCell>
                      );
                    } else
                      return (
                        <TableCell align="center" sx={viewTableStyle.tbCell}>
                          {t(item?.key)}
                        </TableCell>
                      );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )
      )}
    </Box>
  );
};

export default ViewTable;
