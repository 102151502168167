import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Styles } from "./style";
import { StyledText } from "../../utils/styledComponents";
import { useTranslation } from "react-i18next";

const MultipleSelectDropdown = (props: any) => {
  const { setUpdatedList, names, label } = props;

  const [personName, setPersonName] = React.useState<string[]>(names);
  const [selectAll, setSelectAll] = React.useState(true);
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;

    if (value?.length == names?.length) {
      setSelectAll(true);
    }
    setPersonName(typeof value === "string" ? value.split(",") : value);
    setUpdatedList(typeof value === "string" ? value.split(",") : value);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
  };

  React.useEffect(() => {
    if (selectAll) {
      setPersonName(names.map((item: string) => t(item)));
      setUpdatedList(names.map((item: string) => t(item)));
    } else {
      setUpdatedList([]);
      setPersonName([]);
    }
  }, [selectAll]);

  return (
    <div>
      <FormControl
        sx={{
          ...Styles.formControl,
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px",
            borderColor: "#E4E3E3 !important",
          },
        }}
        size="small"
      >
        <InputLabel
          id="demo-multiple-checkbox-label"
          sx={{ ...Styles.inputLabel, color: "#111", fontFamily: "Jost" }}
        >
          {t(label) ?? ""}
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName.map((item) => t(item))}
          onChange={handleChange}
          sx={{
            ...Styles.selectStyle,
            fontSize: "14px !important",
            lineHeight: "1rem !important",
            fontFamily: "Jost !important",
          }}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected: any) => selected.join(", ")}
          MenuProps={{ ...Styles.menuProps, disablePortal: true }}
        >
          <MenuItem sx={Styles.menuItem} onClick={handleSelectAll}>
            <Checkbox
              sx={Styles.checkboxStyle}
              checked={names?.length === personName?.length}
            />
            <StyledText>{t("Select all")}</StyledText>
          </MenuItem>
          {names.map((name: any) => (
            <MenuItem sx={Styles.menuItem} key={name} value={t(name)}>
              <Checkbox
                sx={Styles.checkboxStyle}
                checked={
                  personName.map((item) => t(item)).indexOf(t(name)) > -1
                }
              />
              <StyledText>{t(name)}</StyledText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
export default MultipleSelectDropdown;
