export const muiStyle = {
  thumbnailButton: {
    fontWeight: "500",
    textTransform: "none",
    fontSize: "1.4rem",
    mt: "20px",
    p: "10px 30px",
  },
  buttonsConatiner: {
    fontWeight: "500",
    textTransform: "none",
    fontSize: "1.4rem",
    mt: "0px",
    p: { xs: "10px 0", md: "10px 30px" },
  },
};
