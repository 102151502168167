import { PRIMARYCOLOUR, THIN_BORDER_COLOR } from "../../../colors";

export const MarketingToolStyle = {
  headerBox: {
    display: "flex",
    columnGap: "35px",
    mt: "16px",
    headerItem: {
      flex: 1,
      padding: "16px 24px",
      border: `1px solid ${THIN_BORDER_COLOR}`,
      borderBottom: `2px solid ${PRIMARYCOLOUR}`,
      alignSelf: "baseline",
      display: "flex",
      flexDirection: "column",
      rowGap: "15px",
      maxWidth: "100%",
    },
    headerItemMobile: {
      flex: 1,
      padding: "16px 24px",
      border: `1px solid ${THIN_BORDER_COLOR}`,
      borderBottom: `2px solid ${PRIMARYCOLOUR}`,
      alignSelf: "baseline",
      display: "flex",
      flexDirection: "column",
      rowGap: "15px",
      maxWidth: "100%",
    },
    headingBox: {
      display: "flex",
      justifyContent: "space-between",
      history: {
        display: "flex",
        columnGap: "7px",
        alignItems: "center",
      },
    },
  },
  productBox: {
    display: {
      xs:"block",
      md:"flex"
    },
    flexWrap: "wrap",
    columnGap: "28px",
    rowGap: "15px",
    productItem: {
      flex: "1"
    },
    loadingBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    button: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }
  },
  historyLabel: {
    display: "flex",
    columnGap: "8px",
    alignItems: "center"
  },
  boxMargin: {
    mt: "30px",
    mb: "20px"
  },
  filterWrapper: {
    display: "flex",
    justifyContent: "space-between",
    mt: "16px",
    mb: "16px",
    alignItems: "center",
  },
  historyBox: {
    mb: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  historyProduct: {
    border: "1px solid #bfbfbf",
    padding: "16px",
    mb: "16px",
    mr: "16px"
  },
  textBreak: {
    wordBreak: "break-word",
  },
  textStyle: {
    wordBreak: "break-word",
    display: "inline",
    mr: "8px"
  },
  flexStyle: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px"
  },
  card: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
    gridGap: "1rem",
    mt: "16px",
    cardWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
    cardItem: {
      display: "flex",
      flexDirection: "column",
      boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.12)",
      borderBottom: `2px solid ${PRIMARYCOLOUR}`,
      width: "350px",
    },
    cardItemMobile: {
      display: "flex",
      flexDirection: "column",
      boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.12)",
      borderBottom: `2px solid ${PRIMARYCOLOUR}`,
      width: "330px",
    },
  },
  cardBody: {
    padding: "18px 24px",
    header: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      rowGap: "5px",
      item: {
        display: "flex",
        columnGap: "3px",
        flexWrap: "wrap",
        rowGap: "5px",
      },
      IconItem: {
        display: "flex",
        columnGap: "8px",
        alignItems: "center",
      },
    },
    mediaIcons: {
      display: "flex",
      columnGap: "0px",
      alignItems: "center"
    },
  },
  pointer: {
    cursor: "pointer",
  },
  mediaIcons: {
    cursor: "pointer",
    padding: "4px"
  },
  flexBoxStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
