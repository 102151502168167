import { THEMEBACKGROUNDCOLOR } from "../../../colors";

export const analyticsStyles = {
  gridBox: { padding: "15px", backgroundColor: THEMEBACKGROUNDCOLOR ,height:"calc(100% - 30px) !important" },
  stackStyle: { background: "#fff", padding: "10px" },
  stackBox: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  boxBG: {
    backgroundColor: THEMEBACKGROUNDCOLOR,
    padding: "15px",
    mt: "15px",
  },
  whiteBG: { background: "#fff" },
};
