import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import VideoViews from "./views/VideoViews";
import VideoCompletionGraph from "./completion/VideoCompletionGraph";
import LinkedProductsGraph from "./linkedProducts/LinkedProducts";
import { CONSTANTS } from "../../../utils/constants";
import { useTranslation } from "react-i18next";
import { StyledText } from "../../../utils/styledComponents";
import { THIN_BORDER_COLOR } from "../../../colors";
import MobileTabList from "./MobileTabList";
import FloatMobileFilter from "../../../shared/components/FloatMobileFilter";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { linkedProductStyles } from "./linkedProducts/style";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const { t } = useTranslation();

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={linkedProductStyles.boxStyle}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <StyledText>{children}</StyledText>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function AnalyticTabs() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:600px)");

  const [value, setValue] = React.useState(0);
  const [mobileFilters, setMobileFilters]: any = React.useState({});
  const [openMobileDate, setOpenMobileDate] = useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onTabChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(event.target.value));
  };
  const SelectFilters = [
    {
      list: CONSTANTS.analyticsTabList,
      label: "Select",
      name: "tab",
      onChange: onTabChange,
      value: value.toString(),
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={{ marginRight: "1px", display: { xs: "none", md: "block" } }}
        TabIndicatorProps={{ style: { left: 0 } }} // Move the indicator to the left
      >
        <Tab
          label={t("Views")}
          {...a11yProps(0)}
          sx={
            value === 0
              ? {
                  borderBottom: `2px solid ${THIN_BORDER_COLOR}`,
                  borderTop: `1px solid ${THIN_BORDER_COLOR}`,
                  width: "170px",
                  textTransform: "none",
                }
              : { width: "170px", textTransform: "none" }
          }
        />
        <Tab
          label={t("Completion")}
          {...a11yProps(1)}
          sx={
            value === 1
              ? {
                  borderBottom: `2px solid ${THIN_BORDER_COLOR}`,
                  borderTop: `1px solid ${THIN_BORDER_COLOR}`,
                  width: "170px",
                  textTransform: "none",
                }
              : { width: "170px", textTransform: "none" }
          }
        />
        <Tab
          label={t("Linked products")}
          {...a11yProps(2)}
          sx={
            value === 2
              ? {
                  borderBottom: `2px solid ${THIN_BORDER_COLOR}`,
                  borderTop: `1px solid ${THIN_BORDER_COLOR}`,
                  width: "170px",
                  textTransform: "none",
                }
              : { width: "170px", textTransform: "none" }
          }
        />
        <Box sx={{ flexGrow: 1, minHeight: 0 }}></Box>
      </Tabs>

      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          mb: "10px",
          alignItems: "center",
        }}
      >
        {SelectFilters.map((item, index) => (
          <MobileTabList
            key={index}
            list={item.list}
            label={t(item.label)}
            onChange={item.onChange}
            value={item.value}
            name={item.name}
          />
        ))}
        {isMobile && mobileFilters?.SelectFilters?.length && (
          <FloatMobileFilter
            filters={mobileFilters?.filters}
            setFilters={mobileFilters?.setFilters}
            selectFilters={mobileFilters?.SelectFilters}
            onSubmitMobile={mobileFilters?.onSubmit}
            setOpenMobileDate={setOpenMobileDate}
          />
        )}
      </Box>
      <CustomTabPanel value={value} index={0}>
        <VideoViews
          setSelectFilters={setMobileFilters}
          openMobileDate={openMobileDate}
          setOpenMobileDate={setOpenMobileDate}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <VideoCompletionGraph setSelectFilters={setMobileFilters} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <LinkedProductsGraph
          setSelectFilters={setMobileFilters}
          openMobileDate={openMobileDate}
          setOpenMobileDate={setOpenMobileDate}
        />
      </CustomTabPanel>
    </Box>
  );
};

export default AnalyticTabs;
