import React from "react";

const PinterestIcon = ({
  fill = "#D5D5D5",
  width = "1.253rem",
  height = "0.933rem",
}) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0016 3.60001C10.0492 3.60001 3.60156 10.0476 3.60156 18C3.60156 24.1008 7.39716 29.3112 12.754 31.4088C12.628 30.27 12.514 28.5216 12.8044 27.2784C13.066 26.1552 14.4928 20.1204 14.4928 20.1204C14.4928 20.1204 14.062 19.2576 14.062 17.9832C14.062 15.9804 15.2224 14.4864 16.6672 14.4864C17.896 14.4864 18.4888 15.4092 18.4888 16.5144C18.4888 17.7504 17.7028 19.5972 17.296 21.3084C16.9564 22.7424 18.0148 23.9112 19.4284 23.9112C21.988 23.9112 23.9548 21.2124 23.9548 17.3172C23.9548 13.8696 21.478 11.4588 17.9404 11.4588C13.8436 11.4588 11.4388 14.532 11.4388 17.7084C11.4388 18.9456 11.9152 20.2728 12.5104 20.9952C12.628 21.138 12.6448 21.2628 12.61 21.408C12.5008 21.8628 12.2584 22.8408 12.2104 23.0412C12.148 23.3052 12.0016 23.3604 11.7292 23.2332C9.93036 22.3956 8.80596 19.7664 8.80596 17.6544C8.80596 13.1124 12.106 8.94001 18.3208 8.94001C23.3164 8.94001 27.1984 12.4992 27.1984 17.2572C27.1984 22.2204 24.0688 26.214 19.726 26.214C18.2668 26.214 16.8952 25.4556 16.426 24.5604C16.426 24.5604 15.7036 27.3096 15.5284 27.984C15.2032 29.2344 14.326 30.8028 13.738 31.7592C15.0856 32.1744 16.5172 32.4 18.0016 32.4C25.954 32.4 32.4016 25.9524 32.4016 18C32.4016 10.0476 25.954 3.60001 18.0016 3.60001Z"
      fill="black"
    />
  </svg>
);

export default React.memo(PinterestIcon);
