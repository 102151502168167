
export const PRIMARYCOLOUR = "#000";

export const SECONDARYCOLOUR = "#333";

export const HOVERPRIMARYCOLOUR = "#444";

export const HOVERPRIMARYTEXTCOLOUR = "#FFF";

export const THEMEBACKGROUNDCOLOR = "#EEEEEE";

export const MODALHEADERCOLOR = "#D9D9D9";

export const SIDENAV_SELECTEDROUTE_BG = "#555";

export const SIDENAV_SELECTEDSUBROUTE_BG = "#C0C0C0";

export const SIDENAV_SELECTED_TEXT = "#FFF";

export const SIDENAV_HOVER_COLOR = "#848884";

export const SCROLLBAR_THUMB = "#888";

export const THIN_BORDER_COLOR = "#BFBFBF";

export const BOX_SHADOW_COLOR = "#BFBFBF";

export const TABLEHEADBACKGROUNDSHADE2 = "#F7F7F7"