import { Box, CircularProgress, Grid, Table } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  StyledTableCell,
  StyledTableHeadCell,
  StyledTableHeadRow,
  StyledTableRow,
  StyledText,
} from "../../../utils/styledComponents";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../configureStore";
import { getTransactionOrder } from "../../../actions";
import { styles } from "./style";
import { useTranslation } from "react-i18next";
import { THIN_BORDER_COLOR } from "../../../colors";

interface Iprops {
  entityId: number;
}

interface ILineItems {
  name: string;
  quantity: number;
  price: string;
  commission: number;
  total: number;
}

interface IProductData {
  site_order_number: string;
  commission_adjustment?: CommissionAdjustment[];
  customer: {
    first_name: string;
    last_name: string;
    email: string;
  }
  shipping_address: {
    first_name?: string;
    address_1?: string;
    address_2?: string;
    city?: string;
    country?: string;
    state?: string;
    zip?: string;
  };
  affiliate_commission: string;
  order_value: string;
  shipping_price: number;
  coupons: string[];
  line_items: ILineItems[];
}


interface CommissionAdjustment {
  commission_type: string;
  commission_value: any;
  source: string;
  value: any;
}


function TransactionOrdersModal({
  entityId
}: Iprops) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const transactionOrderData = useSelector(
    (state: RootState) => state?.entities?.transactionData.transactionOrderData
  ) as any;

  const selectedTransaction =
    transactionOrderData &&
    transactionOrderData.data &&
    transactionOrderData.data;

  const [isError, setIsError] = useState<boolean>(false);
  const [transactionData, setTransactionOrderData] = useState<any>()
  const [data, setData] = useState({
    Order: "-",
    Customer: "-",
    "Customer mail": "-",
    "Shipping address": "-",
    "Order total": "-",
    Commission: "-",
    table: "",
    Shipping: "-",
    "Coupon code": "-",
  });

  useEffect(() => {
    dispatch(getTransactionOrder.request(entityId));
    return () => {
      setData({
        Order: "-",
        Customer: "-",
        "Customer mail": "-",
        "Shipping address": "-",
        "Order total": "-",
        Commission: "-",
        table: "",
        Shipping: "-",
        "Coupon code": "-",
      });
      setIsError(false);
    };
  }, []);

  useEffect(() => {
    if (
      !transactionOrderData.isLoading &&
      transactionOrderData?.data?.data &&
      !transactionOrderData.isError
    ) {
      setTransactionOrderData(transactionOrderData?.data?.data)
    }
          if (transactionOrderData.isError) {
            setIsError(true);
          }
  }, [transactionOrderData.isLoading]);

  useEffect(() => {
    transactionData && setData({
      Order: transactionData?.site_order_number ?? "-",
      Customer: `${transactionData?.customer?.first_name ?? "-"} ${transactionData?.customer?.last_name ?? "" }`,
      "Customer mail": transactionData?.customer?.email ?? "-",
      "Shipping address":
        transactionData?.shipping_address
          ? Object.entries(transactionData?.shipping_address)
            .filter(
              ([key, value]) =>
                value !== null &&
                value !== undefined &&
                [
                  "address1",
                  "address2",
                  "city",
                  "country",
                  "state",
                  "zip",
                ].includes(key)
            )
            .map(([key, value]) => value)
            .join(", ")
          : "-",
      "Order total": Number(transactionData.order_value).toFixed(2) ?? "-",
      Commission: `$${Number(transactionData.affiliate_commission).toFixed(2)}` ?? "-",
      table: "",
      Shipping: `$${transactionData?.shipping_price}` ?? "-",
      "Coupon code": (transactionData?.coupons).join(",") ?? "-",
    });
  }, [transactionData])

  return (
    <Box>
      {transactionOrderData.isLoading ? (
        <Box sx={styles.noRecord}>
          <CircularProgress />
        </Box>
      ) : isError ? (
        <StyledText textAlign="center">No data.</StyledText>
      ) : (
        <Grid
          container
          spacing={2}
          className="overflow-y"
          sx={{ width: "min-content" }}
        >
          {Object.entries(data).map(([key, value], index) =>
            index === 6 &&
            transactionData &&
            transactionData?.line_items.length > 0 ? (
              <Box>
                <Table
                  sx={{
                    width: "calc(100% - 16px) !important",
                    m: "16px",
                    border: `1px solid ${THIN_BORDER_COLOR}`,
                  }}
                >
                  <StyledTableHeadRow>
                    <StyledTableHeadCell>{t("Product")}</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">
                      {t("Quantity")}
                    </StyledTableHeadCell>
                    <StyledTableHeadCell align="right">
                      {t("Price")}
                    </StyledTableHeadCell>
                    <StyledTableHeadCell>{t("Commission")}</StyledTableHeadCell>
                  </StyledTableHeadRow>
                  {transactionData?.line_items?.map((item:any, index:number) => (
                    <StyledTableRow>
                      <StyledTableCell sx={{ whiteSpace: "nowrap" }}>
                        {item.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.quantity}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        ${item.total ? Number(item.total).toFixed(2) : "0"}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {item.commission ? item.commission : "-"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </Table>
                {transactionData && transactionData?.commission_adjustment ? (
                  <Table
                    sx={{
                      width: "calc(100% - 16px) !important",
                      m: "16px",
                      border: `1px solid ${THIN_BORDER_COLOR}`,
                    }}
                  >
                    <StyledTableHeadRow>
                      <StyledTableHeadCell>
                        {t("Adjustments")}
                      </StyledTableHeadCell>
                      <StyledTableHeadCell align="right"></StyledTableHeadCell>
                      <StyledTableHeadCell></StyledTableHeadCell>
                    </StyledTableHeadRow>
                    {transactionData?.commission_adjustment?.map(
                      (item:any, index:number) => (
                        <StyledTableRow>
                          <StyledTableCell sx={{ whiteSpace: "nowrap" }}>
                            {item?.source &&
                              item?.source[0]?.toUpperCase() +
                                item?.source.slice(1).replace("_", " ")}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {item.commission_type === "percentage"
                              ? `${item?.commission_value}%`
                              : item?.commission_value}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {item?.value >= 0
                              ? `$${item.value}`
                              : `-$${Math.abs(item.value)}`}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    )}
                  </Table>
                ) : null}
              </Box>
            ) : (
              <React.Fragment key={index}>
                <Grid item xs={12} lg={4}>
                  <StyledText
                    fontvalue="14px"
                    lineheight="21px"
                    letterSpacing="0.15"
                    fontWeight="bold"
                  >
                    {t(key)}
                  </StyledText>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <StyledText fontvalue="14px" lineheight="21px">
                    {value}
                  </StyledText>
                </Grid>
              </React.Fragment>
            )
          )}
        </Grid>
      )}
    </Box>
  );
};

export default TransactionOrdersModal;
