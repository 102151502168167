import React, { useCallback } from 'react';
import { StyledStep, StyledStepLabel, StyledStepper, StyledText } from '../../../../utils/styledComponents';
import { orderSteps } from '../helper';
import { styles } from '../style';
import { useMediaQuery } from '@mui/material';

const Returned = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

const stepStatus = useCallback(() => {
  return (
    <StyledStepper
      activeStep={6}
      alternativeLabel={isMobile ? false : true}
      orientation={isMobile ? "vertical" : "horizontal"}
    >
      {orderSteps.map((label, index) => {
        return (
          <StyledStep key={index} expanded={true}>
            <StyledStepLabel
              StepIconComponent={() => (
                <img
                  src={label.iconActive}
                  alt={(index + 1)?.toString() ?? ""}
                  style={styles.stepperIcon}
                />
              )}
            >
              <StyledText lineheight="25px">{label.status}</StyledText>
            </StyledStepLabel>
          </StyledStep>
        );
      })}
    </StyledStepper>
  );
}, []);

return stepStatus();
};

export default Returned;