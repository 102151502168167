import {
  Box,
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  StyledTableHeadCell,
  StyledText,
} from "../../../../utils/styledComponents";
import FLoatDropDown from "../../../../shared/components/FLoatDropDown";
import { VideosHeadCellProps } from "../../../../models/backoffice.models";
import { useTranslation } from "react-i18next";
import { trafficAnalysisStyle } from "./styles";
import { isArrayIncludesKey, mmddyyyyFormat } from "../../../../utils/hooks";
import {
  processCommission,
  processFiltersList,
  processOrders,
  tableHeaders,
} from "./helper";
import TrafficData from "./TrafficData";
import FloatMobileFilter from "../../../../shared/components/FloatMobileFilter";
import FloatModal from "../../../../shared/components/FloatModal";
import CustomParamModal from "./CustomParamModal";
import { usePagination } from "../../../../utils/pagination";
import CustomPagination from "../../../../shared/components/CustomPagination";

const groupList = [
  { name: "Origin", key: "Origin" },
  { name: "Origin domain", key: "Origin domain" },
  { name: "Source", key: "Source" },
  { name: "Date", key: "Date" },
  { name: "Landing page", key: "Landing page" },
  { name: "Custom query param", key: "Custom query param" },
];

const TraficAnalysis = ({ visits }: any) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    aggregate: "Origin",
    sortBy: "Orders",
  });
  const [aggregationTitle, setAggregationTitle] = useState("Origin");
  const [data, setData]: any = useState([]);
  const [queryParam, setQueryParam] = useState("");
  const [openParam, setOpenParam] = useState(false);
  const [aggregateList, setAggregateList] = useState(groupList);
  const [page, setPage] = useState<number>(1);

  const [
    totalPages,
    startPageIndex,
    endPageIndex,
    currentPageIndex, //eslint-disable-line
    displayPage,
  ] = usePagination(10, data?.length);

  useEffect(() => {
    aggregateData(filters.aggregate);
  }, [visits]);

  useEffect(() => {
    if (queryParam) {
      let queryList = [
        { name: "Origin", key: "Origin" },
        { name: "Origin domain", key: "Origin domain" },
        { name: "Source", key: "Source" },
        { name: "Date", key: "Date" },
        { name: "Landing page", key: "Landing page" },
        { name: `Landing page - ${queryParam}`, key: "Landing page query" },
        { name: "Custom query param", key: "Custom query param" },
      ];
      setAggregateList(queryList);
    } else {
      setAggregateList(groupList);
    }
  }, [queryParam]);

  const aggregateData = (aggregateType: string) => {
    if (!isMobile && aggregateType !== "Custom query param") {
      const colName =
        aggregateType === "Landing page query" ? queryParam : aggregateType;
      setAggregationTitle(colName);
    }

    switch (aggregateType) {
      case "Origin":
        return originList(aggregateType);
      case "Origin domain":
        return originDomainList(aggregateType);
      case "Source":
        return sourceList(aggregateType);
      case "Date":
        return dateWiseList(aggregateType);
      case "Landing page":
        return landingPageList(aggregateType);
      case "Custom query param":
        return openParamModal();
      case "Landing page query":
        return customQueryFilterList(aggregateType);
      default:
        return [];
    }
  };

  function openParamModal() {
    setOpenParam(true);
  }

  const commonFilterChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let payload = {
      aggregate: name === "aggregate" ? value : filters.aggregate,
      sortBy: name === "sortBy" ? value : filters.sortBy,
    };
    setFilters({ ...filters, ...payload });
    if (name === "aggregate") {
      aggregateData(value);
    } else {
      sortDataBy(value, data);
    }
  };

  const onMobileFilter = () => {
    setAggregationTitle(filters.aggregate);
    aggregateData(filters.aggregate);
  };

  const sortDataBy = (sortBy: string, list: any[]) => {
    switch (sortBy) {
      case "Orders":
        const ordersSort = list
          ?.slice()
          ?.sort((a: any, b: any) => b?.orders - a?.orders);
        return setData(ordersSort);
      case "Visits":
        const visitSort = list
          ?.slice()
          ?.sort((a: any, b: any) => b?.visits - a?.visits);
        return setData(visitSort);
      case "Conversion":
        const conversionSort = list
          ?.slice()
          ?.sort((a: any, b: any) => b?.conversion - a?.conversion);
        return setData(conversionSort);
      case "Commission":
        const commissionSort = list
          ?.slice()
          ?.sort((a: any, b: any) => b?.commissions - a?.commissions);
        return setData(commissionSort);
    }
  };

  const groupData = (clickOrigins: any, aggregateType: string) => {
    const filterData = Object.keys(clickOrigins).map((key: any) => ({
      aggregateValue: key,
      orders: gerOrders(key, aggregateType),
      visits: clickOrigins[key],
      commissions: getCommission(key, aggregateType),
      conversion: Math.round(
        (gerOrders(key, aggregateType) / clickOrigins[key]) * 100
      ),
    }));
    return filterData;
  };

  const originList = (aggregateType: string) => {
    const clickOrigins = visits.reduce((acc: any, item: any) => {
      const url = item?.referrer ? item?.referrer : "";
      acc[url] = (acc[url] || 0) + 1;
      return acc;
    }, {});
    sortDataBy(filters.sortBy, groupData(clickOrigins, aggregateType));
  };

  const originDomainList = (aggregateType: string) => {
    const clickOrigins = visits.reduce((acc: any, item: any) => {
      const url = item?.referrer;
      const startIndex = url?.indexOf("://") + 3;
      const endIndex = isArrayIncludesKey(url, "localhost")
        ? url?.length - 1
        : url?.indexOf(".com") + 4;
      const domainOrigin = url ? url?.slice(startIndex, endIndex) : "";
      acc[domainOrigin] = (acc[domainOrigin] || 0) + 1;
      return acc;
    }, {});
    sortDataBy(filters.sortBy, groupData(clickOrigins, aggregateType));
  };

  const landingPageList = (aggregateType: string) => {
    const clickOrigins = visits.reduce((acc: any, item: any) => {
      const url = item?.landing_page ?? "";
      acc[url] = (acc[url] || 0) + 1;
      return acc;
    }, {});
    sortDataBy(filters.sortBy, groupData(clickOrigins, aggregateType));
  };

  const sourceList = (aggregateType: string) => {
    const clickOrigins = visits.reduce((acc: any, item: any) => {
      const url = item?.source ?? "";
      acc[url] = (acc[url] || 0) + 1;
      return acc;
    }, {});
    sortDataBy(filters.sortBy, groupData(clickOrigins, aggregateType));
  };

  const dateWiseList = (aggregateType: string) => {
    const clickOrigins = visits.reduce((acc: any, item: any) => {
      const date = mmddyyyyFormat(item?.site_visit_date) ?? "";
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});
    sortDataBy(filters.sortBy, groupData(clickOrigins, aggregateType));
  };

  const customQueryFilterList = (aggregateType: string) => {
    const clickOrigins = visits?.reduce((acc: any, item: any) => {
      const paramValue = getUrl(item?.landing_page);
      acc[paramValue] = (acc[paramValue] || 0) + 1;
      return acc;
    }, {});

    const filterData = Object.keys(clickOrigins).map((key: any) => ({
      aggregateValue: key,
      orders: customQueryOrders(key, aggregateType),
      visits: clickOrigins[key],
      commissions: customQueryCommission(key, aggregateType),
      conversion: Math.round(
        (customQueryOrders(key, aggregateType) / clickOrigins[key]) * 100
      ),
    }));
    sortDataBy(filters.sortBy, filterData);
  };

  const getUrl = (value: string) => {
    try {
      const url = new URL(value) ?? "";
      const ref = url?.searchParams?.get(queryParam) ?? "";
      return ref;
    } catch {
      return "";
    }
  };

  const customQueryCommission = (
    aggregateUrl: string,
    aggregateType: string
  ) => {
    const totalCommission = visits.reduce((acc: any, currentValue: any) => {
      if (
        currentValue?.affiliate_commission &&
        getUrl(currentValue.landing_page) === aggregateUrl
      ) {
        acc += parseFloat(currentValue.affiliate_commission);
      }
      return acc;
    }, 0);
    return parseFloat(totalCommission?.toFixed(2));
  };
  const customQueryOrders = (aggregateUrl: string, aggregateType: string) => {
    const customQueryOrders = visits?.filter((item: any) => {
      return getUrl(item?.landing_page) === aggregateUrl && item.order_id;
    });
    return customQueryOrders?.length ?? 0;
  };

  const gerOrders: any = useCallback(
    (aggregateUrl: string, aggregateType: string) =>
      processOrders(aggregateUrl, aggregateType, visits),
    [visits]
  );

  const getCommission = useCallback(
    (aggregateUrl: string, aggregateType: string) =>
      processCommission(aggregateUrl, aggregateType, visits),
    [visits]
  );

  const SelectFilters = useMemo(
    () =>
      processFiltersList(
        commonFilterChange,
        filters.aggregate,
        filters.sortBy,
        aggregateList
      ),
    [commonFilterChange, filters.aggregate, filters.sortBy, aggregateList]
  );

  const headerCells = useMemo(
    (): VideosHeadCellProps[] => tableHeaders(aggregationTitle),
    [aggregationTitle]
  );

  function onParamModalClose() {
    if (queryParam) {
      setFilters({ ...filters, aggregate: "Landing page query" });
      aggregateData("Landing page query");
    } else {
      setFilters({ ...filters, aggregate: "Landing page" });
      aggregateData("Landing page");
    }

    setOpenParam(false);
  }

  function handlePageChange(event: any, value: any) {
    setPage(value);
    displayPage(value);
  }

  return (
    <Box sx={trafficAnalysisStyle.outer}>
      <Stack sx={trafficAnalysisStyle.filterContain}>
        <StyledText sx={trafficAnalysisStyle.trafficText}>
          {t("Traffic analysis")}
        </StyledText>
        {isMobile ? (
          <FloatMobileFilter
            filters={filters}
            setFilters={setFilters}
            selectFilters={SelectFilters}
            onSubmitMobile={onMobileFilter}
          />
        ) : (
          <Stack direction={"row"}>
            {SelectFilters?.map((item, index) => (
              <FLoatDropDown
                key={index}
                list={item.list}
                label={item.label}
                onChange={item.onChange}
                value={item.value}
                name={item.name}
              />
            ))}
          </Stack>
        )}
      </Stack>

      <TableContainer
        className="overflow-x-contain"
        component={"div"}
        sx={trafficAnalysisStyle.tblContainer}
      >
        <Table aria-label="collapsible table" size="small">
          <TableHead sx={trafficAnalysisStyle.tableHead}>
            <TableRow sx={trafficAnalysisStyle.thRow}>
              {headerCells?.map((header, index) => (
                <StyledTableHeadCell
                  key={index}
                  align={header.align}
                  sx={trafficAnalysisStyle.thCell}
                >
                  {isArrayIncludesKey(header.label, ":")
                    ? header.label
                    : t(header.label)}
                </StyledTableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          {data && (
            <TrafficData
              data={data}
              visits={visits}
              startPageIndex={data?.length > 10 ? startPageIndex : 0}
              endPageIndex={data?.length > 0 ? endPageIndex : 9}
            />
          )}
        </Table>
      </TableContainer>
      <Stack alignItems={"flex-end"} justifyContent={"flex-end"}>
        {totalPages > 1 && (
          <CustomPagination
            page={page}
            count={totalPages}
            handlePageChange={handlePageChange}
          />
        )}
      </Stack>
      <FloatModal
        open={openParam}
        onClose={onParamModalClose}
        modalTitle={t("Landing page - Custom query parameter")}
        modalBody={
          <CustomParamModal
            close={onParamModalClose}
            queryParam={queryParam}
            setQueryParam={setQueryParam}
            setAggregateList={setAggregateList}
          />
        }
      />
    </Box>
  );
};

export default TraficAnalysis;
