
import OrderPlaced from "../../../utils/images/Placed.png";
import InActiveOrderPlaced from "../../../utils/images/InActiveOrderPlaced.png";
import BeingProcessed from "../../../utils/images/BeingProcessed.png";
import ReadyToShip from "../../../utils/images/ReadyToShip.png";
import InTransit from "../../../utils/images/InTransit.png";
import Delivered from "../../../utils/images/Delivered.png";
import Returned from "../../../utils/images/Return.png";
import InActiveBeingProcessed from "../../../utils/images/InactiveBeingProcess.png";
import InActiveReadyToShip from "../../../utils/images/InActiveReadyToShip.png";
import InActiveInTransit from "../../../utils/images/InActiveInTransit.png";
import InActiveDelivered from "../../../utils/images/InActiveDelivered.png";
import InActiveReturned from "../../../utils/images/InActiveReturn.png";

export const orderSteps = [
  {
    status: "Order placed",
    date: "",
    time: "",
    response: "New Orders",
    iconActive: OrderPlaced,
    step: 1,
    iconInactive: InActiveOrderPlaced,
  },
  {
    status: "Being Processed",
    date: "",
    time: "",
    response: "Being Processed",
    iconActive: BeingProcessed,
    step: 2,
    iconInactive: InActiveBeingProcessed,
  },
  {
    status: "Ready to Ship",
    date: "",
    time: "",
    response: "Ready to Ship",
    iconActive: ReadyToShip,
    step: 3,
    iconInactive: InActiveReadyToShip,
  },
  {
    status: "In Transit",
    date: "-",
    time: "-",
    response: "In Transit",
    iconActive: InTransit,
    step: 4,
    iconInactive: InActiveInTransit,
  },
  {
    status: "Delivered",
    date: "-",
    time: "-",
    response: "Delivered",
    iconActive: Delivered,
    step: 5,
    iconInactive: InActiveDelivered,
  },
  {
    status: "Returned",
    date: "-",
    time: "-",
    response: "Returned",
    iconActive: Returned,
    step: 6,
    iconInactive: InActiveReturned,
  },
];



