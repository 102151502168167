import { SCROLLBAR_THUMB, THEMEBACKGROUNDCOLOR, THIN_BORDER_COLOR } from "../../../colors";

export const styles = {
  loaderBox: {
    display: "flex",
    justifyContent: "center",
    height: "500px",
    alignItems: "center",
  },
  tableContainer: {
    height: "calc(100vh - 240px)",
    marginTop: "20px",
    table: {
      border: `1px solid ${THIN_BORDER_COLOR}`,
      width: "48%",
    },
    buttonBox: {
      display: "flex",
      flexDirection: "column",
      columnGap: "10px",
      rowGap: "10px",
    },
    descriptionBox: {
      textOverflow: "ellipsis",
      height: "30px",
      width: "175px",
      overflowX: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContents: "center",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: SCROLLBAR_THUMB,
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-track": {
        background: THEMEBACKGROUNDCOLOR,
        borderRadius: "4px",
      },
    },
    titleBox: {
      textOverflow: "ellipsis",
      height: "30px",
      width: "100px",
      overflowX: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContents: "center",
      "&::-webkit-scrollbar": {
        width: "0px",
      },
    },
    deleteIconWrapper: {
      padding: "3px 4px",
      borderRadius: "45%",
      backgroundColor: "#E5E4E2",
    },
  },
  buttonBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  boxStyle: { width: "100%" },
  paperStyle: { width: "100%", mb: 2, padding: 0 },
  rowStyle: { cursor: "pointer" },
  deleteIconStyle: { color: "#818589", fontSize: "18px" },
};

export const networkStyle = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    BoxStyle: { marginTop: "20px" },
    linkColor: { color: "#2196F3" },
    iconStyle: {
      cursor: "pointer",
      ":hover": { color: "#333" },
    },
    buttonStyle: { mt: "5px", textTransform: "none" },
    gridStyle: {
      backgroundColor: THEMEBACKGROUNDCOLOR,
      display: {
        xs: "block",
        sm: "block",
        md: "block",
        lg: "flex",
      },
      padding: " 15px 20px",
      mt: "20px",
      mb: "10px",
      alignItems: "center",
      justifyContent: "space-between",
      width: {
        xs: "100%",
        sm: "100%",
        md: "40%",
        lg: "40%",
        xl: "40%",
      },
    },
    innerGrid: {
      display: {
        xs: "block",
        sm: "block",
        md: "block",
        lg: "flex",
      },
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    nestedGrid: {
      background: "#fff",
      textAlign: "center",
      padding: "10px 40px",
      marginTop: {
        xs: "15px",
        sm: "15px",
        md: "15px",
        lg: "0px",
      },
    },
    textMargin: { mt: "10px" },
    gridMargin: { mt: "20px" },
    cardStyle: {
      backgroundColor: THEMEBACKGROUNDCOLOR,
      padding: "12px 16px",
    },
    innerCard: { backgroundColor: "#fff", display: "flex" },
    cardMedia: { width: "30%" },
    boxStyle: {
      width: "30%",
      display: "flex",
      justifyContent: "center",
    },
    boxFlex: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    avatarStyle: {
      width: "60px",
      height: "60px",
      fontSize: 30,
      backgroundColor: "grey", 
      color: "#fff",
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    gridTable: {
      width: "100%",
      margin: "15px 0px",
    },
    tableCell: { width: "70%" },
    tableContainer: {
      width: "100%",
      margin: "15px 0px",
    },
  },
};

export const networkExplorerStyle = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    IconButton: {
      border: "1px solid black",
      borderRadius: "3px",
      marginRight: "5px",
    },
    gridStyle: {
      backgroundColor: THEMEBACKGROUNDCOLOR,
      display: { xs: "block", sm: "block", md: "block", lg: "flex" },
      padding: " 15px 20px",
      mt: "20px",
      mb: "10px",
      alignItems: "center",
      justifyContent: "space-between",
      width: {
        xs: "100%",
        sm: "100%",
        md: "40%",
        lg: "40%",
        xl: "40%",
      },
    },
    innerGrid: {
      display: { xs: "block", sm: "block", md: "block", lg: "flex" },
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    datagrid: {
      background: "#fff",
      textAlign: "center",
      padding: "10px 40px",
      marginTop: {
        xs: "15px",
        sm: "15px",
        md: "15px",
        lg: "0px",
      },
    },
    textMargin: { mt: "10px" },
    gridMargin: { mt: "20px" },
    cardStyle: {
      backgroundColor: THEMEBACKGROUNDCOLOR,
      padding: "12px 16px",
    },
    innerCard: { backgroundColor: "#fff", display: "flex" },
    cardMedia: { width: "30%" },
    boxStyle: {
      width: "30%",
      display: "flex",
      justifyContent: "center",
    },
    boxFlex: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    avatarStyle: {
      width: "60px",
      height: "60px",
      fontSize: 30,
      backgroundColor: "grey", 
      color: "#fff", 
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
};

export const orderStyle = {
  tableHead: { minWidth: "90px" },
  wrapper: { border: `1px solid ${THIN_BORDER_COLOR}` },
};
