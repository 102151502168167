import React from "react";
import { Box, Stack } from "@mui/material";
import { SubTitle2 } from "../../../../utils/styledComponents";
import CancelOrder from "../../../../utils/images/CancelOrder.png";
import { useTranslation } from "react-i18next";
import { styles } from "../style";

const Cancelled = () => {
  const { t } = useTranslation();
  const { stepperCancel } = styles;

  return (
    <Stack sx={stepperCancel.outer}>
      <Stack sx={stepperCancel.statusWrap}>
        <Box sx={stepperCancel.iconWrap}>
          <img
            src={CancelOrder}
            alt={"x"}
            style={styles.stepperIcon}
          />
        </Box>
        <SubTitle2>{t("Order cancelled")}</SubTitle2>
      </Stack>
    </Stack>
  );
};

export default Cancelled;
