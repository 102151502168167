import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import React from "react";
import {
  StyledTableCell,
  StyledTableHeadCell,
  StyledTableHeadRow,
  StyledTableRow,
} from "../../../utils/styledComponents";
import { orderStyle } from "./styles";

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

function Orders({ orders }: any) {

  const headCells: readonly HeadCell[] = [
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "order",
      numeric: false,
      disablePadding: false,
      label: "Order#",
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: false,
      label: "Amount",
    },
  ];

  function formatDate(inputDate: string) {
    let date = new Date(inputDate);
    let day: any = date.getDate();
    let month: any = date.getMonth() + 1;
    let year: any = date.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    let formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }
  const TableHeadComponent = () => {
    return (
      <TableHead>
        <StyledTableHeadRow>
          {headCells.map((headCell, index) => (
            <StyledTableHeadCell
              key={index}
              align="left"
              id={headCell.id}
              scope="row"
              sx={orderStyle.tableHead}
            >
              {headCell.label}
            </StyledTableHeadCell>
          ))}
        </StyledTableHeadRow>
      </TableHead>
    );
  };

  return (
    <Box>
      <TableContainer sx={orderStyle.wrapper}>
        <Table aria-labelledby="tableTitle">
          <TableHeadComponent />
          <TableBody>
            {orders?.length &&
              orders.map((row: any, index: number) => {
                return (
                  <StyledTableRow key={index} hover>
                    <StyledTableCell align="left">
                      {formatDate(row?.created ?? "")}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.number ?? ""}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.subtotal ?? ""}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Orders;
