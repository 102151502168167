import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE, GET_TRANSACTION_DETAILS } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";

const { SUCCESS, REQUEST, GET_TRANSACTION_DATA, GET_TRANSACTION_ORDER } =
  actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const transactionData = (state = {}, action: actions) => {
    switch (action.type) {
      case GET_TRANSACTION_DATA[SUCCESS]:
        return {
          ...state,
          data: action.payload.data,
          isLoading: false,
          isError: false,
        };
      case GET_TRANSACTION_DATA[REQUEST]:
        return intialState;
      case GET_TRANSACTION_DATA[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  const transactionOrderData = (state = {}, action: actions) => {
    switch (action.type) {
      case GET_TRANSACTION_ORDER[SUCCESS]:
        return {
          ...state,
          data: action.payload.data,
          isLoading: false,
          isError: false,
        };
      case GET_TRANSACTION_ORDER[REQUEST]:
        return intialState;
      case GET_TRANSACTION_ORDER[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  const transactionDetailsData = (state = intialState, action: actions) => {
    switch (action.type) {
      case GET_TRANSACTION_DETAILS[SUCCESS]:
        return {
          ...state,
          data: action.payload.data,
          isLoading: false,
          isError: false,
        };
      case GET_TRANSACTION_DETAILS[REQUEST]:
        return intialState;
      case GET_TRANSACTION_DETAILS[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  return combineReducers({
    transactionData,
    transactionOrderData,
    transactionDetailsData
  });
};

export default entity;

export const transactionData = (state: RootState) =>
  state.entities.transactionData;
