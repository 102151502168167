import React, { useCallback, useEffect, useRef, useState } from "react";
import { CardDiv } from "../planprogress/style";
import {
  Box,
  CircularProgress,
  Grid,
  Popover,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import FloatLineChart from "../../../../shared/components/FloatLineChart";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../configureStore";
import { CONSTANTS } from "../../../../utils/constants";
import {
  formatMontDate,
  getDayOfWeek,
  getPast7days,
  past24Hours,
} from "../../../../utils/hooks";
import { clickStatsWeekly, totalGraphViews } from "../../../../actions";
import { styles } from "./style";
import FloatBarChart, {
  ChartBarType,
} from "../../../../shared/components/FloatBarChart";
import { ChartLineType } from "../../../../models/backoffice.models";
import FLoatDropDown from "../../../../shared/components/FLoatDropDown";
import { useTranslation } from "react-i18next";
import FloatModal from "../../../../shared/components/FloatModal";
import DateRangeGraphFilter from "../../../../shared/components/DateRangeGraphFilter";
import FloatDateSelector from "../../../../shared/components/FloatDateSelector";

interface TotaGraphObj {
  view_count: string;
  date?: string;
}

interface ClickStatPropType {
  click_throughs: string;
  product_clicks: string;
  date?: string;
}

interface GlobalClickPropType {
  hour: string;
  visits: string;
  clicks: string;
}

interface GlobalViewsPropType {
  hour?: any;
  day?: any;
  views: number;
}
function GlobalGraphs() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref = useRef<any>();
  const refChart2 = useRef<any>();

  const { filterViews, graphFilters } = CONSTANTS;

  const { totalGraphViewsData } = useSelector(
    (state: RootState) => state?.entities?.totalGraphViews
  );

  const { clickStatsWeeklyData } = useSelector(
    (state: RootState) => state?.entities?.clickStatsWeekly
  );

  const isMobile = useMediaQuery("(max-width:900px)");

  const moment = require("moment");
  const today = moment().format("YYYY-MM-DD");
  const past7thDay = moment().subtract(6, "days").format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
  const past8thDay = moment().subtract(7, "days").format("YYYY-MM-DD");
  const past30thDay = moment().subtract(29, "days").format("YYYY-MM-DD");
  const past31stDay = moment().subtract(30, "days").format("YYYY-MM-DD");

  const [viewFilter, setViewFilter] = useState(filterViews[0].key);
  const [chart2Filter, setChart2Filter] = useState(graphFilters[0].key);
  const [productFilter, setProductFilter] = useState(graphFilters[0].key);
  const [chart1Data, setChart1Data]: any = useState([]);
  const [chart1Loading, setChart1Loading]: any = useState([]);
  const [chart2Loading, setChart2Loading]: any = useState([]);
  const [chart2Data, setChart2Data]: any = useState([]);
  const [max, setMax] = useState(0);
  const [selectedDates, setSelectedDates]: any = useState(["", ""]);
  const [chart2SelectedDates, setChart2SelectedDates]: any = useState(["", ""]);
  const [anchorEl, setAnchorEl] = useState<boolean>(false);
  const [anchorElChart2, setAnchorElChart2] = useState<boolean>(false);
  const [openChart1Date, setOpenChart1Date] = useState<boolean>(false);
  const [openChart2Date, setOpenChart2Date] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(!anchorEl);
    setViewFilter("dateRange");
    setOpenChart1Date(true);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleClickChart2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElChart2(!anchorElChart2);
    setChart2Filter("dateRange");
    setOpenChart2Date(true);
  };

  const handleCloseChart2 = () => {
    setAnchorElChart2(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const openChart2 = Boolean(anchorElChart2);
  const idChart2 = openChart2 ? "simple-popover" : undefined;

  const handleDateFilter = () => {
    dispatch(
      totalGraphViews.request({
        data: {
          filter: "pastNdays",
          startDate: selectedDates[0]?.format("YYYY-MM-DD"),
          endDate: selectedDates[1]?.format("YYYY-MM-DD"),
        },
      })
    );
    handleClose();
    setOpenChart1Date(false);
  };

  const handleChart2DateFilter = () => {
    dispatch(
      clickStatsWeekly.request({
        data: {
          filter: "pastNdays",
          startDate: chart2SelectedDates[0]?.format("YYYY-MM-DD"),
          endDate: chart2SelectedDates[1]?.format("YYYY-MM-DD"),
        },
      })
    );
    handleCloseChart2();
    setOpenChart2Date(false);
  };

  useEffect(() => {
    dispatch(
      clickStatsWeekly.request({
        data: {
          startDate: past7thDay,
          endDate: today,
          filter: "pastNdays",
        },
      })
    );
  }, []);

  useEffect(() => {
    setChart1Loading(totalGraphViewsData?.isLoading);
    if (totalGraphViewsData?.data?.length) {
      onChart1DataChange(totalGraphViewsData?.data);
    }
  }, [totalGraphViewsData?.data?.length, totalGraphViewsData?.isLoading]);

  useEffect(() => {
    setChart2Loading(clickStatsWeeklyData?.isLoading);
  }, [clickStatsWeeklyData?.data?.length, clickStatsWeeklyData?.isLoading]);

  // set product click graph stats
  useEffect(() => {
    if (clickStatsWeeklyData?.data?.length) {
      const chart2: GlobalClickPropType[] = [];
      const hourStats = productFilter === "past24hours";
      const weekDays = productFilter === "past7days"
      const hours = past24Hours();
      clickStatsWeeklyData?.data?.map(
        (item: ClickStatPropType, index: number) => {
          const hour = hourStats
            ? hours[index + 1]
            : weekDays
            ? item?.date ? getDayOfWeek(item?.date) : ""
            : item?.date ? formatMontDate(item?.date) : "";
          chart2.push({
            hour: hour,
            visits: item?.click_throughs,
            clicks: item?.product_clicks,
          });
        }
      );
      const visits = chart2?.map((item: GlobalClickPropType) =>
        parseInt(item.visits)
      );
      const clicks = chart2?.map((item: GlobalClickPropType) =>
        parseInt(item.clicks)
      );

      const maximum =
        Math.max(...visits) > Math.max(...clicks)
          ? Math.max(...visits)
          : Math.max(...clicks);
      setMax(maximum);
      setChart2Data(chart2);
    }
  }, [clickStatsWeeklyData?.data]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      if (event.target.value !== "dateRange") {
        setChart1Data([]);
        const data = event.target.value;
        setViewFilter(data);
        if (data === "past24hours") {
          dispatch(totalGraphViews.request({ data: { filter: data } }));
        } else if (data === "past7days") {
          dispatch(
            totalGraphViews.request({
              data: {
                filter: "pastNdays",
                startDate: past7thDay,
                endDate: today,
              },
            })
          );
        } else if (data === "past30days") {
          dispatch(
            totalGraphViews.request({
              data: {
                filter: "pastNdays",
                startDate: past30thDay,
                endDate: today,
              },
            })
          );
        }
      }

    },
    [dispatch]
  );

  const handleChart2Change = useCallback(
    (event: SelectChangeEvent<string>) => {
      setProductFilter(event.target.value)
      if (event.target.value !== "dateRange") {
        setChart2Data([]);
        const data = event.target.value;
        setChart2Filter(data);
        if (data === "past7days") {
          dispatch(
            clickStatsWeekly.request({
              data: {
                startDate: past7thDay,
                endDate: today,
                filter:"pastNdays"
              },
            })
          );
        } else if (data === "past30days") {
          dispatch(
            clickStatsWeekly.request({
              data: {
                startDate: past30thDay,
                endDate: today,
                filter: "pastNdays",
              },
            })
          );
        } else if (data === "past24hours") {
           dispatch(
             clickStatsWeekly.request({
               data: {
                 filter:"past24hours"
               },
             })
           );
        }
      }
    },
    [dispatch]
  );

  const onChart1DataChange = (data: any[]) => {
    const chartData: GlobalViewsPropType[] = [];
    const week = getPast7days();
    const hours = past24Hours();
    data.map((item: TotaGraphObj, index: number) => {
      if (viewFilter === "past24hours") {
        if (index == 0) {
          chartData.push({
            hour: hours[0],
            views: 0,
          });
        }
        chartData.push({
          hour: hours[index + 1],
          views: parseInt(item?.view_count),
        });
      } else if (viewFilter === "dateRange") {
        chartData.push({
          day: item?.date ? formatMontDate(item?.date) : "",
          views: parseInt(item?.view_count),
        });
      } else if (viewFilter === "past7days") {
        chartData.push({ day: week[index], views: parseInt(item?.view_count) });
      } else {
        chartData.push({
          day: item?.date ? formatMontDate(item?.date) : "",
          views: parseInt(item?.view_count),
        });
      }
    });
    setChart1Data(chartData);
  };

  const ChartLines2: ChartLineType[] = [
    {
      type: "monotone",
      name: t("Click throughs"),
      dataKey: "visits",
      stroke: "#0457D4",
    },
    {
      type: "monotone",
      name: t("Product clicks"),
      dataKey: "clicks",
      stroke: "#C864C7",
    },
  ];

  const ChartLines1: ChartBarType[] = [
    {
      name: t("Video views"),
      dataKey: "views",
      fill: "linear-gradient(to right, #D3D3D3, #818589)",
    },
  ];

  return (
    <>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <CardDiv style={{ height: "330px", marginBottom: "20px" }}>
            <Box sx={styles.filterContain}>
              <Box
                sx={{ display: "flex" }}
                onClick={() => {
                  if (open) {
                    handleClose();
                  }
                }}
              >
                <FLoatDropDown
                  value={viewFilter}
                  onChange={handleChange}
                  list={filterViews}
                  iconClick={(e) => handleClick(e)}
                  label="Filter"
                  name="Filter"
                />
              </Box>
            </Box>
            <FloatDateSelector
              open={isMobile ? false : open}
              max30Days={true}
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
              handleDateFilter={handleDateFilter}
            />
            {chart1Loading || chart1Data.length === 0 ? (
              <Box sx={styles.loaderContainer}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {viewFilter == "past24hours" ? (
                  <Box
                    className="overflow-x-contain"
                    sx={{ width: "100%", maxWidth: "calc(100% - 10px)" }}
                  >
                    <Box
                      sx={{
                        width: "800px",
                        height: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <FloatBarChart
                        Xaxis={{ dataKey: "hour" }}
                        ChartData={chart1Data}
                        ChartBars={ChartLines1}
                      />
                    </Box>
                  </Box>
                ) : viewFilter == "past30days" || viewFilter == "dateRange" ? (
                  <Box
                    className="overflow-x-contain"
                    sx={{ width: "100%", maxWidth: "calc(100% - 10px)" }}
                  >
                    <Box
                      sx={{
                        width: "1500px",
                        height: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <FloatBarChart
                        Xaxis={{ dataKey: "day" }}
                        ChartData={chart1Data}
                        ChartBars={ChartLines1}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box
                    className="overflow-x-contain"
                    sx={{ width: "100%", maxWidth: "calc(100% - 10px)" }}
                  >
                    <Box
                      sx={{
                        width: { xs: "800px", md: "100%" },
                        height: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <FloatBarChart
                        Xaxis={{ dataKey: "day" }}
                        ChartData={chart1Data}
                        ChartBars={ChartLines1}
                      />
                    </Box>
                  </Box>
                )}
              </>
            )}
          </CardDiv>
        </Grid>
        <Grid item xs={12} lg={6}>
          <CardDiv
            style={{
              height: "330px",
              padding: "0px 20px 10px",
              marginBottom: "20px",
            }}
          >
            <Box sx={styles.filterContain}>
              <Box
                sx={{ display: "flex" }}
                onClick={() => {
                  if (openChart2) {
                    handleCloseChart2();
                  }
                }}
              >
                <FLoatDropDown
                  value={chart2Filter}
                  onChange={handleChart2Change}
                  list={graphFilters}
                  iconClick={(e) => handleClickChart2(e)}
                  label="Filter"
                  name="Filter"
                />
              </Box>
            </Box>
            <FloatDateSelector
              open={isMobile ? false : openChart2}
              max30Days={true}
              selectedDates={chart2SelectedDates}
              setSelectedDates={setChart2SelectedDates}
              handleDateFilter={handleChart2DateFilter}
            />
            {chart2Loading ? (
              <Box sx={styles.loaderContainer}>
                <CircularProgress />
              </Box>
            ) : chart2Filter === "past7days" ? (
              <Box
                className="overflow-x-contain"
                sx={{ width: "100%", maxWidth: "calc(100% - 10px)" }}
              >
                <Box
                  sx={{
                    width: { xs: "800px", md: "100%" },
                    height: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <FloatLineChart
                    Xaxis={{ dataKey: "hour" }}
                    ChartData={chart2Data}
                    ChartLines={ChartLines2}
                    max={max}
                  />
                </Box>
              </Box>
            ) : (
              <Box
                className="overflow-x-contain"
                sx={{ width: "100%", maxWidth: "calc(100% - 10px)" }}
              >
                <Box
                  sx={{
                    width: "1500px",
                    height: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <FloatLineChart
                    Xaxis={{ dataKey: "hour" }}
                    ChartData={chart2Data}
                    ChartLines={ChartLines2}
                    max={max}
                  />
                </Box>
              </Box>
            )}
          </CardDiv>
        </Grid>
      </Grid>
      {isMobile && (
        <FloatModal
          open={openChart1Date}
          onClose={() => {
            setOpenChart1Date(false);
          }}
          modalTitle={t("Select date")}
          modalBody={
            <DateRangeGraphFilter
              max30Days={true}
              isView={true}
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
              handleDateFilter={handleDateFilter}
            />
          }
        />
      )}
      {isMobile && (
        <FloatModal
          open={openChart2Date}
          onClose={() => {
            setOpenChart2Date(false);
          }}
          modalTitle={t("Select date")}
          modalBody={
            <DateRangeGraphFilter
              max30Days={true}
              selectedDates={chart2SelectedDates}
              setSelectedDates={setChart2SelectedDates}
              handleDateFilter={handleChart2DateFilter}
            />
          }
        />
      )}
    </>
  );
};

export default GlobalGraphs;