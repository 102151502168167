import { all } from "redux-saga/effects";
import { sagas as loginSagas } from "./login";
import { sagas as refreshSagas } from "./refresh";
import { sagas as totalViewsSagas } from "./total_views";
import { sagas as totalGraphviews } from "./total_view_graph";
import { sagas as clickStatsSagas } from "./clickStats";
import { sagas as planSagas } from "./plan";
import { sagas as grabProductsSagas } from "./grabProducts";
import { sagas as influencerNetworkSagas } from "./networkInfluencer";
import { sagas as totalGlobalStatsSagas } from "./globalStats";
import { sagas as addPyvitProductSagas } from "./addPyvitProduct";
import { sagas as uploadCustomProductSagas } from "./uploadCustomProduct";
import { sagas as saveProductSagas } from "./saveItem";
import { sagas as deleteProductSagas } from "./deleteProduct";
import { sagas as editProductSagas } from "./editProduct";
import { sagas as videosSagas } from "./videos";
import { sagas as videoAnalyticsSagas } from "./videosAnalytics";
import { sagas as addVideoSagas } from "./addVideo";
import { sagas as videoDetailSagas } from "./videoDetails";
import { sagas as deleteVideoSagas } from "./deleteVideo";
import { sagas as saveVideoDetailSagas } from "./saveVideoDetails";
import { sagas as changeVideoThumbmnailSagas } from "./changeThumbnail";
import { sagas as unassignProductSagas } from "./unAssignProduct";
import { sagas as editVideoAuthSagas } from "./editVideoAuth";
import { sagas as assignItemSagas } from "./assignItem";
import { sagas as productByVideoSagas } from "./productByVideo";
import { sagas as videoViewStatsSagas } from "./videoViewsStat";
import { sagas as uniqueVideoTotalViewsSagas } from "./uniqueVideoViews";
import { sagas as videoProductClickSagas } from "./videoProductCLick";
import { sagas as analyticsVideoSagas } from "./analyticsVideo";
import { sagas as userProfileSagas } from "./userProfile";
import { sagas as createNomadeWalletSagas } from "./createNomad";
import { sagas as eWalletDashboardSagas } from "./nomadDashboard";
import { sagas as profileAccountSagas } from "./accounts/accountProfile";
import { sagas as deleteAddressSagas } from "./accounts/accountAddress";
import { sagas as createAddressSagas } from "./accounts/createAddress";
import { sagas as countriesListSaga } from "./countriesList";
import { sagas as subscriptionDetailSagas } from "./subscriptionDetail";
import { sagas as orderHistorySaga } from "./orderHistory";
import { sagas as subscriptionAlert } from "./subscriptionAlert";
import { sagas as creatorLibrarySagas } from "./creatorLibrary";
import { sagas2 as creatLibraryAnalyticsSagas } from "./creatorLibrary";
import { sagas3 as creatorCopyVideoSagas } from "./creatorLibrary";
import { sagas as activatePauseVideoSagas } from "./activatePauseVideo";
import { sagas as reactivateSubscriptionSagas } from "./reactivateSubscription";
import { sagas as unsubscribePlanSagas } from "./unsubscribePlan";
import { sagas as summaryProfileSagas } from "./summaryProfile";
import { sagas as summaryBalanceSagas } from "./summaryBalance";
import { sagas as marketingProductLinkSagas } from "./marketingProductLink";
import { sagas as affiliatePostAddressSagas } from "./affiliateSettingsProfile";
import { sagas2 as getAffiliateProfileSagas } from "./affiliateSettingsProfile";
import { sagas3 as setGoaffproNotificationPreferenceSagas } from "./affiliateSettingsProfile";
import { sagas as changeInfluencerPasswordSagas } from "./changeInfluencerPassword";
import { sagas as paymentDataSagas } from "./paymentData";
import { sagas as getOrderTrackingSagas } from "./orderTracking";
import { sagas as transactionDataSagas } from "./transactionData";
import { sagas as mediaAssetsSagas } from "./mediaAssets";
import { sagas as purchaseAlertSagas } from "./purchaseAlert";
import { sagas2 as InfluencerNetworkFilerSagas } from "./networkInfluencer";
import { sagas as translationSagas } from "./translation";
import { sagas as uploadProfilePhoto } from "./uploadProfilePhoto";
import { sagas as goaffproPaymentSagas } from "./goaffproPayments";
import { sagas as athenaAnalyticsSagas } from "./athenaAnalytics";
import { sagas as creatorAthenaAnalyticsSagas } from "./creatorAthenaAnalytics";
import { sagas as goaffproAnalyticsSagas } from "./goaffproAnalytics"

export default function* rootSaga() {
  yield all({
    ...loginSagas,
    ...refreshSagas,
    ...totalViewsSagas,
    ...totalGraphviews,
    ...clickStatsSagas,
    ...planSagas,
    ...grabProductsSagas,
    ...influencerNetworkSagas,
    ...totalGlobalStatsSagas,
    ...addPyvitProductSagas,
    ...uploadCustomProductSagas,
    ...saveProductSagas,
    ...deleteProductSagas,
    ...editProductSagas,
    ...videosSagas,
    ...videoAnalyticsSagas,
    ...addVideoSagas,
    ...videoDetailSagas,
    ...deleteVideoSagas,
    ...saveVideoDetailSagas,
    ...changeVideoThumbmnailSagas,
    ...unassignProductSagas,
    ...editVideoAuthSagas,
    ...assignItemSagas,
    ...productByVideoSagas,
    ...videoViewStatsSagas,
    ...uniqueVideoTotalViewsSagas,
    ...videoProductClickSagas,
    ...analyticsVideoSagas,
    ...userProfileSagas,
    ...createNomadeWalletSagas,
    ...eWalletDashboardSagas,
    ...profileAccountSagas,
    ...deleteAddressSagas,
    ...createAddressSagas,
    ...countriesListSaga,
    ...subscriptionDetailSagas,
    ...orderHistorySaga,
    ...subscriptionAlert,
    ...creatorLibrarySagas,
    ...creatLibraryAnalyticsSagas,
    ...creatorCopyVideoSagas,
    ...activatePauseVideoSagas,
    ...reactivateSubscriptionSagas,
    ...unsubscribePlanSagas,
    ...summaryProfileSagas,
    ...summaryBalanceSagas,
    ...marketingProductLinkSagas,
    ...affiliatePostAddressSagas,
    ...getAffiliateProfileSagas,
    ...changeInfluencerPasswordSagas,
    ...paymentDataSagas,
    ...getOrderTrackingSagas,
    ...transactionDataSagas,
    ...purchaseAlertSagas,
    ...InfluencerNetworkFilerSagas,
    ...mediaAssetsSagas,
    ...setGoaffproNotificationPreferenceSagas,
    ...translationSagas,
    ...uploadProfilePhoto,
    ...goaffproPaymentSagas,
    ...athenaAnalyticsSagas,
    ...creatorAthenaAnalyticsSagas,
    ...goaffproAnalyticsSagas,
  });
}
