import {
  PRIMARYCOLOUR,
  SECONDARYCOLOUR,
  THIN_BORDER_COLOR,
} from "../../../colors";

export const muiStyle = {
  btnContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    columnGap: "25px",
    marginBottom: "10px",
    justifyContent: "flex-end",
  },
  pyvitBtn: {
    marginBottom: "15px",
    textTransform: "none",
    padding: "5px 30px",
  },
  outlinedBtn: {
    marginBottom: "15px",
    textTransform: "none",
    padding: "4px 29px",
  },
  customBtn: {
    textTransform: "none",
    padding: "5px 30px",
    mb: "15px",
    backgroundColor: "#000",
  },
  paper: {
    padding: 2,
    textAlign: "center",
    border: `1px solid ${THIN_BORDER_COLOR}`,
    boxShadow: "none",
  },
  textStyle: {
    fontWeight: "600",
    width: "100px",
    textAlign: "left",
    fontFamily: "Jost",
    fontSize: "0.95rem",
    lineHeight: 1.6,
  },
  gridStyle: { width: "175px" },
  productDetail: {
    display: "flex",
    justifyContent: "space-between",
    mt: 1,
  },
  titleContainer: { maxWidth: "calc(100% - 20px)", padding: "0 20px 0 0" },
  loader: { height: "calc(100vh - 350px)" },
  gridContainerStyle: { mb: "20px" },
  productContainer: {
    marginTop: "20px",
  },
  paper2: {
    boxShadow: "none",
    border: `1px solid ${THIN_BORDER_COLOR}`,
    padding: 2,
    textAlign: "center",
    ":hover": {
      border: `1px solid ${THIN_BORDER_COLOR}`,
      boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.24)",
    },
  },
  paperMobile2: {
    boxShadow: "none",
    padding: 0,
    textAlign: "center",
    ":hover": {
      border: `1px solid ${THIN_BORDER_COLOR}`,
      boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.24)",
    },
  },
  boxStyle: { position: "relative" },
  content: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    alignItems: "center",
  },
  contentMobile: {
    marginTop: "10px",
  },
  image: {
    width: "100%",
    height: "15rem",
    objectFit: "cover",
  },
  cart: {
    border: `0.5px solid ${PRIMARYCOLOUR}`,
    borderRadius: "3px",
    padding: "6px",
    width: "45%",
    display: "flex",
    color: SECONDARYCOLOUR,
    justifyContent: "center",
  },
  cartMobile: {
    border: `0.5px solid ${PRIMARYCOLOUR}`,
    borderRadius: "3px",
    padding: "6px",
    width: "100%",
    display: "flex",
    color: SECONDARYCOLOUR,
    justifyContent: "center",
  },
  buyNow: {
    background: PRIMARYCOLOUR,
    borderRadius: "3px",
    color: "#fff",
    padding: "7px",
    width: "45%",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "black",
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "black",
    },
  },
  buyNowMobile: {
    background: PRIMARYCOLOUR,
    borderRadius: "3px",
    color: "#fff",
    padding: "7px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    "&:hover": {
      backgroundColor: "black",
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "black",
    },
  },
  productBox: {
    height: "44px",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  typographyStyle: {
    fontSize: "1rem",
    lineHeight: "16px",
    fontFamily: "Jost",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: "bold",
  },
  icon:{ mr: "5px" }
};
