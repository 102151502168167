import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React, { useEffect, useState } from "react";
import { styles } from "./style";
import { changePasswordSchema, initialValues } from "./ChangePasswordSchema";
import { changeAccountPassword } from "../../../actions";
import { FormLabel } from "../../../utils/styledComponents";
import { RootState } from "../../../configureStore";

function ChangePassword() {
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState([false, false]);
  const { changePasswordData } = useSelector(
    (state: RootState) => state?.entities?.accountProfile
  );

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    dirty,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: () => {
      dispatch(changeAccountPassword.request({ data: { ...values } }));
    },
  });

  useEffect(() => {
    if (!changePasswordData?.isLoading && changePasswordData?.isSuccess) {
      resetForm();
    }
  }, [changePasswordData && changePasswordData?.isLoading]);

  const passwordFields = [
    {
      label: "New password",
      id: "password",
      name: "password",
      value: values.password,
      error: touched?.password && !!errors?.password,
      helperText: touched?.password && errors?.password,
      type: showPassword[0] ? "text" : "password",
    },
    {
      label: "Re-type password",
      id: "confirm_password",
      name: "confirm_password",
      value: values.confirm_password,
      error: touched?.confirm_password && !!errors?.confirm_password,
      helperText: touched?.confirm_password && errors?.confirm_password,
      type: showPassword[1] ? "text" : "password",
    },
  ];

  return (
    <Box>
      {passwordFields.map((item, index) => {
        return (
          <Stack key={index} sx={styles.fieldContain}>
            <FormLabel>
              {t(item.label)}
              <span style={{ color: "#FF0000" }}>*</span>
            </FormLabel>
            <TextField
              size="small"
              type={item.type}
              sx={styles.field}
              id={item.id}
              name={item.name}
              onChange={handleChange}
              value={item.value}
              error={item.error}
              helperText={item.helperText && t(item.helperText)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword((prev) =>
                          prev.map((value, i) => (i === index ? !value : value))
                        );
                      }}
                      edge="end"
                    >
                      {showPassword[index] ? (
                        <Visibility sx={styles.inputStyle} />
                      ) : (
                        <VisibilityOff sx={styles.inputStyle} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Stack>
        );
      })}
      <Box
        sx={{
          display: "flex",
          justifyContent: isSmallScreen ? "center" : "left",
        }}
      >
        <Button
          type="submit"
          onClick={() => handleSubmit()}
          sx={
            Object.keys(errors).length > 0
              ? { ...styles.saveBtn }
              : styles.saveBtn
          }
          variant="contained"
          disabled={!dirty}
        >
          {changePasswordData?.isLoading && (
            <CircularProgress size="12px" sx={styles.loaderStyle} />
          )}
          {t("Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default ChangePassword;
