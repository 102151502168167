import { combineReducers } from "redux";
import { actionTypes } from "../../../actions";
import { actions } from "../../../actions/Types";
import { CHANGE_PASSWORD, FAILURE } from "../../../actions/actiontypes";
import { RootState } from "../../../configureStore";
import { toast } from "react-toastify";
import { t } from "i18next";

const { SUCCESS, REQUEST, ACCOUNT_PROFILE, PUT_ACCOUNT_USER_DATA } =
  actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
  isSuccess: false,
};

const entity = () => {
  const accountProfileData = (state = intialState, action: actions) => {
    switch (action.type) {
      case ACCOUNT_PROFILE[SUCCESS]:
        return { ...state, data: action.payload?.data, isLoading: false };
      case ACCOUNT_PROFILE[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload, isLoading: true }
          : state;
      case ACCOUNT_PROFILE[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  const putUserDataResponse = (
    state = { ...intialState, isLoading: false },
    action: actions
  ) => {
    switch (action.type) {
      case PUT_ACCOUNT_USER_DATA[SUCCESS]:
        toast.success(t("Data saved successfully"));
        return { ...intialState, data: action.payload, isLoading: false };
      case PUT_ACCOUNT_USER_DATA[REQUEST]:
        return action?.payload
          ? { ...intialState, data: action.payload, isLoading: true }
          : intialState;
      case PUT_ACCOUNT_USER_DATA[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  const changePasswordData = (
    state = { ...intialState, isLoading: false },
    action: actions
  ) => {
    switch (action.type) {
      case CHANGE_PASSWORD[SUCCESS]:
        toast.success(t("Password changed successfully"));
        return {
          ...intialState,
          data: action.payload,
          isLoading: false,
          isSuccess: true,
        };
      case CHANGE_PASSWORD[REQUEST]:
        return action?.payload
          ? { ...intialState, data: action.payload, isLoading: true }
          : intialState;
      case CHANGE_PASSWORD[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  return combineReducers({
    accountProfileData,
    putUserDataResponse,
    changePasswordData,
  });
};

export default entity;

export const accountProfile = (state: RootState) =>
  state.entities.accountProfile;
