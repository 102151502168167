import * as Yup from "yup";

export const initialValues = {
  password: "",
  confirm_password: "",
};

export const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("*Please enter the new password")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^a-zA-Z0-9\s]).+$/,
      `*Password must contain at least one letter, one number, and one special character`
    )
    .min(8, "*Password must be at least 8 characters"),
  confirm_password: Yup.string()
    .required("*Please enter the retype password")
    .oneOf([Yup.ref("password"), ""], "*Passwords must match")
    .matches(
      /^[^./\\\s]+$/,
      `**Password cannot contain '.', '/', '\\', or space"`
    )
    .min(8, "*Password must be at least 8 characters"),
});
