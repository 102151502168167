import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const {
  REQUEST,
  CREATOR_LIBRARY_ANALYTICS,
  CREATOR_LIBRARY_GRAB_VIDEOS,
  COPY_CREATOR_VIDEO_LINK,
} = actionTypes;

//write sagas function

function* creatorLibraryVideoListSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getCreatorLibraryVideos,
      data.data
    );
    yield sendPayload(apiResponse, CREATOR_LIBRARY_GRAB_VIDEOS);
  } catch (e) {
    yield sendPayloadFailure(e, CREATOR_LIBRARY_GRAB_VIDEOS);
  }
}

function* creatorLibraryAnalyticsListSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getCreatorLibraryAnalitics,
      data.data
    );
    yield sendPayload(apiResponse, CREATOR_LIBRARY_ANALYTICS);
  } catch (e) {
    yield sendPayloadFailure(e, CREATOR_LIBRARY_ANALYTICS);
  }
}

function* copyCreatorVideoSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.copyCreatorVideo,
      data.data
    );
    yield sendPayload(apiResponse, COPY_CREATOR_VIDEO_LINK);
  } catch (e) {
    yield sendPayloadFailure(e, COPY_CREATOR_VIDEO_LINK);
  }
}

export const sagas = {
  watchCreatorLibraryVideosSaga: takeLatest(
    actionTypes.CREATOR_LIBRARY_GRAB_VIDEOS[REQUEST],
    creatorLibraryVideoListSaga
  ),
};

export const sagas2 = {
  watchCreatorLibraryAnalyticsSaga: takeLatest(
    actionTypes.CREATOR_LIBRARY_ANALYTICS[REQUEST],
    creatorLibraryAnalyticsListSaga
  ),
};

export const sagas3 = {
  watchCopylinkSaga: takeLatest(
    actionTypes.COPY_CREATOR_VIDEO_LINK[REQUEST],
    copyCreatorVideoSaga
  ),
};
