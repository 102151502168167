import * as RestAPI from "./rest";

export const getLoginAuth = (data: any) =>
  RestAPI.POST(
    data?.isNomad ? `/api/ewallet/login` : `/auth/login`,
    data?.data
  );

export const getRefreshToken = (data: any) =>
  RestAPI.GET_WITH_CREDS(
    data?.isNomad ? `/api/ewallet/refresh` : `/auth/refresh`,
    data.data
  );

export const getAllViews = (data: any) =>
  RestAPI.GET("/api/athena/total-views", data);

export const getTotalViewStats = (data: any) =>
  RestAPI.GET(`/api/athena/graph-views`, data);

export const getClickStatsWeekly = (data: any) =>
  RestAPI.GET("/api/athena/graph-product-stats", data);
// /api/aacilnsty/videos/click-stats-weekly;

export const getPlanData = (data: any) => RestAPI.GET(`/api/user/plan`, data);

export const getUserProducts = (data: any) =>
  RestAPI.POST("/api/tig/items/grab-items", data);

export const getInfluencerNetworkDetails = (data: any) =>
  RestAPI.GET("/api/goaffpro/user/network", data);

export const getTotalGlobalStats = (data: any) =>
  RestAPI.GET("/api/tig/videos/get-global-stats", data);

export const postPyvitProduct = (data: any) =>
  RestAPI.POST("/api/tig/items/add-shopify-item", data);

export const postCustomProduct = (data: any) =>
  RestAPI.POST_FILE_DATA("/api/tig/upload", data);

export const postAddProduct = (data: any) =>
  RestAPI.POST("/api/tig/items/add-item", data);

export const deleteProduct = (data: any) =>
  RestAPI.POST("/api/tig/items/delete-item", data);

export const postEditProduct = (data: any) =>
  RestAPI.POST("/api/tig/items/save-item", data);

export const getVideos = (data: any) =>
  RestAPI.GET("/api/tig/videos/grab-videos", data);

export const getVideosAnalytics = (data: any) =>
  RestAPI.POST("/api/athena/all-list-analytics", data);

export const postVideoData = (data: any) =>
  RestAPI.POST("/api/tig/videos/add-video", data);

export const getVideoDetails = (data: any) =>
  RestAPI.POST("/api/tig/videos/grab-video", data);

export const deleteVideo = (data: any) =>
  RestAPI.POST("/api/tig/videos/delete-video", data);

export const saveVideoDetails = (data: any) =>
  RestAPI.POST("/api/tig/videos/save-video", data);

export const changeVideoThumbnail = (data: any) =>
  RestAPI.POST("/api/tig/videos/change-thumbnail", data);

export const unAssignProduct = (data: any) =>
  RestAPI.POST("/api/tig/items/unassign-item", data);

export const editVideoAuth = (data: any) =>
  RestAPI.POST("/api/tig/videos/edit/auth", data);

export const assignItem = (data: any) =>
  RestAPI.POST("/api/tig/items/assign-item", data);

export const getVideoProducts = (data: any) =>
  RestAPI.POST("/api/tig/items/grab-items", data);

export const getVideoViewsStats = (data: any) =>
  RestAPI.GET(`/api/athena/graph-views/${data?.videoId}`, data?.data);

export const getUniqueVideoViews = (data: any) =>
  RestAPI.GET(`/api/athena/total-views/${data.videoId}`, {});

export const getProductClicksByVideo = (data: any) =>
  RestAPI.GET(`/api/athena/graph-product-stats`, data);

export const getVideoAnalyticsDataById = (data: any) =>
  RestAPI.POST(`/api/tig/videos/grab-video-analytics`, data);

export const getUserProfileData = (data: any) =>
  RestAPI.GET(`/api/user/profile`, data);

export const createNomadeWallet = (data: any) =>
  RestAPI.POST(`/api/ewallet/account`, data);

export const getEwalletDashboard = (data: any) =>
  RestAPI.GET(`/api/ewallet/dashboard`, data);

export const getAccountProfileData = (data: any) =>
  RestAPI.GET(`/api/shopify/user`, data);

export const putAccountUser = (data: any) =>
  RestAPI.PUT(`/api/shopify/user`, data);

export const deleteAddress = (data: any) =>
  RestAPI.DELETE(`/api/shopify/user/address/${data.id}`);

export const changeAccountPassword = (data: any) =>
  RestAPI.PUT(`/api/shopify/user/password`, data);

export const createAddress = (data: any) =>
  RestAPI.POST(`/api/shopify/user/address`, data);

export const setDefaultAddress = (data: any) =>
  RestAPI.PUT(`/api/shopify/user/address/default/${data.id}`, {});

export const getAllAccountAddresses = (data: any) =>
  RestAPI.GET(`/api/shopify/user/address`, data);

export const updateAddress = (data: any) =>
  RestAPI.PUT(`/api/shopify/user/address/${data.id}`, data?.payload);

export const getAllCountiesListApi = (searchValue: string) =>
  RestAPI.GET(`/api/shopify/countries?search=${searchValue}`);

export const getSubscriptionDetails = (data: any) =>
  RestAPI.GET(`/api/shopify/user/subscription`, data);

export const getOrderHistoryApi = (data: any) =>
  RestAPI.GET(`/api/shopify/orders/history`, data);

export const getSubscriptionALertsApi = () =>
  RestAPI.GET("/api/user/subscription/alerts");

export const getCreatorLibraryAnalitics = (data: any) =>
  RestAPI.POST(`/api/tig/creator-library/stats`, data);

export const getCreatorLibraryVideos = (data: any) =>
  RestAPI.GET(`/api/tig/creator-library`, data);

export const copyCreatorVideo = (data: any) =>
  RestAPI.POST(`/api/tig/videos/clone/${data.content_id}`, {});

export const activatePauseVideoAPI = (data: any) =>
  RestAPI.POST(`/api/tig/videos/toggle-status`, data);

export const reactivateSubscriptionApi = (data: any) =>
  RestAPI.GET(`/api/shopify/user/reactivate`, data);

export const summaryProfileApi = (data: any) =>
  RestAPI.GET(`/api/goaffpro/user/profile`, data);

export const summaryBalanceApi = (data: any) =>
  RestAPI.GET(`/api/goaffpro/user/summary`, data);

export const marketingToolsLinkApi = (data: any) =>
  RestAPI.POST(`/api/goaffpro/user/product-link`, data);

export const unsubscribePlanApi = (data: any) =>
  RestAPI.POST(`/api/shopify/user/unsubscribe?subscription_id=${data}`, {"cancel_reason":"other"});   

export const patchAffiliateAddress = (data: any) =>
  RestAPI.PATCH(`/api/goaffpro/user`, data);

export const getAffiliateProfile = (data: any) =>
  RestAPI.GET(`/api/goaffpro/user`, data);
export const changeInfluencerPassword = (data: any) =>
  RestAPI.POST(`/api/goaffpro/user/change-password`, data);

export const getPaymentSummaryApi = () =>
  RestAPI.GET(`/api/goaffpro/user/profile`);

export const getPaymentSummaryDetails = () =>
  RestAPI.GET(`/api/goaffpro/user/payments?page=1&limit=10`);

export const orderTrackingApi = (data: any) =>
  RestAPI.GET(`/api/shopify/orders/tracking`, data);

export const getTransactionDataApi = (data: any) =>
  RestAPI.GET("/api/goaffpro/user/transactions", data);

export const getTransactionOrderApi = (data: number) =>
  RestAPI.GET(
    `/api/goaffpro/user/transactions/order/${data}`
  );

export const getTransactionDetailsApi = (data: number) =>
  RestAPI.GET(
    `/api/goaffpro/user/transactions/details/${data}`
  );

export const getPurchaseAlert = (data: any) =>
  RestAPI.GET("/api/user/purchase/alerts", data);

export const getMediaAssets = (data: any) =>
  RestAPI.GET("/api/goaffpro/user/media-assets", data);

export const setNotificationPreference = (data: any) =>
  RestAPI.PATCH(`/api/goaffpro/user`, data);

export const getTranslationDataApi = (language: any) =>
  RestAPI.GET(
    language
      ? `/api/translation /?language=${language}`
      : `/api/translation`
  );

export const uploadProfilePhotoApi = (data: any) =>
  RestAPI.POST_FILE_DATA("/api/goaffpro/user/upload-image", data);

export const getGoaffproPayments = (data: any) =>
  RestAPI.GET("/api/goaffpro/user/amount", data);

export const getAthenaAnalyticsStore = (data: any) =>
  RestAPI.GET("/api/athena/get-athena-views-data", data);

export const getCreatorAthenaAnalyticsData = (data: any) =>
  RestAPI.GET("/api/athena/get-creator-videos-athena-data", data);

export const getProductLink = () =>
  RestAPI.GET("/api/goaffpro/user/get-product-link");

export const saveProductLink = (data: any) =>
  RestAPI.POST("/api/goaffpro/user/save-product-link", data);

export const favoriteUnfavorite = (data: any) =>
  RestAPI.PATCH(`/api/goaffpro/user/change-link-status?id=${data.id}`, data.data);

export const deleteProductHistory = (data: any) =>
  RestAPI.DELETE(`/api/goaffpro/user/delete-product-link?id=${data}`);

export const getGoaffproAnalytics = (data: any) =>
  RestAPI.GET("/api/goaffpro/user/analytics", data);  
  
