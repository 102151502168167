import React from "react";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import NavigateRoute from "./NavigateRoute";
import ErrorFallback from "./ErrorFallback";

function NavigateRoutes() {
  const navigate = useNavigate();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => navigate("/")}
    >
      <NavigateRoute />
    </ErrorBoundary>
  );
}

export default NavigateRoutes;
