import React, { useState, useCallback, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import "react-image-crop/dist/ReactCrop.css";
import { Modal, Box, Button, Slider, Typography, Stack } from "@mui/material";
import CropIcon from "@mui/icons-material/Crop";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { getCroppedImg, getRotatedImage } from "../../utils/canvasUtils";

import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { Info, StyledText } from "../../utils/styledComponents";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
const ImageCropper = ({ image, onImageCropped, type }: any) => {
  const laptopBreakPoint = useMediaQuery("(min-width: 900px)");
  const [showCropModal, setShowCropModal] = useState(true);
  const { t } = useTranslation();

  const [imageSrcUrl, setImageSrcUrl] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [videoRatio, setVideoRatio] = useState(16 / 9);
  const [ratio, setRatio] = useState("16:9");

  useEffect(() => {
    const imageUrl = URL.createObjectURL(image);
    setImageSrcUrl(imageUrl);
    return () => URL.revokeObjectURL(imageUrl); 
  }, [image]);

  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const showCroppedImage: any = useCallback(
    async (test: any) => {
      try {
        const croppedImage: any = await getCroppedImg(
          imageSrcUrl,
          croppedAreaPixels,
          rotation
        );
        setCroppedImage(croppedImage);
        onImageCropped(croppedImage);
      } catch (e) {
        console.error(e);
      }
    },
    [imageSrcUrl, croppedAreaPixels, rotation]
  );

  const onRatioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRatio((event.target as HTMLInputElement).value);
    if ((event.target as HTMLInputElement).value === "16:9") {
      setVideoRatio(16 / 9);
    } else {
      setVideoRatio(9 / 16);
    }
  };

  return (
    <>
      <Modal
        open={showCropModal}
        onClose={() => {
          setShowCropModal(false);
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: laptopBreakPoint ? "32rem" : "90vw",
            maxWidth: "100vw",
            bgcolor: "#ffffff",
            p: 4,
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: { xs: "100%", md: "50%" },
              height: { xs: "300px", md: "400px" },
              backgroundColor: "d3d3d3",
            }}
          >
            {imageSrcUrl && (
              <Cropper
                image={imageSrcUrl}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={type == "video" ? videoRatio : 256 / 256}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: { xs: "0px", md: "40px" },
              alignItems: "center",
            }}
          >
            {
              type === "video" && (
                <FormControl sx={{ alignItems: "center" }}>
                  <StyledText variant="overline" textTransform="capitalize">
                    {t("Ratio")}
                  </StyledText>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={ratio}
                    onChange={(e) => onRatioChange(e)}
                  >
                    <FormControlLabel
                      value="16:9"
                      control={<Radio />}
                      label="16:9"
                    />
                    <FormControlLabel
                      value="9:16"
                      control={<Radio />}
                      label="9:16"
                    />
                  </RadioGroup>
                </FormControl>
              )
            }
            <StyledText variant="overline" textTransform="capitalize">
              {t("Zoom")}
            </StyledText>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e: any, zoom: any) => {
                setZoom(zoom);
              }}
            />
            <StyledText variant="overline" textTransform="capitalize">
              {t("Rotation")}
            </StyledText>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              aria-labelledby="Rotation"
              onChange={(e: any, rotation: any) => setRotation(rotation)}
            />
            <Button
              variant="contained"
              component="label"
              sx={{ mt: 2, mb: 2, textTransform: "none" }}
              onClick={() => {
                setShowCropModal(false);
                showCroppedImage();
              }}
            >
              <CheckRoundedIcon />
              {t("Save crop")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

ImageCropper.defaultProps = {
  onImageCropped: () => {},
};

export default ImageCropper;
