import React, { useCallback, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styled from "styled-components";
import { Box, Button, CircularProgress, Grid, useMediaQuery } from "@mui/material";
import { StyledButton, StyledText } from "../../../utils/styledComponents";
import { orderHistoryStyle } from "./style";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import FloatSearch from "../../../shared/components/FloatSearch";
import FLoatDropDown from "../../../shared/components/FLoatDropDown";
import { CONSTANTS } from "../../../utils/constants";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { stylesGlobal } from "../../../utils/muiStyles";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionDetail, unsubscribePlan } from "../../../actions";
import { RootState } from "../../../configureStore";
import CustomPagination from "../../../shared/components/CustomPagination";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackDropLoader from "../../../shared/BackdropLoader";
import { THIN_BORDER_COLOR } from "../../../colors";
import MultipleSelectDropdown from "../../../shared/components/Multiselect";
import Swal from "sweetalert2";
import FloatAlert from "../../../shared/components/FloatAlert";
import FloatMobileFilter from "../../../shared/components/FloatMobileFilter";
import { removeEmptyValues } from "../../../utils/hooks";

const PAGE_LIMIT = 6;

const StyledAccordion = styled(Accordion)({
  border: "none",
  borderTop: `1px solid ${THIN_BORDER_COLOR}`,
  borderRadius: "0px !important",
  boxShadow: "none",
  overflowX: "auto",
  ":hover": {
    boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.24)",
    transform: "scale(1.01)",
    zIndex: "10",
  },
  "& .MuiPaper-root": {
    margin: "0px 0px",
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  width: "100%",
  marginBottom: "0px",
  overflowX: "auto",
  "& .MuiAccordionSummary-content": {
    overflow: "auto !important",
    marginBottom: "0px !important",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    border: "1px solid",
    borderRadius: "4px",
    transition: "none",
    padding: "5px",
    position: "absolute",
    top: "0",
    right: "0",
    margin: "5px",
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({});

const plantList = [
  { name: "Frequency", status: true },
  { name: "SKU", status: true },
  { name: "Next charge date", status: true },
  { name: "Status", status: true },
  { name: "Address id", status: true },
  { name: "Customer id", status: true },
  { name: "External product id/varient id", status: true },
];

const searchByList = [
  { name: "Select all", key: "all", status: false },
  { name: "Subscription Id", key: "subscription_id", status: true },
  { name: "Product title", key: "product_title", status: false },
];

function Subscriptions() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { searchFilterStyle } = stylesGlobal;

  const { subscriptionDetailsData }: any = useSelector(
    (state: RootState) => state?.entities?.subscriptionDetail
  );

  const [multiListColumns, setMultiList] = useState(
    plantList?.map((item) => t(item.name))
  );
  const [subscriptionInfo, setSubscriptionInfo] = useState([]);
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItem, setTotalItem] = useState<number>();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [backdropLoading, setBackdropLoading] = useState(false);
  const [unSubscribePlanData, setUnsubscribePlanData]: any = useState();

  const [filters, setFilters] = React.useState({
    search: "",
    searchBy: "all",
    status: "active",
    sortBy: "id",
    sortOrder: "asc",
    page: currentPage,
    limit: PAGE_LIMIT,
  });

  useEffect(() => {
    if (!subscriptionDetailsData?.isLoading) {
      const info = subscriptionDetailsData?.data?.data?.subscriptions || [];
      setSubscriptionInfo(info);
    }
    if (subscriptionDetailsData?.data?.data) {
      setTotalPages(subscriptionDetailsData?.data?.data?.total_pages ?? 1);
      setCurrentPage(subscriptionDetailsData?.data?.data?.current_page ?? 1);
      setTotalItem(
        subscriptionDetailsData?.data?.data?.total_subsciptions ?? 1
      );
    }
  }, [
    subscriptionDetailsData,
    subscriptionDetailsData?.data?.data?.length,
    currentPage,
  ]);

  const handlePageChange = (event: any, pageNumber: number) => {
    setCurrentPage(pageNumber);
    dispatch(
      getSubscriptionDetail.request(
        removeEmptyValues({ ...filters, page: pageNumber })
      )
    );
  };

  useEffect(() => {
    dispatch(getSubscriptionDetail.request(removeEmptyValues(filters)));
  }, []);

  const commonFilterChange: any = (e: any) => {
    let name = e.target.name;
    let data = {
      searchBy: name === "searchBy" ? e.target.value : filters.searchBy,
      status: name === "status" ? e.target.value : filters.status,
      search: name === "search" ? e.target.value : filters.search,
      sortBy: name === "sortBy" ? e.target.value : filters.sortBy,
      sortOrder: name === "sortOrder" ? e.target.value : filters.sortOrder,
    };
    setFilters({ ...filters, ...data });
    dispatch(getSubscriptionDetail.request(removeEmptyValues({ ...filters, ...data })));
  };

  const searchByChange = (e: any) => {
    let name = e.target.name;
    let data = {
      searchBy: name === "searchBy" ? e.target.value : filters.searchBy,
    };
    setFilters({ ...filters, ...data });
    dispatch(
      getSubscriptionDetail.request(removeEmptyValues({ ...filters, ...data }))
    );
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (debouncedSearchTerm?.length > 0) {
        setFilters({ ...filters, search: debouncedSearchTerm });
        dispatch(
          getSubscriptionDetail.request(
            removeEmptyValues({
              ...{ ...filters, search: debouncedSearchTerm },
              page: 1,
            })
          )
        );
      }
    }, 1000);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [debouncedSearchTerm]);

  const seachByChange = (e: any) => {
    if (e.target.value?.length === 0) {
      setFilters({ ...filters, search: "" });
      dispatch(
        getSubscriptionDetail.request(
          removeEmptyValues({
            ...{ ...filters, search: "" },
            page: 1,
          })
        )
      );
    }
    setDebouncedSearchTerm(e.target.value);
  };

  const { reactivateSubscriptionData }: any = useSelector(
    (state: RootState) => state?.entities?.reactivateSubscription
  );

  const { unsubscribePlanData }: any = useSelector(
    (state: RootState) => state?.entities?.unsubscribePlan
  );

  useEffect(() => {
    if (reactivateSubscriptionData?.data?.data?.landing_url) {
      setTimeout(() => {
        window.location.href =
          reactivateSubscriptionData?.data?.data?.landing_url;
      }, 3000);
    }
  }, [reactivateSubscriptionData]);

  useEffect(() => {
    if (!unsubscribePlanData?.isLoading && !unsubscribePlanData?.isError) {
      dispatch(
        getSubscriptionDetail.request(removeEmptyValues({ ...filters }))
      );
      dispatch(unsubscribePlan.reset());
      setBackdropLoading(false);
    }
    if (unsubscribePlanData?.isError) {
      setBackdropLoading(false);
    }
  }, [unsubscribePlanData?.isLoading, unsubscribePlanData?.isError]);

  const [openAlert, setAlert] = useState(false);
  const handleUnsubscribePlan = (e: any, data: any) => {
    e.preventDefault();
    e.stopPropagation();
    setUnsubscribePlanData(data);
    setAlert(true);
  };

  const unsubscribePlanAction = () => {
    setBackdropLoading(true);
    dispatch(unsubscribePlan.request(unSubscribePlanData));
    setAlert(false);
  };

  const SelectFilters = [
    {
      list: CONSTANTS.SearchOption,
      label: "Search by",
      name: "searchBy",
      onChange: searchByChange,
      value: filters.searchBy,
      disabled: false,
    },
    {
      list: CONSTANTS.subscriptionColumnsFilter,
      label: "Sort by",
      name: "sortBy",
      onChange: commonFilterChange,
      value: filters.sortBy,
      disabled: false,
    },
    {
      list: CONSTANTS.Order,
      label: "Order",
      name: "sortOrder",
      onChange: commonFilterChange,
      value: filters.sortOrder,
      disabled: false,
    },
    {
      list: CONSTANTS.SubscriptionStatusFilter,
      label: "Status",
      name: "status",
      onChange: commonFilterChange,
      value: filters.status,
      disabled: false,
    },
  ];

  const multiselectList = [
    {
      list: plantList,
      label: "Column visibility",
      name: "colVisibility",
      isColumnVisibilty: true,
      disabled: false,
    },
  ];

  const setColumnsMobile = useCallback((value: any) => {
    setMultiList(value);
  }, []);

  const onSubmitMobile = (filters: any) => {
    let mobFilters:any = {}
    Object.entries(filters)?.map(([key,val]) => {
      if (val) {
        mobFilters[key] = val
      }
    })
    dispatch(getSubscriptionDetail.request(mobFilters));
  };

  return (
    <>
      <BackDropLoader open={backdropLoading} />
      <Box
        sx={
          isMobile
            ? { ...searchFilterStyle.outer, alignItems: "center" }
            : searchFilterStyle.outer
        }
      >
        <Box
          sx={{
            ...searchFilterStyle.searchOuter,
            padding: { xs: "0", md: "0 10px 0  10px" },
            width: "20%",
          }}
        >
          <FloatSearch handleSearch={seachByChange} />
        </Box>
        {isMobile ? (
          <FloatMobileFilter
            setColumnsMobile={(values: any) => setColumnsMobile(values)}
            filters={filters}
            setFilters={setFilters}
            multiSelect={multiselectList}
            selectFilters={SelectFilters}
            onSubmitMobile={onSubmitMobile}
          />
        ) : (
          <Box sx={searchFilterStyle.filterOuter}>
            {SelectFilters.map((item) => (
              <FLoatDropDown
                list={item.list}
                label={item.label}
                name={item.name}
                onChange={item.onChange}
                value={item.value}
              />
            ))}
            <MultipleSelectDropdown
              names={plantList?.map((item) => t(item.name))}
              setUpdatedList={setMultiList}
              label="Column visibility"
            />
          </Box>
        )}
      </Box>
      {subscriptionDetailsData?.isLoading ? (
        <Box sx={orderHistoryStyle.loaderBox}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box>
            {subscriptionInfo && subscriptionInfo.length > 0 ? (
              subscriptionInfo?.map((item: any, index: any) => {
                return (
                  <StyledAccordion
                    className=" overflow-x-containnn"
                    sx={orderHistoryStyle.accordionStyle}
                  >
                    <StyledAccordionSummary
                      onClick={(e) => e.stopPropagation()}
                      className=" overflow-x-contain"
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Grid
                        container
                        alignItems={"center"}
                        sx={orderHistoryStyle.gridStyle}
                      >
                        <Grid container alignItems={"center"} width={"70%"}>
                          <Grid item xs={12} md={3}>
                            <StyledText fontWeight="600">
                              {t("Subscription iD")}
                            </StyledText>
                            <StyledText lineheight="34px">
                              {item?.id}
                            </StyledText>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <StyledText fontWeight="600">
                              {t("Product/Varient")}
                            </StyledText>
                            <StyledText lineheight="34px">
                              {item?.product_title}
                            </StyledText>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <StyledText fontWeight="600">
                              {t("Price")}
                            </StyledText>
                            <StyledText lineheight="34px">
                              {item?.price} {item?.presentment_currency}
                            </StyledText>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <StyledText fontWeight="600">
                              {t("Quantity")}
                            </StyledText>
                            <StyledText lineheight="34px">
                              {item?.quantity}
                            </StyledText>
                          </Grid>
                        </Grid>
                        <Grid>
                          <Grid item xs={12} md={2.4}>
                            {item.status !== "active" ? (
                              <StyledButton
                                paddingvalue="8px 20px"
                                colour="#FFF"
                                background="#262626"
                                sx={orderHistoryStyle.reactivateButton}
                                onClick={() => {
                                  window.open(item?.subscription_url);
                                }}
                              >
                                <RestartAltIcon />
                                <StyledText
                                  color="#fff"
                                  fontWeight="600"
                                  sx={
                                    orderHistoryStyle.reactivateButton
                                      .buttonText
                                  }
                                >
                                  {t("Reactivate")}
                                </StyledText>
                              </StyledButton>
                            ) : (
                              <Button
                                sx={stylesGlobal.outlinedBtn}
                                onClick={(e) =>
                                  handleUnsubscribePlan(e, item.id)
                                }
                                variant="outlined"
                              >
                                <DeleteOutlineOutlinedIcon />
                                {t("Unsubscribe")}
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails
                      className=" overflow-x-contain"
                      sx={orderHistoryStyle.accordionDetailsStyle}
                    >
                      <Grid
                        container
                        alignItems={"center"}
                        sx={orderHistoryStyle.gridStyle}
                      >
                        <StyledAccordionDetails
                          className=" overflow-x-contain"
                          sx={orderHistoryStyle.accordionDetailsStyle}
                        >
                          <Grid
                            container
                            alignItems={"center"}
                            sx={orderHistoryStyle.gridStyle}
                          >
                            <Grid
                              container
                              alignItems={"center"}
                              width={"80%"}
                              sx={orderHistoryStyle.dataStyle}
                            >
                              {multiListColumns?.includes(t("Frequency")) && (
                                <Grid item xs={12} lg={2.4} pr={"50px"}>
                                  <StyledText fontWeight="600">
                                    {t("Frequency")}
                                  </StyledText>
                                  <StyledText lineheight="34px">{`${item?.order_interval_frequency} ${item?.order_interval_unit}`}</StyledText>
                                </Grid>
                              )}
                              {multiListColumns?.includes(t("SKU")) && (
                                <Grid item xs={12} lg={2.4} pr={"50px"}>
                                  <StyledText fontWeight="600">
                                    {t("SKU")}
                                  </StyledText>
                                  <StyledText lineheight="34px">{`${item?.sku}`}</StyledText>
                                </Grid>
                              )}
                              {multiListColumns?.includes(
                                t("Next charge date")
                              ) && (
                                  <Grid item xs={12} lg={2.4} pr={"50px"}>
                                    <StyledText fontWeight="600">
                                      {t("Next charge date")}
                                    </StyledText>
                                    <StyledText lineheight="34px">{`${item?.next_charge_scheduled_at || "-"
                                      }`}</StyledText>
                                  </Grid>
                                )}
                              {multiListColumns?.includes(t("Status")) && (
                                <Grid item xs={12} lg={2.4} pr={"50px"}>
                                  <StyledText fontWeight="600">
                                    {t("Status")}
                                  </StyledText>
                                  <StyledText
                                    sx={orderHistoryStyle.textStyle}
                                    lineheight="34px"
                                  >{`${item?.status}`}</StyledText>
                                </Grid>
                              )}
                              {multiListColumns?.includes(t("Address id")) && (
                                <Grid item xs={12} lg={2.4} pr={"50px"}>
                                  <StyledText fontWeight="600">
                                    {t("Address id")}
                                  </StyledText>
                                  <StyledText lineheight="34px">{`${item?.address_id}`}</StyledText>
                                </Grid>
                              )}
                              {multiListColumns?.includes(t("Customer id")) && (
                                <Grid item xs={12} lg={2.4} pr={"50px"}>
                                  <StyledText fontWeight="600">
                                    {t("Customer id")}
                                  </StyledText>
                                  <StyledText lineheight="34px">{`${item?.customer_id}`}</StyledText>
                                </Grid>
                              )}
                              {multiListColumns?.includes(
                                t("External product id/varient id")
                              ) && (
                                  <Grid item xs={12} lg={2.4} pr={"50px"}>
                                    <StyledText fontWeight="600">
                                      {t("External product id/varient id")}
                                    </StyledText>
                                    <StyledText lineheight="34px">{`${item?.external_product_id?.ecommerce}`}</StyledText>
                                  </Grid>
                                )}
                            </Grid>
                            <Grid></Grid>
                          </Grid>
                        </StyledAccordionDetails>
                      </Grid>
                      {/* </Grid> */}
                    </StyledAccordionDetails>
                  </StyledAccordion>
                );
              })
            ) : (
              <StyledText textAlign={"center"}>
                {t("No records found.")}
              </StyledText>
            )}
          </Box>
          {totalItem &&
            totalPages &&
            currentPage &&
            subscriptionInfo.length > 0 ? (
            <CustomPagination
              page={currentPage}
              count={totalPages}
              handlePageChange={handlePageChange}
            />
          ) : (
            <></>
          )}
        </>
      )}
      <FloatAlert
        open={openAlert}
        onClose={() => setAlert(false)}
        alert={t("Are you sure you want to unsubscribe this plan?")}
        action={() => unsubscribePlanAction()}
      />
    </>
  );
};

export default Subscriptions;

export const LinkProductStyles = {
  wrapperBox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    columnGap: "15px",
  },
  loaderBox: {
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  tableContainer: {
    marginTop: "20px",
    table: {
      border: `1px solid ${THIN_BORDER_COLOR}`,
    },
    titleBox: {
      textOverflow: "ellipsis",
      height: "65px",
      width: "100%",
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "0px",
      },
    },
    rowStyle: {
      cursor: "pointer",
    },
  },
};
