import React from 'react';
import { CreatorLibVideoProps } from '../../../models/backoffice.models';
import { TableHead } from '@mui/material';
import { StyledTableHeadCell, StyledTableHeadRow } from '../../../utils/styledComponents';
import { CONSTANTS } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';

function CreatorLibVideoHead(props: CreatorLibVideoProps) {
    const { multilistInfo } = props;
    const { creatorLibHeadCells } = CONSTANTS
    const {t } = useTranslation()
    return (
      <TableHead>
        <StyledTableHeadRow>
          {creatorLibHeadCells.map((headCell, index) => {
            if (
              t(headCell.label) === t("Video") ||
              multilistInfo
                .map((item: any) => t(item))
                ?.includes(t(headCell.label))
            ) {
              return (
                <StyledTableHeadCell
                  key={index}
                  align="left"
                  id={headCell.id}
                  scope="row"
                >
                  {t(headCell.label)}
                </StyledTableHeadCell>
              );
            }
            return null;
          })}
        </StyledTableHeadRow>
      </TableHead>
    );
  }
export default CreatorLibVideoHead;