import * as actions from "./actiontypes";
import {
  DELETE_HISTORY,
  FAVORITE_UNFAVORITE,
  GET_COUNTRIES_LIST,
  GET_PRODUCT_LINK,
  GET_TRANSACTION_DETAILS,
  GET_TRANSLATION_DATA,
  SAVE_PRODUCT_LINK,
  UPLOAD_PROFILE_PHOTO,
} from "./actiontypes";

const {
  SUCCESS,
  REQUEST,
  FAILURE,
  RESET,
  LOGIN,
  REFRESH,
  TOTAL_VIEWS,
  TOTAL_VIEW_GRAPH,
  CLICK_STATS_WEEKLY,
  PLAN,
  USER_PRODUCTS,
  INFLUENCER_NETWORK,
  TOTAL_GLOBAL_STATS,
  ADD_PYVIT_PRODUCT,
  UPLOAD_CUSTOM_PRODUCT,
  SAVE_ITEM,
  DELETE_PRODUCT,
  EDIT_ITEM,
  GRAB_VIDEOS,
  VIDEOS_ANALYTICS,
  ADD_VIDEO,
  VIDEO_DETAILS,
  DELETE_VIDEO,
  SAVE_VIDEO_DETAILS,
  CHANGE_VIDEO_THUMBNAIL,
  UNASSIGN_PRODUCT,
  EDIT_VIDEO_AUTH,
  ASSIGN_ITEM,
  GRAB_PRODUCTS_BY_VIDEO,
  VIDEO_VIEW_STATS,
  UNIQUE_VIDEO_TOTAL_VIEWS,
  VIDEO_PRODUCT_CLICKS_STATS,
  GRAB_VIDEO_ANALYTICS,
  USER_PROFILE_DATA,
  NOMAD_CREATE_ACCOUNT,
  E_WALLET_DASHBOARD,
  ACCOUNT_PROFILE,
  PUT_ACCOUNT_USER_DATA,
  CHANGE_PASSWORD,
  DELETE_ADDRESSS,
  CREATE_ADDRESS,
  GET_ALL_ADDRESSES,
  SET_DEFAULT_ADDRESS,
  UPDATE_ADDRESS,
  SUBSCRIPTION_DETAILS,
  GET_ORDER_HISTORY,
  GET_SUBSCRIPTION_ALERTS,
  CREATOR_LIBRARY_ANALYTICS,
  CREATOR_LIBRARY_GRAB_VIDEOS,
  COPY_CREATOR_VIDEO_LINK,
  OPEN_SEND_MONEY,
  CLOSE_SEND_MONEY,
  ACTIVATE_PAUSE_VIDEO,
  REACTIVATE_SUBSCRIPTION,
  UNSUBSCRIBE_PLAN,
  SUMMARY_PROFILE,
  SUMMARY_BALANCE,
  MARKETING_TOOLS_PRODUCT_LINK,
  POST_AFFILIATE_ADDRESS,
  GET_AFFILIATE_PROFILE,
  CHANGE_INFLUENCER_PASSWORD,
  GET_PAYMENT_SUMMARY,
  GET_PAYMENT_DETAILS,
  ORDER_TRACKING,
  GET_TRANSACTION_DATA,
  GET_TRANSACTION_ORDER,
  USER_PURCHASE_ALERT,
  INFLUENCER_NETWORK_FILTER,
  GET_MEDIA_ASSETS,
  GOAFFPRO_NOTIFICATION_PREFERENCE,
  GOAFFPRO_PAYMENTS,
  ATHENA_ANALYTICS_CACHE,
  CREATOR_ATHENA_ANALYTICS_CACHE,
  GOAFFPRO_ANALYTICS,
  action,
} = actions;

export const loginUser = {
  request: (data: Object) => action(LOGIN[REQUEST], { data }),
  reset: () => action(LOGIN[RESET], {}),
};

export const refreshCall = {
  request: (data: Object) => action(REFRESH[REQUEST], { data }),
  reset: () => action(REFRESH[RESET], {}),
};

export const totalViews = {
  request: (data: Object) => action(TOTAL_VIEWS[REQUEST], data),
  reset: () => action(TOTAL_VIEWS[RESET], {}),
};

export const totalGraphViews = {
  request: (data: Object) => action(TOTAL_VIEW_GRAPH[REQUEST], data),
  reset: () => action(TOTAL_VIEW_GRAPH[RESET], {}),
};

export const clickStatsWeekly = {
  request: (data: Object) => action(CLICK_STATS_WEEKLY[REQUEST], data),
  reset: () => action(CLICK_STATS_WEEKLY[RESET], {}),
};

export const planDataCall = {
  request: (data: Object) => action(PLAN[REQUEST], data),
  reset: () => action(PLAN[RESET], {}),
};

export const showUserProducts = {
  request: (data: Object) => action(USER_PRODUCTS[REQUEST], data),
  reset: () => action(USER_PRODUCTS[RESET], {}),
};

export const getInfluencerNetowrk = {
  request: (data: Object) => action(INFLUENCER_NETWORK[REQUEST], { data }),
  reset: () => action(INFLUENCER_NETWORK[RESET], {}),
};

export const filterInfluencerNetowrk = {
  request: (data: Object) =>
    action(INFLUENCER_NETWORK_FILTER[REQUEST], { data }),
  reset: () => action(INFLUENCER_NETWORK_FILTER[RESET], {}),
};

export const totalGlobalStats = {
  request: (data: Object) => action(TOTAL_GLOBAL_STATS[REQUEST], data),
  reset: () => action(TOTAL_GLOBAL_STATS[RESET], {}),
};

export const addPyvitProduct = {
  request: (data: Object) => action(ADD_PYVIT_PRODUCT[REQUEST], data),
  reset: () => action(ADD_PYVIT_PRODUCT[RESET], {}),
};

export const uploadCustomProductCall = {
  request: (data: Object) => action(UPLOAD_CUSTOM_PRODUCT[REQUEST], data),
  reset: () => action(UPLOAD_CUSTOM_PRODUCT[RESET], {}),
};

export const saveProductCall = {
  request: (data: Object) => action(SAVE_ITEM[REQUEST], data),
  reset: () => action(SAVE_ITEM[RESET], {}),
};

export const deleteProductCall = {
  request: (data: Object) => action(DELETE_PRODUCT[REQUEST], data),
  reset: () => action(DELETE_PRODUCT[RESET], {}),
};

export const editProductCall = {
  request: (data: Object) => action(EDIT_ITEM[REQUEST], data),
  reset: () => action(EDIT_ITEM[RESET], {}),
};

export const getVideosCall = {
  request: (data: Object) => action(GRAB_VIDEOS[REQUEST], { data }),
  reset: () => action(GRAB_VIDEOS[RESET], {}),
};

export const getVideosAnalyticsCall = {
  request: (data: Object) => action(VIDEOS_ANALYTICS[REQUEST], data),
  reset: () => action(VIDEOS_ANALYTICS[RESET], {}),
};

export const addVideoCall = {
  request: (data: Object) => action(ADD_VIDEO[REQUEST], data),
  reset: () => action(ADD_VIDEO[RESET], {}),
};

export const getVideoDetailsCall = {
  request: (data: Object) => action(VIDEO_DETAILS[REQUEST], data),
  reset: () => action(VIDEO_DETAILS[RESET], {}),
};

export const deleteVideoCall = {
  request: (data: Object) => action(DELETE_VIDEO[REQUEST], data),
  reset: () => action(DELETE_VIDEO[RESET], {}),
};

export const saveVideoDetailsCall = {
  request: (data: Object) => action(SAVE_VIDEO_DETAILS[REQUEST], data),
  reset: () => action(SAVE_VIDEO_DETAILS[RESET], {}),
};

export const changeVideoThumbnailCall = {
  request: (data: Object) => action(CHANGE_VIDEO_THUMBNAIL[REQUEST], data),
  reset: () => action(CHANGE_VIDEO_THUMBNAIL[RESET], {}),
};

export const unAssignProductCall = {
  request: (data: Object) => action(UNASSIGN_PRODUCT[REQUEST], data),
  reset: () => action(UNASSIGN_PRODUCT[RESET], {}),
};

export const editVideoAuthCall = {
  request: (data: Object) => action(EDIT_VIDEO_AUTH[REQUEST], data),
  reset: () => action(EDIT_VIDEO_AUTH[RESET], {}),
};

export const assignItemCall = {
  request: (data: Object) => action(ASSIGN_ITEM[REQUEST], data),
  reset: () => action(ASSIGN_ITEM[RESET], {}),
};

export const productsByVideoIdCall = {
  request: (data: Object) => action(GRAB_PRODUCTS_BY_VIDEO[REQUEST], data),
  reset: () => action(GRAB_PRODUCTS_BY_VIDEO[RESET], {}),
};

export const videoViewStatsCall = {
  request: (data: Object) => action(VIDEO_VIEW_STATS[REQUEST], data),
  reset: () => action(VIDEO_VIEW_STATS[RESET], {}),
};

export const uniqueVideoTotalViewsCall = {
  request: (data: Object) => action(UNIQUE_VIDEO_TOTAL_VIEWS[REQUEST], data),
  reset: () => action(UNIQUE_VIDEO_TOTAL_VIEWS[RESET], {}),
};

export const videoProductClicksWeekly = {
  request: (data: Object) => action(VIDEO_PRODUCT_CLICKS_STATS[REQUEST], data),
  reset: () => action(VIDEO_PRODUCT_CLICKS_STATS[RESET], {}),
};

export const grabVideoAnalytics = {
  request: (data: Object) => action(GRAB_VIDEO_ANALYTICS[REQUEST], data),
  reset: () => action(GRAB_VIDEO_ANALYTICS[RESET], {}),
};

export const getUserProfile = {
  request: (data: Object) => action(USER_PROFILE_DATA[REQUEST], data),
  reset: () => action(USER_PROFILE_DATA[RESET], {}),
};

export const createNomadAccount = {
  request: (data: Object) => action(NOMAD_CREATE_ACCOUNT[REQUEST], data),
  reset: () => action(NOMAD_CREATE_ACCOUNT[RESET], {}),
};

export const geteWalletDashboard = {
  request: (data: Object) => action(E_WALLET_DASHBOARD[REQUEST], data),
  reset: () => action(E_WALLET_DASHBOARD[RESET], {}),
};

export const getAccountProfile = {
  request: (data: Object) => action(ACCOUNT_PROFILE[REQUEST], data),
  reset: () => action(ACCOUNT_PROFILE[RESET], {}),
};

export const putAccountUserData = {
  request: (data: Object) => action(PUT_ACCOUNT_USER_DATA[REQUEST], data),
  reset: () => action(PUT_ACCOUNT_USER_DATA[RESET], {}),
};

export const changeAccountPassword = {
  request: (data: Object) => action(CHANGE_PASSWORD[REQUEST], data),
  reset: () => action(CHANGE_PASSWORD[RESET], {}),
};

export const deleteAdderess = {
  request: (data: Object) => action(DELETE_ADDRESSS[REQUEST], data),
  reset: () => action(DELETE_ADDRESSS[RESET], {}),
};

export const createAddress = {
  request: (data: Object) => action(CREATE_ADDRESS[REQUEST], data),
  reset: () => action(CREATE_ADDRESS[RESET], {}),
};

export const setDefultAddress = {
  request: (data: Object) => action(SET_DEFAULT_ADDRESS[REQUEST], data),
  reset: () => action(SET_DEFAULT_ADDRESS[RESET], {}),
};

export const getAllAddresses = {
  request: (data: Object) => action(GET_ALL_ADDRESSES[REQUEST], data),
  reset: () => action(GET_ALL_ADDRESSES[RESET], {}),
};

export const updateAddress = {
  request: (data: Object) => action(UPDATE_ADDRESS[REQUEST], data),
  reset: () => action(UPDATE_ADDRESS[RESET], {}),
};

export const getAllCountriesList = {
  request: (data: string) => action(GET_COUNTRIES_LIST[REQUEST], { data }),
  reset: () => action(GET_COUNTRIES_LIST[RESET], {}),
};

export const getSubscriptionDetail = {
  request: (data: Object) => action(SUBSCRIPTION_DETAILS[REQUEST], { data }),
  reset: () => action(SUBSCRIPTION_DETAILS[RESET], {}),
};

export const getOrderHistory = {
  request: (data: any) => action(GET_ORDER_HISTORY[REQUEST], { data }),
  reset: () => action(GET_ORDER_HISTORY[RESET], {}),
};

export const getSubscriptionALerts = {
  request: () => action(GET_SUBSCRIPTION_ALERTS[REQUEST]),
  reset: () => action(GET_SUBSCRIPTION_ALERTS[RESET], {}),
};

export const grabCreatorLibraryList = {
  request: (data: Object) =>
    action(CREATOR_LIBRARY_GRAB_VIDEOS[REQUEST], { data }),
  reset: () => action(CREATOR_LIBRARY_GRAB_VIDEOS[RESET], {}),
};

export const getCreatorLibraryAnalytics = {
  request: (data: Object) =>
    action(CREATOR_LIBRARY_ANALYTICS[REQUEST], { data }),
  reset: () => action(CREATOR_LIBRARY_ANALYTICS[RESET], {}),
};

export const copyCreatorVideo = {
  request: (data: Object) => action(COPY_CREATOR_VIDEO_LINK[REQUEST], { data }),
  reset: () => action(COPY_CREATOR_VIDEO_LINK[RESET], {}),
};

export const activatePauseVideo = {
  request: (data: Object) => action(ACTIVATE_PAUSE_VIDEO[REQUEST], { data }),
  reset: () => action(ACTIVATE_PAUSE_VIDEO[RESET], {}),
};

export const reactivateSubscription = {
  request: (data: Object) => action(REACTIVATE_SUBSCRIPTION[REQUEST], { data }),
  reset: () => action(REACTIVATE_SUBSCRIPTION[RESET], {}),
};

export const unsubscribePlan = {
  request: (data: Object) => action(UNSUBSCRIBE_PLAN[REQUEST], { data }),
  reset: () => action(UNSUBSCRIBE_PLAN[RESET], {}),
};

export const summaryProfile = {
  request: (data: Object) => action(SUMMARY_PROFILE[REQUEST], data),
  reset: () => action(SUMMARY_PROFILE[RESET], {}),
};

export const summaryBalance = {
  request: (data: Object) => action(SUMMARY_BALANCE[REQUEST], { data }),
  reset: () => action(SUMMARY_BALANCE[RESET], {}),
};

export const marketingProductLink: any = {
  request: (data: Object) =>
    action(MARKETING_TOOLS_PRODUCT_LINK[REQUEST], { data }),
  reset: () => action(MARKETING_TOOLS_PRODUCT_LINK[RESET], {}),
};

export const changeInfluencerPassword: any = {
  request: (data: Object) => action(CHANGE_INFLUENCER_PASSWORD[REQUEST], data),
  reset: () => action(CHANGE_INFLUENCER_PASSWORD[RESET], {}),
};

export const openSendMoney = () => ({
  type: OPEN_SEND_MONEY,
});

export const closeSendMoney = () => ({
  type: CLOSE_SEND_MONEY,
});

export const addAffiliateAddress = {
  request: (data: Object) => action(POST_AFFILIATE_ADDRESS[REQUEST], { data }),
  reset: () => action(POST_AFFILIATE_ADDRESS[RESET], {}),
};

export const getAffiliateProfile = {
  request: (data: Object) => action(GET_AFFILIATE_PROFILE[REQUEST], { data }),
  reset: () => action(GET_AFFILIATE_PROFILE[RESET], {}),
};

export const getPaymentSummary = {
  request: () => action(GET_PAYMENT_SUMMARY[REQUEST]),
  reset: () => action(GET_PAYMENT_SUMMARY[RESET], {}),
};

export const getPaymentDetails = {
  request: () => action(GET_PAYMENT_DETAILS[REQUEST]),
  reset: () => action(GET_PAYMENT_DETAILS[RESET], {}),
};

export const getOrderTrackingDetails = {
  request: (data: Object) => action(ORDER_TRACKING[REQUEST], { data }),
  reset: () => action(ORDER_TRACKING[RESET], {}),
};

export const getTransactionData = {
  request: (data: Object) => action(GET_TRANSACTION_DATA[REQUEST], { data }),
  reset: () => action(GET_TRANSACTION_DATA[RESET], {}),
};

export const getTransactionOrder = {
  request: (data: number) => action(GET_TRANSACTION_ORDER[REQUEST], { data }),
  reset: () => action(GET_TRANSACTION_ORDER[RESET], {}),
};

export const getTransactionDetails = {
  request: (data: number) => action(GET_TRANSACTION_DETAILS[REQUEST], { data }),
  reset: () => action(GET_TRANSACTION_DETAILS[RESET], {}),
};

export const getUserPurchaseAlert = {
  request: (data: Object) => action(USER_PURCHASE_ALERT[REQUEST], { data }),
  reset: () => action(USER_PURCHASE_ALERT[RESET], {}),
};

export const getMediaAssets = {
  request: (data: Object) => action(GET_MEDIA_ASSETS[REQUEST], { data }),
  reset: () => action(GET_MEDIA_ASSETS[RESET], {}),
};

export const setNotificationPreference = {
  request: (data: Object) =>
    action(GOAFFPRO_NOTIFICATION_PREFERENCE[REQUEST], { data }),
  reset: () => action(GOAFFPRO_NOTIFICATION_PREFERENCE[RESET], {}),
};

export const getTranslationData = {
  request: (data: string) => action(GET_TRANSLATION_DATA[REQUEST], { data }),
  reset: () => action(GET_TRANSLATION_DATA[RESET], {}),
};

export const uploadProfilePhoto = {
  request: (data: any) => action(UPLOAD_PROFILE_PHOTO[REQUEST], { data }),
  reset: () => action(UPLOAD_PROFILE_PHOTO[RESET], {}),
};

export const getGoaffproPayments = {
  request: (data: any) => action(GOAFFPRO_PAYMENTS[REQUEST], { data }),
  reset: () => action(GOAFFPRO_PAYMENTS[RESET], {}),
};

export const getAthenaAnalytics = {
  request: (data: any) => action(ATHENA_ANALYTICS_CACHE[REQUEST], { data }),
  reset: () => action(ATHENA_ANALYTICS_CACHE[RESET], {}),
};

export const getCreatorAthenaAnalytics = {
  request: (data: any) => action(CREATOR_ATHENA_ANALYTICS_CACHE[REQUEST], { data }),
  reset: () => action(CREATOR_ATHENA_ANALYTICS_CACHE[RESET], {}),
};

export const getProductLink = {
  request: () => action(GET_PRODUCT_LINK[REQUEST]),
  reset: () => action(GET_PRODUCT_LINK[RESET], {}),
};

export const saveProductLink = {
  request: (data: Object) => action(SAVE_PRODUCT_LINK[REQUEST], { data }),
  reset: () => action(SAVE_PRODUCT_LINK[RESET], {}),
};

export const favoriteProduct = {
  request: (data: Object) => action(FAVORITE_UNFAVORITE[REQUEST], { data }),
  reset: () => action(FAVORITE_UNFAVORITE[RESET], {}),
};

export const deleteProduct = {
  request: (data: number) => action(DELETE_HISTORY[REQUEST], { data }),
  reset: () => action(DELETE_HISTORY[RESET], {}),
};
export const getGoaffproAnalytics = {
  request: (data: any) => action(GOAFFPRO_ANALYTICS[REQUEST], { data }),
  reset: () => action(GOAFFPRO_ANALYTICS[RESET], {}),
}

export const actionTypes = {
  ...actions,
};
