import React from "react";

const LinkedinIcon = ({
  fill = "#D5D5D5",
  width = "1.253rem",
  height = "0.933rem",
}) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0016 3.60001C10.0492 3.60001 3.60156 10.0476 3.60156 18C3.60156 25.9524 10.0492 32.4 18.0016 32.4C25.954 32.4 32.4016 25.9524 32.4016 18C32.4016 10.0476 25.954 3.60001 18.0016 3.60001ZM12.5968 10.0836C13.6072 10.0836 14.2804 10.7568 14.2804 11.6544C14.2804 12.552 13.6072 13.2252 12.4852 13.2252C11.4748 13.2264 10.8016 12.552 10.8016 11.6544C10.8016 10.7568 11.4748 10.0836 12.5968 10.0836ZM14.4016 24H10.8016V14.4H14.4016V24ZM26.4016 24H23.0128V18.7536C23.0128 17.3028 22.1092 16.968 21.7708 16.968C21.4324 16.968 20.302 17.1912 20.302 18.7536C20.302 18.9768 20.302 24 20.302 24H16.8016V14.4H20.3032V15.7392C20.7544 14.958 21.658 14.4 23.3524 14.4C25.0468 14.4 26.4016 15.7392 26.4016 18.7536V24Z"
      fill="black"
    />
  </svg>
);

export default React.memo(LinkedinIcon);
