import {
  SCROLLBAR_THUMB,
  THEMEBACKGROUNDCOLOR,
  THIN_BORDER_COLOR,
} from "../../colors";

export const styles = {
  loaderBox: {
    display: "flex",
    justifyContent: "center",
    height: "400px",
    alignItems: "center",
  },
  tableContainer: {
    height: "calc(100vh - 240px)",
    marginTop: "20px",
    table: {
      border: `1px solid ${THIN_BORDER_COLOR}`,
    },
    buttonBox: {
      display: "flex",
      flexDirection: "row",
      columnGap: "10px",
      rowGap: "10px",
      alignItems:"center",
    },
    descriptionBox: {
      textOverflow: "ellipsis",
      width: "200px",
      overflowX: "hidden",
      padding: "10px 0",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: SCROLLBAR_THUMB,
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-track": {
        background: THEMEBACKGROUNDCOLOR,
        borderRadius: "4px",
      },
    },
    titleBox: {
      textOverflow: "ellipsis",
      width: "100px",
      overflowX: "hidden",
      padding: "10px 0",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: SCROLLBAR_THUMB,
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-track": {
        background: THEMEBACKGROUNDCOLOR,
        borderRadius: "4px",
      },
    },
    titleBoxMobile: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "250px",
      overflowX: "hidden",
      textAlign: "left",
      padding: "10px 0",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: SCROLLBAR_THUMB,
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-track": {
        background: THEMEBACKGROUNDCOLOR,
        borderRadius: "4px",
      },
    },
    deleteIconWrapper: {
      padding: "3px 4px",
      borderRadius: "45%",
      backgroundColor: "#E5E4E2",
    },
  },
  buttonBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px",
  },
  boxStyle: { width: "100%" },
  paperStyle: { width: "100%", mb: 2, padding: 0 },
  rowStyle: { width: "100%" },
  deleteIconStyle: { color: "#818589", fontSize: "18px" },
  noRecord: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  noRecordsBox: {
    display: "flex",
    padding: "20px",
    justifyContent: "center",
    alignItems: "center",
  },
};
