import { BOX_SHADOW_COLOR, SECONDARYCOLOUR, THIN_BORDER_COLOR } from "../../colors"

export const addVideoStyle = {
    wrapper: { pr: "10px", height: "fit-content" },
    stackStyle: { cursor: "pointer" },
    wrapperStack: {
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        display: "flex",
        p: "10px 0",
    },
    buttonStyle: { mt: 2, textTransform: "none" }
}

export const videoDetailsStyle = {
    icon: { cursor: "pointer" },
    stackMargin: { mb: "10px" },
    childStack: { mt: "10px" },
    boxHeight: { height: "100vh" }
}

export const videoHeaderCard = {
    wrapper: {
        border: `1px solid ${THIN_BORDER_COLOR}`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0px",
    },
    gridStyle: {
        p: "0 10px",
        height: "100%",
        width: { xl: "70%", lg: "70%", md: "70%", sm: "15rem", xs: "7rem" },
    },
    subtitle: {
        wordBreak: "break-word",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    iconButton: {
        border: `1px solid ${THIN_BORDER_COLOR}`,
        height: "fit-content",
    },
    deleteIcon: { color: "grey" }
}

export const videoMetaDataStyle = {
    wrapper: { display: "flex", flexDirection: "column", alignItems: "center" ,textAlign:"center"}
}

export const videoTabsStyle = {
    wrapper: { p: { lg: "20px", xl: "20px", md: "20px", xs: "20px 0px" } },
    boxStyle: { width: "100%" },
    boxItem: { borderBottom: 1, borderColor: THIN_BORDER_COLOR, width: "100%" },
    parentTab: {
        width: { xs: "fit-content", sm: "100%", textTransform: "none" },
    },
    childTab: { width: "20%", textTransform: "none" ,whiteSpace:"nowrap"}
}

export const videoUploadStyle = {
  baseStyle: {
    flex: 1,
    minHeight: "150px",
    minWidth: "150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px",
    height: "auto",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: THIN_BORDER_COLOR,
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    outline: "none",
    transition: "border .24s ease-in-out",
    margin: "0px 10px",
    cursor: "pointer",
  },
  focusedStyle: {
    borderColor: SECONDARYCOLOUR,
  },
  acceptStyle: {
    borderColor: "#00e676",
  },
  rejectStyle: {
    borderColor: "#ff1744",
  },
  uploadBtnStyle: {
    mt: 2,
    textTransform: "none",
    display: "flex",
    ":disabled": {
      backgroundColor: "#555",
      color: "#fff",
    },
  },
  infoIconStyle: { fontSize: "16px", color: "#FE2020", marginRight: "5px" },
  errorInfoStyle: { display: "flex", marginBottom: "15px", color: "#FE2020 !important" },
  errorTextOuter: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "4px",
  },
  changeFileBtn: {
    padding: "5px 20px",
    width: "fit-content",
    minWidth: "100px",
    textTransform: "none",
    fontFamily: "Jost",
    boxShadow: `0 2px 2px ${BOX_SHADOW_COLOR}`,
    mt: 2,
    backgroundColor: "#fff",
    border: `1px solid ${THIN_BORDER_COLOR}`,
  },
};

