import { TableHead } from '@mui/material';
import React from 'react';
import { StyledTableHeadCell, StyledTableHeadRow } from '../../../utils/styledComponents';
import { useTranslation } from 'react-i18next';
import { headCellHook } from './helper,';
import { useSelector } from 'react-redux';
import { IAPIData } from '../../../models/types.model';
import { RootState } from '../../../configureStore';
import { VideosHeadCellProps } from '../../../models/backoffice.models';

const VideosTableHead = (props:any) => {
    const { t } = useTranslation();
    const { rowCount, multilistInfo } = props;

    const profileData = useSelector(
        (state: RootState) => state?.entities?.userProfile?.userData as IAPIData
      );
    const userRole = profileData?.data && profileData?.data?.user?.role;
    const headCells:VideosHeadCellProps[] = headCellHook(userRole);

    return (
      <TableHead>
        <StyledTableHeadRow>
          {headCells.map((headCell, index: number) => {
            if (
              t(headCell.label) === t("Video") ||
              multilistInfo
                .map((item: any) => t(item))
                ?.includes(t(headCell.label))
            ) {
              return (
                <StyledTableHeadCell
                  key={index}
                  align={headCell.align}
                  id={headCell.id}
                  scope="row"
                >
                  {t(headCell.label)}
                </StyledTableHeadCell>
              );
            }
            return null;
          })}
        </StyledTableHeadRow>
      </TableHead>
    );
};

export default VideosTableHead;