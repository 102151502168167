import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";

const { OPEN_SEND_MONEY, CLOSE_SEND_MONEY } = actionTypes;

const entity = () => {
  const globalModalState: any = (
    state = { isOpen: false },
    action: actions
  ) => {
    switch (action.type) {
      case OPEN_SEND_MONEY:
        return {
          isOpen: true,
        };
      case CLOSE_SEND_MONEY:
        return {
          isOpen: false,
        };
      default:
        return state;
    }
  };

  return combineReducers({
    globalModalState,
  });
};

export default entity;
