import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { StyledText } from "../../../utils/styledComponents";
import { stylesGlobal } from "../../../utils/muiStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../configureStore";
import { addAffiliateAddress } from "../../../actions";
import { addressInitialValues } from "./settingsSchema";
import { addressSchema } from "./settingsSchema";
import { addressStyle, centerButtonWrapper } from "./style";
import { CountryMobileData } from "../../../utils/constants";
import { ICountryData } from "../../../models/types.model";
import { useTranslation } from "react-i18next";

function InfluencerAddress() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [modified, setModified] = useState<boolean>(false);

  const { affiliateProfileData, postAffiliateAddress }: any = useSelector(
    (state: RootState) => state?.entities?.affiliateProfile
  );

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    dirty,
  } = useFormik({
    initialValues: addressInitialValues,
    validationSchema: addressSchema,
    onSubmit: () => {
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([key, value]) => (value ? true : false))
      );
      dispatch(
        addAffiliateAddress.request({
          ...filteredValues,
          zip: filteredValues?.zip.toString(),
        })
      );
    },
  });

  const addressDetailsFields = [
    {
      label: "Street address",
      id: "address_1",
      name: "address_1",
      value: values.address_1,
      error: touched?.address_1 && !!errors?.address_1,
      helperText: touched?.address_1 && errors?.address_1,
      type: "text",
    },
    {
      label: "City",
      id: "city",
      name: "city",
      value: values.city,
      error: touched?.city && !!errors?.city,
      helperText: touched?.city && errors?.city,
      type: "text",
    },
    {
      label: "State",
      id: "state",
      name: "state",
      value: values.state,
      error: touched?.state && !!errors?.state,
      helperText: touched?.state && errors?.state,
      type: "text",
    },
    {
      label: "Country",
      id: "country",
      name: "country",
      value: values.country,
      error: touched?.country && !!errors?.country,
      helperText: touched?.country && errors?.country,
      type: "selectField",
    },
    {
      label: "Zipcode/Postcode",
      id: "zip",
      name: "zip",
      value: values.zip,
      error: touched?.zip && !!errors?.zip,
      helperText: touched?.zip && errors?.zip,
      type: "number",
    },
  ];

  useEffect(() => {
    if (!affiliateProfileData?.isLoading && !affiliateProfileData?.isError) {
      let keys = Object.keys(addressInitialValues);
      const filteredValues: any = Object.fromEntries(
        Object.entries(affiliateProfileData?.data).filter(([key, value]) =>
          keys.includes(key) && value ? true : false
        )
      );

      let address = affiliateProfileData?.data?.address_1 ?? "";
      setValues({ ...filteredValues, address_1: address });
    }
  }, [affiliateProfileData?.isLoading]);

  const renderOptions = (optionArray: ICountryData[]) => {
    const options = optionArray.map((item) => ({
      label: item.name,
      value: item.code,
    }));
    return options;
  };

  return (
    <Box>
      <Box sx={addressStyle.wrapper}>
        <StyledText
          fontvalue="20px"
          lineheight="30px"
          sx={addressStyle.wrapper.profileText}
        >
          {t("Address")}
        </StyledText>
        <StyledText
          fontvalue="14px"
          lineheight="18px"
          sx={addressStyle.wrapper.idText}
        >
          {t("Your mailing address")}
        </StyledText>

        <Grid container spacing={2}>
          {addressDetailsFields.map((item, index) => (
            <Grid item xs={12} lg={6} key={index}>
              <StyledText fontvalue="16px" lineheight="21px">
                {t(item.label)}
              </StyledText>
              {item.type === "selectField" ? (
                <Autocomplete
                  disablePortal
                  id={item.id}
                  options={renderOptions(CountryMobileData)}
                  defaultValue={{
                    label:
                      CountryMobileData?.find(
                        (item: any) =>
                          item.code === affiliateProfileData?.data?.country
                      )?.name ?? "",
                    value: "country",
                  }}
                  onChange={(event, newValue: any) => {
                    setFieldValue("country", newValue?.value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      sx={addressStyle.wrapper.field}
                      id={item.id}
                      name={item.name}
                      value={item.value}
                      error={item.error}
                      helperText={
                        item.helperText ? t(item.helperText) : item.helperText
                      }
                    ></TextField>
                  )}
                />
              ) : (
                <TextField
                  fullWidth
                  size="small"
                  sx={addressStyle.wrapper.field}
                  id={item.id}
                  name={item.name}
                  onChange={(e) => {
                    handleChange(e);
                    setModified(true);
                  }}
                  value={item.value}
                  error={item.error}
                  helperText={
                    item.helperText ? t(item.helperText) : item.helperText
                  }
                  type={item.type}
                />
              )}
            </Grid>
          ))}
        </Grid>
        <Box sx={centerButtonWrapper}>
          <Button
            variant="contained"
            sx={stylesGlobal.containedBtn}
            onClick={() => handleSubmit()}
            disabled={!dirty || !modified}
          >
            {t("Submit")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default InfluencerAddress;
