import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyledText } from "../../../utils/styledComponents";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { linkedProductStyles } from "./linkedProducts/style";
import { iskeyInObject } from "../../../utils/hooks";

interface DropDownProps {
  value: string;
  onChange: (e: any) => void;
  list: ListProps[];
  label: string;
  name?: string;
  disabled?: boolean;
}

interface ListProps {
  name: string;
  key?: string;
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Jost",
  },
}));

function MobileTabList({
  value,
  onChange,
  list,
  name,
  disabled,
}: DropDownProps) {
  const { t } = useTranslation();

  return (
    <FormControl
      sx={linkedProductStyles.formControlStyle}
      size="small"
    >
      <Select
        disabled={disabled}
        label="Unit"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        input={<BootstrapInput />}
        name={name}
        sx={linkedProductStyles.textStyle}
        value={value}
        onChange={onChange}
      >
        {list?.map((unit: ListProps, index: number) => {
          return (
            <MenuItem
              key={index}
              value={iskeyInObject(unit,"key") ? unit.key : unit.name}
            >
              <StyledText>{t(unit?.name)}</StyledText>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default MobileTabList;
