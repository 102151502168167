import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Info,
  StyledTableCell,
  StyledTableRow,
  StyledText,
} from "../../../utils/styledComponents";
import NetworkExplorer from "./NetworkExplorer";
import { useDispatch, useSelector } from "react-redux";
import { getInfluencerNetowrk } from "../../../actions";
import { RootState } from "../../../configureStore";
import Loading from "../../../shared/Loading";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { useTranslation } from "react-i18next";
import { networkStyle, styles } from "./styles";
import NetworkTableTowHeader from "./NetworkTableTowHeader";
import NetworkTableOneHeader from "./NetworkTableOneHeader";

function Network() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [tooltipOpenCustomer, setTooltipOpenCustomer] = useState(false);
  const [showExplorer, setShowExplorer] = useState(false);
  const [affiliateReferalsList, setAffiliateReferalsList] = useState<any[]>([]);
 
  const { influencerNetworkData }: any = useSelector(
    (state: RootState) => state?.entities?.influencerNetwork
  );

  const referalEarnings = [
    {
      value:
        `$${parseFloat(influencerNetworkData?.data?.earnings).toFixed(2)}` ??
        "-",
      name: "Earnings",
    },
    {
      value: influencerNetworkData?.data?.affiliates?.length ?? "-",
      name: "Influencer referrals",
    },
  ];

  useEffect(() => {
    dispatch(getInfluencerNetowrk.request({}));
  }, []);

  useEffect(() => {
    let data = influencerNetworkData?.data?.affiliates?.map((item: any) => {
      return {
        image: item?.profile_photo?.url ?? "",
        isProfileImage: item?.profile_photo?.url?.length ? true : false,
        name: item.name,
        email: item.email,
        number: item.phone,
        orders: item.orders,
      };
    });

    setAffiliateReferalsList(data);
  }, [influencerNetworkData?.isLoading]);
  
  const handleExploreClick = () => {
    setShowExplorer(true);
  };

  const handleBackClick = () => {
    setShowExplorer(false);
  };

  const handleIconClick = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url);
      setTooltipOpenCustomer(true);
      setTimeout(() => {
        setTooltipOpenCustomer(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  return (
    <>
      {!influencerNetworkData?.isLoading && !influencerNetworkData?.isError ? (
        <Grid>
          {!showExplorer ? (
            <Box>
              <Box sx={networkStyle.wrapper}>
                <StyledText>
                  {t(
                    "Invite influencers to promote on PYVIT, grow your influencer network, and earn up to 5% commissions on their sales"
                  )}
                </StyledText>
                <Box sx={networkStyle.wrapper.BoxStyle}>
                  <StyledText fontvalue="16px">{t("Signup link")}</StyledText>
                  <Box display={"flex"} alignItems={"center"}>
                    <Link
                      sx={networkStyle.wrapper.linkColor}
                      underline="hover"
                      href={
                        influencerNetworkData?.data?.influencer_referral_link ??
                        "#"
                      }
                    >
                      {influencerNetworkData?.data?.influencer_referral_link ??
                        ""}
                    </Link>
                    <IconButton>
                      <Tooltip
                        open={tooltipOpenCustomer}
                        onClose={() => setTooltipOpenCustomer(false)}
                        title={t("Link copied!")}
                      >
                        <ContentCopyRoundedIcon
                          sx={networkStyle.wrapper.iconStyle}
                          onClick={() =>
                            handleIconClick(
                              influencerNetworkData?.data
                                ?.influencer_referral_link ?? ""
                            )
                          }
                        />
                      </Tooltip>
                    </IconButton>
                  </Box>
                </Box>
                <Box marginTop={"20px"}>
                  <StyledText fontvalue="16px">
                    {t("Network summary")}
                  </StyledText>
                  <Button
                    variant="outlined"
                    sx={networkStyle.wrapper.buttonStyle}
                    onClick={handleExploreClick}
                  >
                    {t("Network explorer")}
                  </Button>
                </Box>
              </Box>
              <Grid sx={networkStyle.wrapper.gridStyle}>
                <Grid sx={networkStyle.wrapper.innerGrid}>
                  {referalEarnings &&
                    referalEarnings?.map((item) => {
                      return (
                        <Grid sx={networkStyle.wrapper.nestedGrid}>
                          <StyledText
                            fontSize="30px"
                            lineheight="45px"
                            fontWeight="500"
                          >
                            {item.value}
                          </StyledText>
                          <StyledText>{t(item.name)}</StyledText>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
              <StyledText fontvalue="16px" sx={networkStyle.wrapper.textMargin}>
                {t("Influencer referrals")}
              </StyledText>
              <Grid item xs={12} sx={networkStyle.wrapper.gridMargin}>
                <Grid container spacing={2}>
                  {affiliateReferalsList?.length > 0 ? (
                    affiliateReferalsList?.map((affiliate, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card sx={networkStyle.wrapper.cardStyle}>
                          <Card sx={networkStyle.wrapper.innerCard}>
                            {affiliate.isProfileImage ? (
                              <CardMedia
                                sx={networkStyle.wrapper.cardMedia}
                                component="img"
                                height="88px"
                                image={affiliate.image}
                              />
                            ) : (
                              <Box sx={networkStyle.wrapper.boxStyle}>
                                <Box sx={networkStyle.wrapper.boxFlex}>
                                  <Avatar sx={networkStyle.wrapper.avatarStyle}>
                                    {affiliate.name?.slice(0, 1)}
                                  </Avatar>
                                </Box>
                              </Box>
                            )}
                            <CardContent sx={networkStyle.wrapper.cardContent}>
                              <StyledText>{affiliate.name}</StyledText>
                              <StyledText color={"#2196F3"}>
                                {affiliate.email}
                              </StyledText>
                              <StyledText>{affiliate.number}</StyledText>
                            </CardContent>
                          </Card>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <Box padding={"20px"}>
                      <Info>{t("No referrals to show")}</Info>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TableContainer sx={networkStyle.wrapper.tableContainer} >
                    <Table
                      stickyHeader
                      className="overflow-y-tbl"
                      sx={{ ...styles.tableContainer.table, width: "100%" }}
                      aria-labelledby="tableTitle"
                    >
                      <NetworkTableOneHeader />
                      <TableBody>
                        <StyledTableRow
                          hover
                          role="checkbox"
                          sx={styles.rowStyle}
                        >
                          <StyledTableCell
                            sx={networkStyle.wrapper.tableCell}
                            align="left"
                          >
                            <StyledText
                              sx={styles.tableContainer.descriptionBox}
                            >
                              {"https://www.pyvit.com/"}
                            </StyledText>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <StyledText sx={styles.tableContainer.titleBox}>
                              {influencerNetworkData?.data?.affiliates
                                ?.length ?? "-"}
                            </StyledText>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TableContainer sx={networkStyle.wrapper.tableContainer}>
                    <Table
                      stickyHeader
                      className="overflow-y-tbl"
                      sx={{ ...styles.tableContainer.table, width: "100%" }}
                      aria-labelledby="tableTitle"
                    >
                      <NetworkTableTowHeader />
                      <TableBody>
                        <StyledTableRow
                          hover
                          role="checkbox"
                          sx={styles.rowStyle}
                        >
                          <StyledTableCell align="left">
                            <StyledText
                              sx={styles.tableContainer.descriptionBox}
                            >
                              {"level"}
                            </StyledText>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <StyledText sx={styles.tableContainer.titleBox}>
                              {influencerNetworkData?.data?.sales ?? "-"}
                            </StyledText>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <StyledText
                              sx={styles.tableContainer.descriptionBox}
                            >
                              ${influencerNetworkData?.data?.revenue ?? "-"}
                            </StyledText>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <NetworkExplorer handleBack={handleBackClick} />
          )}
        </Grid>
      ) : !influencerNetworkData?.isError ? (
        <Box height={"calc(100vh - 100px)"}>
          <Loading />
        </Box>
      ) : (
        <Info>{t("Something went wrong")}</Info>
      )}
    </>
  );
};

export default Network;
