import { IconButton } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { FloatSearchStyle, Styles } from "./style";
import { useTranslation } from "react-i18next";
import { iskeyInObject } from "../../utils/hooks";

const FloatSearch = (props: any) => {
  const { t } = useTranslation();

  return (
    <Paper sx={Styles.searchPaper}>
      <InputBase
        name={"searchString"}
        sx={FloatSearchStyle.inputBaseStyle}
        placeholder={
          iskeyInObject(props,"placeHolder")
            ? t(props.placeHolder)
            : t("Search")
        }
        inputProps={{ "aria-label": "search" }}
        onChange={(e) => props.handleSearch(e)}
        disabled={
          iskeyInObject(props,"disabled") ? props.disabled : false
        }
      />
      <IconButton type="button" sx={Styles.searchIcon} aria-label="search">
        <SearchIcon sx={FloatSearchStyle.searchIconStyle} />
      </IconButton>
    </Paper>
  );
};

export default FloatSearch;
