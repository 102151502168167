import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { centerButtonWrapper, detailsStyle, referralCodeStyle } from "./style";
import { StyledText } from "../../../utils/styledComponents";
import { useFormik } from "formik";
import { refInitialValues, refSchema } from "./settingsSchema";
import { addressDetailsStyle } from "../../eWallet/GlobalCard/style";
import { stylesGlobal } from "../../../utils/muiStyles";
import { useDispatch, useSelector } from "react-redux";
import { addAffiliateAddress } from "../../../actions";
import { RootState } from "../../../configureStore";
import { useTranslation } from "react-i18next";
import FloatAlert from "../../../shared/components/FloatAlert";

function InfluencerReferralCode() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openAlert, setAlert] = useState(false);
  const [modified, setModified] = useState<boolean>(false);

  const { affiliateProfileData }: any = useSelector(
    (state: RootState) => state?.entities?.affiliateProfile
  );

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: refInitialValues,
      validationSchema: refSchema,
      onSubmit: () => {
        dispatch(addAffiliateAddress.request(values));
      },
    });

  useEffect(() => {
    if (!affiliateProfileData?.isLoading && !affiliateProfileData?.isError) {
      setFieldValue("ref_code", affiliateProfileData?.data.ref_code ?? "");
    }
  }, [affiliateProfileData?.isLoading]);

  return (
    <Box sx={detailsStyle.wrapper}>
      <Box sx={detailsStyle.wrapper.item}>
        <StyledText fontvalue="20px" lineheight="30px">
          {t("Referral code")}
        </StyledText>
        <StyledText>{t("Customize your referral code")}</StyledText>
        <StyledText
          fontvalue="16px"
          lineheight="22px"
          sx={detailsStyle.wrapper.item.labelText}
        >
          {t("Referral code")}
        </StyledText>
        <Box sx={referralCodeStyle.flexStyle}>
          <TextField
            size="small"
            type="text"
            sx={addressDetailsStyle.wrapper.field}
            id="ref_code"
            name="ref_code"
            onChange={(e) => {
              handleChange(e);
              setModified(true);
            }}
            value={values.ref_code}
            error={touched?.ref_code && !!errors?.ref_code}
            helperText={
              touched?.ref_code && errors?.ref_code && t(errors?.ref_code)
            }
          ></TextField>
          <StyledText fontvalue="12px" lineheight="18px" color="#949494">
            {t("This will change all the product reference handles.")}
          </StyledText>
        </Box>
        <Box sx={centerButtonWrapper}>
          <Button
            variant="contained"
            disabled={values.ref_code.length < 1 || !modified}
            sx={stylesGlobal.containedBtn}
            onClick={() => setAlert(true)}
          >
            {t("Submit")}
          </Button>
        </Box>
      </Box>
      <FloatAlert
        open={openAlert}
        onClose={() => {
          setAlert(false);
        }}
        alert={t("This will change all the product reference handles.")}
        actionBtn="Submit"
        action={() => {
          handleSubmit();
          setAlert(false);
        }}
      />
    </Box>
  );
};

export default InfluencerReferralCode;
