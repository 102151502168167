/* eslint-disable */
import { Avatar, Box, Button, Grid, Stack, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { StyledText } from "../../../utils/styledComponents";
import { centerButtonWrapper, profileStyle } from "./style";
import { useFormik } from "formik";
import { profileInitialValues, profileSchema } from "./settingsSchema";
import { useDropzone } from "react-dropzone";
import { stylesGlobal } from "../../../utils/muiStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  addAffiliateAddress,
  getAffiliateProfile,
  uploadCustomProductCall,
} from "../../../actions";
import { RootState } from "../../../configureStore";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";
import ImageCropper from "../../../shared/cropImage/ImageCroper";
import { uploadProfilePhoto } from "../../../actions";
import FloatModal from "../../../shared/components/FloatModal";
import FloatThumbnailBody from "../../../shared/components/addProducts/FloatThumbnailBody";
import {
  PRIMARYCOLOUR,
  SECONDARYCOLOUR,
  THIN_BORDER_COLOR,
} from "../../../colors";

function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openThumbnailModal, setOpenThumbnailModal] = useState<boolean>(false);
  const [bioText, setBioText] = useState("");
  const [modified, setModified] = useState<boolean>(false);

  const { baseStyle, focusedStyle, acceptStyle, rejectStyle } = {
    baseStyle: {
      flex: 1,
      minHeight: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "1.75rem",
      height: "auto",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: THIN_BORDER_COLOR,
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    focusedStyle: {
      borderColor: SECONDARYCOLOUR,
    },
    acceptStyle: {
      borderColor: "#00e676",
    },
    rejectStyle: {
      borderColor: "#ff1744",
    },
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    open: openFileDialog,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: { "image/jpeg": [], "image/png": [] },
    maxFiles: 1,
    multiple: false,
  });

  const [croppedImage, setCroppedImage]: any = useState();

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const file = acceptedFiles.map((file) => (
    <ImageCropper
      key={file.name}
      image={file}
      type="video"
      onImageCropped={(image: any) => setCroppedImage(image)}
    />
  ));

  const { affiliateProfileData, postAffiliateAddress }: any = useSelector(
    (state: RootState) => state?.entities?.affiliateProfile
  );

  const { uploadProfilePhotoData }: any = useSelector(
    (state: RootState) => state?.entities?.uploadProfilePhoto
  );

  const [goaffproID, setId] = useState("");
  const [profileImageURL, setProfileImageURL] = useState<string>("");
  const [bioTouched, setbioTouched] = useState<boolean>(false);
  const reactQuillRef: any = React.useRef();
  const requiredFieldsIndexes = [0, 1, 2, 3,4, 12];

  const imageSrcUrl = (image: any) => URL.createObjectURL(image);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    dirty,
  } = useFormik({
    initialValues: profileInitialValues,
    validationSchema: profileSchema,
    onSubmit: () => {
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([key, value]) => (value ? true : false))
      );
      if (convertToPlainText(values.comments)?.length > 0) {
        dispatch(addAffiliateAddress.request(filteredValues));
      }
    },
  });

  useEffect(() => {
    if (affiliateProfileData?.data?.database_image?.profile_photo_path) {
      setProfileImageURL(
        affiliateProfileData?.data?.database_image?.profile_photo_path
      );
    } else if (affiliateProfileData?.data.hasOwnProperty("profile_photo")) {
      setProfileImageURL(affiliateProfileData?.data?.profile_photo?.url);
    }
    return () => setbioTouched(false);
  }, []);

  useEffect(() => {
    if (!affiliateProfileData?.isLoading && !affiliateProfileData?.isError) {
      if (affiliateProfileData?.data?.database_image?.profile_photo_path) {
        setProfileImageURL(
          affiliateProfileData?.data?.database_image?.profile_photo_path
        );
      } else if (affiliateProfileData?.data.hasOwnProperty("profile_photo")) {
        setProfileImageURL(affiliateProfileData?.data?.profile_photo?.url);
      }

      setId(affiliateProfileData?.data?.id);
      let keys = Object.keys(profileInitialValues);
      const filteredValues: any = Object.fromEntries(
        Object.entries(affiliateProfileData?.data).filter(([key, value]) =>
          keys.includes(key) && value ? true : false
        )
      );
      setValues(filteredValues);
    }
  }, [affiliateProfileData?.isLoading]);

  useEffect(() => {
    if (
      !uploadProfilePhotoData?.isLoading &&
      !uploadProfilePhotoData.isError &&
      uploadProfilePhotoData.isSuccess
    ) {
      dispatch(getAffiliateProfile.reset());
      dispatch(uploadProfilePhoto.reset());
      dispatch(getAffiliateProfile.request({}));
    }
  }, [uploadProfilePhotoData?.isLoading]);

  const profileFields = useMemo(() => {
    const profileFieldsList = [
      {
        label: "Pyvit handle",
        id: "company_name",
        name: "company_name",
        value: values.company_name,
        error: touched?.company_name && !!errors?.company_name,
        helperText: touched?.company_name && errors?.company_name,
        type: "text",
      },
      {
        label: "First name",
        id: "first_name",
        name: "first_name",
        value: values.first_name,
        error: touched?.first_name && !!errors?.first_name,
        helperText: touched?.first_name && errors?.first_name,
        type: "text",
      },
      {
        label: "Last name",
        id: "last_name",
        name: "last_name",
        value: values.last_name,
        error: touched?.last_name && !!errors?.last_name,
        helperText: touched?.last_name && errors?.last_name,
        type: "text",
      },
      {
        label: "Date of birth",
        id: "date_of_birth",
        name: "date_of_birth",
        value: values.date_of_birth,
        error: touched?.date_of_birth && !!errors?.date_of_birth,
        helperText: touched?.date_of_birth && errors?.date_of_birth,
        type: "date",
      },
      {
        label: "Phone",
        id: "phone",
        name: "phone",
        value: values.phone,
        error: touched?.phone && !!errors?.phone,
        helperText: touched?.phone && errors?.phone,
        type: "text",
      },
      {
        label: "Website link",
        id: "website",
        name: "website",
        value: values.website,
        error: touched?.website && !!errors?.website,
        helperText: touched?.website && errors?.website,
        type: "text",
      },
      {
        label: "Facebook link",
        id: "facebook",
        name: "facebook",
        value: values.facebook,
        error: touched?.facebook && !!errors?.facebook,
        helperText: touched?.facebook && errors?.facebook,
        type: "text",
      },
      {
        label: "Instagram link",
        id: "instagram",
        name: "instagram",
        value: values.instagram,
        error: touched?.instagram && !!errors?.instagram,
        helperText: touched?.instagram && errors?.instagram,
        type: "text",
      },
      {
        label: "Pinterest link",
        id: "pinterest",
        name: "pinterest",
        value: values.pinterest,
        error: touched?.pinterest && !!errors?.pinterest,
        helperText: touched?.pinterest && errors?.pinterest,
        type: "text",
      },
      {
        label: "Snapchat link",
        id: "snapchat",
        name: "snapchat",
        value: values.snapchat,
        error: touched?.snapchat && !!errors?.snapchat,
        helperText: touched?.snapchat && errors?.snapchat,
        type: "text",
      },
      {
        label: "Tiktok link",
        id: "tiktok",
        name: "tiktok",
        value: values.tiktok,
        error: touched?.tiktok && !!errors?.tiktok,
        helperText: touched?.tiktok && errors?.tiktok,
        type: "text",
      },
      {
        label: "Twitter link",
        id: "twitter",
        name: "twitter",
        value: values.twitter,
        error: touched?.twitter && !!errors?.twitter,
        helperText: touched?.twitter && errors?.twitter,
        type: "text",
      },
      {
        label: "Youtube link",
        id: "youtube",
        name: "youtube",
        value: values.youtube,
        error: touched?.youtube && !!errors?.youtube,
        helperText: touched?.youtube && errors?.youtube,
        type: "text",
      },
      {
        label: "Bio",
        id: "comments",
        name: "comments",
        value: values.comments,
        error: touched?.comments && !!errors?.comments,
        helperText: touched?.comments && errors?.comments,
        type: "text",
        infoText:
          "Enter a short profile description so your followers know a little about you or your business",
      },
    ];
    return profileFieldsList;
  }, [values, touched, errors]);

  const handleView = () => {
    window.open(
      `https://www.pyvit.com/pages/influencer-profile/${affiliateProfileData?.data?.ouid}&collection=all_collections`
    );
  };

  const handleBioChange = (e: any) => {
    setFieldValue("comments", e);
  };

  const checkCharacterCount = (event: any) => {
    const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
    if (unprivilegedEditor.getLength() > 1000 && event.key !== "Backspace")
      event.preventDefault();
  };

  const handleChangeProfilePhoto = async (newThumbnail: any) => {
    let blobFile = await fetch(newThumbnail).then((r) => r.blob());
    let file = new File([blobFile], "photo", { type: "image/jpeg" });
    let formData = new FormData();
    formData.append("file", file);
    dispatch(uploadProfilePhoto.request(formData));
  };

  const handleUploadProfileImage = (imageUrl: any) => {
    handleChangeProfilePhoto(imageUrl);
  };

  const convertToPlainText = (html: string) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    if (tempDiv.textContent?.length) {
      return tempDiv.textContent;
    } else if (tempDiv.innerText) {
      return tempDiv.innerText;
    } else return "";
  };

  return (
    <>
      <Box sx={profileStyle.wrapper}>
        <Box sx={profileStyle.viewButtonStyle}>
          <Box>
            <StyledText
              fontvalue="20px"
              lineheight="30px"
              sx={profileStyle.wrapper.profileText}
            >
              {t("Profile")}
            </StyledText>
            <StyledText
              fontvalue="14px"
              lineheight="18px"
              sx={profileStyle.wrapper.idText}
            >
              {t("Update your profile")}: {t("Id")}:{goaffproID}
            </StyledText>
          </Box>
          {affiliateProfileData?.data?.ouid !== null && (
            <Button
              variant="outlined"
              sx={stylesGlobal.outlinedBtn}
              onClick={handleView}
            >
              {t("View profile")}
            </Button>
          )}
        </Box>

        <Box sx={profileStyle.wrapper.profileWrapper}>
          <Box sx={profileStyle.wrapper.profileWrapper.profileItem}>
            {profileImageURL?.length > 0 ? (
              <img
                src={profileImageURL}
                alt="photo"
                width="auto"
                height="184px"
              />
            ) : (
              <Avatar sx={profileStyle.wrapper.avatarStyle}>
                {affiliateProfileData?.data?.first_name
                  ? affiliateProfileData?.data?.first_name?.slice(0, 1)
                  : ""}
              </Avatar>
            )}
            <Box sx={profileStyle.wrapper.profileWrapper.profileButton}>
              <Button
                variant="outlined"
                onClick={() => setOpenThumbnailModal(true)}
                sx={{ ...stylesGlobal.outlinedBtn, mt: 0 }}
              >
                {t("Update your photo")}
              </Button>
              {acceptedFiles.length > 0 && (
                <DeleteIcon htmlColor="rgba(0,0,0,0.56)" />
              )}
            </Box>
          </Box>
        </Box>
        <Grid container spacing={2}>
          {profileFields.map((item, index) => (
            <>
              {index === 13 ? (
                <Grid item xs={12} lg={12} key={index}>
                  <StyledText fontvalue="16px" lineheight="21px">
                    {t(item.label)}
                    {requiredFieldsIndexes.includes(index) && (
                      <span style={{ color: "#FF0000" }}>*</span>
                    )}
                  </StyledText>
                  <Stack>
                    <ReactQuill
                      id={item.id}
                      theme="snow"
                      value={item.value}
                      onChange={handleBioChange}
                      onKeyDown={checkCharacterCount}
                      ref={reactQuillRef}
                      style={
                        {
                          height: "fit-content",
                        } as React.CSSProperties
                      }
                      modules={{
                        toolbar: [
                          [{ header: "1" }, { header: "2" }, { font: [] }],
                          [{ size: [] }],
                          [
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                          ],
                          [
                            { list: "ordered" },
                            { list: "bullet" },
                            { indent: "-1" },
                            { indent: "+1" },
                          ],
                          ["link"],
                          ["clean"],
                        ],
                      }}
                    />
                  </Stack>
                  {item.infoText && (
                    <StyledText
                      fontvalue="12px"
                      lineheight="18px"
                      color="#949494"
                    >
                      {t(item.infoText)}
                    </StyledText>
                  )}
                  {convertToPlainText(values.comments)?.length <= 0 && (
                    <StyledText
                      fontvalue="12px"
                      lineheight="24px"
                      color="#d32f2f"
                    >
                      {t("*Please enter the bio")}
                    </StyledText>
                  )}
                </Grid>
              ) : (
                <Grid item xs={12} lg={6} key={index}>
                  <StyledText fontvalue="16px" lineheight="21px">
                    {t(item.label)}
                    {requiredFieldsIndexes.includes(index) && (
                      <span style={{ color: "#FF0000" }}>*</span>
                    )}
                  </StyledText>
                  <TextField
                    fullWidth
                    size="small"
                    sx={profileStyle.wrapper.field}
                    id={item.id}
                    name={item.name}
                    onChange={(e) => {
                      handleChange(e);
                      setModified(true);
                    }}
                    value={item.value}
                    error={item.error}
                    helperText={
                      item.helperText ? t(item.helperText) : item.helperText
                    }
                    type={item.type}
                  />
                  {item.infoText && (
                    <StyledText
                      fontvalue="12px"
                      lineheight="18px"
                      color="#949494"
                    >
                      {t(item.infoText)}
                    </StyledText>
                  )}
                </Grid>
              )}
            </>
          ))}
        </Grid>
        <Box sx={centerButtonWrapper}>
          <Box onClick={() => setbioTouched(true)}>
            <Button
              variant="contained"
              sx={stylesGlobal.containedBtn}
              onClick={() => {
                setFieldValue(
                  "comments",
                  convertToPlainText(values.comments) === "undefined"
                    ? ""
                    : values.comments
                );
                handleSubmit();
              }}
              disabled={!dirty || !modified}
            >
              {t("Submit")}
            </Button>
          </Box>
        </Box>
      </Box>
      <FloatModal
        modalTitle="Upload Profile Photo"
        onClose={() => setOpenThumbnailModal(false)}
        modalBody={
          <FloatThumbnailBody
            changeThumbnailCallback={(e: any) => handleUploadProfileImage(e)}
            onClose={() => setOpenThumbnailModal(false)}
            isNameRequired={true}
          />
        }
        open={openThumbnailModal}
      />
    </>
  );
};

export default Profile;
