import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { StyledText, SubTitle1, SubTitle2 } from "../../../utils/styledComponents";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../configureStore";
import MediaTwitterIcon from "../../../utils/icons/MediaTwitterIcon";
import MediaFacebookIcon from "../../../utils/icons/MediaFacebookIcon";
import MediaLinkedInIcon from "../../../utils/icons/MediaLinkedInIcon";
import MediaPinterestIcon from "../../../utils/icons/MediaPinterestIcon";
import MediaWhatsappIcon from "../../../utils/icons/MediaWhatsappIcon";
import QRIcon from "../../../utils/icons/QRIcon";
import MediaMailIcon from "../../../utils/icons/MediaMailIcon";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { analyticsStyles, summaryStyles } from "./style";
import { summaryBalance, summaryProfile } from "../../../actions";
import { useTranslation } from "react-i18next";
import FloatModal from "../../../shared/components/FloatModal";
import QRmodalbody from "../../../shared/QRmodalbody";
import { FloatLinearProgress } from "../../../shared/components/FloatLenearProgress";
import Loading from "../../../shared/Loading";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  formatDateForPicker,
  formatInitialDate,
  formatPayloadDate,
} from "./helper";
import FloatDateSelector from "../../../shared/components/FloatDateSelector";

interface MediaItem {
  icon: JSX.Element;
  name: string;
  url: string;
}

function Summary() {
  const theme = useTheme();
  const ref = useRef<any>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDates, setSelectedDates]: any = useState(["", ""]);
  const [showDate, setShowDate] = useState("MM/DD/YYYY to MM/DD/YYYY");
  const [tooltipOpenCustomer, setTooltipOpenCustomer] = useState(false);
  const [tooltipOpenInfluencer, setTooltipOpenInfluencer] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [openQRModal, setOpenQR] = useState(false);
  const [QRImage, setQRImage] = useState("");

  const { summaryProfileData }: any = useSelector(
    (state: RootState) => state?.entities?.summaryProfile
  );

  const { summaryBalanceData }: any = useSelector(
    (state: RootState) => state?.entities?.summaryBalance
  );

  const summaryData = summaryProfileData?.data;
  const balanceSummary = summaryBalanceData?.data?.data;

  useEffect(() => {
    const currentDate = new Date();
    const now = new Date(); 
    const currentYear = now.getFullYear(); 
const yearStartDate = new Date(currentYear, 0, 1);
    const startDate = formatPayloadDate(yearStartDate);
    const endDate = formatPayloadDate(currentDate);
    dispatch(
      summaryBalance.request({
        startDate: `${startDate.split("+")[2]}-${startDate.split("+")[0]}-${
          startDate.split("+")[1]
        }`,
        endDate: `${endDate.split("+")[2]}-${endDate.split("+")[0]}-${
          endDate.split("+")[1]
        }`,
      })
    );
    setShowDate(
      `${formatInitialDate(yearStartDate)} to ${formatInitialDate(currentDate)}`
    );
  }, []);

  const handleCopyLink = async (link: string, type: string) => {
    navigator.clipboard.writeText(link);
    try {
      await navigator.clipboard.writeText(link);
      if (type === "customer") {
        setTooltipOpenCustomer(true);
        setTimeout(() => {
          setTooltipOpenCustomer(false);
        }, 2000);
      } else if (type === "influencer") {
        setTooltipOpenInfluencer(true);
        setTimeout(() => {
          setTooltipOpenInfluencer(false);
        }, 2000);
      }
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  useEffect(() => {
    dispatch(summaryProfile.request({}));
  }, []);

  const onSelectDate = () => {
    setOpenDatePicker(!openDatePicker);
  };

  const handleDateFilter = () => {
    setShowDate(
      `${selectedDates[0]?.format("MM/DD/YYYY")} to ${selectedDates[1]?.format(
        "MM/DD/YYYY"
      )}`
    );
    const startTime = formatDateForPicker(
      selectedDates[0]?.format("YYYY/MM/DD")
    );
    const endTime = formatDateForPicker(selectedDates[1]?.format("YYYY/MM/DD"));
    dispatch(
      summaryBalance.request({
        startDate: selectedDates[0]?.format("YYYY/MM/DD"),
        endDate: selectedDates[1]?.format("YYYY/MM/DD"),
      })
    );
    setOpenDatePicker(false);
  };

  const balanceInfo = useMemo(
    () => [
      {
        key: "Referrals",
        value: balanceSummary?.referrals ?? "0",
        isLoading: summaryBalanceData?.isLoading,
        prefixSymbol: "",
        postfixSymbol: "",
      },
      {
        key: "Orders",
        value: balanceSummary?.orders ?? "0",
        isLoading: summaryBalanceData?.isLoading,
        prefixSymbol: "",
        postfixSymbol: "",
      },
      {
        key: "Conversions",
        value: balanceSummary?.conversions
          ? parseFloat(balanceSummary?.conversions)?.toFixed(2)
          : "0",
        isLoading: summaryBalanceData?.isLoading,
        prefixSymbol: "",
        postfixSymbol: "%",
      },
      {
        key: "Sales",
        value: balanceSummary?.sales
          ? parseFloat(balanceSummary?.sales)?.toFixed(2)
          : "0",
        isLoading: summaryBalanceData?.isLoading,
        prefixSymbol: "$",
        postfixSymbol: "",
      },
      {
        key: "Earnings",
        value: balanceSummary?.earning
          ? parseFloat(balanceSummary?.earning)?.toFixed(2)
          : "0",
        isLoading: summaryBalanceData?.isLoading,
        prefixSymbol: "$",
        postfixSymbol: "",
      },
    ],
    [summaryBalanceData, summaryBalanceData?.data?.data]
  );

  const media: MediaItem[] = [
    {
      icon: <MediaFacebookIcon />,
      name: "Facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        shareUrl
      )}`,
    },
    {
      icon: <MediaTwitterIcon />,
      name: "Twitter",
      url: `https://twitter.com/intent/tweet?text=${"video"} - ${""}&url=${shareUrl}`,
    },
    {
      icon: <MediaLinkedInIcon />,
      name: "Linked In",
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${"video"}&summary=${""}&source=`,
    },
    {
      icon: <MediaPinterestIcon />,
      name: "Pinterest",
      url: `https://pinterest.com/pin/create/button/?url=${shareUrl}&media=&description=${"video"}`,
    },
    {
      icon: <MediaWhatsappIcon />,
      name: "Whatsapp",
      url: `https://wa.me/?text=${shareUrl}`,
    },
    {
      icon: <QRIcon />,
      name: "QR",
      url: `https://wa.me/?text=${shareUrl}`,
    },
    {
      icon: <MediaMailIcon />,
      name: "Mail",
      url:
        "mailto:?&subject=Check out this Pyvit video!&body=%20%0d%0a" +
        shareUrl,
    },
  ];

  const totalVisits =
    balanceSummary?.traffic?.traffic?.reduce(
      (sum: any, data: any) => sum + data.visits,
      0
    ) || 0;

  return (
    <Box>
      <Box sx={summaryStyles.headerBox}>
        <Box sx={summaryStyles.headerBox.headerItem}>
          <StyledText fontvalue="18px" lineheight="24px">
            {t("Customer referral link (up to")}{" "}
            {summaryData?.customer_commission ?? ""}
            {t("%)")}
          </StyledText>
          <StyledText>
            {t(
              "Using the link below, refer your followers to PYVIT and earn up to"
            )}{" "}
            {summaryData?.customer_commission ?? ""}
            {t(
              "% commissions on their purchases (you can also generate product links to earn up to"
            )}{" "}
            {summaryData?.customer_commission ?? ""}
            {t("% commissions in the Marketing Tools tab above)")}
          </StyledText>
          <Box sx={summaryStyles.topCards.copyLink}>
            <Link sx={summaryStyles.linkStyle}>
              {summaryData?.customer_referral_link ?? (
                <CircularProgress size="14px" />
              )}
            </Link>
            <Tooltip
              open={tooltipOpenCustomer}
              onClose={() => setTooltipOpenCustomer(false)}
              title={t("Link copied!")}
            >
              <IconButton
                onClick={() => {
                  handleCopyLink(
                    summaryData?.customer_referral_link,
                    "customer"
                  );
                }}
              >
                <ContentCopyOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={summaryStyles.topCards.mediaBox}>
            <Box
              sx={{
                display: "flex",
                columnGap: isSmallScreen ? "2px" : "10px",
              }}
            >
              {media.map((item: MediaItem) => {
                return (
                  <IconButton
                    onClick={() => {
                      if (item.name !== "QR") {
                        setShareUrl(summaryData?.customer_referral_link ?? "");
                        setTimeout(() => {
                          window.open(item.url);
                        }, 300);
                      } else if (summaryData?.customerQR?.length > 0) {
                        setQRImage(summaryData?.customerQR ?? "");
                        setOpenQR(true);
                      }
                    }}
                    sx={
                      isSmallScreen
                        ? summaryStyles.topCards.mediaIconsMobile
                        : summaryStyles.topCards.mediaIcons
                    }
                  >
                    {item.icon}
                  </IconButton>
                );
              })}
            </Box>
          </Box>
        </Box>

        <Box sx={summaryStyles.headerBox.headerItem}>
          <StyledText fontvalue="18px" lineheight="24px">
            {t("Influencer referral link (up to")}
            {" 20% + "}
            {t("up to")}
            {" 5%)"}
          </StyledText>
          <StyledText>
            {t("Invite influencers to promote on PYVIT (earn up to")} 20%{" "}
            {t(
              "on what they buy) and grow your influencer network (earn up to"
            )}{" "}
            5% {t("on what they sell)")}
          </StyledText>
          <Box>
            <StyledText fontvalue="16px" lineheight="20px">
              {" "}
              {t("Signup link")}
            </StyledText>
            <Box sx={summaryStyles.topCards.copyLink}>
              <Link sx={summaryStyles.linkStyle}>
                {summaryData?.influencer_referral_link ?? (
                  <CircularProgress size="14px" />
                )}
              </Link>
              <Tooltip
                open={tooltipOpenInfluencer}
                onClose={() => setTooltipOpenInfluencer(false)}
                title={t("Link copied!")}
              >
                <IconButton
                  onClick={() =>
                    handleCopyLink(
                      summaryData?.influencer_referral_link,
                      "influencer"
                    )
                  }
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={summaryStyles.topCards.mediaBox}>
            <Box
              sx={{
                display: "flex",
                columnGap: isSmallScreen ? "2px" : "10px",
              }}
            >
              {media.map((item: MediaItem) => {
                return (
                  <IconButton
                    onClick={() => {
                      if (item.name !== "QR") {
                        setShareUrl(
                          summaryData?.influencer_referral_link ?? ""
                        );
                        setTimeout(() => {
                          window.open(item.url);
                        }, 300);
                      } else if (summaryData?.influencerQR?.length > 0) {
                        setQRImage(summaryData?.influencerQR ?? "");
                        setOpenQR(true);
                      }
                    }}
                    sx={
                      isSmallScreen
                        ? summaryStyles.topCards.mediaIconsMobile
                        : summaryStyles.topCards.mediaIcons
                    }
                  >
                    {item.icon}
                  </IconButton>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Stack sx={summaryStyles.dateFilterBox}>
          <Button
            ref={ref}
            onClick={() => onSelectDate()}
            sx={summaryStyles.showDateButton}
          >
            <StyledText sx={{ textTransform: "none" }}>{showDate} </StyledText>
            &nbsp;
            <ArrowDropDownIcon />
          </Button>
        </Stack>
        <FloatDateSelector
          open={openDatePicker}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          handleDateFilter={handleDateFilter}
        />
      </Box>
      <Box sx={analyticsStyles.boxBG}>
        <Grid container spacing={2}>
          {balanceInfo &&
            balanceInfo.map((item) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"86px"}
                    sx={analyticsStyles.whiteBG}
                  >
                    <SubTitle1>
                      {item.isLoading ? (
                        <CircularProgress size="12px" />
                      ) : (
                        item.prefixSymbol +
                        item.value?.toString() +
                        item.postfixSymbol
                      )}
                    </SubTitle1>
                    <SubTitle2>{t(item.key)}</SubTitle2>
                  </Stack>
                </Grid>
              );
            })}
        </Grid>
      </Box>
      <Grid sx={summaryStyles.balacesOuterGrid}>
        <Box sx={summaryStyles.balacesOuterGrid.progressCard}>
          <Box>
            <Typography sx={summaryStyles.balacesOuterGrid.balance}>
              {summaryBalanceData?.isLoading ? (
                <CircularProgress size={"14px"} />
              ) : (
                balanceSummary?.traffic?.total_visits ?? "0"
              )}
            </Typography>
            <StyledText>{t("Traffic")}</StyledText>
          </Box>
          {summaryBalanceData.isLoading ? (
            <Box sx={summaryStyles.loading}>
              <Loading />
            </Box>
          ) : (
            <Box sx={summaryStyles.balacesOuterGrid.trafficCard}>
              {balanceSummary?.traffic &&
                balanceSummary?.traffic?.traffic.map((data: any) => {
                  const percentage: any = Math.round(
                    (data.visits / totalVisits) * 100
                  );
                  return (
                    <Box textAlign={"left"} mb={"10px"}>
                      <Box sx={summaryStyles.flexStyle}>
                        <StyledText>{data.hostname}</StyledText>
                        <StyledText> {percentage}% </StyledText>
                      </Box>
                      <FloatLinearProgress
                        variant="determinate"
                        value={percentage}
                      />
                    </Box>
                  );
                })}
            </Box>
          )}
        </Box>
        <Grid sx={summaryStyles.balacesOuterGrid.productBalanceCard}>
          <Typography sx={summaryStyles.balacesOuterGrid.balance}>-</Typography>
          <StyledText>{t("Product sold")}</StyledText>
        </Grid>
      </Grid>
      <FloatModal
        open={openQRModal}
        onClose={() => {
          setOpenQR(false);
          setQRImage("");
        }}
        modalTitle={t("QR Code")}
        modalBody={<QRmodalbody url={QRImage} />}
      />
    </Box>
  );
}

export default Summary;
