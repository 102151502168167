import React, { useCallback } from "react";
import { StepProp } from "../../../../models/backoffice.models";
import {
  StyledStep,
  StyledStepLabel,
  StyledStepper,
  StyledText,
} from "../../../../utils/styledComponents";
import { orderSteps } from "../helper";
import { styles } from "../style";
import { useMediaQuery } from "@mui/material";

const Delivered = ({ active }: StepProp) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const stepStatus = useCallback(() => {
    return (
      <StyledStepper
        activeStep={active}
        alternativeLabel={isMobile ? false : true}
        orientation={isMobile ? "vertical" : "horizontal"}
      >
        {orderSteps?.slice(0, orderSteps?.length - 1).map((label, index) => {
          return (
            <StyledStep key={index} expanded={true}>
              <StyledStepLabel
                StepIconComponent={() => (
                  <img
                    src={
                      index + 1 > active ? label.iconInactive : label.iconActive
                    }
                    alt={(index + 1)?.toString() ?? ""}
                    style={styles.stepperIcon}
                  />
                )}
              >
                <StyledText lineheight="25px">{label.status}</StyledText>
              </StyledStepLabel>
            </StyledStep>
          );
        })}
      </StyledStepper>
    );
  }, [active, isMobile]);

  return stepStatus();
};

export default Delivered;
