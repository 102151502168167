import { Box, Button, Checkbox, CircularProgress, Stack } from "@mui/material";
import React, { useEffect, useState, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../configureStore";
import {
  assignItemCall,
  productsByVideoIdCall,
  showUserProducts,
} from "../../../actions";
import { GrabProdutsResponseObjType } from "../../../models/backoffice.models";
import { Info, StyledText } from "../../../utils/styledComponents";
import { stylesGlobal } from "../../../utils/muiStyles";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { THIN_BORDER_COLOR } from "../../../colors";
import { LinkProductStyles, styles } from "./style";
import { linkedProductStyles } from "../Analytics.tsx/linkedProducts/style";

function ProductsLinkList({ onClose }: any) {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();

  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);

  const { grabProducts }: any = useSelector(
    (state: RootState) => state?.entities?.grabProducts
  );

  const { produtsByvideoIdData }: any = useSelector(
    (state: RootState) => state?.entities?.productByVideo
  );

  const { assignItemData }: any = useSelector(
    (state: RootState) => state?.entities?.assignItem
  );

  const [products, setProducts] = useState<GrabProdutsResponseObjType[]>([]);

  useEffect(() => {
    dispatch(showUserProducts.request({}));
    dispatch(
      productsByVideoIdCall.request({ data: { content_id: params?.id } })
    );
  }, []);

  useEffect(() => {
    if (!grabProducts?.isLoading && !produtsByvideoIdData?.isLoading) {
      if (
        Array.isArray(grabProducts?.data) &&
        Array.isArray(produtsByvideoIdData?.data)
      ) {
        const linkedItems =
          produtsByvideoIdData?.data?.length > 0
            ? produtsByvideoIdData?.data?.map(
                (item: GrabProdutsResponseObjType) => item.item_id
              )
            : [];
        const list = grabProducts?.data.map(
          (item: GrabProdutsResponseObjType) => {
            return { ...item, status: linkedItems?.includes(item.item_id) };
          }
        );

        const selected = list?.filter((item: any) => item.status);
        const rest = list?.filter((item: any) => !item.status);
        const finallist = selected.concat(rest);
        setProducts(finallist);
      }
    }
  }, [grabProducts?.isLoading, produtsByvideoIdData?.isLoading]);

  useEffect(() => {
    if (!assignItemData?.isLoading && assignItemData?.data?.success) {
      dispatch(assignItemCall.reset());
      onClose();
    }
  }, [assignItemData?.isLoading]);

  const onStatusChange = (
    event: ChangeEvent<HTMLInputElement>,
    item: GrabProdutsResponseObjType
  ) => {
    const changeList: GrabProdutsResponseObjType[] = [];

    products?.map((product) => {
      if (item.item_id == product.item_id) {
        changeList?.push({ ...product, status: !product.status });
      } else {
        changeList?.push(product);
      }
    });
    setProducts(changeList);
    const isChecked = changeList.some((product) => product.status);
    setIsAnyCheckboxChecked(isChecked);
  };

  const onLink = () => {
    const productChecked = products?.filter((p: any) => p.status);
    const productIds = productChecked?.map((item) => item.item_id);

    dispatch(
      assignItemCall.request({
        data: {
          item_ids: productIds,
          content_id: params.id,
        },
      })
    );
    setTimeout(() => {
      dispatch(
        productsByVideoIdCall.request({ data: { content_id: params?.id } })
      );
    }, 3000);
  };

  return (
    <Box
      sx={LinkProductStyles.wrapper}
    >
      {grabProducts && grabProducts?.isLoading ? (
        <Box
          sx={{display: "flex", justifyContent:"center", alignItems: "center"}}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box className="overflow-y-contain" sx={LinkProductStyles.boxHeight}>
          {products?.length > 0 ? (
            <>
              {products?.map((item: GrabProdutsResponseObjType) => {
                return (
                  <Stack
                    sx={LinkProductStyles.stackStyle}
                  >
                    <Stack>
                      <img
                        src={item.thumbnail_url}
                        height={"60px"}
                        width={"60px"}
                      />
                    </Stack>

                    <Stack
                      sx={LinkProductStyles.productStack}
                    >
                      <Info>{item.title}</Info>
                    </Stack>
                    <Stack>
                      <Checkbox
                        checked={item.status}
                        color="default"
                        onChange={(e) => onStatusChange(e, item)}
                      />
                    </Stack>
                  </Stack>
                );
              })}
            </>
          ) : (
            <StyledText textAlign={"center"}>
              {t("No records found.")}
            </StyledText>
          )}
        </Box>
      )}

      <Stack
        sx={LinkProductStyles.itemStack}
      >
        <Stack
          sx={LinkProductStyles.styledStack}
        >
          <Button
            variant="outlined"
            sx={stylesGlobal.outlinedBtn}
            onClick={() => onClose()}
          >
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            sx={stylesGlobal.containedBtn}
            onClick={onLink}
            disabled={!isAnyCheckboxChecked || assignItemData?.isLoading}
          >
            {assignItemData?.isLoading && (
              <CircularProgress
                size={"14px"}
                sx={LinkProductStyles.btnLoader}
              />
            )}{" "}
            {t("Save")}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ProductsLinkList;
