import React from "react";
import Profile from "../Pages/Account/Profile/Profile";
import ChangePassword from "../Pages/Account/ChangePassword/ChangePassword";
import AccountAddress from "../Pages/Account/Adresses/Address";
import Products from "../Pages/videoEditor/products/Products";
import Dashboard from "../Pages/videoEditor/dashboard/Dashboard";
import TrackinhgReturns from "../Pages/Orders/TrackingReturns/TrackingReturns";
import Subscriptions from "../Pages/Orders/Subscriptions/Subscriptions";
import Summary from "../Pages/Influencer/Summary/Summary";
import MarketingTools from "../Pages/Influencer/MarketingTools/MarketingTools";
import Payment from "../Pages/Influencer/Payment/Payment";
import ProgramInfo from "../Pages/Influencer/ProgramInfo/ProgramInfo";
import InfluencerSettings from "../Pages/Influencer/InfluencerSettings/Settings";
import EwalletSignUp from "../Pages/eWallet/signUp/SignUp";
import Network from "../Pages/Influencer/Network/Network";
import InfluencerTransactions from "../Pages/Influencer/Transactions/InfluencerTransactions";
import CreatorSummary from "../Pages/Creator/Summary/Summary";
import CreatorPayment from "../Pages/Creator/Payment/Payment";
import CreatorProgramInfo from "../Pages/Creator/ProgramInfo/ProgramInfo";
import EwalletSignIn from "../Pages/eWallet/signUp/EwalletSignIn";
import CreatorSettings from "../Pages/Creator/CreatorSettings/Settings";
import CreatorTransactions from "../Pages/Creator/Transactions/CreatorTransactions";
import EditorVideos from "../Pages/videoEditor/videos";
import CreatorLibrary from "../Pages/videoEditor/creatorLibrary";
import CreatorAnalytics from "../Pages/Creator/Analytics/Analytics";

export const RouteList = [
  {
    route: "account",
    name: "Account",
    role: ["creator", "influencer", "customer"],
    subRoute: [
      {
        name: "Profile",
        displayName: true,
        route: "/profile",
        element: <Profile />,
        role: ["creator", "influencer", "customer"],
      },
      {
        name: "Change password",
        route: "/change-password",
        displayName: true,
        element: <ChangePassword />,
        role: ["creator", "influencer", "customer"],
      },
      {
        name: "Addresses",
        displayName: false,
        route: "/Addresses",
        element: <AccountAddress />,
        role: ["creator", "influencer", "customer"],
      },
    ],
  },
  {
    route: "orders",
    name: "Orders",
    role: ["creator", "influencer", "customer"],
    subRoute: [
      {
        name: "Order history",
        route: "/order-history",
        displayName: true,
        element: <TrackinhgReturns />,
        role: ["creator", "influencer", "customer"],
      },

      {
        name: "Subscriptions",
        displayName: true,
        route: "/order-subscriptions",
        element: <Subscriptions />,
        role: ["creator", "influencer", "customer"],
      },
    ],
  },
  {
    route: "influencer",
    name: "Influencer",
    role: ["influencer"],
    subRoute: [
      {
        name: "Summary",
        displayName: true,
        route: "/summary",
        element: <Summary />,
        role: ["influencer"],
      },
      {
        name: "Marketing tools",
        displayName: true,
        route: "/marketing-tools",
        element: <MarketingTools />,
        role: ["influencer"],
      },
      {
        name: "Analytics",
        displayName: true,
        route: "/analytics",
        element: <CreatorAnalytics />,
        role: ["influencer"],
      },
      {
        name: "Network",
        displayName: true,
        route: "/network",
        element: <Network />,
        role: ["influencer"],
      },
      {
        name: "Transactions",
        displayName: true,
        route: "/transactions",
        element: <InfluencerTransactions />,
        role: ["influencer"],
      },
      {
        name: "Payments",
        displayName: true,
        route: "/payment",
        element: <Payment />,
        role: ["influencer"],
      },
      {
        name: "Program info",
        displayName: true,
        route: "/program-info",
        element: <ProgramInfo />,
        role: ["influencer"],
      },
      {
        name: "Settings",
        displayName: true,
        route: "/settings",
        element: <InfluencerSettings />,
        role: ["influencer"],
      },
    ],
  },
  {
    route: "creator",
    name: "Creator",
    role: ["creator"],
    subRoute: [
      {
        name: "Summary",
        displayName: true,
        route: "/summary",
        element: <CreatorSummary />,
        role: ["creator"],
      },
      {
        name: "Marketing tools",
        displayName: true,
        route: "/marketing-tools",
        element: <></>,
        role: ["creator"],
      },
      {
        name: "Analytics",
        displayName: true,
        route: "/analytics",
        element: <CreatorAnalytics />,
        role: ["creator"],
      },
      {
        name: "Transactions",
        displayName: true,
        route: "/transactions",
        element: <CreatorTransactions />,
        role: ["creator"],
      },
      {
        name: "Payments",
        displayName: true,
        route: "/payment",
        element: <CreatorPayment />,
        role: ["creator"],
      },
      {
        name: "Program info",
        displayName: false,
        route: "/program-info",
        element: <CreatorProgramInfo />,
        role: ["creator"],
      },
      {
        name: "Settings",
        displayName: true,
        route: "/settings",
        element: <CreatorSettings />,
        role: ["creator"],
      },
    ],
  },
  {
    route: "eWallet",
    name: "eWallet",
    role: ["creator", "influencer"],
    tags: [" commission earned", " ewallet"],
    subRoute: [
      {
        name: "Sign Up",
        displayName: false,
        route: "---",
        element: <EwalletSignUp />,
        role: ["creator", "influencer"],
        isModal: true,
        tags: " commission earned",
      },
      {
        name: "Sign In",
        displayName: false,
        route: "---",
        isModal: true,
        element: <EwalletSignIn />,
        role: ["creator", "influencer"],
        tags: " ewallet",
      },
    ],
  },
  {
    route: "video-editor",
    name: "Video editor",
    uniqueRoute: "",
    role: ["creator", "influencer", "customer"],
    subRoute: [
      {
        name: "Get a plan",
        displayName: false,
        route: "/get-plan",
        element: <> </>,
        role: ["creator", "influencer", "customer"],
      },
      {
        name: "Summary",
        displayName: true,
        route: "/summary",
        element: <Dashboard />,
        role: ["creator", "influencer", "customer"],
      },
      {
        name: "Videos",
        displayName: true,
        route: "/videos",
        element: <EditorVideos />,
        role: ["creator", "influencer", "customer"],
      },
      {
        name: "Products",
        displayName: true,
        route: "/products",
        element: <Products />,
        role: ["creator", "influencer", "customer"],
      },
      {
        name: "Creator library",
        displayName: true,
        route: "/creator-library",
        element: <CreatorLibrary />,
        role: ["creator", "influencer", "customer"],
      },
    ],
  },
];
