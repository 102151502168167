/* eslint-disable */
import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { SubTitle1 } from "../../utils/styledComponents";
import VideoHeaderCard from "./VideoHeaderCard";
import BasicTabs from "./VideoTabs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVideoCall,
  getVideoDetailsCall,
  grabVideoAnalytics,
  productsByVideoIdCall,
} from "../../actions";
import { RootState } from "../../configureStore";
import Loading from "../../shared/Loading";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { t } from "i18next";
import { videoDetailsStyle } from "./style";

function VideoDetailsLayout() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { videoDetails } = useSelector(
    (state: RootState) => state?.entities?.videoDetail
  );
  const videoInfo = videoDetails?.data?.data?.response;

  useEffect(() => {
    dispatch(getVideoDetailsCall.request({ data: { content_id: params.id } }));
    return () => {
      dispatch(grabVideoAnalytics.reset());
      dispatch(productsByVideoIdCall.reset());
    };
  }, []);

  const handleDeleteVideo = () => {
    Swal.fire({
      title: "Are you sure you want to delete this video?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: t("Yes!"),
      confirmButtonColor: "#333",
      iconColor: "#333",
      cancelButtonText: t("Cancel"),
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        dispatch(
          deleteVideoCall.request({
            data: { content_id: videoInfo?.content_id },
          })
        );
        await navigate("/video-editor/videos");
      },
    });
  };

  return !videoDetails?.isLoading ? (
    <Box>
      <ArrowBackIcon
        fontSize="large"
        sx={videoDetailsStyle.icon}
        onClick={() => {
          navigate("/video-editor/videos");
        }}
      />

      <Stack height={"fit-content"} sx={videoDetailsStyle.stackMargin}>
        <VideoHeaderCard />
      </Stack>
      <Stack sx={videoDetailsStyle.childStack}>
        <BasicTabs />
      </Stack>
    </Box>
  ) : (
    <Box sx={videoDetailsStyle.boxHeight}>
      <Loading />
    </Box>
  );
};

export default VideoDetailsLayout;
