import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { CircularProgress, Stack } from "@mui/material";
import {
  Info,
  StyledTableCell,
  StyledTableRow,
} from "../../../utils/styledComponents";
import FloatDotIndecator from "../../../shared/components/FloatDotIndecator";
import { videoListStyles } from "./styles";

function VideosAnalyticsActionRow({ row, actions, mobile, accordian }: any) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { analyticsRow } = videoListStyles;
  const { t } = useTranslation();
  const [isNavRefLeft, setNavLeft] = useState(true);
  const [scrollableNav, setScrollable] = useState<boolean>(true);

  const handleScroll = () => {
    if (ref.current) {
      const scrollLeft = ref.current.scrollLeft;
      const scrollWidth = ref.current.scrollWidth;
      const containerWidth = ref.current.clientWidth;
      const isAtLeft = scrollLeft === 0;

      setNavLeft(isAtLeft);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("scroll", handleScroll);
      const { scrollWidth, clientWidth } = ref.current;
      const isScrollable = scrollWidth > clientWidth;
      setScrollable(isScrollable);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("scroll", handleScroll);
      }
    };
  });

  const statValue = (value:string | number | undefined) =>{
      if(value === "..."){
        return <CircularProgress size={"12px"} />
      }else{
        return value
      }
  }

  return (
    <StyledTableRow sx={analyticsRow.rowStyle}>
      {!mobile ? (
        <StyledTableCell colSpan={6} sx={analyticsRow.tableCell}>
          <Stack sx={analyticsRow.containerDesktop}>
            <Box sx={{ ...analyticsRow.wrapBox, flexGrow: 1 }}>
              <Stack sx={analyticsRow.infoBox}>
                <Info sx={analyticsRow.infoMobile}>
                  {t("Total views")}:<b> {statValue(row?.views)} </b>
                </Info>
              </Stack>
              <Stack sx={analyticsRow.infoBox}>
                <Info sx={analyticsRow.infoMobile}>
                  {t("Unique views")}:<b> {statValue(row?.unique)}</b>
                </Info>
              </Stack>
              <Stack sx={analyticsRow.infoBox}>
                <Info sx={analyticsRow.infoMobile}>
                  {t("Product clicks")}:<b> {statValue(row?.clicks)}</b>
                </Info>
              </Stack>
            </Box>

            <Box sx={videoListStyles.flexBox}>{actions(row)}</Box>
          </Stack>
        </StyledTableCell>
      ) : (
        <StyledTableCell colSpan={6} sx={analyticsRow.tableCell}>
          <Box
            ref={ref}
            className={"overflow-x-stats-mobilenavsroll"}
            sx={analyticsRow.mobileScrollOuter}
          >
            <Box
              sx={
                accordian?.open === "false" && accordian.id === row.id
                  ? analyticsRow.wrapBox
                  : analyticsRow.flexBox
              }
            >
              <Stack sx={analyticsRow.infoBox}>
                <Info sx={analyticsRow.infoMobile}>
                  {t("Total views")}:<b> {statValue(row?.views)} </b>
                </Info>
              </Stack>
              <Stack sx={analyticsRow.infoBox}>
                <Info sx={analyticsRow.infoMobile}>
                  {t("Unique views")}:<b> {statValue(row?.unique)}</b>
                </Info>
              </Stack>
              <Stack sx={analyticsRow.infoBox}>
                <Info sx={analyticsRow.infoMobile}>
                  {t("Product clicks")}:<b> {statValue(row?.clicks)}</b>
                </Info>
              </Stack>
            </Box>
          </Box>
          {scrollableNav && (
            <Box sx={analyticsRow.scrollIndicatorBox}>
              <FloatDotIndecator checked={isNavRefLeft} />
              <FloatDotIndecator checked={!isNavRefLeft} />
            </Box>
          )}
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
};

export default VideosAnalyticsActionRow;
