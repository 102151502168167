import { VisitsPropType } from "../../../models/backoffice.models";
import { isArrayIncludesKey } from "../../../utils/hooks";

export function formatDateForPicker(dateStr: string) {
  let dateObj = new Date(dateStr.replace(/-/g, "/"));

  let day = ("0" + dateObj.getDate()).slice(-2);
  let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  let year = dateObj.getFullYear();
  let hours = ("0" + dateObj.getHours()).slice(-2);
  let minutes = ("0" + dateObj.getMinutes()).slice(-2);
  let seconds = ("0" + dateObj.getSeconds()).slice(-2);

  let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let dayOfWeek = days[dateObj.getDay()];

  let formattedDate =
    dayOfWeek +
    "%2B" +
    month +
    "%2B" +
    day +
    "%2B" +
    year +
    "%2B" +
    hours +
    "%3A" +
    minutes +
    "%3A" +
    seconds +
    "%2BGMT%2B0530%2B%28India%2BStandard%2BTime%29";

  return formattedDate;
}

export const getConvertions = (response: any) => {
  if (response?.visits?.length) {
    const calc = (response.sales.num_orders / response?.visits?.length) * 100;
    return Math.round(calc);
  } else {
    return "0";
  }
};

export const getSafariProgress = (data: VisitsPropType[]) => {
  const safari = data.filter(
    (item) =>
    isArrayIncludesKey(item?.user_agent,"Mac OS") &&
    isArrayIncludesKey(item?.user_agent,"Safari") &&
    !isArrayIncludesKey(item?.user_agent,"Mobile") &&
    !isArrayIncludesKey(item?.user_agent,"Chrome")
  )?.length;
  return data?.length > 0 ? Math.round((safari / data?.length) * 100) : 0;
};

export const getSafariMobileProgress = (data: VisitsPropType[]) => {
  const total = data?.filter((item) => item.user_agent)?.length;
  const safariMob = data.filter(
    (item) =>
    isArrayIncludesKey(item?.user_agent,"Mobile") &&
    isArrayIncludesKey(item?.user_agent,"Safari") &&
    isArrayIncludesKey(item?.user_agent,"Mac OS")
  )?.length;
  return data?.length > 0 ? Math.round((safariMob / total) * 100) : 0;
};

export const getFbProgress = (data: VisitsPropType[]) => {
  const total = data?.filter((item) => item.user_agent)?.length;
  const Fb = data.filter((item) => isArrayIncludesKey(item?.user_agent,"FB")).length;
  return data?.length > 0 ? Math.round((Fb / total) * 100) : 0;
};

export const getChromeProgress = (data: VisitsPropType[]) => {
  const total = data?.filter((item) => item.user_agent)?.length;
  const chrome = data.filter((item) =>
  isArrayIncludesKey(item?.user_agent,"Chrome") 
  ).length;

  const safariProgress = getSafariProgress(data);
  const edgProgress = getEdgeProgress(data);
  const fbProgress = getFbProgress(data);
  const safariMobProgress = getSafariMobileProgress(data);
  
  return 100 - (edgProgress + safariProgress + fbProgress + safariMobProgress);
};

export const getEdgeProgress = (data: VisitsPropType[]) => {
  const total = data?.filter((item) => item.user_agent)?.length;
  const edge = (data?.filter((item) => isArrayIncludesKey(item?.user_agent,"Edg"))).length;
  return data?.length > 0 ? Math.round((edge / total) * 100) : 0;
};

export const getMacOsProgress = (data: VisitsPropType[]) => {
  const total = data?.length;
  const MacOs = data.filter(
    (item) =>
    isArrayIncludesKey(item.user_agent,"Mac OS") &&
      !isArrayIncludesKey(item.user_agent,"phone") &&
      !isArrayIncludesKey(item.user_agent,"Mobile")
  )?.length;
  return data?.length > 0 ? Math.round((MacOs / total) * 100) : 0;
};

export const getWindowsProgress = (data: VisitsPropType[]) => {
  const total = data?.length;
  const Windows = data.filter((item) =>
  isArrayIncludesKey(item.user_agent,"Windows")
  )?.length;
  return data?.length > 0 ? Math.round((Windows / total) * 100) : 0;
};

export const getIOSProgress = (data: VisitsPropType[]) => {
  const total = data?.length;
  const iPhone = data.filter((item) =>
  isArrayIncludesKey(item.user_agent,"iPhone")
  )?.length;
  return data?.length > 0 ? Math.round((iPhone / total) * 100) : 0;
};

export const getAndroidProgress = (data: VisitsPropType[]) => {
  const total = data?.length;
  const android = data.filter((item) =>
  isArrayIncludesKey(item.user_agent,"Android")
  )?.length;
  return data?.length > 0 ? Math.round((android / total) * 100) : 0;
};

export const getLinuxProgress = (data: VisitsPropType[]) => {
  const total = data?.length;
  const linux = data.filter(
    (item) =>
      item?.user_agent?.includes("Linux") &&
      !item?.user_agent?.includes("Mobile") &&
      !item?.user_agent?.includes("Phone")
  ).length;

  return data?.length > 0 ? Math.round((linux / total) * 100) : 0;
};

export const mobileIpadProgress = (data: VisitsPropType[]) => {
  const total = data?.length;
  const mob = data.filter(
    (item) =>
    isArrayIncludesKey(item.user_agent,"Mobile") ||
    isArrayIncludesKey(item.user_agent,"Phone") ||
    isArrayIncludesKey(item.user_agent,"phone")
  )?.length;
  return data?.length > 0 ? Math.round((mob / total) * 100) : 0;
};

export const desktopProgress = (data: VisitsPropType[]) => {
  const total = data?.length;
  const mob = data.filter(
    (item) =>
    isArrayIncludesKey(item.user_agent,"Mobile") ||
    isArrayIncludesKey(item.user_agent,"iPhone") ||
    isArrayIncludesKey(item.user_agent,"phone")
  )?.length;
  return data?.length > 0 ? 100 - Math.round((mob / total) * 100) : 0;
};