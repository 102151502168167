import React, { useEffect } from "react";
import { CardDiv, Row100, Row50, RowBox, styles } from "./style";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../configureStore";
import { StatCard } from "../../../../models/backoffice.models";
import {  StyledText } from "../../../../utils/styledComponents";
import { getSubscriptionALerts } from "../../../../actions";
import { IAPIData } from "../../../../models/types.model";
import { FloatLinearProgress } from "../../../../shared/components/FloatLenearProgress";
import { useTranslation } from "react-i18next";
import { iskeyInObject } from "../../../../utils/hooks";

function PlanProgress() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { grabProducts }: any = useSelector(
    (state: RootState) => state?.entities?.grabProducts
  );

  const { totalViewsData }: any = useSelector(
    (state: RootState) => state?.entities?.totalViewsData
  );

  const { totalGlobalStatsData }: any = useSelector(
    (state: RootState) => state?.entities?.totalGlobalStatsData
  );

  const { planData }: any = useSelector(
    (state: RootState) => state?.entities?.planData
  );

  const subscriptionAlertData = useSelector(
    (state: RootState) =>
      state?.entities?.subscriptionAlert?.subscriptionAlert as IAPIData
  );

  useEffect(() => {
    dispatch(getSubscriptionALerts.request());
  }, []);

  const formattedStats = (min: number, sec: number) => {
    if (min && sec) return `${min}m ${sec}s`;
    else return "";
  };

  const cards: StatCard[] = [
    {
      label: "Total videos",
      icon: "",
      value: totalGlobalStatsData?.data?.total_videos,
      actionRef: "/videos",
      isLoading: totalGlobalStatsData?.isLoading,
      color: "#333",
      errorExist: totalGlobalStatsData?.isError,
    },
    {
      label: "Total video views",
      icon: "",
      value: totalViewsData?.data?.total_views,
      actionRef: "",
      isLoading: totalViewsData?.isLoading,
      color: "#333",
      errorExist: totalViewsData?.isError,
    },
    {
      label: "Total unique views",
      icon: "",
      value: totalViewsData?.data?.total_unique_views,
      actionRef: "",
      isLoading: totalViewsData?.isLoading,
      color: "#333",
      errorExist: totalViewsData?.isError,
    },
    {
      label: "Total products",
      icon: "",
      value: grabProducts?.data?.length,
      actionRef: "",
      isLoading: grabProducts?.isLoading,
      color: "#333",
      errorExist: grabProducts?.isError,
    },
    {
      label: "Total product clicks",
      icon: "",
      value: totalGlobalStatsData?.data?.product_clicks,
      actionRef: "",
      isLoading: totalGlobalStatsData?.isLoading,
      color: "#333",
      errorExist: totalGlobalStatsData?.isError,
    },

    {
      label: "Total clickthroughs",
      icon: "",
      value: totalGlobalStatsData?.data?.click_throughs,
      actionRef: "",
      isLoading: totalGlobalStatsData?.isLoading,
      color: "#333",
      errorExist: totalGlobalStatsData?.isError,
    },
  ];

  const upgradePlan = () => {
    window.open(
      `https://www.pyvit.com/pages/pyvit-video-editor-pricing`,
      "_blank"
    );
  };

  const UsedAvailable = (props: any) => {
    const { used, available, loading, title, progress } = props;
    if (loading) {
      return (
        <Box sx={styles.boxStyle}>
          <CircularProgress />
        </Box>
      );
    } else {
      return (
        <React.Fragment>
          <Box width="100%">
            <StyledText
              fontvalue="16px"
              lineheight="24px"
              sx={styles.progressText}
            >
              {t(title)}
            </StyledText>
            <FloatLinearProgress variant="determinate" value={progress} />
          </Box>
          <Row100
            style={{ justifyContent: "space-between", marginBottom: "10px" }}
          >
            <StyledText fontvalue="16px" lineheight="24px">
              {t("Used")}- {used}{" "}
            </StyledText>
            <StyledText fontvalue="16px" lineheight="24px">
              {t("Available")}- {available}{" "}
            </StyledText>
          </Row100>
        </React.Fragment>
      );
    }
  };

  return (
    <>
    {subscriptionAlertData &&
        !subscriptionAlertData?.isLoading &&
        subscriptionAlertData?.data?.subscriptionRenewAlert && (
          <Box
            sx={{
              ...styles.alertBox,
              borderBottom:"2px solid #FF0000"
            }}
          >
            <StyledText
              color="#333"
              fontWeight="bold"
              fontvalue="16px"
              lineheight="20px"
            >
              {t("Subscription renewal alert")}:
            </StyledText>
            <StyledText color="#333" fontvalue="16px" lineheight="20px">
              {typeof(subscriptionAlertData?.data?.subscriptionRenewAlert) === "string" && t(subscriptionAlertData?.data?.subscriptionRenewAlert)}
            </StyledText>
          </Box>
        )}
      {subscriptionAlertData &&
        !subscriptionAlertData?.isLoading &&
        subscriptionAlertData?.data?.storageAlert &&
        subscriptionAlertData?.data?.storageAlert > 90 && (
          <Box
            sx={{
              ...styles.alertBox,
              borderBottom:
                subscriptionAlertData?.data?.storageAlert === 100
                  ? "2px solid #FF0000"
                  : "2px solid #FFA800",
            }}
          >
            <StyledText
              color="#333"
              fontWeight="bold"
              fontvalue="16px"
              lineheight="20px"
            >
              {t("Storage alert:")}
            </StyledText>
            <StyledText color="#333" fontvalue="16px" lineheight="20px">
              {Math.ceil(subscriptionAlertData?.data?.storageAlert)}%{" "}
              {t(
                "of your current video plan’s storage has been used. Consider upgrading for more storage"
              )}
            </StyledText>
          </Box>
        )}
      {subscriptionAlertData &&
        !subscriptionAlertData?.isLoading &&
        subscriptionAlertData?.data?.viewAlert &&
        subscriptionAlertData?.data?.viewAlert > 90 && (
          <Box
            sx={{
              ...styles.alertBox,
              borderBottom:
                subscriptionAlertData?.data?.storageAlert === 100
                  ? "2px solid #FF0000"
                  : "2px solid #FFA800",
            }}
          >
            <StyledText
              color="#333"
              fontWeight="bold"
              fontvalue="16px"
              lineheight="20px"
            >
              {t("Monthly view alert:")}
            </StyledText>
            <StyledText color="#333" fontvalue="16px" lineheight="20px">
              {Math.ceil(subscriptionAlertData?.data?.viewAlert)}%{" "}
              {t(
                "of your current video plan’s monthly views has been used. Consider upgrading for more views."
              )}
            </StyledText>
          </Box>
        )}

      <Grid container spacing={2} sx={styles.gridStyle}>
        <Grid item xs={12} md={6}>
          <CardDiv>
            <Stack sx={{ ...styles.cordTitleConatiner, padding: "0px" }}>
              <StyledText fontvalue="34px" lineheight="38px" variant="h4">
                {t("Plan")}
              </StyledText>
            </Stack>
            <RowBox
              style={{
                justifyContent: "space-between",
                marginBottom: "5px",
                marginTop: "0px",
              }}
            >
              <StyledText fontvalue="16px" sx={styles.planText}>
                {t("Plan")}: {planData?.data?.plan?.title}
                &nbsp;
                <span style={{ fontSize: "14px", color: "red" }}>
                  {iskeyInObject(planData?.data,"isSubscribed") &&
                  !planData?.data?.isSubscribed
                    ? t("(Expired)")
                    : ""}
                </span>
              </StyledText>
              <Button
                variant="contained"
                sx={styles.buttonStyle}
                onClick={upgradePlan}
              >
                {t("Purchase plan")}
              </Button>
            </RowBox>

            <UsedAvailable
              title="Videos storage"
              progress={planData?.data?.storageProgress ?? 0}
              available={`${planData?.data?.storageAvailable?.minutes}m ${planData?.data?.storageAvailable?.seconds}s`}
              used={`${planData?.data?.storageUsed?.minutes}m ${planData?.data?.storageUsed?.seconds}s`}
              loading={planData?.isLoading}
            />
            <UsedAvailable
              title="Videos viewed"
              progress={planData?.data?.viewsProgress ?? 0}
              loading={planData?.isLoading}
              used={planData?.data?.viewsUsed ?? ""}
              available={planData?.data?.viewsAvailable ?? ""}
            />
          </CardDiv>
        </Grid>
        <Grid item xs={12} md={6}>
          <CardDiv>
            <Stack sx={styles.cordTitleConatiner}>
              <StyledText fontvalue="34px" lineheight="38px" variant="h4">
                {t("Overall stats")}
              </StyledText>
            </Stack>
            <Box sx={styles.stats}>
              {cards.map((item: StatCard, index: number) => {
                return (
                  <Row100 key={index}>
                    <Row50 style={{ width: "75%" }}>
                      <StyledText fontvalue="16px" sx={styles.progressText}>
                        {t(item.label)}
                      </StyledText>
                    </Row50>
                    <Row50 style={{ width: "25%", alignItems: "flex-end" }}>
                      <StyledText
                        fontvalue="16px"
                        sx={{ ...styles.progressText }}
                      >
                        {!item.isLoading && !item.errorExist ? (
                          item?.value?.toLocaleString()
                        ) : item.errorExist ? (
                          "--"
                        ) : (
                          <CircularProgress size={"14px"} />
                        )}
                      </StyledText>
                    </Row50>
                  </Row100>
                );
              })}
            </Box>
          </CardDiv>
        </Grid>
      </Grid>
    </>
  );
};

export default PlanProgress;
