import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../../actions";
import * as ApiService from "../../services";
import { sendPayload, sendPayloadFailure } from ".././helper";
import { CHANGE_PASSWORD } from "../../actions/actiontypes";
const { REQUEST, ACCOUNT_PROFILE, PUT_ACCOUNT_USER_DATA } = actionTypes;

function* getAccountProfile(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAccountProfileData,
      data.data
    );
    yield sendPayload(apiResponse, ACCOUNT_PROFILE);
  } catch (e) {
    yield sendPayloadFailure(e, ACCOUNT_PROFILE);
  }
}

function* putAccountUserDataSagas(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.putAccountUser,
      data.data
    );
    yield sendPayload(apiResponse, PUT_ACCOUNT_USER_DATA);
  } catch (e) {
    yield sendPayloadFailure(e, PUT_ACCOUNT_USER_DATA);
  }
}

function* changePasswordSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.changeAccountPassword,
      data.data
    );
    yield sendPayload(apiResponse, CHANGE_PASSWORD);
  } catch (e) {
    yield sendPayloadFailure(e, CHANGE_PASSWORD);
  }
}

export const sagas = {
  watchUserDataSaga: takeLatest(
    actionTypes.ACCOUNT_PROFILE[REQUEST],
    getAccountProfile
  ),
  watchPutUserData: takeLatest(
    actionTypes.PUT_ACCOUNT_USER_DATA[REQUEST],
    putAccountUserDataSagas
  ),
  watchChangePassword: takeLatest(
    actionTypes.CHANGE_PASSWORD[REQUEST],
    changePasswordSaga
  ),
};
