export const videoDetailStyle = {
  pyvsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  addPyvButton: {
    backgroundColor: "black",
    color: "#fff",
    marginTop: "25px",
    marginBottom: "15px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "black",
    },
  },
};
