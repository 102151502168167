import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { stylesGlobal } from "../utils/muiStyles";
import DownloadSvg from "../utils/icons/DownloadSvg";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";

const QRmodalbody = ({ url }: any) => {
  const { t } = useTranslation();

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "qrcode.png";
    link.click();
  };

  return (
    <Stack>
      {url?.length > 0 ? (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Box sx={{ display: "flex" }}>
            <img
              src={url}
              alt={t("QR Code")}
              style={{ width: "220px", objectFit: "cover" }}
            />
          </Box>

          <Button
            variant="contained"
            sx={stylesGlobal.containedBtn}
            onClick={() => handleDownload()}
          >
            {" "}
            <DownloadSvg fill="#fff" /> &nbsp;{t("Download")}
          </Button>
        </Stack>
      ) : (
        <Loading />
      )}
    </Stack>
  );
};

export default QRmodalbody;
