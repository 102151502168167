export const CONSTANTS = {
   creatorLibColumnList: [
    { name: "Actions", status: true },
    { name: "Collection", status: true },
    { name: "Content id", status: true },
    { name: "Creator handle", status: true },
    { name: "Description", status: true },
    { name: "Title", status: true },
  ],
  
  languages: [
    { name: "English" },
    { name: "Spanish" },
    { name: "Japanese" },
    { name: "Thai" },
    { name: "Korean" },
    { name: "French" },
    { name: "Chinese" },
  ],
  filterViews: [
    {
      key: "past24hours",
      name: "Past 24 hours",
    },
    {
      key: "past7days",
      name: "Past 7 days",
    },
    {
      key: "past30days",
      name: "Past 30 days",
    },
    {
      key: "dateRange",
      name: "Select date",
    },
  ],
  graphFilters: [
    {
      key: "past7days",
      name: "Past 7 days",
    },
    {
      key: "past30days",
      name: "Past 30 days",
    },
    {
      key: "past24hours",
      name:"Past 24 hours"
    },
    {
      key: "dateRange",
      name: "Select date",
    },
  ],
  videoDetailList: [
    {
      value: 0,
      label: "Details",
    },
    {
      value: 1,
      label: "Linked products",
    },
    {
      value: 2,
      label: "PYV video",
    },
    {
      value: 3,
      label: "Share",
    },
    {
      value: 4,
      label: "Analytics",
    },
  ],
  analyticsList: [
    {
      value: 0,
      label: "Views",
    },
    {
      value: 1,
      label: "Products",
    },
    {
      value: 2,
      label: "Completion",
    },
    {
      value: 3,
      label: "Linked products",
    },
  ],
  CreatorVideoFilterColumns: [
    { name: "Title" },
    { name: "Description" },
    { name: "Collection" },
    { name: "Content id" },
    { name: "Unique views" },
    { name: "Total views" },
    { name: "Product clicks" },
    { name: "Status" },
  ],
  CreatorLibraryFilterColumns: [
    { name: "Creator handle" },
    { name: "Title" },
    { name: "Content id" },
    { name: "Unique Views" },
    { name: "Total views" },
    { name: "Product clicks" },
  ],
  InfluencerVideoFilterColumns: [
    { name: "Title" },
    { name: "Description" },
    { name: "Collection" },
    { name: "Content id" },
    { name: "Unique views" },
  ],
  CreatorLibraryAnalyticsOrder: [
    { name: "Content id", key: "content_id" },
    { name: "Title" ,key:"title"},
    { name: "Creator handle", key: "creator_handle" },
    { name: "Description", key:"description"},
    {name : "Total views", key:"total_views"},
    {name : "Unique views", key:"total_unique_views"},
  ],
  CreatorLibraryOrderContentId: [
    { name: "Content id", key: "content_id" },
    { name: "Title" ,key:"title"},
    { name: "Creator handle", key: "creator_handle" },
    { name: "Description", key:"description"},
  ],
  OrderHistoryFilterColumns: [
    { name: "Item Name", key: "item_name" },
    { name: "Order Id", key: "order_id" },
    { name: "Order Date", key: "order_date" },
    { name: "Delivery Date", key: "delivery_date" },
  ],
  OrderHistorySortBy: [
    { name: "Order Id", key: "shopify_order_id" },
    { name: "Total price", key: "total_price" },
    { name: "Transaction Id", key: "transaction_ids" },
  ],
  Order: [
    { name: "Asc", key: "asc" },
    { name: "Desc", key: "desc" },
  ],
  OrderHistoryDurationFilters: [
    { name: "Last 7 days", key: "7days" },
    { name: "Last 15 days", key: "15days" },
    { name: "Last 30 days", key: "30days" },
    { name: "All", key: "all" },
  ],
  FilterVideo: [
    { name: "Show all", key: "" },
    { name: "Web ads", key: "web ads" },
    { name: "Social media post", key: "social media posts" },
    { name: "Social media ads", key: "social media ads" },
  ],
  OrderHistorySearchByFilters: [
    { name: "Item Name", key: "item_name" },
    { name: "Order Id", key: "order_id" },
    { name: "Order Date", key: "order_date" },
    { name: "Delivery Date", key: "delivery_date" },
  ],
  StatusFilter: [
    { name: "Any", key: "any" },
    { name: "Open", key: "open" },
    { name: "Closed", key: "closed" },
    { name: "Cancelled", key: "cancelled" },
  ],
  SubscriptionStatusFilter: [
    { name: "All", key: "all" },
    { name: "Active", key: "active" },
    { name: "Cancelled", key: "cancelled" },
    { name: "Expired", key: "expired" },
  ],
  SearchOption: [
    { name: "Both", key: "all" },
    { name: "Subscription id", key: "subscription_id" },
    { name: "Product title", key: "product_title" },
  ],
  SearchOptionTracking: [
    { name: "All", key: "all" },
    { name: "Shopify order id", key: "shopify_order_id" },
    { name: "Tracking status", key: "tracking_status" },
    { name: "Product title", key: "product_title" },
  ],
  trackingDateRange: [
    { name: "All", key: "all" },
    { name: "Last week", key: "7days" },
    { name: "Last 15 days", key: "15days" },
    { name: "Last 30 days", key: "30days" },
    { name: "Last 6 months", key: "6months" },
  ],
  subscriptionColumnsFilter: [
    { name: "Subscription id", key: "id" },
    { name: "Product title", key: "product_title" },
    { name: "Quantity", key: "quantity" },
    { name: "Price", key: "price" },
  ],

  OrderHistoryFilterDuration: [
    { name: "Last 30 Days" },
    { name: "Last 90 Days" },
    { name: "This Year" },
  ],
  OrderTrackingFilters: [
    { name: "Id", key: "id" },
    { name: "Shopify order id", key: "number" },
    { name: "Total quantity", key: "total_quantity" },
    { name: "Grand total", key: "total_price" },
  ],
  InfluencerVideoSearchByFilters: [
    { name: "Title", key: "title" },
    { name: "Creator handle", key: "creator_handle" },
    { name: "Description", key: "description" },
    { name: "Collection", key: "collection" },
    { name: "Content id", key: "content_id" },
  ],
  InfluencerVideoSortByFilters: [
    { name: "Collection", key: "collections" },
    { name: "Content id", key: "content_id" },
    { name: "Description", key: "description" },
    { name: "Title", key: "title" },
  ],
  InfluencerVideoSortByFiltersInfl: [
    { name: "Collection", key: "collections" },
    { name: "Content id", key: "content_id" },
    { name: "Creator handle", key: "creator_handle" },
    { name: "Description", key: "description" },
    { name: "Title", key: "title" },
  ],
  videosAnalyticsFilter: [
    { name: "Collection", key: "collections" },
    { name: "Content id", key: "content_id" },
    { name: "Description", key: "description" },
    { name: "Title", key: "title" },
    {name : "Total views", key:"total_views"},
    {name : "Unique views", key:"total_unique_views"},
  ],
  videosAnalyticsFilterInfl: [
    { name: "Collection", key: "collections" },
    { name: "Content id", key: "content_id" },
    { name: "Creator handle", key: "creator_handle" },
    { name: "Description", key: "description" },
    { name: "Title", key: "title" },
    {name : "Total views", key:"total_views"},
    {name : "Unique views", key:"total_unique_views"},
  ],


  roles: {
    INFLUENCER: "influencer",
    CREATOR: "creator",
    CUSTOMER: "customer",
  },
  analyticsTabList: [
    { name: "Views", key: "0" },
    { name: "Completion", key: "1" },
    { name: "Linked products", key: "2" },
  ],
  CreatorLibrarySearchBy: [
    { name: "Title", key: "title" },
    { name: "Description", key: "description" },
    { name: "Creator handle", key: "creator_handle" },
    { name: "Collection", key: "collections" },
    { name: "Content id", key: "content_id" },
    { name: "Product name", key: "product_name" },
    { name: "Vendor name", key: "vendor_name" },
  ],
creatorLibHeadCells: [
    {
      id: "video",
      numeric: false,
      align: "left",
      disablePadding: false,
      label: "Video",
    },
    {
      id: "title",
      numeric: false,
      align: "left",
      disablePadding: true,
      label: "Title",
    },
    {
      id: "creatorHandle",
      numeric: true,
      disablePadding: false,
      align: "left",
      label: "Creator handle",
    },
    {
      id: "description",
      numeric: true,
      disablePadding: false,
      align: "left",
      label: "Description",
    },
    {
      id: "collection",
      numeric: true,
      disablePadding: false,
      align: "left",
      label: "Collection",
    },
    {
      id: "content",
      numeric: false,
      disablePadding: false,
      align: "left",
      label: "Content id",
    },
  ]
};

export const CountryMobileData = [
  {
    name: "Afghanistan",
    code: "AF",
    continent: "Asia",
    phoneNumberPrefix: 93,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Åland Islands",
    code: "AX",
    continent: "Europe",
    phoneNumberPrefix: 358,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Albania",
    code: "AL",
    continent: "Europe",
    phoneNumberPrefix: 355,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Algeria",
    code: "DZ",
    continent: "Africa",
    phoneNumberPrefix: 213,
    autocompletionField: "address1",
    provinceKey: "PROVINCE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Andorra",
    code: "AD",
    continent: "Europe",
    phoneNumberPrefix: 376,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Angola",
    code: "AO",
    continent: "Africa",
    phoneNumberPrefix: 244,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Anguilla",
    code: "AI",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country} {zip}_{phone}",
    },
    zones: [],
  },
  {
    name: "Antigua \u0026 Barbuda",
    code: "AG",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Argentina",
    code: "AR",
    continent: "South America",
    phoneNumberPrefix: 54,
    autocompletionField: "address1",
    provinceKey: "PROVINCE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Province",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Buenos Aires Province",
        code: "B",
      },
      {
        name: "Catamarca",
        code: "K",
      },
      {
        name: "Chaco",
        code: "H",
      },
      {
        name: "Chubut",
        code: "U",
      },
      {
        name: "Buenos Aires (Autonomous City)",
        code: "C",
      },
      {
        name: "Córdoba",
        code: "X",
      },
      {
        name: "Corrientes",
        code: "W",
      },
      {
        name: "Entre Ríos",
        code: "E",
      },
      {
        name: "Formosa",
        code: "P",
      },
      {
        name: "Jujuy",
        code: "Y",
      },
      {
        name: "La Pampa",
        code: "L",
      },
      {
        name: "La Rioja",
        code: "F",
      },
      {
        name: "Mendoza",
        code: "M",
      },
      {
        name: "Misiones",
        code: "N",
      },
      {
        name: "Neuquén",
        code: "Q",
      },
      {
        name: "Río Negro",
        code: "R",
      },
      {
        name: "Salta",
        code: "A",
      },
      {
        name: "San Juan",
        code: "J",
      },
      {
        name: "San Luis",
        code: "D",
      },
      {
        name: "Santa Cruz",
        code: "Z",
      },
      {
        name: "Santa Fe",
        code: "S",
      },
      {
        name: "Santiago del Estero",
        code: "G",
      },
      {
        name: "Tierra del Fuego",
        code: "V",
      },
      {
        name: "Tucumán",
        code: "T",
      },
    ],
  },
  {
    name: "Armenia",
    code: "AM",
    continent: "Asia",
    phoneNumberPrefix: 374,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Aruba",
    code: "AW",
    continent: "North America",
    phoneNumberPrefix: 297,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Ascension Island",
    code: "AC",
    continent: "Africa",
    phoneNumberPrefix: 247,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Australia",
    code: "AU",
    continent: "Oceania",
    phoneNumberPrefix: 61,
    autocompletionField: "address1",
    provinceKey: "STATE_AND_TERRITORY",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "Suburb",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postcode",
      zone: "State/territory",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province} {zip}_{country}_{phone}",
    },
    zones: [
      {
        name: "Australian Capital Territory",
        code: "ACT",
      },
      {
        name: "New South Wales",
        code: "NSW",
      },
      {
        name: "Northern Territory",
        code: "NT",
      },
      {
        name: "Queensland",
        code: "QLD",
      },
      {
        name: "South Australia",
        code: "SA",
      },
      {
        name: "Tasmania",
        code: "TAS",
      },
      {
        name: "Victoria",
        code: "VIC",
      },
      {
        name: "Western Australia",
        code: "WA",
      },
    ],
  },
  {
    name: "Austria",
    code: "AT",
    continent: "Europe",
    phoneNumberPrefix: 43,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Street and house number",
      address2: "Additional address",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Additional address (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    continent: "Asia",
    phoneNumberPrefix: 994,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Bahamas",
    code: "BS",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Bahrain",
    code: "BH",
    continent: "Asia",
    phoneNumberPrefix: 973,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Bangladesh",
    code: "BD",
    continent: "Asia",
    phoneNumberPrefix: 880,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Barbados",
    code: "BB",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Belarus",
    code: "BY",
    continent: "Europe",
    phoneNumberPrefix: 375,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Belgium",
    code: "BE",
    continent: "Europe",
    phoneNumberPrefix: 32,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Belize",
    code: "BZ",
    continent: "North America",
    phoneNumberPrefix: 501,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Benin",
    code: "BJ",
    continent: "Africa",
    phoneNumberPrefix: 229,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Bermuda",
    code: "BM",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Bhutan",
    code: "BT",
    continent: "Asia",
    phoneNumberPrefix: 975,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Bolivia",
    code: "BO",
    continent: "South America",
    phoneNumberPrefix: 591,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Bosnia \u0026 Herzegovina",
    code: "BA",
    continent: "Europe",
    phoneNumberPrefix: 387,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Botswana",
    code: "BW",
    continent: "Africa",
    phoneNumberPrefix: 267,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Brazil",
    code: "BR",
    continent: "South America",
    phoneNumberPrefix: 55,
    autocompletionField: "zip",
    provinceKey: "STATE",
    labels: {
      address1: "Street and house number",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "State",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{zip}_{address1}_{address2}_{city}{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Acre",
        code: "AC",
      },
      {
        name: "Alagoas",
        code: "AL",
      },
      {
        name: "Amapá",
        code: "AP",
      },
      {
        name: "Amazonas",
        code: "AM",
      },
      {
        name: "Bahia",
        code: "BA",
      },
      {
        name: "Ceará",
        code: "CE",
      },
      {
        name: "Federal District",
        code: "DF",
      },
      {
        name: "Espírito Santo",
        code: "ES",
      },
      {
        name: "Goiás",
        code: "GO",
      },
      {
        name: "Maranhão",
        code: "MA",
      },
      {
        name: "Mato Grosso",
        code: "MT",
      },
      {
        name: "Mato Grosso do Sul",
        code: "MS",
      },
      {
        name: "Minas Gerais",
        code: "MG",
      },
      {
        name: "Pará",
        code: "PA",
      },
      {
        name: "Paraíba",
        code: "PB",
      },
      {
        name: "Paraná",
        code: "PR",
      },
      {
        name: "Pernambuco",
        code: "PE",
      },
      {
        name: "Piauí",
        code: "PI",
      },
      {
        name: "Rio Grande do Norte",
        code: "RN",
      },
      {
        name: "Rio Grande do Sul",
        code: "RS",
      },
      {
        name: "Rio de Janeiro",
        code: "RJ",
      },
      {
        name: "Rondônia",
        code: "RO",
      },
      {
        name: "Roraima",
        code: "RR",
      },
      {
        name: "Santa Catarina",
        code: "SC",
      },
      {
        name: "São Paulo",
        code: "SP",
      },
      {
        name: "Sergipe",
        code: "SE",
      },
      {
        name: "Tocantins",
        code: "TO",
      },
    ],
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    continent: "Africa",
    phoneNumberPrefix: 246,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "British Virgin Islands",
    code: "VG",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Brunei",
    code: "BN",
    continent: "Asia",
    phoneNumberPrefix: 673,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Bulgaria",
    code: "BG",
    continent: "Europe",
    phoneNumberPrefix: 359,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Burkina Faso",
    code: "BF",
    continent: "Africa",
    phoneNumberPrefix: 226,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Burundi",
    code: "BI",
    continent: "Africa",
    phoneNumberPrefix: 257,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Cambodia",
    code: "KH",
    continent: "Asia",
    phoneNumberPrefix: 855,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Cameroon",
    code: "CM",
    continent: "Africa",
    phoneNumberPrefix: 237,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Canada",
    code: "CA",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "PROVINCE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Province",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province} {zip}_{country}_{phone}",
    },
    zones: [
      {
        name: "Alberta",
        code: "AB",
      },
      {
        name: "British Columbia",
        code: "BC",
      },
      {
        name: "Manitoba",
        code: "MB",
      },
      {
        name: "New Brunswick",
        code: "NB",
      },
      {
        name: "Newfoundland and Labrador",
        code: "NL",
      },
      {
        name: "Northwest Territories",
        code: "NT",
      },
      {
        name: "Nova Scotia",
        code: "NS",
      },
      {
        name: "Nunavut",
        code: "NU",
      },
      {
        name: "Ontario",
        code: "ON",
      },
      {
        name: "Prince Edward Island",
        code: "PE",
      },
      {
        name: "Quebec",
        code: "QC",
      },
      {
        name: "Saskatchewan",
        code: "SK",
      },
      {
        name: "Yukon",
        code: "YT",
      },
    ],
  },
  {
    name: "Cape Verde",
    code: "CV",
    continent: "Africa",
    phoneNumberPrefix: 238,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Caribbean Netherlands",
    code: "BQ",
    continent: "North America",
    phoneNumberPrefix: 599,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Cayman Islands",
    code: "KY",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Central African Republic",
    code: "CF",
    continent: "Africa",
    phoneNumberPrefix: 236,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Chad",
    code: "TD",
    continent: "Africa",
    phoneNumberPrefix: 235,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Chile",
    code: "CL",
    continent: "South America",
    phoneNumberPrefix: 56,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Arica y Parinacota",
        code: "AP",
      },
      {
        name: "Tarapacá",
        code: "TA",
      },
      {
        name: "Antofagasta",
        code: "AN",
      },
      {
        name: "Atacama",
        code: "AT",
      },
      {
        name: "Coquimbo",
        code: "CO",
      },
      {
        name: "Valparaíso",
        code: "VS",
      },
      {
        name: "Santiago Metropolitan",
        code: "RM",
      },
      {
        name: "Libertador General Bernardo O’Higgins",
        code: "LI",
      },
      {
        name: "Maule",
        code: "ML",
      },
      {
        name: "Ñuble",
        code: "NB",
      },
      {
        name: "Bío Bío",
        code: "BI",
      },
      {
        name: "Araucanía",
        code: "AR",
      },
      {
        name: "Los Ríos",
        code: "LR",
      },
      {
        name: "Los Lagos",
        code: "LL",
      },
      {
        name: "Aysén",
        code: "AI",
      },
      {
        name: "Magallanes Region",
        code: "MA",
      },
    ],
  },
  {
    name: "China",
    code: "CN",
    continent: "Asia",
    phoneNumberPrefix: 86,
    autocompletionField: "address1",
    provinceKey: "PROVINCE",
    labels: {
      address1: "Full address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Province",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1} {address2} {city}_{zip} {province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Anhui",
        code: "AH",
      },
      {
        name: "Beijing",
        code: "BJ",
      },
      {
        name: "Chongqing",
        code: "CQ",
      },
      {
        name: "Fujian",
        code: "FJ",
      },
      {
        name: "Gansu",
        code: "GS",
      },
      {
        name: "Guangdong",
        code: "GD",
      },
      {
        name: "Guangxi",
        code: "GX",
      },
      {
        name: "Guizhou",
        code: "GZ",
      },
      {
        name: "Hainan",
        code: "HI",
      },
      {
        name: "Hebei",
        code: "HE",
      },
      {
        name: "Heilongjiang",
        code: "HL",
      },
      {
        name: "Henan",
        code: "HA",
      },
      {
        name: "Hubei",
        code: "HB",
      },
      {
        name: "Hunan",
        code: "HN",
      },
      {
        name: "Inner Mongolia",
        code: "NM",
      },
      {
        name: "Jiangsu",
        code: "JS",
      },
      {
        name: "Jiangxi",
        code: "JX",
      },
      {
        name: "Jilin",
        code: "JL",
      },
      {
        name: "Liaoning",
        code: "LN",
      },
      {
        name: "Ningxia",
        code: "NX",
      },
      {
        name: "Qinghai",
        code: "QH",
      },
      {
        name: "Shaanxi",
        code: "SN",
      },
      {
        name: "Shandong",
        code: "SD",
      },
      {
        name: "Shanghai",
        code: "SH",
      },
      {
        name: "Shanxi",
        code: "SX",
      },
      {
        name: "Sichuan",
        code: "SC",
      },
      {
        name: "Tianjin",
        code: "TJ",
      },
      {
        name: "Xinjiang",
        code: "XJ",
      },
      {
        name: "Tibet",
        code: "YZ",
      },
      {
        name: "Yunnan",
        code: "YN",
      },
      {
        name: "Zhejiang",
        code: "ZJ",
      },
    ],
  },
  {
    name: "Christmas Island",
    code: "CX",
    continent: "Oceania",
    phoneNumberPrefix: 61,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC",
    continent: "Oceania",
    phoneNumberPrefix: 891,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Colombia",
    code: "CO",
    continent: "South America",
    phoneNumberPrefix: 57,
    autocompletionField: "address1",
    provinceKey: "PROVINCE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Province",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Capital District",
        code: "DC",
      },
      {
        name: "Amazonas",
        code: "AMA",
      },
      {
        name: "Antioquia",
        code: "ANT",
      },
      {
        name: "Arauca",
        code: "ARA",
      },
      {
        name: "Atlántico",
        code: "ATL",
      },
      {
        name: "Bolívar",
        code: "BOL",
      },
      {
        name: "Boyacá",
        code: "BOY",
      },
      {
        name: "Caldas",
        code: "CAL",
      },
      {
        name: "Caquetá",
        code: "CAQ",
      },
      {
        name: "Casanare",
        code: "CAS",
      },
      {
        name: "Cauca",
        code: "CAU",
      },
      {
        name: "Cesar",
        code: "CES",
      },
      {
        name: "Chocó",
        code: "CHO",
      },
      {
        name: "Córdoba",
        code: "COR",
      },
      {
        name: "Cundinamarca",
        code: "CUN",
      },
      {
        name: "Guainía",
        code: "GUA",
      },
      {
        name: "Guaviare",
        code: "GUV",
      },
      {
        name: "Huila",
        code: "HUI",
      },
      {
        name: "La Guajira",
        code: "LAG",
      },
      {
        name: "Magdalena",
        code: "MAG",
      },
      {
        name: "Meta",
        code: "MET",
      },
      {
        name: "Nariño",
        code: "NAR",
      },
      {
        name: "Norte de Santander",
        code: "NSA",
      },
      {
        name: "Putumayo",
        code: "PUT",
      },
      {
        name: "Quindío",
        code: "QUI",
      },
      {
        name: "Risaralda",
        code: "RIS",
      },
      {
        name: "San Andrés \u0026 Providencia",
        code: "SAP",
      },
      {
        name: "Santander",
        code: "SAN",
      },
      {
        name: "Sucre",
        code: "SUC",
      },
      {
        name: "Tolima",
        code: "TOL",
      },
      {
        name: "Valle del Cauca",
        code: "VAC",
      },
      {
        name: "Vaupés",
        code: "VAU",
      },
      {
        name: "Vichada",
        code: "VID",
      },
    ],
  },
  {
    name: "Comoros",
    code: "KM",
    continent: "Africa",
    phoneNumberPrefix: 269,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Congo - Brazzaville",
    code: "CG",
    continent: "Africa",
    phoneNumberPrefix: 242,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Congo - Kinshasa",
    code: "CD",
    continent: "Africa",
    phoneNumberPrefix: 243,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Cook Islands",
    code: "CK",
    continent: "Oceania",
    phoneNumberPrefix: 682,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Costa Rica",
    code: "CR",
    continent: "North America",
    phoneNumberPrefix: 506,
    autocompletionField: "address1",
    provinceKey: "PROVINCE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Province",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{province}{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{province} {city}_{zip}_{country}_{phone}",
    },
    zones: [
      {
        name: "Alajuela",
        code: "CR-A",
      },
      {
        name: "Cartago",
        code: "CR-C",
      },
      {
        name: "Guanacaste",
        code: "CR-G",
      },
      {
        name: "Heredia",
        code: "CR-H",
      },
      {
        name: "Limón",
        code: "CR-L",
      },
      {
        name: "Puntarenas",
        code: "CR-P",
      },
      {
        name: "San José",
        code: "CR-SJ",
      },
    ],
  },
  {
    name: "Croatia",
    code: "HR",
    continent: "Europe",
    phoneNumberPrefix: 385,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Curaçao",
    code: "CW",
    continent: "North America",
    phoneNumberPrefix: 599,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Cyprus",
    code: "CY",
    continent: "Asia",
    phoneNumberPrefix: 357,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Czechia",
    code: "CZ",
    continent: "Europe",
    phoneNumberPrefix: 420,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Côte d’Ivoire",
    code: "CI",
    continent: "Africa",
    phoneNumberPrefix: 225,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Denmark",
    code: "DK",
    continent: "Europe",
    phoneNumberPrefix: 45,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Street and house number",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Djibouti",
    code: "DJ",
    continent: "Africa",
    phoneNumberPrefix: 253,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Dominica",
    code: "DM",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Dominican Republic",
    code: "DO",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Ecuador",
    code: "EC",
    continent: "South America",
    phoneNumberPrefix: 593,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Egypt",
    code: "EG",
    continent: "Africa",
    phoneNumberPrefix: 20,
    autocompletionField: "address1",
    provinceKey: "GOVERNORATE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Governorate",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{province}_{city}_{zip}_{country}_{phone}",
    },
    zones: [
      {
        name: "6th of October",
        code: "SU",
      },
      {
        name: "Al Sharqia",
        code: "SHR",
      },
      {
        name: "Alexandria",
        code: "ALX",
      },
      {
        name: "Aswan",
        code: "ASN",
      },
      {
        name: "Asyut",
        code: "AST",
      },
      {
        name: "Beheira",
        code: "BH",
      },
      {
        name: "Beni Suef",
        code: "BNS",
      },
      {
        name: "Cairo",
        code: "C",
      },
      {
        name: "Dakahlia",
        code: "DK",
      },
      {
        name: "Damietta",
        code: "DT",
      },
      {
        name: "Faiyum",
        code: "FYM",
      },
      {
        name: "Gharbia",
        code: "GH",
      },
      {
        name: "Giza",
        code: "GZ",
      },
      {
        name: "Helwan",
        code: "HU",
      },
      {
        name: "Ismailia",
        code: "IS",
      },
      {
        name: "Kafr el-Sheikh",
        code: "KFS",
      },
      {
        name: "Luxor",
        code: "LX",
      },
      {
        name: "Matrouh",
        code: "MT",
      },
      {
        name: "Minya",
        code: "MN",
      },
      {
        name: "Monufia",
        code: "MNF",
      },
      {
        name: "New Valley",
        code: "WAD",
      },
      {
        name: "North Sinai",
        code: "SIN",
      },
      {
        name: "Port Said",
        code: "PTS",
      },
      {
        name: "Qalyubia",
        code: "KB",
      },
      {
        name: "Qena",
        code: "KN",
      },
      {
        name: "Red Sea",
        code: "BA",
      },
      {
        name: "Sohag",
        code: "SHG",
      },
      {
        name: "South Sinai",
        code: "JS",
      },
      {
        name: "Suez",
        code: "SUZ",
      },
    ],
  },
  {
    name: "El Salvador",
    code: "SV",
    continent: "North America",
    phoneNumberPrefix: 503,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Department",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Ahuachapán",
        code: "SV-AH",
      },
      {
        name: "Cabañas",
        code: "SV-CA",
      },
      {
        name: "Chalatenango",
        code: "SV-CH",
      },
      {
        name: "Cuscatlán",
        code: "SV-CU",
      },
      {
        name: "La Libertad",
        code: "SV-LI",
      },
      {
        name: "La Paz",
        code: "SV-PA",
      },
      {
        name: "La Unión",
        code: "SV-UN",
      },
      {
        name: "Morazán",
        code: "SV-MO",
      },
      {
        name: "San Miguel",
        code: "SV-SM",
      },
      {
        name: "San Salvador",
        code: "SV-SS",
      },
      {
        name: "San Vicente",
        code: "SV-SV",
      },
      {
        name: "Santa Ana",
        code: "SV-SA",
      },
      {
        name: "Sonsonate",
        code: "SV-SO",
      },
      {
        name: "Usulután",
        code: "SV-US",
      },
    ],
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    continent: "Africa",
    phoneNumberPrefix: 240,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Eritrea",
    code: "ER",
    continent: "Africa",
    phoneNumberPrefix: 291,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Estonia",
    code: "EE",
    continent: "Europe",
    phoneNumberPrefix: 372,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Eswatini",
    code: "SZ",
    continent: "Africa",
    phoneNumberPrefix: 268,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Ethiopia",
    code: "ET",
    continent: "Africa",
    phoneNumberPrefix: 251,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Falkland Islands",
    code: "FK",
    continent: "South America",
    phoneNumberPrefix: 500,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Faroe Islands",
    code: "FO",
    continent: "Europe",
    phoneNumberPrefix: 298,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Fiji",
    code: "FJ",
    continent: "Oceania",
    phoneNumberPrefix: 679,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Finland",
    code: "FI",
    continent: "Europe",
    phoneNumberPrefix: 358,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "France",
    code: "FR",
    continent: "Europe",
    phoneNumberPrefix: 33,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "French Guiana",
    code: "GF",
    continent: "South America",
    phoneNumberPrefix: 594,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "French Polynesia",
    code: "PF",
    continent: "Oceania",
    phoneNumberPrefix: 689,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "French Southern Territories",
    code: "TF",
    continent: "Africa",
    phoneNumberPrefix: 262,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Gabon",
    code: "GA",
    continent: "Africa",
    phoneNumberPrefix: 241,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Gambia",
    code: "GM",
    continent: "Africa",
    phoneNumberPrefix: 220,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Georgia",
    code: "GE",
    continent: "Asia",
    phoneNumberPrefix: 995,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Germany",
    code: "DE",
    continent: "Europe",
    phoneNumberPrefix: 49,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Street and house number",
      address2: "Additional address",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Additional address (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Ghana",
    code: "GH",
    continent: "Africa",
    phoneNumberPrefix: 233,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Gibraltar",
    code: "GI",
    continent: "Europe",
    phoneNumberPrefix: 350,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Greece",
    code: "GR",
    continent: "Europe",
    phoneNumberPrefix: 30,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Greenland",
    code: "GL",
    continent: "North America",
    phoneNumberPrefix: 299,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Grenada",
    code: "GD",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Guadeloupe",
    code: "GP",
    continent: "North America",
    phoneNumberPrefix: 590,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Guatemala",
    code: "GT",
    continent: "North America",
    phoneNumberPrefix: 502,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province}_{zip}_{country}_{phone}",
    },
    zones: [
      {
        name: "Alta Verapaz",
        code: "AVE",
      },
      {
        name: "Baja Verapaz",
        code: "BVE",
      },
      {
        name: "Chimaltenango",
        code: "CMT",
      },
      {
        name: "Chiquimula",
        code: "CQM",
      },
      {
        name: "El Progreso",
        code: "EPR",
      },
      {
        name: "Escuintla",
        code: "ESC",
      },
      {
        name: "Guatemala",
        code: "GUA",
      },
      {
        name: "Huehuetenango",
        code: "HUE",
      },
      {
        name: "Izabal",
        code: "IZA",
      },
      {
        name: "Jalapa",
        code: "JAL",
      },
      {
        name: "Jutiapa",
        code: "JUT",
      },
      {
        name: "Petén",
        code: "PET",
      },
      {
        name: "Quetzaltenango",
        code: "QUE",
      },
      {
        name: "Quiché",
        code: "QUI",
      },
      {
        name: "Retalhuleu",
        code: "RET",
      },
      {
        name: "Sacatepéquez",
        code: "SAC",
      },
      {
        name: "San Marcos",
        code: "SMA",
      },
      {
        name: "Santa Rosa",
        code: "SRO",
      },
      {
        name: "Sololá",
        code: "SOL",
      },
      {
        name: "Suchitepéquez",
        code: "SUC",
      },
      {
        name: "Totonicapán",
        code: "TOT",
      },
      {
        name: "Zacapa",
        code: "ZAC",
      },
    ],
  },
  {
    name: "Guernsey",
    code: "GG",
    continent: "Europe",
    phoneNumberPrefix: 44,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Guinea",
    code: "GN",
    continent: "Africa",
    phoneNumberPrefix: 224,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    continent: "Africa",
    phoneNumberPrefix: 245,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Guyana",
    code: "GY",
    continent: "South America",
    phoneNumberPrefix: 592,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Haiti",
    code: "HT",
    continent: "North America",
    phoneNumberPrefix: 509,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Honduras",
    code: "HN",
    continent: "North America",
    phoneNumberPrefix: 504,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Hong Kong SAR",
    code: "HK",
    continent: "Asia",
    phoneNumberPrefix: 852,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "District",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province} {country}_{phone}",
    },
    zones: [
      {
        name: "Hong Kong Island",
        code: "HK",
      },
      {
        name: "Kowloon",
        code: "KL",
      },
      {
        name: "New Territories",
        code: "NT",
      },
    ],
  },
  {
    name: "Hungary",
    code: "HU",
    continent: "Europe",
    phoneNumberPrefix: 36,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{zip}{city}_{address1}_{address2}_{phone}",
      show: "{firstName} {lastName}_{company}_{zip} {city}_{address1}_{address2}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Iceland",
    code: "IS",
    continent: "Europe",
    phoneNumberPrefix: 354,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "India",
    code: "IN",
    continent: "Asia",
    phoneNumberPrefix: 91,
    autocompletionField: "address1",
    provinceKey: "STATE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "PIN code",
      zone: "State",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Andaman and Nicobar Islands",
        code: "AN",
      },
      {
        name: "Andhra Pradesh",
        code: "AP",
      },
      {
        name: "Arunachal Pradesh",
        code: "AR",
      },
      {
        name: "Assam",
        code: "AS",
      },
      {
        name: "Bihar",
        code: "BR",
      },
      {
        name: "Chandigarh",
        code: "CH",
      },
      {
        name: "Chhattisgarh",
        code: "CG",
      },
      {
        name: "Dadra and Nagar Haveli",
        code: "DN",
      },
      {
        name: "Daman and Diu",
        code: "DD",
      },
      {
        name: "Delhi",
        code: "DL",
      },
      {
        name: "Goa",
        code: "GA",
      },
      {
        name: "Gujarat",
        code: "GJ",
      },
      {
        name: "Haryana",
        code: "HR",
      },
      {
        name: "Himachal Pradesh",
        code: "HP",
      },
      {
        name: "Jammu and Kashmir",
        code: "JK",
      },
      {
        name: "Jharkhand",
        code: "JH",
      },
      {
        name: "Karnataka",
        code: "KA",
      },
      {
        name: "Kerala",
        code: "KL",
      },
      {
        name: "Ladakh",
        code: "LA",
      },
      {
        name: "Lakshadweep",
        code: "LD",
      },
      {
        name: "Madhya Pradesh",
        code: "MP",
      },
      {
        name: "Maharashtra",
        code: "MH",
      },
      {
        name: "Manipur",
        code: "MN",
      },
      {
        name: "Meghalaya",
        code: "ML",
      },
      {
        name: "Mizoram",
        code: "MZ",
      },
      {
        name: "Nagaland",
        code: "NL",
      },
      {
        name: "Odisha",
        code: "OR",
      },
      {
        name: "Puducherry",
        code: "PY",
      },
      {
        name: "Punjab",
        code: "PB",
      },
      {
        name: "Rajasthan",
        code: "RJ",
      },
      {
        name: "Sikkim",
        code: "SK",
      },
      {
        name: "Tamil Nadu",
        code: "TN",
      },
      {
        name: "Telangana",
        code: "TS",
      },
      {
        name: "Tripura",
        code: "TR",
      },
      {
        name: "Uttar Pradesh",
        code: "UP",
      },
      {
        name: "Uttarakhand",
        code: "UK",
      },
      {
        name: "West Bengal",
        code: "WB",
      },
    ],
  },
  {
    name: "Indonesia",
    code: "ID",
    continent: "Asia",
    phoneNumberPrefix: 62,
    autocompletionField: "address1",
    provinceKey: "PROVINCE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Province",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province} {zip}_{country}_{phone}",
    },
    zones: [
      {
        name: "Aceh",
        code: "AC",
      },
      {
        name: "Bali",
        code: "BA",
      },
      {
        name: "Bangka–Belitung Islands",
        code: "BB",
      },
      {
        name: "Banten",
        code: "BT",
      },
      {
        name: "Bengkulu",
        code: "BE",
      },
      {
        name: "Gorontalo",
        code: "GO",
      },
      {
        name: "Jakarta",
        code: "JK",
      },
      {
        name: "Jambi",
        code: "JA",
      },
      {
        name: "West Java",
        code: "JB",
      },
      {
        name: "Central Java",
        code: "JT",
      },
      {
        name: "East Java",
        code: "JI",
      },
      {
        name: "West Kalimantan",
        code: "KB",
      },
      {
        name: "South Kalimantan",
        code: "KS",
      },
      {
        name: "Central Kalimantan",
        code: "KT",
      },
      {
        name: "East Kalimantan",
        code: "KI",
      },
      {
        name: "North Kalimantan",
        code: "KU",
      },
      {
        name: "Riau Islands",
        code: "KR",
      },
      {
        name: "Lampung",
        code: "LA",
      },
      {
        name: "Maluku",
        code: "MA",
      },
      {
        name: "North Maluku",
        code: "MU",
      },
      {
        name: "North Sumatra",
        code: "SU",
      },
      {
        name: "West Nusa Tenggara",
        code: "NB",
      },
      {
        name: "East Nusa Tenggara",
        code: "NT",
      },
      {
        name: "Papua",
        code: "PA",
      },
      {
        name: "West Papua",
        code: "PB",
      },
      {
        name: "Riau",
        code: "RI",
      },
      {
        name: "South Sumatra",
        code: "SS",
      },
      {
        name: "West Sulawesi",
        code: "SR",
      },
      {
        name: "South Sulawesi",
        code: "SN",
      },
      {
        name: "Central Sulawesi",
        code: "ST",
      },
      {
        name: "Southeast Sulawesi",
        code: "SG",
      },
      {
        name: "North Sulawesi",
        code: "SA",
      },
      {
        name: "West Sumatra",
        code: "SB",
      },
      {
        name: "Yogyakarta",
        code: "YO",
      },
    ],
  },
  {
    name: "Iraq",
    code: "IQ",
    continent: "Asia",
    phoneNumberPrefix: 964,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Ireland",
    code: "IE",
    continent: "Europe",
    phoneNumberPrefix: 353,
    autocompletionField: "address1",
    provinceKey: "COUNTY",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "County",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province}_{zip}_{country}_{phone}",
    },
    zones: [
      {
        name: "Carlow",
        code: "CW",
      },
      {
        name: "Cavan",
        code: "CN",
      },
      {
        name: "Clare",
        code: "CE",
      },
      {
        name: "Cork",
        code: "CO",
      },
      {
        name: "Donegal",
        code: "DL",
      },
      {
        name: "Dublin",
        code: "D",
      },
      {
        name: "Galway",
        code: "G",
      },
      {
        name: "Kerry",
        code: "KY",
      },
      {
        name: "Kildare",
        code: "KE",
      },
      {
        name: "Kilkenny",
        code: "KK",
      },
      {
        name: "Laois",
        code: "LS",
      },
      {
        name: "Leitrim",
        code: "LM",
      },
      {
        name: "Limerick",
        code: "LK",
      },
      {
        name: "Longford",
        code: "LD",
      },
      {
        name: "Louth",
        code: "LH",
      },
      {
        name: "Mayo",
        code: "MO",
      },
      {
        name: "Meath",
        code: "MH",
      },
      {
        name: "Monaghan",
        code: "MN",
      },
      {
        name: "Offaly",
        code: "OY",
      },
      {
        name: "Roscommon",
        code: "RN",
      },
      {
        name: "Sligo",
        code: "SO",
      },
      {
        name: "Tipperary",
        code: "TA",
      },
      {
        name: "Waterford",
        code: "WD",
      },
      {
        name: "Westmeath",
        code: "WH",
      },
      {
        name: "Wexford",
        code: "WX",
      },
      {
        name: "Wicklow",
        code: "WW",
      },
    ],
  },
  {
    name: "Isle of Man",
    code: "IM",
    continent: "Europe",
    phoneNumberPrefix: 44,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Israel",
    code: "IL",
    continent: "Asia",
    phoneNumberPrefix: 972,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Italy",
    code: "IT",
    continent: "Europe",
    phoneNumberPrefix: 39,
    autocompletionField: "address1",
    provinceKey: "PROVINCE",
    labels: {
      address1: "Street and house number",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Province",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Agrigento",
        code: "AG",
      },
      {
        name: "Alessandria",
        code: "AL",
      },
      {
        name: "Ancona",
        code: "AN",
      },
      {
        name: "Aosta Valley",
        code: "AO",
      },
      {
        name: "Arezzo",
        code: "AR",
      },
      {
        name: "Ascoli Piceno",
        code: "AP",
      },
      {
        name: "Asti",
        code: "AT",
      },
      {
        name: "Avellino",
        code: "AV",
      },
      {
        name: "Bari",
        code: "BA",
      },
      {
        name: "Barletta-Andria-Trani",
        code: "BT",
      },
      {
        name: "Belluno",
        code: "BL",
      },
      {
        name: "Benevento",
        code: "BN",
      },
      {
        name: "Bergamo",
        code: "BG",
      },
      {
        name: "Biella",
        code: "BI",
      },
      {
        name: "Bologna",
        code: "BO",
      },
      {
        name: "South Tyrol",
        code: "BZ",
      },
      {
        name: "Brescia",
        code: "BS",
      },
      {
        name: "Brindisi",
        code: "BR",
      },
      {
        name: "Cagliari",
        code: "CA",
      },
      {
        name: "Caltanissetta",
        code: "CL",
      },
      {
        name: "Campobasso",
        code: "CB",
      },
      {
        name: "Carbonia-Iglesias",
        code: "CI",
      },
      {
        name: "Caserta",
        code: "CE",
      },
      {
        name: "Catania",
        code: "CT",
      },
      {
        name: "Catanzaro",
        code: "CZ",
      },
      {
        name: "Chieti",
        code: "CH",
      },
      {
        name: "Como",
        code: "CO",
      },
      {
        name: "Cosenza",
        code: "CS",
      },
      {
        name: "Cremona",
        code: "CR",
      },
      {
        name: "Crotone",
        code: "KR",
      },
      {
        name: "Cuneo",
        code: "CN",
      },
      {
        name: "Enna",
        code: "EN",
      },
      {
        name: "Fermo",
        code: "FM",
      },
      {
        name: "Ferrara",
        code: "FE",
      },
      {
        name: "Florence",
        code: "FI",
      },
      {
        name: "Foggia",
        code: "FG",
      },
      {
        name: "Forlì-Cesena",
        code: "FC",
      },
      {
        name: "Frosinone",
        code: "FR",
      },
      {
        name: "Genoa",
        code: "GE",
      },
      {
        name: "Gorizia",
        code: "GO",
      },
      {
        name: "Grosseto",
        code: "GR",
      },
      {
        name: "Imperia",
        code: "IM",
      },
      {
        name: "Isernia",
        code: "IS",
      },
      {
        name: "L’Aquila",
        code: "AQ",
      },
      {
        name: "La Spezia",
        code: "SP",
      },
      {
        name: "Latina",
        code: "LT",
      },
      {
        name: "Lecce",
        code: "LE",
      },
      {
        name: "Lecco",
        code: "LC",
      },
      {
        name: "Livorno",
        code: "LI",
      },
      {
        name: "Lodi",
        code: "LO",
      },
      {
        name: "Lucca",
        code: "LU",
      },
      {
        name: "Macerata",
        code: "MC",
      },
      {
        name: "Mantua",
        code: "MN",
      },
      {
        name: "Massa and Carrara",
        code: "MS",
      },
      {
        name: "Matera",
        code: "MT",
      },
      {
        name: "Medio Campidano",
        code: "VS",
      },
      {
        name: "Messina",
        code: "ME",
      },
      {
        name: "Milan",
        code: "MI",
      },
      {
        name: "Modena",
        code: "MO",
      },
      {
        name: "Monza and Brianza",
        code: "MB",
      },
      {
        name: "Naples",
        code: "NA",
      },
      {
        name: "Novara",
        code: "NO",
      },
      {
        name: "Nuoro",
        code: "NU",
      },
      {
        name: "Ogliastra",
        code: "OG",
      },
      {
        name: "Olbia-Tempio",
        code: "OT",
      },
      {
        name: "Oristano",
        code: "OR",
      },
      {
        name: "Padua",
        code: "PD",
      },
      {
        name: "Palermo",
        code: "PA",
      },
      {
        name: "Parma",
        code: "PR",
      },
      {
        name: "Pavia",
        code: "PV",
      },
      {
        name: "Perugia",
        code: "PG",
      },
      {
        name: "Pesaro and Urbino",
        code: "PU",
      },
      {
        name: "Pescara",
        code: "PE",
      },
      {
        name: "Piacenza",
        code: "PC",
      },
      {
        name: "Pisa",
        code: "PI",
      },
      {
        name: "Pistoia",
        code: "PT",
      },
      {
        name: "Pordenone",
        code: "PN",
      },
      {
        name: "Potenza",
        code: "PZ",
      },
      {
        name: "Prato",
        code: "PO",
      },
      {
        name: "Ragusa",
        code: "RG",
      },
      {
        name: "Ravenna",
        code: "RA",
      },
      {
        name: "Reggio Calabria",
        code: "RC",
      },
      {
        name: "Reggio Emilia",
        code: "RE",
      },
      {
        name: "Rieti",
        code: "RI",
      },
      {
        name: "Rimini",
        code: "RN",
      },
      {
        name: "Rome",
        code: "RM",
      },
      {
        name: "Rovigo",
        code: "RO",
      },
      {
        name: "Salerno",
        code: "SA",
      },
      {
        name: "Sassari",
        code: "SS",
      },
      {
        name: "Savona",
        code: "SV",
      },
      {
        name: "Siena",
        code: "SI",
      },
      {
        name: "Syracuse",
        code: "SR",
      },
      {
        name: "Sondrio",
        code: "SO",
      },
      {
        name: "Taranto",
        code: "TA",
      },
      {
        name: "Teramo",
        code: "TE",
      },
      {
        name: "Terni",
        code: "TR",
      },
      {
        name: "Turin",
        code: "TO",
      },
      {
        name: "Trapani",
        code: "TP",
      },
      {
        name: "Trentino",
        code: "TN",
      },
      {
        name: "Treviso",
        code: "TV",
      },
      {
        name: "Trieste",
        code: "TS",
      },
      {
        name: "Udine",
        code: "UD",
      },
      {
        name: "Varese",
        code: "VA",
      },
      {
        name: "Venice",
        code: "VE",
      },
      {
        name: "Verbano-Cusio-Ossola",
        code: "VB",
      },
      {
        name: "Vercelli",
        code: "VC",
      },
      {
        name: "Verona",
        code: "VR",
      },
      {
        name: "Vibo Valentia",
        code: "VV",
      },
      {
        name: "Vicenza",
        code: "VI",
      },
      {
        name: "Viterbo",
        code: "VT",
      },
    ],
  },
  {
    name: "Jamaica",
    code: "JM",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Japan",
    code: "JP",
    continent: "Asia",
    phoneNumberPrefix: 81,
    autocompletionField: "zip",
    provinceKey: "PREFECTURE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City/ward/town/village",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Prefecture",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{lastName}{firstName}_{company}_{zip}{province}_{city}_{address1}_{address2}_{phone}",
      show: "{country} 〒{zip}_{province} {city}_{address1}_{address2}_{company}_{lastName} {firstName}様_{phone}",
    },
    zones: [
      {
        name: "Hokkaido",
        code: "JP-01",
      },
      {
        name: "Aomori",
        code: "JP-02",
      },
      {
        name: "Iwate",
        code: "JP-03",
      },
      {
        name: "Miyagi",
        code: "JP-04",
      },
      {
        name: "Akita",
        code: "JP-05",
      },
      {
        name: "Yamagata",
        code: "JP-06",
      },
      {
        name: "Fukushima",
        code: "JP-07",
      },
      {
        name: "Ibaraki",
        code: "JP-08",
      },
      {
        name: "Tochigi",
        code: "JP-09",
      },
      {
        name: "Gunma",
        code: "JP-10",
      },
      {
        name: "Saitama",
        code: "JP-11",
      },
      {
        name: "Chiba",
        code: "JP-12",
      },
      {
        name: "Tokyo",
        code: "JP-13",
      },
      {
        name: "Kanagawa",
        code: "JP-14",
      },
      {
        name: "Niigata",
        code: "JP-15",
      },
      {
        name: "Toyama",
        code: "JP-16",
      },
      {
        name: "Ishikawa",
        code: "JP-17",
      },
      {
        name: "Fukui",
        code: "JP-18",
      },
      {
        name: "Yamanashi",
        code: "JP-19",
      },
      {
        name: "Nagano",
        code: "JP-20",
      },
      {
        name: "Gifu",
        code: "JP-21",
      },
      {
        name: "Shizuoka",
        code: "JP-22",
      },
      {
        name: "Aichi",
        code: "JP-23",
      },
      {
        name: "Mie",
        code: "JP-24",
      },
      {
        name: "Shiga",
        code: "JP-25",
      },
      {
        name: "Kyoto",
        code: "JP-26",
      },
      {
        name: "Osaka",
        code: "JP-27",
      },
      {
        name: "Hyogo",
        code: "JP-28",
      },
      {
        name: "Nara",
        code: "JP-29",
      },
      {
        name: "Wakayama",
        code: "JP-30",
      },
      {
        name: "Tottori",
        code: "JP-31",
      },
      {
        name: "Shimane",
        code: "JP-32",
      },
      {
        name: "Okayama",
        code: "JP-33",
      },
      {
        name: "Hiroshima",
        code: "JP-34",
      },
      {
        name: "Yamaguchi",
        code: "JP-35",
      },
      {
        name: "Tokushima",
        code: "JP-36",
      },
      {
        name: "Kagawa",
        code: "JP-37",
      },
      {
        name: "Ehime",
        code: "JP-38",
      },
      {
        name: "Kochi",
        code: "JP-39",
      },
      {
        name: "Fukuoka",
        code: "JP-40",
      },
      {
        name: "Saga",
        code: "JP-41",
      },
      {
        name: "Nagasaki",
        code: "JP-42",
      },
      {
        name: "Kumamoto",
        code: "JP-43",
      },
      {
        name: "Oita",
        code: "JP-44",
      },
      {
        name: "Miyazaki",
        code: "JP-45",
      },
      {
        name: "Kagoshima",
        code: "JP-46",
      },
      {
        name: "Okinawa",
        code: "JP-47",
      },
    ],
  },
  {
    name: "Jersey",
    code: "JE",
    continent: "Europe",
    phoneNumberPrefix: 44,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Jordan",
    code: "JO",
    continent: "Asia",
    phoneNumberPrefix: 962,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    continent: "Asia",
    phoneNumberPrefix: 7,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Kenya",
    code: "KE",
    continent: "Africa",
    phoneNumberPrefix: 254,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Kiribati",
    code: "KI",
    continent: "Oceania",
    phoneNumberPrefix: 686,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Kosovo",
    code: "XK",
    continent: "Europe",
    phoneNumberPrefix: 383,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Kuwait",
    code: "KW",
    continent: "Asia",
    phoneNumberPrefix: 965,
    autocompletionField: "address1",
    provinceKey: "GOVERNORATE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Governorate",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Al Ahmadi",
        code: "KW-AH",
      },
      {
        name: "Al Asimah",
        code: "KW-KU",
      },
      {
        name: "Al Farwaniyah",
        code: "KW-FA",
      },
      {
        name: "Al Jahra",
        code: "KW-JA",
      },
      {
        name: "Hawalli",
        code: "KW-HA",
      },
      {
        name: "Mubarak Al-Kabeer",
        code: "KW-MU",
      },
    ],
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    continent: "Asia",
    phoneNumberPrefix: 996,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{zip}{city}_{address2}_{address1}_{company}_{firstName}{lastName}_{phone}",
      show: "{zip} {city}_{address2}_{address1}_{company}_{firstName} {lastName}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Laos",
    code: "LA",
    continent: "Asia",
    phoneNumberPrefix: 856,
    autocompletionField: "address1",
    provinceKey: "PROVINCE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Latvia",
    code: "LV",
    continent: "Europe",
    phoneNumberPrefix: 371,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Lebanon",
    code: "LB",
    continent: "Asia",
    phoneNumberPrefix: 961,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Lesotho",
    code: "LS",
    continent: "Africa",
    phoneNumberPrefix: 266,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Liberia",
    code: "LR",
    continent: "Africa",
    phoneNumberPrefix: 231,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Libya",
    code: "LY",
    continent: "Africa",
    phoneNumberPrefix: 218,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Liechtenstein",
    code: "LI",
    continent: "Europe",
    phoneNumberPrefix: 423,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Lithuania",
    code: "LT",
    continent: "Europe",
    phoneNumberPrefix: 370,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Luxembourg",
    code: "LU",
    continent: "Europe",
    phoneNumberPrefix: 352,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Macao SAR",
    code: "MO",
    continent: "Asia",
    phoneNumberPrefix: 853,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "Area",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Madagascar",
    code: "MG",
    continent: "Africa",
    phoneNumberPrefix: 261,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Malawi",
    code: "MW",
    continent: "Africa",
    phoneNumberPrefix: 265,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Malaysia",
    code: "MY",
    continent: "Asia",
    phoneNumberPrefix: 60,
    autocompletionField: "address1",
    provinceKey: "STATE_AND_TERRITORY",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postcode",
      zone: "State/territory",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Johor",
        code: "JHR",
      },
      {
        name: "Kedah",
        code: "KDH",
      },
      {
        name: "Kelantan",
        code: "KTN",
      },
      {
        name: "Kuala Lumpur",
        code: "KUL",
      },
      {
        name: "Labuan",
        code: "LBN",
      },
      {
        name: "Malacca",
        code: "MLK",
      },
      {
        name: "Negeri Sembilan",
        code: "NSN",
      },
      {
        name: "Pahang",
        code: "PHG",
      },
      {
        name: "Penang",
        code: "PNG",
      },
      {
        name: "Perak",
        code: "PRK",
      },
      {
        name: "Perlis",
        code: "PLS",
      },
      {
        name: "Putrajaya",
        code: "PJY",
      },
      {
        name: "Sabah",
        code: "SBH",
      },
      {
        name: "Sarawak",
        code: "SWK",
      },
      {
        name: "Selangor",
        code: "SGR",
      },
      {
        name: "Terengganu",
        code: "TRG",
      },
    ],
  },
  {
    name: "Maldives",
    code: "MV",
    continent: "Asia",
    phoneNumberPrefix: 960,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Mali",
    code: "ML",
    continent: "Africa",
    phoneNumberPrefix: 223,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Malta",
    code: "MT",
    continent: "Europe",
    phoneNumberPrefix: 356,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Martinique",
    code: "MQ",
    continent: "North America",
    phoneNumberPrefix: 596,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Mauritania",
    code: "MR",
    continent: "Africa",
    phoneNumberPrefix: 222,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Mauritius",
    code: "MU",
    continent: "Africa",
    phoneNumberPrefix: 230,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Mayotte",
    code: "YT",
    continent: "Africa",
    phoneNumberPrefix: 262,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Mexico",
    code: "MX",
    continent: "North America",
    phoneNumberPrefix: 52,
    autocompletionField: "address1",
    provinceKey: "STATE",
    labels: {
      address1: "Street and house number",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "State",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Aguascalientes",
        code: "AGS",
      },
      {
        name: "Baja California",
        code: "BC",
      },
      {
        name: "Baja California Sur",
        code: "BCS",
      },
      {
        name: "Campeche",
        code: "CAMP",
      },
      {
        name: "Chiapas",
        code: "CHIS",
      },
      {
        name: "Chihuahua",
        code: "CHIH",
      },
      {
        name: "Ciudad de Mexico",
        code: "DF",
      },
      {
        name: "Coahuila",
        code: "COAH",
      },
      {
        name: "Colima",
        code: "COL",
      },
      {
        name: "Durango",
        code: "DGO",
      },
      {
        name: "Guanajuato",
        code: "GTO",
      },
      {
        name: "Guerrero",
        code: "GRO",
      },
      {
        name: "Hidalgo",
        code: "HGO",
      },
      {
        name: "Jalisco",
        code: "JAL",
      },
      {
        name: "Mexico State",
        code: "MEX",
      },
      {
        name: "Michoacán",
        code: "MICH",
      },
      {
        name: "Morelos",
        code: "MOR",
      },
      {
        name: "Nayarit",
        code: "NAY",
      },
      {
        name: "Nuevo León",
        code: "NL",
      },
      {
        name: "Oaxaca",
        code: "OAX",
      },
      {
        name: "Puebla",
        code: "PUE",
      },
      {
        name: "Querétaro",
        code: "QRO",
      },
      {
        name: "Quintana Roo",
        code: "Q ROO",
      },
      {
        name: "San Luis Potosí",
        code: "SLP",
      },
      {
        name: "Sinaloa",
        code: "SIN",
      },
      {
        name: "Sonora",
        code: "SON",
      },
      {
        name: "Tabasco",
        code: "TAB",
      },
      {
        name: "Tamaulipas",
        code: "TAMPS",
      },
      {
        name: "Tlaxcala",
        code: "TLAX",
      },
      {
        name: "Veracruz",
        code: "VER",
      },
      {
        name: "Yucatán",
        code: "YUC",
      },
      {
        name: "Zacatecas",
        code: "ZAC",
      },
    ],
  },
  {
    name: "Moldova",
    code: "MD",
    continent: "Europe",
    phoneNumberPrefix: 373,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Monaco",
    code: "MC",
    continent: "Europe",
    phoneNumberPrefix: 377,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "Ward",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Mongolia",
    code: "MN",
    continent: "Asia",
    phoneNumberPrefix: 976,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Montenegro",
    code: "ME",
    continent: "Europe",
    phoneNumberPrefix: 382,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Montserrat",
    code: "MS",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Morocco",
    code: "MA",
    continent: "Africa",
    phoneNumberPrefix: 212,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Mozambique",
    code: "MZ",
    continent: "Africa",
    phoneNumberPrefix: 258,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Myanmar (Burma)",
    code: "MM",
    continent: "Asia",
    phoneNumberPrefix: 95,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Namibia",
    code: "NA",
    continent: "Africa",
    phoneNumberPrefix: 264,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Nauru",
    code: "NR",
    continent: "Oceania",
    phoneNumberPrefix: 674,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Nepal",
    code: "NP",
    continent: "Asia",
    phoneNumberPrefix: 977,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Netherlands",
    code: "NL",
    continent: "Europe",
    phoneNumberPrefix: 31,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Street and house number",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "New Caledonia",
    code: "NC",
    continent: "Oceania",
    phoneNumberPrefix: 687,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "New Zealand",
    code: "NZ",
    continent: "Oceania",
    phoneNumberPrefix: 64,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Suburb",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Suburb (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{province}_{city} {zip}_{country}_{phone}",
    },
    zones: [
      {
        name: "Auckland",
        code: "AUK",
      },
      {
        name: "Bay of Plenty",
        code: "BOP",
      },
      {
        name: "Canterbury",
        code: "CAN",
      },
      {
        name: "Chatham Islands",
        code: "CIT",
      },
      {
        name: "Gisborne",
        code: "GIS",
      },
      {
        name: "Hawke’s Bay",
        code: "HKB",
      },
      {
        name: "Manawatū-Whanganui",
        code: "MWT",
      },
      {
        name: "Marlborough",
        code: "MBH",
      },
      {
        name: "Nelson",
        code: "NSN",
      },
      {
        name: "Northland",
        code: "NTL",
      },
      {
        name: "Otago",
        code: "OTA",
      },
      {
        name: "Southland",
        code: "STL",
      },
      {
        name: "Taranaki",
        code: "TKI",
      },
      {
        name: "Tasman",
        code: "TAS",
      },
      {
        name: "Waikato",
        code: "WKO",
      },
      {
        name: "Wellington",
        code: "WGN",
      },
      {
        name: "West Coast",
        code: "WTC",
      },
    ],
  },
  {
    name: "Nicaragua",
    code: "NI",
    continent: "North America",
    phoneNumberPrefix: 505,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Niger",
    code: "NE",
    continent: "Africa",
    phoneNumberPrefix: 227,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Nigeria",
    code: "NG",
    continent: "Africa",
    phoneNumberPrefix: 234,
    autocompletionField: "address1",
    provinceKey: "STATE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "State",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Abia",
        code: "AB",
      },
      {
        name: "Federal Capital Territory",
        code: "FC",
      },
      {
        name: "Adamawa",
        code: "AD",
      },
      {
        name: "Akwa Ibom",
        code: "AK",
      },
      {
        name: "Anambra",
        code: "AN",
      },
      {
        name: "Bauchi",
        code: "BA",
      },
      {
        name: "Bayelsa",
        code: "BY",
      },
      {
        name: "Benue",
        code: "BE",
      },
      {
        name: "Borno",
        code: "BO",
      },
      {
        name: "Cross River",
        code: "CR",
      },
      {
        name: "Delta",
        code: "DE",
      },
      {
        name: "Ebonyi",
        code: "EB",
      },
      {
        name: "Edo",
        code: "ED",
      },
      {
        name: "Ekiti",
        code: "EK",
      },
      {
        name: "Enugu",
        code: "EN",
      },
      {
        name: "Gombe",
        code: "GO",
      },
      {
        name: "Imo",
        code: "IM",
      },
      {
        name: "Jigawa",
        code: "JI",
      },
      {
        name: "Kaduna",
        code: "KD",
      },
      {
        name: "Kano",
        code: "KN",
      },
      {
        name: "Katsina",
        code: "KT",
      },
      {
        name: "Kebbi",
        code: "KE",
      },
      {
        name: "Kogi",
        code: "KO",
      },
      {
        name: "Kwara",
        code: "KW",
      },
      {
        name: "Lagos",
        code: "LA",
      },
      {
        name: "Nasarawa",
        code: "NA",
      },
      {
        name: "Niger",
        code: "NI",
      },
      {
        name: "Ogun",
        code: "OG",
      },
      {
        name: "Ondo",
        code: "ON",
      },
      {
        name: "Osun",
        code: "OS",
      },
      {
        name: "Oyo",
        code: "OY",
      },
      {
        name: "Plateau",
        code: "PL",
      },
      {
        name: "Rivers",
        code: "RI",
      },
      {
        name: "Sokoto",
        code: "SO",
      },
      {
        name: "Taraba",
        code: "TA",
      },
      {
        name: "Yobe",
        code: "YO",
      },
      {
        name: "Zamfara",
        code: "ZA",
      },
    ],
  },
  {
    name: "Niue",
    code: "NU",
    continent: "Oceania",
    phoneNumberPrefix: 683,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Norfolk Island",
    code: "NF",
    continent: "Oceania",
    phoneNumberPrefix: 672,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country} {zip}_{phone}",
    },
    zones: [],
  },
  {
    name: "North Macedonia",
    code: "MK",
    continent: "Europe",
    phoneNumberPrefix: 389,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Norway",
    code: "NO",
    continent: "Europe",
    phoneNumberPrefix: 47,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Street and house number",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Oman",
    code: "OM",
    continent: "Asia",
    phoneNumberPrefix: 968,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Pakistan",
    code: "PK",
    continent: "Asia",
    phoneNumberPrefix: 92,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Palestinian Territories",
    code: "PS",
    continent: "Asia",
    phoneNumberPrefix: 970,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Panama",
    code: "PA",
    continent: "North America",
    phoneNumberPrefix: 507,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Bocas del Toro",
        code: "PA-1",
      },
      {
        name: "Chiriquí",
        code: "PA-4",
      },
      {
        name: "Coclé",
        code: "PA-2",
      },
      {
        name: "Colón",
        code: "PA-3",
      },
      {
        name: "Darién",
        code: "PA-5",
      },
      {
        name: "Emberá",
        code: "PA-EM",
      },
      {
        name: "Herrera",
        code: "PA-6",
      },
      {
        name: "Guna Yala",
        code: "PA-KY",
      },
      {
        name: "Los Santos",
        code: "PA-7",
      },
      {
        name: "Ngöbe-Buglé",
        code: "PA-NB",
      },
      {
        name: "Panamá",
        code: "PA-8",
      },
      {
        name: "West Panamá",
        code: "PA-10",
      },
      {
        name: "Veraguas",
        code: "PA-9",
      },
    ],
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    continent: "Oceania",
    phoneNumberPrefix: 675,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Paraguay",
    code: "PY",
    continent: "South America",
    phoneNumberPrefix: 595,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Peru",
    code: "PE",
    continent: "South America",
    phoneNumberPrefix: 51,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Amazonas",
        code: "PE-AMA",
      },
      {
        name: "Ancash",
        code: "PE-ANC",
      },
      {
        name: "Apurímac",
        code: "PE-APU",
      },
      {
        name: "Arequipa",
        code: "PE-ARE",
      },
      {
        name: "Ayacucho",
        code: "PE-AYA",
      },
      {
        name: "Cajamarca",
        code: "PE-CAJ",
      },
      {
        name: "El Callao",
        code: "PE-CAL",
      },
      {
        name: "Cusco",
        code: "PE-CUS",
      },
      {
        name: "Huancavelica",
        code: "PE-HUV",
      },
      {
        name: "Huánuco",
        code: "PE-HUC",
      },
      {
        name: "Ica",
        code: "PE-ICA",
      },
      {
        name: "Junín",
        code: "PE-JUN",
      },
      {
        name: "La Libertad",
        code: "PE-LAL",
      },
      {
        name: "Lambayeque",
        code: "PE-LAM",
      },
      {
        name: "Lima (Department)",
        code: "PE-LIM",
      },
      {
        name: "Lima (Metropolitan)",
        code: "PE-LMA",
      },
      {
        name: "Loreto",
        code: "PE-LOR",
      },
      {
        name: "Madre de Dios",
        code: "PE-MDD",
      },
      {
        name: "Moquegua",
        code: "PE-MOQ",
      },
      {
        name: "Pasco",
        code: "PE-PAS",
      },
      {
        name: "Piura",
        code: "PE-PIU",
      },
      {
        name: "Puno",
        code: "PE-PUN",
      },
      {
        name: "San Martín",
        code: "PE-SAM",
      },
      {
        name: "Tacna",
        code: "PE-TAC",
      },
      {
        name: "Tumbes",
        code: "PE-TUM",
      },
      {
        name: "Ucayali",
        code: "PE-UCA",
      },
    ],
  },
  {
    name: "Philippines",
    code: "PH",
    continent: "Asia",
    phoneNumberPrefix: 63,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Abra",
        code: "PH-ABR",
      },
      {
        name: "Agusan del Norte",
        code: "PH-AGN",
      },
      {
        name: "Agusan del Sur",
        code: "PH-AGS",
      },
      {
        name: "Aklan",
        code: "PH-AKL",
      },
      {
        name: "Albay",
        code: "PH-ALB",
      },
      {
        name: "Antique",
        code: "PH-ANT",
      },
      {
        name: "Apayao",
        code: "PH-APA",
      },
      {
        name: "Aurora",
        code: "PH-AUR",
      },
      {
        name: "Basilan",
        code: "PH-BAS",
      },
      {
        name: "Bataan",
        code: "PH-BAN",
      },
      {
        name: "Batanes",
        code: "PH-BTN",
      },
      {
        name: "Batangas",
        code: "PH-BTG",
      },
      {
        name: "Benguet",
        code: "PH-BEN",
      },
      {
        name: "Biliran",
        code: "PH-BIL",
      },
      {
        name: "Bohol",
        code: "PH-BOH",
      },
      {
        name: "Bukidnon",
        code: "PH-BUK",
      },
      {
        name: "Bulacan",
        code: "PH-BUL",
      },
      {
        name: "Cagayan",
        code: "PH-CAG",
      },
      {
        name: "Camarines Norte",
        code: "PH-CAN",
      },
      {
        name: "Camarines Sur",
        code: "PH-CAS",
      },
      {
        name: "Camiguin",
        code: "PH-CAM",
      },
      {
        name: "Capiz",
        code: "PH-CAP",
      },
      {
        name: "Catanduanes",
        code: "PH-CAT",
      },
      {
        name: "Cavite",
        code: "PH-CAV",
      },
      {
        name: "Cebu",
        code: "PH-CEB",
      },
      {
        name: "Cotabato",
        code: "PH-NCO",
      },
      {
        name: "Davao Occidental",
        code: "PH-DVO",
      },
      {
        name: "Davao Oriental",
        code: "PH-DAO",
      },
      {
        name: "Compostela Valley",
        code: "PH-COM",
      },
      {
        name: "Davao del Norte",
        code: "PH-DAV",
      },
      {
        name: "Davao del Sur",
        code: "PH-DAS",
      },
      {
        name: "Dinagat Islands",
        code: "PH-DIN",
      },
      {
        name: "Eastern Samar",
        code: "PH-EAS",
      },
      {
        name: "Guimaras",
        code: "PH-GUI",
      },
      {
        name: "Ifugao",
        code: "PH-IFU",
      },
      {
        name: "Ilocos Norte",
        code: "PH-ILN",
      },
      {
        name: "Ilocos Sur",
        code: "PH-ILS",
      },
      {
        name: "Iloilo",
        code: "PH-ILI",
      },
      {
        name: "Isabela",
        code: "PH-ISA",
      },
      {
        name: "Kalinga",
        code: "PH-KAL",
      },
      {
        name: "La Union",
        code: "PH-LUN",
      },
      {
        name: "Laguna",
        code: "PH-LAG",
      },
      {
        name: "Lanao del Norte",
        code: "PH-LAN",
      },
      {
        name: "Lanao del Sur",
        code: "PH-LAS",
      },
      {
        name: "Leyte",
        code: "PH-LEY",
      },
      {
        name: "Maguindanao",
        code: "PH-MAG",
      },
      {
        name: "Marinduque",
        code: "PH-MAD",
      },
      {
        name: "Masbate",
        code: "PH-MAS",
      },
      {
        name: "Metro Manila",
        code: "PH-00",
      },
      {
        name: "Misamis Occidental",
        code: "PH-MSC",
      },
      {
        name: "Misamis Oriental",
        code: "PH-MSR",
      },
      {
        name: "Mountain",
        code: "PH-MOU",
      },
      {
        name: "Negros Occidental",
        code: "PH-NEC",
      },
      {
        name: "Negros Oriental",
        code: "PH-NER",
      },
      {
        name: "Northern Samar",
        code: "PH-NSA",
      },
      {
        name: "Nueva Ecija",
        code: "PH-NUE",
      },
      {
        name: "Nueva Vizcaya",
        code: "PH-NUV",
      },
      {
        name: "Occidental Mindoro",
        code: "PH-MDC",
      },
      {
        name: "Oriental Mindoro",
        code: "PH-MDR",
      },
      {
        name: "Palawan",
        code: "PH-PLW",
      },
      {
        name: "Pampanga",
        code: "PH-PAM",
      },
      {
        name: "Pangasinan",
        code: "PH-PAN",
      },
      {
        name: "Quezon",
        code: "PH-QUE",
      },
      {
        name: "Quirino",
        code: "PH-QUI",
      },
      {
        name: "Rizal",
        code: "PH-RIZ",
      },
      {
        name: "Romblon",
        code: "PH-ROM",
      },
      {
        name: "Samar",
        code: "PH-WSA",
      },
      {
        name: "Sarangani",
        code: "PH-SAR",
      },
      {
        name: "Siquijor",
        code: "PH-SIG",
      },
      {
        name: "Sorsogon",
        code: "PH-SOR",
      },
      {
        name: "South Cotabato",
        code: "PH-SCO",
      },
      {
        name: "Southern Leyte",
        code: "PH-SLE",
      },
      {
        name: "Sultan Kudarat",
        code: "PH-SUK",
      },
      {
        name: "Sulu",
        code: "PH-SLU",
      },
      {
        name: "Surigao del Norte",
        code: "PH-SUN",
      },
      {
        name: "Surigao del Sur",
        code: "PH-SUR",
      },
      {
        name: "Tarlac",
        code: "PH-TAR",
      },
      {
        name: "Tawi-Tawi",
        code: "PH-TAW",
      },
      {
        name: "Zambales",
        code: "PH-ZMB",
      },
      {
        name: "Zamboanga Sibugay",
        code: "PH-ZSI",
      },
      {
        name: "Zamboanga del Norte",
        code: "PH-ZAN",
      },
      {
        name: "Zamboanga del Sur",
        code: "PH-ZAS",
      },
    ],
  },
  {
    name: "Pitcairn Islands",
    code: "PN",
    continent: "Oceania",
    phoneNumberPrefix: 64,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Poland",
    code: "PL",
    continent: "Europe",
    phoneNumberPrefix: 48,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Portugal",
    code: "PT",
    continent: "Europe",
    phoneNumberPrefix: 351,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Azores",
        code: "PT-20",
      },
      {
        name: "Aveiro",
        code: "PT-01",
      },
      {
        name: "Beja",
        code: "PT-02",
      },
      {
        name: "Braga",
        code: "PT-03",
      },
      {
        name: "Bragança",
        code: "PT-04",
      },
      {
        name: "Castelo Branco",
        code: "PT-05",
      },
      {
        name: "Coimbra",
        code: "PT-06",
      },
      {
        name: "Évora",
        code: "PT-07",
      },
      {
        name: "Faro",
        code: "PT-08",
      },
      {
        name: "Guarda",
        code: "PT-09",
      },
      {
        name: "Leiria",
        code: "PT-10",
      },
      {
        name: "Lisbon",
        code: "PT-11",
      },
      {
        name: "Madeira",
        code: "PT-30",
      },
      {
        name: "Portalegre",
        code: "PT-12",
      },
      {
        name: "Porto",
        code: "PT-13",
      },
      {
        name: "Santarém",
        code: "PT-14",
      },
      {
        name: "Setúbal",
        code: "PT-15",
      },
      {
        name: "Viana do Castelo",
        code: "PT-16",
      },
      {
        name: "Vila Real",
        code: "PT-17",
      },
      {
        name: "Viseu",
        code: "PT-18",
      },
    ],
  },
  {
    name: "Qatar",
    code: "QA",
    continent: "Asia",
    phoneNumberPrefix: 974,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Réunion",
    code: "RE",
    continent: "Africa",
    phoneNumberPrefix: 262,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Romania",
    code: "RO",
    continent: "Europe",
    phoneNumberPrefix: 40,
    autocompletionField: "address1",
    provinceKey: "COUNTY",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "County",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Alba",
        code: "AB",
      },
      {
        name: "Arad",
        code: "AR",
      },
      {
        name: "Argeș",
        code: "AG",
      },
      {
        name: "Bacău",
        code: "BC",
      },
      {
        name: "Bihor",
        code: "BH",
      },
      {
        name: "Bistriţa-Năsăud",
        code: "BN",
      },
      {
        name: "Botoşani",
        code: "BT",
      },
      {
        name: "Brăila",
        code: "BR",
      },
      {
        name: "Braşov",
        code: "BV",
      },
      {
        name: "Bucharest",
        code: "B",
      },
      {
        name: "Buzău",
        code: "BZ",
      },
      {
        name: "Caraș-Severin",
        code: "CS",
      },
      {
        name: "Cluj",
        code: "CJ",
      },
      {
        name: "Constanța",
        code: "CT",
      },
      {
        name: "Covasna",
        code: "CV",
      },
      {
        name: "Călărași",
        code: "CL",
      },
      {
        name: "Dolj",
        code: "DJ",
      },
      {
        name: "Dâmbovița",
        code: "DB",
      },
      {
        name: "Galați",
        code: "GL",
      },
      {
        name: "Giurgiu",
        code: "GR",
      },
      {
        name: "Gorj",
        code: "GJ",
      },
      {
        name: "Harghita",
        code: "HR",
      },
      {
        name: "Hunedoara",
        code: "HD",
      },
      {
        name: "Ialomița",
        code: "IL",
      },
      {
        name: "Iași",
        code: "IS",
      },
      {
        name: "Ilfov",
        code: "IF",
      },
      {
        name: "Maramureş",
        code: "MM",
      },
      {
        name: "Mehedinți",
        code: "MH",
      },
      {
        name: "Mureş",
        code: "MS",
      },
      {
        name: "Neamţ",
        code: "NT",
      },
      {
        name: "Olt",
        code: "OT",
      },
      {
        name: "Prahova",
        code: "PH",
      },
      {
        name: "Sălaj",
        code: "SJ",
      },
      {
        name: "Satu Mare",
        code: "SM",
      },
      {
        name: "Sibiu",
        code: "SB",
      },
      {
        name: "Suceava",
        code: "SV",
      },
      {
        name: "Teleorman",
        code: "TR",
      },
      {
        name: "Timiș",
        code: "TM",
      },
      {
        name: "Tulcea",
        code: "TL",
      },
      {
        name: "Vâlcea",
        code: "VL",
      },
      {
        name: "Vaslui",
        code: "VS",
      },
      {
        name: "Vrancea",
        code: "VN",
      },
    ],
  },
  {
    name: "Russia",
    code: "RU",
    continent: "Europe",
    phoneNumberPrefix: 7,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province}_{zip}_{country}_{phone}",
    },
    zones: [
      {
        name: "Altai Krai",
        code: "ALT",
      },
      {
        name: "Altai",
        code: "AL",
      },
      {
        name: "Amur",
        code: "AMU",
      },
      {
        name: "Arkhangelsk",
        code: "ARK",
      },
      {
        name: "Astrakhan",
        code: "AST",
      },
      {
        name: "Belgorod",
        code: "BEL",
      },
      {
        name: "Bryansk",
        code: "BRY",
      },
      {
        name: "Chechen",
        code: "CE",
      },
      {
        name: "Chelyabinsk",
        code: "CHE",
      },
      {
        name: "Chukotka Okrug",
        code: "CHU",
      },
      {
        name: "Chuvash",
        code: "CU",
      },
      {
        name: "Irkutsk",
        code: "IRK",
      },
      {
        name: "Ivanovo",
        code: "IVA",
      },
      {
        name: "Jewish",
        code: "YEV",
      },
      {
        name: "Kabardino-Balkar",
        code: "KB",
      },
      {
        name: "Kaliningrad",
        code: "KGD",
      },
      {
        name: "Kaluga",
        code: "KLU",
      },
      {
        name: "Kamchatka Krai",
        code: "KAM",
      },
      {
        name: "Karachay-Cherkess",
        code: "KC",
      },
      {
        name: "Kemerovo",
        code: "KEM",
      },
      {
        name: "Khabarovsk Krai",
        code: "KHA",
      },
      {
        name: "Khanty-Mansi",
        code: "KHM",
      },
      {
        name: "Kirov",
        code: "KIR",
      },
      {
        name: "Komi",
        code: "KO",
      },
      {
        name: "Kostroma",
        code: "KOS",
      },
      {
        name: "Krasnodar Krai",
        code: "KDA",
      },
      {
        name: "Krasnoyarsk Krai",
        code: "KYA",
      },
      {
        name: "Kurgan",
        code: "KGN",
      },
      {
        name: "Kursk",
        code: "KRS",
      },
      {
        name: "Leningrad",
        code: "LEN",
      },
      {
        name: "Lipetsk",
        code: "LIP",
      },
      {
        name: "Magadan",
        code: "MAG",
      },
      {
        name: "Mari El",
        code: "ME",
      },
      {
        name: "Moscow",
        code: "MOW",
      },
      {
        name: "Moscow Province",
        code: "MOS",
      },
      {
        name: "Murmansk",
        code: "MUR",
      },
      {
        name: "Nizhny Novgorod",
        code: "NIZ",
      },
      {
        name: "Novgorod",
        code: "NGR",
      },
      {
        name: "Novosibirsk",
        code: "NVS",
      },
      {
        name: "Omsk",
        code: "OMS",
      },
      {
        name: "Orenburg",
        code: "ORE",
      },
      {
        name: "Oryol",
        code: "ORL",
      },
      {
        name: "Penza",
        code: "PNZ",
      },
      {
        name: "Perm Krai",
        code: "PER",
      },
      {
        name: "Primorsky Krai",
        code: "PRI",
      },
      {
        name: "Pskov",
        code: "PSK",
      },
      {
        name: "Adygea",
        code: "AD",
      },
      {
        name: "Bashkortostan",
        code: "BA",
      },
      {
        name: "Buryat",
        code: "BU",
      },
      {
        name: "Dagestan",
        code: "DA",
      },
      {
        name: "Ingushetia",
        code: "IN",
      },
      {
        name: "Kalmykia",
        code: "KL",
      },
      {
        name: "Karelia",
        code: "KR",
      },
      {
        name: "Khakassia",
        code: "KK",
      },
      {
        name: "Mordovia",
        code: "MO",
      },
      {
        name: "North Ossetia-Alania",
        code: "SE",
      },
      {
        name: "Tatarstan",
        code: "TA",
      },
      {
        name: "Rostov",
        code: "ROS",
      },
      {
        name: "Ryazan",
        code: "RYA",
      },
      {
        name: "Saint Petersburg",
        code: "SPE",
      },
      {
        name: "Sakha",
        code: "SA",
      },
      {
        name: "Sakhalin",
        code: "SAK",
      },
      {
        name: "Samara",
        code: "SAM",
      },
      {
        name: "Saratov",
        code: "SAR",
      },
      {
        name: "Smolensk",
        code: "SMO",
      },
      {
        name: "Stavropol Krai",
        code: "STA",
      },
      {
        name: "Sverdlovsk",
        code: "SVE",
      },
      {
        name: "Tambov",
        code: "TAM",
      },
      {
        name: "Tomsk",
        code: "TOM",
      },
      {
        name: "Tula",
        code: "TUL",
      },
      {
        name: "Tver",
        code: "TVE",
      },
      {
        name: "Tyumen",
        code: "TYU",
      },
      {
        name: "Tuva",
        code: "TY",
      },
      {
        name: "Udmurt",
        code: "UD",
      },
      {
        name: "Ulyanovsk",
        code: "ULY",
      },
      {
        name: "Vladimir",
        code: "VLA",
      },
      {
        name: "Volgograd",
        code: "VGG",
      },
      {
        name: "Vologda",
        code: "VLG",
      },
      {
        name: "Voronezh",
        code: "VOR",
      },
      {
        name: "Yamalo-Nenets Okrug",
        code: "YAN",
      },
      {
        name: "Yaroslavl",
        code: "YAR",
      },
      {
        name: "Zabaykalsky Krai",
        code: "ZAB",
      },
    ],
  },
  {
    name: "Rwanda",
    code: "RW",
    continent: "Africa",
    phoneNumberPrefix: 250,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Samoa",
    code: "WS",
    continent: "Oceania",
    phoneNumberPrefix: 685,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "San Marino",
    code: "SM",
    continent: "Europe",
    phoneNumberPrefix: 378,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "São Tomé \u0026 Príncipe",
    code: "ST",
    continent: "Africa",
    phoneNumberPrefix: 239,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    continent: "Asia",
    phoneNumberPrefix: 966,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Senegal",
    code: "SN",
    continent: "Africa",
    phoneNumberPrefix: 221,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Serbia",
    code: "RS",
    continent: "Europe",
    phoneNumberPrefix: 381,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Seychelles",
    code: "SC",
    continent: "Africa",
    phoneNumberPrefix: 248,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Sierra Leone",
    code: "SL",
    continent: "Africa",
    phoneNumberPrefix: 232,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Singapore",
    code: "SG",
    continent: "Asia",
    phoneNumberPrefix: 65,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{country} {zip}_{phone}",
    },
    zones: [],
  },
  {
    name: "Sint Maarten",
    code: "SX",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Slovakia",
    code: "SK",
    continent: "Europe",
    phoneNumberPrefix: 421,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Slovenia",
    code: "SI",
    continent: "Europe",
    phoneNumberPrefix: 386,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Solomon Islands",
    code: "SB",
    continent: "Oceania",
    phoneNumberPrefix: 677,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Somalia",
    code: "SO",
    continent: "Africa",
    phoneNumberPrefix: 252,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "South Africa",
    code: "ZA",
    continent: "Africa",
    phoneNumberPrefix: 27,
    autocompletionField: "address1",
    provinceKey: "PROVINCE",
    labels: {
      address1: "Address",
      address2: "Suburb",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Province",
    },
    optionalLabels: {
      address2: "Suburb (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province}_{zip}_{country}_{phone}",
    },
    zones: [
      {
        name: "Eastern Cape",
        code: "EC",
      },
      {
        name: "Free State",
        code: "FS",
      },
      {
        name: "Gauteng",
        code: "GP",
      },
      {
        name: "KwaZulu-Natal",
        code: "NL",
      },
      {
        name: "Limpopo",
        code: "LP",
      },
      {
        name: "Mpumalanga",
        code: "MP",
      },
      {
        name: "North West",
        code: "NW",
      },
      {
        name: "Northern Cape",
        code: "NC",
      },
      {
        name: "Western Cape",
        code: "WC",
      },
    ],
  },
  {
    name: "South Georgia \u0026 South Sandwich Islands",
    code: "GS",
    continent: "South America",
    phoneNumberPrefix: 500,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "South Korea",
    code: "KR",
    continent: "Asia",
    phoneNumberPrefix: 82,
    autocompletionField: "zip",
    provinceKey: "PROVINCE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Province",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{company}_{lastName}{firstName}_{zip}_{province}{city}_{address1}_{address2}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Busan",
        code: "KR-26",
      },
      {
        name: "North Chungcheong",
        code: "KR-43",
      },
      {
        name: "South Chungcheong",
        code: "KR-44",
      },
      {
        name: "Daegu",
        code: "KR-27",
      },
      {
        name: "Daejeon",
        code: "KR-30",
      },
      {
        name: "Gangwon",
        code: "KR-42",
      },
      {
        name: "Gwangju City",
        code: "KR-29",
      },
      {
        name: "North Gyeongsang",
        code: "KR-47",
      },
      {
        name: "Gyeonggi",
        code: "KR-41",
      },
      {
        name: "South Gyeongsang",
        code: "KR-48",
      },
      {
        name: "Incheon",
        code: "KR-28",
      },
      {
        name: "Jeju",
        code: "KR-49",
      },
      {
        name: "North Jeolla",
        code: "KR-45",
      },
      {
        name: "South Jeolla",
        code: "KR-46",
      },
      {
        name: "Sejong",
        code: "KR-50",
      },
      {
        name: "Seoul",
        code: "KR-11",
      },
      {
        name: "Ulsan",
        code: "KR-31",
      },
    ],
  },
  {
    name: "South Sudan",
    code: "SS",
    continent: "Africa",
    phoneNumberPrefix: 211,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Spain",
    code: "ES",
    continent: "Europe",
    phoneNumberPrefix: 34,
    autocompletionField: "address1",
    provinceKey: "PROVINCE",
    labels: {
      address1: "Street and house number",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Province",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
    },
    zones: [
      {
        name: "A Coruña",
        code: "C",
      },
      {
        name: "Álava",
        code: "VI",
      },
      {
        name: "Albacete",
        code: "AB",
      },
      {
        name: "Alicante",
        code: "A",
      },
      {
        name: "Almería",
        code: "AL",
      },
      {
        name: "Asturias Province",
        code: "O",
      },
      {
        name: "Ávila",
        code: "AV",
      },
      {
        name: "Badajoz",
        code: "BA",
      },
      {
        name: "Balears Province",
        code: "PM",
      },
      {
        name: "Barcelona",
        code: "B",
      },
      {
        name: "Burgos",
        code: "BU",
      },
      {
        name: "Cáceres",
        code: "CC",
      },
      {
        name: "Cádiz",
        code: "CA",
      },
      {
        name: "Cantabria Province",
        code: "S",
      },
      {
        name: "Castellón",
        code: "CS",
      },
      {
        name: "Ceuta",
        code: "CE",
      },
      {
        name: "Ciudad Real",
        code: "CR",
      },
      {
        name: "Córdoba",
        code: "CO",
      },
      {
        name: "Cuenca",
        code: "CU",
      },
      {
        name: "Girona",
        code: "GI",
      },
      {
        name: "Granada",
        code: "GR",
      },
      {
        name: "Guadalajara",
        code: "GU",
      },
      {
        name: "Gipuzkoa",
        code: "SS",
      },
      {
        name: "Huelva",
        code: "H",
      },
      {
        name: "Huesca",
        code: "HU",
      },
      {
        name: "Jaén",
        code: "J",
      },
      {
        name: "La Rioja Province",
        code: "LO",
      },
      {
        name: "Las Palmas",
        code: "GC",
      },
      {
        name: "León",
        code: "LE",
      },
      {
        name: "Lleida",
        code: "L",
      },
      {
        name: "Lugo",
        code: "LU",
      },
      {
        name: "Madrid Province",
        code: "M",
      },
      {
        name: "Málaga",
        code: "MA",
      },
      {
        name: "Melilla",
        code: "ML",
      },
      {
        name: "Murcia",
        code: "MU",
      },
      {
        name: "Navarra",
        code: "NA",
      },
      {
        name: "Ourense",
        code: "OR",
      },
      {
        name: "Palencia",
        code: "P",
      },
      {
        name: "Pontevedra",
        code: "PO",
      },
      {
        name: "Salamanca",
        code: "SA",
      },
      {
        name: "Santa Cruz de Tenerife",
        code: "TF",
      },
      {
        name: "Segovia",
        code: "SG",
      },
      {
        name: "Seville",
        code: "SE",
      },
      {
        name: "Soria",
        code: "SO",
      },
      {
        name: "Tarragona",
        code: "T",
      },
      {
        name: "Teruel",
        code: "TE",
      },
      {
        name: "Toledo",
        code: "TO",
      },
      {
        name: "Valencia",
        code: "V",
      },
      {
        name: "Valladolid",
        code: "VA",
      },
      {
        name: "Biscay",
        code: "BI",
      },
      {
        name: "Zamora",
        code: "ZA",
      },
      {
        name: "Zaragoza",
        code: "Z",
      },
    ],
  },
  {
    name: "Sri Lanka",
    code: "LK",
    continent: "Asia",
    phoneNumberPrefix: 94,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "St. Barthélemy",
    code: "BL",
    continent: "North America",
    phoneNumberPrefix: 590,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "St. Helena",
    code: "SH",
    continent: "Africa",
    phoneNumberPrefix: 290,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "St. Kitts \u0026 Nevis",
    code: "KN",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "St. Lucia",
    code: "LC",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "St. Martin",
    code: "MF",
    continent: "North America",
    phoneNumberPrefix: 590,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "St. Pierre \u0026 Miquelon",
    code: "PM",
    continent: "North America",
    phoneNumberPrefix: 508,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "St. Vincent \u0026 Grenadines",
    code: "VC",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Sudan",
    code: "SD",
    continent: "Africa",
    phoneNumberPrefix: 249,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Suriname",
    code: "SR",
    continent: "South America",
    phoneNumberPrefix: 597,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Svalbard \u0026 Jan Mayen",
    code: "SJ",
    continent: "Europe",
    phoneNumberPrefix: 47,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Sweden",
    code: "SE",
    continent: "Europe",
    phoneNumberPrefix: 46,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Street and house number",
      address2: "Apartment, suite, etc.",
      city: "City/town",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Switzerland",
    code: "CH",
    continent: "Europe",
    phoneNumberPrefix: 41,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Street and house number",
      address2: "Additional address",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Additional address (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Taiwan",
    code: "TW",
    continent: "Asia",
    phoneNumberPrefix: 886,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Tajikistan",
    code: "TJ",
    continent: "Asia",
    phoneNumberPrefix: 992,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Tanzania",
    code: "TZ",
    continent: "Africa",
    phoneNumberPrefix: 255,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Thailand",
    code: "TH",
    continent: "Asia",
    phoneNumberPrefix: 66,
    autocompletionField: "address1",
    provinceKey: "PROVINCE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Province",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province} {zip}_{country}_{phone}",
    },
    zones: [
      {
        name: "Amnat Charoen",
        code: "TH-37",
      },
      {
        name: "Ang Thong",
        code: "TH-15",
      },
      {
        name: "Bangkok",
        code: "TH-10",
      },
      {
        name: "Bueng Kan",
        code: "TH-38",
      },
      {
        name: "Buri Ram",
        code: "TH-31",
      },
      {
        name: "Chachoengsao",
        code: "TH-24",
      },
      {
        name: "Chai Nat",
        code: "TH-18",
      },
      {
        name: "Chaiyaphum",
        code: "TH-36",
      },
      {
        name: "Chanthaburi",
        code: "TH-22",
      },
      {
        name: "Chiang Mai",
        code: "TH-50",
      },
      {
        name: "Chiang Rai",
        code: "TH-57",
      },
      {
        name: "Chon Buri",
        code: "TH-20",
      },
      {
        name: "Chumphon",
        code: "TH-86",
      },
      {
        name: "Kalasin",
        code: "TH-46",
      },
      {
        name: "Kamphaeng Phet",
        code: "TH-62",
      },
      {
        name: "Kanchanaburi",
        code: "TH-71",
      },
      {
        name: "Khon Kaen",
        code: "TH-40",
      },
      {
        name: "Krabi",
        code: "TH-81",
      },
      {
        name: "Lampang",
        code: "TH-52",
      },
      {
        name: "Lamphun",
        code: "TH-51",
      },
      {
        name: "Loei",
        code: "TH-42",
      },
      {
        name: "Lopburi",
        code: "TH-16",
      },
      {
        name: "Mae Hong Son",
        code: "TH-58",
      },
      {
        name: "Maha Sarakham",
        code: "TH-44",
      },
      {
        name: "Mukdahan",
        code: "TH-49",
      },
      {
        name: "Nakhon Nayok",
        code: "TH-26",
      },
      {
        name: "Nakhon Pathom",
        code: "TH-73",
      },
      {
        name: "Nakhon Phanom",
        code: "TH-48",
      },
      {
        name: "Nakhon Ratchasima",
        code: "TH-30",
      },
      {
        name: "Nakhon Sawan",
        code: "TH-60",
      },
      {
        name: "Nakhon Si Thammarat",
        code: "TH-80",
      },
      {
        name: "Nan",
        code: "TH-55",
      },
      {
        name: "Narathiwat",
        code: "TH-96",
      },
      {
        name: "Nong Bua Lam Phu",
        code: "TH-39",
      },
      {
        name: "Nong Khai",
        code: "TH-43",
      },
      {
        name: "Nonthaburi",
        code: "TH-12",
      },
      {
        name: "Pathum Thani",
        code: "TH-13",
      },
      {
        name: "Pattani",
        code: "TH-94",
      },
      {
        name: "Pattaya",
        code: "TH-S",
      },
      {
        name: "Phang Nga",
        code: "TH-82",
      },
      {
        name: "Phatthalung",
        code: "TH-93",
      },
      {
        name: "Phayao",
        code: "TH-56",
      },
      {
        name: "Phetchabun",
        code: "TH-67",
      },
      {
        name: "Phetchaburi",
        code: "TH-76",
      },
      {
        name: "Phichit",
        code: "TH-66",
      },
      {
        name: "Phitsanulok",
        code: "TH-65",
      },
      {
        name: "Phra Nakhon Si Ayutthaya",
        code: "TH-14",
      },
      {
        name: "Phrae",
        code: "TH-54",
      },
      {
        name: "Phuket",
        code: "TH-83",
      },
      {
        name: "Prachin Buri",
        code: "TH-25",
      },
      {
        name: "Prachuap Khiri Khan",
        code: "TH-77",
      },
      {
        name: "Ranong",
        code: "TH-85",
      },
      {
        name: "Ratchaburi",
        code: "TH-70",
      },
      {
        name: "Rayong",
        code: "TH-21",
      },
      {
        name: "Roi Et",
        code: "TH-45",
      },
      {
        name: "Sa Kaeo",
        code: "TH-27",
      },
      {
        name: "Sakon Nakhon",
        code: "TH-47",
      },
      {
        name: "Samut Prakan",
        code: "TH-11",
      },
      {
        name: "Samut Sakhon",
        code: "TH-74",
      },
      {
        name: "Samut Songkhram",
        code: "TH-75",
      },
      {
        name: "Saraburi",
        code: "TH-19",
      },
      {
        name: "Satun",
        code: "TH-91",
      },
      {
        name: "Sing Buri",
        code: "TH-17",
      },
      {
        name: "Si Sa Ket",
        code: "TH-33",
      },
      {
        name: "Songkhla",
        code: "TH-90",
      },
      {
        name: "Sukhothai",
        code: "TH-64",
      },
      {
        name: "Suphanburi",
        code: "TH-72",
      },
      {
        name: "Surat Thani",
        code: "TH-84",
      },
      {
        name: "Surin",
        code: "TH-32",
      },
      {
        name: "Tak",
        code: "TH-63",
      },
      {
        name: "Trang",
        code: "TH-92",
      },
      {
        name: "Trat",
        code: "TH-23",
      },
      {
        name: "Ubon Ratchathani",
        code: "TH-34",
      },
      {
        name: "Udon Thani",
        code: "TH-41",
      },
      {
        name: "Uthai Thani",
        code: "TH-61",
      },
      {
        name: "Uttaradit",
        code: "TH-53",
      },
      {
        name: "Yala",
        code: "TH-95",
      },
      {
        name: "Yasothon",
        code: "TH-35",
      },
    ],
  },
  {
    name: "Timor-Leste",
    code: "TL",
    continent: "Asia",
    phoneNumberPrefix: 670,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Togo",
    code: "TG",
    continent: "Africa",
    phoneNumberPrefix: 228,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Tokelau",
    code: "TK",
    continent: "Oceania",
    phoneNumberPrefix: 690,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Tonga",
    code: "TO",
    continent: "Oceania",
    phoneNumberPrefix: 676,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Trinidad \u0026 Tobago",
    code: "TT",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Tristan da Cunha",
    code: "TA",
    continent: "Africa",
    phoneNumberPrefix: 2908,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Tunisia",
    code: "TN",
    continent: "Africa",
    phoneNumberPrefix: 216,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Türkiye",
    code: "TR",
    continent: "Asia",
    phoneNumberPrefix: 90,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Turkmenistan",
    code: "TM",
    continent: "Asia",
    phoneNumberPrefix: 993,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{zip}{city}_{firstName}{lastName}_{company}_{address1}_{address2}_{phone}",
      show: "{zip} {city}_{country}_{firstName} {lastName}_{company}_{address1}_{address2}_{phone}",
    },
    zones: [],
  },
  {
    name: "Turks \u0026 Caicos Islands",
    code: "TC",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Tuvalu",
    code: "TV",
    continent: "Oceania",
    phoneNumberPrefix: 688,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "U.S. Outlying Islands",
    code: "UM",
    continent: "Oceania",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "STATE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Uganda",
    code: "UG",
    continent: "Africa",
    phoneNumberPrefix: 256,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Ukraine",
    code: "UA",
    continent: "Europe",
    phoneNumberPrefix: 380,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    continent: "Asia",
    phoneNumberPrefix: 971,
    autocompletionField: "address1",
    provinceKey: "EMIRATE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Emirate",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Abu Dhabi",
        code: "AZ",
      },
      {
        name: "Ajman",
        code: "AJ",
      },
      {
        name: "Dubai",
        code: "DU",
      },
      {
        name: "Fujairah",
        code: "FU",
      },
      {
        name: "Ras al-Khaimah",
        code: "RK",
      },
      {
        name: "Sharjah",
        code: "SH",
      },
      {
        name: "Umm al-Quwain",
        code: "UQ",
      },
    ],
  },
  {
    name: "United Kingdom",
    code: "GB",
    continent: "Europe",
    phoneNumberPrefix: 44,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postcode",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [
      {
        name: "British Forces",
        code: "BFP",
      },
      {
        name: "England",
        code: "ENG",
      },
      {
        name: "Northern Ireland",
        code: "NIR",
      },
      {
        name: "Scotland",
        code: "SCT",
      },
      {
        name: "Wales",
        code: "WLS",
      },
    ],
  },
  {
    name: "United States",
    code: "US",
    continent: "North America",
    phoneNumberPrefix: 1,
    autocompletionField: "address1",
    provinceKey: "STATE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "ZIP code",
      zone: "State",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province} {zip}_{country}_{phone}",
    },
    zones: [
      {
        name: "Alabama",
        code: "AL",
      },
      {
        name: "Alaska",
        code: "AK",
      },
      {
        name: "American Samoa",
        code: "AS",
      },
      {
        name: "Arizona",
        code: "AZ",
      },
      {
        name: "Arkansas",
        code: "AR",
      },
      {
        name: "California",
        code: "CA",
      },
      {
        name: "Colorado",
        code: "CO",
      },
      {
        name: "Connecticut",
        code: "CT",
      },
      {
        name: "Delaware",
        code: "DE",
      },
      {
        name: "Washington DC",
        code: "DC",
      },
      {
        name: "Micronesia",
        code: "FM",
      },
      {
        name: "Florida",
        code: "FL",
      },
      {
        name: "Georgia",
        code: "GA",
      },
      {
        name: "Guam",
        code: "GU",
      },
      {
        name: "Hawaii",
        code: "HI",
      },
      {
        name: "Idaho",
        code: "ID",
      },
      {
        name: "Illinois",
        code: "IL",
      },
      {
        name: "Indiana",
        code: "IN",
      },
      {
        name: "Iowa",
        code: "IA",
      },
      {
        name: "Kansas",
        code: "KS",
      },
      {
        name: "Kentucky",
        code: "KY",
      },
      {
        name: "Louisiana",
        code: "LA",
      },
      {
        name: "Maine",
        code: "ME",
      },
      {
        name: "Marshall Islands",
        code: "MH",
      },
      {
        name: "Maryland",
        code: "MD",
      },
      {
        name: "Massachusetts",
        code: "MA",
      },
      {
        name: "Michigan",
        code: "MI",
      },
      {
        name: "Minnesota",
        code: "MN",
      },
      {
        name: "Mississippi",
        code: "MS",
      },
      {
        name: "Missouri",
        code: "MO",
      },
      {
        name: "Montana",
        code: "MT",
      },
      {
        name: "Nebraska",
        code: "NE",
      },
      {
        name: "Nevada",
        code: "NV",
      },
      {
        name: "New Hampshire",
        code: "NH",
      },
      {
        name: "New Jersey",
        code: "NJ",
      },
      {
        name: "New Mexico",
        code: "NM",
      },
      {
        name: "New York",
        code: "NY",
      },
      {
        name: "North Carolina",
        code: "NC",
      },
      {
        name: "North Dakota",
        code: "ND",
      },
      {
        name: "Northern Mariana Islands",
        code: "MP",
      },
      {
        name: "Ohio",
        code: "OH",
      },
      {
        name: "Oklahoma",
        code: "OK",
      },
      {
        name: "Oregon",
        code: "OR",
      },
      {
        name: "Palau",
        code: "PW",
      },
      {
        name: "Pennsylvania",
        code: "PA",
      },
      {
        name: "Puerto Rico",
        code: "PR",
      },
      {
        name: "Rhode Island",
        code: "RI",
      },
      {
        name: "South Carolina",
        code: "SC",
      },
      {
        name: "South Dakota",
        code: "SD",
      },
      {
        name: "Tennessee",
        code: "TN",
      },
      {
        name: "Texas",
        code: "TX",
      },
      {
        name: "Utah",
        code: "UT",
      },
      {
        name: "Vermont",
        code: "VT",
      },
      {
        name: "U.S. Virgin Islands",
        code: "VI",
      },
      {
        name: "Virginia",
        code: "VA",
      },
      {
        name: "Washington",
        code: "WA",
      },
      {
        name: "West Virginia",
        code: "WV",
      },
      {
        name: "Wisconsin",
        code: "WI",
      },
      {
        name: "Wyoming",
        code: "WY",
      },
      {
        name: "Armed Forces Americas",
        code: "AA",
      },
      {
        name: "Armed Forces Europe",
        code: "AE",
      },
      {
        name: "Armed Forces Pacific",
        code: "AP",
      },
    ],
  },
  {
    name: "Uruguay",
    code: "UY",
    continent: "South America",
    phoneNumberPrefix: 598,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Department",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}, {province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Artigas",
        code: "UY-AR",
      },
      {
        name: "Canelones",
        code: "UY-CA",
      },
      {
        name: "Cerro Largo",
        code: "UY-CL",
      },
      {
        name: "Colonia",
        code: "UY-CO",
      },
      {
        name: "Durazno",
        code: "UY-DU",
      },
      {
        name: "Flores",
        code: "UY-FS",
      },
      {
        name: "Florida",
        code: "UY-FD",
      },
      {
        name: "Lavalleja",
        code: "UY-LA",
      },
      {
        name: "Maldonado",
        code: "UY-MA",
      },
      {
        name: "Montevideo",
        code: "UY-MO",
      },
      {
        name: "Paysandú",
        code: "UY-PA",
      },
      {
        name: "Río Negro",
        code: "UY-RN",
      },
      {
        name: "Rivera",
        code: "UY-RV",
      },
      {
        name: "Rocha",
        code: "UY-RO",
      },
      {
        name: "Salto",
        code: "UY-SA",
      },
      {
        name: "San José",
        code: "UY-SJ",
      },
      {
        name: "Soriano",
        code: "UY-SO",
      },
      {
        name: "Tacuarembó",
        code: "UY-TA",
      },
      {
        name: "Treinta y Tres",
        code: "UY-TT",
      },
    ],
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    continent: "Asia",
    phoneNumberPrefix: 998,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Vanuatu",
    code: "VU",
    continent: "Oceania",
    phoneNumberPrefix: 678,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Vatican City",
    code: "VA",
    continent: "Europe",
    phoneNumberPrefix: 39,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Venezuela",
    code: "VE",
    continent: "South America",
    phoneNumberPrefix: 58,
    autocompletionField: "address1",
    provinceKey: "STATE",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}{province}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip} {province}_{country}_{phone}",
    },
    zones: [
      {
        name: "Amazonas",
        code: "VE-Z",
      },
      {
        name: "Anzoátegui",
        code: "VE-B",
      },
      {
        name: "Apure",
        code: "VE-C",
      },
      {
        name: "Aragua",
        code: "VE-D",
      },
      {
        name: "Barinas",
        code: "VE-E",
      },
      {
        name: "Bolívar",
        code: "VE-F",
      },
      {
        name: "Carabobo",
        code: "VE-G",
      },
      {
        name: "Cojedes",
        code: "VE-H",
      },
      {
        name: "Delta Amacuro",
        code: "VE-Y",
      },
      {
        name: "Federal Dependencies",
        code: "VE-W",
      },
      {
        name: "Capital",
        code: "VE-A",
      },
      {
        name: "Falcón",
        code: "VE-I",
      },
      {
        name: "Guárico",
        code: "VE-J",
      },
      {
        name: "Vargas",
        code: "VE-X",
      },
      {
        name: "Lara",
        code: "VE-K",
      },
      {
        name: "Mérida",
        code: "VE-L",
      },
      {
        name: "Miranda",
        code: "VE-M",
      },
      {
        name: "Monagas",
        code: "VE-N",
      },
      {
        name: "Nueva Esparta",
        code: "VE-O",
      },
      {
        name: "Portuguesa",
        code: "VE-P",
      },
      {
        name: "Sucre",
        code: "VE-R",
      },
      {
        name: "Táchira",
        code: "VE-S",
      },
      {
        name: "Trujillo",
        code: "VE-T",
      },
      {
        name: "Yaracuy",
        code: "VE-U",
      },
      {
        name: "Zulia",
        code: "VE-V",
      },
    ],
  },
  {
    name: "Vietnam",
    code: "VN",
    continent: "Asia",
    phoneNumberPrefix: 84,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Wallis \u0026 Futuna",
    code: "WF",
    continent: "Oceania",
    phoneNumberPrefix: 681,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Western Sahara",
    code: "EH",
    continent: "Africa",
    phoneNumberPrefix: 212,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Yemen",
    code: "YE",
    continent: "Asia",
    phoneNumberPrefix: 967,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Zambia",
    code: "ZM",
    continent: "Africa",
    phoneNumberPrefix: 260,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
    },
    zones: [],
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    continent: "Africa",
    phoneNumberPrefix: 263,
    autocompletionField: "address1",
    provinceKey: "REGION",
    labels: {
      address1: "Address",
      address2: "Apartment, suite, etc.",
      city: "City",
      company: "Company",
      country: "Country/region",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      postalCode: "Postal code",
      zone: "Region",
    },
    optionalLabels: {
      address2: "Apartment, suite, etc. (optional)",
    },
    formatting: {
      edit: "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      show: "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
    },
    zones: [],
  },
];

export const TransactionConstants={
  INSERT:"insert",
  ORDERS:"orders",
  UPDATE:"update",
  REWARDS:"rewards",
  PAYOUT:"payouts"
}

export const Currencies = [
  { country: "Afghanistan", currency: "AFN", symbol: "؋" },
  { country: "Åland Islands", currency: "EUR", symbol: "€" },
  { country: "Albania", currency: "ALL", symbol: "L" },
  { country: "Algeria", currency: "DZD", symbol: "د.ج" },
  { country: "Angola", currency: "USD", symbol: "$" },
  { country: "Anguilla", currency: "XCD", symbol: "$" },
  { country: "Armenia", currency: "AMD", symbol: "դր." },
  { country: "Aruba", currency: "AWG", symbol: "ƒ" },
  { country: "Ascension Island", currency: "SHP", symbol: "£" },
  { country: "Australia", currency: "AUD", symbol: "$" },
  { country: "Azerbaijan", currency: "AZN", symbol: "₼" },
  { country: "Bahamas", currency: "BSD", symbol: "$" },
  { country: "Bangladesh", currency: "BDT", symbol: "৳" },
  { country: "Barbados", currency: "BBD", symbol: "$" },
  { country: "Belize", currency: "BZD", symbol: "$" },
  { country: "Benin", currency: "XOF", symbol: "Fr" },
  { country: "Bolivia", currency: "BOB", symbol: "Bs." },
  { country: "Bosnia & Herzegovina", currency: "BAM", symbol: "КМ" },
  { country: "Botswana", currency: "BWP", symbol: "P" },
  { country: "Brunei", currency: "BND", symbol: "$" },
  { country: "Bulgaria", currency: "BGN", symbol: "лв." },
  { country: "Burundi", currency: "BIF", symbol: "Fr" },
  { country: "Cambodia", currency: "KHR", symbol: "៛" },
  { country: "Cameroon", currency: "XAF", symbol: "Fr" },
  { country: "Canada", currency: "CAD", symbol: "$" },
  { country: "Cape Verde", currency: "CVE", symbol: "$" },
  { country: "Cayman Islands", currency: "KYD", symbol: "$" },
  { country: "China", currency: "CNY", symbol: "¥" },
  { country: "Comoros", currency: "KMF", symbol: "Fr" },
  { country: "Congo - Kinshasa", currency: "CDF", symbol: "Fr" },
  { country: "Cook Islands", currency: "NZD", symbol: "$" },
  { country: "Costa Rica", currency: "CRC", symbol: "₡" },
  { country: "Curaçao", currency: "ANG", symbol: "ƒ" },
  { country: "Czechia", currency: "CZK", symbol: "Kč" },
  { country: "Denmark", currency: "DKK", symbol: "kr." },
  { country: "Djibouti", currency: "DJF", symbol: "Fdj" },
  { country: "Dominican Republic", currency: "DOP", symbol: "$" },
  { country: "Egypt", currency: "EGP", symbol: "ج.م" },
  { country: "Ethiopia", currency: "ETB", symbol: "Br" },
  { country: "Falkland Islands", currency: "FKP", symbol: "£" },
  { country: "Fiji", currency: "FJD", symbol: "$" },
  { country: "French Polynesia", currency: "XPF", symbol: "Fr" },
  { country: "Gambia", currency: "GMD", symbol: "D" },
  { country: "Gibraltar", currency: "GBP", symbol: "£" },
  { country: "Guatemala", currency: "GTQ", symbol: "Q" },
  { country: "Guinea", currency: "GNF", symbol: "Fr" },
  { country: "Guyana", currency: "GYD", symbol: "$" },
  { country: "Honduras", currency: "HNL", symbol: "L" },
  { country: "Hong Kong SAR", currency: "HKD", symbol: "$" },
  { country: "Hungary", currency: "HUF", symbol: "Ft" },
  { country: "Iceland", currency: "ISK", symbol: "kr" },
  { country: "India", currency: "INR", symbol: "₹" },
  { country: "Indonesia", currency: "IDR", symbol: "Rp" },
  { country: "Israel", currency: "ILS", symbol: "₪" },
  { country: "Jamaica", currency: "JMD", symbol: "$" },
  { country: "Japan", currency: "JPY", symbol: "¥" },
  { country: "Kazakhstan", currency: "KZT", symbol: "〒" },
  { country: "Kenya", currency: "KES", symbol: "KSh" },
  { country: "Kyrgyzstan", currency: "KGS", symbol: "som" },
  { country: "Laos", currency: "LAK", symbol: "₭" },
  { country: "Lebanon", currency: "LBP", symbol: "ل.ل" },
  { country: "Liechtenstein", currency: "CHF", symbol: "CHF" },
  { country: "Macao SAR", currency: "MOP", symbol: "P" },
  { country: "Malawi", currency: "MWK", symbol: "MK" },
  { country: "Malaysia", currency: "MYR", symbol: "RM" },
  { country: "Maldives", currency: "MVR", symbol: "MVR" },
  { country: "Mauritius", currency: "MUR", symbol: "₨" },
  { country: "Moldova", currency: "MDL", symbol: "L" },
  { country: "Mongolia", currency: "MNT", symbol: "₮" },
  { country: "Morocco", currency: "MAD", symbol: "د.م." },
  { country: "Myanmar (Burma)", currency: "MMK", symbol: "K" },
  { country: "Nepal", currency: "NPR", symbol: "₨" },
  { country: "Nicaragua", currency: "NIO", symbol: "C$" },
  { country: "Nigeria", currency: "NGN", symbol: "₦" },
  { country: "North Macedonia", currency: "MKD", symbol: "ден" },
  { country: "Pakistan", currency: "PKR", symbol: "₨" },
  { country: "Papua New Guinea", currency: "PGK", symbol: "K" },
  { country: "Paraguay", currency: "PYG", symbol: "₲" },
  { country: "Peru", currency: "PEN", symbol: "S/." },
  { country: "Philippines", currency: "PHP", symbol: "₱" },
  { country: "Poland", currency: "PLN", symbol: "zł" },
  { country: "Qatar", currency: "QAR", symbol: "ر.ق" },
  { country: "Romania", currency: "RON", symbol: "Lei" },
  { country: "Rwanda", currency: "RWF", symbol: "FRw" },
  { country: "Samoa", currency: "WST", symbol: "T" },
  { country: "São Tomé & Príncipe", currency: "STD", symbol: "Db" },
  { country: "Saudi Arabia", currency: "SAR", symbol: "ر.س" },
  { country: "Serbia", currency: "RSD", symbol: "РСД" },
  { country: "Sierra Leone", currency: "SLL", symbol: "Le" },
  { country: "Singapore", currency: "SGD", symbol: "$" },
  { country: "Solomon Islands", currency: "SBD", symbol: "$" },
  { country: "South Korea", currency: "KRW", symbol: "₩" },
  { country: "Sri Lanka", currency: "LKR", symbol: "₨" },
  { country: "Sweden", currency: "SEK", symbol: "kr" },
  { country: "Taiwan", currency: "TWD", symbol: "$" },
  { country: "Tajikistan", currency: "TJS", symbol: "ЅМ" },
  { country: "Tanzania", currency: "TZS", symbol: "Sh" },
  { country: "Thailand", currency: "THB", symbol: "฿" },
  { country: "Tonga", currency: "TOP", symbol: "T$" },
  { country: "Trinidad & Tobago", currency: "TTD", symbol: "$" },
  { country: "Uganda", currency: "UGX", symbol: "USh" },
  { country: "Ukraine", currency: "UAH", symbol: "₴" },
  { country: "United Arab Emirates", currency: "AED", symbol: "د.إ" },
  { country: "Uruguay", currency: "UYU", symbol: "$" },
  { country: "Uzbekistan", currency: "UZS", symbol: "" },
  { country: "Vanuatu", currency: "VUV", symbol: "Vt" },
  { country: "Vietnam", currency: "VND", symbol: "₫" },
  { country: "Yemen", currency: "YER", symbol: "﷼" },
];
 