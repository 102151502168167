import { TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { StyledText } from '../../../../utils/styledComponents';
import { trafficAnalysisStyle } from './styles';
import { useTranslation } from 'react-i18next';

const TrafficData = ({data,visits,startPageIndex,endPageIndex}:any) => {
    const {t} = useTranslation()
    return (  
      <TableBody>
        {(() => {
          const displayPosts = [];
       displayPosts.push(
            data?.slice(startPageIndex, endPageIndex + 1)?.map((item: any) => {
             return <TableRow className="table-row">
                <TableCell align={"left"} sx={trafficAnalysisStyle.tbCell}>
                  {item?.aggregateValue}
                </TableCell>
                <TableCell align={"right"} sx={trafficAnalysisStyle.tbCell}>
                  {item?.orders}
                </TableCell>
                <TableCell align={"right"} sx={trafficAnalysisStyle.tbCell}>
                  {item?.visits}
                </TableCell>
                <TableCell align={"right"} sx={trafficAnalysisStyle.tbCell}>
                  ${item?.commissions}
                </TableCell>
                <TableCell align={"right"} sx={trafficAnalysisStyle.tbCell}>
                  {item?.conversion}% 
                </TableCell>
              </TableRow>
        })
          );
          return displayPosts;
        })()}

        {!visits?.length && (
          <TableRow className="table-row">
            <TableCell colSpan={5} sx={trafficAnalysisStyle.tbCell}>
              <StyledText fontvalue="16px" lineheight="21px" sx={{textAlign:"center"}}>
                {t("No records found.")}
              </StyledText>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
};

export default TrafficData;