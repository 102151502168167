import { combineReducers } from "redux";
import { actionTypes } from "../../../actions";
import { actions } from "../../../actions/Types";
import { FAILURE, RESET } from "../../../actions/actiontypes";
import { RootState } from "../../../configureStore";
import { toast } from "react-toastify";
import { t } from "i18next";

const {
  SUCCESS,
  REQUEST,
  DELETE_ADDRESSS,
  SET_DEFAULT_ADDRESS,
  GET_ALL_ADDRESSES,
  UPDATE_ADDRESS,
} = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const accountAddressDelete = (state = intialState, action: actions) => {
    switch (action.type) {
      case DELETE_ADDRESSS[SUCCESS]:
        toast.success(t("Address deleted successfully"));
        return { ...state, data: action.payload, isLoading: false };
      case DELETE_ADDRESSS[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload, isLoading: true }
          : state;
      case DELETE_ADDRESSS[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case DELETE_ADDRESSS[RESET]:
        return intialState;
      default:
        return state;
    }
  };

  const accountAddressDefault = (state = intialState, action: actions) => {
    switch (action.type) {
      case SET_DEFAULT_ADDRESS[SUCCESS]:
        toast.success(t("Address set as default"));
        return { ...state, data: action.payload, isLoading: false };
      case SET_DEFAULT_ADDRESS[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload, isLoading: true }
          : state;
      case SET_DEFAULT_ADDRESS[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case SET_DEFAULT_ADDRESS[RESET]:
        return intialState;
      default:
        return state;
    }
  };

  const updateAddressResponse = (state = intialState, action: actions) => {
    switch (action.type) {
      case UPDATE_ADDRESS[SUCCESS]:
        toast.success(t("Address updated successfully"));
        return { ...state, data: action.payload, isLoading: false };
      case UPDATE_ADDRESS[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload, isLoading: true }
          : state;
      case UPDATE_ADDRESS[FAILURE]:
        return {
          ...state,
          data: action.payload?.data,
          isLoading: false,
          isError: true,
        };
      case UPDATE_ADDRESS[RESET]:
        return { ...intialState, loading: false };
      default:
        return state;
    }
  };

  const allAddresses = (state = intialState, action: actions) => {
    switch (action.type) {
      case GET_ALL_ADDRESSES[SUCCESS]:
        return { ...state, data: action.payload?.data, isLoading: false };
      case GET_ALL_ADDRESSES[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload, isLoading: true }
          : state;
      case GET_ALL_ADDRESSES[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  return combineReducers({
    accountAddressDelete,
    accountAddressDefault,
    allAddresses,
    updateAddressResponse,
  });
};

export default entity;

export const accountAddress = (state: RootState) =>
  state.entities.accountAddress;
