import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Radio, Stack, LinearProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { styles } from "./style";
import { deleteAdderess, setDefultAddress } from "../../../actions";
import { Address } from "../../../models/backoffice.models";
import { RootState } from "../../../configureStore";
import { stylesGlobal } from "../../../utils/muiStyles";
import FloatModal from "../../../shared/components/FloatModal";
import EditAddress from "./EditAddress";
import { StyledText } from "../../../utils/styledComponents";
import FloatAlert from "../../../shared/components/FloatAlert";

function ListAddresses ({ address, getAll, setAddresses }: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [defaultCheck, set_default] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [addressSelected, setselectedAddress] = useState<Object>({});
  const [addressLoader, setAddressLoader] = useState<boolean>(false);
  const [openAlert, setAlert] = useState<boolean>(false);
  const [deleteAddressData, setDeletAdressData]: any = useState();

  const { allAddresses, accountAddressDefault, accountAddressDelete } =
  useSelector((state: RootState) => state?.entities?.accountAddress);

  useEffect(() => {
    if (!accountAddressDefault?.isLoading || !accountAddressDelete.isLoading) {
      dispatch(deleteAdderess.reset());
      dispatch(setDefultAddress.reset());
      set_default(false);
      setTimeout(() => {
        setAddressLoader(false);
      }, 2000);
      getAll();
    }
  }, [accountAddressDefault, accountAddressDelete.isLoading]);

  useEffect(() => {
    if (accountAddressDefault?.isError) {
      set_default(false);
    }
  }, [accountAddressDefault?.isError]);

  useEffect(() => {
    if (!allAddresses?.isLoading) {
      setAddresses(allAddresses?.data);
    }
  }, [allAddresses?.isLoading, allAddresses?.data]);

  const onDeleteAddress = () => {
    setAddressLoader(true);
    dispatch(deleteAdderess.request({ data: { id: deleteAddressData?.id } }));
    setAlert(false);
  };

  const handleClose = () => {
    setEditOpen(false);
  };

  const handleOpen = (address: Address) => {
    setselectedAddress(address);
    setEditOpen(true);
  };

  const onDefaultChange = (id: number | string) => {
    dispatch(setDefultAddress.request({ data: { id: id } }));
    set_default(!defaultCheck);
    setAddressLoader(!defaultCheck);
  };

  return (
    <React.Fragment>
      <Box sx={styles.addressOuter}>
        <StyledText sx={styles.addressText}>{address?.name ?? ""}</StyledText>
        <StyledText sx={styles.addressText}>
          {address?.address1 ?? ""},
        </StyledText>
        <StyledText sx={styles.addressText}>{`${address?.country ?? ""} ${
          address?.zip ?? ""
        }`}</StyledText>
      </Box>
      {!address?.default ? (
        <Stack sx={styles.addressStack}>
          <EditIcon
            sx={stylesGlobal.iconBtn}
            onClick={() => handleOpen(address)}
          />
          <DeleteIcon
            sx={stylesGlobal.iconBtn}
            onClick={() => {
              setDeletAdressData(address);
              setAlert(true);
            }}
          />
          <Stack sx={{ ...styles.checkOuter, mt: 0 }}>
            <Radio
              size="small"
              checked={defaultCheck}
              onChange={() => onDefaultChange(address?.id)}
              value={address?.id}
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <StyledText sx={styles.checkText}>{t("Set as default")}</StyledText>
          </Stack>
        </Stack>
      ) : null}
      <Box sx={styles.addressLoaderStyle}>
        {addressLoader ? <LinearProgress /> : null}
      </Box>
      {editOpen && (
        <FloatModal
          open={editOpen}
          onClose={handleClose}
          modalTitle={t("Edit address")}
          modalBody={
            <EditAddress
              editFields={addressSelected}
              handleClose={handleClose}
            />
          }
        />
      )}
      <FloatAlert
        open={openAlert}
        onClose={() => {
          setAlert(false);
          setDeletAdressData({});
        }}
        alert={t("Are you sure you want to delete?")}
        action={() => onDeleteAddress()}
      />
    </React.Fragment>
  );
};

export default ListAddresses;
