import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  Info,
  StyledText,
  SubTitle1,
  SubTitle2,
} from "../../../utils/styledComponents";
import { FloatLinearProgress } from "../../../shared/components/FloatLenearProgress";
import { analyticsStyles } from "./styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import { getGoaffproAnalytics } from "../../../actions";
import { RootState } from "../../../configureStore";
import {
  desktopProgress,
  formatDateForPicker,
  getAndroidProgress,
  getChromeProgress,
  getConvertions,
  getEdgeProgress,
  getFbProgress,
  getIOSProgress,
  getLinuxProgress,
  getMacOsProgress,
  getSafariMobileProgress,
  getSafariProgress,
  getWindowsProgress,
  mobileIpadProgress,
} from "./helper";
import BackDropLoader from "../../../shared/BackdropLoader";
import TraficAnalysis from "./traficAnalysis/TraficAnalysis";
import { useTranslation } from "react-i18next";
import { isArrayIncludesKey } from "../../../utils/hooks";
import FloatDateSelector from "../../../shared/components/FloatDateSelector";

function CreatorAnalytics() {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDates, setSelectedDates]: any = useState(["", ""]);
  const [showDate, setShowDate] = useState("YYYY/MM/DD to YYYY/MM/DD");
  const [clickOrigins, setClickOrigins]: any = useState([]);
  const [landingUrls, setLandingUrls]: any = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();


  const { goaffproAnalyticsData }: any = useSelector(
    (state: RootState) => state?.entities?.goaffproAnalytics
  );

  const [visits, setVisits] = useState([]);

  const AnalyticsProgressList = useMemo(
    () => [
      {
        name: "Browsers",
        translate:true,
        progressList: [
          { name: "Chrome", value: getChromeProgress(visits) },
          { name: "Mobile Safari", value: getSafariMobileProgress(visits) },
          { name: "Safari", value: getSafariProgress(visits) },
          { name: "Facebook", value: getFbProgress(visits) },
          { name: "Edge", value: getEdgeProgress(visits) },
        ],
      },
      {
        name: "Operating systems",
        translate:true,
        progressList: [
          { name: "Linux", value: getLinuxProgress(visits) },
          { name: "Mac OS", value: getMacOsProgress(visits) },
          { name: "iOS", value: getIOSProgress(visits) },
          { name: "Android", value: getAndroidProgress(visits) },
          { name: "Windows", value: getWindowsProgress(visits) },
        ],
      },
      {
        name: "Devices",
        translate:true,
        progressList: [
          { name: "Desktop", value: desktopProgress(visits) },
          { name: "Mobile", value: mobileIpadProgress(visits) },
        ],
      },
      {
        name: "Landing pages",
        translate:false,
        progressList: landingUrls,
      },
      {
        name: "Click origins",
        translate:false,
        progressList: clickOrigins,
      },
    ],
    [visits, clickOrigins,landingUrls]
  );

  useEffect(() => {
    if (!goaffproAnalyticsData?.isLoading && !goaffproAnalyticsData.isError) {
      setVisits(goaffproAnalyticsData?.data?.visits ?? []);

      const visitsList = goaffproAnalyticsData?.data?.visits ?? [];
      getClickOriginList(visitsList);
      getLangingUrlsList(visitsList);
    }
  }, [goaffproAnalyticsData?.isLoading]);

  function getLangingUrlsList(visitsList: any) {
    const clickOrigins = visitsList.reduce((acc: any, item: any) => {
      const page = item?.landing_page;
      const sliceBase = page?.indexOf(".com");
      const sliceIndex = page?.indexOf("?");
      const url = isArrayIncludesKey(item?.landing_page,"?")
        ? page.slice(sliceBase + 4, sliceIndex)
        : page;
      const origin = url;

      if (origin) {
        acc[origin] = (acc[origin] || 0) + 1;
      }

      return acc;
    }, {});

    const clicklandingUrlArray = Object.keys(clickOrigins).map((key) => ({
      name: key,
      value: Math.round((clickOrigins[key] / visitsList?.length) * 100),
    }));

    clicklandingUrlArray?.sort((a, b) => b?.value - a?.value);

    clicklandingUrlArray?.length > 5
      ? setLandingUrls(clicklandingUrlArray?.slice(0, 5))
      : setLandingUrls(clicklandingUrlArray);
  }

  function getDomainName(value:string){
    const url = new URL(value)
    return url.hostname ?? ""
  }
  function getClickOriginList(visitsList: any) {
    const clickOrigins = visitsList.reduce((acc: any, item: any) => {
      const url = item?.referrer;
      const sliceIndex = url?.indexOf(".com") + 4;
      const origin = url?.slice(0, sliceIndex);

      if (origin && isArrayIncludesKey(url,".com")) {
        acc[origin] = (acc[origin] || 0) + 1;
      }

      return acc;
    }, {});

    const clickOriginsArray = Object.keys(clickOrigins).map((key) => ({
      name: getDomainName(key),
      value: Math.round((clickOrigins[key] / visitsList?.length) * 100),
    }));
    const direct = Math.round(
      (visitsList?.filter((item: any) => !item.referrer).length /
        visitsList?.length) *
        100
    );
    clickOriginsArray?.push({ name: "Direct", value: direct });
    clickOriginsArray?.sort((a, b) => b?.value - a?.value);

    clickOriginsArray?.length > 5
      ? setClickOrigins(clickOriginsArray?.slice(0, 5))
      : setClickOrigins(clickOriginsArray);
  }

  const onSelectDate = () => {
    setOpenDatePicker(!openDatePicker);
  };

  const handleDateFilter = () => {
    setShowDate(
      `${selectedDates[0]?.format("MM/DD/YYYY")} to ${selectedDates[1]?.format(
        "MM/DD/YYYY"
      )}`
    );

    const startTime = formatDateForPicker(
      selectedDates[0]?.format("YYYY/MM/DD")
    );
    const endTime = formatDateForPicker(selectedDates[1]?.format("YYYY/MM/DD"));

    dispatch(
      getGoaffproAnalytics.request({
        startDate: selectedDates[0]?.format("YYYY/MM/DD"),
        endDate: selectedDates[1]?.format("YYYY/MM/DD"),
        startTime: startTime,
        endTime: endTime,
      })
    );
    setOpenDatePicker(false);
  };

  useEffect(() => {
    const currentDate = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    const startDate = formatPayloadDate(oneMonthAgo);
    const endDate = formatPayloadDate(currentDate);

    dispatch(
      getGoaffproAnalytics.request({
        startDate: `${startDate.split("+")[2]}-${startDate.split("+")[0]}-${
          startDate.split("+")[1]
        }`,
        endDate: `${endDate.split("+")[2]}-${endDate.split("+")[0]}-${
          endDate.split("+")[1]
        }`,
        startTime: startDate,
        endTime: endDate,
      })
    );

    setShowDate(
      `${formatInitialDate(oneMonthAgo)} to ${formatInitialDate(currentDate)}`
    );
  }, []);

  function formatPayloadDate(date: Date) {
    const month = date.toLocaleString("default", { month: "short" });
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}+${day}+${year}+00:00:00+GMT+0530+(India+Standard+Time)`;
  }

  function formatInitialDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${month}/${day}/${year}`;
  }

  const cards = [
    {
      key: "Visits",
      value: goaffproAnalyticsData?.data?.visits?.length ?? "0",
      isLoading: goaffproAnalyticsData?.isLoading,
      postFixSymbol: "",
      prefixSymbol: "",
    },
    {
      key: "Orders",
      value: goaffproAnalyticsData?.data?.sales?.num_orders ?? "0",
      isLoading: goaffproAnalyticsData?.isLoading,
      postFixSymbol: "",
      prefixSymbol: "",
    },
    {
      key: "Conversions",
      value: getConvertions(goaffproAnalyticsData.data),
      isLoading: goaffproAnalyticsData?.isLoading,
      postFixSymbol: "%",
      prefixSymbol: "",
    },
    {
      key: "Commissions",
      value: goaffproAnalyticsData?.data?.sales?.commission
        ? parseFloat(goaffproAnalyticsData?.data?.sales?.commission).toFixed(2)
        : "0",
      isLoading: goaffproAnalyticsData?.isLoading,
      postFixSymbol: "",
      prefixSymbol: "$",
    },
  ];

  return (
    <Box>
      <BackDropLoader open={goaffproAnalyticsData?.isLoading} />
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          mb: "10px",
        }}
      >
        <Button
          onClick={onSelectDate}
          sx={{
            width: "250px",
            padding: "10px 6px",
            borderBottom: "2px solid #333",
            borderRadius: "0",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.10)",
          }}
        >
          <StyledText sx={{ textTransform: "none" }}>{showDate} </StyledText>
          &nbsp;
          <ArrowDropDownIcon />
        </Button>
      </Stack>
      <FloatDateSelector
        open={openDatePicker}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        handleDateFilter={handleDateFilter}
      />

      <Grid container spacing={2}>
        {AnalyticsProgressList?.map((item: any) => {
          return (
            visits?.length > 0 && (
              <Grid item xs={12} sm={6} lg={4} xl={3}>
                <Box sx={analyticsStyles.gridBox}>
                  <Stack>
                    <SubTitle1>{t(item.name)}</SubTitle1>
                  </Stack>
                  {item.progressList.map((progress: any) => {
                    return progress?.value ? (
                      <Stack sx={analyticsStyles.stackStyle}>
                        <Box sx={analyticsStyles.stackBox}>
                          <SubTitle2
                            sx={{
                              width: "calc(100% - 50px)",
                              overflow: "hidden",
                              wordBreak: "break-all",
                              lineHeight: "1.3",
                            }}
                          >
                            {item.translate ? t(progress.name) : progress.name}
                          </SubTitle2>
                          <Info sx={{ width: "40px" }}>
                            {progress.value?.toString() + "%"}
                          </Info>
                        </Box>
                        <FloatLinearProgress
                          variant="determinate"
                          value={progress.value}
                        />
                      </Stack>
                    ) : null;
                  })}
                </Box>
              </Grid>
            )
          );
        })}
      </Grid>

      <Box sx={analyticsStyles.boxBG}>
        <Grid container spacing={2}>
          {cards?.map((item) => {
            return (
              <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"86px"}
                  sx={analyticsStyles.whiteBG}
                >
                  <SubTitle1>
                    {item.isLoading ? (
                      <CircularProgress size="12px" />
                    ) : (
                      item.prefixSymbol +
                      item.value?.toString() +
                      item.postFixSymbol
                    )}
                  </SubTitle1>
                  <SubTitle2>{t(item.key)}</SubTitle2>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <TraficAnalysis visits={visits} />
    </Box>
  );
}

export default CreatorAnalytics;
