import { combineReducers } from "redux";
import { actionTypes } from "../../../actions";
import { actions } from "../../../actions/Types";
import { FAILURE } from "../../../actions/actiontypes";
import { RootState } from "../../../configureStore";
import { toast } from "react-toastify";
import { t } from "i18next";

const { SUCCESS, REQUEST, CREATE_ADDRESS, RESET } = actionTypes;

const intialState = {
  isApiCallInProgress: false,
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const addressCreateData = (state = intialState, action: actions) => {
    switch (action.type) {
      case CREATE_ADDRESS[SUCCESS]:
        toast.success(t("Address added successfully"));
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: false,
        };
      case CREATE_ADDRESS[REQUEST]:
        return action?.payload
          ? {
            ...state,
            data: action.payload,
            isLoading: true,
            isApiCallInProgress: true,
          }
          : { ...state, isApiCallInProgress: true };
      case CREATE_ADDRESS[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case CREATE_ADDRESS[RESET]:
        return intialState;
      default:
        return state;
    }
  };

  return combineReducers({
    addressCreateData,
  });
};

export default entity;

export const createAccountAddress = (state: RootState) =>
  state.entities.createAccountAddress;
