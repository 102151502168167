import React, { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledButton,
  StyledText,
  SubTitle1,
} from "../../../utils/styledComponents";
import { orderHistoryStyle, styles } from "./style";
import { LocalMallIcon } from "../../../utils/icons";
import TrackinhgSteps from "./TrsckStep";
import FloatSearch from "../../../shared/components/FloatSearch";
import FLoatDropDown from "../../../shared/components/FLoatDropDown";
import { CONSTANTS, Currencies } from "../../../utils/constants";
import { stylesGlobal } from "../../../utils/muiStyles";
import { useDispatch, useSelector } from "react-redux";
import { getOrderTrackingDetails } from "../../../actions";
import CustomPagination from "../../../shared/components/CustomPagination";
import { RootState } from "../../../configureStore";
import FloatModal from "../../../shared/components/FloatModal";
import BuyAgainProduct from "./BuyAgainProduct";
import ProductDetails from "./ProductDetails";
import { formatDate, removeEmptyValues } from "../../../utils/hooks";
import { useTranslation } from "react-i18next";
import FloatMobileFilter from "../../../shared/components/FloatMobileFilter";
import { ILineItem, ILineItemsWithCurrency } from "../../../models/backoffice.models";

const PAGE_LIMIT = 4;

function TrackingReturns() {
  const isMobile = useMediaQuery("(max-width:600px)");

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const trackingData: any = useSelector(
    (state: RootState) => state?.entities?.orderTracking?.orderTrackingData
  );

  const orderTrackingData = trackingData?.data?.data ?? {};
  const [openProductDetails, setOpenProductDetails] = useState<boolean>(false);
  const [openBuyAgain, setOpenBuyAgain] = useState<boolean>(false);
  const [selectedProductDetails, setSelectedProductDetails] =
    useState<ILineItemsWithCurrency>();
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>();
  const [totalItem, setTotalItem] = useState<number>();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const [filters, setFilters] = React.useState({
    search: "",
    searchBy: "all",
    status: "any",
    sortBy: "id",
    sortOrder: "asc",
    dateRange: "all",
    page: 1,
    limit: "",
  });

  const { searchFilterStyle } = stylesGlobal;

  const openProductDetailsModal = (data: ILineItem[],currency:string) => {
    setOpenProductDetails(true);
    setSelectedProductDetails({ currency: currency, lineItems: data });
  };

  const closeProductDetails = () => {
    setOpenProductDetails(false);
  };

  const openBuyAgainModal = (data: ILineItem[], currency:string) => {
    setOpenBuyAgain(true);
    setSelectedProductDetails({ currency: currency, lineItems: data });
  };

  const closeBuyAgain = () => {
    setOpenBuyAgain(false);
  };

  const buyAgain = (event: any, data: ILineItem[], currency:string) => {
    event.preventDefault();
    event.stopPropagation();
    openBuyAgainModal(data, currency);
  };

  useEffect(() => {
    dispatch(getOrderTrackingDetails.request(removeEmptyValues(filters)));
  }, []);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (debouncedSearchTerm?.length > 0) {
        setFilters({ ...filters, search: debouncedSearchTerm });
        dispatch(
          getOrderTrackingDetails.request(
            removeEmptyValues({
              ...{ ...filters, search: debouncedSearchTerm },
              page: 1,
            })
          )
        );
      }
    }, 1000);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (Object.keys(orderTrackingData)?.length) {
      setTotalPages(orderTrackingData?.totalPages ?? 1);
      setCurrentPage(orderTrackingData?.current_page ?? 1);
      setTotalItem(orderTrackingData?.totalItems ?? 1);
    }
  }, [orderTrackingData?.paginatedData]);

  const searchByChange = (e: any) => {
    if (e.target.value?.length === 0) {
      setFilters({ ...filters, search: "" });
      dispatch(
        getOrderTrackingDetails.request(removeEmptyValues({
          ...{ ...filters, search: "" },
          page: 1,
        }))
      );
    }
    setDebouncedSearchTerm(e.target.value);
  };

  const commonFilterChange: any = (e: any) => {
    let name = e.target.name;
    let data = {
      searchBy: name === "searchBy" ? e.target.value : filters.searchBy,
      status: name === "status" ? e.target.value : filters.status,
      search: name === "search" ? e.target.value : filters.search,
      sortBy: name === "sortBy" ? e.target.value : filters.sortBy,
      sortOrder: name === "sortOrder" ? e.target.value : filters.sortOrder,
      dateRange: name === "dateRange" ? e.target.value : filters.dateRange,
    };
    setFilters({ ...filters, ...data });
    dispatch(
      getOrderTrackingDetails.request(
        removeEmptyValues({ ...filters, ...data })
      )
    );
  };

  const SelectFilters = [
    {
      list: CONSTANTS.SearchOptionTracking,
      label: "Search by",
      name: "searchBy",
      onChange: commonFilterChange,
      value: filters.searchBy,
      disabled: false,
    },
    {
      list: CONSTANTS.OrderTrackingFilters,
      label: "Order by",
      name: "sortBy",
      onChange: commonFilterChange,
      value: filters.sortBy,
      disabled: false,
    },
    {
      list: CONSTANTS.Order,
      label: "Order",
      name: "sortOrder",
      onChange: commonFilterChange,
      value: filters.sortOrder,
      disabled: false,
    },
    {
      list: CONSTANTS.StatusFilter,
      label: "Status",
      name: "status",
      onChange: commonFilterChange,
      value: filters.status,
      disabled: false,
    },
    {
      list: CONSTANTS.trackingDateRange,
      label: "Filter",
      name: "dateRange",
      onChange: commonFilterChange,
      value: filters.dateRange,
      disabled: false,
    },
  ];

  const OrderSummary = (orderSummaryData: any,currency:string) => {
    return [
      {
        key: "Sub total",
        value: orderSummaryData?.sub_total
          ? `${currency} ${orderSummaryData?.sub_total}`
          : "-",
      },
      {
        key: "Total discount",
        value: orderSummaryData?.total_discounts
          ? `${currency} ${orderSummaryData?.total_discounts}`
          : "-",
      },
      {
        key: "Tax price",
        value: orderSummaryData?.total_tax
          ? `${currency} ${orderSummaryData?.total_tax}`
          : "-",
      },
      {
        key: "Shipping price",
        value: orderSummaryData?.total_shipping_price
          ? `${currency} ${orderSummaryData?.total_shipping_price}`
          : "-",
      },
      {
        key: "Total item",
        value: orderSummaryData?.total_price
          ? `${t("Grand total")}: ${currency} ${orderSummaryData?.total_price}`
          : "-",
      },
    ];
  };

  const handlePageChange = (event: any, pageNumber: number) => {
    setCurrentPage(pageNumber);
    setFilters({ ...filters, page: pageNumber });
    dispatch(
      getOrderTrackingDetails.request(
        removeEmptyValues({ ...filters, page: pageNumber })
      )
    );
  };

  const onSubmitMobile = (filters: any) => {
    dispatch(getOrderTrackingDetails.request(removeEmptyValues(filters)));
  };

  return (
    <>
      <Box
        sx={
          isMobile
            ? { ...searchFilterStyle.outer, alignItems: "center" }
            : searchFilterStyle.outer
        }
      >
        <Box
          sx={{
            ...searchFilterStyle.searchOuter,
            padding: { xs: "0", md: "0 10px 0  10px" },
            width: "20%",
          }}
        >
          <FloatSearch handleSearch={searchByChange} />
        </Box>
        {isMobile ? (
          <FloatMobileFilter
            filters={filters}
            setFilters={setFilters}
            selectFilters={SelectFilters}
            onSubmitMobile={onSubmitMobile}
          />
        ) : (
          <Box sx={searchFilterStyle.filterOuter}>
            {SelectFilters.map((item) => (
              <FLoatDropDown
                list={item.list}
                label={item.label}
                onChange={item.onChange}
                value={item.value}
                name={item.name}
              />
            ))}
          </Box>
        )}
      </Box>
      {trackingData.isLoading ? (
        <Box sx={styles.loaderBox}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {orderTrackingData && orderTrackingData?.paginatedData?.length > 0 ? (
              orderTrackingData?.paginatedData?.map((data: any, index: number) => {
                console.log(data, "currrrrrr");
                const currencySymbol = Currencies.find(
                  (item: any) => item.currency === data?.currency
                )?.symbol ?? "$";
                console.log(data, "currrrrrr", currencySymbol);
                return (
                  <StyledAccordion key={index}>
                    <StyledAccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        "& .MuiAccordionSummary-expandIconWrapper": {
                          padding: "0px !important",
                        },
                      }}
                    >
                      <Grid
                        container
                        alignItems={"center"}
                        sx={styles.gridContainer}
                      >
                        <Grid item xs={12} md={2.8}>
                          <Box sx={styles.textBox}>
                            <StyledText
                              fontvalue="18px"
                              lineheight="30px"
                              fontWeight="600"
                            >
                              {data?.line_items[0]?.name || "-"}
                            </StyledText>
                            {data?.line_items?.length > 1 && (
                              <StyledText
                                fontvalue="16px"
                                lineheight="30px"
                                fontWeight="500"
                                opacityValue="0.5"
                              >
                                + {data?.line_items?.length - 1} {t("more")}
                              </StyledText>
                            )}
                          </Box>
                          <StyledText
                            fontvalue="20px"
                            lineheight="35px"
                            fontWeight="600"
                          >
                            {currencySymbol} {data?.line_items[0]?.price || "-"}
                          </StyledText>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          sx={{
                            height: { xs: "fit-content", md: "100%" },
                            paddingTop: "8px",
                          }}
                        >
                          <StyledText>
                            {t("Order id")}: {data?.number || "-"}
                          </StyledText>
                          <StyledText lineheight="34px">
                            {t("Quantity")}:{" "}
                            {data?.line_items[0]?.quantity || "-"}
                          </StyledText>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2.6}
                          height={"100%"}
                          sx={{
                            height: { xs: "fit-content", md: "100%" },
                            padding: "8px 20px 0 0",
                          }}
                        >
                          <StyledText>
                            {t("Ordered on")}:{" "}
                            {formatDate(data?.order_date) || "-"}
                          </StyledText>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2.2}
                          sx={{
                            height: { xs: "fit-content", md: "100%" },
                            paddingTop: "8px",
                          }}
                        >
                          <StyledText>
                            {t("Delivered on")}: {data?.delivery_date || "-"}
                          </StyledText>
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                          <StyledButton
                            paddingvalue="8px 30px"
                            colour="#FFF"
                            background="#262626"
                            sx={{
                              ...styles.buyAgainButton,
                              width: { xs: "fit-content", lg: "170px" },
                              marginTop: "8px",
                            }}
                            onClick={(event) =>
                              buyAgain(event, data?.line_items, currencySymbol)
                            }
                          >
                            <img src={LocalMallIcon} />
                            <StyledText
                              color="#fff"
                              fontWeight="600"
                              sx={styles.buyAgainButton.buttonText}
                            >
                              {t("Buy again")}
                            </StyledText>
                          </StyledButton>
                        </Grid>
                      </Grid>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails
                      sx={styles.accordionDetailsStyle}
                      key={index}
                    >
                      <Grid container>
                        <Grid item xs={12} lg={2.4}>
                          <Box sx={styles.accordionBox}>
                            <Box sx={styles.detailsBox}>
                              <StyledText fontWeight="600">
                                {t("Name")}
                              </StyledText>
                              <StyledText lineheight="34px">
                                {`${data?.customer?.first_name} ${data?.customer?.last_name}` ??
                                  "-"}
                              </StyledText>
                            </Box>
                            <Box sx={styles.detailsBox}>
                              <StyledText fontWeight="600">
                                {t("Delivery address")}
                              </StyledText>
                              <StyledText lineheight="34px">
                                {data?.address?.address1 ?? "-"}
                              </StyledText>
                            </Box>
                            <Box sx={styles.detailsBox}>
                              <StyledText fontWeight="600">
                                {t("Email")}
                              </StyledText>
                              <StyledText
                                lineheight="18px"
                                sx={styles.styleText}
                              >
                                {data?.customer?.email || "-"}
                              </StyledText>
                            </Box>
                            <Box sx={styles.detailsBox}>
                              <StyledText fontWeight="600">
                                {t("Phone")}
                              </StyledText>
                              <StyledText lineheight="34px">
                                {data?.customer?.phone || "-"}
                              </StyledText>
                            </Box>
                            <Box sx={styles.detailsBox}>
                              <StyledText fontWeight="600">
                                {t("Transaction id")}
                              </StyledText>
                              <StyledText lineheight="34px">
                                {data?.transaction_ids[0] || "-"}
                              </StyledText>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} lg={9.6}>
                          <Grid container>
                            <Grid item xs={12} lg={9}>
                              {data?.line_items?.length > 0 &&
                                data?.line_items[0].requires_shipping && (
                                  <TrackinhgSteps
                                    stepsData={data?.line_items[0]}
                                  />
                                )}
                            </Grid>
                          </Grid>

                          <Grid container sx={styles.gridContainerStyles}>
                            <Box sx={styles.summaryBox}>
                              <Box>
                                <SubTitle1>{t("Order summary")}</SubTitle1>
                              </Box>
                              {OrderSummary(data,currencySymbol).map(
                                (info: any, index: any) => {
                                  return (
                                    <Stack
                                      key={index}
                                      sx={styles.summaryStack}
                                      direction={"row"}
                                    >
                                      {info.key === "Sub total" ? (
                                        <Box
                                          sx={orderHistoryStyle.summaryStackBox}
                                        >
                                          <Stack
                                            sx={{
                                              fontWeight: info.isBold
                                                ? "600"
                                                : "500",
                                            }}
                                          >
                                            {t(info.key)}
                                          </Stack>
                                          <StyledText
                                            fontvalue="14px"
                                            lineheight="16px"
                                            opacityValue="0.8"
                                            sx={
                                              orderHistoryStyle.summaryBoxStyle
                                            }
                                            onClick={() =>
                                              data?.line_items?.length &&
                                              openProductDetailsModal(
                                                data?.line_items,
                                                currencySymbol
                                              )
                                            }
                                          >
                                            {t("View details")}
                                          </StyledText>
                                        </Box>
                                      ) : (
                                        <Stack
                                          sx={{
                                            fontWeight: info.isBold
                                              ? "600"
                                              : "500",
                                          }}
                                        >
                                          {t(info.key)}
                                        </Stack>
                                      )}
                                      <Stack>{t(info.value)}</Stack>
                                    </Stack>
                                  );
                                }
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </StyledAccordionDetails>
                  </StyledAccordion>
                );
              })
          ) : (
            <Box sx={orderHistoryStyle.noRecordWrapper}>
              <StyledText sx={orderHistoryStyle.noRecordText}>
                {t("No records found.")}
              </StyledText>
            </Box>
          )}
        </>
      )}
      {totalItem && totalPages && currentPage ? (
        <CustomPagination
          page={currentPage}
          count={totalPages}
          handlePageChange={handlePageChange}
        />
      ) : (
        <></>
      )}
      {selectedProductDetails && (
        <FloatModal
          open={openProductDetails}
          onClose={closeProductDetails}
          modalTitle={t("Product details")}
          modalBody={<ProductDetails productData={selectedProductDetails} />}
        />
      )}
      {selectedProductDetails && (
        <FloatModal
          open={openBuyAgain}
          onClose={closeBuyAgain}
          modalTitle={t("Products to buy again")}
          modalBody={<BuyAgainProduct productData={selectedProductDetails} />}
        />
      )}
    </>
  );
};

export default TrackingReturns;
