import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
import { toast } from "react-toastify";
import { t } from "i18next";
const { REQUEST, CHANGE_VIDEO_THUMBNAIL } = actionTypes;

//write sagas function

function* changeVideoThumbmnailSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.changeVideoThumbnail,
      data.data
    );
    yield sendPayload(apiResponse, CHANGE_VIDEO_THUMBNAIL);
    toast.success(t("Thumbnail changed successfully"));
  } catch (e) {
    yield sendPayloadFailure(e, CHANGE_VIDEO_THUMBNAIL);
  }
}

export const sagas = {
  watchChangeVideoThumbnail: takeLatest(
    actionTypes.CHANGE_VIDEO_THUMBNAIL[REQUEST],
    changeVideoThumbmnailSaga
  ),
};
