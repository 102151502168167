import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, ASSIGN_ITEM } = actionTypes;

//write sagas function

function* assignItemSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.assignItem,
      data?.data
    );
    yield sendPayload(apiResponse, ASSIGN_ITEM);
  } catch (e) {
    yield sendPayloadFailure(e, ASSIGN_ITEM);
  }
}

export const sagas = {
  watchAssignItem: takeLatest(actionTypes.ASSIGN_ITEM[REQUEST], assignItemSaga),
};
