import { VideosHeadCellProps } from "../../../../models/backoffice.models";
import { isArrayIncludesKey, mmddyyyyFormat } from "../../../../utils/hooks";

export const sortByList = [
  { name: "Orders", key: "Orders" },
  { name: "Visits", key: "Visits" },
  { name: "Conversion", key: "Conversion" },
  { name: "Commission", key: "Commission" },
];

export const tableHeaders = (aggregate: string): VideosHeadCellProps[] => [
  {
    id: "aggregateValue",
    numeric: false,
    disablePadding: false,
    label: aggregate,
    align: "left",
  },
  {
    id: "orders",
    numeric: false,
    disablePadding: true,
    label: "Orders",
    align: "right",
  },
  {
    id: "visits",
    numeric: false,
    disablePadding: true,
    label: "Visits",
    align: "right",
  },
  {
    id: "commissions",
    numeric: false,
    disablePadding: true,
    label: "Commission",
    align: "right",
  },
  {
    id: "conversion",
    numeric: false,
    disablePadding: true,
    label: "Conversion",
    align: "right",
  },
];

export const processFiltersList = (
  commonFilterChange: any,
  aggregate: string,
  sortBy: string,
  aggregateList: any[]
) => {
  return [
    {
      list: aggregateList,
      label: "Aggregate by",
      name: "aggregate",
      onChange: commonFilterChange,
      value: aggregate,
    },
    {
      list: sortByList,
      label: "Sort by",
      name: "sortBy",
      onChange: commonFilterChange,
      value: sortBy,
    },
  ];
};

export const processCommission = (
  aggregateUrl: string,
  aggregateType: string,
  visits: any[]
) => {
  switch (aggregateType) {
    case "Origin":
      const totalCommission = visits.reduce((acc: any, currentValue: any) => {
        if (
          currentValue?.affiliate_commission &&
          currentValue.referrer === aggregateUrl
        ) {
          acc += parseFloat(currentValue.affiliate_commission);
        }
        return acc;
      }, 0);
      return parseFloat(totalCommission?.toFixed(2));
    case "Origin domain":
      const originDomainCommission = visits.reduce(
        (acc: number, currentValue: any) => {
          const ref = currentValue?.referrer ?? "";
          const affiliateCommission = parseFloat(
            currentValue?.affiliate_commission
          );
          if (!affiliateCommission) return acc;
          if (aggregateUrl && isArrayIncludesKey(ref,aggregateUrl)) {
            acc += affiliateCommission;
          } else if (!aggregateUrl && aggregateUrl?.length === ref.length) {
            acc += affiliateCommission;
          }
          return acc;
        },
        0
      );
      return parseFloat(originDomainCommission?.toFixed(2));
    case "Source":
      const sourceCommission = visits.reduce((acc: any, currentValue: any) => {
        if (
          currentValue?.affiliate_commission &&
          currentValue.source === aggregateUrl
        ) {
          acc += parseFloat(currentValue.affiliate_commission);
        }
        return acc;
      }, 0);
      return parseFloat(sourceCommission?.toFixed(2));
    case "Date":
      const dateCommission = visits.reduce((acc: any, currentValue: any) => {
        if (
          currentValue?.affiliate_commission &&
          mmddyyyyFormat(currentValue.site_visit_date) === aggregateUrl
        ) {
          acc += parseFloat(currentValue.affiliate_commission);
        }
        return acc;
      }, 0);
      return parseFloat(dateCommission?.toFixed(2));
    case "Landing page":
      const landingPageCommission = visits.reduce(
        (acc: any, currentValue: any) => {
          if (
            currentValue?.affiliate_commission &&
            currentValue.landing_page === aggregateUrl
          ) {
            acc += parseFloat(currentValue.affiliate_commission);
          }
          return acc;
        },
        0
      );
      return parseFloat(landingPageCommission?.toFixed(2));
    case "Landing page query":
      return 0.0;
    default:
      return "0";
  }
};

export const processOrders = (
  aggregateUrl: string,
  aggregateType: string,
  visits: any[]
) => {
  switch (aggregateType) {
    case "Origin":
      const orders = visits?.filter((item: any) => {
        const ref = item.referrer ?? "";
        return ref === aggregateUrl && item.order_id;
      });
      return orders.length ?? 0;
    case "Origin domain":
      const domainOrders = visits?.filter((item: any) => {
        const ref = item?.referrer ?? "";
        return !aggregateUrl
          ? ref.length === aggregateUrl.length && item.order_id
          : isArrayIncludesKey(ref,aggregateUrl) && item.order_id;
      });
      return domainOrders?.length ?? 0;
    case "Source":
      const sourceOrders = visits?.filter((item: any) => {
        const ref = item.source ?? "";
        return ref === aggregateUrl && item.order_id;
      });
      return sourceOrders.length ?? 0;
    case "Date":
      const dateOrders = visits?.filter((item: any) => {
        const ref = mmddyyyyFormat(item.site_last_visit) ?? "";
        return ref === aggregateUrl && item.order_id;
      });
      return dateOrders.length ?? 0;
    case "Landing page":
      const landingPageOrders = visits?.filter((item: any) => {
        const ref = item?.landing_page ?? "";
        return ref === aggregateUrl && item.order_id;
      });
      return landingPageOrders?.length ?? 0;
    default:
      return "0";
  }
};
