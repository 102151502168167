export const styles = {
  filterContain: {
    width: "100%",
    mt: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  loaderContainer: {
    minHeight: "272px",
    minWidth: "785px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  filterBox:{ width: "100%", maxWidth: "calc(100% - 10px)" },
childBox:{
  width: "800px",
  height: "100%",
  marginBottom: "20px",
}
};
