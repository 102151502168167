import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, SUBSCRIPTION_DETAILS } = actionTypes;

function* subscriptionDetailSagas(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getSubscriptionDetails,
      data.data
    );
    yield sendPayload(apiResponse, SUBSCRIPTION_DETAILS);
  } catch (e) {
    yield sendPayloadFailure(e, SUBSCRIPTION_DETAILS);
  }
}

export const sagas = {
  watchSubscriptionDetail: takeLatest(
    actionTypes.SUBSCRIPTION_DETAILS[REQUEST],
    subscriptionDetailSagas
  ),
};
