import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../../actions";
import * as ApiService from "../../services";
import { sendPayload, sendPayloadFailure } from ".././helper";
const {
  REQUEST,
  DELETE_ADDRESSS,
  SET_DEFAULT_ADDRESS,
  GET_ALL_ADDRESSES,
  UPDATE_ADDRESS,
} = actionTypes;

function* deleteAccountAddress(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.deleteAddress,
      data.data
    );
    yield sendPayload(apiResponse, DELETE_ADDRESSS);
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_ADDRESSS);
  }
}

function* setDefaultAddress(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.setDefaultAddress,
      data.data
    );
    yield sendPayload(apiResponse, SET_DEFAULT_ADDRESS);
  } catch (e) {
    yield sendPayloadFailure(e, SET_DEFAULT_ADDRESS);
  }
}

function* updateAddresses(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updateAddress,
      data.data
    );
    yield sendPayload(apiResponse, UPDATE_ADDRESS);
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_ADDRESS);
  }
}

function* getAllAddresses(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAllAccountAddresses,
      data.data
    );
    yield sendPayload(apiResponse, GET_ALL_ADDRESSES);
  } catch (e) {
    yield sendPayloadFailure(e, GET_ALL_ADDRESSES);
  }
}

export const sagas = {
  watchDeleteAddressSaga: takeLatest(
    actionTypes.DELETE_ADDRESSS[REQUEST],
    deleteAccountAddress
  ),
  watchSetDefualtaddressSaga: takeLatest(
    actionTypes.SET_DEFAULT_ADDRESS[REQUEST],
    setDefaultAddress
  ),
  watchallAddressesSaga: takeLatest(
    actionTypes.GET_ALL_ADDRESSES[REQUEST],
    getAllAddresses
  ),
  watchUpdateAddressSaga: takeLatest(
    actionTypes.UPDATE_ADDRESS[REQUEST],
    updateAddresses
  ),
};
