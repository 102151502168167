import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, REACTIVATE_SUBSCRIPTION } = actionTypes;

//write sagas function

function* reactivateSubscriptionSagas(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.reactivateSubscriptionApi,
      data.data
    );
    yield sendPayload(apiResponse, REACTIVATE_SUBSCRIPTION);
  } catch (e) {
    yield sendPayloadFailure(e, REACTIVATE_SUBSCRIPTION);
  }
}

export const sagas = {
  watchReactivateSubscription: takeLatest(
    actionTypes.REACTIVATE_SUBSCRIPTION[REQUEST],
    reactivateSubscriptionSagas
  ),
};
