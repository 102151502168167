import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";

const { SUCCESS, REQUEST, TOTAL_VIEWS } = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const totalViewsData: any = (state = intialState, action: actions) => {
    switch (action.type) {
      case TOTAL_VIEWS[SUCCESS]:
        return { ...state, data: action.payload.data, isLoading: false };
      case TOTAL_VIEWS[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload.data, isLoading: false }
          : state;
      case TOTAL_VIEWS[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  return combineReducers({
    totalViewsData,
  });
};

export default entity;

export const totalViewsData = (state: RootState) =>
  state.entities.totalViewsData;
