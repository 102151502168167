import { put } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { addAuthToken } from "../services/rest";
import { toast } from "react-toastify";
import { t } from "i18next";
import { iskeyInObject } from "../utils/hooks";
const { SUCCESS, FAILURE } = actionTypes;

export function* sendPayload(apiResponse: any, event: { [x: string]: any }) {
  if (event[SUCCESS] === "LOGIN_SUCCESS" && apiResponse.data) {
    localStorage.setItem("token", apiResponse.data.data);
    addAuthToken();
  }
  if (event[SUCCESS] === "REFRESH_SUCCESS" && apiResponse.data) {
    localStorage.setItem("token", apiResponse?.data?.data);
    addAuthToken();
  }
  yield put({
    type: apiResponse ? event[SUCCESS] : event[FAILURE],
    payload: apiResponse
      ? apiResponse
        ? apiResponse.data
        : apiResponse.data.error
      : {},
  });
}

export function* sendPayloadFailure(error: any, event: { [x: string]: any }) {
  if (error.response) {
    if (error.response.data.message === "JwtAccessTokenExpiredError") {
      window.location.reload();
    }
    if (error.response.data.message === "JwtRefreshTokenExpiredError") {
      localStorage.clear();
    }

    const { data } = error.response;
    if (iskeyInObject(data,"message")) {
      if (
        !(
          error.response.data.message === "JwtAccessTokenExpiredError" ||
          error.response.data.message === "JwtRefreshTokenExpiredError"
        )
      ) {
        toast.error(t(data?.message));
      }
    }
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/unauthorized";
    }
    if (error.response.status === 403) {

    }
    yield put({
      type: event[FAILURE],
      payload: error.response ? error.response : {},
    });
  } else {
    yield put({
      type: event[FAILURE],
      payload: error,
    });
  }
}
