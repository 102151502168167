import { Box, CircularProgress, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { StyledText, SubTitle1, SubTitle2 } from "../../../utils/styledComponents";
import { analyticsStyles, paymentStyle } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getGoaffproPayments,
  getPaymentDetails,
  getPaymentSummary,
} from "../../../actions";
import { RootState } from "../../../configureStore";
import { convertDateFromServer } from "../../../utils/hooks";
import { useTranslation } from "react-i18next";

interface IPayoutData {
  amount_earned: number;
  data: any[];
  amount_pending: number;
  amount_under_request: number;
  amount_paid: number;
  last_payment: null | string;
  amount_payable: number;
  payout_lag_days: string;
}

function Payment() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { paymentSummary } = useSelector(
    (state: RootState) => state?.entities?.paymentData
  ) as any;

  const { paymentDetails } = useSelector(
    (state: RootState) => state?.entities?.paymentData
  ) as any;

  const { goaffproPaymentsData } = useSelector(
    (state: RootState) => state?.entities?.goaffproPayment
  ) as any;

  useEffect(() => {
    dispatch(getPaymentSummary.request());
    dispatch(getPaymentDetails.request());
    dispatch(getGoaffproPayments.request({}));
  }, []);

  const payoutData: IPayoutData = {
    amount_earned: 0,
    data: [],
    amount_pending: 0,
    amount_under_request: 0,
    amount_paid: 0,
    last_payment: null,
    amount_payable: 0,
    payout_lag_days: "35",
  };

  const amountDetails = {
    "Amount paid": goaffproPaymentsData?.data?.amount_paid ?? "-",
    "Amount pending": goaffproPaymentsData?.data?.amount_pending ?? "-",
    [`${t("Due in")} ${goaffproPaymentsData?.data?.due_days ?? "-"} ${"days"}`]:
      goaffproPaymentsData?.data?.amount_due ?? "-",
  };

  return (
    <>
      {(paymentSummary && paymentSummary.isLoading) ||
      (paymentDetails && paymentDetails.isLoading) ||
      (goaffproPaymentsData && goaffproPaymentsData?.isLoading) ? (
        <Box sx={paymentStyle.loaderBox}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <StyledText
            fontvalue="16px"
            lineheight="24px"
            sx={paymentStyle.subHeading}
          >
            {t("Summary")}
          </StyledText>
          <Box sx={analyticsStyles.boxBG}>
            <Grid container spacing={2}>
              {Object.entries(amountDetails).map(([key, value]:any) => {
                  return (
                    <Grid key={key} item xs={12} sm={6} md={4} lg={4} xl={4}>
                      <Stack
                        justifyContent={"center"}
                        alignItems={"center"}
                        height={"86px"}
                        sx={analyticsStyles.whiteBG}
                      >
                        <SubTitle1>
                          
                            ${value}
                          
                        </SubTitle1>
                        <SubTitle2>{t(key)}</SubTitle2>
                      </Stack>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
          <StyledText
            fontvalue="16px"
            lineheight="24px"
            sx={paymentStyle.subHeading}
          >
            {t("Details")}
          </StyledText>
          {paymentDetails &&
          paymentDetails.data &&
          paymentDetails.data.data &&
          paymentDetails.data.data.amount?.length > 0 ? (
            <Box sx={paymentStyle.amountWrapper}>
              {paymentDetails.data.data.amount.map(
                (item: any, index: number) => (
                  <Box key={index} sx={paymentStyle.amountWrapper.amountItem}>
                    <StyledText fontvalue="24px" lineheight="36px">
                      {item.amount ? `$${item.amount}` : ""}
                    </StyledText>
                    <StyledText fontvalue="14px" lineheight="21px">
                      {item.payment_title}
                    </StyledText>
                    <StyledText fontvalue="14px" lineheight="21px">
                      {convertDateFromServer(item.payout_date)}
                    </StyledText>
                  </Box>
                )
              )}
            </Box>
          ) : (
            <Box sx={paymentStyle.noDataWrapper}>
              <StyledText>{t("No payments have been made yet")}</StyledText>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default Payment;
