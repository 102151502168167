import { TableHead } from '@mui/material';
import React from 'react';
import { StyledTableHeadCell, StyledTableHeadRow } from '../../../utils/styledComponents';
import { useTranslation } from 'react-i18next';
import { HeadCell, headCells } from './helper';

const NetworkTableOneHeader = () => {
    const {t} = useTranslation();
    return (
        <TableHead>
          <StyledTableHeadRow>
            {headCells.map((headCell: HeadCell, index: number) => {
              {
                return (
                  <StyledTableHeadCell
                    key={index}
                    align="left"
                    id={headCell.id}
                    scope="row"
                    width={headCell.width}
                  >
                    {t(headCell.label)}
                  </StyledTableHeadCell>
                );
              }
            })}
          </StyledTableHeadRow>
        </TableHead>
      );
};

export default NetworkTableOneHeader;

