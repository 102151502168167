import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styles } from "../styles";
import { RootState } from "../../../configureStore";
import {
  getCreatorAthenaAnalytics,
  getCreatorLibraryAnalytics,
  grabCreatorLibraryList,
} from "../../../actions";
import {
  StyledTableHeadCell,
  StyledTableHeadRow,
  StyledText,
} from "../../../utils/styledComponents";
import FloatSearch from "../../../shared/components/FloatSearch";
import FLoatDropDown from "../../../shared/components/FLoatDropDown";
import { stylesGlobal } from "../../../utils/muiStyles";
import FloatModal from "../../../shared/components/FloatModal";
import AddVideoModal from "../../videoDetails/AddVideoModal";
import CustomPagination from "../../../shared/components/CustomPagination";
import MultipleSelectDropdown from "../../../shared/components/Multiselect";
import FloatMobileFilter from "../../../shared/components/FloatMobileFilter";
import { creatorLibraryStyle } from "./style";
import {
  CreatorLibHeadCell,
  CreatorLibRowProps,
  CreatorLibVideoProps,
} from "../../../models/backoffice.models";
import CreatorLibraryTblRow from "./CreatorLibraryTblRow";
import CreatorLibraryMobileRow from "./CreatorLibraryMobileRow";
import {
  creatorLibColumnList,
  defaultFilters,
  headCellsMobile,
  multiselectList,
  selectFilterHook,
} from "./helper";
import CreatorLibVideoHead from "./CreatorLibVideoHead";
import { THIN_BORDER_COLOR } from "../../../colors";
import { removeEmptyValues } from "../../../utils/hooks";

const PAGE_LIMIT = 10;

function CreatorLibrary() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTab = useMediaQuery("(max-width:900px)");

  const { searchFilterStyle } = stylesGlobal;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const creatorAthenaAccess =
    localStorage.getItem("lastCreatorAthenaRefreshTime") || "";

  const { creatorLibraryAnalyticsList, creatorLibraryVideoList }: any =
    useSelector((state: RootState) => state?.entities?.creatorLibrary);

  const { creatorAthenaAnalyticsData }: any = useSelector(
    (state: RootState) => state?.entities?.creatorAthenaAnalytics
  );

  const [videos, setVideos]: any[] = useState([]);
  const [analytics, setAnalytics]: any = useState([]);
  const [rows, setRowData]: any[] = useState([]);
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [multilistInfo, setMultiList] = useState(
    creatorLibColumnList?.map((item) => item.name)
  );
  const [openAddVideoModal, setOpenAddVideoModal] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>();
  const [currentData, setCurrentPage] = useState<number>();
  const [totalItem, setTotalItem] = useState<any>();
  const [tigModalController, setTigModalController] = useState<any>();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [showRefreshButton, setShowRefreshButton] = useState(false);

  const [filters, setFilters] = React.useState({
    ...defaultFilters,
    page: currentData,
    limit: PAGE_LIMIT,
  });

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (debouncedSearchTerm?.length > 0) {
        setFilters({ ...filters, searchValue: debouncedSearchTerm });
        dispatch(
          grabCreatorLibraryList.request(
            removeEmptyValues({
              ...{ ...filters, searchValue: debouncedSearchTerm },
              page: 1,
            })
          )
        );
      }
    }, 1000);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (
      !creatorLibraryVideoList?.isLoading &&
      !creatorLibraryVideoList?.isError
    ) {
      const contentIds = creatorLibraryVideoList?.data?.data?.map(
        (item: any) => item.content_id
      );
      setVideos(creatorLibraryVideoList?.data?.data);
      dispatch(getCreatorLibraryAnalytics.request({ content_ids: contentIds }));

      setTotalPages(creatorLibraryVideoList?.data?.total_pages ?? 1);
      setCurrentPage(creatorLibraryVideoList?.data?.current_page ?? 1);
      setTotalItem(creatorLibraryVideoList?.data?.total_pages ?? 1);
    }
  }, [creatorLibraryVideoList?.isLoading, creatorLibraryVideoList]);

  const handleApiCall = () => {
    localStorage.removeItem("lastCreatorAthenaRefreshTime");
    dispatch(getCreatorAthenaAnalytics.request({}));
    setShowRefreshButton(false);
  };

  useEffect(() => {
    const lastRefreshTime: any = localStorage.getItem(
      "lastCreatorAthenaRefreshTime"
    );
    const oneHourInSeconds = 60 * 60; // 1 hour in seconds
    const t = new Date();
    const currentTime: any = t.getTime();
    const elapsedTime = Math.floor(
      (currentTime - parseInt(lastRefreshTime, 10)) / 1000
    );
    if (elapsedTime >= oneHourInSeconds) {
      setShowRefreshButton(true);
    }
  }, []);

  useEffect(() => {
    setMultiList(creatorLibColumnList?.map((item) => t(item.name)));
  }, [i18n.language]);

  useEffect(() => {
    if (!creatorLibraryAnalyticsList?.isLoading) {
      setAnalytics(creatorLibraryAnalyticsList?.data);
    }
  }, [creatorLibraryAnalyticsList?.isLoading]);

  useEffect(() => {
    dispatch(
      grabCreatorLibraryList.request(
        removeEmptyValues({ ...filters, orderBy: "", limit: 10 })
      )
    );
    return () => {
      setVideos([]);
      setAnalytics([]);
    };
  }, []);

  useEffect(() => {
    let combine: any = [];
    let rows: any = [];

    if (videos?.length || analytics?.length) {
      videos?.map((item: any) => {
        combine.push({
          ...item,
          ...analytics?.find((stat: any) => stat.content_id == item.content_id),
        });
      });
    }
    combine?.map((item: any) => {
      let row = {
        id: item.content_id,
        thumbnail: item.ogp_thumbnail_url,
        title: item.title,
        description: item.description,
        collections: item.collections,
        playlist_url: item.video_url,
        identifier: item?.content_identifier,
        creatorHandle: item.creator_handle,
        item_details: item?.item_details ?? [],
        views: creatorLibraryVideoList?.isLoading
          ? "..."
          : videos?.length && item?.views_data.length
          ? item?.views_data[0]?.total_views
          : analytics?.length
          ? item?.total_views
          : "-",
        unique: creatorLibraryVideoList?.isLoading
          ? "..."
          : videos?.length && item?.views_data.length
          ? item?.views_data[0]?.total_unique_views
          : analytics.length
          ? item?.total_unique_views
          : "-",
        clicks: creatorLibraryAnalyticsList?.isLoading
          ? "..."
          : analytics?.length
          ? item.product_clicks ?? 0
          : "-",
      };
      rows.push(row);
    });
    setRowData(rows);
  }, [videos, analytics]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const closeVideoModal = () => {
    setOpenAddVideoModal(false);
  };

  const seachByChange = (e: any) => {
    e.preventDefault();
    if (e.target.value?.length === 0) {
      setFilters({ ...filters, searchValue: "" });
      dispatch(
        grabCreatorLibraryList.request(
          removeEmptyValues({
            ...{ ...filters, searchValue: "" },
            page: 1,
          })
        )
      );
    }
    setDebouncedSearchTerm(e.target.value);
  };

  function CreatorVideoHeadMobile(props: CreatorLibVideoProps) {
    return (
      <TableHead>
        <StyledTableHeadRow sx={creatorLibraryStyle.headrow}>
          {headCellsMobile.map(
            (headCell: CreatorLibHeadCell, index: number) => {
              if (
                headCell.label === "Video" ||
                multilistInfo
                  .map((item: any) => t(item))
                  ?.includes(t(headCell.label))
              ) {
                return (
                  <StyledTableHeadCell
                    key={index}
                    // align="left"
                    id={headCell.id}
                    scope="row"
                    sx={{ width: "8rem" }}
                  >
                    {t(headCell.label)}
                  </StyledTableHeadCell>
                );
              }
              return null;
            }
          )}
        </StyledTableHeadRow>
      </TableHead>
    );
  }

  const commonFilterChange = (e: any) => {
    let name = e.target.name;
    let data = {
      sortKey: name === "sortKey" ? e.target.value : filters.sortKey,
      searchValue:
        name === "searchValue" ? e.target.value : filters.searchValue,
      searchKey: name === "searchKey" ? e.target.value : filters.searchKey,
      sortOrder: name === "sortOrder" ? e.target.value : filters.sortOrder,
    };
    setFilters({ ...filters, ...data });
    if (name === "searchKey" && filters.searchValue === "") {
    } else {
      dispatch(grabCreatorLibraryList.reset());
      dispatch(
        grabCreatorLibraryList.request(
          removeEmptyValues({
            ...filters,
            ...data,
            page: currentData,
            limit: 10,
          })
        )
      );
    }
  };

  const SelectFilters = selectFilterHook(
    filters,
    commonFilterChange,
    creatorAthenaAnalyticsData,
    creatorAthenaAccess
  );

  const handlePageChange = (event: any, pageNumber: number) => {
    setCurrentPage(pageNumber);
    dispatch(
      grabCreatorLibraryList.request(
        removeEmptyValues({
          ...filters,
          page: pageNumber,
          limit: 10,
        })
      )
    );
  };

  useEffect(() => {
    function processTigCreatorCustomEmbedFunc() {
      if (rows?.length > 0) {
        window.initTigCreatorEmbed({
          type: "custom",
          customContentsList: rows,
          customReadyCallback: function (controller: any) {
            setTigModalController(controller);
          },
        });
      }
    }
    if (
      document.readyState === "interactive" ||
      document.readyState === "complete"
    ) {
      processTigCreatorCustomEmbedFunc();
    } else {
      window.addEventListener("DOMContentLoaded", function () {
        processTigCreatorCustomEmbedFunc();
      });
    }
  }, [rows]);

  const openPlayerModal: any = (contentIdentifier: any) => {
    tigModalController?.open(contentIdentifier);
  };

  const onPreview = (
    event: React.ChangeEvent<HTMLButtonElement>,
    video: any
  ) => {
    event.preventDefault();
    event.stopPropagation();
    openPlayerModal(video?.identifier);
  };

  const setColumnsMobile = React.useCallback((value: any) => {
    setMultiList(value);
  }, []);

  const onSubmitMobile = (filters: any) => {
    dispatch(
      grabCreatorLibraryList.request(
        removeEmptyValues({
          ...filters,
          orderBy: "",
          limit: 10,
        })
      )
    );
  };

  return (
    <Box sx={styles.boxStyle}>
      <Box
        sx={
          isTab
            ? { ...searchFilterStyle.outer, alignItems: "center" }
            : searchFilterStyle.outer
        }
      >
        <Box sx={searchFilterStyle.searchOuter}>
          <FloatSearch disabled={false} handleSearch={seachByChange} />
        </Box>
        {isTab ? (
          <FloatMobileFilter
            setColumnsMobile={(values: any) => setColumnsMobile(values)}
            filters={filters}
            setFilters={setFilters}
            multiSelect={multiselectList}
            selectFilters={SelectFilters}
            onSubmitMobile={onSubmitMobile}
          />
        ) : (
          <Box sx={searchFilterStyle.filterOuter}>
            {SelectFilters.map((item, index) => (
              <FLoatDropDown
                key={index}
                list={item.list}
                label={item.label}
                isAthenaFilter={item?.isAthenaFilter}
                name={item.name}
                onChange={item.onChange}
                value={item.value}
                disabled={item.disabled}
              />
            ))}
            <MultipleSelectDropdown
              names={creatorLibColumnList?.map((item) => item.name)}
              setUpdatedList={setMultiList}
              label="Column visibility"
            />
          </Box>
        )}
      </Box>
      {showRefreshButton && !creatorAthenaAnalyticsData?.isLoading && (
        <Paper sx={{ padding: "5px", mb: "10px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledText>
              {t(
                "Click refresh to update the most recent video list and statistics"
              )}
            </StyledText>
            <Button
              variant="contained"
              sx={{
                ...stylesGlobal.containedBtn,
                width: "100px",
                mt: "0px",
                border: `1px solid ${THIN_BORDER_COLOR}`,
                fontWeight: "500",
                padding: "6px 10px !important",
              }}
              onClick={handleApiCall}
            >
              {t("Refresh")}
            </Button>
          </Box>
        </Paper>
      )}
      {creatorLibraryVideoList?.isLoading ? (
        <Box sx={styles.loaderBox}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!isMobile ? (
            <Paper sx={styles.paperStyle}>
              {rows?.length > 0 ? (
                <TableContainer>
                  <Table
                    stickyHeader
                    className="overflow-y-tbl"
                    sx={styles.tableContainer.table}
                    aria-labelledby="tableTitle"
                  >
                    <CreatorLibVideoHead multilistInfo={multilistInfo} />
                    <TableBody>
                      {rows?.length > 0 &&
                        rows?.map((row: CreatorLibRowProps, index: number) => {
                          return (
                            <CreatorLibraryTblRow
                              row={row}
                              index={multilistInfo}
                              multilistInfo={multilistInfo}
                              onPreview={onPreview}
                            />
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box sx={styles.noRecord}>
                  <StyledText fontvalue="16px">
                    {t("No Records Found.")}
                  </StyledText>
                </Box>
              )}
            </Paper>
          ) : (
            <Paper sx={{ ...styles.paperStyle, maxWidth: "600px" }}>
              {rows?.length > 0 ? (
                <TableContainer>
                  <Table
                    stickyHeader
                    sx={styles.tableContainer.table}
                    aria-labelledby="tableTitle"
                  >
                    <CreatorVideoHeadMobile
                      numSelected={selected.length}
                      onSelectAllClick={handleSelectAllClick}
                      rowCount={rows.length}
                      multilistInfo={multilistInfo}
                    />
                    <TableBody>
                      {rows?.length > 0 &&
                        rows?.map((row: CreatorLibRowProps, index: number) => {
                          return (
                            <CreatorLibraryMobileRow
                              row={row}
                              index={index}
                              pageLimit={PAGE_LIMIT}
                              multilistInfo={multilistInfo}
                              onPreview={onPreview}
                            />
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box sx={styles.noRecord}>
                  <StyledText fontvalue="16px">
                    {t("No records found.")}
                  </StyledText>
                </Box>
              )}
            </Paper>
          )}
        </>
      )}
      <FloatModal
        modalTitle={t("Add video")}
        onClose={() => setOpenAddVideoModal(false)}
        modalBody={
          <AddVideoModal
            changeThumbnailCallback={(e: any) => {}}
            handleClose={closeVideoModal}
          />
        }
        open={openAddVideoModal}
      />
      {rows?.length > 0 && totalItem && totalPages && currentData && (
        <CustomPagination
          page={currentData}
          count={totalPages}
          handlePageChange={handlePageChange}
        />
      )}
    </Box>
  );
}

export default CreatorLibrary;
