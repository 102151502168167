import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";

const { SUCCESS, REQUEST, GET_ORDER_HISTORY } = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const orderHistory = (state = {}, action: actions) => {
    switch (action.type) {
      case GET_ORDER_HISTORY[SUCCESS]:
        return { ...state, data: action.payload, isLoading: false };
      case GET_ORDER_HISTORY[REQUEST]:
        return intialState;
      case GET_ORDER_HISTORY[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  return combineReducers({
    orderHistory,
  });
};

export default entity;

export const orderHistory = (state: RootState) => state.entities.orderHistory;
