import { Box, Paper, Table, TableBody, TableContainer } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styles } from '../styles';
import VideosTableHead from './VideosTableHead';
import { StyledTableCell, StyledTableRow, StyledText } from '../../../utils/styledComponents';
import VideosProductsRow from './VideosProductsRow';
import VideosAnalyticsActionRow from './VideosAnalyticsActionRow';
import { VideoeRowProps } from '../../../models/backoffice.models';
import { convertToPlainText, isVisibleCol } from './helper,';
import { CONSTANTS } from '../../../utils/constants';
import { RootState } from '../../../configureStore';

const VideosListUI = ({rows,multilistInfo,onPreview,videos,RowActions,userRole,isMobile}:any) => {
      const { t } = useTranslation();
    const { getVideosData } = useSelector(
        (state: RootState) => state?.entities?.videos
    );
    return (
        <Paper sx={styles.paperStyle}>
        {rows?.length > 0 ? (
          <TableContainer className="overflow-x-contain">
            <Table
              stickyHeader
              className="overflow-y-tbl"
              sx={styles.tableContainer.table}
              aria-labelledby="tableTitle"
            >
              <VideosTableHead
                rowCount={rows.length}
                multilistInfo={multilistInfo}
              />
              <TableBody>
                {rows?.length > 0
                  ? rows?.map((row: VideoeRowProps, index: number) => {
                      return (
                        <React.Fragment>
                          <StyledTableRow
                            key={index}
                            hover
                            role="checkbox"
                            sx={styles.rowStyle}
                          >
                            <StyledTableCell align="left">
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "start",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  display={"flex"}
                                  sx={{
                                    minWidth: "130px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    src={row.thumbnail}
                                    style={{
                                      height: "80px",
                                      width: "auto",
                                      maxWidth: "130px",
                                      cursor:"pointer"
                                    }}
                                    alt=""
                                    onClick={(e: any) => onPreview(e, row)}
                                  />
                                </Box>
                              </Box>
                            </StyledTableCell>
                            {isVisibleCol(multilistInfo, "Title", t) && (
                              <StyledTableCell align="left">
                                <StyledText sx={styles.tableContainer.titleBox}>
                                  {row.title ?? "-"}
                                </StyledText>
                              </StyledTableCell>
                            )}
                            {isVisibleCol(multilistInfo, "Creator handle", t) &&
                              userRole === CONSTANTS.roles.INFLUENCER && (
                                <StyledTableCell align="left">
                                  {row.creator_handle ?? "-"}
                                </StyledTableCell>
                              )}
                            {isVisibleCol(multilistInfo, "Description", t) && (
                              <StyledTableCell align="left">
                                <Box
                                  className="overflow-y"
                                  sx={{ height: "100%",minWidth:"150px",  maxWidth: "250px"}}
                                >
                                  <Box
                                    sx={{
                                      fontFamily: "Jost",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      lineHeight: "16px",
                                    }}
                                  >
                                    {row.description
                                      ? convertToPlainText(row.description)
                                      : "-"}
                                  </Box>
                                </Box>
                              </StyledTableCell>
                            )}
                            {isVisibleCol(multilistInfo, "Collection", t) && (
                              <>
                                {row.collections?.length > 0 ? (
                                  <StyledTableCell
                                    align="left"
                                    sx={{ whiteSpace: "nowrap" }}
                                  >
                                    {row.collections.join(", ")}
                                  </StyledTableCell>
                                ) : (
                                  <StyledTableCell align="left">
                                    -
                                  </StyledTableCell>
                                )}
                              </>
                            )}

                            {isVisibleCol(multilistInfo, "Content id", t) && (
                              <StyledTableCell align="left">
                                {row.id}
                              </StyledTableCell>
                            )}
                          </StyledTableRow>
                          <VideosProductsRow row={row} />
                          <VideosAnalyticsActionRow
                            row={row}
                            actions={RowActions}
                            mobile={isMobile}
                          />
                        </React.Fragment>
                      );
                    })
                  : videos?.length === 0 &&
                    !getVideosData?.isLoading && (
                      <StyledText>{t("No records found.")}</StyledText>
                    )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          videos?.length === 0 &&
          !getVideosData?.isLoading && (
            <Box sx={styles.noRecordsBox}>
              <StyledText>{t("No records found.")}</StyledText>
            </Box>
          )
        )}
      </Paper>
    );
};

export default VideosListUI;