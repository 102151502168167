import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, SAVE_VIDEO_DETAILS } = actionTypes;

function* saveVideoDetailSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.saveVideoDetails,
      data.data
    );
    yield sendPayload(apiResponse, SAVE_VIDEO_DETAILS);
  } catch (e) {
    yield sendPayloadFailure(e, SAVE_VIDEO_DETAILS);
  }
}

export const sagas = {
  watchSaveVideoDetails: takeLatest(
    actionTypes.SAVE_VIDEO_DETAILS[REQUEST],
    saveVideoDetailSaga
  ),
};
