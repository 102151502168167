import { combineReducers } from "redux";
import entities, * as Entities from "./entities";
import ui, * as UI from "./ui";

export const loginEntity = Entities.loginState;
export const refreshEntity = Entities.refreshTokenState;
export const totalGraphviewEntity = Entities.totalGraphViewState;
export const planEntity = Entities.planDataState;
export const grabProductsEntity = Entities.grabProductsState;
export const clickStatsWeeklyEntity = Entities.clickStatsWeeklyState;
export const networkInfluencerEntity = Entities.influencerNetworkState;
export const totalGlobalStatsEntity = Entities.totalGlobalStatsState;
export const addPyvitProductEntity = Entities.addPyvitProductState;
export const uploadCustomProductEntity = Entities.uploadCustomProductState;
export const saveProductEntity = Entities.saveProductState;
export const deleteProductEntity = Entities.deleteProductState;
export const editProductEntity = Entities.editProductState;
export const videosEntity = Entities.videosState;
export const videoAnalyticsEntity = Entities.videosAnalyticsState;
export const addVideoEntity = Entities.addVideoState;
export const videoDetailEntity = Entities.videoDetailState;
export const deleteVideoEntity = Entities.deleteVideoState;
export const saveVideoDetailEntity = Entities.saveVideoDetailState;
export const changeVideoThumbnailEntity = Entities.changeVideoThumbnailState;
export const unassignProductEntity = Entities.unassignProductState;
export const editVideoAuthEntity = Entities.editVideoAuthState;
export const assignItemEntity = Entities.assignItemState;
export const productsByVideoEntity = Entities.productByVideoState;
export const videoViewsStatsEntity = Entities.videoViewsState;
export const uniqueVideoViewsEntity = Entities.uniqueVideoViewsState;
export const videoProductClicksEntity = Entities.videoProductClickState;
export const analyticsVideoEntity = Entities.analyticsVideoState;
export const userProfileEntity = Entities.userProfileState;
export const nomadCreateEntity = Entities.createNomadState;
export const nomadDashboardEntity = Entities.nomadDashboardState;
export const accountProfileEntity = Entities.accountProfileState;
export const accountAddressEntity = Entities.accountAddressState;
export const accountAddressCreateEntity = Entities.createAccountAddressState;
export const orderHistoryEntity = Entities.orderHistory;
export const subsciptionAlertEntity = Entities.subscriptionAlert;
export const creatorLibraryEntity = Entities.creatorLibraryState;
export const activatePauseVideoEntity = Entities.activatePauseVideo;
export const affiliateAddressEntity = Entities.affiliateProfileState;
export const transactionDataEntity = Entities.transactionData;
export const purchaseAlertEntity = Entities.purchaseAlertState;
export const translation = Entities.translation;
export const uploadProfilePhoto = Entities.uploadProfilePhoto;
export const goaffproPaymentEntity = Entities.goaffproPaymentState;
export const athenaAnalyticsEntity = Entities.athenaAnalyticsState;
export const goaffproAnalyticsEntity = Entities.goaffproAnalyticsState;

export const loginUI = UI.loginState;

export default () =>
  combineReducers({
    entities,
    ui,
  });
