import React, { useCallback, useEffect, useState } from "react";
import { orderSteps } from "./helper";
import Delivered from "./statuses/Delivered";
import Cancelled from "./statuses/Cancelled";
import Returned from "./statuses/Returned";

function TrackinhgSteps(props: any) {
  const { tracking_status } = props.stepsData || {};
  const [active, setActive] = useState(1);

  useEffect(() => {
    let activeData: any = orderSteps?.find((item) => tracking_status == item.response) ?? 0;
    setActive(activeData?.step);
  }, [tracking_status]);

  const step = useCallback(() => {
    switch (tracking_status) {
      case "cancelled":
        return <Cancelled />;
      case "Returned":
        return <Returned />;
      default:
        return <Delivered active={active} />;
    }
  }, [tracking_status, active]);
  
  return step()
};

export default TrackinhgSteps;
