import { ACCTypes } from "./Types";
export const REQUEST = "REQUEST";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const RESET = "RESET";

export const createRequestTypes = (base: string) => {
  return [REQUEST, SUCCESS, FAILURE, RESET].reduce((acc: ACCTypes, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
};

export const action = (type: string, payload = {}) => {
  return { type, ...payload };
};

export const LOGIN = createRequestTypes("LOGIN");
export const REFRESH = createRequestTypes("REFRESH");
export const TOTAL_VIEWS = createRequestTypes("TOTAL_VIEWS");
export const TOTAL_VIEW_GRAPH = createRequestTypes("TOTAL_VIEW_GRAPH");
export const CLICK_STATS_WEEKLY = createRequestTypes("CLICK_STATS_WEEKLY");
export const PLAN = createRequestTypes("PLAN");
export const USER_PRODUCTS = createRequestTypes("USER_PRODUCTS");
export const INFLUENCER_NETWORK = createRequestTypes("INFLUENCER_NETWORK");
export const INFLUENCER_NETWORK_FILTER = createRequestTypes(
  "INFLUENCER_NETWORK_FILTER"
);
export const TOTAL_GLOBAL_STATS = createRequestTypes("TOTAL_GLOBAL_STATS");
export const ADD_PYVIT_PRODUCT = createRequestTypes("ADD_PYVIT_PRODUCT");
export const UPLOAD_CUSTOM_PRODUCT = createRequestTypes(
  "UPLOAD_CUSTOM_PRODUCT"
);
export const SAVE_ITEM = createRequestTypes("SAVE_ITEM");
export const DELETE_PRODUCT = createRequestTypes("DELETE_PRODUCT");
export const EDIT_ITEM = createRequestTypes("EDIT_ITEM");
export const GRAB_VIDEOS = createRequestTypes("GRAB_VIDEOS");
export const VIDEOS_ANALYTICS = createRequestTypes("VIDEOS_ANALYTICS");
export const ADD_VIDEO = createRequestTypes("ADD_VIDEO");
export const VIDEO_DETAILS = createRequestTypes("VIDEO_DETAILS");
export const DELETE_VIDEO = createRequestTypes("DELETE_VIDEO");
export const SAVE_VIDEO_DETAILS = createRequestTypes("SAVE_VIDEO_DETAILS");
export const CHANGE_VIDEO_THUMBNAIL = createRequestTypes(
  "CHANGE_VIDEO_THUMBNAIL"
);
export const UNASSIGN_PRODUCT = createRequestTypes("UNASSIGN_PRODUCT");
export const EDIT_VIDEO_AUTH = createRequestTypes("EDIT_VIDEO_AUTH");
export const ASSIGN_ITEM = createRequestTypes("ASSIGN_ITEM");
export const GRAB_PRODUCTS_BY_VIDEO = createRequestTypes(
  "GRAB_PRODUCTS_BY_VIDEO"
);
export const VIDEO_VIEW_STATS = createRequestTypes("VIDEO_VIEW_STATS");
export const UNIQUE_VIDEO_TOTAL_VIEWS = createRequestTypes(
  "UNIQUE_VIDEO_TOTAL_VIEWS"
);
export const VIDEO_PRODUCT_CLICKS_STATS = createRequestTypes(
  "VIDEO_PRODUCT_CLICKS_STATS"
);
export const GRAB_VIDEO_ANALYTICS = createRequestTypes("GRAB_VIDEO_ANALYTICS");
export const USER_PROFILE_DATA = createRequestTypes("USER_PROFILE_DATA");
export const NOMAD_CREATE_ACCOUNT = createRequestTypes("NOMAD_CREATE_ACCOUNT");
export const E_WALLET_DASHBOARD = createRequestTypes("E_WALLET_DASHBOARD");
export const ACCOUNT_PROFILE = createRequestTypes("ACCOUNT_PROFILE");
export const PUT_ACCOUNT_USER_DATA = createRequestTypes(
  "PUT_ACCOUNT_USER_DATA"
);
export const CHANGE_PASSWORD = createRequestTypes("CHANGE_PASSWORD");
export const DELETE_ADDRESSS = createRequestTypes("DELETE_ADDRESSS");
export const CREATE_ADDRESS = createRequestTypes("CREATE_ADDRESS");
export const SET_DEFAULT_ADDRESS = createRequestTypes("SET_DEFAULT_ADDRESS");
export const GET_ALL_ADDRESSES = createRequestTypes("GET_ALL_ADDRESSES");
export const UPDATE_ADDRESS = createRequestTypes("UPDATE_ADDRESS");
export const GET_COUNTRIES_LIST = createRequestTypes("GET_COUNTRIES_LIST");
export const SUBSCRIPTION_DETAILS = createRequestTypes("SUBSCRIPTION_DETAILS");
export const GET_ORDER_HISTORY = createRequestTypes("GET_ORDER_HISTORY");
export const CREATOR_LIBRARY_GRAB_VIDEOS = createRequestTypes(
  "CREATOR_LIBRARY_GRAB_VIDEOS"
);
export const CREATOR_LIBRARY_ANALYTICS = createRequestTypes(
  "CREATOR_LIBRARY_ANALYTICS"
);
export const COPY_CREATOR_VIDEO_LINK = createRequestTypes(
  "COPY_CREATOR_VIDEO_LINK"
);
export const GET_SUBSCRIPTION_ALERTS = createRequestTypes(
  "GET_SUBSCRIPTION_ALERTS"
);
export const OPEN_SEND_MONEY = "OPEN_SEND_MONEY";
export const CLOSE_SEND_MONEY = "CLOSE_SEND_MONEY";
export const ACTIVATE_PAUSE_VIDEO = createRequestTypes("ACTIVATE_PAUSE_VIDEO");
export const REACTIVATE_SUBSCRIPTION = createRequestTypes(
  "REACTIVATE_SUBSCRIPTION"
);
export const UNSUBSCRIBE_PLAN = createRequestTypes("UNSUBSCRIBE_PLAN");
export const SUMMARY_PROFILE = createRequestTypes("SUMMARY_PROFILE");
export const SUMMARY_BALANCE = createRequestTypes("SUMMARY_BALANCE");
export const MARKETING_TOOLS_PRODUCT_LINK = createRequestTypes(
  "MARKETING_TOOLS_PRODUCT_LINK"
);
export const POST_AFFILIATE_ADDRESS = createRequestTypes(
  "POST_AFFILIATE_ADDRESS"
);
export const GET_AFFILIATE_PROFILE = createRequestTypes(
  "GET_AFFILIATE_PROFILE"
);
export const CHANGE_INFLUENCER_PASSWORD = createRequestTypes(
  "CHANGE_INFLUENCER_PASSWORD"
);
export const GET_PAYMENT_SUMMARY = createRequestTypes("GET_PAYMENT_SUMMARY");
export const GET_PAYMENT_DETAILS = createRequestTypes("GET_PAYMENT_DETAILS");
export const ORDER_TRACKING = createRequestTypes("ORDER_TRACKING");
export const GET_TRANSACTION_DATA = createRequestTypes("GET_TRANSACTION_DATA");
export const GET_TRANSACTION_ORDER = createRequestTypes(
  "GET_TRANSACTION_ORDER"
);
export const GET_TRANSACTION_DETAILS = createRequestTypes("GET_TRANSACTION_DETAILS");
export const USER_PURCHASE_ALERT = createRequestTypes("USER_PURCHASE_ALERT");
export const GET_MEDIA_ASSETS = createRequestTypes("GET_MEDIA_ASSETS");
export const GOAFFPRO_NOTIFICATION_PREFERENCE = createRequestTypes(
  "GOAFFPRO_NOTIFICATION_PREFERENCE"
);
export const GET_TRANSLATION_DATA = createRequestTypes("GET_TRANSLATION_DATA");
export const UPLOAD_PROFILE_PHOTO = createRequestTypes("UPLOAD_PROFILE_PHOTO");
export const GOAFFPRO_PAYMENTS = createRequestTypes("GOAFFPRO_PAYMENTS");
export const ATHENA_ANALYTICS_CACHE = createRequestTypes(
  "ATHENA_ANALYTICS_CACHE"
);
export const CREATOR_ATHENA_ANALYTICS_CACHE = createRequestTypes(
  "CREATOR_ATHENA_ANALYTICS_CACHE"
);
export const GET_PRODUCT_LINK = createRequestTypes("GET_PRODUCT_LINK");
export const SAVE_PRODUCT_LINK = createRequestTypes("SAVE_PRODUCT_LINK");

export const FAVORITE_UNFAVORITE = createRequestTypes("FAVORITE_UNFAVORITE");
export const DELETE_HISTORY = createRequestTypes("DELETE_HISTORY");
export const GOAFFPRO_ANALYTICS = createRequestTypes("GOAFFPRO_ANALYTICS");
