import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import GraphDateRangePicker from "./videoDetails/GraphDateRangePicker";
import { Info } from "../../utils/styledComponents";
import { useTranslation } from "react-i18next";

interface IProps {
  selectedDates: any;
  setSelectedDates: any;
  handleDateFilter: any;
  isView?: boolean;
  max30Days?:boolean;
}

const DateRangeGraphFilter = ({
  isView,
  selectedDates,
  setSelectedDates,
  handleDateFilter,
  max30Days,
}: IProps) => {
  const { t } = useTranslation();
  const handleDateChange = (newValue: any) => {
    if (!newValue || !newValue[0] || !newValue[1]) {
      return;
    }
    setSelectedDates([newValue[0], newValue[1]]);
  };

  function isDateInRange():Boolean {
    if (selectedDates[0] && selectedDates[1]) {
      let startDate = new Date(selectedDates[0]);
      let endDate = new Date(selectedDates[1]);

      const timeDifference = endDate.getTime() - startDate.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      return daysDifference <= 30;
    } else return false;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom:max30Days ?"30px":"15px",
        width: "fit-content",
        margin: "auto",
      }}
    >
      <GraphDateRangePicker
        isView={isView}
        value={selectedDates}
        onChange={handleDateChange}
        isNoWrap={true}
        max30Days={max30Days}
        // sx={summaryStyles.dateRangePicker}
      />
      {max30Days && (
        <Stack
          sx={{ width: "100%", alignItems: "center", marginBottom: "8px" }}
        >
          <Info
            sx={{
              fontSize: "0.8rem !important",
              color: "grey !important",
            }}
          >
            {t("Choose a date within a range of 1 to 30 days")}
          </Info>
        </Stack>
      )}
      <Button
        variant="contained"
        sx={{
          textTransform: "none",
        }}
        onClick={() => {
          if (!selectedDates[0] || !selectedDates[1]) {
            toast.warn(t("Please enter the start and end dates"));
          } else {
            handleDateFilter();
          }
        }}
        disabled={max30Days ? !isDateInRange() : false}
      >
        {t("Save")}
      </Button>
    </Box>
  );
};

export default DateRangeGraphFilter;
