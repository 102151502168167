import React from "react";
import {
  CartesianGrid,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";
import { ChartLineType, LineChartProps } from "../../models/backoffice.models";
import { Stack } from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useTranslation } from "react-i18next";

const FloatLineChart: React.FC<LineChartProps> = ({
  Xaxis,
  ChartData,
  ChartLines,
  max,
}) => {
  const { t } = useTranslation();

  const CustomLegend = (props: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        {ChartLines?.map((item) => {
          return (
            <Stack direction={"row"} alignItems={"center"}>
              <RadioButtonCheckedIcon
                sx={{
                  color: item.stroke,
                  marginRight: "5px",
                  marginLeft: "5px",
                }}
              />
              <span>{t(item.name)}</span>
            </Stack>
          );
        })}
      </div>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={ChartData}
        margin={{ top: 5, right: 20, left: 5, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          axisLine={{ stroke: "black" }}
          tickLine={false}
          style={{ stroke: "#333" }}
          dataKey={Xaxis.dataKey}
          interval={0}
          tick={{ fontSize: 12, fontWeight: 400, fontFamily: "Jost", dx: -5 }}
        />
        <YAxis
          interval="equidistantPreserveStart"
          tickCount={5}
          axisLine={{ stroke: "black" }}
          tickLine={false}
          style={{ stroke: "#333" }}
          domain={[0, max ? max : "dataMax + 10"]}
          tick={{ fontSize: 12, fontFamily: "Jost", fontWeight: 400, dx: -5 }}
        />
        <Tooltip />
        <Legend verticalAlign="top" content={CustomLegend} />
        {ChartLines?.map((item: ChartLineType, index: number) => {
          return (
            <Line
              key={index}
              type={item.type}
              name={t(item.name)}
              dataKey={item.dataKey}
              stroke={item.stroke}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default FloatLineChart;
