export const programInfoStyle = {
  wrapper: {
    mb: "80px",
    boxMargin: {
      mt: "12px",
      mb: "24px",
    },
    textStyle: { display: "inline" },
  },
};
