import { Box, Button, CircularProgress, Modal, Stack } from "@mui/material";
import React from "react";
import { Styles } from "./style";
import { SubTitle1 } from "../../utils/styledComponents";
import { useTranslation } from "react-i18next";
import QuestionIcon from "../../utils/icons/QuestionIcon";
import { stylesGlobal } from "../../utils/muiStyles";

interface IModalProps {
  open: boolean;
  onClose: any;
  alert: string;
  action: any;
  isLoading?: boolean;
  actionBtn?: string | undefined;
}

const FloatAlert = ({
  open,
  onClose,
  alert,
  action,
  isLoading,
  actionBtn,
}: IModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={{ ...Styles.modalWrapper }} className="overflow-x-contain">
        <Stack sx={{ ...Styles.modalWrapper.modalBody, alignItems: "center" }}>
          <QuestionIcon />
          <Stack sx={{ marginTop: "25px" }}>
            <SubTitle1 sx={{ textAlign: "center" }}>{alert}</SubTitle1>
          </Stack>
          <Stack alignItems={"center"} margin={"10px 0px 0px"}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              width="240px"
            >
              <Button
                variant="outlined"
                sx={{ ...stylesGlobal.outlinedBtn }}
                onClick={() => onClose()}
              >
                {t("Cancel")}
              </Button>

              <Button
                onClick={action}
                variant="contained"
                sx={{
                  ...stylesGlobal.containedBtn,
                  cursor: isLoading ? "not-allowed" : "pointer",
                }}
                disabled={isLoading}
              >
                {isLoading && (
                  <CircularProgress size="14px" sx={{ mr: "10px" }} />
                )}{" "}
                {actionBtn && actionBtn?.length ? t(actionBtn) : t("Yes!")}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default FloatAlert;
