import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18next";
import { stylesMobile } from "../style";
import { closeSendMoney, getUserProfile } from "../../actions";
import { RootState } from "../../configureStore";
import { RouteList } from "../RouteList";
import { Title } from "../../utils/styledComponents";
import Loading from "../../shared/Loading";
import FloatModal from "../../shared/components/FloatModal";
import FLoatDropDown from "../../shared/components/FLoatDropDown";
import { CONSTANTS } from "../../utils/constants";
import MobileSubRoute from "./MobileSubRoute";
import { languageFormat, languageTranslateHook } from "../helper";
import { getLanguageByCode } from "../MainNav/helper";
import { useTranslation } from "react-i18next";

function MobileNav() {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const currentPath = window.location.pathname;

  const [accessibleRoute, setAccessibleRoute] = useState({
    accessible: false,
    routeList: RouteList,
  });
  const [subRoute, setSubRouteName] = useState<{
    display: string;
    name: string;
  }>();
  const [modelProps, setModelProps]: any = useState({
    element: <Loading />,
    name: "",
  });
  const [language, setLanguage] = useState<string>("English");
  const [isLanguageDisable, setIsLanguageDisable] = useState<boolean>(false);

  const { globalModalState }: any = useSelector(
    (state: RootState) => state?.entities?.globalMoadal
  );

  const { userData } = useSelector(
    (state: RootState) => state?.entities?.userProfile
  );

  const translatedLabels: any = useSelector(
    (state: RootState) => state?.entities?.translation?.getTranslationData
  );

  useEffect(() => {
    dispatch(getUserProfile.request({}));
  }, []);

  useEffect(() => {
    if (userData?.data && !userData?.isLoading) {
      const access: any = userData?.data?.access;

      let mainAccess = access?.filter((item: any) => item.access);

      let list = mainAccess?.map((item: any) => {
        let main = item;
        let subRoute = main.subRoute?.filter((item: any) => item.access);
        return {
          ...main,
          subRoute: subRoute,
        };
      });

      let elementMap = list?.map((item: any) => {
        let route = RouteList?.find((route: any) => item.name === route.name);

        let subroute = route?.subRoute
          ?.map((sub) => {
            return item.subRoute.find(
              (accessSub: any) =>
                sub.name === accessSub?.name && accessSub?.access
            );
          })
          .filter((item) => item !== undefined);

        return { ...route, subRoute: subroute };
      });
      setAccessibleRoute({ accessible: true, routeList: elementMap });
    }
  }, [userData?.isLoading]);

  useEffect(() => {
    RouteList.map((item) => {
      const parentRoute = item.route;
      item.subRoute.map((sub) => {
        if (`/${parentRoute}${sub.route}` === currentPath) {
          setSubRouteName({
            display: sub.displayName ? "block" : "none",
            name: sub.name,
          });
        }
      });
    });
  }, [currentPath]);

  useEffect(() => {
    languageTranslateHook(translatedLabels,setIsLanguageDisable)
  }, [
    translatedLabels?.isLoading,
    i18n,
    translatedLabels?.intialState?.isLoading,
    language
  ]);

  useEffect(()=>{
    if(!translatedLabels?.isLoading){
      const lang = localStorage.getItem("parent-lang");
      let code: any = lang?.split("/");
      setLanguage(getLanguageByCode(code[1]) ?? "English");
    }
  },[translatedLabels?.isLoading]);

  const handleLanguageChange = (e: any) => {
    setLanguage(e.target.value);
    i18n.changeLanguage(languageFormat(e.target.value));
  };

  return (
    <>
      {accessibleRoute?.accessible ? (
        <Box sx={stylesMobile.container}>
          <MobileSubRoute
            setSubRouteName={setSubRouteName}
            setModelProps={setModelProps}
            accessibleRoute={accessibleRoute}
          />
          <Box className="overflow-y-contain" sx={stylesMobile.outletContainer}>
            <Box sx={stylesMobile.containerStyle}>
              <Box sx={stylesMobile.languageWrapper}>
                <Box sx={stylesMobile.languageWrapper.languageContainer}>
                  <FLoatDropDown
                    list={CONSTANTS.languages}
                    label="Select language:"
                    name="language"
                    onChange={handleLanguageChange}
                    value={language}
                    disabled={isLanguageDisable}
                  />
                </Box>
              </Box>
              <Title
                fontvalue="20px"
                style={{ display: subRoute?.display, marginTop: "10px" }}
              >
                {subRoute?.name == "Plan" ? t("Dashboard") : t(subRoute?.name ?? "")}
              </Title>
              <Outlet />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box height={"100vh"}>
          <Loading />
        </Box>
      )}
      <FloatModal
        open={globalModalState?.isOpen}
        onClose={() => dispatch(closeSendMoney())}
        modalTitle={modelProps.name}
        modalBody={modelProps.element}
      />
    </>
  );
}

export default MobileNav;
