import styled from "styled-components";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableCell,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import {
  PRIMARYCOLOUR,
  SECONDARYCOLOUR,
  THEMEBACKGROUNDCOLOR,
  THIN_BORDER_COLOR,
} from "../colors";

interface IStyledButtonProps {
  fontvalue?: string;
  lineheight?: string;
  paddingvalue?: string;
  background?: string;
  border?: string;
  colour?: string;
  borderRadius?: string;
  fontWeight?: number;
  letterSpacing?: string;
  disableBorder?: string;
  minWidth?: string;
  hoverColor?: string;
  hoverbackground?: string;
  isHoverElevate?: boolean;
  texttransform?: string;
}

interface IStyledNormalText {
  fontvalue?: string;
  lineheight?: string;
  color?: string;
  letterSpacing?: string;
  opacityValue?: string;
  fontWeight?: string;
  applydarktheme?: number;
  theme?: any;
  darkColor?: string;
  margin?: string;
  textAlign?: string;
}

export const StyledText = styled(Typography)(
  ({
    fontvalue = "14px",
    lineheight = "16px",
    color = "#000",
    fontWeight = "500",
    opacityValue = "1",
  }: IStyledNormalText) => ({
    fontFamily: "Jost",
    fontWeight: fontWeight,
    fontSize: `${fontvalue}`,
    lineHeight: `${lineheight}`,
    color: `${color}`,
    opacity: opacityValue,
  })
);

export const Title = styled(Typography)(
  ({
    fontvalue = "2.125rem",
    lineheight = "1.6rem",
    color = "#3E3168",
    letterSpacing = "0px",
    opacityValue = "1",
    fontWeight = "bold",
    applydarktheme = 0,
    theme,
    darkColor = "#FFFFFF",
  }: IStyledNormalText) => ({
    margin: "unset",
    color: SECONDARYCOLOUR,
    fontSize: `${fontvalue}`,
    lineheight: "1.235rem",
    letterSpacing: "0px",
    opacityValue: "1",
    fontWeight: "500",
    fontFamily: "Jost",
    applydarktheme: 0,
    theme,
    darkColor: "#FFFFFF",
  })
);

export const SubTitle1 = styled(Typography)(
  ({
    fontvalue = "1.2rem",
    lineheight = "1.6rem",
    color = "#3E3168",
    letterSpacing = "0px",
    opacityValue = "1",
    fontWeight = "bold",
    applydarktheme = 0,
    theme,
    darkColor = "#FFFFFF",
  }: IStyledNormalText) => ({
    margin: 0,
    fontWeight: 500,
    fontSize: "1.25rem",
    fontFamily: "Jost",
    lineHeight: "1.6",
    letterSpacing: "0.0075em",
    color: PRIMARYCOLOUR,
  })
);

export const SubTitle2: any = styled(Typography)(
  ({
    fontvalue = "1.2rem",
    lineheight = "1.6rem",
    color = "#3E3168",
    letterSpacing = "0px",
    opacityValue = "1",
    fontWeight = "bold",
    applydarktheme = 0,
    theme,
    darkColor = "#FFFFFF",
  }: IStyledNormalText) => ({
    margin: 0,
    fontWeight: 500,
    fontSize: "1rem",
    fontFamily: "Jost",
    lineHeight: "1.6",
    letterSpacing: "0.0075em",
    color: PRIMARYCOLOUR,
  })
);

export const Info = styled(Typography)(
  ({
    fontvalue = "1.2rem",
    lineheight = "1.6rem",
    color = "#3E3168",
    letterSpacing = "0px",
    opacityValue = "1",
    fontWeight = "bold",
    applydarktheme = 0,
    theme,
    darkColor = "#FFFFFF",
    margin = "0px",
  }: IStyledNormalText) => ({
    margin: 0,
    fontFamily: "Jost",
    fontWeight: 500,
    fontSize: "0.95rem",
    lineHeight: "1.345",
    letterSpacing: "0.0075em",
  })
);

export const FormLabel = styled(Typography)(
  ({
    fontvalue = "1.2rem",
    lineheight = "1.6rem",
    color = "#3E3168",
    letterSpacing = "0px",
    opacityValue = "1",
    fontWeight = "bold",
    applydarktheme = 0,
    theme,
    darkColor = "#FFFFFF",
  }: IStyledNormalText) => ({
    margin: 0,
    fontSize: "0.95rem",
    fontFamily: "Jost",
    lineHeight: "1.6",
    letterSpacing: "0.0075em",
    color: PRIMARYCOLOUR,
  })
);

interface ITableCell {
  border?: string;
}

export const StyledTableCell = styled(TableCell)<ITableCell>(
  ({ border = "unset" }) => ({
    maxHeight: "100px",
    overflowY: "auto",
    borderBottom: border,
    "&:nth-child(1)": {
      width: "20px", 
    },
    [`&.${tableCellClasses.head}`]: {
      fontFamily: "Jost",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "16px",
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontFamily: "Jost",
      fontWeight: "400" ,
      fontSize: "14px",
      lineHeight: "16px",
      opacity: "1",
      padding: "12px",
      wordBreak: "break-word",
      color: PRIMARYCOLOUR,
    },
  })
);

export const StyledTableHeadCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "Jost",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    padding: "20px 12px",
    backgroundColor: THEMEBACKGROUNDCOLOR,
    color: PRIMARYCOLOUR,
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Jost",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    opacity: "1",
    borderBottom: `1px solid ${PRIMARYCOLOUR}`,
    padding: "16px 10px",
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  "&.MuiTableRow-root.MuiTableRow-hover:hover": {
    background: "#FFF",
  },
}));

export const StyledTableHeadRow = styled(TableRow)(() => ({
  "&.MuiTableRow-root": {
    backgroundColor: THEMEBACKGROUNDCOLOR,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const StyledButton = styled(Button)(
  ({
    fontvalue = "12px",
    lineheight = "14px",
    paddingvalue = "8px",
    background = "#FFFFFF",
    colour = "#000",
    border = "1px solid #000",
    borderRadius = "4px",
    fontWeight = 600,
    letterSpacing = "0.5px",
    disableBorder = "1px solid rgba(0,0,0,0.3)",
    minWidth = "90px",
    hoverbackground = "#333",
    hoverColor = "#fff",
    isHoverElevate = false,
    texttransform = "capitalize",
  }: IStyledButtonProps) => ({
    fontFamily: "Jost",
    color: `${colour}`,
    background: `${background}`,
    fontSize: `${fontvalue}`,
    lineHeight: `${lineheight}`,
    border: `${border}`,
    padding: `${paddingvalue}`,
    borderRadius: `${borderRadius}`,
    fontWeight: `${fontWeight}`,
    letterSpacing: `${letterSpacing}`,
    minWidth: `${minWidth}`,
    "&.Mui-disabled": {
      border: `${disableBorder}`,
    },
    ":hover": {
      background: isHoverElevate
        ? "#f5f5f5 !important"
        : `${hoverbackground} !important`,
      color: hoverColor,
      border: isHoverElevate ? "none" : "",
      boxShadow: isHoverElevate ? "0px 4px 4px rgba(0, 0, 0, 0.35)" : "",
    },
  })
);

interface IProp {
  isNoShadow?: boolean;
  isExpandedStyle?: boolean;
}

export const StyledAccordion = styled(Accordion)<IProp>(
  ({ isNoShadow, theme, isExpandedStyle = false }) => ({
    transform: "scale(1)",
    padding: "0 16px",
    border: `1px solid ${THIN_BORDER_COLOR}`,
    "&.Mui-expanded": {
      margin: isExpandedStyle ? "0px" : "4px 0",
    },
  })
);

export const StyledAccordionSummary = styled(AccordionSummary)({
  width: "100%",
  overflow: "auto",
  padding: "0px 16px 0px 0px",
  "& .MuiAccordionSummary-content": {
    overflow: "auto !important",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    border: "1px solid",
    borderRadius: "4px",
    transition: "none",
    padding: "5px",
    position: "absolute",
    top: "0",
    right: "0",
    margin: "5px",
  },
  "& .MuiSvgIcon-root": {
    webkitTransition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    transition: " fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
});

export const StyledAccordionDetails = styled(AccordionDetails)({ padding: "0 5px 8px !important" });

export const StyledModalTable = styled(Table)({
  border: `1px solid ${THIN_BORDER_COLOR}`,
});

export const StyledModalTableCell = styled(TableCell)({
  padding: "4px",
  border: "none",
  fontSize: "16px",
  lineHeight: "18px",
  "&:first-child": {
    paddingLeft: "20px",
  },
  "&:last-child": {
    paddingRight: "20px",
  },
  "&.MuiTableRow-root td:first-child": {
    paddingTop: "32px",
  },
  "&.MuiTableRow-root td:last-child": {
    paddingBottom: "32px",
  },
});

export const StyledStepper = styled(Stepper)({
  "& .MuiStepConnector-root.Mui-completed": {
    "& .MuiStepConnector-line": {
      borderColor: "#212121",
      borderTopWidth: "2px",
    },
  },
});

export const StyledStep = styled(Step)({});

export const StyledStepLabel = styled(StepLabel)({
  "& .MuiSvgIcon-root.Mui-completed": {
    color: "#212121",
  },
  "& .MuiSvgIcon-root": {
    color: "rgba(0,0,0,0.3)",
  },
});

export const StyledDivider = styled.div`
  height: 1px; 
  width: 100%; 
  background-color: #e0e0e0;  
`;

interface IndicatorProps {
  status?: boolean;
  dimension?: string;
}

// Create the styled component with the defined props type
export const StyledActivePauseIndicator = styled.div<IndicatorProps>`
  height: ${(props) => (props.dimension ? props.dimension : "18px")};
  width: ${(props) => (props.dimension ? props.dimension : "18px")};
  border-radius: 50%;
  margin-right: 8px;
  margin-top: ${(props) => (props.dimension ? "8px" : "0")};
  background-color: ${(props) => (props.status ? "green" : "red")};
`; 