import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, USER_PROFILE_DATA } = actionTypes;

//write sagas function
function* getUserData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getUserProfileData,
      data.data
    );
    yield sendPayload(apiResponse, USER_PROFILE_DATA);
  } catch (e) {
    yield sendPayloadFailure(e, USER_PROFILE_DATA);
  }
}

export const sagas = {
  watchUserData: takeLatest(
    actionTypes.USER_PROFILE_DATA[REQUEST],
    getUserData
  ),
};
