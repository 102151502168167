import { Box } from "@mui/material";
import React, { useEffect } from "react";
import PlanProgress from "./planprogress/PlanProgress";
import GlobalGraphs from "./graph/GlobalGraphs";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../shared/Loading";
import {
  planDataCall,
  showUserProducts,
  totalGlobalStats,
  totalGraphViews,
  totalViews,
} from "../../../actions";
import { RootState } from "../../../configureStore";
import { styles } from "./style";

function Dashboard() {
  const dispatch = useDispatch();

  const { planData }: any = useSelector(
    (state: RootState) => state?.entities?.planData
  );

  useEffect(() => {
    dispatch(totalGraphViews.request({ data: { filter: "past24hours" } }));
    dispatch(planDataCall.request({}));
    dispatch(totalViews?.request({}));
    dispatch(totalGlobalStats.request({}));
    dispatch(showUserProducts.request({}));
  }, []);

  return (
    <>
      {planData?.isLoading ? (
        <Box sx={styles.boxStyle}>
          {" "}
          <Loading />
        </Box>
      ) : (
        <>
          <PlanProgress />
          <GlobalGraphs />
        </>
      )}
    </>
  );
};

export default Dashboard;
