import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";

const { SUCCESS, REQUEST, GET_PAYMENT_SUMMARY, GET_PAYMENT_DETAILS } =
  actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const paymentSummary = (state = {}, action: actions) => {
    switch (action.type) {
      case GET_PAYMENT_SUMMARY[SUCCESS]:
        return { ...state, data: action.payload, isLoading: false };
      case GET_PAYMENT_SUMMARY[REQUEST]:
        return intialState;
      case GET_PAYMENT_SUMMARY[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };
  const paymentDetails = (state = {}, action: actions) => {
    switch (action.type) {
      case GET_PAYMENT_DETAILS[SUCCESS]:
        return { ...state, data: action.payload, isLoading: false };
      case GET_PAYMENT_DETAILS[REQUEST]:
        return intialState;
      case GET_PAYMENT_DETAILS[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  return combineReducers({
    paymentSummary,
    paymentDetails,
  });
};

export default entity;

export const paymentData = (state: RootState) => state.entities.paymentData;
