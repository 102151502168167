import {
  MODALHEADERCOLOR,
  PRIMARYCOLOUR,
  THIN_BORDER_COLOR,
} from "../../colors";

export const Styles = {
  floatSelect: {
    borderBottom: `2px solid ${PRIMARYCOLOUR}`,
    borderRadius: "0",
    boxShadow: "0px 4px 4px 0px #0000001A",
    fontSize: "14px !important",
    lineHeight: "1.45rem",
    fontFamily: "Jost !important",
    textWrap: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  floatSelectFormcontrol: {
    p: { xs: "0", sm: "0" },
    width: "145px",
    mr: 1,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px",
      borderColor: `${THIN_BORDER_COLOR} !important`,
    },
  },
  selectListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: "30px",
  },
  selectListItemText:{
    maxWidth:"150px",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"
  },
  listLoadingOuter:{
    height:"20px", marginTop:"8px"
  },
  listLoaderBox:{width:"100%", display:"flex", alignContent:"center", justifyContent:"center", alignItems:"center"},
  searchPaper: {
    display: "flex",
    alignItems: "center",
    height: 40,
    borderRadius: "25px",
    minWidth: "240px",
    margin: { xs: "10px 0", md: "0" },
  },
  searchIcon: { ml: 1, background: "#333", width: "40px" },
  formControl: { width: "145px", mr: 1 },
  inputLabel: { fontSize: "14px", paddingLeft: "0px" },
  inputLabelText: {width:"90px", overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis"},
  menuProps: {
    PaperProps: {
      sx: {
        maxHeight: 180,
        overflow: "auto",
        textOverflow: "ellipsis",
        "&::-webkit-scrollbar": {
          width: "5px",
          height: "4px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#000",
          borderRadius: "4px",
        },
      },
    },
  },
  selectAll: {
    height: "40px",
    fontSize: "14px",
    borderBottom: `1px solid ${THIN_BORDER_COLOR}`,
  },
  menuItem: {
    fontSize: "12px",
    borderBottom: `1px solid ${THIN_BORDER_COLOR}`,
    "&.MuiMenuItem-root.Mui-selected": {
      backgroundColor: "#fff",
    },
  },
  paginationBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
    marginBottom: "30px",
    paginationContainer: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#f0f0f0",
      padding: "5px 10px",
    },
  },
  modalWrapper: {
    backgroundColor: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "max-content",
    minWidth: "400px",
    maxHeight: "500px",
    maxWidth: "900px",
    "@media (max-width: 600px)": {
      width: "96%",
      minWidth: "325px",
    },
    modalHeader: {
      backgroundColor: MODALHEADERCOLOR,
      padding: "14px 20px",
      display: "flex",
      justifyContent: "space-between",
      crossWrapper: {
        borderRadius: "50%",
        backgroundColor: "#fff",
        padding: "3px",
        width:"30px",
        height:"30px",
        marginLeft:"8px",
        cursor:"pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    modalBody: {
      padding: "20px 20px 20px 20px",
      height: "fit-content",
      maxHeight: "60vh",
      overflow: "auto",
    },
    modalbody: {
      height: "fit-content",
      maxHeight: "60vh",
      overflow: "auto",
    },
  },
  productModalWrapper: {
    backgroundColor: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "600px",
    maxHeight: "500px",
    "@media (max-width: 600px)": {
      width: "90%",
    },
    modalHeader: {
      backgroundColor: "#D9D9D9",
      padding: "14px 20px",
      display: "flex",
      justifyContent: "space-between",
      crossWrapper: {
        borderRadius: "50%",
        backgroundColor: "#fff",
        padding: "3px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    modalBody: {
      padding: "20px 20px 30px 20px",
      height: "fit-content",
      maxHeight: "60vh",
      overflow: "auto",
    },
  },
  selectStyle: {
    borderBottom: `2px solid ${PRIMARYCOLOUR}`,
    borderRadius: "0",
    boxShadow: "0px 4px 4px 0px #0000001A",
    textOverflow: "ellipsis",
  },
  menuItemStyle: { display: "none" },
  checkboxStyle: { padding: "0px 10px 0px 0px" },
};

export const FloatSearchStyle = {
  inputBaseStyle: { flex: 1, pr: 1, pl: 2, fontSize: "14px" },
  searchIconStyle: { color: "#fff" },
};

export const addProductStyle = {
  boxStyle: { width: "100%", alignItems: "center" },
  textFieldStyle: { color: "#969696", marginBottom: "12px" },
  urlTextField: { color: "#969696", marginBottom: "8px" },
};

export const thumbnailBodyStyle = {
  stackStyle: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
    p: "10px 0",
  },
  boxStyle: { pr: "10px", height: "fit-content" },
};

export const playerModalStyle = {
  crossWrapper: {
    position: "fixed",
    right: "30px",
    top: "30px",
    borderRadius: "50%",
    backgroundColor: "#fff",
    padding: "3px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const shareMediaIconModal = {
  mediaIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
};
