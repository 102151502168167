import moment from "moment";
import { toast } from "react-toastify";
import i18n from "i18next";

export const past24Hours = () => {
  const now: any = new Date();
  const timeArray = [];

  for (let i = 8; i >= 0; i--) {
    const timestamp = new Date(now - i * 3 * 60 * 60 * 1000);
    const formattedTime = timestamp.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    timeArray.push(formattedTime);
  }
  return timeArray;
};

export const getPast7days = () => {
  const daysArray = [];
  const daysOfWeek = [
    i18n.t("Sun"),
    i18n.t("Mon"),
    i18n.t("Tue"),
    i18n.t("Wed"),
    i18n.t("Thu"),
    i18n.t("Fri"),
    i18n.t("Sat"),
  ];
  const today: any = new Date();
  for (let i: number = 0; i < 7; i++) {
    const pastDate: any = new Date(today - i * 24 * 60 * 60 * 1000);
    const formattedDay = `${daysOfWeek[pastDate.getDay()]}/${String(
      pastDate.getDate()
    ).padStart(2, "0")}`;
    daysArray.push(formattedDay);
  }
  const days = daysArray.reverse();
  return days;
};

export const getDayOfWeek = (dateTimeString: string) => {
  const [year, month, day] = dateTimeString.split("/").map(Number);
  const dateTime = new Date(year, month - 1, day);
  const weekdays = [
    i18n.t("Sun"),
    i18n.t("Mon"),
    i18n.t("Tue"),
    i18n.t("Wed"),
    i18n.t("Thu"),
    i18n.t("Fri"),
    i18n.t("Sat"),
  ];

  const dayOfWeek = dateTime.getDay();
  const dayOfMonth = dateTime.getDate();
  const dayName = weekdays[dayOfWeek];
  const dayWithDate = `${dayName}/${dayOfMonth}`;

  return dayWithDate;
};

const APP_DATE_FORMAT = "DD MMM YYYY";
const APP_TIME_FORMAT = "HH:MM";
export const convertDateFromServer = (date: any) =>
  date ? moment(date).format(APP_DATE_FORMAT) : null;
export const convertTimeFromServer = (date: any) =>
  date ? moment(date).format(APP_TIME_FORMAT) : null;

export const formatMontDate = (inputDate: string) => {
  // Split the input string into year, month, and day
  let [year, month, day] = inputDate?.includes("/") ? inputDate?.split("/") : ["","", ""]

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  month = monthNames[parseInt(month, 10) - 1];

  return `${month}/${day}`;
};

export const formatDate = (inputDate: string) => {
  const date = new Date(inputDate);
  const day = date.getDate();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  return `${day} ${monthNames[monthIndex]} ${year} at ${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const alertListMessages = (subscription: any) => {
  subscription?.map((message: string) => {
    toast.info(message);
  });
  //
};

export const iskeyInObject = (object:Object, key:string) =>{
    return Object?.keys(object).includes(key)
}

export const isArrayIncludesKey = (object:Array<any> | string, key:any) =>{
  return object?.includes(key)
}

export const mmddyyyyFormat = (value:Date)=>{
  const date = new Date(value);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
 return `${day}/${month}/${year}`;                                                                                                                                                                                                          
  
}

export function removeEmptyValues(obj:any) {
  const result:any = {};
  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] !== "" &&
      obj[key] !== null &&
      obj[key] !== undefined
    ) {
      result[key] = obj[key];
    }
  }
  return result;
}