import { createTheme } from "@mui/material";
import { HOVERPRIMARYCOLOUR, HOVERPRIMARYTEXTCOLOUR, PRIMARYCOLOUR, SECONDARYCOLOUR } from "./colors";

export const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
            color: "#fff",
            backgroundColor: SECONDARYCOLOUR,
            "&:hover": {
              backgroundColor: HOVERPRIMARYCOLOUR,
              color: HOVERPRIMARYTEXTCOLOUR,
            },
          },
          outlined: {
            border: `1px solid ${PRIMARYCOLOUR}`,
            color: PRIMARYCOLOUR,
            "&:hover": {
              backgroundColor: HOVERPRIMARYCOLOUR,
              color: HOVERPRIMARYTEXTCOLOUR,
              borderColor: HOVERPRIMARYCOLOUR,
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            color: PRIMARYCOLOUR,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: PRIMARYCOLOUR,
          },
          root: {
            color: PRIMARYCOLOUR,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {},
        },
      },
    },
    palette: {
      primary: {
        main: PRIMARYCOLOUR,
      },
      secondary: {
        main: "#444",
      },
    },
  });