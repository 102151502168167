import React from 'react';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledTableCell, StyledTableRow, StyledText } from '../../../utils/styledComponents';
import { creatorLibraryStyle } from './style';
import { Box, Button, Grid, IconButton, Stack } from '@mui/material';
import CreatorLibraryActionRow from './CreatorLibraryActionRow';
import { styles } from '../styles';
import { stylesGlobal } from '../../../utils/muiStyles';
import { useEffect, useState } from "react";
import { CONSTANTS } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { IAPIData } from '../../../models/types.model';
import { RootState } from '../../../configureStore';
import { RotatableIcon, convertToPlainText } from './helper';
import { TABLEHEADBACKGROUNDSHADE2, THIN_BORDER_COLOR } from '../../../colors';
import { copyCreatorVideo } from '../../../actions';
import { useTranslation } from 'react-i18next';
import CreatorLibraryProductVendorMobile from './CreatorLibraryProductsMobile';
import CreatorsProductVendorMobile from './CreatorLibraryProductsMobile';

const CreatorLibraryMobileRow = ({row,index,pageLimit,multilistInfo,onPreview}:any) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [expandedStates, setExpandedStates] = useState(
        Array(pageLimit).fill(false)
      );
    const [copyLinkContents, setCopyLinkContents] = useState<string[]>([]);
    const [isExpanded, setIsExpanded] = useState(false);


  const {
    copyVideoLinkData,
  }: any = useSelector((state: RootState) => state?.entities?.creatorLibrary);


      const profileData = useSelector(
        (state: RootState) => state?.entities?.userProfile?.userData as IAPIData
      );
      const userRole = profileData?.data && profileData?.data?.user?.role;


      useEffect(() => {
        if (!copyVideoLinkData?.isLoading) {
          setCopyLinkContents([]);
        }
      }, [copyVideoLinkData?.isLoading]);

    const handleAccordionChange =
    (index: number) => (event: any, isExpanded: any) => {
      setExpandedStates((prev) =>
        prev.map((state, i) => (i === index ? isExpanded : state))
      );
    };
    
    const copyVideoLink = (id: string) => {
        copyLinkContents?.push(id);
        dispatch(copyCreatorVideo.request({ content_id: id }));
      };

    return (
        <>
        <StyledAccordion
          isNoShadow={true}
          sx={creatorLibraryStyle.accordionStyle}
          expanded={expandedStates[index]}
          onChange={handleAccordionChange(index)}
        >
          <StyledAccordionSummary
            sx={creatorLibraryStyle.accordionSummary}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <StyledTableRow
              key={index}
              role="checkbox"
              sx={{...styles.rowStyle}}
            >
              <StyledTableCell
                align="left"
                sx={creatorLibraryStyle.styledCell}
              >
                <Box sx={creatorLibraryStyle.cellBox}>
                  <img
                    src={row.thumbnail}
                    style={{
                      height: "52px",
                      width: "auto",
                      maxWidth: "74px",
                    }}
                    alt=""
                    onClick={(e: any) =>
                      onPreview(e, row)
                    }
                  />
                </Box>
              </StyledTableCell>
              {multilistInfo?.includes("Title") && (
                <StyledTableCell
                  align="center"
                  sx={creatorLibraryStyle.cellStyle}
                >
                  <StyledText
                    sx={styles.tableContainer.titleBoxMobile}
                  >
                    {row.title || "-"}
                  </StyledText>
                  <Box sx={{display: "flex", justifyContent: "end", mt: "5px"}}>
                  <Button
                      variant="contained"
                      disabled={
                        copyLinkContents?.includes(
                          row.id.toString()
                        ) ||
                        userRole !==
                          CONSTANTS.roles.INFLUENCER
                      }
                      sx={{
                        ...stylesGlobal.containedBtn,
                        width: "100px",
                        mt: "0px",
                        border: `1px solid ${THIN_BORDER_COLOR}`,
                        fontWeight: "500",
                        padding: "6px 10px !important",
                      }}
                      onClick={() =>
                        copyVideoLink(row.id.toString())
                      }
                    >
                      {t("Copy video")}
                    </Button>
                    <IconButton
                      sx={{
                        border: "1px solid #000",
                        borderRadius: "5px",
                        ml: "10px",
                      }}
                    >
                      <RotatableIcon rotate={isExpanded ? 1 : 0}/>
                    </IconButton>
                    </Box>
                </StyledTableCell>
              )}
             
            </StyledTableRow>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
          <Stack sx={{padding:"0 5px"}}>
            {multilistInfo?.includes("Creator handle") &&
              userRole === CONSTANTS.roles.INFLUENCER && (
                <Grid
                  item
                  xs={12}
                  lg={2.4}
                  sx={creatorLibraryStyle.gridStyle}
                >
                  <StyledText fontWeight="600">
                    {t("Creator handle")}:
                  </StyledText>
                  <StyledTableCell align="left">
                    {row.creatorHandle ?? "-"}
                  </StyledTableCell>
                </Grid>
              )}
            {multilistInfo?.includes("Description") && (
              <Grid
                item
                xs={12}
                lg={2.4}
                sx={creatorLibraryStyle.gridStyle}
              >
                <StyledText fontWeight="600">
                  Description:
                </StyledText>
                <StyledTableCell align="left">
                  {row.description
                    ? convertToPlainText(row.description)
                    : "-"}
                  {/* </StyledText> */}
                </StyledTableCell>
              </Grid>
            )}
            {multilistInfo?.includes("Collection") && (
              <>
                {row.collections?.length > 0 ? (
                  <Grid
                    item
                    xs={12}
                    lg={2.4}
                    sx={creatorLibraryStyle.gridStyle}
                  >
                    <StyledText fontWeight="600">
                      Collection:
                    </StyledText>
                    <StyledTableCell
                      align="left"
                      sx={
                        creatorLibraryStyle.cellTextWrap
                      }
                    >
                      {row.collections.join(", ")}
                    </StyledTableCell>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    lg={2.4}
                    sx={creatorLibraryStyle.gridStyle}
                  >
                    <StyledText fontWeight="600">
                      Collection:
                    </StyledText>
                    <StyledTableCell align="left">
                      -
                    </StyledTableCell>
                  </Grid>
                )}
              </>
            )}

            {multilistInfo?.includes("Content id") && (
              <Grid
                item
                xs={12}
                lg={2.4}
                sx={creatorLibraryStyle.gridStyle}
              >
                <StyledText fontWeight="600">
                  {t("Content id")}:
                </StyledText>
                <StyledTableCell align="left">
                  {row.id}
                </StyledTableCell>
              </Grid>
            )}
            </Stack>
            <CreatorsProductVendorMobile row={row}/>
          </StyledAccordionDetails>
        </StyledAccordion>
        <CreatorLibraryActionRow
          row={row}
          expanded={expandedStates[index]}
        />
      </>
    );
};

export default CreatorLibraryMobileRow;