import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

const backdropStyle = { color: "#fff" };

const BackDropLoader = (props: any) => {
  return (
    <div>
      <Backdrop
        sx={{
          ...backdropStyle,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={props?.open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
export default BackDropLoader;
