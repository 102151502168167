import * as React from "react";
import DateRangeGraphFilter from "./DateRangeGraphFilter";
import { Box, Paper } from "@mui/material";

export default function FloatDateSelector({
  selectedDates,
  setSelectedDates,
  handleDateFilter,
  open,
  max30Days,
  isView
}: any) {
  return (
    <Box
      className={`grid-container ${open ? "open" : "closed"}`}
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        padding: "2px",
      }}
    >
      <Paper elevation={4} sx={{ display: "flex" }}>
        <DateRangeGraphFilter
          max30Days={max30Days??false}
          isView={isView ?? false}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          handleDateFilter={handleDateFilter}
        />
      </Paper>
    </Box>
  );
}
