import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE } from "../../actions/actiontypes";
import { toast } from "react-toastify";
import { RootState } from "../../configureStore";
import { t } from "i18next";

const { SUCCESS, REQUEST, DELETE_PRODUCT } = actionTypes;

const intialState = {
  isLoading: false,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const deleteProductData = (state = intialState, action: actions) => {
    switch (action.type) {
      case DELETE_PRODUCT[REQUEST]:
        return { ...intialState, isLoading: true };
      case DELETE_PRODUCT[SUCCESS]:
        toast.success(t("Product Deleted Successfully"));
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: false,
        };
      case DELETE_PRODUCT[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  return combineReducers({
    deleteProductData,
  });
};

export default entity;

export const deleteProduct = (state: RootState) => state.entities.deleteProduct;
