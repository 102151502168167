import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Stack } from "@mui/material";
import { ChartLineType, LineChartProps } from "../../models/backoffice.models";
import { useTranslation } from "react-i18next";

const FloatAreaLineGraph: React.FC<LineChartProps> = ({
  Xaxis,
  ChartData,
  ChartLines,
  max,
  interval,
}) => {
  const { t } = useTranslation();

  const CustomLegend = (props: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        {ChartLines?.map((item) => {
          return (
            <Stack direction={"row"} alignItems={"center"}>
              <RadioButtonCheckedIcon
                sx={{
                  color: item.stroke,
                  marginRight: "5px",
                  marginLeft: "5px",
                }}
              />
              <span>{t(item.name)}</span>
            </Stack>
          );
        })}
      </div>
    );
  };
  return (
    <AreaChart
      data={ChartData}
      width={800}
      height={330}
      margin={{ top: 5, right: 20, left: 5, bottom: 5 }}
    >
      <defs>
        <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
          <stop offset="1%" stopColor="#D9D9D9" stopOpacity={0.9} />
          <stop offset="100%" stopColor="#2456CC" stopOpacity={0} />
        </linearGradient>
      </defs>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        axisLine={{ stroke: "black" }}
        tickLine={false}
        style={{ stroke: "#333" }}
        dataKey={Xaxis.dataKey}
        interval={interval ?? 5}
        tick={{ fontSize: 12, fontWeight: 400, fontFamily: "Jost", dx: -5 }}
      />
      <YAxis
        axisLine={{ stroke: "black" }}
        tickLine={false}
        style={{ stroke: "#333" }}
        domain={max ? [0, max + 10] : [0, "dataMax + 10"]}
        tick={{ fontSize: 12, fontFamily: "Jost", fontWeight: 400, dx: -5 }}
      />
      <Tooltip />
      <Legend verticalAlign="top" content={CustomLegend} />

      {ChartLines?.map((item: ChartLineType, index: number) => {
        return (
          <Area
            key={index}
            type={item.type}
            name={item.name}
            dataKey={item.dataKey}
            stroke={item.stroke}
            fillOpacity={1}
            dot={
              item.dotedGraph
                ? { stroke: item.stroke, fill: item.stroke, r: 3 }
                : false
            }
            fill="url(#color)"
          />
        );
      })}
    </AreaChart>
  );
};

export default FloatAreaLineGraph;
