import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE, RESET } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";
import { toast } from "react-toastify";
import { t } from "i18next";

const { SUCCESS, REQUEST, EDIT_ITEM } = actionTypes;

interface IEditProductData {
  isLoading: boolean;
  isError: boolean;
  data: object;
  errorMessage: string;
  isEditSuccess: boolean;
}

const intialState: IEditProductData = {
  isLoading: false,
  isError: false,
  data: {},
  errorMessage: "",
  isEditSuccess: false,
};

const entity = () => {
  const editProductData = (state = {}, action: actions) => {
    switch (action.type) {
      case EDIT_ITEM[SUCCESS]:
        toast.success(t("Product Updated Successfully"));
        return {
          ...intialState,
          data: action.payload,
          isLoading: false,
          isEditSuccess: true,
        };
      case EDIT_ITEM[REQUEST]:
        return { ...intialState, isLoading: true };
      case EDIT_ITEM[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
          isEditSuccess: false,
        };
      case EDIT_ITEM[RESET]:
        return intialState;
      default:
        return state;
    }
  };

  return combineReducers({
    editProductData,
  });
};

export default entity;

export const editProduct = (state: RootState) => state.entities.editProduct;
