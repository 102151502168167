

export const getLanguageByCode = (language: string) => {
    switch (language) {
      case "en":
        return "English";
      case "ja":
        return "Japanese";
      case "th":
        return "Thai";
      case "es":
        return "Spanish";
      case "ko":
        return "Korean";
      case "fr":
        return "French";
      case "ch":
        return "Chinese";
      default:
        return "en";
    }
  };

