import { Box, Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import Profile from "./Profile";
import { stylesGlobal } from "../../../utils/muiStyles";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateProfile } from "../../../actions";
import { RootState } from "../../../configureStore";
import Loading from "../../../shared/Loading";
import BackDropLoader from "../../../shared/BackdropLoader";
import { useNavigate } from "react-router-dom";
import FloatModal from "../../../shared/components/FloatModal";
import InfluencerAddress from "./Address";
import InfluencerDetails from "./InfluencerDetails";
import NotificationPreference from "./NotificationPreference";
import { useTranslation } from "react-i18next";
import { profileStyle } from "./style";

function InfluencerSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loader, setLoader] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] =
    useState<boolean>(false);

  const { affiliateProfileData, postAffiliateAddress }: any = useSelector(
    (state: RootState) => state?.entities?.affiliateProfile
  );

  const { uploadProfilePhotoData }: any = useSelector(
    (state: RootState) => state?.entities?.uploadProfilePhoto
  );

  useEffect(() => {
    dispatch(getAffiliateProfile.request({}));
  }, []);

  useEffect(() => {
    setLoader(postAffiliateAddress?.isLoading);
  }, [postAffiliateAddress]);

  const handleOpenNotificationdModal = () => {
    setOpenNotificationModal(true);
  };

  const handleCloseNotificationModal = () => {
    setOpenNotificationModal(false);
  };

  const handleView = () => {
    navigate("/creator/preview");
  };

  return (
    <Box>
      {!affiliateProfileData?.isLoading &&
      !affiliateProfileData?.isError &&
      !uploadProfilePhotoData?.isLoading ? (
        <Box sx={profileStyle.wrapper.settingMargin}>
          <Profile />
          <InfluencerAddress />
          <InfluencerDetails />

          <Stack sx={profileStyle.wrapper.settingBox}>
            <Button
              variant="outlined"
              sx={{ ...stylesGlobal.outlinedBtn, mt: "0px" }}
              onClick={() => handleOpenNotificationdModal()}
            >
              {t("Notification preferences")}
            </Button>
          </Stack>
          <FloatModal
            open={openNotificationModal}
            onClose={handleCloseNotificationModal}
            modalTitle={t("Notification preferences")}
            modalBody={
              <NotificationPreference
                profileData={affiliateProfileData}
                handleClose={handleCloseNotificationModal}
              />
            }
          />
        </Box>
      ) : (
        <Box height={"calc(100vh - 100px)"}>
          <Loading />
        </Box>
      )}
      <BackDropLoader open={loader} />
    </Box>
  );
};

export default InfluencerSettings;
