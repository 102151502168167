import React from "react";

const DeleteIcon = ({
  fill = "#D5D5D5",
  width = "1.253rem",
  height = "0.933rem",
}) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="18" fill="black" fill-opacity="0.04" />
    <path
      d="M12.9993 23.8333C12.9993 24.75 13.7493 25.5 14.666 25.5H21.3327C22.2493 25.5 22.9993 24.75 22.9993 23.8333V13.8333H12.9993V23.8333ZM23.8327 11.3333H20.916L20.0827 10.5H15.916L15.0827 11.3333H12.166V13H23.8327V11.3333Z"
      fill="black"
      fill-opacity="0.56"
    />
  </svg>
);
export default DeleteIcon;
