import React from "react";
import { RouteList } from "./appRoutes/RouteList";
import { Route, Routes } from "react-router-dom";
import PersistLogin from "./auth/PersistLogin";
import MainNav from "./appRoutes/MainNav/MainNav";
import VideoDetailsLayout from "./Pages/videoDetails";
import SettingsPreview from "./Pages/Influencer/InfluencerSettings/SettingsPreview";
import ProtectedRoute from "./auth/ProtectedRoute";
import Login from "./Pages/login/Login";
import PageNotfound from "./shared/PageNotfound";
import Unauthorized from "./shared/Unauthorized";

const NavigateRoute = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route element={<PersistLogin />}>
          <Route path="/" element={<MainNav />}>
            {RouteList?.map((item, route) => (
              <React.Fragment key={route}>
                {item.subRoute.map((sub) => (
                  <React.Fragment key={sub?.route}>
                    <Route
                      path={`${item.route}${sub.route}`}
                      element={sub.element}
                    />
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
            <Route
              path="/video-editor/videos/:id"
              element=<VideoDetailsLayout />
            />
            <Route
              path="/influencer/preview"
              element=<SettingsPreview />
            />
            <Route path="/creator/preview" element=<SettingsPreview />/>
          </Route>
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<PageNotfound />} />
      <Route path="unauthorized" element={<Unauthorized />} />
    </Routes>
  );
};

export default NavigateRoute;
