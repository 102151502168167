import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { stylesGlobal } from "../../utils/muiStyles";
import { StyledText } from "../../utils/styledComponents";
import { Styles } from "./style";

const ITEM_HEIGHT = 48;

const FloatMobileFilter = (props: any) => {
  const {
    filters,
    setFilters,
    multiSelect,
    selectFilters,
    setColumnsMobile,
    onSubmitMobile,
    setOpenMobileDate,
  } = props;

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  
  const [openStates, setOpenStates] = useState(selectFilters?.map(() => false));
  const [openMultiStates, setOpenMultiStates] = useState(
    multiSelect?.map(() => false)
  );
  const [selectList, setSelectList]: any = useState([]);

  useEffect(() => {
    if (selectList?.length === 0) {
      const data = multiSelect?.map((item: any) => [item.name, item.list]);
      const list: any = {};

      data?.forEach(([key, value]: any) => {
        list[key] = value;
      });
      setSelectList(list);
    }
  }, [multiSelect]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterMenu = (index: number) => {
    const newOpenStates = Array(openStates.length).fill(false);
    newOpenStates[index] = !openStates[index];
    setOpenStates(newOpenStates);
  };

  const handleMultiSelectMenu = (index: number) => {
    const newOpenStates = Array(openMultiStates.length).fill(false);
    newOpenStates[index] = !openMultiStates[index];
    setOpenMultiStates(newOpenStates);
  };

  const onRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    setFilters({ ...filters, [name]: event.target.value });
  };

  const onMultiSelectClick = (obj: any, name: any) => {
    const updated = selectList[name]?.map((item: any) => {
      if (item.name === obj.name) {
        return { ...item, status: !item.status };
      } else {
        return item;
      }
    });
    setSelectList({ ...selectList, [name]: updated });
    if (name === "colVisibility") {
      const cols = updated?.filter((i: any) => i.status === true);
      setColumnsMobile(cols?.map((c: any) => c.name));
      setSelectList({ ...selectList, [name]: updated });
    }
  };

  const handleSelectAll = (key: string, status: boolean) => {
    const all = selectList[key]?.map((item: any) => {
      return { ...item, status: !status };
    });
    setSelectList({ ...selectList, [key]: all });
    if (key === "colVisibility") {
      const list = all;
      const cols = list?.filter((i: any) => i.status === true) ?? [];
      setColumnsMobile(cols?.map((c: any) => c?.name));
      setSelectList({ ...selectList, [key]: all });
    }
  };

  const handleDateClick = (key: string) => {
    if (key === "dateRange") {
      setOpenMobileDate(true);
    }
  };

  const onSubmit = () => {
    const result: any = {};
    const keys = Object.keys(selectList);
    const un_consider = ["colVisibility"];
    keys?.map((item: any) => {
      if (!un_consider.includes(item)) {
        const arr = selectList[item]
          ?.filter((item2: any) => {
            return item2.status;
          })
          ?.map((item2: any) => {
            return item2.key;
          });
        const payload = arr.join(",");
        result[item] = payload;
      }
    });
    onSubmitMobile({ ...filters, ...result });
    handleClose();
  };

  return (
    <Box marginLeft={"10px"}>
      <Box
        sx={{
          display: "flex",
          border: "1px solid",
          width: "fit-content",
          borderRadius: "3px",
          padding: "4px",
        }}
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <FilterAltOutlinedIcon sx={{ color: "#333" }} />
      </Box>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6,
            width: "20ch",
            ...Styles.menuProps.PaperProps,
          },
        }}
      >
        <Stack alignItems={"center"} direction={"column"}>
          <List sx={{ width: "100%" }}>
            {Array.isArray(multiSelect) &&
              multiSelect?.map((item: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <ListItemButton
                      onClick={() => handleMultiSelectMenu(index)}
                    >
                      <ListItemText
                        sx={{
                          ".MuiListItemText-primary": {
                            fontFamily: "Jost",
                          },
                        }}
                        primary={t(item?.label)}
                      />
                      {openMultiStates[index] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                      in={openMultiStates[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <MenuItem
                        sx={Styles.menuItem}
                        onClick={() =>
                          handleSelectAll(
                            item.name,
                            selectList[item?.name]?.filter(
                              (all: any) => all.status
                            )?.length === item?.list?.length
                          )
                        }
                      >
                        <Checkbox
                          sx={Styles.checkboxStyle}
                          checked={
                            selectList[item.name]?.filter(
                              (all: any) => all.status
                            )?.length === item?.list?.length
                          }
                        />{" "}
                        <StyledText>{t("Select all")}</StyledText>
                      </MenuItem>
                      {item?.list?.map((sub: any) => (
                        <MenuItem
                          sx={{ width: "100%" }}
                          key={sub?.key}
                          value={sub?.name}
                          onClick={() => onMultiSelectClick(sub, item.name)}
                        >
                          <Checkbox
                            sx={Styles.checkboxStyle}
                            checked={
                              selectList[item.name]?.find(
                                (f: any) => f.name === sub.name
                              ).status
                            }
                          />
                          <StyledText>{t(sub?.name)}</StyledText>
                        </MenuItem>
                      ))}
                    </Collapse>
                  </React.Fragment>
                );
              })}

            {selectFilters?.map(
              (item: any, index: number) =>
                !item.disabled && (
                  <React.Fragment>
                    <ListItemButton onClick={() => handleFilterMenu(index)}>
                      <ListItemText
                        sx={{
                          ".MuiListItemText-primary": {
                            fontFamily: "Jost",
                          },
                        }}
                        primary={t(item?.label ?? "")}
                      />
                      {openStates[index] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                      in={openStates[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <FormControl sx={{ width: "100%" }}>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name={item.name}
                            id={item.name}
                            value={item.value}
                            onChange={(e) => onRadioChange(e, item.name)}
                          >
                            {item.list?.map((sub: any, subIndex: any) => (
                              <ListItemButton
                                sx={{
                                  fontFamily: "Jost",
                                  p: "8px 5px 8px 25px",
                                  fontWeight: "bold",
                                  textOverflow: "ellipsis",
                                  fontSize: "0.95rem",
                                  lineHeight: 1.2,
                                }}
                              >
                                <FormControlLabel
                                  sx={{ width: "100%" }}
                                  value={sub.key}
                                  control={<Radio sx={{ p: "4px" }} />}
                                  onClick={() => handleDateClick(sub.key)}
                                  label={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        columnGap: "10px",
                                      }}
                                    >
                                      <StyledText>{t(sub.name)}</StyledText>
                                      {sub.key === "dateRange" && <EventIcon />}
                                    </Box>
                                  }
                                />
                              </ListItemButton>
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </List>
                    </Collapse>
                  </React.Fragment>
                )
            )}
          </List>

          <Button
            sx={stylesGlobal.containedBtn}
            variant="contained"
            onClick={() => onSubmit()}
          >
            {t("Submit")}
          </Button>
        </Stack>
      </Menu>
    </Box>
  );
};

export default FloatMobileFilter;
