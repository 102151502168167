import { THIN_BORDER_COLOR } from "../../../colors";

export const styles = {
  stepperCancel: {
    iconWrap: { display: "flex" ,marginRight:"8px"},
    statusWrap: { display: "flex", width: "fit-content", alignItems: "center" ,flexDirection:{xs:"row",sm:"column"}},
    outer:{justifyContent:{xs:"flex-start",sm:"center"},alignItems:"center",flexDirection:"row"},
  },
  stepperIcon: {
    height: "30px",
    width: "30px",
  },
  loaderBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "400px",
  },
  tableCell: {
    border: "none",
    padding: "16px 30px",
  },
  buyAgainButton: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    textTransform: "none",
    buttonText: {
      whiteSpace: "nowrap",
    },
  },
  gridContainer: { padding: "10px 0", width: "95%" },
  accordionDetailsStyle: { padding: "8px 45px 8px 0px" },
  accordionBox: { display: "flex", flexWrap: "wrap" },
  detailsBox: { width: { xs: "220px", lg: "100%" } },
  gridContainerStyles: { padding: "8px 30px 8px 0px" },
  summaryBox: {
    border: `1px solid ${THIN_BORDER_COLOR}`,
    width: "100%",
    padding: "8px",
  },
  summaryStack: {
    width: "100%",
    padding: "4px",
    justifyContent: "space-between",
  },
  textStyle: { fontWeight: "500" },
  textBox: {
    display: "flex",
    columnGap: "5px",
    textStyle: {
      maxWidth: "150px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  rowStyle: { mb: "8px" },
  styleText: { wordBreak: "break-all" },
};

export const orderHistoryStyle = {
  loaderBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "400px",
  },
  accordionWrapper: {
  },
  noRecordText: {
    padding: "20px",
  },
  noRecordWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  tableCell: {
    border: "none",
    padding: "16px 30px",
  },
  buyAgainButton: {
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    buttonText: {
      whiteSpace: "nowrap",
    },
  },
  buttontableCell: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    border: "none",
    padding: "16px 30px",
    paddingTop: "0px",
  },
  summaryDatatableCell: {
    border: "none",
    padding: "16px 30px",
    paddingTop: "0px",
    width: "52%",
  },
  summaryDetailsData: {
    textAlign: "right",
  },
  summaryData: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  summaryDataWrapper: {
    border: `1px solid ${THIN_BORDER_COLOR}`,
    padding: "12px 20px",
  },
  seeAllButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "15px",
  },
  seeAllButton: {
    textDecoration: "underline",
    cursor: "pointer",
    margin: "auto",
  },
  gridStyle: { padding: "10px 0" },
  gridBoxStyle: {
    display: "flex",
  },
  textBox: {
    display: "flex",
    columnGap: "5px",
    textStyle: {
      maxWidth: "150px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  priceText: {
    width: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  accordionDetailsStyle: { padding: "10px 45px 0px 16px" },
  gridContainerStyle: { paddingLeft: "16px" },
  addressBoxStyle: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: "0px",
  },
  detailsBoxStyle: { width: { xs: "220px", lg: "100%" } },
  summaryBoxStyle: {
    padding: "8px",
    border: `1px solid ${THIN_BORDER_COLOR}`,
    cursor: "pointer",
  },
  summaryStack: {
    width: "100%",
    padding: "4px",
    justifyContent: "space-between",
  },
  summaryStackBox: {
    display: "flex",
    columnGap: "8px",
    alignItems: "center",
  },
  summaryText: {
    textDecoration: "underline",
    cursor: "pointer",
  },
};

export const buyProductAgainModalStyle = {
  wrappper: {},
  productName: { fontWeight: "bold", width: "100%" },
  textBold: { fontWeight: "bold" },
  whiteSpace: { whiteSpace: "nowrap" },
};
