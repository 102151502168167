import { combineReducers } from "redux";
import * as loginState from "./login";
import * as refreshTokenState from "./refresh";
import * as totalViewsState from "./total_views";
import * as totalGraphViewState from "./total_view_graph";
import * as clickStatsWeeklyState from "./clickStats";
import * as planDataState from "./plan";
import * as grabProductsState from "./grabProducts";
import * as influencerNetworkState from "./networkInfluencer";
import * as totalGlobalStatsState from "./globalStats";
import * as addPyvitProductState from "./addPyvitProduct";
import * as uploadCustomProductState from "./uploadCustomProduct";
import * as saveProductState from "./saveItem";
import * as deleteProductState from "./deleteProduct";
import * as editProductState from "./editProduct";
import * as videosState from "./videos";
import * as videosAnalyticsState from "./videoAnalytics";
import * as addVideoState from "./addVideo";
import * as videoDetailState from "./videoDetails";
import * as deleteVideoState from "./deleteVideo";
import * as saveVideoDetailState from "./saveVideoDetails";
import * as changeVideoThumbnailState from "./changeThumbnail";
import * as unassignProductState from "./unAssignProduct";
import * as editVideoAuthState from "./editVideoAuth";
import * as assignItemState from "./assignItem";
import * as productByVideoState from "./productsByVideo";
import * as videoViewsState from "./videoViewsStat";
import * as uniqueVideoViewsState from "./uniqueVideoViews";
import * as videoProductClickState from "./videoProductClicks";
import * as analyticsVideoState from "./analyticsVideo";
import * as userProfileState from "./userProfile";
import * as createNomadState from "./createNomad";
import * as nomadDashboardState from "./nomadDashboard";
import * as accountProfileState from "./accounts/accountProfile";
import * as accountAddressState from "./accounts/accountAddress";
import * as createAccountAddressState from "./accounts/createAddress";
import * as countriesList from "./countriesList";
import * as subscriptionDetailState from "./subscriptionDetail";
import * as orderHistory from "./orderHistory";
import * as subscriptionAlert from "./subscriptionAlert";
import * as creatorLibraryState from "./creatorLibrary";
import * as globalMoadalState from "./sendMoneyPop";
import * as sendMoneyPop from "./sendMoneyPop";
import * as activatePauseVideo from "./activatePauseVideo";
import * as reactivateSubscription from "./reactivateSubscription";
import * as unsubscribePlan from "./unsubscribePlan";
import * as summaryProfile from "./summaryProfile";
import * as summaryBalance from "./summaryBalance";
import * as marketingProductLink from "./marketingProductLink";
import * as affiliateProfileState from "./affiliateSettingsProfile";
import * as changeInfluencerPassword from "./changeInfluencerPassword";
import * as paymentData from "./paymentData";
import * as orderTrackingState from "./orderTracking";
import * as transactionData from "./transactionData";
import * as purchaseAlertState from "./purchaseAlert";
import * as mediaAssetState from "./mediaAssets";
import * as translation from "./translation";
import * as uploadProfilePhoto from "./uploadProfilePhoto";
import * as goaffproPaymentState from "./goaffproPaments";
import * as athenaAnalyticsState from "./athenaAnalytics";
import * as creatorAthenaAnalyticsState from "./creatorAthenaAnalytics"
import * as goaffproAnalyticsState from "./goaffproAnalytics"

export {
  loginState,
  refreshTokenState,
  totalViewsState,
  totalGraphViewState,
  clickStatsWeeklyState,
  planDataState,
  grabProductsState,
  influencerNetworkState,
  totalGlobalStatsState,
  addPyvitProductState,
  uploadCustomProductState,
  saveProductState,
  deleteProductState,
  editProductState,
  videosState,
  videosAnalyticsState,
  addVideoState,
  videoDetailState,
  deleteVideoState,
  saveVideoDetailState,
  changeVideoThumbnailState,
  unassignProductState,
  editVideoAuthState,
  assignItemState,
  productByVideoState,
  videoViewsState,
  uniqueVideoViewsState,
  videoProductClickState,
  analyticsVideoState,
  userProfileState,
  createNomadState,
  nomadDashboardState,
  accountProfileState,
  accountAddressState,
  createAccountAddressState,
  countriesList,
  subscriptionDetailState,
  orderHistory,
  subscriptionAlert,
  creatorLibraryState,
  globalMoadalState,
  activatePauseVideo,
  reactivateSubscription,
  unsubscribePlan,
  summaryProfile,
  summaryBalance,
  marketingProductLink,
  affiliateProfileState,
  changeInfluencerPassword,
  paymentData,
  orderTrackingState,
  transactionData,
  purchaseAlertState,
  mediaAssetState,
  translation,
  uploadProfilePhoto,
  goaffproPaymentState,
  athenaAnalyticsState,
  creatorAthenaAnalyticsState,
  goaffproAnalyticsState
};

export default combineReducers({
  loginUser: loginState.default(),
  refreshToken: refreshTokenState.default(),
  totalViewsData: totalViewsState.default(),
  totalGraphViews: totalGraphViewState.default(),
  clickStatsWeekly: clickStatsWeeklyState.default(),
  planData: planDataState.default(),
  grabProducts: grabProductsState.default(),
  influencerNetwork: influencerNetworkState.default(),
  totalGlobalStatsData: totalGlobalStatsState.default(),
  addPyvitProductUrl: addPyvitProductState.default(),
  uploadCustomProduct: uploadCustomProductState.default(),
  saveProduct: saveProductState.default(),
  deleteProduct: deleteProductState.default(),
  editProduct: editProductState.default(),
  videos: videosState.default(),
  videosAnalytics: videosAnalyticsState.default(),
  addVideo: addVideoState.default(),
  videoDetail: videoDetailState.default(),
  deleteVideo: deleteVideoState.default(),
  saveVideoDetail: saveVideoDetailState.default(),
  changeVideoThumbnail: changeVideoThumbnailState.default(),
  unassignProduct: unassignProductState.default(),
  editVideoAuth: editVideoAuthState.default(),
  assignItem: assignItemState.default(),
  productByVideo: productByVideoState.default(),
  videoViews: videoViewsState.default(),
  uniqueVideoViews: uniqueVideoViewsState.default(),
  videoProductClick: videoProductClickState.default(),
  analyticsVideo: analyticsVideoState.default(),
  userProfile: userProfileState.default(),
  createNomad: createNomadState.default(),
  nomadDashboard: nomadDashboardState.default(),
  accountProfile: accountProfileState.default(),
  accountAddress: accountAddressState.default(),
  createAccountAddress: createAccountAddressState.default(),
  countriesList: countriesList.default(),
  subscriptionDetail: subscriptionDetailState.default(),
  orderHistory: orderHistory.default(),
  subscriptionAlert: subscriptionAlert.default(),
  creatorLibrary: creatorLibraryState.default(),
  globalMoadal: globalMoadalState.default(),
  activatePauseVideo: activatePauseVideo.default(),
  reactivateSubscription: reactivateSubscription.default(),
  unsubscribePlan: unsubscribePlan.default(),
  summaryProfile: summaryProfile.default(),
  summaryBalance: summaryBalance.default(),
  marketingProductLink: marketingProductLink.default(),
  affiliateProfile: affiliateProfileState.default(),
  changeInfluencerPassword: changeInfluencerPassword.default(),
  paymentData: paymentData.default(),
  orderTracking: orderTrackingState.default(),
  transactionData: transactionData.default(),
  purchaseAlert: purchaseAlertState.default(),
  mediaAssets: mediaAssetState.default(),
  translation: translation.default(),
  uploadProfilePhoto: uploadProfilePhoto.default(),
  goaffproPayment: goaffproPaymentState.default(),
  athenaAnalytics: athenaAnalyticsState.default(),
  creatorAthenaAnalytics: creatorAthenaAnalyticsState.default(),
  goaffproAnalytics: goaffproAnalyticsState.default(),
});
