import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, CREATOR_ATHENA_ANALYTICS_CACHE } = actionTypes;

//write sagas function

function* creatorAthenaAnalyticsSagas(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getCreatorAthenaAnalyticsData,
      data.data
    );
    yield sendPayload(apiResponse, CREATOR_ATHENA_ANALYTICS_CACHE);
  } catch (e) {
    yield sendPayloadFailure(e, CREATOR_ATHENA_ANALYTICS_CACHE);
  }
}

export const sagas = {
  watchCreatorAthenaAnalyticsSagas: takeLatest(
    actionTypes.CREATOR_ATHENA_ANALYTICS_CACHE[REQUEST],
    creatorAthenaAnalyticsSagas
  ),
};
