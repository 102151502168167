import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Info,
  StyledTableCell,
  StyledTableRow,
} from "../../../utils/styledComponents";
import { Button, CircularProgress, Stack, useMediaQuery } from "@mui/material";
import { stylesGlobal } from "../../../utils/muiStyles";
import { RootState } from "../../../configureStore";
import { IAPIData } from "../../../models/types.model";
import { CONSTANTS } from "../../../utils/constants";
import { CreatorActionRowStyles } from "./style";
import FloatDotIndecator from "../../../shared/components/FloatDotIndecator";
import { copyVideoLink, handleScroll } from "./helper";
interface IProps {
  row: any;
  expanded?: boolean;
}

function CreatorLibraryActionRow({ row, expanded }: IProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mobile = useMediaQuery("(max-width:600px)");
  const { analyticsRow } = CreatorActionRowStyles;
  const ref = useRef<HTMLDivElement | null>(null);

  const profileData = useSelector(
    (state: RootState) => state?.entities?.userProfile?.userData as IAPIData
  );

  const copyContentData = useSelector(
    (state: RootState) => state?.entities?.creatorLibrary?.copyVideoLinkData as IAPIData
  );

  const userRole = profileData?.data && profileData?.data?.user?.role;

  const [copyLinkContents, setCopyLinkContents] = useState<string[]>([]);
  const [isNavRefLeft, setNavLeft] = useState(true);
  const [scrollableNav, setScrollable] = useState<boolean>(true);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("scroll", () =>
        handleScroll(ref, setNavLeft)
      );
      const { scrollWidth, clientWidth } = ref.current;
      const isScrollable = scrollWidth > clientWidth;
      setScrollable(isScrollable);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("scroll", () =>
          handleScroll(ref, setNavLeft)
        );
      }
    };
  });

  useEffect(() => {
    if (!copyContentData.isLoading) {
      setCopyLinkContents([])
    }
  }, [copyContentData.isLoading])

  const RowData = [
    { name: t("Total views"), value: row?.views },
    { name: t("Unique views"), value: row?.unique },
    { name: t("Product clicks"), value: row?.clicks },
  ];

  const RowValues = ({ name, value }: any) => {
    return (
      <Stack sx={analyticsRow.infoBox}>
        <Info sx={analyticsRow.infoMobile}>
          {name}:<b> {value} </b>
        </Info>
      </Stack>
    );
  };

  const statValue = (value: string | number | undefined) => {
    if (value === "...") {
      return <CircularProgress size={"12px"} />
    } else {
      return value
    }
  }

  return (
    <StyledTableRow sx={analyticsRow.rowStyle}>
      {!mobile ? (
        <StyledTableCell colSpan={6} sx={analyticsRow.tableCell}>
          <Stack sx={analyticsRow.containerDesktop}>
            <Box sx={{ ...analyticsRow.wrapBox, flexGrow: 1 }}>
              {RowData.map((stat: any) => (
                <RowValues name={stat.name} value={statValue(stat.value)} />
              ))}
            </Box>
            <Box sx={{ display: "flex" }}>
              <Button
                variant="contained"
                disabled={
                  copyLinkContents?.includes(row.id.toString()) ||
                  userRole !== CONSTANTS.roles.INFLUENCER
                }
                sx={{
                  ...stylesGlobal.containedBtn,
                  width: "140px",
                  fontWeight: "500",
                  margin: "0px",
                }}
                onClick={() =>
                  copyVideoLink(row.id.toString(), dispatch, copyLinkContents)
                }
              >
                {t("Copy video")}
              </Button>
            </Box>
          </Stack>
        </StyledTableCell>
      ) : (
        <StyledTableCell colSpan={6} sx={analyticsRow.tableCell}>
          <Box
            ref={ref}
            className={"overflow-x-stats-mobilenavsroll"}
            sx={analyticsRow.mobileScrollOuter}
          >
            <Box sx={expanded ? analyticsRow.wrapBox : analyticsRow.flexBox}>
              {RowData.map((stat: any) => (
                <RowValues name={stat.name} value={stat.value} />
              ))}
            </Box>
          </Box>
          {scrollableNav && (
            <Box sx={analyticsRow.scrollIndicatorBox}>
              <FloatDotIndecator checked={isNavRefLeft} />
              <FloatDotIndecator checked={!isNavRefLeft} />
            </Box>
          )}
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
}

export default CreatorLibraryActionRow;
