import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { centerButtonWrapper, detailsStyle } from "./style";
import { StyledText } from "../../../utils/styledComponents";
import { useFormik } from "formik";
import { taxInitialValues, taxSchema } from "./settingsSchema";
import { addressDetailsStyle } from "../../eWallet/GlobalCard/style";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { stylesGlobal } from "../../../utils/muiStyles";
import { useDispatch, useSelector } from "react-redux";
import { addAffiliateAddress } from "../../../actions";
import { RootState } from "../../../configureStore";
import { useTranslation } from "react-i18next";
import InfluencerReferralCode from "./InfluencerReferralCode";

function InfluencerDetails() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [modified, setModified] = useState<boolean>(false);

  const { affiliateProfileData, postAffiliateAddress }: any = useSelector(
    (state: RootState) => state?.entities?.affiliateProfile
  );

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    dirty,
  } = useFormik({
    initialValues: taxInitialValues,
    validationSchema: taxSchema,
    onSubmit: () => {
      dispatch(addAffiliateAddress.request(values));
    },
  });

  useEffect(() => {
    if (!affiliateProfileData?.isLoading && !affiliateProfileData?.isError) {
      setFieldValue(
        "tax_identification_number",
        affiliateProfileData?.data.tax_identification_number ?? ""
      );
    }
  }, [affiliateProfileData?.isLoading]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6} sx={detailsStyle.wrapper.height}>
        <InfluencerReferralCode />
      </Grid>
      <Grid item xs={12} lg={6} sx={detailsStyle.wrapper.height}>
        <Box sx={{ ...detailsStyle.wrapper, mb: "0" }}>
          <Box sx={detailsStyle.wrapper.item}>
            <StyledText fontvalue="20px" lineheight="30px">
              {t("Tax identification number")}
            </StyledText>
            <StyledText>
              {t("EIN or SSN or PAN (whichever is applicable)")}
            </StyledText>
            <StyledText
              fontvalue="16px"
              lineheight="22px"
              sx={detailsStyle.wrapper.item.labelText}
            >
              {t("Tax identification number")}
            </StyledText>
            <TextField
              size="small"
              type={showPassword ? "text" : "password"}
              sx={addressDetailsStyle.wrapper.field}
              id="tax_identification_number"
              name="tax_identification_number"
              onChange={(e) => {
                handleChange(e);
                setModified(true);
              }}
              value={values.tax_identification_number}
              error={
                touched?.tax_identification_number &&
                !!errors?.tax_identification_number
              }
              helperText={
                touched?.tax_identification_number &&
                errors?.tax_identification_number &&
                t(errors?.tax_identification_number)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                      }}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <Box sx={centerButtonWrapper}>
              <Button
                variant="contained"
                disabled={
                  values.tax_identification_number.length < 1 ||
                  !dirty ||
                  !modified
                }
                sx={{ ...stylesGlobal.containedBtn, mb: "19px" }}
                onClick={() => handleSubmit()}
              >
                {t("Submit")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default InfluencerDetails;
