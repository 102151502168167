import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
import { toast } from "react-toastify";
import { t } from "i18next";
const { REQUEST, ACTIVATE_PAUSE_VIDEO } = actionTypes;

//write sagas function

function* activatePauseVideoSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.activatePauseVideoAPI,
      data.data
    );
    if (data.data.status === "active") {
      toast.success(t("Video activated successfully"));
    } else {
      toast.success(t("Video paused successfully"));
    }
    yield sendPayload(apiResponse, ACTIVATE_PAUSE_VIDEO);
  } catch (e) {
    yield sendPayloadFailure(e, ACTIVATE_PAUSE_VIDEO);
  }
}

export const sagas = {
  watchActivePauseVideo: takeLatest(
    actionTypes.ACTIVATE_PAUSE_VIDEO[REQUEST],
    activatePauseVideoSaga
  ),
};
