/* eslint-disable */
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledText } from "../../../utils/styledComponents";
import ClearIcon from "@mui/icons-material/Clear";
import { Styles, addProductStyle } from "../style";
import { stylesGlobal } from "../../../utils/muiStyles";
import FloatModal from "../FloatModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../configureStore";
import {
  assignItemCall,
  editProductCall,
  productsByVideoIdCall,
  saveProductCall,
  uploadCustomProductCall,
} from "../../../actions";
import FloatThumbnailBody from "./FloatThumbnailBody";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { productModalStyle } from "./styles";
import { useTranslation } from "react-i18next";
import { iskeyInObject } from "../../../utils/hooks";

interface IModalProps {
  open: boolean;
  onClose: any;
  productType?: string;
  product?: any;
}

const FloatAddProductModal = ({
  open,
  onClose,
  productType,
  product,
}: IModalProps) => {
  const MySwal = withReactContent(Swal);
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { uploadCustomProductData }: any = useSelector(
    (state: RootState) => state?.entities?.uploadCustomProduct
  );

  const { saveProductData }: any = useSelector(
    (state: RootState) => state?.entities?.saveProduct
  );

  const { editProductData }: any = useSelector(
    (state: RootState) => state?.entities?.editProduct
  );

  const { assignItemData }: any = useSelector(
    (state: RootState) => state?.entities?.assignItem
  );

  const { produtsByvideoIdData }: any = useSelector(
    (state: RootState) => state?.entities?.productByVideo
  );

  const [openThumbnailModal, setOpenThumbnailModal] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [productData, setProductData] = useState({
    product_name: "",
    product_url: "",
    vendor_name: ""
  });
  const [disabled, setDisabled] = useState(false);
  const [linkedProductsId, setLinkedProductsId] = useState([]);

  const handleClose = () => {
    setProductData({
      product_name: "",
      product_url: "",
      vendor_name: ""
    });
    setThumbnail("");
    setDisabled(false);
    onClose();
  };

  useEffect(() => {
    dispatch(
      productsByVideoIdCall.request({ data: { content_id: params?.id } })
    );
  }, []);
  useEffect(() => {
    if (
      !produtsByvideoIdData?.isLoading &&
      produtsByvideoIdData?.data?.length > 0
    )
      setLinkedProductsId(
        produtsByvideoIdData?.data?.map((item: any) => item.item_id)
      );
  }, [produtsByvideoIdData?.isLoading]);

  useEffect(() => {
    if (uploadCustomProductData?.data?.file_key?.length) {
      let fileKey = uploadCustomProductData?.data?.file_key;
      let data = {
        thumbnail_file_key: fileKey,
        title: productData.product_name,
        url: productData.product_url,
        vendor_name: productData.vendor_name
      };
      if (product && Object.keys(product)?.length) {
        dispatch(
          editProductCall.request({
            data: { ...data, item_id: product?.item_id },
          })
        );
      } else {
        dispatch(saveProductCall.request({ data: data }));
      }
      dispatch(uploadCustomProductCall.reset());
    }
    if (uploadCustomProductData?.isError) {
      setDisabled(false);
    }
  }, [
    uploadCustomProductData?.data?.file_key,
    uploadCustomProductData?.isError,
  ]);

  useEffect(() => {
    if (!saveProductData?.isLoading && saveProductData?.data?.success) {
      if (productType == "linking") {
        dispatch(
          assignItemCall.request({
            data: {
              item_ids: [
                ...linkedProductsId,
                saveProductData?.data?.data?.item_id,
              ],
              content_id: params.id,
            },
          })
        );
        dispatch(saveProductCall?.reset());
      } else {
        dispatch(saveProductCall?.reset());
        handleClose();
      }
    }
    if (saveProductData?.isError) {
      dispatch(saveProductCall?.reset());
      setDisabled(false);
      handleClose();
    }
  }, [saveProductData?.isLoading]);

  useEffect(() => {
    if (product?.custom) {
      try {
        let jsonString = product?.custom;
        let jsonObject = JSON.parse(jsonString);
        if (product?.title) {
          setProductData({
            product_name: product?.title,
            product_url: product?.url,
            vendor_name: jsonObject?.v_name
          });
          setThumbnail(product?.thumbnail_url);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, [product?.title, product?.custom]);
  
  useEffect(() => {
    if (editProductData) {
      if (
        (iskeyInObject(editProductData,"isEditSuccess") &&
          editProductData?.isEditSuccess) ||
        editProductData.isError
      ) {
        dispatch(editProductCall.reset());
        setDisabled(false);
        handleClose();
      }
    }
  }, [editProductData]);

  useEffect(() => {
    if (
      (!assignItemData?.isLoading && assignItemData?.data?.success) ||
      assignItemData?.isError
    ) {
      dispatch(
        productsByVideoIdCall.request({ data: { content_id: params.id } })
      );
      dispatch(assignItemCall.reset());
      handleClose();
    }
  }, [assignItemData]);

  const handleChangeThumbnail = async (newThumbnail: any) => {
    let blobFile = await fetch(newThumbnail).then((r) => r.blob());
    let file = new File([blobFile], "thumbnail.jpeg");
    let formData = new FormData();
    formData.append("file", file);
  };

  const changeThumbnailCallback = (imageUrl: any) => {
    handleChangeThumbnail(imageUrl);
    setThumbnail(imageUrl);
  };

  const onSaveProduct = async () => {
    if (
      (!productData.product_url.includes("https://") &&
        !productData.product_url.includes("http://")) ||
      productData?.product_name?.length === 0 
    ) {
      MySwal.fire({
        icon: "error",
        title: "",
        text: t("Make sure you enter the Product Name and include https:// or http:// at the beginning of the url."),
        confirmButtonText: t("OK"),
        confirmButtonColor: "#333",
        iconColor: "#333",
      });
    }
    if (thumbnail) {
      if (thumbnail?.startsWith("blob")) {
        let blobFile = await fetch(thumbnail).then((r) => r.blob());
        let file = new File([blobFile], "thumbnail", { type: "image/jpeg" });
        let formData = new FormData();
        formData.append("file", file);

        if (
          productData?.product_name?.length > 0 && 
          (productData?.product_url?.startsWith("http://") ||
            productData?.product_url?.startsWith("https://"))
        ) {
          dispatch(uploadCustomProductCall.request({ data: formData }));
          setDisabled(true);
        } else {
          MySwal.fire({
            icon: "error",
            title: "",
            text: "Make sure you enter the Product Name and include https:// or http:// at the beginning of the url.",
            confirmButtonText: "OK",
            confirmButtonColor: "#333",
            iconColor: "#333",
          });
        }
      } else if (
        product?.thumbnail_url?.length > 0 &&
        productData?.product_name?.length > 0 &&
        (productData?.product_url?.startsWith("http://") ||
          productData?.product_url?.startsWith("https://"))
      ) {
        let data = {
          title: productData.product_name,
          url: productData.product_url,
          vendor_name: productData.vendor_name
        };
        dispatch(
          editProductCall.request({
            data: { ...data, item_id: product?.item_id },
          })
        );
        setDisabled(true);
        dispatch(uploadCustomProductCall.reset());
      }
    } else {
      setDisabled(false);

      MySwal.fire({
        icon: "error",
        title: t("Please enter a thumbnail to proceed"),
        text: t(
          "To include a custom product, it is necessary to upload a thumbnail."
        ),
        confirmButtonText: t("OK"),
        confirmButtonColor: "#333",
        cancelButtonText: t("Cancel"),
        iconColor: "#333",
      });
      return;
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{ ...Styles.productModalWrapper, maxHeight: "630px" }}
        className="overflow-x-contain"
      >
        <Box sx={Styles.productModalWrapper.modalHeader}>
          <StyledText fontvalue="22px" lineheight="33px" fontWeight="500">
            {productType !== "linking"
              ? product
                ? t("Edit product")
                : t("Add new product")
              : t("Add new product")}
          </StyledText>
          <Box
            sx={Styles.productModalWrapper.modalHeader.crossWrapper}
            onClick={handleClose}
          >
            <ClearIcon />
          </Box>
        </Box>
        <Box
          className="overflow-x-content"
          sx={Styles.productModalWrapper.modalBody}
        >
          <Stack
            sx={{
              ...addProductStyle.boxStyle,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Stack
              sx={
                thumbnail
                  ? productModalStyle.floatAddProductStyles.imageBtnContainer
                  : {
                    ...productModalStyle.floatAddProductStyles
                      .imageBtnContainer,
                    minHeight: { xs: "fit-content", md: "170px" },
                  }
              }
            >
              {thumbnail ? (
                <>
                  <img
                    src={thumbnail}
                    style={{
                      height: "10rem",
                      width:"100%",
                      objectFit: "contain",
                      display: "block",
                      marginBottom: "15px",
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={() => setOpenThumbnailModal(true)}
                    sx={{
                      ...stylesGlobal.containedBtn,
                      width: "160px",
                      mt: "0px",
                      mb: "15px",
                    }}
                  >
                    {t("Change thumbnail")}
                  </Button>
                </>
              ) : (
                <Button
                  onClick={() => setOpenThumbnailModal(true)}
                  variant="contained"
                  sx={{
                    ...stylesGlobal.containedBtn,
                    width: "160px",
                    mb: "20px",
                  }}
                >
                  {t("Upload thumbnail")}
                </Button>
              )}

            </Stack>

            <Box>
              <TextField
                size="medium"
                type="text"
                fullWidth
                label={t("Product name")}
                placeholder={t("Product Name")}
                variant="outlined"
                sx={addProductStyle.textFieldStyle}
                id={"name"}
                name={"name"}
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    product_name: e.target.value,
                  })
                }
                value={productData.product_name}
              />

              <TextField
                size="medium"
                type="text"
                fullWidth
                label={t("Product URL link")}
                variant="outlined"
                placeholder="https://domain.com/product-url"
                sx={addProductStyle.urlTextField}
                id={"url"}
                name={"Url"}
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    product_url: e.target.value,
                  })
                }
                value={productData.product_url}
              />

              <TextField
                size="medium"
                type="text"
                fullWidth
                label={t("Product brand name")}
                variant="outlined"
                placeholder={t("Product brand name")}
                sx={addProductStyle.urlTextField}
                id={"vname"}
                name={"Vname"}
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    vendor_name: e.target.value,
                  })
                }
                value={productData.vendor_name}
                disabled={product ? !product?.is_editable : false}
              />

              <Stack alignItems={"center"}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  width="240px"
                >
                  <Button
                    variant="outlined"
                    sx={{ ...stylesGlobal.outlinedBtn }}
                    onClick={() => handleClose()}
                  >
                    {t("Cancel")}
                  </Button>

                  <Button
                    onClick={onSaveProduct}
                    variant="contained"
                    sx={{
                      ...stylesGlobal.containedBtn,
                      cursor: disabled ? "not-allowed" : "pointer",
                    }}
                    disabled={disabled}
                  >
                    {disabled && (
                      <CircularProgress size="14px" sx={{ mr: "10px" }} />
                    )}{" "}
                    {t("Save")}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <FloatModal
          modalTitle="Upload thumbnail"
          modalBody={
            <FloatThumbnailBody
              changeThumbnailCallback={(e: any) => changeThumbnailCallback(e)}
              onClose={() => setOpenThumbnailModal(false)}
            />
          }
          open={openThumbnailModal}
          onClose={() => setOpenThumbnailModal(false)}
        />
      </Box>
    </Modal>
  );
};

export default FloatAddProductModal;
