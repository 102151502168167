/* eslint-disable */
import * as Yup from "yup";

export const initialValues = {
  password: "",
};

export const onlyPasswordSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  password: Yup.string()
    .required("*Please enter the password")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/|.,<>\[\]\\]).{8,}$/,
      `*Password must contain at least one uppercase letter, one lowercase letter, one numeric digit, one special character, and be at least 8 characters long`
    ),
});
