import { BOX_SHADOW_COLOR, PRIMARYCOLOUR, SECONDARYCOLOUR, THIN_BORDER_COLOR } from "../colors";

export const ThemeStyles = {
  palette: {
    primary: {
      main: "#333",
    },
    secondary: {
      main: "#666",
    },
  },
  shape: {
  },
  typography: {
    fontFamily: "Jost",
  },
};

export const stylesGlobal = {
  buttonLoader: { marginRight: "10px", color: "#fff" },
  mainContainer: {
    width: "100vw !important",
    height: "100vh !important",
    overflow: "hidden",
  },
  stackRowCenter: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  stackColumnCenter: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  popupButton: {
    mt: 1,
    mx: 1,
    py: 1,
    px: 3,
    width: "fit-content",
    minWidth: "120px",
    textTransform: "capitalize",
  },
  modalContainer: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: { xs: "calc(100vw - 60px)", md: "fit-content" },
    height: "fit-content",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: "20px",
    outline: 0,
    borderRadius: "30px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  loader: {
    row: {
      height: "100%",
      flexDirection: "row",
      alignItems: "center",
    },
    col: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  popuptitle: {
    margin: 0,
    fontFamily: `Roboto, Helvetica, Arial, sansSerif`,
    fontWeight: 700,
    fontSize: "2.125rem",
    lineHeight: "1.235",
    letterSpacing: "0.00735em",
  },
  customModalContainer: {
    overflowY: "auto",
    overflowX: "hidden",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "calc(100vw - 30px)", md: "500px" },
    maxWidth: { xs: "500px", md: "500px" },
    height: "calc(100vh - 100px)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: "20px",
    outline: 0,
    borderRadius: "30px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    mt: 2,
    width: { xs: "100%", md: "450px" },
    maxWidth: "450px",
  },
  thumbnailModal: {
    baseStyle: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "1.75rem",
      width: "90%",
      height: "auto",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: THIN_BORDER_COLOR,
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    focusedStyle: {
      borderColor: SECONDARYCOLOUR,
    },
    acceptStyle: {
      borderColor: "#00e676",
    },
    rejectStyle: {
      borderColor: "#ff1744",
    },
  },
  iconBtn: { cursor: "pointer", fontSize: "22px", mr: "5px" },
  searchFilterStyle: {
    outer: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: { xs: "row", lg: "row", md: "column" },
      padding: "10px 0",
      rowGap: "10px",
      alignItems: "self-start",
    },
    fourFilterOuter: {
      display: "flex",
      flexDirection: { xs: "column", xl: "row" },
      padding: "10px 0",
      rowGap: "10px",
    },
    searchOuter: {
      flexGrow: 1,
      padding: { xs: "0", md: "0 10px 0  0px" },
      margin: "5px 0px",
      minWidth: "240px",
    },
    filterOuter: {
      width: "fit-content",
      display: "flex",
      flexWrap: "wrap",
      columnGap: "1px",
      rowGap: "10px",
      height:"45px",
      alignItems:"center",
    },
  },
  containedBtn: {
    mt: "10px",
    minWidth: "100px",
    width: "fit-content",
    padding: "6px 24px",
    textTransform: "none",
  },
  outlinedBtn: {
    mt: "10px",
    padding: "5px 20px",
    width: "fit-content",
    minWidth: "100px",
    textTransform: "none",
    fontFamily:"Jost",
    border:`1px solid ${PRIMARYCOLOUR}`,
    boxShadow:`0 2px 2px ${BOX_SHADOW_COLOR}`
  },
  stackStyle: { width: "100%", alignItems: "flex-end" },
  iconButtonStyle: { top: "10px" },
  uploadIconStyle: { fontSize: 115 },
  fileButtonStyle: { mt: 2, mb: 2 },
  alertBox: {
    marginTop: "18px",
    marginBottom: "18px",
    boxShadow: "0px 4px 15px 0px rgba(0,0,0,0.12)",
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    columnGap: "3px",
    padding: "18px 24px",
    borderBottom: "2px solid #FFA800",
  },
};
