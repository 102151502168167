import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CartesianGrid,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";

import { Box, Stack } from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ProductWiseChart from "./ProductWiseChart";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../configureStore";
import { productsByVideoIdCall } from "../../../../actions";
import Loading from "../../../../shared/Loading";
import { THIN_BORDER_COLOR } from "../../../../colors";
import { CONSTANTS } from "../../../../utils/constants";
import { useTranslation } from "react-i18next";

export interface ChartBarType {
  name: string;
  dataKey: string;
  fill: string;
  shape?: any;
}
interface BarChartProps {
  Xaxis: {
    dataKey: string;
  };
  ChartData: any[];
  ChartBars?: ChartBarType[];
  max?: number | null;
}

const Xaxis = { dataKey: "name" };

function GradientBar(props: any, data: any) {
  const { fill, x, y, width, height, dataKey } = props;
  return (
    <g>
      <defs>
        <linearGradient id="barFill1" x1="0" y1="0" x2="0" y2="1">
          {" "}
          {/* Use unique id */}
          <stop offset="5%" stopColor="#BB69C2" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#FFFFFF" stopOpacity={1} />
        </linearGradient>
      </defs>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill="url(#barFill1)"
        rx={5} // Adjust the radius to your preference
        ry={5} // Adjust the radius to your preference
      />
    </g>
  );
};

const GradientBar2 = (props: any, data: any) => {
  const { fill, x, y, width, height, dataKey } = props;
  return (
    <g>
      <defs>
        <linearGradient id="barFill2" x1="0" y1="0" x2="0" y2="1">
          {" "}
          {/* Use unique id */}
          <stop offset="5%" stopColor="#2456CC" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#FFFFFF" stopOpacity={1} />
        </linearGradient>
      </defs>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill="url(#barFill2)"
        rx={5} // Adjust the radius to your preference
        ry={5} // Adjust the radius to your preference
      />
    </g>
  );
};

const ChartBars = [
  {
    name: "Product clicks",
    dataKey: "product_clicks",
    fill: "#BB69C2",
    stroke: "#BB69C2",
    shape: GradientBar,
  },
  {
    name: "Click throughs",
    dataKey: "click_throughs",
    fill: "#2456CC",
    stroke: "#2456CC",
    shape: GradientBar2,
  },
];

const LinkedProductsGraph = ({
  setSelectFilters,
  openMobileDate,
  setOpenMobileDate,
}: any) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { filterViews } = CONSTANTS;

  const { produtsByvideoIdData }: any = useSelector(
    (state: RootState) => state?.entities?.productByVideo
  );

  const moment = require("moment");
  const today = moment().format("YYYY-MM-DD");
  const past7thDay = moment().subtract(6, "days").format("YYYY-MM-DD");

  const [isAll, setIsAll] = useState(true);
  const [clickedProduct, setClickedProduct]: any = useState();
  const [chartData, setChartData]: any = useState();

  const CustomXAxisTick = ({ x, y, payload }: any) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <rect
          x={-40}
          y={10}
          width={80}
          height={30}
          fill="#fff"
          stroke="#ccc" // Set border color
          strokeWidth={1}
          rx={10}
          ry={10}
          onClick={() => { }}
          style={{
            cursor: "pointer",
            border: `1px solid ${THIN_BORDER_COLOR}`,
          }}
        />
        <text
          x={0}
          y={23}
          dy={4}
          textAnchor="middle"
          fill="#666"
          onClick={() => {
            // handle click event
            let clicked = chartData?.filter(
              (item: any) => item.name === payload.value
            );
            setClickedProduct(clicked[0]);
            setIsAll(false);
          }}
          style={{ cursor: "pointer" }}
        >
          {payload.value?.slice(0, 5) + ".."}
        </text>
      </g>
    );
  };

  const CustomLegend = (props: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        {ChartBars?.map((item: any) => {
          return (
            <Stack direction={"row"} alignItems={"center"}>
              <RadioButtonCheckedIcon
                sx={{
                  color: item.stroke,
                  marginRight: "5px",
                  marginLeft: "5px",
                }}
              />
              <span>{t(item.name)}</span>
            </Stack>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    // dispatch(productsByVideoIdCall.request({ data: { content_id: id } }));
    dispatch(
      productsByVideoIdCall.request({
        data: {
          content_id: id,
        },
      })
    );
  }, []);

  useEffect(() => {
    if (!produtsByvideoIdData?.isLoading && !produtsByvideoIdData?.isError) {
      let chart: any = [];

      produtsByvideoIdData?.data?.map((item: any) => {
        chart.push({
          click_throughs: item.click_throughs,
          product_clicks: item.product_clicks,
          name: item.title,
          item_id: item.item_id,
        });
      });
      setChartData(chart);
    }
  }, [produtsByvideoIdData]);

  return (
    <>
      {!produtsByvideoIdData?.isLoading ? (
        !produtsByvideoIdData?.isLoading && (
          <Box>
            {isAll ? (
              <Box
                className="overflow-x-contain"
                style={{
                  width: "100%",
                  maxWidth: "calc(100% - 10px)",
                  height: "330px",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs:
                        chartData?.length > 4
                          ? "1200px"
                          : chartData?.length > 2
                            ? "800px"
                            : "400px",
                      md: chartData?.length > 6 ? "850px" : "100%",
                    },
                  }}
                  height="100%"
                >
                  <ResponsiveContainer width={"100%"} height="100%">
                    <BarChart
                      data={chartData}
                      margin={{ top: 5, right: 20, left: 5, bottom: 20 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />

                      <XAxis
                        axisLine={{ stroke: "black" }}
                        tickLine={false}
                        style={{ stroke: "#333" }}
                        dataKey={Xaxis.dataKey}
                        interval={0}
                        tick={<CustomXAxisTick />}
                      // dataKey={Xaxis.dataKey}
                      />
                      <YAxis
                        axisLine={{ stroke: "black" }}
                        tickLine={false}
                        style={{ stroke: "#333" }} // Hide ticks on X axis
                        domain={[0, "dataMax + 10"]}
                        tick={{
                          fontSize: 12,
                          fontFamily: "Jost",
                          fontWeight: 400,
                          dx: -5,
                        }}
                      />
                      <Tooltip />
                      <Legend verticalAlign="top" content={CustomLegend} />
                      {ChartBars?.map((item: ChartBarType, index: number) => {
                        return (
                          <Bar
                            key={index}
                            name={item.name}
                            dataKey={item.dataKey}
                            fill={item.fill}
                            shape={item.shape}
                            radius={[5, 5, 0, 0]}
                          //   barSize={20}
                          />
                        );
                      })}
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            ) : (
              !isAll && (
                <ProductWiseChart
                  setSelectFilters={(e: any) => setSelectFilters(e)}
                  product={clickedProduct}
                  setAll={setIsAll}
                  products={chartData}
                  setClickedProduct={setClickedProduct}
                  openMobileDate={openMobileDate}
                  setOpenMobileDate={setOpenMobileDate}
                />
              )
            )}
          </Box>
        )
      ) : (
        <Box height="200px">
          <Loading />
        </Box>
      )}
    </>
  );
};

export default LinkedProductsGraph;
