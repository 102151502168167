import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE, RESET } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";

const { SUCCESS, REQUEST, VIDEO_PRODUCT_CLICKS_STATS } = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const videoProductClickStat = (state = intialState, action: actions) => {
    switch (action.type) {
      case VIDEO_PRODUCT_CLICKS_STATS[SUCCESS]:
        return { ...state, data: action?.payload?.data, isLoading: false };
      case VIDEO_PRODUCT_CLICKS_STATS[REQUEST]:
        return action?.payload
          ? { ...state, data: action?.payload?.data, isLoading: true }
          : { ...state, isLoading: true };
      case VIDEO_PRODUCT_CLICKS_STATS[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case VIDEO_PRODUCT_CLICKS_STATS[RESET]:
        return intialState;
      default:
        return state;
    }
  };

  return combineReducers({
    videoProductClickStat,
  });
};

export default entity;

export const videoProductClick = (state: RootState) =>
  state.entities.videoProductClick;
