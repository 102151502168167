import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE, RESET } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";

const { SUCCESS, REQUEST, CREATOR_ATHENA_ANALYTICS_CACHE } = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const creatorAthenaAnalyticsData: any = (
    state = { ...intialState, isLoading: false },
    action: actions
  ) => {
    switch (action.type) {
      case CREATOR_ATHENA_ANALYTICS_CACHE[REQUEST]:
        return intialState;
      case CREATOR_ATHENA_ANALYTICS_CACHE[SUCCESS]:
        const t = new Date()
        const currentTime = t.getTime();
        localStorage.setItem('lastCreatorAthenaRefreshTime', currentTime.toString());
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: false,
        };
      case CREATOR_ATHENA_ANALYTICS_CACHE[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case CREATOR_ATHENA_ANALYTICS_CACHE[RESET]:
        return {};
      default:
        return state;
    }
  };

  return combineReducers({
    creatorAthenaAnalyticsData,
  });
};

export default entity;

export const creatorAthenaAnalytics = (state: RootState) =>
  state.entities.creatorAthenaAnalytics;
