import { TableHead } from '@mui/material';
import React from 'react';
import { StyledTableHeadCell, StyledTableHeadRow } from '../../../utils/styledComponents';
import { HeadCell, headCellsTwo } from './helper';
import { useTranslation } from 'react-i18next';

const NetworkTableTowHeader = () => {
    const { t } = useTranslation();

    return (
        <TableHead>
          <StyledTableHeadRow>
            {headCellsTwo.map((headCell: HeadCell, index: number) => {
              {
                return (
                  <StyledTableHeadCell
                    key={index}
                    align="left"
                    id={headCell.id}
                    scope="row"
                  >
                    {t(headCell.label)}
                  </StyledTableHeadCell>
                );
              }
            })}
          </StyledTableHeadRow>
        </TableHead>
      );
};

export default NetworkTableTowHeader;