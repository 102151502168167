import { Box, Menu, MenuItem, Stack } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { RouteList } from "../RouteList";
import { openSendMoney } from "../../actions";
import EwalletSignUp from "../../Pages/eWallet/signUp/SignUp";
import EwalletSignIn from "../../Pages/eWallet/signUp/EwalletSignIn";
import { RootState } from "../../configureStore";
import FloatDotIndecator from "../../shared/components/FloatDotIndecator";
import { getTabName } from "../helper";
import { stylesMobile } from "../style";
import { IAPIData } from "../../models/types.model";
import { isArrayIncludesKey, iskeyInObject } from "../../utils/hooks";

const mainRouteModals = ["eWallet"];

function MobileSubRoute({
  setSubRouteName,
  setModelProps,
  accessibleRoute,
}: any) {
  const ref = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [menuRef, setMenuRef] = useState<any>(null);
  const [isNavRefLeft, setNavLeft] = useState(true);
  const [scrollableNav, setScrollable] = useState<boolean>(true);

  const [openStates, setOpenStates] = useState(
    Array(RouteList.length).fill(null)
  );

  const { userData } = useSelector(
    (state: RootState) => state?.entities?.userProfile
  );

  const profileData = useSelector(
    (state: RootState) => state?.entities?.userProfile?.userData as IAPIData
  );

  const userRole = profileData?.data && profileData?.data?.user?.role;

  const onEwalletClick = () => {
    const access: any = userData?.data?.access;
    let eWallet = access?.find((item: any) => item.name === "eWallet");

    let signUpAccess = eWallet?.subRoute?.find(
      (sub: any) => sub.name === "Sign Up"
    );

    if (signUpAccess?.access) {
      setModelProps({
        name: "eWallet Sign Up",
        element: <EwalletSignUp />,
      });
      dispatch(openSendMoney());
    } else {
      setModelProps({
        name: "eWallet Sign In",
        element: <EwalletSignIn />,
      });
      dispatch(openSendMoney());
    }
  };

  const handleScroll = () => {
    if (ref.current) {
      const scrollLeft = ref.current.scrollLeft;
      const scrollWidth = ref.current.scrollWidth;
      const containerWidth = ref.current.clientWidth;
      const isAtLeft = scrollLeft === 0;
      setNavLeft(isAtLeft);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("scroll", handleScroll);
      const { scrollWidth, clientWidth } = ref.current;
      const isScrollable = scrollWidth > clientWidth;
      setScrollable(isScrollable);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("scroll", handleScroll);
      }
    };
  });

  const handleClick = (index: number, event: any,name:string) => {
    setMenuRef(() => event.target);
    const newOpenStates = Array(openStates.length).fill(false);
    newOpenStates[index] = !openStates[index];
    setOpenStates(newOpenStates);
     if (name === "eWallet") {
       onEwalletClick();
     }
  };

  const onSubROuteClick = (
    name: string,
    displayName: boolean,
    navigateTo: string
  ) => {
    let display = displayName ? "block" : "none";
    if (navigateTo !== window?.location?.pathname) {
      setSubRouteName({ name, display });
      navigate(navigateTo);
    }
  };

  const subMenuClick = (item: any, sub: any, index: number) => {
      if (sub.name == "Get a plan") {
        const newOpenStates = Array(openStates.length).fill(false);
        newOpenStates[index] = !openStates[index];
        setOpenStates(newOpenStates);
        window.open(
          "https://www.pyvit.com/pages/pyvit-video-editor-pricing",
          "_blank"
        );
      } else if (iskeyInObject(sub, "isModal")) {
        const newOpenStates = Array(openStates.length).fill(false);
        newOpenStates[index] = !openStates[index];
        setOpenStates(newOpenStates);
        setModelProps({
          name: sub.name,
          element: sub.element,
        });
        dispatch(openSendMoney());
      } else {
        onSubROuteClick(
          sub.name,
          sub.displayName,
          `/${item.route}${sub.route}`
        );
        const newOpenStates = Array(openStates.length).fill(false);
        newOpenStates[index] = !openStates[index];
        setOpenStates(newOpenStates);
      }
  };

  return (
    <>
      <Box
        sx={stylesMobile.navContainer}
        className={"overflow-x-contain-mobilenavsroll"}
        id="list-container"
        ref={ref}
      >
        <Stack sx={stylesMobile.listContainer}>
          {accessibleRoute?.routeList?.map((item: any, index: number) => {
            if (isArrayIncludesKey(mainRouteModals,item.name)) {
              return (
                <Box>
                  <Box
                    onClick={(e) => {
                      handleClick(index, e, item.name);
                    }}
                    sx={stylesMobile.menuButton}
                  >
                    {t(item.name)}
                  </Box>
                </Box>
              );
            } else {
              return (
                <Box>
                  <Box
                    onClick={(e) => handleClick(index, e,item.name)}
                    sx={stylesMobile.menuButton}
                  >
                    {t(item.name)} <ArrowDropDownIcon fontSize="small" />
                  </Box>
                  <Menu
                    anchorEl={menuRef}
                    open={Boolean(openStates[index])}
                    onClose={() => {
                      const newOpenStates = Array(openStates.length).fill(
                        false
                      ); 
                      newOpenStates[index] = !openStates[index];
                      setOpenStates(newOpenStates);
                    }}
                  >
                    {item.subRoute?.map((sub: any) => {
                      return (
                        <MenuItem
                          onClick={() => subMenuClick(item, sub, index)}
                        >
                          {getTabName(sub.name, userRole, t)}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Box>
              );
            }
          })}
        </Stack>
      </Box>
      {scrollableNav && (
            <Stack
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              padding="3px 0px"
            >
              <Stack
                width={"22px"}
                justifyContent={"space-between"}
                alignItems={"center"}
                direction={"row"}
              >
                <FloatDotIndecator checked={isNavRefLeft} />
                <FloatDotIndecator checked={!isNavRefLeft} />
              </Stack>
            </Stack>
          )}
    </>
  );
}

export default MobileSubRoute;
