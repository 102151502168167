import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, GET_PAYMENT_SUMMARY, GET_PAYMENT_DETAILS } = actionTypes;

function* getPaymentSummarySaga() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getPaymentSummaryApi
    );
    yield sendPayload(apiResponse, GET_PAYMENT_SUMMARY);
  } catch (e) {
    yield sendPayloadFailure(e, GET_PAYMENT_SUMMARY);
  }
}

function* getPaymentDetailsSaga() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getPaymentSummaryDetails
    );
    yield sendPayload(apiResponse, GET_PAYMENT_DETAILS);
  } catch (e) {
    yield sendPayloadFailure(e, GET_PAYMENT_DETAILS);
  }
}

export const sagas = {
  watchPaymentSummary: takeLatest(
    actionTypes.GET_PAYMENT_SUMMARY[REQUEST],
    getPaymentSummarySaga
  ),
  watchPaymentDetails: takeLatest(
    actionTypes.GET_PAYMENT_DETAILS[REQUEST],
    getPaymentDetailsSaga
  ),
};
