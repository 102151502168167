import React from "react";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { stylesGlobal } from "./utils/muiStyles";
import "./App.css";
import NavigateRoutes from "./Navigate";
import { theme } from "./theme";

function App() {
  i18n.use(initReactI18next).init({
    resources: {
      en: { translation: {} },
      ja: { translation: {} },
      th: { translation: {} },
      es: { translation: {} },
      ko: { translation: {} },
      fr: { translation: {} },
      ch: { translation: {} },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

  return (
    <main style={stylesGlobal.mainContainer}>
      <ThemeProvider theme={theme}>
        <NavigateRoutes />
        <ToastContainer theme="colored" position="top-right" />
      </ThemeProvider>
    </main>
  );
};

export default App;
