import React from "react";

const TwitterIcon = ({ fill = "#D5D5D5", width = "50", height = "50" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0016 3.60001C10.0492 3.60001 3.60156 10.0476 3.60156 18C3.60156 25.9524 10.0492 32.4 18.0016 32.4C25.954 32.4 32.4016 25.9524 32.4016 18C32.4016 10.0476 25.954 3.60001 18.0016 3.60001ZM25.7584 15.042C25.7656 15.2016 25.7692 15.36 25.7692 15.5184C25.7692 20.4 22.0552 26.0256 15.262 26.0256C13.1752 26.0256 11.2348 25.4148 9.60156 24.366C9.89076 24.4008 10.1848 24.4164 10.4836 24.4164C12.2152 24.4164 13.8064 23.8272 15.0688 22.8348C13.4524 22.8048 12.088 21.7368 11.6188 20.2704C11.8444 20.3136 12.076 20.3364 12.3136 20.3364C12.6508 20.3364 12.9784 20.2908 13.2868 20.2068C11.5972 19.8684 10.324 18.3756 10.324 16.5864C10.324 16.5708 10.324 16.5552 10.324 16.5396C10.822 16.8168 11.3908 16.9824 11.9968 17.0016C11.0068 16.3404 10.354 15.2088 10.354 13.9284C10.354 13.2504 10.5352 12.6156 10.8532 12.072C12.6748 14.3064 15.3964 15.7776 18.4648 15.9312C18.4024 15.6612 18.37 15.3792 18.37 15.09C18.37 13.0512 20.0236 11.3964 22.0624 11.3964C23.1244 11.3964 24.0844 11.8452 24.7576 12.5628C25.5988 12.396 26.3896 12.09 27.1036 11.6664C26.8276 12.5292 26.242 13.2516 25.4788 13.71C26.2252 13.6212 26.9368 13.4232 27.6004 13.1292C27.1072 13.8708 26.482 14.52 25.7584 15.042Z"
      fill="black"
    />
  </svg>
);

export default React.memo(TwitterIcon);
