import { CONSTANTS } from "../utils/constants"
import i18n from "i18next";

export const getTabName = (name:string,userRole:string | undefined,t:any) =>{
    if(name === "Get a plan"){
     switch(userRole){
      case CONSTANTS.roles.CUSTOMER:
        return t(name)
      default: return t("Upgrade plan")
    }
  }else{
    return t(name)
  }
}

export const languageFormat = (language: string) => {
  switch (language) {
    case "English":
      return "en";
    case "Japanese":
      return "ja";
    case "Thai":
      return "th";
    case "Spanish":
      return "es";
    case "Korean":
      return "ko";
    case "French":
      return "fr";
    case "Chinese":
      return "ch";
    default:
      return "en";
  }
};

export const languageTranslateHook = (translatedLabels:any,setIsLanguageDisable:any) =>{
  if (
      !translatedLabels?.isLoading &&
      !translatedLabels?.intialState?.isLoading &&
      (translatedLabels?.intialState?.isError || translatedLabels?.isError)
    ) {
      setIsLanguageDisable(true);
    }
    if (
      !translatedLabels?.isLoading &&
      !translatedLabels?.intialState?.isLoading &&
      !translatedLabels?.intialState?.isError &&
      !translatedLabels?.isError
    ) {
      i18n.addResourceBundle(
        "en",
        "translation",
        translatedLabels?.data?.english,
        true,
        true
      );
      i18n.addResourceBundle(
        "ja",
        "translation",
        translatedLabels?.data?.japanese,
        true,
        true
      );
      i18n.addResourceBundle(
        "th",
        "translation",
        translatedLabels?.data?.thai,
        true,
        true
      );
      i18n.addResourceBundle(
        "es",
        "translation",
        translatedLabels?.data?.spanish,
        true,
        true
      );
      i18n.addResourceBundle(
        "ko",
        "translation",
        translatedLabels?.data?.korean,
        true,
        true
      );
      i18n.addResourceBundle(
        "fr",
        "translation",
        translatedLabels?.data?.french,
        true,
        true
      );
      i18n.addResourceBundle(
        "ch",
        "translation",
        translatedLabels?.data?.chinese,
        true,
        true
      );
      setIsLanguageDisable(false);
    }
}