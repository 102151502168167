import { Box } from "@mui/material";
import React from "react";
import { StyledText } from "../../../utils/styledComponents";
import { programInfoStyle } from "./style";
import { useTranslation } from "react-i18next";

function ProgramInfo() {
  const { t } = useTranslation();

  const programInfo = {
    "Earn as a PYVIT Influencer": [
      "Refer people to PYVIT and earn residual monthly income when they subscribe to paid services.",
      "PYVIT customers can send users to thePYVIT website with their affiliate link. Once there the users' sales activity will be tracked and attributed to you.",
      "Even free users can participate. When a free user you refer upgrades, you will also get ongoing commissions for that user.",
    ],
    "Influencer commission model": [
      "PYVIT’s initial 2-teir commission structure pays 20% of the monthly subscriptions their customers pay for, and then it pays 5% of the monthly subscriptions their customer’s customers pay for.",
      "The PYVIT Influencer model allows for passive and assertive affiliate income. Influencers can expect automatic relationship tracking of referrals and customer activities. They can also expect great corporate support, ad creatives, automatic referral links from pyved videos, and more.",
    ],
    "Path to Become an Influencer and Earn": [
      "As a PYVIT Influencer, you will attract people from targeted groups and convert them into monthly subscriber customers. When they convert into paying customers, you make money.",
      "Those new customers will pyv and post shoppable videos, make more sales, and be motivated to share the service with others. When they refer PYVIT, they can earn money and you earn even more.",
    ],
    "It’s Pays to Be a PYVIT Influencer": [
      {
        "Monthly payouts":
          "Commissions are calculated and paid out on a regular schedule.",
      },
      {
        "2 Levels deep":
          "Earn from people you refer and the people they refer.",
      },
      {
        "Residual income":
          "Earn as long as your referrals stay paying customers of PYVIT",
      },
    ],
  };

  return (
    <Box sx={programInfoStyle.wrapper}>
      {Object.entries(programInfo).map(([title, content], index) => (
        <div key={index}>
          <StyledText
            fontvalue="14px"
            lineheight="18px"
            letterSpacing="0.15"
            fontWeight="bold"
          >
            {t(title)}
          </StyledText>
          {Array.isArray(content) ? (
            <Box sx={programInfoStyle.wrapper.boxMargin}>
              {content.map((item, i) => (
                <Box key={i}>
                  {typeof item === "string" ? (
                    <span>{t(item)}</span>
                  ) : (
                    <>
                      {Object.entries(item).map(([key, value], i) => (
                        <Box key={i}>
                          <StyledText
                            fontvalue="14px"
                            lineheight="18px"
                            letterSpacing="0.15"
                            fontWeight="bold"
                            sx={programInfoStyle.wrapper.textStyle}
                          >
                            {t(key)} -{" "}
                          </StyledText>
                          {t(String(value))}
                        </Box>
                      ))}
                    </>
                  )}
                </Box>
              ))}
            </Box>
          ) : (
            <>
              {Object.entries(content).map(([key, value], i) => (
                <Box key={i}>
                  <strong>{key}:</strong> {String(value)}
                </Box>
              ))}
            </>
          )}
        </div>
      ))}
    </Box>
  );
};

export default ProgramInfo;
