/* eslint-disable */
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addressShema, initialValues } from "./addressSchema";
import { styles } from "./style";
import { RootState } from "../../../configureStore";
import { getAllAddresses, updateAddress } from "../../../actions";
import { StyledText } from "../../../utils/styledComponents";
import { CountryMobileData } from "../../../utils/constants";
import { ICountryData } from "../../../models/types.model";
import { iskeyInObject } from "../../../utils/hooks";
export interface addProductPropsType {
  editFields?: any;
  handleClose: () => void;
}

function EditAddress({ editFields, handleClose }: addProductPropsType) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [mobCode, setMobCode] = useState<string>("");

  const { updateAddressResponse } = useSelector(
    (state: RootState) => state?.entities?.accountAddress
  );

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addressShema,
    onSubmit: () => {
      const phone = values.phone.toString();
      const countryCode = mobCode.toString();

      if (
        phone !== "" &&
        (mobCode === t("Select country code") || mobCode === "")
      ) {
        setFieldError(
          "phone",
          t(
            "Phone number must be in the format '+<country code><10-digit number>'"
          )
        );
        return;
      }

      const mobile = phone !== "" ? countryCode + phone : "";
      setUpdateLoader(true);
      let payload:any = { ...values, phone: mobile };
      delete payload?.is_default

      dispatch(
        updateAddress.request({
          data: {
            id: editFields.id,
            payload: payload,
          },
        })
      );
    },
  });

  const addressFields = [
    {
      label: "First name",
      id: "first_name",
      name: "first_name",
      value: values.first_name ?? "",
      error: touched?.first_name && !!errors?.first_name,
      helperText: touched?.first_name && errors?.first_name,
      type: "text",
    },
    {
      label: "Last name",
      id: "last_name",
      name: "last_name",
      value: values.last_name ?? "",
      error: touched?.last_name && !!errors?.last_name,
      helperText: touched?.last_name && errors?.last_name,
      type: "text",
    },
    {
      label: "Address",
      id: "address1",
      name: "address1",
      value: values.address1 ?? "",
      error: touched?.address1 && !!errors?.address1,
      helperText: touched?.address1 && errors?.address1,
      type: "text",
    },
    {
      label: "City",
      id: "city",
      name: "city",
      value: values.city ?? "",
      error: touched?.city && !!errors?.city,
      helperText: touched?.city && errors?.city,
      type: "text",
    },
    {
      label: "Country/region",
      id: "country",
      name: "country",
      value: values.country ?? "",
      error: touched?.country && !!errors?.country,
      helperText: touched?.country && errors?.country,
      type: "selectField",
    },
    {
      label: "Postal/zip code",
      id: "zip",
      name: "zip",
      value: values.zip ?? "",
      error: touched?.zip && !!errors?.zip,
      helperText: touched?.zip && errors?.zip,
      type: "text",
    },
    {
      label: "Phone number",
      id: "phone",
      name: "phone",
      value: values.phone ?? "",
      error: touched?.phone && !!errors?.phone,
      helperText: touched?.phone && errors?.phone,
      type: "text",
    },
  ];

  useEffect(() => {
    const { first_name, last_name, address1, city, country, zip, phone } =
      editFields;

    setValues({
      ...values,
      first_name,
      last_name,
      address1,
      city,
      country,
      zip,
      phone: phone && phone?.length > 10 ? phone?.slice(-10) : phone ?? "",
    });
    if (phone && phone?.length > 10) {
      setMobCode(phone.slice(0, -10));
    } else {
      setMobCode("");
    }
  }, []);

  useEffect(() => {
    if (iskeyInObject(updateAddressResponse?.data,"success")) {
      const { success } = updateAddressResponse?.data;
      if (success === true) {
        dispatch(getAllAddresses.request({}));
        setUpdateLoader(false);
        handleClose();
      } else {
        setUpdateLoader(false);
        handleClose();
      }
      dispatch(updateAddress.reset());
    }
  }, [updateAddressResponse?.isLoading]);

  const renderMobCodeOptions = (optionArray: ICountryData[]) => {
    const options = optionArray.map((item) => ({
      countryCode: item.code,
      phoneCode: item.phoneNumberPrefix,
    }));
    return options;
  };

  const handleMobileCodeChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    if (e.target.value === t("Select country code")) {
      setMobCode("");
    } else {
      setMobCode(e.target.value);
    }
  };

  const renderOptions = (optionArray: ICountryData[]) => {
    const options = optionArray.map((item) => ({
      label: item.name,
      value: item.name,
    }));
    return options;
  };

  return (
    <Box sx={styles.updateAddressContain}>
      <Box sx={styles.addressBox}>
        {addressFields.map((item, index) => {
          switch (item.type) {
            case "selectField":
              return (
                <Stack key={index} sx={styles.fieldContain}>
                  <StyledText sx={{ ...styles.label, mb: "4px" }}>
                    {t(item.label)}
                    <span style={{ color: "#FF0000" }}>*</span>
                  </StyledText>
                  <Autocomplete
                    disablePortal
                    id={item.id}
                    options={renderOptions(CountryMobileData)}
                    defaultValue={{
                      label: editFields?.country ?? "",
                      value: "country",
                    }}
                    onChange={(event, newValue: any) =>
                      item.value && setFieldValue("country", newValue?.value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={styles.field}
                        id={item.id}
                        name={item.name}
                        onChange={handleChange}
                        value={item.value ?? ""}
                        error={item.error}
                        helperText={item.helperText}
                      />
                    )}
                  />
                </Stack>
              );
            default:
              return (
                <Stack key={index} sx={styles.fieldContain}>
                  <StyledText sx={{ ...styles.label, mb: "4px" }}>
                    {t(item.label)}
                    {index !== 6 && <span style={{ color: "#FF0000" }}>*</span>}
                  </StyledText>
                  <TextField
                    size="small"
                    type={item.type}
                    sx={styles.field}
                    id={item.id}
                    name={item.name}
                    onChange={handleChange}
                    value={item.value}
                    error={item.error}
                    helperText={item.helperText}
                    InputProps={
                      item.name === "phone"
                        ? {
                            startAdornment: (
                              <InputAdornment position="start">
                                <TextField
                                  size="small"
                                  select
                                  id={`${item.id}-country`}
                                  name={`${item.name}-country`}
                                  onChange={handleMobileCodeChange}
                                  value={
                                    mobCode === undefined || mobCode === ""
                                      ? t("Select country code")
                                      : mobCode
                                  }
                                  InputProps={{
                                    sx: {
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none",
                                      },
                                    },
                                  }}
                                >
                                  {renderMobCodeOptions(CountryMobileData).map(
                                    (code, index) =>
                                      code.countryCode === "" ? (
                                        <MenuItem
                                          key={index}
                                          value={t("Select country code")}
                                        >
                                          <StyledText
                                            fontSize="10px"
                                            lineheight="20px"
                                            opacityValue="0.7"
                                            textAlign="left"
                                          >
                                            {t("Select country code")}
                                          </StyledText>
                                        </MenuItem>
                                      ) : (
                                        <MenuItem
                                          key={index}
                                          value={code.phoneCode}
                                        >
                                          {code.phoneCode} ({code.countryCode})
                                        </MenuItem>
                                      )
                                  )}
                                </TextField>
                              </InputAdornment>
                            ),
                          }
                        : {}
                    }
                  />
                </Stack>
              );
          }
        })}
      </Box>
      <Button
        type="submit"
        onClick={() => handleSubmit()}
        sx={
          Object.keys(errors).length
            ? { ...styles.saveBtn, cursor: "not-allowed" }
            : styles.saveBtn
        }
        variant="contained"
      >
        {updateLoader ? (
          <CircularProgress size="16px" sx={styles.loaderStyle} />
        ) : (
          ""
        )}
        {t("Update")}
      </Button>
    </Box>
  );
}

export default EditAddress;
