import React from "react";

const QRIcon = ({ fill = "#D5D5D5", width = "50", height = "50" }) => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7840_63216)">
      <path
        d="M14.6515 17.9895C12.7327 17.9967 11.2087 16.5253 11.1954 14.6521C11.1821 12.8177 12.7317 11.239 14.5861 11.1981C16.4435 11.1562 17.9767 12.6807 17.991 14.5836C18.0064 16.5141 16.5662 17.9824 14.6515 17.9895ZM14.315 15.7319C14.6034 15.7646 14.7272 15.5938 14.8643 15.4609C15.1926 15.1429 15.5373 14.8351 15.8278 14.4844C16.0241 14.2472 16.5846 14.0683 16.1888 13.6429C15.8053 13.2308 15.5393 13.6961 15.3215 13.9302C14.7906 14.4977 14.2966 15.0171 13.6144 14.1132C13.4906 13.9496 13.2799 13.874 13.105 14.0519C13.0273 14.1306 12.9506 14.3249 12.9915 14.3862C13.3566 14.9302 13.824 15.3781 14.315 15.7329V15.7319Z"
        fill="black"
      />
      <path
        d="M2.0046 4.34621C2.0046 3.53772 2.00391 2.00195 2.00391 2.00195C2.00391 2.00195 3.5993 2.00368 4.35504 2.00368C6.85073 2.00368 6.85891 2.00368 6.84766 4.53026C6.83641 7.13353 7.13098 6.83394 4.52279 6.84621C3.43196 6.85108 2.0046 6.84621 2.0046 6.84621C2.0046 6.84621 2.0046 5.76443 2.0046 4.34621Z"
        fill="black"
      />
      <path
        d="M17.9987 6.84126H15.6053C12.8058 6.83921 13.1618 7.18175 13.1567 4.36069C13.1515 1.57439 12.8999 2.05291 15.5184 2.00383C15.5511 2.00383 17.9649 2 17.9987 2C17.9967 2.00383 17.9987 5.23321 17.9987 6.84126Z"
        fill="black"
      />
      <path
        d="M2.00256 15.5354C2.01013 15.1408 2.00624 13.4304 2.00288 13.1547C2.00288 13.1547 1.99163 13.155 4.43995 13.155C7.16986 13.155 6.84256 12.8278 6.84256 15.5824C6.84256 18.427 7.12792 17.9597 4.43483 17.9873C3.99756 17.9917 2.29418 18.0129 2 18.0006C2 18.0006 2.00256 17.5507 2.00256 15.5354Z"
        fill="black"
      />
      <path
        d="M6.45317 9.45543C6.15655 9.45543 5.86096 9.45543 5.56434 9.45543C3.69667 9.45543 3.6803 9.45441 3.5146 11.3031C3.46551 11.8481 3.24253 12.0515 2.70964 12.0454C2.20232 12.0393 2.00083 11.8153 2.00697 11.3246C2.01719 10.4687 2.02538 9.6129 2.00083 8.75809C1.98344 8.16402 2.23915 7.936 2.8242 7.94418C4.1733 7.96259 5.52547 7.91146 6.87253 7.96054C7.66624 7.98917 8.04366 7.76116 7.95774 6.91249C7.90558 6.39101 7.95979 5.86034 7.95263 5.33375C7.94547 4.84602 8.15822 4.61392 8.66042 4.60574C9.1974 4.59653 9.45413 4.82353 9.45209 5.36341C9.44697 6.4493 9.44288 7.53519 9.45618 8.62108C9.46334 9.20083 9.20865 9.46566 8.62565 9.45441C7.90149 9.44009 7.17733 9.45134 6.45317 9.45134C6.45317 9.45339 6.45317 9.45441 6.45317 9.45645V9.45543Z"
        fill="black"
      />
      <path
        d="M12.0432 4.53252C12.0432 5.06013 12.035 5.58774 12.0453 6.11432C12.0555 6.61944 11.7967 6.82803 11.317 6.83007C10.8526 6.83212 10.553 6.66136 10.5499 6.14704C10.5478 5.554 10.5151 4.9589 10.5448 4.3679C10.5775 3.71964 10.2952 3.47015 9.66514 3.50798C9.27136 3.5315 8.87348 3.50798 8.47969 3.48242C7.97442 3.44868 7.95805 3.07342 7.95703 2.69306C7.95703 2.32291 8.05113 2.02843 8.49299 2.02537C9.48206 2.01821 10.4711 2.01923 11.4602 2.02025C11.8785 2.02025 12.0381 2.2677 12.0391 2.6542C12.0391 3.28099 12.0391 3.90676 12.0391 4.53355C12.0401 4.53355 12.0422 4.53355 12.0432 4.53355V4.53252Z"
        fill="black"
      />
      <path
        d="M13.0081 7.95595C13.5687 7.95595 14.1292 7.96311 14.6897 7.9539C15.196 7.94572 15.3934 8.20033 15.3934 8.68499C15.3934 9.15943 15.2154 9.44163 14.7081 9.44572C14.1803 9.44879 13.6535 9.46617 13.1258 9.45493C12.5254 9.44163 11.9966 9.43959 12.017 10.2964C12.0293 10.7913 11.5803 10.7954 11.209 10.7872C10.8306 10.7791 10.5718 10.6093 10.5605 10.1993C10.5472 9.70544 10.5585 9.21055 10.5472 8.71669C10.536 8.17885 10.7866 7.9355 11.3266 7.94981C11.8861 7.96515 12.4476 7.95288 13.0081 7.95288C13.0081 7.9539 13.0081 7.95493 13.0081 7.95595Z"
        fill="black"
      />
      <path
        d="M7.95362 15.5952C7.9526 15.0369 7.95976 14.4786 7.94953 13.9203C7.94032 13.4428 8.1367 13.1811 8.63686 13.1668C9.15237 13.1524 9.45614 13.3447 9.45001 13.9009C9.44591 14.3283 9.45512 14.7547 9.45614 15.1821C9.45717 15.8324 9.30579 16.5471 10.3613 16.541C10.7766 16.5389 10.794 16.9684 10.7879 17.3048C10.7807 17.6688 10.658 17.9612 10.2335 17.9735C9.70776 17.9888 9.18203 17.9735 8.6563 17.9868C8.15511 18.0001 7.94032 17.7618 7.95055 17.27C7.9618 16.7117 7.95362 16.1535 7.95157 15.5952H7.95362Z"
        fill="black"
      />
      <path
        d="M7.05225 10.5426C7.54627 10.5426 8.03927 10.5466 8.53329 10.5405C9.0447 10.5333 9.44156 10.6233 9.45281 11.2747C9.46406 11.9045 9.13062 12.0569 8.58546 12.0487C7.53297 12.0333 6.47947 12.0364 5.42596 12.0466C4.92273 12.0518 4.61384 11.9055 4.60566 11.3268C4.59645 10.7031 4.92784 10.5293 5.47301 10.5395C5.99976 10.5497 6.52652 10.5415 7.05225 10.5415V10.5426Z"
        fill="black"
      />
      <path
        d="M18.0009 8.00046C18.0009 8.38492 17.9865 9.94636 17.9813 10.2091V10.7879H16.5163L16.5136 9.99748C16.4992 10 16.5 7.99893 16.5 7.99893C16.5 7.99893 16.9843 8.01701 17.1682 7.98828L18.0009 8.00046Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_7840_63216">
        <rect width="16" height="16" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

export default React.memo(QRIcon);
