import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DetailsVideo from "./videoDetail/DetailsVideo";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { deleteVideoCall } from "../../actions";
import LinkedProducts from "./LinkedProducts/LinkedProducts";
import ShareVideo from "./shareVideo/ShareVideo";
import PyvVideo from "../../shared/components/videoDetails/PyvVideo";
import AnalyticTabs from "./Analytics.tsx";
import { useTranslation } from "react-i18next";
import { StyledText } from "../../utils/styledComponents";
import { THIN_BORDER_COLOR } from "../../colors";
import { Stack } from "@mui/material";
import FloatDotIndecator from "../../shared/components/FloatDotIndecator";
import { videoTabsStyle } from "./style";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={videoTabsStyle.wrapper}>
          <StyledText>{children}</StyledText>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function VideiTabs() {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);
  const [isNavRefLeft, setNavLeft] = React.useState(true);
  const [scrollableNav, setScrollable] = React.useState<boolean>(true);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleScroll = () => {
    if (ref.current) {
      const scrollLeft = ref.current.scrollLeft;
      const scrollWidth = ref.current.scrollWidth;
      const containerWidth = ref.current.clientWidth;
      const isAtLeft = scrollLeft === 0;

      setNavLeft(isAtLeft);
    }
  };

  React.useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("scroll", handleScroll);
      const { scrollWidth, clientWidth } = ref.current;
      const isScrollable = scrollWidth > clientWidth;
      setScrollable(isScrollable);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("scroll", handleScroll);
      }
    };
  });

  return (
    <Box sx={videoTabsStyle.boxStyle}>
      <Box
        className="overflow-x-contain-tabscroll"
        ref={ref}
        sx={videoTabsStyle.boxItem}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={videoTabsStyle.parentTab}
        >
          <Tab
            label={t("Details")}
            {...a11yProps(0)}
            sx={videoTabsStyle.childTab}
          />
          <Tab
            label={t("Linked products")}
            {...a11yProps(1)}
            sx={videoTabsStyle.childTab}
          />
          <Tab
            label={t("PYV video")}
            {...a11yProps(2)}
            sx={videoTabsStyle.childTab}
          />
          <Tab
            label={t("Share")}
            {...a11yProps(3)}
            sx={videoTabsStyle.childTab}
          />
          <Tab
            label={t("Analytics")}
            {...a11yProps(4)}
            sx={videoTabsStyle.childTab}
          />
        </Tabs>
      </Box>
      {scrollableNav && (
        <Stack
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          padding="3px 0px"
        >
          <Stack
            width={"22px"}
            justifyContent={"space-between"}
            alignItems={"center"}
            direction={"row"}
          >
            <FloatDotIndecator checked={isNavRefLeft} />
            <FloatDotIndecator checked={!isNavRefLeft} />
          </Stack>
        </Stack>
      )}

      <CustomTabPanel value={value} index={0}>
        <DetailsVideo />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <LinkedProducts />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <PyvVideo />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <ShareVideo />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <AnalyticTabs />
      </CustomTabPanel>
    </Box>
  );
};

export default VideiTabs;
