import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";

const { SUCCESS, REQUEST, EDIT_VIDEO_AUTH } = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const editVideoAuthResponse = (state = intialState, action: actions) => {
    switch (action.type) {
      case EDIT_VIDEO_AUTH[SUCCESS]:
        return { ...state, data: action.payload, isLoading: false };
      case EDIT_VIDEO_AUTH[REQUEST]:
        return intialState;
      case EDIT_VIDEO_AUTH[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  return combineReducers({
    editVideoAuthResponse,
  });
};

export default entity;

export const editVideoAuth = (state: RootState) => state.entities.editVideoAuth;
