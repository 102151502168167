import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18next";
import ShareIcon from "@mui/icons-material/Share";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { RootState } from "../../../configureStore";
import {
  activatePauseVideo,
  deleteVideoCall,
  getAthenaAnalytics,
  getVideosAnalyticsCall,
  getVideosCall,
} from "../../../actions";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledActivePauseIndicator,
  StyledButton,
  StyledTableCell,
  StyledTableRow,
  StyledText,
} from "../../../utils/styledComponents";
import { IAPIData } from "../../../models/types.model";
import FloatSearch from "../../../shared/components/FloatSearch";
import FLoatDropDown from "../../../shared/components/FLoatDropDown";
import { CONSTANTS } from "../../../utils/constants";
import { stylesGlobal } from "../../../utils/muiStyles";
import FloatModal from "../../../shared/components/FloatModal";
import AddVideoModal from "../../videoDetails/AddVideoModal";
import CustomPagination from "../../../shared/components/CustomPagination";
import DeleteIcon from "../../../utils/icons/DeleteIcon";
import FloatPlayerModal from "../../../shared/components/FloatPlayerModal";
import FloatShareURL from "../../../shared/components/FloatShareURL";
import MultipleSelectDropdown from "../../../shared/components/Multiselect";
import FloatAlert from "../../../shared/components/FloatAlert";
import FloatMobileFilter from "../../../shared/components/FloatMobileFilter";
import VideosAnalyticsActionRow from "./VideosAnalyticsActionRow";
import { videoListStyles } from "./styles";
import { VideoeRowProps } from "../../../models/backoffice.models";
import {
  RotatableIcon,
  headCellHook,
  searchByList,
  searchByListInfl,
  videoDetailsList,
  videoDetailsListInfl,
} from "./helper,";
import { styles } from "../styles";
import VideosTableHeadMobile from "./VideosTableHeadMobile";
import VideosListUI from "./VideosListUI";
import { THIN_BORDER_COLOR } from "../../../colors";
import VideosProductVendorMobile from "./VideosProductVendorMobile";
import { removeEmptyValues } from "../../../utils/hooks";
declare global {
  interface Window {
    initTigCreatorEmbed: (options: any) => void;
  }
}

const PAGE_LIMIT = 10;

interface CreatorVideoProps {
  rowCount: number;
  multilistInfo: any;
}

function EditorVideos() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTab = useMediaQuery("(max-width:900px)");
  const { searchFilterStyle } = stylesGlobal;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentPath = window.location.pathname;

  const athenaAccess = localStorage.getItem("lastAthenaRefreshTime") || "";

  const { getVideosData } = useSelector(
    (state: RootState) => state?.entities?.videos
  );

  const { getVideosAnalyticsData }: any = useSelector(
    (state: RootState) => state?.entities?.videosAnalytics
  );

  const profileData = useSelector(
    (state: RootState) => state?.entities?.userProfile?.userData as IAPIData
  );

  const { deleteVideoData } = useSelector(
    (state: RootState) => state?.entities?.deleteVideo
  );

  const activatePauseData = useSelector(
    (state: RootState) =>
      state?.entities?.activatePauseVideo?.activePauseVideoData
  );

  const { athenaAnalyticsData }: any = useSelector(
    (state: RootState) => state?.entities?.athenaAnalytics
  );

  const userRole = profileData?.data && profileData?.data?.user?.role;
  const searchByFilterList =
    userRole === CONSTANTS.roles.INFLUENCER ? searchByListInfl : searchByList;

  const [tigModalController, setTigModalController] = useState<any>();
  const [videos, setVideos]: any[] = useState([]);
  const [analytics, setAnalytics]: any = useState([]);
  const [rows, setRowData]: any[] = useState([]);
  const [multilistInfo, setMultiList] = useState(
    userRole === CONSTANTS.roles.INFLUENCER
      ? videoDetailsListInfl?.map((item) => item.name)
      : videoDetailsList?.map((item) => item.name)
  );

  const [multiListSearchBy, setMultiListSearchBy] = useState(
    userRole === CONSTANTS.roles.INFLUENCER
      ? searchByListInfl?.map((item) => t(item.name))
      : searchByList?.map((item) => t(item.name))
  );
  const [openAddVideoModal, setOpenAddVideoModal] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>();
  const [currentData, setCurrentPage] = useState<number>(1);
  const [totalItem, setTotalItem] = useState<any>();
  const [player, setPlayer] = useState<any>({
    url: "",
    open: false,
    onClose: () => {},
  });
  const [activateContents, setActivateContents] = useState<number[]>();
  const [pauseContents, setPauseContents] = useState<number[]>();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [filters, setFilters] = React.useState({
    searchKey: "title",
    sortKey: "content_id",
    sortOrder: "desc",
    searchValue: "",
    page: currentData,
    limit: PAGE_LIMIT,
  });
  const [openShareVideoModal, setOpenShareVideoModal] = useState(false);
  const [videoData, setVideoData]: any = useState({});
  const [openAlert, setAlert] = useState(false);
  const [deleteId, setDeleteID] = useState<number | null>(null);
  const [collapseRowid, setCollapseId] = useState({ id: 0, open: "" });
  const [isExpanded, setIsExpanded] = useState(false);
  const [showRefreshButton, setShowRefreshButton] = useState(false);

  const headCells = headCellHook(userRole);

  useEffect(() => {
    dispatch(getVideosCall.request(removeEmptyValues({ ...filters, page: currentData })));
    return () => {
      setVideos([]);
      dispatch(getVideosCall.reset());
    };
  }, []);

  useEffect(() => {
    setMultiList(
      userRole === CONSTANTS.roles.INFLUENCER
        ? videoDetailsListInfl?.map((item) => t(item.name))
        : videoDetailsList?.map((item) => t(item.name))
    );
  }, [i18n.language]);

  useEffect(() => {
    if (!getVideosData.isLoading && !getVideosData.isError) {
      if (getVideosData?.data?.data?.data?.length > 0) {
        const contentIds = getVideosData?.data?.data?.data?.map(
          (item: { content_id: number }) => item.content_id
        );
        dispatch(getVideosAnalyticsCall.reset());
        dispatch(getVideosAnalyticsCall.request({ content_ids: contentIds }));
      }
    }
  }, [getVideosData.isLoading]);

  useEffect(() => {
    if (getVideosData?.data?.data) {
      setVideos(getVideosData?.data?.data?.data);
    }
    if (getVideosData?.data?.data) {
      setTotalPages(getVideosData?.data?.data?.total_pages ?? 1);
      setCurrentPage(getVideosData?.data?.data?.current_page ?? 1);
      setTotalItem(getVideosData?.data?.data?.total_count ?? 1);
    }
  }, [getVideosData?.data?.data, currentData]);

  useEffect(() => {
    if (!getVideosAnalyticsData?.isLoading) {
      setAnalytics(getVideosAnalyticsData?.data);
    }
  }, [getVideosAnalyticsData?.isLoading]);

  const handlePageChange = (event: any, pageNumber: number) => {
    setCurrentPage(pageNumber);
    dispatch(
      getVideosCall.request(removeEmptyValues({ ...filters, page: pageNumber }))
    );
  };

  useEffect(() => {
    let combine: any = [];
    let rows: any = [];

    if (videos?.length || analytics?.length) {
      videos?.map((item: any) => {
        combine.push({
          ...item,
          ...analytics?.find((stat: any) => stat.content_id == item.content_id),
        });
      });
    }
    combine?.map((item: any) => {
      let row = {
        id: item.content_id,
        thumbnail: item.ogp_thumbnail_url,
        title: item.title,
        description: item.description,
        collections: item.collections,
        creator_handle: item.creator_handle,
        hidden: item.hidden,
        playlist_url: item.video_url,
        webUrl: item.web_player_url,
        identifier: item?.content_identifier ?? "",
        item_details: item?.item_details ?? [],
        views: getVideosData?.isLoading
          ? "..."
          : videos?.length && Object.keys(item?.views_data).length > 0
          ? item?.views_data[0]?.total_views
          : analytics.length
          ? item.total_views
          : "-",
        unique: getVideosData?.isLoading
          ? "..."
          : videos?.length && Object.keys(item?.views_data).length > 0
          ? item?.views_data[0]?.total_unique_views
          : analytics?.length
          ? item.total_unique_views
          : "-",
        clicks: getVideosAnalyticsData?.isLoading
          ? "..."
          : analytics?.length
          ? item.product_clicks ?? 0
            : "-",
        public: item.public ?? false
      };
      rows.push(row);
    });
    setRowData(rows);
  }, [videos, analytics]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (debouncedSearchTerm?.length > 0) {
        setFilters({ ...filters, searchValue: debouncedSearchTerm });
        dispatch(
          getVideosCall.request(
            removeEmptyValues({
              ...{ ...filters, searchValue: debouncedSearchTerm },
              page: 1,
            })
          )
        );
      }
    }, 1000);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [debouncedSearchTerm]);

  useEffect(() => {
    let searchBykeys = searchByFilterList
      ?.filter((item: any) => multiListSearchBy.includes(item.name))
      ?.map((data) => data.key);
    setFilters((prevFilters) => ({
      ...prevFilters,
      searchKey: searchBykeys.join(","),
    }));
    if (filters.searchValue !== "") {
      dispatch(
        getVideosCall.request(
          removeEmptyValues({
            ...{ ...filters, searchKey: searchBykeys.join(",") },
            page: 1,
          })
        )
      );
    }
  }, [multiListSearchBy]);

  useEffect(() => {
    if (!activatePauseData?.isLoading) {
      setActivateContents([]);
      setPauseContents([]);
    }

    if (!activatePauseData?.isLoading && activatePauseData?.isError) {
      dispatch(getVideosCall.reset());
    }
  }, [activatePauseData?.isLoading]);

  useEffect(() => {
    if (!deleteVideoData?.isLoading && !deleteVideoData?.isError) {
      dispatch(
        getVideosCall.request(
          removeEmptyValues({ ...filters, page: currentData })
        )
      );
      dispatch(deleteVideoCall.reset());
    }
  }, [deleteVideoData?.isLoading]);

  const closeVideoModal = () => {
    setOpenAddVideoModal(false);
  };

  const onVideoDelete = () => {
    dispatch(deleteVideoCall.request({ data: { content_id: deleteId } }));
  };

  const commonFilterChange = (e: any) => {
    let name = e.target.name;
    let data = {
      sortKey: name === "sortKey" ? e.target.value : filters.sortKey,
      sortOrder: name === "sortOrder" ? e.target.value : filters.sortOrder,
      searchValue:
        name === "searchValue" ? e.target.value : filters.searchValue,
    };
    setFilters({ ...filters, ...data });
    dispatch(
      getVideosCall.request(
        removeEmptyValues({ ...{ ...filters, ...data }, page: 1 })
      )
    );
  };

  const seachByChange = (e: any) => {
    e.preventDefault();
    if (e.target.value?.length === 0) {
      setFilters({ ...filters, searchValue: "" });
      dispatch(
        getVideosCall.request(
          removeEmptyValues({
            ...{ ...filters, searchValue: "" },
            page: 1,
          })
        )
      );
    }
    setDebouncedSearchTerm(e.target.value);
  };

  const getSortFilterList = React.useCallback(
    (userRole: string) => {
      const isAnalytics = athenaAccess?.length;
      switch (userRole) {
        case CONSTANTS.roles.INFLUENCER:
          return isAnalytics
            ? CONSTANTS.videosAnalyticsFilterInfl
            : CONSTANTS.InfluencerVideoSortByFiltersInfl;
        default:
          return isAnalytics
            ? CONSTANTS.videosAnalyticsFilter
            : CONSTANTS.InfluencerVideoSortByFilters;
      }
    },
    [athenaAccess]
  );

  const SelectFilters = [
    {
      list: getSortFilterList(CONSTANTS.roles.INFLUENCER),
      label: "Sort by",
      isAthenaFilter: !athenaAccess?.length && !athenaAnalyticsData.isError,
      name: "sortKey",
      onChange: commonFilterChange,
      value: filters.sortKey,
    },
    {
      list: CONSTANTS.Order,
      label: "Order",
      name: "sortOrder",
      onChange: commonFilterChange,
      value: filters.sortOrder,
      isAthenaFilter: false,
    },
  ];

  const handleActivate = (event: any, id: number) => {
    event.preventDefault();
    event.stopPropagation();

    setRowData((prev: any[]) => {
      return prev.map((item: any) => {
        if (item.id === id) {
          return {
            ...item,
            hidden: false,
          };
        }
        return item;
      });
    });
    activateContents?.push(id);

    dispatch(
      activatePauseVideo.request({
        content_id: id,
        status: "active",
      })
    );
  };

  const handleActivateProgress = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handlePause = (event: any, id: number) => {
    event.preventDefault();
    event.stopPropagation();
    pauseContents?.push(id);

    setRowData((prev: any[]) => {
      return prev.map((item: any) => {
        if (item.id === id) {
          return {
            ...item,
            hidden: true,
          };
        }
        return item;
      });
    });

    dispatch(
      activatePauseVideo.request({
        content_id: id,
        status: "pause",
      })
    );
  };

  const handlePauseProgress = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDelete = (e: any, id: any) => {
    e.preventDefault();
    e.stopPropagation();
    setAlert(true);
    setDeleteID(id);
  };

  const convertToPlainText = (html: string) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const handleApiCall = () => {
    localStorage.removeItem("lastAthenaRefreshTime");
    dispatch(getAthenaAnalytics.request({}));
    const currentTime = Date.now();
    setShowRefreshButton(false);
  };

  useEffect(() => {
    const lastRefreshTime: any = localStorage.getItem("lastAthenaRefreshTime");
    const oneHourInSeconds = 60 * 60;
    const t = new Date();
    const currentTime = t.getTime();
    const elapsedTime = Math.floor(
      (currentTime - parseInt(lastRefreshTime, 10)) / 1000
    );
    if (elapsedTime >= oneHourInSeconds) {
      setShowRefreshButton(true);
    }
  }, []);

  useEffect(() => {
    function processTigCreatorCustomEmbedFunc() {
      if (rows?.length > 0) {
        window.initTigCreatorEmbed({
          type: "custom",
          customContentsList: rows,
          customReadyCallback: function (controller: any) {
            setTigModalController(controller);
          },
        });
      }
    }

    if (
      document.readyState === "interactive" ||
      document.readyState === "complete"
    ) {
      processTigCreatorCustomEmbedFunc();
    } else {
      window.addEventListener("DOMContentLoaded", function () {
        processTigCreatorCustomEmbedFunc();
      });
    }
  }, [rows]);

  const openPlayerModal: any = (contentIdentifier: any) => {
    tigModalController?.open(contentIdentifier);
  };

  const onPreview: any = (
    event: React.ChangeEvent<HTMLButtonElement>,
    video: any
  ) => {
    event.preventDefault();
    event.stopPropagation();
    openPlayerModal(video?.identifier);
  };

  const multiselectList = [
    {
      list:
        userRole === CONSTANTS.roles.INFLUENCER
          ? videoDetailsListInfl
          : videoDetailsList,
      label: "Column visibility",
      name: "colVisibility",
      isColumnVisibilty: true,
    },
    {
      list:
        userRole === CONSTANTS.roles.INFLUENCER
          ? searchByListInfl
          : searchByList,
      label: "Search by",
      name: "searchKey",
      isColumnVisibilty: false,
    },
  ];

  const setColumnsMobile = React.useCallback((value: any) => {
    setMultiList(value);
  }, []);

  const onSubmitMobile = (filters: any) => {
    dispatch(getVideosCall.request(removeEmptyValues({ ...filters, page: 1 })));
  };

  const RowActions = (row: any) => {
    return (
      <Stack alignItems={"end"}>
        <Box sx={videoListStyles.actionOuter}>
          <Box sx={styles.tableContainer.buttonBox}>
            {userRole !== CONSTANTS.roles.CUSTOMER && (
              <Box
                sx={{
                  minWidth: "150px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleActivateProgress}
              >
                <StyledActivePauseIndicator status={row.public} />
                <StyledButton
                  onClick={(event) => {
                    if (activateContents?.includes(row.id)) {
                      handleActivateProgress(event);
                    } else {
                      handleActivate(event, row.id);
                    }
                  }}
                  disabled={rows && !row.hidden}
                  sx={videoListStyles.activateBtn}
                >
                  {t("Activate")}
                </StyledButton>
                <StyledButton
                  onClick={(event) => {
                    if (pauseContents?.includes(row.id)) {
                      handlePauseProgress(event);
                    } else {
                      handlePause(event, row.id);
                    }
                  }}
                  disabled={rows && row.hidden}
                  sx={videoListStyles.pauseBtn}
                >
                  {t("Pause")}
                </StyledButton>
              </Box>
            )}
            <Box>
              <StyledButton
                onClick={() => {
                  navigate(`${currentPath}/${row.id}`);
                }}
                sx={videoListStyles.editBtn}
              >
                {t("Edit")}
              </StyledButton>
            </Box>
          </Box>

          <Tooltip title={t("Delete")}>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={(e) => onDelete(e, row.id)}
            >
              <DeleteIcon />
            </Box>
          </Tooltip>
          <Tooltip title={t("Share")}>
            <ShareIcon
              sx={{ cursor: "pointer" }}
              fontSize="small"
              onClick={() => {
                setOpenShareVideoModal(true);
                setVideoData(row);
              }}
            />
          </Tooltip>
        </Box>
      </Stack>
    );
  };

  return (
    <Box sx={styles.boxStyle}>
      <Box
        sx={
          isTab
            ? { ...searchFilterStyle.outer, alignItems: "center" }
            : searchFilterStyle.outer
        }
      >
        <Box sx={searchFilterStyle.searchOuter}>
          <FloatSearch handleSearch={seachByChange} />
        </Box>
        {isTab ? (
          <FloatMobileFilter
            setColumnsMobile={(values: any) => setColumnsMobile(values)}
            filters={filters}
            setFilters={setFilters}
            multiSelect={multiselectList}
            selectFilters={SelectFilters}
            onSubmitMobile={onSubmitMobile}
          />
        ) : (
          <Box sx={searchFilterStyle.filterOuter}>
            <MultipleSelectDropdown
              names={
                userRole === CONSTANTS.roles.INFLUENCER
                  ? searchByListInfl?.map((item) => item.name)
                  : searchByList?.map((item) => item.name)
              }
              setUpdatedList={setMultiListSearchBy}
              label="Search by"
            />

            {SelectFilters?.map((item, index) => (
              <FLoatDropDown
                key={index}
                list={item.list}
                label={item.label}
                isAthenaFilter={item.isAthenaFilter}
                onChange={item.onChange}
                value={item.value}
                name={item.name}
              />
            ))}

            <MultipleSelectDropdown
              names={
                userRole === CONSTANTS.roles.INFLUENCER
                  ? videoDetailsListInfl?.map((item) => item.name)
                  : videoDetailsList?.map((item) => item.name)
              }
              setUpdatedList={setMultiList}
              label="Column visibility"
            />
          </Box>
        )}
      </Box>
      {showRefreshButton && !athenaAnalyticsData?.isLoading && (
        <Paper sx={{ padding: "5px", mb: "10px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledText>
              {t("Click refresh to update the most recent video list and statistics")}
            </StyledText>
            <Button
              variant="contained"
              sx={{
                ...stylesGlobal.containedBtn,
                width: "100px",
                mt: "0px",
                border: `1px solid ${THIN_BORDER_COLOR}`,
                fontWeight: "500",
                padding: "6px 10px !important",
              }}
              onClick={handleApiCall}
            >
              {t("Refresh")}
            </Button>
          </Box>
        </Paper>
      )}
      <Box sx={styles.buttonBox}>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setOpenAddVideoModal(true)}
          sx={{
            textTransform: "none",
            marginBottom: "15px",
            padding: "5px 30px",
          }}
        >
          + {t("Add video")}
        </Button>
      </Box>

      {getVideosData?.isLoading ? (
        <Box sx={styles.loaderBox}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!isMobile ? (
            <VideosListUI
              rows={rows}
              multilistInfo={multilistInfo}
              onPreview={onPreview}
              videos={videos}
              RowActions={RowActions}
              userRole={userRole}
              isMobile={isMobile}
            />
          ) : (
            <Paper sx={styles.paperStyle}>
              {rows?.length > 0 ? (
                <TableContainer className="overflow-x-contain">
                  <Table
                    stickyHeader
                    className="overflow-y-tbl"
                    sx={styles.tableContainer.table}
                    aria-labelledby="tableTitle"
                  >
                    <VideosTableHeadMobile
                      rowCount={rows.length}
                      multilistInfo={multilistInfo}
                    />
                    <TableBody>
                      {rows?.length > 0
                        ? rows?.map((row: VideoeRowProps, index: number) => {
                            return (
                              <>
                                <StyledAccordion
                                  sx={{
                                    padding: "0px !important",
                                    margin: "0 !important",
                                    boxShadow: "none",
                                  }}
                                >
                                  <StyledAccordionSummary
                                    sx={videoListStyles.accordianSummary}
                                    onClick={(e) => {
                                      setIsExpanded(!isExpanded);
                                      setCollapseId({
                                        id: row.id,
                                        open:
                                          e.currentTarget.ariaExpanded ||
                                          "false",
                                      });
                                      e.stopPropagation();
                                    }}
                                  >
                                    <StyledTableRow
                                      key={index}
                                      role="checkbox"
                                      sx={{
                                        ...styles.rowStyle,
                                        padding: "0px 4px",
                                      }}
                                    >
                                      <StyledTableCell
                                        colSpan={1}
                                        sx={{ padding: "8px 4px !important" }}
                                        align="center"
                                      >
                                        <Box
                                          sx={
                                            videoListStyles.mobileThumbnailbox
                                          }
                                        >
                                          <img
                                            src={row.thumbnail}
                                            style={{
                                              height: "52px",
                                              width: "auto",
                                              maxWidth: "74px",
                                              cursor: "pointer",
                                            }}
                                            alt=""
                                            onClick={(e: any) =>
                                              onPreview(e, row)
                                            }
                                          />
                                        </Box>
                                      </StyledTableCell>
                                      {multilistInfo
                                        .map((item) => t(item))
                                        ?.includes(t("Title")) && (
                                        <StyledTableCell
                                          colSpan={1}
                                          align="left"
                                          sx={videoListStyles.mobileTitleCell}
                                        >
                                          <StyledText
                                            sx={{
                                              ...styles.tableContainer.titleBox,
                                              width: {
                                                xs: "100%",
                                                md: "100px",
                                                lg: "100px",
                                              },
                                            }}
                                          >
                                            {row.title ?? "-"}
                                          </StyledText>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              mb: "5px",
                                            }}
                                          >
                                            <StyledActivePauseIndicator
                                              status={row.public}
                                              dimension="15px"
                                            />
                                            <Stack
                                              display={"filex"}
                                              minWidth={"150px"}
                                            >
                                              <StyledButton
                                                onClick={(event) => {
                                                  if (
                                                    activateContents?.includes(
                                                      row.id
                                                    )
                                                  ) {
                                                    handleActivateProgress(
                                                      event
                                                    );
                                                  } else {
                                                    handleActivate(
                                                      event,
                                                      row.id
                                                    );
                                                  }
                                                }}
                                                disabled={rows && !row.hidden}
                                                sx={
                                                  videoListStyles.mobileActivateBtn
                                                }
                                              >
                                                {t("Activate")}
                                              </StyledButton>
                                              <StyledButton
                                                onClick={(event: any) => {
                                                  if (
                                                    pauseContents?.includes(
                                                      row.id
                                                    )
                                                  ) {
                                                    handlePauseProgress(event);
                                                  } else {
                                                    handlePause(event, row.id);
                                                  }
                                                }}
                                                disabled={rows && row.hidden}
                                                sx={
                                                  videoListStyles.mobilePauseBtn
                                                }
                                              >
                                                {t("Pause")}
                                              </StyledButton>
                                            </Stack>
                                            <IconButton
                                              sx={{
                                                border: "1px solid #000",
                                                borderRadius: "5px",
                                                ml: "10px",
                                                p: "2px",
                                              }}
                                            >
                                              <RotatableIcon
                                                rotate={isExpanded ? 1 : 0}
                                              />
                                            </IconButton>
                                          </Box>
                                          <Box
                                            sx={{
                                              width: "190px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              float: "right",
                                            }}
                                          >
                                            <StyledButton
                                              onClick={() => {
                                                navigate(
                                                  `${currentPath}/${row.id}`
                                                );
                                              }}
                                              sx={{
                                                textTransform: "capitalize",
                                                width: "75px",
                                                minWidth: "55px !important",
                                                mr: "10px",
                                              }}
                                            >
                                              {t("Edit")}
                                            </StyledButton>
                                            <Tooltip title={t("Delete")}>
                                              <Box
                                                onClick={(e) =>
                                                  onDelete(e, row.id)
                                                }
                                                sx={{
                                                  mr: "10px",
                                                  display: "flex",
                                                }}
                                              >
                                                <DeleteIcon />
                                              </Box>
                                            </Tooltip>
                                            <Tooltip title={t("Share")}>
                                              <ShareIcon
                                                onClick={() => {
                                                  setOpenShareVideoModal(true);
                                                  setVideoData(row);
                                                }}
                                              />
                                            </Tooltip>
                                          </Box>
                                        </StyledTableCell>
                                      )}
                                    </StyledTableRow>
                                  </StyledAccordionSummary>
                                  <StyledAccordionDetails>
                                    {multilistInfo
                                      .map((item) => t(item))
                                      ?.includes(t("Creator handle")) &&
                                      userRole ===
                                        CONSTANTS.roles.INFLUENCER && (
                                        <Grid
                                          item
                                          xs={12}
                                          lg={2.4}
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <StyledText fontWeight="600">
                                            {t("Creator handle")}:
                                          </StyledText>
                                          <StyledTableCell align="left">
                                            {row.creator_handle ?? "-"}
                                          </StyledTableCell>
                                        </Grid>
                                      )}
                                    {multilistInfo
                                      .map((item) => t(item))
                                      ?.includes(t("Description")) && (
                                      <Grid
                                        item
                                        xs={12}
                                        lg={2.4}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <StyledText fontWeight="600">
                                          {t("Description")}:
                                        </StyledText>
                                        <StyledTableCell align="left">
                                          {row.description
                                            ? convertToPlainText(
                                                row.description
                                              )
                                            : "-"}
                                        </StyledTableCell>
                                      </Grid>
                                    )}
                                    {multilistInfo
                                      .map((item) => t(item))
                                      ?.includes(t("Collection")) && (
                                      <>
                                        {row.collections?.length > 0 ? (
                                          <Grid
                                            item
                                            xs={12}
                                            lg={2.4}
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <StyledText fontWeight="600">
                                              {t("Collection")}:
                                            </StyledText>
                                            <StyledTableCell
                                              align="left"
                                              sx={{ whiteSpace: "nowrap" }}
                                            >
                                              {row.collections.join(", ")}
                                            </StyledTableCell>
                                          </Grid>
                                        ) : (
                                          <Grid
                                            item
                                            xs={12}
                                            lg={2.4}
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <StyledText fontWeight="600">
                                              {t("Collection")}:
                                            </StyledText>
                                            <StyledTableCell align="left">
                                              -
                                            </StyledTableCell>
                                          </Grid>
                                        )}
                                      </>
                                    )}

                                    {multilistInfo
                                      .map((item) => t(item))
                                      ?.includes(t("Content id")) && (
                                      <Grid
                                        item
                                        xs={12}
                                        lg={2.4}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <StyledText fontWeight="600">
                                          {t("Content id")}:
                                        </StyledText>
                                        <StyledTableCell align="left">
                                          {row.id}
                                        </StyledTableCell>
                                      </Grid>
                                    )}
                                    <VideosProductVendorMobile row={row} />
                                  </StyledAccordionDetails>
                                </StyledAccordion>
                                <VideosAnalyticsActionRow
                                  row={row}
                                  videos={videos}
                                  actions={RowActions}
                                  mobile={isMobile}
                                  accordian={collapseRowid}
                                />
                              </>
                            );
                          })
                        : videos?.length === 0 &&
                          !getVideosData?.isLoading && (
                            <StyledText>{t("No records found.")}</StyledText>
                          )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                videos?.length === 0 &&
                !getVideosData?.isLoading && (
                  <Box sx={styles.noRecordsBox}>
                    <StyledText>{t("No records found.")}</StyledText>
                  </Box>
                )
              )}
            </Paper>
          )}
        </>
      )}
      <FloatModal
        modalTitle="Add video"
        onClose={closeVideoModal}
        modalBody={
          <AddVideoModal
            handleClose={closeVideoModal}
            filters={filters}
            currentData={currentData}
          />
        }
        open={openAddVideoModal}
      />

      <FloatModal
        modalTitle="Share on social media"
        onClose={() => setOpenShareVideoModal(false)}
        modalBody={
          <FloatShareURL
            handleClose={() => setOpenShareVideoModal(false)}
            videoInfo={videoData}
          />
        }
        open={openShareVideoModal}
      />
      <FloatPlayerModal
        open={player.open}
        onClose={() => setPlayer({ ...player, open: false })}
        url={player.url}
      />
      {rows.length > 0 && totalItem && totalPages && currentData && (
        <CustomPagination
          page={currentData}
          count={totalPages}
          handlePageChange={handlePageChange}
        />
      )}
      <FloatAlert
        open={openAlert}
        onClose={() => {
          setAlert(false);
          setDeleteID(null);
        }}
        alert={t("Are you sure you want to delete this video?")}
        action={() => {
          onVideoDelete();
          setAlert(false);
        }}
      />
    </Box>
  );
}

export default EditorVideos;
