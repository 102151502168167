import React from "react";

const QuestionIcon = ({
  fill = "#D5D5D5",
  width = "1.253rem",
  height = "0.933rem",
}) => (
  <svg
    width="74"
    height="74"
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="37" cy="37" r="37" fill="#262626" />
    <path
      d="M39.5422 57.1859C38.6661 58.062 37.5937 58.5 36.325 58.5C35.0562 58.5 33.9839 58.062 33.1078 57.1859C32.2318 56.3099 31.7937 55.2375 31.7937 53.9688C31.7937 52.7 32.2318 51.6276 33.1078 50.7516C33.9839 49.8755 35.0562 49.4375 36.325 49.4375C37.5937 49.4375 38.6661 49.8755 39.5422 50.7516C40.4182 51.6276 40.8562 52.7 40.8562 53.9688C40.8562 55.2375 40.4182 56.3099 39.5422 57.1859Z"
      fill="white"
    />
    <path
      d="M39.7687 44.5438H33.0625C33.0625 41.3417 33.3646 39.1365 33.9688 37.9281C34.5729 36.7198 36.2042 34.9375 38.8625 32.5813C39.95 31.675 40.8562 30.6932 41.5812 29.6359C42.3062 28.5786 42.6687 27.4458 42.6687 26.2375C42.6687 24.9687 42.1854 23.8057 41.2188 22.7484C40.2521 21.6911 38.8021 21.1625 36.8688 21.1625C34.6938 21.1625 33.0776 21.7969 32.0203 23.0656C30.963 24.3344 30.2833 25.5125 29.9812 26.6L24 24.2438C24.8458 21.7063 26.3411 19.5312 28.4859 17.7188C30.6307 15.9062 33.425 15 36.8688 15C40.2521 15 43.1823 15.9062 45.6594 17.7188C48.1365 19.5312 49.375 22.1292 49.375 25.5125C49.375 27.5063 48.9219 29.213 48.0156 30.6328C47.1094 32.0526 45.8708 33.5479 44.3 35.1188C42.1854 37.1125 40.9016 38.6833 40.4484 39.8313C39.9953 40.9792 39.7687 42.55 39.7687 44.5438Z"
      fill="white"
    />
  </svg>
);

export default QuestionIcon;
