import React from 'react'

const ExpandSVG = () => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_10041_208472" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                    <rect width="16" height="16" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_10041_208472)">
                    <path d="M2.66146 14.8381C2.29479 14.8381 1.9809 14.7069 1.71979 14.4446C1.45868 14.1824 1.32812 13.8671 1.32812 13.4987V6.13232C1.32812 5.764 1.45868 5.44869 1.71979 5.1864C1.9809 4.92411 2.29479 4.79297 2.66146 4.79297H5.99479V6.13232H2.66146V13.4987H10.6615V10.4852H11.9948V13.4987C11.9948 13.8671 11.8642 14.1824 11.6031 14.4446C11.342 14.7069 11.0281 14.8381 10.6615 14.8381H2.66146Z" fill="#427EDC" />
                    <path d="M14.3604 8.49964L12.9312 8.47445L12.9312 4.89799L6.83838 11.0183L5.83545 10.0108L11.9282 3.89054L8.36785 3.89054L8.34277 2.45492L14.3604 2.45492L14.3604 8.49964Z" fill="#427EDC" />
                </g>
            </svg>

        </>
    )
}

export default ExpandSVG