import axios from "axios";
import { isLoggedIn } from "../auth/Auth";

const api: any = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  //baseURL: "http://localhost:8000/",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    // "credentials": 'include',
    Authorization: `Bearer ${isLoggedIn()}`,
  },
});

const apiRefresh: any = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  //baseURL: "http://localhost:8000/",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    // "credentials": 'include',
  },
});

export const addAuthToken = () => {
  api.defaults.headers["token"] = isLoggedIn();
  api.defaults.headers["Authorization"] = `Bearer ${isLoggedIn()}`;
};

export const GET = async (url: string, params = {}) => {
  const response = await api.get(url, { params });
  return response;
};

export const POST = async (url: string, data: object) => {
  const response = await api.post(url, data);
  return response;
};

export const PUT = async (url: string, data: object) => {
  const response = await api.put(url, data);
  return response;
};

export const DELETE = async (url: string) => {
  const response = await api.delete(url);
  return response;
};

export const PATCH = async (url: string, data: object) => {
  const response = await api.patch(url, data);
  return response;
};

export const GET_WITH_CREDS = async (url: string, params = {}) => {
  const response = await apiRefresh.get(url, {
    params,
  });
  return response;
};

export const POST_FILE_DATA = async (url: string, data: object) => {
  const response = await api.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};
