import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE, RESET } from "../../actions/actiontypes";
import { toast } from "react-toastify";
import { RootState } from "../../configureStore";
import { t } from "i18next";

const { SUCCESS, REQUEST, ADD_PYVIT_PRODUCT } = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const addPyvitProductUrl = (state = intialState, action: actions) => {
    switch (action.type) {
      case ADD_PYVIT_PRODUCT[REQUEST]:
        return { ...intialState, isLoading: true };
      case ADD_PYVIT_PRODUCT[SUCCESS]:
        toast.success(t("Product Added Successfully"));
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: false,
        };
      case ADD_PYVIT_PRODUCT[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case ADD_PYVIT_PRODUCT[RESET]:
        return intialState;
      default:
        return state;
    }
  };

  return combineReducers({
    addPyvitProductUrl,
  });
};

export default entity;

export const addPyvitProductUrl = (state: RootState) =>
  state.entities.addPyvitProductUrl;
