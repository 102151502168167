import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import React from "react";
import { Styles } from "./style";
import { useTranslation } from "react-i18next";
import { StyledText } from "../../utils/styledComponents";
import EventIcon from "@mui/icons-material/Event";
import BouncingDotsLoader from "./FloatBounceLoader";
import { iskeyInObject } from "../../utils/hooks";
interface DropDownProps {
  value: string;
  onChange: (e: any) => void;
  list: ListProps[];
  label: string;
  name?: string;
  disabled?: boolean;
  rowIcon?: any;
  iconKey?: string;
  isAthenaFilter?: boolean;
  iconClick?: (e: any) => void;
}

interface ListProps {
  name: string;
  key?: string;
}

const FLoatDropDown = ({
  value,
  onChange,
  list,
  label,
  name,
  disabled,
  iconClick,
  isAthenaFilter,
}: DropDownProps) => {
  const { t } = useTranslation();

  return (
    <FormControl sx={Styles.floatSelectFormcontrol} size="small">
      <InputLabel id="demo-simple-select-label" sx={{ ...Styles.inputLabel }}>
        <StyledText sx={Styles.inputLabelText}>{t(label)}</StyledText>
      </InputLabel>
      <Select
        disabled={disabled}
        label="Unit"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name={name}
        sx={Styles.floatSelect}
        value={value}
        MenuProps={{ ...Styles.menuProps, disablePortal: true }}
        onChange={onChange}
      >
        {list?.map((unit: ListProps, index: number) => {
          return (
            <MenuItem
              sx={Styles.menuItem}
              key={index}
              value={iskeyInObject(unit,"key") ? unit.key : unit.name}
            >
              {unit.key === "dateRange" ? (
                <Box onClick={iconClick} sx={Styles.selectListItem}>
                  <StyledText overflow="hidden" sx={Styles.selectListItemText}>
                    {t(unit?.name)}
                  </StyledText>
                  <EventIcon htmlColor="#bfbfbf" />
                </Box>
              ) : (
                <StyledText overflow="hidden" sx={Styles.selectListItemText}>
                  {t(unit?.name)}
                </StyledText>
              )}
            </MenuItem>
          );
        })}
        {isAthenaFilter && (
          <Stack sx={Styles.listLoadingOuter}>
            <Box sx={Styles.listLoaderBox}>
              <BouncingDotsLoader />
            </Box>
          </Stack>
        )}
      </Select>
    </FormControl>
  );
};

export default FLoatDropDown;
