import React from "react";

const WhatsappIcon = ({ fill = "#D5D5D5", width = "50", height = "50" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0016 3.60001C10.0492 3.60001 3.60156 10.0476 3.60156 18C3.60156 20.7015 4.36037 23.2201 5.65469 25.3805L3.73047 32.4L10.9 30.518C12.9968 31.7101 15.4173 32.4 18.0016 32.4C25.954 32.4 32.4016 25.9524 32.4016 18C32.4016 10.0476 25.954 3.60001 18.0016 3.60001ZM13.0727 11.2828C13.3067 11.2828 13.5471 11.2814 13.7547 11.2922C14.0115 11.2982 14.291 11.317 14.5586 11.9086C14.8766 12.6118 15.569 14.3759 15.6578 14.5547C15.7466 14.7335 15.8095 14.9441 15.6859 15.1781C15.5683 15.4181 15.5072 15.5634 15.3344 15.7758C15.1556 15.9822 14.9597 16.2385 14.7977 16.3945C14.6189 16.5733 14.4342 16.7694 14.6406 17.1258C14.847 17.4822 15.5638 18.6506 16.6234 19.5938C17.9854 20.8106 19.1346 21.1845 19.4922 21.3633C19.8498 21.5421 20.0569 21.5142 20.2633 21.2742C20.4757 21.0402 21.1553 20.2373 21.3953 19.8797C21.6293 19.5221 21.8682 19.584 22.1922 19.7016C22.521 19.8192 24.2744 20.6829 24.632 20.8617C24.9896 21.0405 25.2241 21.129 25.3141 21.2742C25.4065 21.4242 25.4066 22.1383 25.1102 22.9711C24.8138 23.8027 23.3583 24.6069 22.7055 24.6633C22.0467 24.7245 21.4318 24.9594 18.4234 23.775C14.7946 22.3458 12.5061 18.6291 12.3273 18.3891C12.1485 18.1551 10.8742 16.4567 10.8742 14.7047C10.8742 12.9467 11.7959 12.0857 12.1187 11.7281C12.4475 11.3705 12.8327 11.2828 13.0727 11.2828Z"
      fill="black"
    />
  </svg>
);

export default React.memo(WhatsappIcon);
