import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const {
  REQUEST,
  POST_AFFILIATE_ADDRESS,
  GET_AFFILIATE_PROFILE,
  GOAFFPRO_NOTIFICATION_PREFERENCE,
} = actionTypes;

//write sagas function

function* getAffiliateProfile(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAffiliateProfile,
      data.data
    );
    yield sendPayload(apiResponse, GET_AFFILIATE_PROFILE);
  } catch (e) {
    yield sendPayloadFailure(e, GET_AFFILIATE_PROFILE);
  }
}

function* affiliatePostAddress(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.patchAffiliateAddress,
      data.data
    );
    yield sendPayload(apiResponse, POST_AFFILIATE_ADDRESS);
  } catch (e) {
    yield sendPayloadFailure(e, POST_AFFILIATE_ADDRESS);
  }
}

function* setGoaffproNotificatinPreference(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.setNotificationPreference,
      data.data
    );
    yield sendPayload(apiResponse, GOAFFPRO_NOTIFICATION_PREFERENCE);
  } catch (e) {
    yield sendPayloadFailure(e, GOAFFPRO_NOTIFICATION_PREFERENCE);
  }
}

export const sagas = {
  watchAffiliateAddressPost: takeLatest(
    actionTypes.POST_AFFILIATE_ADDRESS[REQUEST],
    affiliatePostAddress
  ),
};

export const sagas2 = {
  watchAffiliateProfileGet: takeLatest(
    actionTypes.GET_AFFILIATE_PROFILE[REQUEST],
    getAffiliateProfile
  ),
};

export const sagas3 = {
  watchSetNotification: takeLatest(
    actionTypes.GOAFFPRO_NOTIFICATION_PREFERENCE[REQUEST],
    setGoaffproNotificatinPreference
  ),
};
