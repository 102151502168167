import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { List, ListItemButton, ListItemText } from "@mui/material";
import { styles } from "../style";
import { IAPIData } from "../../models/types.model";
import { RootState } from "../../configureStore";
import { getTabName } from "../helper";
import { isArrayIncludesKey, iskeyInObject } from "../../utils/hooks";
import { openSendMoney } from "../../actions";

function MainSubRoute({item, setSubRouteName, setModelProps}:any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const url: string = window.location.href;

  const profileData = useSelector(
    (state: RootState) => state?.entities?.userProfile?.userData as IAPIData
  );

  const userRole = profileData?.data && profileData?.data?.user?.role;

  const onSubROuteClick = (
    name: string,
    displayName: boolean,
    navigateTo: string
  ) => {
    let display = displayName ? "block" : "none";
    if (navigateTo !== window?.location?.pathname) {
      setSubRouteName({ name, display });
      navigate(navigateTo);
    }
  };

    const onItemClick = (sub: any) => {
      if (sub.name == "Get a plan") {
        window.open(
          "https://www.pyvit.com/pages/pyvit-video-editor-pricing",
          "_blank"
        );
      } else if (iskeyInObject(sub, "isModal")) {
        setModelProps({
          name: sub.name,
          element: sub.element,
        });
        dispatch(openSendMoney());
      } else {
        onSubROuteClick(
          sub.name,
          sub.displayName,
          `/${item.route}${sub.route}`
        );
      }
    };
  
  return (
    <>
      <List component="div" disablePadding>
        {item.subRoute?.map(
          (sub: any, subIndex: any) =>
            item.name !== "eWallet" && (
              <ListItemButton
                key={subIndex}
                sx={
                  isArrayIncludesKey(url, `${item.route}${sub.route}`)
                    ? styles.slectedRouteBtn
                    : styles.subrouteBtn
                }
                onClick={() => onItemClick(sub)}
              >
                <ListItemText
                  sx={styles.subRouteText}
                  primary={getTabName(sub.name, userRole, t)}
                />
              </ListItemButton>
            )
        )}
      </List>
    </>
  );
}

export default MainSubRoute;
