import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, DELETE_VIDEO } = actionTypes;

//write sagas function

function* deleteVideoSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.deleteVideo,
      data.data
    );
    yield sendPayload(apiResponse, DELETE_VIDEO);
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_VIDEO);
  }
}

export const sagas = {
  watchdeleteVideo: takeLatest(
    actionTypes.DELETE_VIDEO[REQUEST],
    deleteVideoSaga
  ),
};
