import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";

const { SUCCESS, REQUEST, CLICK_STATS_WEEKLY } = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const clickStatsWeeklyData = (state = intialState, action: actions) => {
    switch (action.type) {
      case CLICK_STATS_WEEKLY[SUCCESS]:
        return { ...state, data: action.payload.data, isLoading: false };
      case CLICK_STATS_WEEKLY[REQUEST]:
        return action?.payload?.results
          ? { ...state, data: action.payload.results, isLoading: true }
          : { ...state, isLoading: true };
      case CLICK_STATS_WEEKLY[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  return combineReducers({
    clickStatsWeeklyData,
  });
};

export default entity;

export const clickStatsWeekly = (state: RootState) =>
  state?.entities?.clickStatsWeekly;
