export const programInfoStyle = {
  wrapper: {
    marginB: { mb: "8px" },
    display: { display: "inline" },
    mb: "80px",
    boxMargin: {
      mt: "12px",
      mb: "24px",
    },
  },
};
