import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Info, SubTitle1 } from "../../../utils/styledComponents";
import { stylesGlobal } from "../../../utils/muiStyles";
import { useFormik } from "formik";
import { initialValues, onlyPasswordSchema } from "./passwordOnlySchema";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSendMoney,
  createNomadAccount,
  getUserProfile,
} from "../../../actions";
import { RootState } from "../../../configureStore";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";

function EwalletSignUp() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { nomadCreateData }: any = useSelector(
    (state: RootState) => state?.entities?.createNomad
  );

  const { userData }: any = useSelector(
    (state: RootState) => state?.entities?.userProfile
  );
  const email: string = userData?.data?.customer?.email ?? "---";
  const [loader, setLoader] = useState(false);

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: onlyPasswordSchema,
    onSubmit: () => {
      setLoader(true);
      dispatch(
        createNomadAccount.request({ data: { password: values.password } })
      );
    },
  });

  useEffect(() => {
    if (!nomadCreateData?.isLoading && !nomadCreateData?.isError) {
      setLoader(false);
      dispatch(getUserProfile.request({}));
      dispatch(closeSendMoney());
    }
    if (nomadCreateData?.isError) {
      setLoader(false);
      dispatch(createNomadAccount?.reset());
      dispatch(closeSendMoney());
    }
  }, [nomadCreateData?.isLoading, nomadCreateData?.isError]);

  useEffect(() => {
    if (userData?.data && !userData?.isLoading) {
      let eWallet = userData?.data?.access?.filter(
        (item: any) => item?.name === "eWallet"
      );

      let signUp = eWallet?.subRoute?.filter((item: any) => {
        if (item?.name === "Sign Up") {
          return item;
        }
      });

      let signUpAccess = signUp?.length ? signUp[0]?.access : true;

      if (!signUpAccess) {
        dispatch(closeSendMoney());
      }
    }
  }, [userData, userData?.isLoading]);

  return (
    <Stack sx={styles.signupContainer}>
      <SubTitle1>{t("PYVIT eWallet Email")}</SubTitle1>
      <Info>{email ?? "---"}</Info>

      <SubTitle1>{t("PYVIT eWallet Password")}</SubTitle1>

      <TextField
        label={t("Enter the Password")}
        variant="outlined"
        fullWidth
        size="small"
        value={values.password}
        error={touched?.password && !!errors.password}
        helperText={touched?.password && errors?.password}
        onChange={handleChange}
        sx={{ ...stylesGlobal.textField, mt: 0 }}
        name="password"
      />

      <Button
        variant="contained"
        sx={stylesGlobal.containedBtn}
        disabled={loader}
        onClick={() => handleSubmit()}
      >
        {loader && (
          <CircularProgress size={"14px"} sx={stylesGlobal.buttonLoader} />
        )}
        {t("Create PYVIT eWallet Account")}
      </Button>
    </Stack>
  );
}

export default EwalletSignUp;
