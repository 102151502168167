import { Box, Modal } from "@mui/material";
import React from "react";
import { Styles, playerModalStyle } from "./style";
import ClearIcon from "@mui/icons-material/Clear";

interface IModalProps {
  open: boolean;
  onClose: any;
  url: string;
}

const FloatPlayerModal = ({ open, onClose, url }: IModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          backgroundColor: "transparent",
          overflow: "auto",
          position: "absolute",
          width: "100vw",
          minWidth: "400px",
          height: "100vh",
        }}
      >
        <Box sx={playerModalStyle.crossWrapper} onClick={onClose}>
          <ClearIcon />
        </Box>
        <iframe
          src={url}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
        {/* <iframe src={url} width="100%" height="100%" allowFullScreen /> */}
        {/* </Box> */}
      </Box>
    </Modal>
  );
};

export default FloatPlayerModal;
