import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../configureStore";
import { Info, SubTitle1 } from "../../../utils/styledComponents";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { toast } from "react-toastify";
import MediaTwitterIcon from "../../../utils/icons/MediaTwitterIcon";
import MediaFacebookIcon from "../../../utils/icons/MediaFacebookIcon";
import MediaLinkedInIcon from "../../../utils/icons/MediaLinkedInIcon";
import MediaPinterestIcon from "../../../utils/icons/MediaPinterestIcon";
import MediaWhatsappIcon from "../../../utils/icons/MediaWhatsappIcon";
import MediaMailIcon from "../../../utils/icons/MediaMailIcon";
import { useTranslation } from "react-i18next";
import { shareVideoStyle } from "./style";

function ShareVideo() {
  const { id } = useParams();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [copied, setCopied] = useState(false);
  const [copiedEmbed, setCopiedEmbed] = useState(false);

  const { videoDetails } = useSelector(
    (state: RootState) => state?.entities?.videoDetail
  );
  const videoInfo = videoDetails?.data?.data?.response;

  const media = [
    {
      icon: <MediaFacebookIcon />,
      name: "Facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=${videoInfo?.web_player_url}`,
    },
    {
      icon: <MediaTwitterIcon />,
      name: "Twitter",
      url: `https://twitter.com/intent/tweet?text=${videoInfo?.title} - ${videoInfo?.description}&url=${videoInfo?.web_player_url}`,
    },
    {
      icon: <MediaLinkedInIcon />,
      name: "Linked In",
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${videoInfo?.web_player_url}&title=${videoInfo?.title}&summary=${videoInfo?.description}&source=`,
    },
    {
      icon: <MediaPinterestIcon />,
      name: "Pinterest",
      url: `https://pinterest.com/pin/create/button/?url=${videoInfo?.web_player_url}&media=&description=${videoInfo?.title}`,
    },
    {
      icon: <MediaWhatsappIcon />,
      name: "Whatsapp",
      url: `https://wa.me/?text=${videoInfo?.web_player_url}`,
    },
    {
      icon: <MediaMailIcon />,
      name: "Mail",
      url:
        "mailto:?&subject=Check out this Pyvit video!&body=%20%0d%0a" +
        videoInfo?.web_player_url,
    },
  ];

  const handleIconClick = (url: string, label: string) => {
    try {
      navigator.clipboard.writeText(url);
      if (label === "URL") {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      } else {
        setCopiedEmbed(true);
        setTimeout(() => {
          setCopiedEmbed(false);
        }, 2000);
      }
    } catch (error) {
      // Fallback if clipboard access is not available
      console.error("Clipboard access not available:", error);
      // Display URL for manual copying
      // alert(`Please copy the URL: ${url}`);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={shareVideoStyle.wrapper}>
        <SubTitle1>
          {t("PYVIT videos can be shared anywhere you can share a link.")}
        </SubTitle1>
      </Grid>

      <Grid item xs={12}>
        <SubTitle1 sx={shareVideoStyle.wrapper.subTitleStyle}>
          {t("URL")}
        </SubTitle1>
        <TextField
          variant="outlined"
          value={videoInfo?.web_player_url}
          fullWidth
          size="small"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  open={copied}
                  onClose={() => setCopied(false)}
                  title={t("URL copied!")}
                >
                  <ContentCopyRoundedIcon
                    sx={shareVideoStyle.wrapper.iconStyle}
                    onClick={() =>
                      handleIconClick(videoInfo?.web_player_url ?? "", "URL")
                    }
                  />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          sx={shareVideoStyle.wrapper.textField}
        />
      </Grid>

      <Grid item xs={12}>
        <SubTitle1 sx={shareVideoStyle.wrapper.textStyle}>
          {t("Embed code")}
        </SubTitle1>

        <TextField
          variant="outlined"
          value={`<iframe id="tig-frame" src="${videoInfo?.web_player_url}" width="800" height="600" allowfullscreen="true"></iframe>`}
          fullWidth
          size="small"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  open={copiedEmbed}
                  onClose={() => setCopiedEmbed(false)}
                  title={t("Embed Code copied!")}
                >
                  <ContentCopyRoundedIcon
                    sx={shareVideoStyle.wrapper.iconStyle}
                    onClick={() =>
                      handleIconClick(
                        `<iframe id="tig-frame" src="${
                          videoInfo?.web_player_url ?? ""
                        }" width="800" height="600" allowfullscreen="true"></iframe>`,
                        "Embed Code"
                      )
                    }
                  />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          sx={shareVideoStyle.wrapper.textField}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack alignItems="center" justifyContent="center" sx={shareVideoStyle.wrapper.stackMargin}>
          <Info> {t("Share on social media")} </Info>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box sx={shareVideoStyle.wrapper.box}>
            {media.map((item: any) => {
              return (
                <IconButton
                  onClick={() => {
                    window.open(item.url);
                  }}
                  sx={{ padding: isMobile ? "0px" : "8px" }}
                >
                  {item.icon}
                </IconButton>
              );
            })}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ShareVideo;
