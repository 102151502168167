import styled from "styled-components";
import { copyCreatorVideo } from "../../../actions";
import { CreatorLibHeadCell } from "../../../models/backoffice.models";
import { CONSTANTS } from "../../../utils/constants";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";


export const { creatorLibColumnList } = CONSTANTS;
export const copyVideoLink = (id: string,dispatch:any,copyLinkContents:any) => {
    copyLinkContents?.push(id);
    dispatch(copyCreatorVideo.request({ content_id: id }));
  };

export const handleScroll = (ref:any,setNavLeft:any) => {
    if (ref.current) {
      const scrollLeft = ref.current.scrollLeft;
      const isAtLeft = scrollLeft === 0;
      setNavLeft(isAtLeft);
    }
  };

export const convertToPlainText = (html: string) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  export const headCellsMobile: CreatorLibHeadCell[] = [
    {
      id: "video",
      numeric: false,
      disablePadding: false,
      label: "Video",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label: "Title",
    },
  ];

 export function selectFilterHook(filters:any,commonFilterChange:any,creatorAthenaAnalyticsData:any, creatorAthenaAccess:any){
    return [
        {
          list: CONSTANTS
          .CreatorLibrarySearchBy,
          label: "Search by",
          onChange: commonFilterChange,
          value: filters.searchKey,
          name: "searchKey",
          disabled: false,
        },
        {
          list: creatorAthenaAccess?.length ? CONSTANTS.CreatorLibraryAnalyticsOrder : CONSTANTS.CreatorLibraryOrderContentId,
          label: "Sort by",
          onChange: commonFilterChange,
          isAthenaFilter: !creatorAthenaAccess?.length && !creatorAthenaAnalyticsData.isError,
          value: filters.sortKey,
          name: "sortKey",
          disabled: false,
        },
        {
          list: CONSTANTS.Order,
          label: "Order",
          onChange: commonFilterChange,
          value: filters.sortOrder,
          name: "sortOrder",
          disabled: false,
        },
      ];
  }

  export const defaultFilters ={
    searchKey: "title",
    sortKey: "content_id",
    sortOrder: "desc",
    searchValue: "",
    page: 1,
    limit: 10,
  }

  export const multiselectList = [
    {
      list: creatorLibColumnList,
      label: "Column visibility",
      name: "colVisibility",
      isColumnVisibilty: true,
    },
  ];

  export const RotatableIcon = styled(ArrowDropDownIcon)(({ rotate }) => ({
    transform: rotate ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.3s',
  }));
