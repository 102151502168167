import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { DELETE_HISTORY, FAILURE, FAVORITE_UNFAVORITE, GET_PRODUCT_LINK, RESET, SAVE_PRODUCT_LINK } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";

const { SUCCESS, REQUEST, GET_MEDIA_ASSETS } = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
  inProgress:false,
};

const entity = () => {
  const mediaAssets = (state = {}, action: actions) => {
    switch (action.type) {
      case GET_MEDIA_ASSETS[SUCCESS]:
        return { ...state, data: action.payload.data, isLoading: false };
      case GET_MEDIA_ASSETS[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload, isLoading: true }
          : { ...state, isLoading: true };
      case GET_MEDIA_ASSETS[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case GET_MEDIA_ASSETS[RESET]:
        return {};
      default:
        return state;
    }
  };

  const getProductLinkData = (state = intialState, action: actions) => {
    switch (action.type) {
      case GET_PRODUCT_LINK[SUCCESS]:
        return { ...state, data: action.payload.data, isLoading: false };
      case GET_PRODUCT_LINK[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload, isLoading: true }
          : { ...state, isLoading: true };
      case GET_PRODUCT_LINK[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case GET_PRODUCT_LINK[RESET]:
        return {};
      default:
        return state;
    }
  };

  const saveProductLinkData = (state = intialState, action: actions) => {
    switch (action.type) {
      case SAVE_PRODUCT_LINK[SUCCESS]:
        return { ...state, data: action.payload.data, isLoading: false };
      case SAVE_PRODUCT_LINK[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload, isLoading: true }
          : { ...state, isLoading: true };
      case SAVE_PRODUCT_LINK[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case SAVE_PRODUCT_LINK[RESET]:
        return {};
      default:
        return state;
    }
  };

  const favoriteProductData = (state = intialState, action: actions) => {
    switch (action.type) {
      case FAVORITE_UNFAVORITE[SUCCESS]:
        return { ...state, data: action.payload.data, isLoading: false,inProgress:false };
      case FAVORITE_UNFAVORITE[REQUEST]:
        return { ...state, data: action.payload, isLoading: true,inProgress:true }
      case FAVORITE_UNFAVORITE[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
          inProgress:false
        };
      case FAVORITE_UNFAVORITE[RESET]:
        return {};
      default:
        return state;
    }
  };

  const deleteProductData = (state = intialState, action: actions) => {
    switch (action.type) {
      case DELETE_HISTORY[SUCCESS]:
        return { ...state, data: action.payload.data, isLoading: false };
      case DELETE_HISTORY[REQUEST]:
        return { ...state, data: action.payload, isLoading: true }
      case DELETE_HISTORY[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case DELETE_HISTORY[RESET]:
        return {};
      default:
        return state;
    }
  };

  return combineReducers({
    mediaAssets,
    getProductLinkData,
    saveProductLinkData,
    favoriteProductData,
    deleteProductData
  });
};

export default entity;

export const mediaAssets = (state: RootState) => state.entities.mediaAssets;
