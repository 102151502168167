import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, SUMMARY_PROFILE } = actionTypes;

function* summaryProfileSagas(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.summaryProfileApi,
      data.data
    );
    yield sendPayload(apiResponse, SUMMARY_PROFILE);
  } catch (e) {
    yield sendPayloadFailure(e, SUMMARY_PROFILE);
  }
}

export const sagas = {
  watchSummaryProfile: takeLatest(
    actionTypes.SUMMARY_PROFILE[REQUEST],
    summaryProfileSagas
  ),
};
