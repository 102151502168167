import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";

const { SUCCESS, REQUEST, RESET, ORDER_TRACKING } = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const orderTrackingData: any = (state = intialState, action: actions) => {
    switch (action.type) {
      case ORDER_TRACKING[REQUEST]:
        return intialState;
      case ORDER_TRACKING[SUCCESS]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: false,
        };
      case ORDER_TRACKING[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case ORDER_TRACKING[RESET]:
        return {};
      default:
        return state;
    }
  };

  return combineReducers({
    orderTrackingData,
  });
};

export default entity;

export const orderTracking = (state: RootState) => state.entities.orderTracking;
