import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";

const { SUCCESS, REQUEST, GOAFFPRO_PAYMENTS } = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const goaffproPaymentsData = (state = intialState, action: actions) => {
    switch (action.type) {
      case GOAFFPRO_PAYMENTS[SUCCESS]:
        return { ...state, data: action.payload.data, isLoading: false };
      case GOAFFPRO_PAYMENTS[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload, isLoading: false }
          : state;
      case GOAFFPRO_PAYMENTS[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  return combineReducers({
    goaffproPaymentsData,
  });
};

export default entity;

export const goaffproPayment = (state: RootState) =>
  state.entities.goaffproPayment;
