import { SECONDARYCOLOUR, THIN_BORDER_COLOR } from "../../../colors";

export const tabStyle = {
  detailStyle: {
    inputField: { width: "100%", border: "none" },
    saveBtnOuter: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    saveBtnOuterMobile: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginTop: "25px",
    },
    thumbnailOuter: { display: "flex", flexWrap: "wrap", padding: "0 20px" },
    thumbnailInfoRow: { display: "flex", width: "100%", padding: "4px 0px" },
    thumbnailInfoRowMobile: {
      width: "100%",
      padding: "4px 0px",
      marginBottom: "20px",
    },
    thumbnailImgOuter: { height: "131px", width: "232px" },
    thumbnailImgOuterMobile: {
      width: { xs: "100%", sm: "220px", height: "120px" },
    },
    changeThumbnailbtnOuter: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    thumbnailImgContain: {
      display: "flex",
      alignItems: "center",
      border: `1px solid ${THIN_BORDER_COLOR}`,
      justifyContent: "center",
      height: "100%",
      width: "100%",
      mb: "10px",
    },
  },
};

export const changeThumbnail={
  stackStyle:{
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
    p: "10px 0",
  },
  boxStyle:{ pr: "10px", height: "fit-content" },
  wrapper:{ cursor: "pointer" },
  textBox:{ m: "10px 0", height: { xs: "225px", md: "200px" } },
  buttonStyle:{ textTransform: "none" },
}

export const thumbnailCropzoneStyles = {
  baseStyle: {
    flex: 1,
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "1.75rem",
    height: "auto",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: THIN_BORDER_COLOR,
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  focusedStyle: {
    borderColor: SECONDARYCOLOUR,
  },
  acceptStyle: {
    borderColor: "#00e676",
  },
  rejectStyle: {
    borderColor: "#ff1744",
  },
};