import { Avatar, Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledText } from "../../../utils/styledComponents";
import BackIconSVG from "../../../utils/icons/BackIconSVG";
import { previewStyle, profileStyle } from "./style";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../configureStore";
import { getAffiliateProfile } from "../../../actions";
import Loading from "../../../shared/Loading";
import { useTranslation } from "react-i18next";
import { THIN_BORDER_COLOR } from "../../../colors";
import { CountryMobileData } from "../../../utils/constants";

function SettingsPreview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { affiliateProfileData }: any = useSelector(
    (state: RootState) => state?.entities?.affiliateProfile
  );

  const [profileData, setProfileData] = useState({
    ID: null,
    "Pyvit handle": "",
    "First name": "",
    "Last name": "",
    "Date of birth": "",
    Phone: "",
    Bio: "",
    "Website link": "",
    "Facebook link": "",
    "Pinterest link": "",
    "TikTok link": "",
    "Youtube link": "",
  });

  const [profileImageURL, setProfileImageURL] = useState<string>("");
  const [addressData, setAddressData] = useState({
    "Street address": "",
    City: "",
    State: "",
    Country: "",
    "Postal code": "",
  });

  const [detailsData, setDetailsData] = useState({
    "Referral code": "",
    "Tax identification number": "",
  });

  const handleNavigate = () => {
    window.location.pathname?.includes("creator")
      ? navigate("/creator/settings")
      : navigate("/influencer/settings");
  };

  useEffect(() => {
    if (!affiliateProfileData?.isLoading && !affiliateProfileData?.isError) {
      const affiliate = affiliateProfileData?.data;

      setProfileData({
        ID: affiliate?.id,
        "Pyvit handle": affiliate?.company_name ?? "-",
        "First name": affiliate?.first_name ?? "-",
        "Last name": affiliate?.last_name ?? "-",
        "Date of birth": affiliate?.date_of_birth ?? "-",
        Phone: affiliate?.phone ?? "-",
        Bio: affiliate?.comments ?? "-",
        "Website link": affiliate?.website ?? "-",
        "Facebook link": affiliate?.facebook ?? "-",
        "Pinterest link": affiliate?.pinterest ?? "-",
        "TikTok link": affiliate?.tiktok ?? "-",
        "Youtube link": affiliate?.youtube ?? "-",
      });
      setAddressData({
        "Street address": affiliate?.address_1 ?? "-",
        City: affiliate?.city ?? "-",
        State: affiliate?.state ?? "-",
        Country: affiliate?.country ?? "-",
        "Postal code": affiliate?.zip ?? "-",
      });
      setDetailsData({
        "Referral code": affiliate?.ref_code ?? "-",
        "Tax identification number":
          affiliate?.tax_identification_number ?? "-",
      });
    }
  }, [affiliateProfileData?.isLoading]);

  useEffect(() => {
    dispatch(getAffiliateProfile.request({}));
    if (affiliateProfileData?.data?.database_image?.profile_photo_path) {
      setProfileImageURL(
        affiliateProfileData?.data?.database_image?.profile_photo_path
      );
    } else if (affiliateProfileData?.data.hasOwnProperty("profile_photo")) {
      setProfileImageURL(affiliateProfileData?.data?.profile_photo);
    }
  }, []);

  const convertToPlainText = (html: any) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getCountryNameByCode = (code: string) => {
    const country = CountryMobileData.find((country) => country.code === code);
    return country ? country.name : null;
  };

  return (
    <>
      {!affiliateProfileData?.isLoading && !affiliateProfileData?.isError ? (
        <Box>
          <Box sx={previewStyle.headerBox}>
            <Box sx={previewStyle.previewBody.cursor} onClick={handleNavigate}>
              <BackIconSVG />
            </Box>

            <StyledText fontvalue="25px" lineheight="38px">
              {t("Settings View")}
            </StyledText>
          </Box>
          <Box sx={previewStyle.previewBody}>
            <StyledText fontvalue="20px" lineheight="30px">
              {t("Profile")}
            </StyledText>
            <Box sx={profileStyle.wrapper.profileWrapper}>
              <Box sx={profileStyle.wrapper.profileWrapper.profileItem}>
                {profileImageURL?.length > 0 ? (
                  <img
                    src={profileImageURL}
                    alt="photo"
                    width="auto"
                    height="184px"
                  />
                ) : (
                  <Avatar sx={previewStyle.previewBody.avatarStyle}>
                    {affiliateProfileData?.data?.first_name
                      ? affiliateProfileData?.data?.first_name?.slice(0, 1)
                      : ""}
                  </Avatar>
                )}
              </Box>
            </Box>

            <Grid container spacing={2}>
              {Object.entries(profileData).map(([key, value], index) => (
                <>
                  <Grid item xs={6} lg={3}>
                    <StyledText
                      fontvalue="16px"
                      lineheight="24px"
                      fontWeight="bold"
                      letterSpacing="0.15"
                      sx={previewStyle.previewBody.wordBreak}
                    >
                      {t(key)}
                    </StyledText>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    {key === "Date of birth" ? (
                      <StyledText
                        fontvalue="16px"
                        lineheight="24px"
                        sx={previewStyle.previewBody.wordBreak}
                      >
                        {value && formatDate(value)}
                      </StyledText>
                    ) : (
                      <StyledText
                        fontvalue="16px"
                        lineheight="24px"
                        sx={previewStyle.previewBody.wordBreak}
                      >
                        {convertToPlainText(value)}
                      </StyledText>
                    )}
                  </Grid>
                </>
              ))}
            </Grid>

            <StyledText
              fontvalue="20px"
              lineheight="30px"
              sx={previewStyle.previewBody.margin}
              fontWeight="bold"
              letterSpacing="0.15"
            >
              {t("Address")}
            </StyledText>

            <Grid container spacing={2}>
              {Object.entries(addressData).map(([key, value], index) => (
                <>
                  <Grid item xs={6} lg={3}>
                    <StyledText
                      fontvalue="16px"
                      lineheight="24px"
                      fontWeight="bold"
                      letterSpacing="0.15"
                    >
                      {t(key)}
                    </StyledText>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    {key === "Country" ? (
                      <StyledText fontvalue="16px" lineheight="24px">
                        {getCountryNameByCode(value)}
                      </StyledText>
                    ) : (
                      <StyledText fontvalue="16px" lineheight="24px">
                        {value}
                      </StyledText>
                    )}
                  </Grid>
                </>
              ))}
            </Grid>

            <Grid container spacing={2} mt="20px">
              {Object.entries(detailsData).map(([key, value], index) => (
                <Grid item xs={6} lg={12}>
                  <Grid item xs={3} lg={6}>
                    <StyledText
                      fontvalue="20px"
                      lineheight="30px"
                      fontWeight="bold"
                      letterSpacing="0.15"
                    >
                      {t(key)}
                    </StyledText>
                    {/* </Grid>
                                <Grid item xs={3} lg={6}> */}
                    <StyledText fontvalue="16px" lineheight="24px">
                      {value}
                    </StyledText>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      ) : !affiliateProfileData?.isError ? (
        <Box height={"100vh"}>
          <Loading />
        </Box>
      ) : (
        "Something went wrong"
      )}
    </>
  );
};

export default SettingsPreview;
