import { Box, Grid, IconButton, Stack, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SubTitle1 } from "../../utils/styledComponents";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../configureStore";
import { useNavigate } from "react-router-dom";
import { deleteVideoCall } from "../../actions";
import BackDropLoader from "../../shared/BackdropLoader";
import { useTranslation } from "react-i18next";
import { THIN_BORDER_COLOR } from "../../colors";
import FloatAlert from "../../shared/components/FloatAlert";
import { videoDetailsStyle, videoHeaderCard } from "./style";

function VideoHeaderCard() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { videoDetails } = useSelector(
    (state: RootState) => state?.entities?.videoDetail
  );

  const { deleteVideoData }: any = useSelector(
    (state: RootState) => state?.entities?.deleteVideo
  );

  const [open, setOpen] = React.useState(false);
  const [videoData, setVideoData]: any[] = useState([]);
  const [tigModalController, setTigModalController] = useState<any>();
  const [openAlert, setAlert] = useState(false);

  const handleBackdropClose = () => {
    setOpen(false);
  };
  const handleBackdropOpen = () => {
    setOpen(true);
  };

  const videoInfo = videoDetails?.data?.data?.response;

  const handleDeleteVideo = () => {
    dispatch(
      deleteVideoCall.request({
        data: { content_id: videoInfo?.content_id },
      })
    );
    handleBackdropOpen();
  };

  useEffect(() => {
    if (!deleteVideoData?.isLoading && deleteVideoData?.data?.success) {
      handleBackdropClose();
      dispatch(deleteVideoCall.reset());
      navigate("/video-editor/videos");
    }
  }, [deleteVideoData?.isLoading]);

  useEffect(() => {
    let videoData: any = [];
    let video = {
      identifier: videoInfo?.content_identifier,
      playlist_url: videoInfo?.video_url,
    };
    videoData.push(video);
    setVideoData(videoData);
  }, [videoInfo]);

  useEffect(() => {
    function processTigCreatorCustomEmbedFunc() {
      if (videoData?.length > 0) {
        window.initTigCreatorEmbed({
          type: "custom",
          customContentsList: videoData,
          customReadyCallback: function (controller: any) {
            setTigModalController(controller);
          },
        });
      }
    }

    if (
      document.readyState === "interactive" ||
      document.readyState === "complete"
    ) {
      processTigCreatorCustomEmbedFunc();
    } else {
      window.addEventListener("DOMContentLoaded", function () {
        processTigCreatorCustomEmbedFunc();
      });
    }
  }, [videoData]);

  const openVideo = () => {
    tigModalController?.open(videoData[0]?.identifier);
  };

  return (
    <Grid
      sx={videoHeaderCard.wrapper}
    >
      <Grid
        item
        xs={12}
        sm={3}
        md={3}
        lg={2}
        sx={{
          height: { xs: isMobile ? "" : "90px" },
          width: "160px",
          borderRight: `1px solid ${THIN_BORDER_COLOR}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          height={isMobile ? "65px" : "100%"}
          width={"auto"}
          src={videoInfo?.ogp_thumbnail_url ?? ""}
          alt="video"
          onClick={openVideo}
          style={{
            cursor: "pointer",
            maxWidth: "100%",
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
        md={7}
        lg={8}
        sx={videoHeaderCard.gridStyle}
      >
        <SubTitle1
          sx={videoHeaderCard.subtitle}
        >
          {videoInfo?.title}
        </SubTitle1>
      </Grid>
      <Grid item xs={12} sm={2} md={2} lg={2}>
        <Box>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <IconButton
              onClick={() => setAlert(true)}
              color="primary"
              sx={videoHeaderCard.iconButton}
            >
              <DeleteIcon sx={videoHeaderCard.deleteIcon} />
            </IconButton>
          </Stack>
        </Box>
      </Grid>
      <BackDropLoader open={open} />
      <FloatAlert
        open={openAlert}
        onClose={() => {
          setAlert(false);
        }}
        alert={t("Are you sure you want to delete this video?")}
        action={() => {
          handleDeleteVideo();
          handleBackdropOpen();
          setAlert(false);
        }}
      />
    </Grid>
  );
};

export default VideoHeaderCard;
