import { Box, TableBody, TableHead, TableRow } from "@mui/material";
import React from "react";
import {
  StyledModalTable,
  StyledModalTableCell,
} from "../../../utils/styledComponents";
import { buyProductAgainModalStyle, styles } from "./style";
import { useTranslation } from "react-i18next";
import { ILineItem, ILineItemsWithCurrency } from "../../../models/backoffice.models";

interface IProps {
  productData: ILineItemsWithCurrency;
}

function ProductDetails({ productData }: IProps) {
    const { lineItems, currency } = productData;
  const { t } = useTranslation();

  const totalPriceSum = lineItems.reduce(
    (acc, product) => acc + parseFloat(product.price),
    0
  );
  const totalQuantitySum = lineItems.reduce(
    (acc, product) => acc + product.quantity,
    0
  );
  const totalSum = lineItems.reduce((acc, product) => acc + product.total, 0);

  return (
    <Box sx={buyProductAgainModalStyle.wrappper}>
      <StyledModalTable>
        <TableHead>
          <TableRow sx={styles.rowStyle}>
            <StyledModalTableCell sx={buyProductAgainModalStyle.textBold}>
              {t("Product name")}
            </StyledModalTableCell>
            <StyledModalTableCell sx={buyProductAgainModalStyle.textBold}>
              {t("Price")}
            </StyledModalTableCell>
            <StyledModalTableCell sx={buyProductAgainModalStyle.textBold}>
              {t("Quantity")}
            </StyledModalTableCell>
            <StyledModalTableCell sx={buyProductAgainModalStyle.textBold}>
              {t("Total")}
            </StyledModalTableCell>
            <StyledModalTableCell sx={buyProductAgainModalStyle.textBold}>
              {t("Status")}
            </StyledModalTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems.map((product: ILineItem, index: number) => (
            <TableRow key={index} sx={styles.rowStyle}>
              <StyledModalTableCell>{product?.name}</StyledModalTableCell>
              <StyledModalTableCell>{`${currency} ${product.price}`}</StyledModalTableCell>
              <StyledModalTableCell>{product.quantity}</StyledModalTableCell>
              <StyledModalTableCell>{`${currency} ${product.total.toFixed(
                2
              )}`}</StyledModalTableCell>
              <StyledModalTableCell>
                {product?.requires_shipping
                  ? product.tracking_status
                  : "Digital product"}
              </StyledModalTableCell>
            </TableRow>
          ))}
          <TableRow sx={styles.rowStyle}>
            <StyledModalTableCell sx={buyProductAgainModalStyle.textBold}>
              {t("Total")}
            </StyledModalTableCell>
            <StyledModalTableCell
              sx={buyProductAgainModalStyle.textBold}
            >{`${currency} ${totalPriceSum}`}</StyledModalTableCell>
            <StyledModalTableCell sx={buyProductAgainModalStyle.textBold}>
              {totalQuantitySum}
            </StyledModalTableCell>
            <StyledModalTableCell
              sx={buyProductAgainModalStyle.textBold}
            >{`${currency} ${totalSum}`}</StyledModalTableCell>
          </TableRow>
        </TableBody>
      </StyledModalTable>
    </Box>
  );
};

export default ProductDetails;
