import { THIN_BORDER_COLOR } from "../../../colors";

export const productModalStyle = {
  floatAddProductStyles: {
    imageBtnContainer: {
      minWidth: "150px",
      minHeight: "170px",
      padding: "15px 20px 0px",
      border: { xs: "none", md: `1px solid ${THIN_BORDER_COLOR}` },
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      marginRight: "12px",
    },
  },
};
