import { Box } from "@mui/material";
import React from "react";

const FloatDotIndecator = (props: any) => {
  const { checked }: any = props;
  if (checked) {
    return (
      <Box
        sx={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: "#333",
        }}
      />
    );
  } else
    return (
      <Box
        sx={{
          width: "6px",
          height: "6px",
          borderRadius: "50%",
          border: "1px solid #333",
        }}
      />
    );
};

export default FloatDotIndecator;
