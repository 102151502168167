import { CircularProgress, Stack } from "@mui/material";
import React from "react";
import { stylesGlobal } from "../utils/muiStyles";

const Loading = () => {
  return (
    <Stack sx={stylesGlobal.loader.row}>
      <Stack sx={stylesGlobal.loader.col}>
        <CircularProgress />
      </Stack>
    </Stack>
  );
};

export default Loading;
