import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import { refreshCall } from "../actions";
import { RootState } from "../configureStore";
import Loading from "../shared/Loading";

const PersistLogin = () => {
  const dispatch = useDispatch();
  const [isRefreshAuth, setIsRefreshAuth] = useState<boolean>(false);
  const { loginUserData } = useSelector(
    (state: RootState) => state?.entities?.loginUser
  );
  const { refreshToken } = useSelector(
    (state: RootState) => state?.entities?.refreshToken
  );
  
  useEffect(() => {
    if (!loginUserData?.data.accessToken) {
      dispatch(refreshCall.request({ data: {}, isNomad: false }));
    }
  }, [loginUserData?.data]);

  useEffect(() => {
    if (!refreshToken?.isLoading && refreshToken?.data?.accessToken) {
      setIsRefreshAuth(true);
    }
    if (refreshToken?.isError) {
      setIsRefreshAuth(false);
    }
  }, [refreshToken?.isLoading]);

  return (
    <>
      {loginUserData?.data?.accessToken || isRefreshAuth ? (
        <Outlet />
      ) : refreshToken?.isLoading ? (
        <Stack height={"100vh"}>
          <Loading />
        </Stack>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;
