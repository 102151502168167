import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "../../configureStore";
import { loginUser } from "../../actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../../shared/Loading";
import { Box } from "@mui/material";

function Login() {
  const [params] = useSearchParams();
  const customerId = params.get("customer_id");
  const secret = params.get("secret");
  const lang = params.get("lang");

  useEffect(() => {
    localStorage.setItem("parent-lang", lang ?? "");
  }, [lang]);

  const isIframe = window.self !== window.top;

  const loginData = isIframe
    ? {
      customer_id: customerId,
      secret,
      open: false,
    }
    : {
      customer_id: 123,
      secret: "dev-user",
      open: true,
    };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginUserData } = useSelector(
    (state: RootState) => state?.entities?.loginUser
  );

  useEffect(() => {
    dispatch(loginUser.request({ data: loginData, isNomad: false }));
  }, [dispatch]);

  useEffect(() => {
    if (loginUserData?.data?.data) {
      navigate("/account/profile");
    }
    if (loginUserData?.isError) {
      navigate("/unauthorized");
    }
  }, [navigate, loginUserData]);

  return (
    <Box height={"100vh"}>
      <Loading />
    </Box>
  );
};

export default Login;
