import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
import { GRAB_VIDEOS } from "../actions/actiontypes";
import { toast } from "react-toastify";
const { REQUEST, ADD_VIDEO } = actionTypes;

function* addVideoSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.postVideoData,
      data.data
    );
    yield sendPayload(apiResponse, ADD_VIDEO);
    toast.success("Video Added Successfully!");
    if (apiResponse) {
      const videosListCall: Generator<string, number, string> = yield call(
        ApiService.getVideos,
        {}
      );

      yield sendPayload(videosListCall, GRAB_VIDEOS);
    }
  } catch (e) {
    yield sendPayloadFailure(e, ADD_VIDEO);
  }
}

export const sagas = {
  watchAddVideo: takeLatest(actionTypes.ADD_VIDEO[REQUEST], addVideoSaga),
};
