import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, VIDEO_VIEW_STATS } = actionTypes;

function* getVideoViewsStatSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getVideoViewsStats,
      data.data
    );
    yield sendPayload(apiResponse, VIDEO_VIEW_STATS);
  } catch (e) {
    yield sendPayloadFailure(e, VIDEO_VIEW_STATS);
  }
}

export const sagas = {
  watchVideoViewsStats: takeLatest(
    actionTypes.VIDEO_VIEW_STATS[REQUEST],
    getVideoViewsStatSaga
  ),
};
