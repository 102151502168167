import { t } from "i18next";
import * as Yup from "yup";

export const profileInitialValues = {
  company_name: "",
  first_name: "",
  last_name: "",
  date_of_birth: new Date().toISOString().split("T")[0],
  phone: "",
  comments: "",
  website: "",
  facebook: "",
  instagram: "",
  pinterest: "",
  snapchat: "",
  tiktok: "",
  twitter: "",
  youtube: "",
};

export const profileSchema: any = Yup.object().shape({
  company_name: Yup.string().required("*Please enter the pyvit handle"),
  first_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "First name must contain only alphabets")
    .required("*Please enter the first name"),
  last_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Last name must contain only alphabets")
    .required("*Please enter the last name"),
  phone: Yup.string()
    .matches(
      /^\+\d{1,3}\d{9}$/,
      "Phone number must be in the format '+<country code><10-digit number>'"
    )
    .required(),
  comments: Yup.string().required(t("*Please enter the bio")),
  date_of_birth: Yup.date().required("*Please enter the date of birth"),
  website: Yup.string().url("Invalid URL format").nullable(),
  facebook: Yup.string().url("Invalid URL format").nullable(),
  instagram: Yup.string().url("Invalid URL format").nullable(),
  pinterest: Yup.string().url("Invalid URL format").nullable(),
  snapchat: Yup.string().url("Invalid URL format").nullable(),
  tiktok: Yup.string().url("Invalid URL format").nullable(),
  twitter: Yup.string().url("Invalid URL format").nullable(),
  youtube: Yup.string().url("Invalid URL format").nullable(),
});

export const addressInitialValues = {
  address_1: "",
  city: "",
  state: "",
  country: "",
  zip: "",
};

export const addressSchema: any = Yup.object().shape({
  address_1: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  country: Yup.string(),
  zip: Yup.string().max(10, "Postal code must be at most 10 characters"),
});

export const taxInitialValues = {
  tax_identification_number: "",
};

export const taxSchema: any = Yup.object().shape({
  tax_identification_number: Yup.string().max(
    10,
    "Tax identification number must be at most 10 characters"
  ),
});

export const refInitialValues = {
  ref_code: "",
};

export const refSchema = Yup.object().shape({
  ref_code: Yup.string()
    .max(50, "Referral code must be at most 50 characters")
    .matches(/^\S*$/, "Referral code must not contain spaces"),
});

export const changePasswordInitialValues = {
  email: "",
};

export const changePasswordSchema: any = Yup.object().shape({
  email: Yup.string().required().email(),
});
