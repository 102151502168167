import { PRIMARYCOLOUR, THEMEBACKGROUNDCOLOR } from "../../../colors";

export const summaryStyles = {
  container: { display: "flex", justifyContent: "end", alignItems: "center" },
  flexStyle: { display: "flex", justifyContent: "space-between", mb:"3px" },
  mainGrid: {
    display: { xs: "block", sm: "block", md: "block", lg: "flex" },
    width: "100%",
    justifyContent: "space-between",
  },
  topCards: {
    padding: "20px",
    borderBottom: `2px solid ${PRIMARYCOLOUR}`,
    borderRadius: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "170px",
    boxShadow: "0px 4px 15px 0px #0000001F",
    width: { xs: "100%", sm: "100%", md: "100%", lg: "45%" },
    rowGap: "8px",
    copyLink: {
      display: "flex",
      alignItems: "center",
    },
    mediaBox: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    mediaIcons: {
      padding: "0px",
      width: "40px",
      height: "40px",
    },
  },
  balacesOuterGrid: {
    backgroundColor: THEMEBACKGROUNDCOLOR,
    display: { xs: "block", sm: "block", md: "block", lg: "flex" },
    padding: " 15px 20px",
    alignItems: "center",
    justifyContent: "left",
    width: "100%",
    mt: "20px",
    innerGrid: {
      display: { xs: "block", sm: "block", md: "block", lg: "flex" },
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    balanceCard: {
      background: "#fff",
      textAlign: "center",
      padding: "10px 40px",
      marginTop: {
        xs: "15px",
        sm: "15px",
        md: "15px",
        lg: "0px",
      },
      width: {
        xs: "100%",
        md: "100%",
        lg: "165px"
      },
      height: "86px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    productBalanceCard: {
      background: "#fff",
      textAlign: "center",
      padding: "10px 40px",
      marginTop: {
        xs: "15px",
        sm: "15px",
        md: "15px",
        lg: "0px",
      },
      width: {
        xs: "100%",
        md: "100%",
        lg: "165px"
      },
      height: "94px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      ml: {
        xs: "0px",
        md: "0px",
        lg: "30px"
      },
    },
    progressCard: {
      background: "#fff",
      textAlign: "center",
      padding: "10px 40px",
      marginTop: { xs: "15px", sm: "15px", md: "15px", lg: "0px" },
      display: "flex",
      flexDirection:{
        xs: "column",
        lg: "row",
      },
      justifyContent: "space-between",
      alignItems: "center",
      width: {
        lg: "50%"
      },
    },
    trafficCard: {
      display: "flex",
      flexDirection: "column",
      width: { xs: "100%", md: "70%" },
      mt: { xs: "10px", md: "0px" },
    },
    balance: {
      lineHeight: "25px",
      fontSize: "30px",
      fontWeight: "500",
      font: "jost",
      mb: "10px"
    },
  },
  linearProgress: {
    height: 8,
    borderRadius: 5,
    marginX: 3,
    color: "grey !important",
    background: "linear-gradient(to right, #d4d3d3, #0c0c0c)",
    m: "5px 0 0 0",
  },
  loading: {width:{xs: "100%", md: "70%", height: "80px"}}
};

export const analyticsStyles = {
  gridBox: { padding: "15px", backgroundColor: THEMEBACKGROUNDCOLOR ,height:"calc(100% - 30px) !important" },
  stackStyle: { background: "#fff", padding: "10px" },
  stackBox: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  boxBG: {
    backgroundColor: THEMEBACKGROUNDCOLOR,
    padding: "15px",
    mt: "15px",
  },
  whiteBG: { background: "#fff" },
};
