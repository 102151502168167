import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, CLICK_STATS_WEEKLY } = actionTypes;

//write sagas function

function* totalClickStatsWeeklySaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getClickStatsWeekly,
      data?.data
    );
    yield sendPayload(apiResponse, CLICK_STATS_WEEKLY);
  } catch (e) {
    yield sendPayloadFailure(e, CLICK_STATS_WEEKLY);
  }
}

export const sagas = {
  watchTotalClickStatsWeekly: takeLatest(
    actionTypes.CLICK_STATS_WEEKLY[REQUEST],
    totalClickStatsWeeklySaga
  ),
};
