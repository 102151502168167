import { Box, Button, CircularProgress, TextField } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { stylesGlobal } from "../../utils/muiStyles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import { addPyvitProduct } from "../../actions";
import { toast } from "react-toastify";
import { muiStyle } from "./style";
import { RootState } from "../../configureStore";
import { useTranslation } from "react-i18next";

export interface addProductPropsType {
  handleClose: () => void;
}
const AddPyvitProductModel = ({ handleClose }: addProductPropsType) => {
  const addProducts: any = useSelector(
    (state: RootState) => state?.entities?.addPyvitProductUrl.addPyvitProductUrl
  );
  const [loading, setLoading] = useState(false);

  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [url, setUrl] = useState("");

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  useEffect(() => {
    if (addProducts && !addProducts?.isLoading) {
      dispatch(addPyvitProduct.reset());
      handleClose();
    }
    if (!addProducts?.isLoading) {
      setLoading(false);
    }
  }, [addProducts.isLoading]);

  const onSave = async () => {
    if (url === "") {
      MySwal.fire({
        icon: "error",
        title: t("Please enter the url"),
        text: t(
          "Make sure you include https:// or http:// at the beginning of the url and using the pyvit url."
        ),
      });
    } else if (
      (!url.startsWith("https://") && !url.startsWith("http://")) ||
      !url.includes("pyvit.com")
    ) {
      MySwal.fire({
        icon: "error",
        title: t("Your product URL is invalid"),
        text: t(
          "Make sure you include https:// or http:// at the beginning of the url and using the pyvit url."
        ),
      });
    } else {
      try {
        const response = await fetch(url + ".json");
        const { product } = await response.json();
        if (product.id) {
          setLoading(true);
          dispatch(
            addPyvitProduct.request({
              data: { url: `${url}?product_id=${product.id}` },
            })
          );
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
        toast.error(t("Invalid Url"));
      }
    }
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <TextField
        label={t("Product URL link")}
        variant="outlined"
        placeholder="https://pyvit.com/product-url"
        fullWidth
        value={url}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
        sx={{ ...stylesGlobal.textField, width: "100%" }}
      />
      <Box sx={muiStyle.buttonsConatiner}>
        <Button
          variant="outlined"
          color="secondary"
          sx={stylesGlobal.popupButton}
          onClick={() => handleClose()}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={stylesGlobal.popupButton}
          onClick={onSave}
        >
          {loading && (
            <CircularProgress
              size={"14px"}
              sx={{ color: "#fff", mr: "10px" }}
            />
          )}{" "}
          {t("Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default AddPyvitProductModel;
