/* eslint-disable */
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Divider, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Info, StyledText } from "../../../utils/styledComponents";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../configureStore";
import { editVideoAuthCall } from "../../../actions";
import Swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";
import { videoDetailStyle } from "./styles";
import Loading from "../../Loading";
import { useTranslation } from "react-i18next";
import { ExpandMore } from "@mui/icons-material";
import { THIN_BORDER_COLOR } from "../../../colors";
import PyvSelectStart from "./tutorialGifs/pyv-select-start.gif"
import PyvSaveAndExit from "./tutorialGifs/pyv-save-and-exit.gif"
import PyvAutoTrackingComplete from "./tutorialGifs/pyv-auto-tracking-complete-and-review.gif"
import PyvLeftClickRightClick from "./tutorialGifs/pyv-left-click-set-right-click-finish.gif"
import PyvAutoTracking from "./tutorialGifs/pyv-auto-tracking.gif"
import PyvAddItem from "./tutorialGifs/pyv-add-item.gif"
import ClickAddPyvs from "./tutorialGifs/click-add-pyvs.gif"
import PyvAddAnotherPyv from "./tutorialGifs/pyv-add-another-pyv.gif"
import PyvEditDeleteAccessEditor from "./tutorialGifs/pyv-edit-delete-access-editor.gif"
import PyvEditDeleteSwitchToManualEditor from "./tutorialGifs/pyv-edit-delete-switch-to-manual-editor.gif"
import PyvEditDeleteSelectPyvClickDelete from "./tutorialGifs/pyv-edit-delete-select-pyv-click-delete.gif"
import PyvEditDeleteSelectPyvClickDeleteAll from "./tutorialGifs/pyv-edit-delete-select-pyv-click-delete-all.gif"
import PyvEditDeleteSelectPyvClickYes from "./tutorialGifs/pyv-edit-delete-select-pyv-click-yes.gif"
import PyvEditDeleteSelectPyvClickSaveAndExit from "./tutorialGifs/pyv-edit-delete-select-pyv-click-save-and-exit.gif"

const PyvVideo = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();

  const { editVideoAuthResponse }: any = useSelector(
    (state: RootState) => state?.entities?.editVideoAuth
  );

  const data = editVideoAuthResponse?.data;

  const { videoDetails } = useSelector(
    (state: RootState) => state?.entities?.videoDetail
  );

  const videoInfo = videoDetails?.data?.data?.response;

  useEffect(() => {
    dispatch(editVideoAuthCall.request({ data: { content_id: params?.id } }));
  }, []);

  const editVideo = async () => {
    if (videoInfo?.status !== 2 && videoInfo?.status !== 3) {
      Swal.fire({
        icon: "info",
        title: t("Video Encoding In Progress"),
        text: t(
          "Your video is currently being encoded, this can take several minutes, please try again later."
        ),
      });
      return;
    }
    try {
      let info = {
        content_id: params?.id,
        tig_edit_token: data?.data?.tig_edit_token,
        back_url: window.location,
      };
      redirectPost(
        window.location.origin?.startsWith("http://localhost") ||
          window.location.origin == "https://stage.pyvit.com"
          ? "https://cms.dev.pyvit.com/api/v1/tigedit/autotracking"
          : "https://cms.pyvitapp.com/api/v1/tigedit/autotracking",
        info
      );
    } catch (err) {
      console.error(err);
    }
  };

  const redirectPost = (url: any, data: any) => {
    let form = document.createElement("form");
    document.body.appendChild(form);
    form.method = "post";
    form.action = url;
    for (let name in data) {
      let input = document.createElement("input");
      input.type = "hidden";
      input.name = name;
      input.value = data[name];
      form.appendChild(input);
    }
    form.submit();
  };

  return (
    <>
      <Box sx={videoDetailStyle.pyvsContainer}>
        <StyledText>{t("Click below to add PYVs.")}</StyledText>
        {!editVideoAuthResponse?.isLoading && !editVideoAuthResponse?.isError ? (
          <IconButton sx={videoDetailStyle.addPyvButton} onClick={editVideo}>
            <AddIcon />
            <Info>{t("Add PYVs")}</Info>
          </IconButton>
        ) : editVideoAuthResponse?.isLoading ? (
          <CircularProgress size="20px" sx={{ mt: "25px" }} />
        ) : (
          <Info> Something went wrong </Info>
        )}
      </Box>
      <Box>
        <Accordion sx={{ borderBottom: 1, borderColor: THIN_BORDER_COLOR, boxShadow: 0 }}>
          <AccordionSummary expandIcon={<ExpandMore />} id="accordion-edit-title">
            <Typography variant="h6">Tutorial (click to view/hide)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack>
              <Typography sx={{ fontWeight: "bold", mt: 4 }}>Start to add PYVs (clickable pulsating blue dots)</Typography>
              <p>PYVs are pulsating blue dots you can tag items with in videos. Each PYV tracks the item in the
                video and, when clicked, allows a user to purchase the item or visit a website.</p>
              <p>Click the "Add PYVs" button to begin (return here to add more PYVs if desired).</p>
              <img src={ClickAddPyvs} style={{ maxWidth: 800, height: "auto" }} />

              <Typography sx={{ fontWeight: "bold", mt: 6 }}>Select starting point (required)</Typography>
              <p>Advance the video to the start of where the blue dot should appear.</p>
              <img src={PyvSelectStart} style={{ maxWidth: 800, height: "auto" }} />

              <Typography sx={{ fontWeight: "bold", mt: 6 }}>Attach a PYV area and product to the item being PYVed (required)</Typography>
              <p>A PYV area must be selected on the item in the video to allow tracking.</p>
              <p>Click the "Item" button,</p>
              <p>Click on the product to link to the PYV area,</p>
              <p>Move the square area to a spot on the item in the video,</p>
              <p>Resize the area as needed.</p>
              <img src={PyvAddItem} style={{ maxWidth: 800, height: "auto" }} />

              <Typography sx={{ fontWeight: "bold", mt: 6 }}>Set and save the PYV (required)</Typography>
              <p>Left-click the PYV area to set the product in the area,</p>
              <p>Right-click the PYV area,</p>
              <p>Click the "Finish" button to save the PYV.</p>
              <img src={PyvLeftClickRightClick} style={{ maxWidth: 800, height: "auto" }} />

              <Typography sx={{ fontWeight: "bold", mt: 6 }}>Start auto-tracking the PYV (optional)</Typography>
              <p>With the current PYV pulsating on the screen, click the "Start Auto Track" button,</p>
              <p>Click the "Yes" button to begin auto-tracking,</p>
              <p>Wait for auto-tracking to complete.</p>
              <img src={PyvAutoTracking} style={{ maxWidth: 800, height: "auto" }} />

              <Typography sx={{ fontWeight: "bold", mt: 6 }}>Complete and review auto-tracking (required for auto-tracking only)</Typography>
              <p>Click the "The result will be displayed when you reload" button,</p>
              <p>Click the play video icon to preview the saved PYV (the dotted lines will not be visible in the video when it's published).</p>
              <img src={PyvAutoTrackingComplete} style={{ maxWidth: 800, height: "auto" }} />

              <Typography sx={{ fontWeight: "bold", mt: 6 }}>Add more PYVs if desired (optional)</Typography>
              <p>Repeat the steps for adding a PYV.</p>
              <img src={PyvAddAnotherPyv} style={{ maxWidth: 800, height: "auto" }} />

              <Typography sx={{ fontWeight: "bold", mt: 6 }}>Save and exit the editor (required)</Typography>
              <p>When you are finished adding, changing, or removing PYVs, it's time to save and exit the editor.</p>
              <p>Click the "Save/Exit" button.</p>
              <p>You will be returned to your video Details tab page.</p>
              <img src={PyvSaveAndExit} style={{ maxWidth: 800, height: "auto" }} />

              <Divider sx={{ mt: 10 }} />

              <Typography sx={{ fontWeight: "bold", color: "warning.main", mt: 6 }}>The next set of instructions details how to delete or edit a PYV (optional)</Typography>

              <Typography sx={{ fontWeight: "bold", mt: 6 }}>Access the editor (required)</Typography>
              <p>Click the PYV video tab,</p>
              <p>Click the "Add PYVs" button.</p>
              <img src={PyvEditDeleteAccessEditor} style={{ maxWidth: 800, height: "auto" }} />

              <Typography sx={{ fontWeight: "bold", mt: 6 }}>Switch to PYVIT Manual Editor (required)</Typography>
              <p>Click the "PYVIT Manual Editor" button (the header and footer turn gray when accessed).</p>
              <img src={PyvEditDeleteSwitchToManualEditor} style={{ maxWidth: 800, height: "auto" }} />

              <Typography sx={{ fontWeight: "bold", mt: 6 }}>Select and delete the PYV (required)</Typography>
              <p>Find the PYV by advancing the video until the PYV appears,</p>
              <p>Right-click the PYV area and Click one of the following buttons:</p>
              <ul>
                <li>"Change size" allows you to change the size of the PYV area and PYV.</li>
                <li>"Change item" allows to set a different product to the PYV area.</li>
                <li>"Change overlap order" allows you to change which PYV overlaps the other if they cross paths.</li>
                <li>"Delete" allows you to delete the PYV area and PYV (click this one for this tutorial).</li>
              </ul>
              <img src={PyvEditDeleteSelectPyvClickDelete} style={{ maxWidth: 800, height: "auto" }} />
              <p>Click on one of the following buttons:</p>
              <ul>
                <li>"Delete all" allows to to delete the PYV area and PYV throughout the video (click this one for this tutorial)</li>
                <li>"Delete before this point" allows you to delete the PYV area and PYV from any time before this point in the video.</li>
                <li>"Delete after this point" allows you to delete the PYV area and PYV from any time after this point in the video.</li>
              </ul>
              <img src={PyvEditDeleteSelectPyvClickDeleteAll} style={{ maxWidth: 800, height: "auto" }} />
              <p>Click the "Yes" button.</p>
              <img src={PyvEditDeleteSelectPyvClickYes} style={{ maxWidth: 800, height: "auto" }} />

              <Typography sx={{ fontWeight: "bold", mt: 6 }}>Save and exit to finish (required)</Typography>
              <p>Click the "Save/Exit" button.</p>
              <img src={PyvEditDeleteSelectPyvClickSaveAndExit} style={{ maxWidth: 800, height: "auto" }} />
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default PyvVideo;
