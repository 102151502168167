export const styles = {
  outer: {
    paddingTop: "10px",
    flexDirection: {
      xs: "column",
      sm: "column",
      md: "row",
      lg: "row",
      xl: "row",
    },
  },
  fieldContain: { width: { xs: "100%", md: "100%", lg: "100%" } },
  label: { fontWeight: "500" },
  field: { color: "#969696", marginBottom: "8px" },
  checkOuter: { mt: "10px", flexDirection: "row", alignItems: "center" },
  checkText: {
    fontWeight: "500",
    fontSize: "0.9rem",
    display: "inline",
    fontFamily: "Jost",
  },
  checkError: {
    color: "red",
    marginLeft: "2px",
    display: "inline",
    fontSize: "0.8rem",
    pl: "14px",
    pb: "10px",
  },
  saveBtn: {
    mt: "10px",
    width: "fit-content",
    textTransform: "none",
  },
  addressOuter: { color: "grey", fontSize: "8px", lineHeight: "10px" },
  addressText: {
    fontSize: "14px !important",
    lineHeight: "22px !important",
    color: "grey !important",
  },
  stackStyle: { width: { xs: "100%", md: "50%" } },
  boxStyle: { padding: "10px 0" },
  checkBoxStyle: { p: 0, pr: "10px" },
  loderStyle: { color: "#fff", mr: "10px" },
  addressStack: {
    marginBottom: "10px",
    flexDirection: "row",
    alignItems: "center",
  },
  addressLoaderStyle: { maxWidth: "200px" },
  crossStyle: { width: "100%", display: "flex", justifyContent: "flex-end" },
  crossButtonStyle: { cursor: "pointer" },
  addressBox: { p: "10px 0" },
  updateAddressContain: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loaderStyle: { color: "#fff", mr: "10px" },
};
