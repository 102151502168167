export const styles = {
  fieldContain: { width: { xs: "100%", md: "100%", lg: "600px" } },
  label: { fontWeight: "500" },
  field: {
    marginBottom: "8px",
  },
  checkOuter: { mt: "10px", flexDirection: "row", alignItems: "center" },
  checkText: { fontWeight: "500", fontSize: "0.9rem", display: "inline" },
  checkError: {
    color: "red",
    marginLeft: "2px",
    display: "inline",
    fontSize: "0.8rem",
    pl: "14px",
    pb: "10px",
  },
  saveBtn: {
    mt: "10px",
    width: "fit-content",
    textTransform: "none",
  },
  checkboxStyle: { p: 0, pr: "10px" },
  alertBox: {
    marginTop: "18px",
    marginBottom: "18px",
    boxShadow: "0px 4px 15px 0px rgba(0,0,0,0.12)",
    display: "flex",
    flexWrap: "wrap",
    columnGap: "3px",
    padding: "18px 24px",
    borderBottom: "2px solid #FFA800",
  },
  loaderStyle: { color: "#fff", mr: "2px" },
};
