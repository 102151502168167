import {
  SCROLLBAR_THUMB,
  THEMEBACKGROUNDCOLOR,
  THIN_BORDER_COLOR,
} from "../../../colors";

export const styles = {
  loaderBox: {
    display: "flex",
    justifyContent: "center",
    height: "400px",
    alignItems: "center",
  },
  tableContainer: {
    height: "calc(100vh - 240px)",
    marginTop: "20px",
    table: {
      border: `1px solid ${THIN_BORDER_COLOR}`,
    },
    buttonBox: {
      display: "flex",
      flexDirection: "column",
      columnGap: "10px",
      rowGap: "10px",
    },
    descriptionBox: {
      textOverflow: "ellipsis",
      height: "40px",
      width: "175px",
      overflowX: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContents: "center",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: SCROLLBAR_THUMB,
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-track": {
        background: THEMEBACKGROUNDCOLOR,
        borderRadius: "4px",
      },
    },
    balanceBox: {
      textOverflow: "ellipsis",
      height: "40px",
      width: "125px",
      overflowX: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContents: "center",
      whiteSpace: "nowrap",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: SCROLLBAR_THUMB,
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-track": {
        background: THEMEBACKGROUNDCOLOR,
        borderRadius: "4px",
      },
    },
    titleBox: {
      textOverflow: "ellipsis",
      height: "65px",
      width: "100px",
      overflowX: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContents: "center",
      "&::-webkit-scrollbar": {
        width: "0px",
      },
    },
    deleteIconWrapper: {
      padding: "3px 4px",
      borderRadius: "45%",
      backgroundColor: "#E5E4E2",
    },
  },
  buttonBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  boxStyle: { width: "100%" },
  paperStyle: { width: "100%", mb: 2, padding: 0 },
  rowStyle: { cursor: "pointer" },
  deleteIconStyle: { color: "#818589", fontSize: "18px" },
  transactionText: {
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: { xs: "column", md: "row", lg: "row" },
    alignItems: { xs: "flex-start", md: "center", lg: "center" },
    columnGap: "3px",
  },
  rejectedChip: {
    backgroundColor: "#ff8080",
    color: "#fff",
    fontWeight: "bold",
  },
  approvedChip: {
    backgroundColor: "#009900",
    color: "#fff",
    fontWeight: "bold",
  },
  noRecord: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  smallscreenBreakpoint: {
    display: { xs: "none", md: "none", lg: "block" },
  },
  noDisplay: {
    display: "none"
  },
  largescreenBreakpoint: {
    display: { xs: "block", md: "block", lg: "none" }, 
  },
  accordionStyles: {
    padding: "0px",
    paddingBottom: "10px",
  },
  boxStyles: {
    margin: "10px",
    marginBottom: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
};
