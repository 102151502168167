import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { StyledText, SubTitle1, SubTitle2 } from "../../../utils/styledComponents";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../configureStore";
import dayjs from "dayjs";
import { analyticsStyles, summaryStyles } from "./style";
import { summaryBalance, summaryProfile } from "../../../actions";
import DatePickerValue from "../../../shared/components/FloatDateRangePicker";
import { useTranslation } from "react-i18next";
import { FloatLinearProgress } from "../../../shared/components/FloatLenearProgress";
import Loading from "../../../shared/Loading";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DateRangeGraphFilter from "../../../shared/components/DateRangeGraphFilter";
import { formatDateForPicker } from "./helper";
import FloatDateSelector from "../../../shared/components/FloatDateSelector";

function Summary() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref = useRef<any>();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDates, setSelectedDates]: any = useState(["", ""]);
  const [showDate, setShowDate] = useState("MM/DD/YYYY to MM/DD/YYYY");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    ref.current
  );

  const { videoDetails } = useSelector(
    (state: RootState) => state?.entities?.videoDetail
  );

  const { summaryProfileData }: any = useSelector(
    (state: RootState) => state?.entities?.summaryProfile
  );

  const { summaryBalanceData }: any = useSelector(
    (state: RootState) => state?.entities?.summaryBalance
  );

  const balanceSummary = summaryBalanceData?.data?.data;

  const [linksData, setLinksData] = useState<any>();
  const [balanceData, setBalanceData] = useState<any>();
  const [tooltipOpenCustomer, setTooltipOpenCustomer] = useState(false);
  const [tooltipOpenInfluencer, setTooltipOpenInfluencer] = useState(false);

  useEffect(() => {
    if (!summaryProfileData.isLoading) {
      const profile = summaryProfileData?.data || [];
      setLinksData(profile);
    }
  });

  useEffect(() => {
    if (!summaryBalanceData.isLoading) {
      const balance = summaryBalanceData?.data || [];
      setBalanceData(balance);
    }
  });

  const videoInfo = videoDetails?.data?.data?.response;

  const balanceInfo = [
    {
      key: "Referrals",
      value: balanceSummary?.referrals ?? "0",
      isLoading: summaryBalanceData?.isLoading,
      prefixSymbol: "",
      postfixSymbol: "",
    },
    {
      key: "Orders",
      value: balanceSummary?.orders ?? "0",
      isLoading: summaryBalanceData?.isLoading,
      prefixSymbol: "",
      postfixSymbol: "",
    },
    {
      key: "Conversions",
      value: balanceSummary?.conversions
        ? parseFloat(balanceSummary?.conversions)?.toFixed(2)
        : "0",
      isLoading: summaryBalanceData?.isLoading,
      prefixSymbol: "",
      postfixSymbol: "%",
    },
    {
      key: "Sales",
      value: balanceSummary?.sales
        ? parseFloat(balanceSummary?.sales)?.toFixed(2)
        : "0",
      isLoading: summaryBalanceData?.isLoading,
      prefixSymbol: "$",
      postfixSymbol: "",
    },
    {
      key: "Earnings",
      value: balanceSummary?.earning
        ? parseFloat(balanceSummary?.earning)?.toFixed(2)
        : "0",
      isLoading: summaryBalanceData?.isLoading,
      prefixSymbol: "$",
      postfixSymbol: "",
    },
  ];

  const onSelectDate = () => {
    setAnchorEl(ref?.current);
    setOpenDatePicker(true);
  };

  const handleDateFilter = () => {
    setShowDate(
      `${selectedDates[0]?.format("YYYY/MM/DD")} to ${selectedDates[1]?.format(
        "YYYY/MM/DD"
      )}`
    );

    const startTime = formatDateForPicker(
      selectedDates[0]?.format("YYYY/MM/DD")
    );
    const endTime = formatDateForPicker(selectedDates[1]?.format("YYYY/MM/DD"));

    dispatch(
      summaryBalance.request({
        startDate: selectedDates[0]?.format("YYYY/MM/DD"),
        endDate: selectedDates[1]?.format("YYYY/MM/DD"),
        // startTime: startTime,
        // endTime: endTime,
      })
    );
    setOpenDatePicker(false);
  };

  useEffect(() => {
    const currentDate = new Date();
    const now = new Date(); 
    const currentYear = now.getFullYear();  // Get the current year 
    const yearStartDate = new Date(currentYear, 0, 1);

    const startDate = formatPayloadDate(yearStartDate);
    const endDate = formatPayloadDate(currentDate);

    dispatch(
      summaryBalance.request({
        startDate: `${startDate.split("+")[2]}-${startDate.split("+")[0]}-${
          startDate.split("+")[1]
        }`,
        endDate: `${endDate.split("+")[2]}-${endDate.split("+")[0]}-${
          endDate.split("+")[1]
        }`,
        // startTime: startDate,
        // endTime: endDate,
      })
    );

    setShowDate(
      `${formatInitialDate(yearStartDate)} to ${formatInitialDate(currentDate)}`
    );
  }, []);

  function formatPayloadDate(date: Date) {
    const month = date.toLocaleString("default", { month: "short" });
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}+${day}+${year}+00:00:00+GMT+0530+(India+Standard+Time)`;
  }

  function formatInitialDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${month}/${day}/${year}`;
  }

  const handleCopyLink = async (link: string, type: string) => {
    try {
      await navigator.clipboard.writeText(link);
      if (type === "customer") {
        setTooltipOpenCustomer(true);
        setTimeout(() => {
          setTooltipOpenCustomer(false);
        }, 2000);
      } else if (type === "influencer") {
        setTooltipOpenInfluencer(true);
        setTimeout(() => {
          setTooltipOpenInfluencer(false);
        }, 2000);
      }
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  useEffect(() => {
    dispatch(summaryProfile.request({}));
  }, []);

  const totalVisits =
    balanceSummary?.traffic?.traffic.reduce(
      (sum: any, data: any) => sum + data.visits,
      0
    ) || 0;

  return (
    <Grid>
      {/* <Box sx={summaryStyles.container}>
        <DatePickerValue
          value={selectedDates}
          onChange={handleDateChange}
        />
      </Box> */}
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          mb: "10px",
          mt: "10px",
        }}
      >
        <Button
          ref={ref}
          onClick={() => onSelectDate()}
          sx={{
            width: "250px",
            padding: "10px 6px",
            borderBottom: "2px solid #333",
            borderRadius: "0",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.10)",
          }}
        >
          <StyledText sx={{ textTransform: "none" }}>{showDate} </StyledText>
          &nbsp;
          <ArrowDropDownIcon />
        </Button>
      </Stack>
  
              <FloatDateSelector
          open={openDatePicker}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          handleDateFilter={handleDateFilter}
        />
      <Box sx={analyticsStyles.boxBG}>
        <Grid container spacing={2}>
          {balanceInfo &&
            balanceInfo.map((item) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"86px"}
                    sx={analyticsStyles.whiteBG}
                  >
                    <SubTitle1>
                      {item.isLoading ? (
                        <CircularProgress size="12px" />
                      ) : (
                        item.prefixSymbol +
                        item.value?.toString() +
                        item.postfixSymbol
                      )}
                    </SubTitle1>
                    <SubTitle2>{t(item.key)}</SubTitle2>
                  </Stack>
                </Grid>
              );
            })}
        </Grid>
      </Box>
      <Grid sx={summaryStyles.balacesOuterGrid}>
        <Box sx={summaryStyles.balacesOuterGrid.progressCard}>
          <Box>
            <Typography sx={summaryStyles.balacesOuterGrid.balance}>
              {summaryBalanceData?.isLoading ? (
                <CircularProgress size={"14px"} />
              ) : (
                balanceSummary?.traffic?.total_visits ?? "0"
              )}
            </Typography>
            <StyledText>{t("Traffic")}</StyledText>
          </Box>
          {summaryBalanceData.isLoading ? (
            <Box sx={summaryStyles.loading}>
              <Loading />
            </Box>
          ) : (
            <Box sx={summaryStyles.balacesOuterGrid.trafficCard}>
              {balanceSummary?.traffic &&
                balanceSummary?.traffic?.traffic.map((data: any) => {
                  const percentage: any = Math.round(
                    (data.visits / totalVisits) * 100
                  );

                  return (
                    <Box textAlign={"left"} mb={"10px"}>
                      <Box sx={summaryStyles.flexStyle}>
                        <StyledText>{data.hostname}</StyledText>
                        <StyledText> {percentage}% </StyledText>
                      </Box>
                      <FloatLinearProgress
                        variant="determinate"
                        value={percentage}
                      />
                    </Box>
                  );
                })}
            </Box>
          )}
        </Box>
        <Grid sx={summaryStyles.balacesOuterGrid.productBalanceCard}>
          <Typography sx={summaryStyles.balacesOuterGrid.balance}>-</Typography>
          <StyledText>{t("Product sold")}</StyledText>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Summary;
