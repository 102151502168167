import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { actions } from "../../actions/Types";
import { FAILURE, RESET } from "../../actions/actiontypes";
import { RootState } from "../../configureStore";
import { toast } from "react-toastify";
import { t } from "i18next";

const { SUCCESS, REQUEST, NOMAD_CREATE_ACCOUNT } = actionTypes;

const intialState = {
  isLoading: true,
  isError: false,
  data: {},
  errorMessage: "",
};

const entity = () => {
  const nomadCreateData = (state = intialState, action: actions) => {
    switch (action.type) {
      case NOMAD_CREATE_ACCOUNT[SUCCESS]:
        toast.success(t("eWallet Account Created Successfully"));
        return { ...state, data: action.payload, isLoading: false };
      case NOMAD_CREATE_ACCOUNT[REQUEST]:
        return action?.payload
          ? { ...state, data: action.payload, isLoading: false }
          : state;
      case NOMAD_CREATE_ACCOUNT[FAILURE]:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          isError: true,
        };
      case NOMAD_CREATE_ACCOUNT[RESET]:
        return intialState;
      default:
        return state;
    }
  };

  return combineReducers({
    nomadCreateData,
  });
};

export default entity;

export const createNomad = (state: RootState) => state.entities.createNomad;
