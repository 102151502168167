import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer(),
  middleware: () => [sagaMiddleware],
  devTools: true,
});

sagaMiddleware.run(rootSaga);

const storeState = store.getState;
export type RootState = ReturnType<typeof storeState>;

export default store;
