import { Stack } from "@mui/material";
import React from "react";
import {
  CartesianGrid,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useTranslation } from "react-i18next";

export interface ChartBarType {
  name: string;
  dataKey: string;
  fill: string;
}
interface BarChartProps {
  Xaxis: {
    dataKey: string;
  };
  ChartData: any[];
  ChartBars?: ChartBarType[];
  max?: number | null;
}

const FloatBarChart: React.FC<BarChartProps> = ({
  Xaxis,
  ChartData,
  ChartBars,
  max,
}) => {
  const { t } = useTranslation();

  const GradientBar = (props: any) => {
    const { fill, x, y, width, height } = props;
    return (
      <g>
        <defs>
          <linearGradient id="barFill1" x1="0" y1="0" x2="0" y2="1">
            {" "}
            <stop offset="5%" stopColor="#000" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={1} />
          </linearGradient>
        </defs>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill="url(#barFill1)"
          rx={5}
          ry={5}
        />
      </g>
    );
  };

  const CustomLegend = (props: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        {ChartBars?.map((item: any) => {
          return (
            <Stack direction={"row"} alignItems={"center"}>
              <RadioButtonCheckedIcon
                sx={{
                  color: item.stroke,
                  marginRight: "5px",
                  marginLeft: "5px",
                }}
              />
              <span>{t(item.name)}</span>
            </Stack>
          );
        })}
      </div>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={ChartData}
        margin={{ top: 5, right: 20, left: 5, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis
          tickLine={false}
          style={{ stroke: "#333" }}
          dataKey={Xaxis.dataKey}
          interval={0}
          tick={{ fontSize: 12, fontWeight: 400, fontFamily: "Jost", dx: -5 }}
        />
        <YAxis
          interval="equidistantPreserveStart"
          tickCount={6}
          axisLine={{ stroke: "black" }}
          tickLine={false}
          style={{ stroke: "#333" }}
          domain={max ? [0, max + 10] : [0, "dataMax + 10"]}
          tick={{ fontSize: 12, fontFamily: "Jost", fontWeight: 400, dx: -5 }}
        />
        <Tooltip />
        <Legend verticalAlign="top" content={CustomLegend} />
        {ChartBars?.map((item: ChartBarType, index: number) => {
          return (
            <Bar
              key={index}
              name={item.name}
              dataKey={item.dataKey}
              fill={item.fill}
              shape={<GradientBar />}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default FloatBarChart;
