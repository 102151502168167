import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

interface IProps {
  sx?: any;
  onChange: any;
  isNoWrap?: any;
  value: any;
  isView?: any;
  max30Days?: boolean;
}

const GraphDateRangePicker = ({
  sx,
  onChange,
  isNoWrap,
  isView,
  max30Days,
  value,
  ...restProps
}: IProps) => {
  const { t } = useTranslation();
  const today = dayjs();
  const yesterday = dayjs().subtract(1, "day");

  const [value1, setValue1] = React.useState<any>();
  const [value2, setValue2] = React.useState<any>();
  const [maxDate, setMaxDate] = React.useState<any>();

  React.useEffect(() => {
    if (value) {
      setValue1(value[0]);
      setValue2(value[1]);
    }
  }, [value]);

  const handleDateChange1 = (newValue: any) => {
    setValue1(newValue);
    onChange([newValue, value2]);
    const maxDate = newValue ? dayjs(newValue).add(29, "day") : undefined;
    const maxlimit = today;
    setMaxDate(
      newValue ? (maxlimit.isBefore(maxDate) ? maxlimit : maxDate) : undefined
    );
  };

  const handleDateChange2 = (newValue: any) => {
    setValue2(newValue);
    onChange([value1, newValue]);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          margin: "10px",
          columnGap: "20px",
          rowGap: "20px",
          flexWrap: isNoWrap ? "no-wrap" : "wrap",
          justifyContent: "flex-end",
          overflowX: "auto",
        }}
        className="overflow-x-contain"
      >
        <DatePicker
          label={t("Start date")}
          // value={value1}
          maxDate={ today }
          onChange={handleDateChange1}
          sx={{
            borderColor: "grey",
            mb: "0px !important",
            width: "150px",
            margin: "5px",
          }}
        />
        <DatePicker
          disabled={!value1}
          label={t("End date")}
          // value={value2}
          onChange={handleDateChange2}
          minDate={value1}
          maxDate={max30Days ? maxDate : today}
          sx={{
            mb: "0px !important",
            width: "150px",
            margin: "5px",
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default GraphDateRangePicker;
