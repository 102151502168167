import React from "react";

const PageNotfound = () => {
  return (
    <div>
      <h1> Page Not Found </h1>
    </div>
  );
};

export default PageNotfound;
