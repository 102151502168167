import React from "react";
import {
  StyledTableCell,
  StyledTableRow,
  StyledText,
} from "../../../utils/styledComponents";
import { styles } from "../styles";
import { Box } from "@mui/material";
import CreatorLibraryActionRow from "./CreatorLibraryActionRow";
import { useTranslation } from "react-i18next";
import { creatorLibraryStyle } from "./style";
import { convertToPlainText } from "./helper";
import CreatorLibraryProductsRow from "./CreatorLibraryProductsRow";


const CreatorLibraryTblRow = ({
  row,
  index,
  multilistInfo,
  onPreview,
}: any) => {
  const { t } = useTranslation();

  const visibleColumn = (colName: string) => {
    return multilistInfo.map((item: any) => t(item))?.includes(t(colName));
  };
  return (
    <>
      <StyledTableRow key={index} role="checkbox" sx={styles.rowStyle}>
        <StyledTableCell align="left">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "start",
              width: "100%",
            }}
          >
            <Box
              display={"flex"}
              sx={{
                minWidth: "130px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={row.thumbnail}
                style={{
                  height: "80px",
                  width: "auto",
                  maxWidth: "130px",
                }}
                alt=""
                onClick={(e: any) => onPreview(e, row)}
              />
            </Box>
          </Box>
        </StyledTableCell>
        {visibleColumn("Title") && (
          <StyledTableCell align="left">
            <StyledText sx={styles.tableContainer.titleBox}>
              {row.title || "-"}
            </StyledText>
          </StyledTableCell>
        )}
        {visibleColumn("Creator handle") && (
          <StyledTableCell align="left">
            {row.creatorHandle || "-"}
          </StyledTableCell>
        )}
        {visibleColumn("Description") && (
          <StyledTableCell align="left">
            <Box
              className="overflow-y"
              sx={{ height: "100%",minWidth:"150px",  maxWidth: "250px" }}
            >
              <Box
                sx={{
                  fontFamily: "Jost",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                {row.description ? convertToPlainText(row.description) : "-"}
              </Box>
            </Box>
          </StyledTableCell>
        )}
        {visibleColumn("Collection") && (
          <>
            {row.collections?.length > 0 ? (
              <StyledTableCell align="left" sx={creatorLibraryStyle.tablecell}>
                {row.collections.join(", ")}
              </StyledTableCell>
            ) : (
              <StyledTableCell align="left">-</StyledTableCell>
            )}
          </>
        )}
        {visibleColumn("Content id") && (
          <StyledTableCell align="left">{row.id}</StyledTableCell>
        )}
      </StyledTableRow>
      <CreatorLibraryProductsRow row={row} />
      <CreatorLibraryActionRow row={row} />
    </>
  );
};

export default CreatorLibraryTblRow;
