import { TABLEHEADBACKGROUNDSHADE2 } from "../../../colors";

export const videoListStyles = {
  actionOuter: {
    display: "flex",
    columnGap: "10px",
    alignItems: "center",
    width: "fit-content",
  },
  activateBtn: {
    textTransform: "capitalize",
    width: "75px",
    minWidth: "75px !important",
    borderRadius: "4px 0 0 4px !important",
    height:"34px",
  },
  pauseBtn: {
    textTransform: "capitalize",
    width: "75px",
    minWidth: "75px !important",
    borderRadius: "0 4px 4px 0 !important",
    height:"34px",
  },
  editBtn: {
    textTransform: "capitalize",
    width: "fit-content",
  },
  accordianSummary: {
    padding: "0px !important",
    ".MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "center",
      margin:"0px !important"
    },
    ".Mui-expanded":{
      margin:"0px !important"
    }
  },
  mobileThumbnailbox: {
    display: "flex",
    width: "74px",
    alignItems: "center",
    justifyContent: "center",
  },
  mobileTitleCell: {
    width: "inherit !important",
    padding: "8px 4px !important",
  },
  mobileActionCell: { padding: "8px 4px !important" },
  mobileAcitonBox: {
    display: "flex",
    columnGap: "10px",
    alignItems: "center",
  },
  mobileActivateBtn: {
    textTransform: "capitalize",
    width: "75px",
    minWidth: "65px !important",
    borderRadius: "4px 0 0 4px !important",
    maxHeight:"32px",
  },
  mobilePauseBtn: {
    textTransform: "capitalize",
    width: "70px",
    minWidth: "55px !important",
    borderRadius: "0 4px 4px 0 !important",
    maxHeight:"32px",
  },
  analyticsRow: {
    rowStyle: { backgroundColor: "#EEEEEE" },
    containerDesktop: { display: "flex", flexDirection: "row" },
    tableCell: {
      padding: "5px 12px !important",
      alignItems: "center",
    },
    mobileScrollOuter: {
      width: "calc(100vw - 50px) !important",
      overflowX: "auto",
    },
    wrapBox: { width: "fit-content", display: "flex", flexWrap: "wrap" },
    flexBox: { width: "fit-content", display: "flex" },
    infoBox: { mr: "8px", padding: "3px", justifyContent: "center" },
    infoMobile: { width: "max-content", fontSize: "0.75rem  !important" },
    scrollIndicatorBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      columnGap: "3px",
      marginTop: "6px",
    },
  },
  scrollIndicatorBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "3px",
    marginTop: "6px",
  },
  productsRowStyles:{
   rowStyle:{ backgroundColor: "#F7F7F7" },
   productRow:{maxWidth:"calc(100vw - 340px) !important"},
   productRowinner:{width:"max-content",flexDirection:"row"},
   rowTitles:{display:"flex",padding:"0 10px"},
   valuesRow:{display:"flex",padding:"0 10px",maxWidth:"170px"},
   productName:{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",panddingBottom:"4px"},
   venderName:{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}
  },
  flexBox:{ display: "flex" },
  headRow:{ display: "flex", justifyContent: "space-between" },
  cursor:{cursor:"pointer"},
  buttonStyles:{
    textTransform: "none",
    marginBottom: "15px",
    padding: "5px 30px",
  },
  flexProp:{
    alignItems: "center",
    justifyContent: "center",
  },
  imgStyle:{
    height: "80px",
    width: "auto",
    maxWidth: "130px",
  },
  textStyle:{ whiteSpace: "nowrap" },
  accordionStyle:{ padding: "0px !important",marginBottom:"0 !important",boxShadow:"none" },
  cellStyle:{ padding: "8px 4px !important" },
  flexBoxStyle:{
    display: "flex",
    mb: "5px",
  },
  flexStyle:{
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  buttonStyle:{
    textTransform: "capitalize",
    width: "55px",
    minWidth: "55px !important",
    mr: "10px",
  },
  tooltipBox:{ mr: "10px", display: "flex" },
  gridStyle:{
    display: "flex",
    alignItems: "center",
  },
  textwrap:{ whiteSpace: "nowrap" },
  productTblStyle :{
    rowPadding: { padding: "5px 10px" },
    rowValue: {
      width: "150px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    header: {
      padding: "10px",
      backgroundColor: TABLEHEADBACKGROUNDSHADE2,
      fontWeight: "bold",
      fontSize: "16px",
      fontFamily: "Jost",
    },
    linkStyle: {
      flexDirection: "row",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
    },
    linkText: { fontSize: "16px", fontWeight: "bold" },
    infoStyle:{pl:"10px"}
  }
};
