import styled from "styled-components";
import { THIN_BORDER_COLOR } from "../../../../colors";

export const styles = {
  alertBox: {
    marginTop: "18px",
    marginBottom: "18px",
    boxShadow: "0px 4px 15px 0px rgba(0,0,0,0.12)",
    display: "flex",
    flexWrap: "wrap",
    columnGap: "3px",
    padding: "18px 24px",
  },
  linearProgress: {
    height: 8,
    borderRadius: 5,
    marginX: 3,
    color: "grey !important",
    background: "linear-gradient(to right, #d4d3d3, #0c0c0c)",
    m: "5px 0 0 0",
  },
  countCards: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "180px",
    borderBottom: { xs: `1px solid ${THIN_BORDER_COLOR}`, md: "none" },
    marginBottom: "10px",
  },
  cordTitleConatiner: {
    padding: "5px 0 4px 0",
    width: "100%",
    borderBottom: "1px solid #C7C7C7",
    marginBottom: "10px",
  },
  planText: { justifyContent: "space-between", color: "#444" },
  stats: {
    marginTop: "10px",
    width: "100%",
    minHeight: "170px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  progressText: { fontWeight: "500", fontSize: "1rem" },
  gridStyle: { marginBottom: "20px" },
  buttonStyle: {
    borderRadius: "5px",
    textTransform: "none",
    fontFamily: "Jost",
  },
  boxStyle: { width: "100%", height: "100%" },
  textStyle: { fontSize: "16px" },
};

export const RowBox = styled.div<any>`
  background-color: #fff;
  color: #333;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

export const CardDiv = styled.div`
  min-height: 238px;
  padding: 0 15px 10px 15px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${THIN_BORDER_COLOR};
`;

export const Row50 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;

export const Row100 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const CountsCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 180px;
  border-bottom: 1px solid grey; /* Apply the specific style for each breakpoint if needed */
  margin-bottom: 10px;

  @media (min-width: 768px) {
    /* Apply styles for medium-sized screens (md) */
    border-bottom: none;
  }
`;
