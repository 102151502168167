import {
  HOVERPRIMARYTEXTCOLOUR,
  SECONDARYCOLOUR,
  SIDENAV_HOVER_COLOR,
  SIDENAV_SELECTEDROUTE_BG,
  SIDENAV_SELECTEDSUBROUTE_BG,
  SIDENAV_SELECTED_TEXT,
} from "../colors";

export const styles = {
  container: {
    height: "100vh",
    display: "flex",
  },
  navContainer: {
    pt: { xs: 0, md: 5 },
    pb: { xs: 0, md: 5 },
    p: { xs: "0 5px 0 5px", md: "0px 0 0 0" },
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.10)",
    width: { xs: "180px" },
    display: { xs: "none", md: "block" },
  },
  outletContainer: {
    height: "100%",
    overflowY: "auto",
    flexGrow: 1,
    padding: { xs: "5px 0px", md: "10px" },
  },
  list: {
    width: "100%",
    maxWidth: 360,
    minWidth: "180px",
    bgcolor: "background.paper",
    p: 0,
  },
  subRouteText: {
    ".MuiListItemText-primary": {
      fontFamily: "Jost",
      fontSize: "0.9rem",
      lineHeight: 1.6,
    },
  },
  listBtn: {
    "&:hover": {
      backgroundColor: SECONDARYCOLOUR,
      color: HOVERPRIMARYTEXTCOLOUR,
      fontFamily: "Jost",
      fontSize: "0.95rem",
      lineHeight: 1.6,
    },
  },
  listBtnSelected: {
    "&:hover": {
      backgroundColor: SECONDARYCOLOUR,
      color: HOVERPRIMARYTEXTCOLOUR,
      fontFamily: "Jost",
      fontSize: "0.95rem",
      lineHeight: 1.6,
    },
    color: SIDENAV_SELECTED_TEXT,
    backgroundColor: SIDENAV_SELECTEDROUTE_BG,
    fontFamily: "Jost",
    fontSize: "0.95rem",
    lineHeight: 1.6,
  },
  listContainer: { height: "calc(100% - 80px)" },
  slectedRouteBtn: {
    fontFamily: "Jost",
    p: "8px 5px 8px 30px",
    fontWeight: "bold",
    textOverflow: "ellipsis",
    fontSize: "0.95rem",
    lineHeight: 1.6,
    backgroundColor: SIDENAV_SELECTEDSUBROUTE_BG,
    color: SIDENAV_SELECTED_TEXT,
    "&:hover": {
      backgroundColor: SIDENAV_HOVER_COLOR,
      color: SIDENAV_SELECTED_TEXT,
      textOverflow: "ellipsis",
      fontSize: "0.95rem",
      lineHeight: 1.6,
    },
  },
  subrouteBtn: {
    p: "8px 5px 8px 30px",
    color: "#333",
    borderBottom: "1px solid #E5E4E2",
    fontFamily: "Jost",
    textOverflow: "ellipsis",
    fontSize: "0.95rem",
    lineHeight: 1.6,
  },
  drawerContainer: {
    display: { xs: "block", sm: "block", md: "none" },
    pt: "15px",
    borderRight: "1px solid #c2c5c2",
  },
  textStyle: {
    fontWeight: "bold",
    textOverflow: "ellipsis",
    fontFamily: "Jost",
    fontSize: "1.1rem",
  },
  boxStyle: { width: 220 },
  menuIconStyle: { fontSize: "40px" },
  stackStyle: { minHeight: "40px", alignItems: "flex-start", padding: "10px" },
  greetingStyle: {
    fontWeight: "bold",
    textOverflow: "ellipsis",
    fontFamily: "Jost",
    fontSize: "1rem",
    lineHeight: 1.6,
    wordBreak: "break-all",
  },
  containerStyle: {
    padding: {
      xs: "0px",
      sm: "0 20px 10px",
      md: "0 20px 10px",
      lg: "0 20px 20px",
    },
  },
  languageWrapper: {
    display: "flex",
    justifyContent: "end",
    mt: "15px",
    languageContainer: {
    },
  },
  containerStyles: {
    padding: { xs: "0", md: "0 20px" },
    maxWidth: "1700px !important",
  },
};

export const stylesMobile = {
  mainContain: {
    padding: { xs: "0", md: "0 20px" },
    maxWidth: "1700px !important",
  },
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  navContainer: {},
  listContainer: { display: "flex", flexDirection: "row", padding: "10px" },
  outletContainer: {
    height: "100%",
    overflowY: "auto",
    flexGrow: 1,
    padding: { xs: "5px 10px 5px 10px", md: "10px" },
  },
  containerStyle: {
    padding: {
      xs: "0px",
      sm: "0 20px 10px",
      md: "0 40px 10px",
      lg: "0 20px 20px",
    },
  },
  menuButton: {
    width: "fit-content",
    height: "45px",
    padding: "0 12px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    whiteSpace:"nowrap"
  },
  languageWrapper: {
    display: "flex",
    justifyContent: "end",
    mt: "15px",
    languageContainer: {
    },
  },
};

export const drawerStyle = {
  rightAlign: {
    height: "60px",
    width: "-webkit-fill-available",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 10px",
  },
};
