import { call, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import * as ApiService from "../services";
import { sendPayload, sendPayloadFailure } from "./helper";
const { REQUEST, INFLUENCER_NETWORK, INFLUENCER_NETWORK_FILTER } = actionTypes;

//write sagas function

function* influencerNetworkSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getInfluencerNetworkDetails,
      data.data
    );
    yield sendPayload(apiResponse, INFLUENCER_NETWORK);
  } catch (e) {
    yield sendPayloadFailure(e, INFLUENCER_NETWORK);
  }
}

function* influencerNetworkFilterSaga(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getInfluencerNetworkDetails,
      data.data
    );
    yield sendPayload(apiResponse, INFLUENCER_NETWORK_FILTER);
  } catch (e) {
    yield sendPayloadFailure(e, INFLUENCER_NETWORK_FILTER);
  }
}

export const sagas = {
  watchInfluencerNetworkSaga: takeLatest(
    actionTypes.INFLUENCER_NETWORK[REQUEST],
    influencerNetworkSaga
  ),
};

export const sagas2 = {
  watchFilterableNetworkSaga: takeLatest(
    actionTypes.INFLUENCER_NETWORK_FILTER[REQUEST],
    influencerNetworkFilterSaga
  ),
};
