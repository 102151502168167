import { Box, CircularProgress, Table, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { THIN_BORDER_COLOR } from '../../../colors'
import { StyledTableCell, StyledTableHeadCell, StyledTableHeadRow, StyledTableRow, StyledText } from '../../../utils/styledComponents'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionDetails } from '../../../actions';
import { RootState } from '../../../configureStore';
import { convertDateFromServer, convertTimeFromServer } from '../../../utils/hooks';
import { TransactionConstants } from '../../../utils/constants';
import { styles } from './style';
import TransactionOrdersModal from './TransactionOrdersModal';
import FloatModal from '../../../shared/components/FloatModal';

interface IProps {
    entityId: number
}

interface Metadata {
    reward_data: string;
    reward_created: string;
    reward_type: string;
    new_status: string;
    new_amount: number;
    old_status: string;
    old_amount: number;
    order_number: string;
    order_value: string;
    commission_on: string;
    downline: Downline;
    old_affiliate_commission: number;
    new_affiliate_commission: number;
}

interface Downline {
    name: string;
    affiliate_id: number;
}

interface ITransaction {
    amount: string;
    currency: string;
    event_type: string;
    entity_type: string;
    entity_id: number;
    metadata: Metadata;
    is_paid: number;
    created_at: string;
    endingBalance: string;
    startingBalance: string;
}

const TransactionDetailsModal = ({ entityId }: IProps) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isMobile = useMediaQuery("(max-width:900px)");

    const { transactionDetailsData } = useSelector(
        (state: RootState) => state?.entities?.transactionData
    ) as any;

    const [transactionDetails, setTransactionDetails] = useState<ITransaction[]>([])
    const [isError, setIsError] = useState<boolean>(false);
    const [selectedTransaction, setSelectedTransaction] = useState<string>();
    const [openTransactionModal, setOpenTransactionModal] =
        useState<boolean>(false);

    useEffect(() => {
        dispatch(getTransactionDetails.request(entityId))
    }, [])

    useEffect(() => {
        if (!transactionDetailsData?.isLoading && !transactionDetailsData?.isError)
            transactionDetails && setTransactionDetails(transactionDetailsData?.data?.transactions)
        if (!transactionDetailsData?.isLoading && transactionDetailsData?.isError)
            setIsError(true)
    }, [transactionDetailsData?.isLoading])

    const handleColor = (amount: string) => {
        const value = Number(amount)
        if (value <= 0)
            return "red"
        else
            return "green"
    }

    const handleOpenTransactionModal = (data: ITransaction) => {
        if (
            data &&
            data.entity_id
        ) {
            setOpenTransactionModal(true);
            setSelectedTransaction(data.metadata.order_number);
        }
    };

    const handleCloseTransactionModal = () => {
        setOpenTransactionModal(false);
    };

    return (
        <Box
            sx={{
                paddingRight: isMobile ? "0px" : "30px"
            }}
        >
            {
                transactionDetailsData.isLoading ?
                    <Box sx={styles.noRecord}>
                        <CircularProgress />
                    </Box>
                    : (isError || transactionDetails && transactionDetails.length < 1) ?
                        (<StyledText textAlign="center">{t("No data.")}</StyledText>)
                        :
                        (
                            <Table
                                sx={{
                                    m: isMobile ? "0px" : "16px",
                                    border: `1px solid ${THIN_BORDER_COLOR}`,
                                }}
                            >
                                <StyledTableHeadRow>
                                    <StyledTableHeadCell>
                                        <StyledText fontWeight="bold"> {t("Date")}</StyledText>
                                    </StyledTableHeadCell>
                                    <StyledTableHeadCell>
                                        <StyledText fontWeight="bold">{t("Transaction")}</StyledText>
                                    </StyledTableHeadCell>
                                    <StyledTableHeadCell>
                                        <StyledText fontWeight="bold">{t("Amount")}</StyledText>
                                    </StyledTableHeadCell>
                                    <StyledTableHeadCell>
                                        <StyledText fontWeight="bold">{t("Final balance")}</StyledText>
                                    </StyledTableHeadCell>
                                </StyledTableHeadRow>
                                {
                                    transactionDetails.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell sx={{ whiteSpace: "nowrap" }}>
                                                {
                                                    isMobile ? (
                                                        row.created_at ? (
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    flexDirection: "column"
                                                                }}
                                                            >
                                                                {convertDateFromServer(row.created_at)}
                                                                <br />
                                                                {convertTimeFromServer(row.created_at)}
                                                            </Box>
                                                        ) : (
                                                            "-"
                                                        )
                                                    ) : (
                                                        row.created_at ? (
                                                            `${convertDateFromServer(row.created_at)} ${convertTimeFromServer(row.created_at)}`
                                                        ) : (
                                                            "-"
                                                        )
                                                    )
                                                }
                                            </StyledTableCell>

                                            <StyledTableCell>

                                                {
                                                    row.entity_type === TransactionConstants.PAYOUT &&
                                                    <StyledText fontvalue="14px" lineheight="18px">
                                                        {t("Payout")}
                                                    </StyledText>
                                                }

                                                {
                                                    (row.event_type === TransactionConstants.INSERT && row.entity_type === TransactionConstants.ORDERS) &&
                                                    <StyledText fontvalue="14px" lineheight="18px" color="#4158E3" onClick={() => handleOpenTransactionModal(row)} sx={{ cursor: "pointer" }}>
                                                        {row.metadata.order_number}
                                                    </StyledText>
                                                }

                                                {
                                                    (row.event_type === TransactionConstants.UPDATE && row.entity_type === TransactionConstants.REWARDS) &&
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: isMobile ? "column" : "row",
                                                            columnGap: "5px"
                                                        }}
                                                    >
                                                        <StyledText fontvalue="14px" lineheight="18px" color="#ff6000">
                                                            {t("Network commission")}
                                                        </StyledText>
                                                        <StyledText fontvalue="14px" lineheight="18px" color="#ff6000">
                                                            {row.metadata.order_number}
                                                        </StyledText>
                                                    </Box>
                                                }

                                                {
                                                    (row.event_type === TransactionConstants.INSERT && row.entity_type === TransactionConstants.REWARDS) &&
                                                    <>
                                                        <StyledText fontvalue="14px" lineheight="18px">
                                                            {("Network commission")}
                                                        </StyledText>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: isMobile ? "column" : "row",
                                                                columnGap: "5px"
                                                            }}
                                                        >
                                                            <StyledText>
                                                                {row.metadata.order_number}
                                                            </StyledText>
                                                            <StyledText>
                                                                (${row.metadata.order_value})
                                                            </StyledText>
                                                        </Box>
                                                    </>
                                                }

                                                {
                                                    (row.event_type === TransactionConstants.UPDATE && row.entity_type === TransactionConstants.ORDERS) &&
                                                    <>
                                                        <StyledText fontvalue="14px" lineheight="18px" color="#ff6000">
                                                            {row.metadata.order_number}
                                                        </StyledText>
                                                        <StyledText
                                                            fontvalue="14px"
                                                            lineheight="18px"
                                                            sx={styles.transactionText}
                                                        >
                                                            {"Commission"}: {row.metadata?.old_affiliate_commission}
                                                            {" - "}
                                                            {row.metadata?.new_affiliate_commission}
                                                        </StyledText>
                                                    </>
                                                }

                                                {row.metadata?.new_status && row.metadata.old_status && (
                                                    <>
                                                        {row.metadata?.new_status === "approved" &&
                                                            row.metadata?.old_status === "approved" ? (
                                                            <StyledText
                                                                fontvalue="14px"
                                                                lineheight="16px"
                                                                sx={styles.transactionText}
                                                            >
                                                                <StyledText
                                                                    fontvalue="14px"
                                                                    lineheight="16px"
                                                                    color="green"
                                                                >

                                                                    {t("Approved")}
                                                                </StyledText>
                                                            </StyledText>
                                                        ) : row.metadata?.new_status === "rejected" &&
                                                            row.metadata?.old_status === "rejected" ? (
                                                            <StyledText
                                                                fontvalue="14px"
                                                                lineheight="16px"
                                                                sx={styles.transactionText}
                                                            >
                                                                <StyledText
                                                                    fontvalue="14px"
                                                                    lineheight="16px"
                                                                >

                                                                    {t("Status")}:
                                                                </StyledText>
                                                                <StyledText
                                                                    fontvalue="14px"
                                                                    lineheight="16px"
                                                                    color="red"
                                                                >

                                                                    {t("Rejected")}
                                                                </StyledText>
                                                            </StyledText>
                                                        ) : row.metadata?.new_status === "rejected" ? (
                                                            <StyledText
                                                                fontvalue="14px"
                                                                lineheight="16px"
                                                                sx={styles.transactionText}
                                                            >
                                                                <StyledText
                                                                    fontvalue="14px"
                                                                    lineheight="16px"
                                                                >

                                                                    {t("Status")}:
                                                                </StyledText>
                                                                <StyledText
                                                                    fontvalue="14px"
                                                                    lineheight="16px"
                                                                    color="green"
                                                                >
                                                                    {t("Approved")}
                                                                </StyledText>
                                                                -
                                                                <StyledText
                                                                    fontvalue="14px"
                                                                    lineheight="16px"
                                                                    color="red"
                                                                >
                                                                    {t("Rejected")}
                                                                </StyledText>
                                                            </StyledText>
                                                        ) : (
                                                            <StyledText
                                                                fontvalue="14px"
                                                                lineheight="16px"
                                                                sx={styles.transactionText}
                                                            >
                                                                <StyledText
                                                                    fontvalue="14px"
                                                                    lineheight="16px"
                                                                >

                                                                    {t("Status")}:
                                                                </StyledText>
                                                                <StyledText
                                                                    fontvalue="14px"
                                                                    lineheight="16px"
                                                                    color="red"
                                                                >

                                                                    {t("Rejected")}
                                                                </StyledText>
                                                                -
                                                                <StyledText
                                                                    fontvalue="14px"
                                                                    lineheight="16px"
                                                                    color="green"
                                                                >
                                                                    {t("Approved")}
                                                                </StyledText>
                                                            </StyledText>
                                                        )}
                                                    </>
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <StyledText fontvalue="14px" lineheight="22px" color={handleColor(row.amount)}>
                                                    $
                                                    {row.amount
                                                        ? Number(row.amount).toFixed(2)
                                                        : "-"}
                                                </StyledText>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                $
                                                {row.endingBalance
                                                    ? Number(row.endingBalance).toFixed(2)
                                                    : "-"}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                }
                            </Table>
                        )
            }
            {entityId && selectedTransaction && (
                <FloatModal
                    open={openTransactionModal}
                    onClose={handleCloseTransactionModal}
                    modalTitle={selectedTransaction.toString()}
                    modalBody={
                        <TransactionOrdersModal
                            entityId={entityId}
                        />
                    }
                />
            )}
        </Box>
    )
}

export default TransactionDetailsModal