import React, { useEffect } from "react";
import { Box, Button, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeSendMoney, geteWalletDashboard } from "../../../actions";
import Loading from "../../../shared/Loading";
import { RootState } from "../../../configureStore";
import { stylesGlobal } from "../../../utils/muiStyles";
import { useTranslation } from "react-i18next";
import { styles } from "./styles";

function EwalletSignIn() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { eWalletDashboard } = useSelector(
    (state: RootState) => state?.entities?.nomadDashboard
  );

  useEffect(() => {
    dispatch(geteWalletDashboard.request({}));
  }, []);

  useEffect(() => {
    if (!eWalletDashboard?.isLoading && !eWalletDashboard?.isError) {
    }
  }, [eWalletDashboard?.data]);

  return (
    <Box height="200px">
      {eWalletDashboard?.isLoading && !eWalletDashboard?.isError ? (
        <Loading />
      ) : (
        eWalletDashboard?.data?.data?.length && (
          <Stack sx={styles.signinContainer}>
            <Box sx={styles.signinBtnOuter}>
              <Button
                variant="contained"
                sx={stylesGlobal.containedBtn}
                onClick={() => {
                  window.open(eWalletDashboard?.data?.data, "_blank");
                  dispatch(closeSendMoney());
                }}
              >
                {t("Go to eWallet dashboard")}
              </Button>
            </Box>
          </Stack>
        )
      )}
    </Box>
  );
};
export default EwalletSignIn;
