import {
  Box,
  CircularProgress,
  Popover,
  SelectChangeEvent,
  Stack,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CONSTANTS } from "../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { videoViewStatsCall } from "../../../../actions";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../configureStore";
import { formatMontDate, getPast7days, past24Hours } from "../../../../utils/hooks";
import FloatAreaLineGraph from "../../../../shared/components/FloatAreaLineChart";
import { ChartLineType } from "../../../../models/backoffice.models";
import ViewTable from "./ViewsTableData";
import FLoatDropDown from "../../../../shared/components/FLoatDropDown";
import DateRangeGraphFilter from "../../../../shared/components/DateRangeGraphFilter";
import FloatModal from "../../../../shared/components/FloatModal";
import { useTranslation } from "react-i18next";
import { viewStyle } from "./styles";
import FloatDateSelector from "../../../../shared/components/FloatDateSelector";

const styles = {
  loaderContainer: {
    minHeight: "272px",
    minWidth: "785px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const viewStats = {
  filterContain: {
    width: "100%",
    mb: "5px",
    display: "flex",
    height: "fit-content",
    alignItems: "flex-end",
    justifyContent: "end",
  },
};

function VideoViews({
  setSelectFilters,
  openMobileDate,
  setOpenMobileDate,
}: any) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { id } = useParams();
  const { filterViews } = CONSTANTS;
  const dispatch = useDispatch();
  const ref = useRef<any>();
  const { t } = useTranslation();

  const { videoViewsStat } = useSelector(
    (state: RootState) => state?.entities?.videoViews
  );

  const moment = require("moment");
  const today = moment().format("YYYY-MM-DD");
  const past7thDay = moment().subtract(6, "days").format("YYYY-MM-DD");
  const past30thDay = moment().subtract(29, "days").format("YYYY-MM-DD");

  const [viewFilter, setViewFilter] = useState(filterViews[0].key);
  const [viewStats24Hrs, setChart24hrsData]: any[] = useState();
  const [viewStats7days, setChart7dayData]: any[] = useState();
  const [viewStats30days, setChart30dayData]: any[] = useState();
  const [viewStatsDateRange, setChartDateRangeData]: any[] = useState();
  const [chartLoading, setChartLoading]: any = useState([]);
  const [isLoader, setLoader] = useState(true);
  const [openDate, setOpenDate] = useState<boolean>(false);
  // only aplied for custom mobile filter prop
  const [filters, setFilters]: any = useState({ filter: filterViews[0].key });
  const [anchorEl, setAnchorEl] = React.useState<boolean>(false);
  const [selectedDates, setSelectedDates]: any = useState(["", ""]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(!anchorEl);
    setViewFilter("dateRange");
    // setAnchorEl(null)
  };

  const handleClose = () => {
    setAnchorEl(false);
  };


  const ChartViews: ChartLineType[] = [
    {
      type: "linear",
      name: "Video views",
      dataKey: "views",
      stroke: "#2456CC",
      dotedGraph: true,
    },
  ];

  useEffect(() => {
    const { data, isLoading, isError } = videoViewsStat;
    if (!isLoading && data?.data?.length) {
      onChartDataChange(data?.data);
    }
    if (isError) {
      setChart24hrsData([]);
      setChart7dayData([]);
      setChart30dayData([]);
      setChartDateRangeData([]);
      setLoader(false);
    }
  }, [videoViewsStat, filterViews]);

  useEffect(() => {
    setChartLoading(videoViewsStat?.isLoading);
  }, [videoViewsStat?.isLoading]);

  const onChartDataChange = (data: any[]) => {
    const chart24Data: any = [];
    const chart7days: any = [];
    const chart30days: any = [];
    const chartDateRange: any = [];
    const week = getPast7days();
    const hours = past24Hours();
    data.map((item: any, index: number) => {
      if (viewFilter === "past7days" || filters?.filter === "past7days") {
        chart7days.push({ day: week[index], views: item.view_count });
      } else if (
        viewFilter === "past24hours" ||
        filters?.filter === "past24hours"
      ) {
        if (index == 0) {
          chart24Data.push({
            hour: hours[0],
            views: 0,
          });
        }
        chart24Data.push({
          hour: hours[index + 1],
          views: parseInt(item.view_count),
        });
      } else if (viewFilter === "past30days") {
        chart30days.push({
          day: formatMontDate(item?.date),
          views: item.view_count,
        });
      } else if (viewFilter === "dateRange") {
       item?.date && chartDateRange.push({
          day: formatMontDate(item.date),
          views: item.view_count,
        });
      } else {
        chart7days.push({ day: week[index], views: item.view_count });
      }
    });
    setChart24hrsData(chart24Data);
    setChart7dayData(chart7days);
    setChart30dayData(chart30days);
    setChartDateRangeData(chartDateRange);
    setLoader(false);
  };

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      if (event.target.value !== "dateRange") {
        setLoader(true);
        const data = event.target.value;
        setViewFilter(data);
        if (data === "past24hours") {
          dispatch(
            videoViewStatsCall.request({
              data: {
                data: {
                  filter: data,
                },
                videoId: id,
              },
            })
          );
        } else if (data === "past7days") {
          dispatch(
            videoViewStatsCall.request({
              data: {
                data: {
                  filter: "pastNdays",
                  startDate: past7thDay,
                  endDate: today,
                },
                videoId: id,
              },
            })
          );
        } else if (data === "past30days") {
          dispatch(
            videoViewStatsCall.request({
              data: {
                data: {
                  filter: "pastNdays",
                  startDate: past30thDay,
                  endDate: today,
                },
                videoId: id,
              },
            })
          );
        }
      }
    },
    [dispatch]
  );

  const handleDateFilter = () => {
    dispatch(
      videoViewStatsCall.request({
        data: {
          data: {
            filter: "pastNdays",
            startDate: selectedDates[0]?.format("YYYY-MM-DD"),
            endDate: selectedDates[1]?.format("YYYY-MM-DD"),
          },
          videoId: id,
        },
      })
    );
    handleClose();
    setOpenDate(false);
    setOpenMobileDate(false);
  };

  const SelectFilters = [
    {
      list: filterViews,
      label: "Filter",
      name: "filter",
      onChange: handleChange,
      value: filters?.filter ?? "",
      disabled: false,
    },
  ];

  useEffect(() => {
    dispatch(
      videoViewStatsCall.request({
        data: {
          data: {
            filter: "past24hours",
          },
          videoId: id,
        },
      })
    );
  }, []);

  useEffect(() => {
    return () => {
      setSelectFilters({});
      setOpenMobileDate(false);
      setOpenDate(false);
    };
  }, []);

  useEffect(() => {
    setFilters((prev: any) => ({
      ...prev,
      filter: viewFilter,
    }));
  }, [viewFilter]);

  useEffect(() => {
    setSelectFilters({
      filters: filters,
      setFilters: (a: any) => setFilters(a),
      SelectFilters: SelectFilters,
      onSubmit: () => {},
    });
  }, [viewFilter, filters]);

  useEffect(() => {
    setOpenDate(openMobileDate);
  }, [openMobileDate]);

  return (
    <Stack>
      <Box sx={viewStats.filterContain}>
        {!isMobile && (
          <Box onClick={()=>{anchorEl && setAnchorEl(false)}}>
            <FLoatDropDown
              value={viewFilter}
              onChange={handleChange}
              list={filterViews}
              iconClick={(e) => handleClick(e)}
              label="Filter"
              name="Filter"
            />
          </Box>
        )}
      </Box>
      <FloatDateSelector
        open={anchorEl}
        max30Days={true}
        isView={true}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        handleDateFilter={handleDateFilter}
      />
      <Box className="overflow-x-contain" sx={viewStyle.wrapper}>
        {chartLoading ? (
          <Box sx={styles.loaderContainer}>
            <CircularProgress />
          </Box>
        ) : (
          <FloatAreaLineGraph
            // max={10}
            Xaxis={
              viewFilter === "past24hours" || filters.filter === "past24hours"
                ? { dataKey: "hour" }
                : { dataKey: "day" }
            }
            ChartData={
              viewFilter == "past7days" || filters.filter === "past7days"
                ? viewStats7days
                : viewFilter == "past24hours" ||
                  filters.filter === "past24hours"
                ? viewStats24Hrs
                : viewFilter == "past30days" || filters.filter === "past30days"
                ? viewStats30days
                : viewFilter == "dateRange" || filters.filter === "dateRange"
                ? viewStatsDateRange
                : viewStats7days
            }
            ChartLines={ChartViews}
          />
        )}
      </Box>
      <ViewTable />
      {isMobile && (
        <FloatModal
          open={openDate}
          onClose={() => {
            setOpenDate(false);
            setOpenMobileDate(false);
          }}
          modalTitle={t("Select date")}
          modalBody={
            <DateRangeGraphFilter
              max30Days={true}
              isView={true}
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
              handleDateFilter={handleDateFilter}
            />
          }
        />
      )}
    </Stack>
  );
};

export default VideoViews;
